import React, { Fragment, useEffect, useState } from "react";
import "../../styles/quizAttempt.scss";
import CustomButton from "../reusable/CustomButton";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { defaultUser, logo_main, logo_mobile } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/userAction";
import { useAuth } from "../../context/userContext";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { MdMenuOpen } from "react-icons/md";
import { useSelector } from "react-redux";
import { clearMcqErrors, getMyMcqList } from "../../redux/actions/mcqAction";
import { useNotification } from "../../context/notificationContext";
import Loader from "../reusable/Loader";
// import Header from "../reusable/Header";
const QuizAttempt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const [openToggler, setOpenToggler] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [auth] = useAuth();

  const handleSignUpPage = () => {
    navigate("/register");
  };

  const handleSignInPage = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  // GET MY CREATED MCQ START
  const { loading, error, mcqList } = useSelector(
    (state) => state.getMyselfMcq
  );

  useEffect(() => {
    dispatch(getMyMcqList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearMcqErrors());
      showNotification("error", error);
    }
  }, [dispatch, showNotification, error]);
  // GET MY CREATED MCQ ENDS

  // HANDLE QUIZ FUNCTIONALTY STARTS
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [correctCount, setCorrectCount] = useState(0);
  const [skippedCount, setSkippedCount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30 * 60);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const subjectSet = mcqList?.mcqs?.[1].questions;

  // const currentQuestion = ;

  useEffect(() => {
    if (subjectSet?.[currentQuestionIndex]) {
      const selectedOptionId =
        selectedAnswers[subjectSet?.[currentQuestionIndex]._id];
      if (selectedOptionId !== undefined) {
        const selectedOption = subjectSet?.[currentQuestionIndex].options.find(
          (option) => option._id === selectedOptionId
        );
        if (selectedOption && selectedOption.isRight) {
          setCorrectCount((prev) => prev + 1);
        }
      }
    }
  }, [currentQuestionIndex]);

  const handleAnswer = (questionId, optionId, isRight) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: optionId,
    }));

    if (isRight) {
      setCorrectCount((prev) => prev + 1);
    }
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < subjectSet?.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const skipQuestion = () => {
    setSkippedCount((prev) => prev + 1);
    nextQuestion();
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="header_main">
            <div className="container">
              <div className="nav_bar_sec">
                <div className="logo">
                  <Link to="/">
                    <img
                      className="desktop"
                      src={logo_main}
                      alt="Alpha Regiment desktop_logo"
                    />
                    <img
                      className="mobile"
                      src={logo_mobile}
                      alt="Alpha Regiment mobile_logo"
                    />
                  </Link>
                </div>
                <div className="open_navbar_btn">
                  <GiHamburgerMenu
                    onClick={() => setOpenToggler(!openToggler)}
                  />
                </div>
                <div
                  className={`nav_links ${openToggler ? "open_navbar" : ""}`}
                >
                  <div className="close_menu_btn">
                    <AiOutlineClose
                      onClick={() => setOpenToggler(!openToggler)}
                    />
                  </div>

                  {auth?.user?._id ? (
                    <div className="user_details_nav_link_mobile">
                      <div className="userDetails">
                        <div className="profileImg">
                          <img
                            src={
                              auth?.user?.profile?.url
                                ? auth?.user?.profile?.url
                                : defaultUser
                            }
                            alt={auth?.user?.first_name}
                          />
                        </div>
                        <div className="userName">
                          {auth?.user?.first_name?.slice(0, 10)}
                        </div>
                      </div>
                      <div className="auth_user_links">
                        <Link
                          to={
                            auth?.user?.role === "15"
                              ? "/affiliate/profile"
                              : auth?.user?.role === "16" ||
                                auth?.user?.role === "17" ||
                                auth?.user?.role === "18"
                              ? "/officer/profile"
                              : auth?.user?.role === "13"
                              ? "/teacher/profile"
                              : auth?.user?.role === "12"
                              ? "/ar-teacher/profile"
                              : auth?.user?.role === "19"
                              ? "/doctor/profile"
                              : "/student/profile"
                          }
                        >
                          Profile
                        </Link>
                        <CustomButton title="Log Out" onClick={handleLogout} />
                      </div>
                    </div>
                  ) : (
                    <img
                      className="sideNavdesktopImg"
                      src={logo_main}
                      alt="Alpha Regiment desktop_logo"
                    />
                  )}

                  <div className="nav_link dropdown_nav">
                    {auth?.user?._id ? (
                      <div className="auth_profile_dropdown">
                        <button
                          className={`sign_up_btn dashboard_btn ${
                            auth?.user?.first_name ? "profile_header_btn" : ""
                          }`}
                          onClick={() => setUserDropdown(!userDropdown)}
                        >
                          <img
                            src={
                              auth?.user?.profile?.url
                                ? auth?.user?.profile?.url
                                : defaultUser
                            }
                            alt={auth?.user?.first_name}
                          />
                          {auth?.user?.first_name?.slice(0, 10)}
                          {userDropdown ? (
                            <BsFillCaretUpFill />
                          ) : (
                            <BsFillCaretDownFill />
                          )}
                        </button>
                        <div
                          className={`nav_dropdown_links ${
                            userDropdown ? "show_nav_dropdown" : ""
                          }`}
                        >
                          <Link
                            to={
                              auth?.user?.role === "15"
                                ? "/affiliate/profile"
                                : auth?.user?.role === "16" ||
                                  auth?.user?.role === "17" ||
                                  auth?.user?.role === "18"
                                ? "/officer/profile"
                                : auth?.user?.role === "13"
                                ? "/teacher/profile"
                                : auth?.user?.role === "12"
                                ? "/ar-teacher/profile"
                                : auth?.user?.role === "19"
                                ? "/doctor/profile"
                                : "/student/profile"
                            }
                          >
                            Profile
                          </Link>
                          <button className="logout_btn" onClick={handleLogout}>
                            logout
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="headerSign_btns">
                        <button
                          className="sign_up_btn"
                          onClick={handleSignInPage}
                        >
                          Sign In
                        </button>
                        <button
                          className="sign_up_btn"
                          onClick={handleSignUpPage}
                        >
                          Register
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quiz_wrapper">
            <div className="container">
              <div className="quiz_section">
                <div
                  className={`quiz_sidebar ${
                    openSidebar ? "sidebar_show" : ""
                  }`}
                >
                  <div className="close_btn">
                    <IoClose onClick={() => setOpenSidebar(!openSidebar)} />
                  </div>
                  <h4>Section</h4>
                  <ul className="subjects">
                    <li>
                      <p>Maths</p>
                    </li>
                    <li className="active">
                      <p>English</p>
                    </li>
                    <li>
                      <p>Hindi</p>
                    </li>
                    <li>
                      <p>Reasoning</p>
                    </li>
                  </ul>
                  <h4>Questions</h4>
                  <ul className="count">
                    {subjectSet?.map((_, idx) => (
                      <li
                        key={idx}
                        className={currentQuestionIndex === idx ? "active" : ""}
                      >
                        <p>{idx + 1}</p>
                      </li>
                    ))}
                  </ul>
                  <h4>Attempt Status</h4>
                  <ul className="status">
                    <li>
                      <p className="attempt">
                        <span>Answered</span>
                        <span>{correctCount}</span>
                      </p>
                    </li>
                    <li>
                      <p className="failed">
                        <span>Failed</span>
                        <span>{skippedCount}</span>
                      </p>
                    </li>
                    <li>
                      <p className="pending">
                        <span>Pending</span>
                        <span>
                          {subjectSet?.length - correctCount - skippedCount}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="quiz_question">
                  <div className="title">
                    <div className="toggle_menu">
                      <MdMenuOpen
                        onClick={() => setOpenSidebar(!openSidebar)}
                      />
                    </div>
                    <h2>Top 50 Question Set</h2>
                    <h2>Time Left: {formatTime(timeLeft)}</h2>
                  </div>
                  <div className="question_list">
                    {subjectSet?.[currentQuestionIndex] && (
                      <div>
                        <h3>{subjectSet?.[currentQuestionIndex].question}</h3>
                        <ul>
                          {subjectSet?.[currentQuestionIndex].options.map(
                            (option) => (
                              <li key={option._id}>
                                <label>
                                  <input
                                    type="radio"
                                    name={`question_${subjectSet?.[currentQuestionIndex]._id}`}
                                    value={option._id}
                                    checked={
                                      selectedAnswers[
                                        subjectSet?.[currentQuestionIndex]._id
                                      ] === option._id
                                    }
                                    onChange={() =>
                                      handleAnswer(
                                        subjectSet?.[currentQuestionIndex]._id,
                                        option._id,
                                        option.isRight
                                      )
                                    }
                                  />
                                  {option.option}
                                </label>
                              </li>
                            )
                          )}
                        </ul>
                        <div className="btn_group">
                          <button
                            onClick={prevQuestion}
                            disabled={currentQuestionIndex === 0}
                          >
                            Prev
                          </button>
                          <button onClick={skipQuestion}>Skip</button>
                          <button onClick={nextQuestion}>Next</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};
export default QuizAttempt;
