import React from "react";
import "./ticketSection.scss";
import CustomButton from "../../components/reusable/CustomButton";
import { Link } from "react-router-dom";

const TicketSection = () => {
  return <div className="get_ticket_now">
  <div className="container">
    <div className="title">
      <h2>Get Ticket Now For #EVTR22</h2>
      <p>
        The Eventer Conferennce has a variety of ticket types to suit
        the variety of designers out there. Find out the best option for
        you and your team!
      </p>
    </div>

    <div className="all_tickets_list">
      {["1 Day", "2 Day", "Full"].map((item, idx) => (
        <div className="single_ticket_card" key={idx}>
          <div className="ticket_desc">
            <h2>{item} Ticket</h2>
            <p className="desc">
              Access to one day of the Eventer Conference 2022
            </p>
            <p className="end_date">Sales end on 24 August 2022</p>
          </div>
          <div className="ticket_pricing">
            <p className="price">$ {429 * (idx + 1)}</p>
            <p className="tax_structure">Price excluding 18% GST</p>
            <CustomButton
              className="book_ticket_btn"
              title="Book Now"
              // onClick=""
              // icon=""
              // disabled=""
            />
          </div>
        </div>
      ))}
    </div>

    <div className="for_more_pricing">
      Need additional pricing info? <Link to="#!">Get in touch</Link>{" "}
      for custom and high-volume pricing.
    </div>
  </div>
</div>
};

export default TicketSection;
