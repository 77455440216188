import React from "react";
import "../styles/courseYouJoin.scss";
import CustomInput from "../components/reusable/CustomInput";
import Layout from "../components/reusable/Layout";
import CustomSelect from "../components/reusable/CustomSelect";

const CourseYouJoin = () => {
  const qualificationOptions = [
    {
      option: "10th",
    },
    {
      option: "12th",
    },
    {
      option: "Graduate",
    },
    {
      option: "Post Graduate",
    },
  ];

  // FORCE CHOICE ARRAY
  const forceChoice = [
    {
      option: "All",
    },
    {
      option: "Air Force",
    },
    {
      option: "Navy",
    },
    {
      option: "Army",
    },
    {
      option: "CAPF",
    },
    {
      option: "Police",
    },
  ];

  return (
    <Layout>
      <div className="course_you_join">
        <div className="container">
          <div className="course_form">
            <div className="title">
              <h1>details</h1>
            </div>
            <div className="form_fields">
              <CustomInput
                label="Name"
                type="text"
                placeholder="Ex: Ashish verma"
                name="name"
              />
              <CustomInput
                label="Age"
                type="number"
                placeholder="Ex: 18"
                name="age"
              />
              <CustomInput
                label="Email"
                type="email"
                placeholder="Ex: ashishverma123@gmail.com"
                name="email"
              />
              <CustomSelect
                label="Qualification"
                name="qualification"
                optionArray={qualificationOptions}
              />
              <CustomInput
                label="Phone number"
                type="number"
                placeholder="Ex: 9876555432"
                name="phone"
              />
              <CustomSelect
                label="Choice of force"
                name="choiceOfForce"
                optionArray={forceChoice}
              />
            </div>
            <button className="submit_btn">submit</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CourseYouJoin;
