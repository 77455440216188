export const affiliateSidebarLinks = [
  {
    id: 1,
    title: "Profile",
    link: "/affiliate/profile",
  },
  {
    id: 2,
    title: "Dashboard",
    link: "/affiliate/dashboard",
  },
  {
    id: 4,
    title: "Analytics and payments",
    link: "/affiliate/analytics-payments",
  },
  {
    id: 5,
    title: "Course Links",
    link: "/affiliate/course-links",
  },
  {
    id: 6,
    title: "Chat Support",
    link: "https://wa.me/+917015971023",
  },
  {
    id: 7,
    title: "Feedback",
    link: "/affiliate/feedback",
  },
];
