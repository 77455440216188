import React, { Fragment, useEffect, useState } from "react";
import { CourseSection } from "../../styles/ssbCoursesCss";
import Layout from "../../components/reusable/Layout";
import CustomInput from "../../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
import { RiMenuUnfoldLine, RiCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CustomHelmet from "../../components/reusable/Helmet";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/reusable/Loader";
import {
  getAllSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import CourseCard from "../../components/course/CourseCard";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { clearErrors, getAllCourses } from "../../redux/actions/courseAction";




const SsbCourses = () => {
  const [t] = useLocale();
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [activeCategory] = useState(0);
  const [courseMode, setCourseMode] = useState("");
  const [categoryTitle] = useState("All Courses");
  const [, , showNotification] = useNotification();
  const [filterCourse, setFilterCourse] = useState([]);

  // GET COURSES FROM BACKEND/APIS
  const dispatch = useDispatch();

  const { loading, courses, error } = useSelector(
    (state) => state.getAllSsbCourses
  );

  const allSsbApiCourses = courses?.courses?.filter(
    (itm) =>
      (itm?.isActive && !itm?.isPause) ||
      (itm?.isActive && itm?.isPause && itm?.pauseForDate !== null)
  );

  // console.log(allSsbApiCourses);

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch]);

  // handle search change input

  // Filter Courses by Search String or category wise

  const handleSearchChange = (e) => {
    const filter = e.target.value;
    setSearchCategory(filter);
    if (categoryTitle === "Spoken English") {
      const data = allApiCourses?.filter(
        (i) => i?.courseCategory?.toString() === categoryTitle?.toString()
      );
      const filterdata = data?.filter((i) => {
        return (
          i.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) &&
          i.study_mode
            .toLocaleLowerCase()
            .includes(courseMode.toLocaleLowerCase())
        );
      });
      setFilterCourse(filterdata);
    } else {
      const filterdata = allSsbApiCourses?.filter((i) => {
        return (
          i.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) &&
          i.courseMode
            .toLocaleLowerCase()
            .includes(courseMode.toLocaleLowerCase())
        );
      });

      setFilterCourse(filterdata);
    }
  };

  const handleFilterMode = (query) => {
    setCourseMode(query);
    const filterData = allSsbApiCourses?.filter((course) => {
      return course?.courseMode === query;
    });
    setFilterCourse(filterData);
  };

  // SSB COURSE DETAILS HANDLER
  const handleSsbDetailsPage = (data) => {
    if (categoryTitle === "Spoken English") {
      const { title, study_mode, courseCategory, _id } = data;
      const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
      const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
      const formattedCategory = courseCategory
        .toLowerCase()
        .replace(/\s+/g, "-");
      const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
      navigate(seoFriendlyUrl);
    } else {
      const { slug } = data;
      const seoFriendlyUrl = `/ssb-course-details/${slug}`;
      navigate(seoFriendlyUrl);
    }
  };

  // HANDLE SELECT CATEGORY
  const handleSelectCategory = (category, activeNumber) => {
    if (category === "SSB Coaching") {
      navigate("/ssb-coaching");
    }
    if (category === "Medical Checkups") {
      navigate("/medical-checkups");
    }
    if (category === "Mock SSB Interview") {
      navigate("/mock-ssb-interview");
    }
    if (category === "Mock Interview") {
      navigate("/mock-interview");
    }
    if (category === "Mock Psychology") {
      navigate("/mock-psychology");
    }
    if (category === "Mock GTO") {
      navigate("/mock-gto");
    }
    if (category === "Spoken English") {
      navigate("/spoken-english");
    }
    if (category === "Fitness Coach") {
      navigate("/fitness-coach");
    }
  };

  useEffect(() => {
    if (
      activeCategory === 0 &&
      categoryTitle === "All Courses" &&
      !filterCourse?.length
    ) {
      setFilterCourse(allSsbApiCourses);
    }
  }, [activeCategory, categoryTitle, allSsbApiCourses, filterCourse]);

  // get language param from localstorage
  // const langParam = localStorage.getItem("lang")
  //   ? localStorage.getItem("lang")
  //   : "en";

  // CHECK WISHLIST EXIST OR NOT
  const [wishListId, setWishListId] = useState("");

  const {
    // eslint-disable-next-line
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    setWishListId("");
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES
  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);

  // const [shuffledCourses, setShuffledCourses] = useState([]);

  // GET ALL COURSES

  const {
    loading: courseLoading,
    courses: data,
    error: courseError,
  } = useSelector((state) => state.getAllCourses);

  const allApiCourses = data?.courses;

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(clearErrors());
    }
  }, [courseError, showNotification, dispatch]);


  // JOYRIDE SETTINGS
  // const [joyrideSteps] = useState([
  //   {
  //     target: ".open_course_nav",
  //     content: "Click this menu button to explore navigation bar.",
  //     placement: "bottom",
  //     disableBeacon: true,
  //   },
  // ]);
  // const [runJoyride, setRunJoyride] = useState(true);

  // useEffect(() => {
  //   if (window.innerWidth < 767 && document.querySelector(".open_course_nav")) {
  //     setRunJoyride(true);
  //   }
  // }, []);


 
  const currentPageUrl = window?.location?.href;
  return (
    <Fragment>
      {loading || courseLoading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="Prepare for SSB Interview your way with mock SSB sessions, coaching, mock psychology tests, personal interview simulations, spoken English for SSB, and more."
            author="Team Alpha Regiment"
            title="Prepare for the SSB Interview with DIPR-Trained Retired Officers' Guidance"
            canonical={currentPageUrl}
          />

          <CourseSection className="all_courses_main">
            <div className="container">
              <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div>
            </div>
            <div className="all_course_content">
              <button
                className="open_course_nav"
                onClick={() => setShowSideNav(!showSideNav)}
                title={showSideNav ? "Close Button Bar" : "Open Button Bar"}
              >
                {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
              </button>
              {/* {window?.innerWidth < 767 && runJoyride && (
                <Joyride
                  steps={joyrideSteps}
                  run={runJoyride}
                  // continuous
                  showProgress
                  showSkipButton={false}
                  disableOverlayClose
                  styles={{
                    buttonNext: {
                      display: "none",
                    },
                    buttonSkip: {
                      display: "none",
                    },
                  }}
                  callback={(data) => {
                    if (data.action === "skip" || data.status === "finished") {
                      setRunJoyride(false);
                    }
                  }}
                />
              )} */}

              <div className={`course_nav ${showSideNav ? "showSideNav" : ""}`}>
                <button
                  onClick={() => handleSelectCategory("All Courses", 0)}
                  className={activeCategory === 0 ? "active" : ""}
                >
                  All
                </button>
                <button
                  onClick={() => handleSelectCategory("SSB Coaching", 1)}
                  className={activeCategory === 1 ? "active" : ""}
                >
                  SSB Coaching
                </button>
                <button
                  onClick={() => handleSelectCategory("Mock SSB Interview", 9)}
                  className={activeCategory === 9 ? "active" : ""}
                >
                  Mock SSB Interview
                </button>
                <button
                  onClick={() => handleSelectCategory("Mock Interview", 2)}
                  className={activeCategory === 2 ? "active" : ""}
                >
                  Mock Interview
                </button>
                <button
                  onClick={() => handleSelectCategory("Mock Psychology", 3)}
                  className={activeCategory === 3 ? "active" : ""}
                >
                  Mock Psychology
                </button>
                <button
                  onClick={() => handleSelectCategory("Mock GTO", 4)}
                  className={activeCategory === 4 ? "active" : ""}
                >
                  Mock GTO
                </button>
                <button
                  onClick={() =>
                    handleSelectCategory("Spoken English", 5)
                  }
                  className={activeCategory === 5 ? "active" : ""}
                >
                  Spoken English
                </button>
                <button
                  onClick={() => handleSelectCategory("Medical Checkups", 7)}
                  className={activeCategory === 7 ? "active" : ""}
                >
                  Medical Checkups
                </button>
                <button
                  onClick={() => handleSelectCategory("Fitness Coach", 8)}
                  className={activeCategory === 8 ? "active" : ""}
                >
                  Fitness Coach
                </button>
              </div>

              <div className="all_course_cards">
                <h1 className="main_title">{categoryTitle}</h1>

                {categoryTitle !== "Physical Fitness" &&
                  categoryTitle !== "Medical Checkups" &&
                  filterCourse?.length > 0 && (
                    <div className="slider_container">
                      {filterCourse?.map((item, idx) => {
                        const roundedPrice = Math.ceil(
                          categoryTitle !== "Spoken English"
                            ? item?.originalPrice
                            : item?.original_price
                        );
                        const roundedDiscountedPrice = Math.ceil(
                          categoryTitle !== "Spoken English"
                            ? item?.discountedPrice
                            : item?.discounted_price
                        );
                        return (
                          <CourseCard
                            key={idx}
                            isActiveClass={true}
                            className="course_card"
                            title={item?.title}
                            description={`${item?.description?.substr(
                              0,
                              40
                            )}...`}
                            icon={item?.thumbnail?.url}
                            startingFrom={item?.starting_date}
                            price={roundedPrice}
                            discountedPrice={roundedDiscountedPrice}
                            numOfReviews={item?.numOfReviews}
                            rating={item?.rating}
                            addWishList={() =>
                              handleAddtoWishlist({
                                itemId: item?._id,
                                isSsb: true,
                              })
                            }
                            deleteWishListLoading={
                              wishListId === item?._id &&
                              deleteLoading && <ButtonLoader />
                            }
                            existInWishList={allWishList?.wishlist?.ssbCourse?.some(
                              (obj) => obj._id === item._id
                            )}
                            addWishListLoading={
                              wishListId === item?._id &&
                              addWishListLoading && <ButtonLoader />
                            }
                            onHomePageClick={() => handleSsbDetailsPage(item)}
                          />
                        );
                      })}
                    </div>
                  )}

                {categoryTitle !== "Physical Fitness" &&
                  categoryTitle !== "Medical Checkups" &&
                  filterCourse?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}

              

              </div>
            </div>
          </CourseSection>
        </Layout>
      )}

     
    </Fragment>
  );
};

export default SsbCourses;
