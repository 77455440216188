import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_VIDEOCALL_ERROR,
  CLEAR_VIDEOCALL_STATE,
  PRO_VIDEOCALL_LINK__FAIL,
  PRO_VIDEOCALL_LINK__REQUEST,
  PRO_VIDEOCALL_LINK__SUCCESS,
  VIDEOCALL_LINK_FAIL,
  VIDEOCALL_LINK_REQUEST,
  VIDEOCALL_LINK_SUCCESS,
  VIDEOCALL_LINK_VALIDATE_FAIL,
  VIDEOCALL_LINK_VALIDATE_REQUEST,
  VIDEOCALL_LINK_VALIDATE_SUCCESS,
} from "../constants/videoCallConstant";

// FOR VIDEO CALL LINK CREATION
export const createVideoCallLink =
  (professionalsFullName, socketId, professionalsId) => async (dispatch) => {
    try {
      dispatch({ type: VIDEOCALL_LINK_REQUEST });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/video-call/user-link",
        {
          professionalsFullName,
          socketId,
          professionalsId
        },
        config
      );
      dispatch({
        type: VIDEOCALL_LINK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VIDEOCALL_LINK_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// VALIDATE CREATED VIDEO CALL LINK
export const validateVideoCallLink = (token) => async (dispatch) => {
  try {
    dispatch({ type: VIDEOCALL_LINK_VALIDATE_REQUEST });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      "/video-call/validate-link",
      {
        token,
      },
      config
    );
    dispatch({
      type: VIDEOCALL_LINK_VALIDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VIDEOCALL_LINK_VALIDATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// PROFESSIONALS VIDEO CALL LINK
export const proVideoCallLink = () => async (dispatch) => {
  try {
    dispatch({ type: PRO_VIDEOCALL_LINK__REQUEST });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/video-call/pro-link",
      config
    );
    dispatch({
      type: PRO_VIDEOCALL_LINK__SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRO_VIDEOCALL_LINK__FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearVideoCallErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_VIDEOCALL_ERROR });
};

// CLEAR STATE ACTION FUNCTION
export const clearVideoCallState = () => async (dispatch) => {
  dispatch({ type: CLEAR_VIDEOCALL_STATE });
};
