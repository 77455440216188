import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import "../../styles/ssbCoaching.scss";
import CustomInput from "../../components/reusable/CustomInput";
import {
  checkCouponCodeValidation,
  clearCouponCode,
  clearErrors,
  userListByRoleAction,
  userProfile,
} from "../../redux/actions/userAction";
import MedicalAndCoachCard from "../../components/reusable/MedicalAndCoachCard";
import CustomButton from "../../components/reusable/CustomButton";
import {
  clearRazorErrors,
  clearRazorState,
  createRazorPayment,
  getRazorKey,
  razorPaymentverification,
} from "../../redux/actions/razorpayAction";
import { useCookies } from "react-cookie";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";

const CouponPaymentPopup = ({
  showCouponPopup,
  setShowCouponPopup,
  courseDetails,
}) => {
  // const [t] = useLocale();
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  // const [showEmiTable, setShowEmiTable] = useState(false);
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const payment = courseDetails?.price;
  const discountePercentage = courseDetails?.price;
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [initialData, setInitialData] = useState({});

  const closeModal = () => {
    // window.location.reload();
    dispatch(clearCouponCode());
    setShowCouponPopup(false);
    // setShowEmiTable(false);
    setCouponCode("");
  };

  // GET RAZOR PAY KEY START
  const {
    key: razorKey,
    error: razorKeyError,
    // eslint-disable-next-line
    loading: razorKeyLoading,
  } = useSelector((state) => state.razorKey);

  const razorPaykey = razorKey?.key;

  useEffect(() => {
    dispatch(getRazorKey());
  }, [dispatch]);

  useEffect(() => {
    if (razorKeyError) {
      dispatch(clearRazorErrors());
    }
  }, [dispatch, razorKeyError]);
  // GET RAZOR PAY KEY ENDS

  // CHECK COUPON CODE IS VALID OR NOT START
  // CHECK COUPON CODE IS VALID OR NOT START
  const handleCodeCheck = () => {
    if (couponCode) {
      dispatch(checkCouponCodeValidation(couponCode));
    } else {
      showNotification("warning", "Please fill Coupon Code!");
    }
  };

  const { loading, isValidCoupon, error } = useSelector(
    (state) => state.checkCouponCode
  );

  let discountedAmount = payment;
  if (isValidCoupon?.success) {
    const discounteAmount = (payment * discountePercentage) / 100;
    discountedAmount = payment - discounteAmount;
  } else {
    discountedAmount = payment;
  }

  useEffect(() => {
    if (error) {
      setCouponCode("");
    }
  }, [error]);
  // CHECK COUPON CODE IS VALID OR NOT ENDS
  // CHECK COUPON CODE IS VALID OR NOT ENDS

  // GET COUPON CODE FROM COOKIES START
  // GET COUPON CODE FROM COOKIES START
  const [cookies] = useCookies([]);

  useEffect(() => {
    const myCookieValue = cookies.alphaCouponCodeCookie;
    setCouponCode(myCookieValue);
  }, [cookies]);
  // GET COUPON CODE FROM COOKIES ENDS
  // GET COUPON CODE FROM COOKIES ENDS

  // CREATE PAYEMENT INSTANCE START
  // CREATE PAYEMENT INSTANCE START
  const {
    payment: razorPayment,
    error: razorError,
    loading: razorLoading,
  } = useSelector((state) => state.razorPayment);

  const paymentDone = (
    amount,
    for_course,
    forDoctor,
    couponCode,
    emiId,
    seatBooking
  ) => {
    const ssbCourse = false;

    const amountRound = Math.ceil(amount);
    setInitialData({
      for_course,
      ssbCourse,
      amountRound,
      couponCode,
      emiId,
      seatBooking,
      forDoctor,
    });
    dispatch(
      createRazorPayment(
        for_course,
        ssbCourse,
        amountRound,
        couponCode,
        emiId,
        seatBooking,
        forDoctor
      )
    );
  };

  useEffect(() => {
    if (razorPayment?.success) {
      // INTILSE RAZOR PAY FOR PAYENT POPUP START
      const intialiseRazorPay = (order) => {
        var options = {
          key: razorPaykey,
          amount: order.amount,
          currency: "INR",
          order_id: order.id,
          handler: function (response) {
            setPaymentResponse(response);
          },
          prefill: {
            name: auth?.user?.first_name,
            email: auth?.user?.email,
            contact: auth?.user?.phone,
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      };
      intialiseRazorPay(razorPayment?.order);
      // INTILSE RAZOR PAY FOR PAYENT POPUP ENDS
      dispatch(clearRazorState());
    }
    if (razorError) {
      showNotification("error", razorError);
      dispatch(clearRazorErrors());
    }
  }, [
    dispatch,
    razorPaykey,
    razorError,
    razorPayment?.success,
    razorPayment?.order,
    showNotification,
    auth,
  ]);
  // CREATE PAYEMENT INSTANCE ENDS
  // CREATE PAYEMENT INSTANCE ENDS

  // VERIFY PAYMENT START
  // VERIFY PAYMENT START
  const {
    payment: verifyPayment,
    error: verifyPaymentError,
    // eslint-disable-next-line
    loading: verifyverifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  useEffect(() => {
    if (paymentResponse && initialData) {
      dispatch(
        razorPaymentverification(
          paymentResponse.razorpay_payment_id,
          paymentResponse.razorpay_order_id,
          paymentResponse.razorpay_signature,
          initialData?.for_course,
          initialData?.ssbCourse,
          initialData?.couponCode,
          initialData?.emiId,
          initialData?.seatBooking,
          initialData?.forDoctor
        )
      );
    }
  }, [paymentResponse, initialData, dispatch]);

  useEffect(() => {
    if (verifyPayment?.success) {
      showNotification("success", verifyPayment?.message);
      setShowCouponPopup(false);
      navigate("/verification", { state: courseDetails });
      dispatch(clearRazorState());
      dispatch(userProfile());
    }
    if (verifyPaymentError) {
      showNotification("error", verifyPaymentError);
      dispatch(clearRazorErrors());
    }
  }, [
    setShowCouponPopup,
    verifyPayment?.success,
    verifyPayment?.message,
    verifyPaymentError,
    showNotification,
    dispatch,
    navigate,
    courseDetails,
  ]);

  // VERIFY PAYMENT ENDS
  // VERIFY PAYMENT ENDS

  return (
    <section
      className={`coupon_payment_popup_main bg_color txt_color ${
        showCouponPopup ? "showPaymentModal" : ""
      }`}
    >
      {showCouponPopup && (
        <>
          <button className="close_modal" onClick={() => closeModal()}>
            <AiOutlineClose />
          </button>
          <div>
            <p className="coupon_code_txt">
              If you have coupon for discount fill here!
            </p>

            {error && <p className="error_line">{error}</p>}
            {isValidCoupon?.success && (
              <p className="succes_line">{isValidCoupon?.message}</p>
            )}

            <div className="couponCode_sec">
              <CustomInput
                type="text"
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Coupon Code"
                name="couponCode"
                value={couponCode}
              />
              <button
                className={`check_couponCode_btn ${
                  couponCode?.length <= 0 ? "disabled" : ""
                }`}
                onClick={() => handleCodeCheck()}
              >
                {loading ? <ButtonLoader /> : "Apply Coupon"}
              </button>
            </div>
            <div className="pay_withoutCoupon">
              {isValidCoupon?.success && (
                <p style={{ color: "green", fontWeight: "bold" }}>
                  This is {isValidCoupon?.isValidCoupon?.name}'s Coupon Code.
                  you get {discountePercentage}% discount for this Course.
                </p>
              )}
              <div className="all_payment_btns">
                <CustomButton
                  className=""
                  disabled={razorLoading ? true : false}
                  title={
                    razorLoading ? (
                      <ButtonLoader />
                    ) : (
                      `Pay ₹${Math.ceil(discountedAmount)}`
                    )
                  }
                  onClick={() =>
                    paymentDone(
                      discountedAmount,
                      null,
                      courseDetails?._id,
                      null,
                      null,
                      false
                    )
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const FitnessCoach = () => {
  const currentPageUrl = window?.location?.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [t] = useLocale();

  // GET ALL DOCTOR LIST STARTS
  useEffect(() => {
    dispatch(userListByRoleAction(19));
  }, [dispatch]);
  const {
    error: userListError,
    loading: userListLoading,
    userList,
  } = useSelector((state) => state.userListByRole);

  useEffect(() => {
    if (userListError) {
      dispatch(clearErrors());
    }
  }, [dispatch, userListError]);

  const filterDoctorList = userList?.users;
  // GET ALL DOCTOR LIST ENDS
  // GET ALL DOCTOR LIST ENDS

  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [courseData, setcourseData] = useState({});
  const paymentHandler = (data) => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/ssb-courses` });
      showNotification("warning", "Please login before proceed to buy");
    } else {
      setShowCouponPopup(true);
      setcourseData(data);
    }
  };

  return (
    <Fragment>
      {userListLoading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="Benefit from the renowned training techniques of Indian armed forces Ustads at Alpha Regiment, preparing you thoroughly before joining the forces."
            author="Team Alpha Regiment"
            title="Enhance your fitness with retired instructors at Alpha Regiment."
            canonical={currentPageUrl}
          />
          <section className="ssb_coaching_courses_main">
            <div className="container">
              {/* <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div> */}

              <div className="all_course_content">
                <div className="all_course_cards">
                  <h1 className="main_title">
                    <span onClick={() => navigate(-1)}>
                      <IoMdArrowRoundBack />
                    </span>{" "}
                    Fitness Coach
                  </h1>
                  <p className="description">
                    Many defense aspirants face challenges in maintaining the
                    required physical fitness for the SSB interview, especially
                    as there is no standalone physical fitness test. Instead,
                    your physical fitness is evaluated during self-assessments
                    and tasks, making it essential to stay in top form. Fitness
                    coaching for SSB helps candidates improve strength, stamina,
                    and overall fitness, ensuring they are prepared for these
                    evaluations and ready to handle the physical demands of the
                    process.
                  </p>

                  {filterDoctorList?.length > 0 && (
                    <div className="slider_container">
                      {filterDoctorList?.map((itm, idx) => (
                        <MedicalAndCoachCard
                          key={idx}
                          data={itm}
                          onClick={() => paymentHandler(itm)}
                          tooltip="For a single appointment, medication and tests are not included."
                        />
                      ))}
                    </div>
                  )}

                  {filterDoctorList?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is a Fitness Coach?</h2>
                </div>
                <div className="detail">
                  <p>
                    SSB fitness coaching is a personalized training program
                    designed to enhance the physical health and endurance
                    required for the SSB interview. Alpha Regiment connects
                    students with expert fitness trainers who can work with them
                    either online or offline to improve overall fitness levels,
                    focusing on endurance, strength, flexibility, and agility.
                    This coaching ensures aspirants are well-prepared to face
                    the physical aspects of the SSB process with confidence.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is the Duration of SSB Fitness Coaching?</h2>
                </div>
                <div className="detail">
                  <p>
                    The duration of SSB fitness coaching can vary depending on
                    the individual's fitness level and specific goals.
                    Typically, coaching sessions last between 4 to 12 weeks,
                    with tailored programs for those who need intensive
                    short-term improvement or long-term conditioning. The
                    coaching is adaptable, based on progress and ongoing
                    evaluations.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    Is it Necessary to Stay On-Campus During Fitness Coaching?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    For fitness coaching, students can choose between online or
                    offline options. While staying on-campus may be ideal for
                    immersive and focused training, daily commuting can also be
                    an option for offline coaching. For those opting for online
                    coaching, flexible scheduling allows them to train from
                    anywhere while receiving personalized fitness guidance.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    What if an Aspirant Faces Challenges During Fitness
                    Coaching?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    Aspirants can face challenges during their fitness journey,
                    but the personalized coaching ensures continuous support.
                    Whether it's an injury, a fitness plateau, or motivation
                    issues, the trainers will adjust the workout plans to
                    address individual needs. Progress is regularly tracked, and
                    adjustments are made to optimize results and help candidates
                    overcome any obstacles.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Who Benefits Most from SSB Fitness Coaching?</h2>
                </div>
                <div className="detail">
                  <p>
                    SSB fitness coaching is perfect for the following categories
                    of aspirants:
                  </p>
                  <ul style={{ listStyle: "decimal" }}>
                    <li>
                      <b>Repeaters:</b> For those who have faced rejection in
                      previous SSBs due to fitness-related issues, personalized
                      coaching helps them target specific areas to improve their
                      physical performance and overall health.
                    </li>
                    <li>
                      <b>First-Timers:</b> Candidates preparing for the SSB
                      interview who want to ensure they meet the required
                      physical standards will find coaching essential for
                      strengthening their body and mind before the interview.
                    </li>
                    <li>
                      <b>Those Looking to Improve Specific Areas:</b> Whether
                      it's improving running speed, upper body strength, or
                      stamina, fitness coaching offers targeted training to help
                      candidates excel in these areas.
                    </li>
                    <li>
                      <b>Overall Health Improvement:</b> Fitness coaching also
                      benefits those seeking to improve their overall health,
                      build stamina, and boost endurance, helping them to
                      perform well during self-assessments in the SSB interview.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    Why SSB Fitness Coaching Stands Out Compared to Other
                    Methods
                  </h2>
                </div>
                <div className="detail">
                  <ul style={{ listStyle: "decimal" }}>
                    <li>
                      <b>Personalized Training:</b> SSB fitness coaching
                      provides a customized approach to physical training,
                      focusing on the specific needs and goals of each aspirant,
                      whether for the SSB interview or overall fitness
                      improvement.
                    </li>
                    <li>
                      <b>Expert Guidance:</b> Students have access to expert
                      fitness trainers who understand the physical expectations
                      of the SSB process and can design fitness plans tailored
                      to each candidate's needs. These professionals guide them
                      through techniques that prevent injury while maximizing
                      results.
                    </li>
                    <li>
                      <b>Holistic Approach:</b> Fitness coaching is not just
                      about improving physical fitness but also about boosting
                      mental toughness, which is crucial for handling the
                      challenges of the SSB interview. Candidates receive
                      guidance on maintaining discipline, focus, and resilience.
                    </li>
                    <li>
                      <b>Progress Tracking:</b> Progress is continuously
                      monitored, and fitness assessments allow candidates to see
                      improvements over time. If needed, the trainers adjust the
                      plans to address any weaknesses, ensuring aspirants are
                      fully prepared for the physical tasks they will face.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Is SSB Fitness Coaching the Best Choice for Everyone?</h2>
                </div>
                <div className="detail">
                  <p>
                    While SSB fitness coaching is highly effective for most
                    aspirants, some candidates may prefer to focus on their
                    physical training independently or through other methods. At{" "}
                    <b>Alpha Regiment</b>, we provide flexibility by offering
                    both online and offline coaching, allowing each student to
                    choose what best suits their needs.
                  </p>
                  <br />
                  <p>
                    Ready to improve your physical fitness and prepare for the
                    SSB interview? <b>Alpha Regiment</b> connects you with
                    expert fitness coaches, whether online or offline, to ensure
                    you're in top shape for your SSB success!
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}

      {/* COUPON CODE POPUP START */}

      <CouponPaymentPopup
        showCouponPopup={showCouponPopup}
        setShowCouponPopup={setShowCouponPopup}
        courseDetails={courseData}
      />
      {/* COUPON CODE POPUP ENDS */}
    </Fragment>
  );
};

export default FitnessCoach;
