import {
  AUTHOR_REPLY_COMMENT_FAIL,
  AUTHOR_REPLY_COMMENT_REQUEST,
    AUTHOR_REPLY_COMMENT_SUCCESS,
    AUTHOR_REPLY_DELETE_FAIL,
    AUTHOR_REPLY_DELETE_REQUEST,
    AUTHOR_REPLY_DELETE_STATE,
    AUTHOR_REPLY_DELETE_SUCCESS,
    CLEAR_BLOG_ERROR,
    CLEAR_BLOG_STATE,
    CLEAR_COMMENT_ERROR,
    CLEAR_COMMENT_STATE,
    CLEAR_DELETE_BLOG_STATE,
    CREATE_BLOG_FAIL,
    CREATE_BLOG_REQUEST,
    CREATE_BLOG_SUCCESS,
    DELETE_BLOG_FAIL,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    DELETE_COMMENT_FAIL,
    DELETE_COMMENT_REQUEST,
    DELETE_COMMENT_SUCCESS,
    GET_BLOGS_FAIL,
    GET_BLOGS_REQUEST,
    GET_BLOGS_SUCCESS,
    GET_BLOG_BY_ID_FAIL,
    GET_BLOG_BY_ID_REQUEST,
    GET_BLOG_BY_ID_SUCCESS,
    POST_COMMENT_FAIL,
    POST_COMMENT_REQUEST,
    POST_COMMENT_SUCCESS,
  } from "../constants/blogConstant";
  
  // CREATE NEW BATCH BY ADMIN
  export const createBlogReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_BLOG_REQUEST:
        return {
          loading: true,
          blogCreate: {},
        };
      case CREATE_BLOG_SUCCESS:
        return {
          loading: false,
          blogCreate: action.payload,
        };
      case CREATE_BLOG_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogCreate: {},
        };
  
      default:
        return state;
    }
  };

  // POST COMMENT 
  export const postCommentBlogReducer = (state = {}, action) => {
    switch (action.type) {
      case POST_COMMENT_REQUEST:
        return {
          loading: true,
          blogComment: {},
        };
      case POST_COMMENT_SUCCESS:
        return {
          loading: false,
          blogComment: action.payload,
        };
      case POST_COMMENT_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_COMMENT_ERROR:
        return {
          loading: false,
          error: null,
        };
      case CLEAR_COMMENT_STATE:
        return {
          loading: false,
          error: null,
          blogComment: {},
        };
  
      default:
        return state;
    }
  };

  // REPLY ON COMMENT 
  export const replyCommentBlogReducer = (state = {}, action) => {
    switch (action.type) {
      case AUTHOR_REPLY_COMMENT_REQUEST:
        return {
          loading: true,
          replyComment: {},
        };
      case AUTHOR_REPLY_COMMENT_SUCCESS:
        return {
          loading: false,
          replyComment: action.payload,
        };
      case AUTHOR_REPLY_COMMENT_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_COMMENT_ERROR:
        return {
          loading: false,
          error: null,
        };
      case CLEAR_COMMENT_STATE:
        return {
          loading: false,
          error: null,
          replyComment: {},
        };
  
      default:
        return state;
    }
  };

  // DELETE COMMENT 
  export const deleteCommentReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_COMMENT_REQUEST:
        return {
          loading: true,
          commentDelete: {},
        };
      case DELETE_COMMENT_SUCCESS:
        return {
          loading: false,
          commentDelete: action.payload,
        };
      case DELETE_COMMENT_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_COMMENT_ERROR:
        return {
          loading: false,
          error: null,
        };
      case AUTHOR_REPLY_DELETE_STATE:
        return {
          loading: false,
          error: null,
          commentDelete: {},
        };
  
      default:
        return state;
    }
  };

  export const deleteReplyReducer = (state = {}, action) => {
    switch (action.type) {
      case AUTHOR_REPLY_DELETE_REQUEST:
        return {
          loading: true,
          replyDelete: {},
        };
      case AUTHOR_REPLY_DELETE_SUCCESS:
        return {
          loading: false,
          replyDelete: action.payload,
        };
      case AUTHOR_REPLY_DELETE_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_COMMENT_ERROR:
        return {
          loading: false,
          error: null,
        };
      case AUTHOR_REPLY_DELETE_STATE:
        return {
          loading: false,
          error: null,
          replyDelete: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL blogs
  export const getBlogsReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_BLOGS_REQUEST:
        return {
          loading: true,
          blogs: {},
        };
      case GET_BLOGS_SUCCESS:
        return {
          loading: false,
          blogs: action.payload,
        };
      case GET_BLOGS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogs: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL BATCH BY ID
  export const getBlogByIdReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_BLOG_BY_ID_REQUEST:
        return {
          loading: true,
          blogDetails: {},
        };
      case GET_BLOG_BY_ID_SUCCESS:
        return {
          loading: false,
          blogDetails: action.payload,
        };
      case GET_BLOG_BY_ID_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogDetails: {},
        };
  
      default:
        return state;
    }
  };
  
  // DELETE BATCH BY ADMIN
  export const deleteBlogByAdminReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_BLOG_REQUEST:
        return {
          loading: true,
          blogDelete: {},
        };
      case DELETE_BLOG_SUCCESS:
        return {
          loading: false,
          blogDelete: action.payload,
        };
      case DELETE_BLOG_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
      case CLEAR_DELETE_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogDelete: {},
        };
  
      default:
        return state;
    }
  };
  