import React, { Fragment, useEffect, useState } from "react";
import "../styles/forgotPassword.scss";
import CustomHelmet from "../components/reusable/Helmet";
import Loader from "../components/reusable/Loader";
import { useNavigate } from "react-router-dom";
import CustomInput from "../components/reusable/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearUpdateState,
  requestForgotPassword,
} from "../redux/actions/userAction";
import { forgotPassword } from "../assets/images";
import { useNotification } from "../context/notificationContext";

const ForgotPassword = () => {
  const [, , showNotification] = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  // FORGOT PASSWORD REQUEST START
  // FORGOT PASSWORD REQUEST START
  const { loading, data, error } = useSelector((state) => state.forgotPassword);

  const handleForgotPassword = () => {
    if (!email) {
      showNotification("warning", "Please provide your registered email id!");
    } else {
      dispatch(requestForgotPassword(email));
    }
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors);
    }

    if (data?.success) {
      showNotification("success", data?.message);
      dispatch(clearUpdateState());
    }
  }, [data?.message, data?.success, dispatch, error, showNotification]);

  // FORGOT PASSWORD REQUEST ENDS
  // FORGOT PASSWORD REQUEST ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CustomHelmet
            keywords="Forgot Password Alpha Regiment"
            description="Welcome again, Alpha Soldier, let's complete today's stuff so that tomorrow we will be free to serve the motherland."
            author="Team Alpha Regiment"
            title="Forgot Password || Alpha Regiment"
          />
          <section className="forgotPassword_main">
            <div className="container">
              <div className="forgotPassword_form">
                <div className="login_form_inputs">
                  <div className="mobileTitle">
                    <h1>Forgot Password</h1>
                  </div>
                  <div className="login_logo">
                    <img src={forgotPassword} alt="forgotPassword" />
                  </div>
                  <div className="form">
                    <div className="title">
                      <h1>Forgot Password</h1>
                    </div>
                    <CustomInput
                      label="Your Registered Mail Id"
                      placeholder="Registered Mail Id"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      impStar="*"
                    />

                    <button
                      className="login_submit_btn"
                      onClick={handleForgotPassword}
                    >
                      Forgot Password
                    </button>
                    <button
                      className="go_home_btn"
                      onClick={() => navigate("/")}
                    >
                      Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Fragment>
  );
};

export default ForgotPassword;
