import React, { Fragment, useEffect, useState } from "react";
import "../../styles/studentPayments.scss";
import { courseSidebarLinks } from "./courseSidebarLinks";
import StudentLayout from "../../components/student/StudentLayout";
import StudentFooter from "../../components/student/StudentFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPaymentErrors,
  getAllPayments,
  getSelfPaymentDetails,
  updateUserPayment,
} from "../../redux/actions/instaMojoAction";
import Loader from "../../components/reusable/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import ButtonLoader from "../../components/reusable/ButtonLoader";

const StudentPayments = () => {
  const dispatch = useDispatch();
  const [paymentId, setPaymentId] = useState("");
  // GET ALL PAYMENTS DONE BY SELF USER START
  // GET ALL PAYMENTS DONE BY SELF USER START
  const { loading, allPayments, error } = useSelector(
    (state) => state.allPaymentsDoneByUser
  );

  const allPaymentsParam = allPayments?.paymentDetails;

  useEffect(() => {
    dispatch(getAllPayments());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearPaymentErrors());
    }
  }, [error, dispatch]);
  // GET ALL PAYMENTS DONE BY SELF USER ENDS
  // GET ALL PAYMENTS DONE BY SELF USER ENDS

  // GET PAYMENT MORE DETAILS START
  // GET PAYMENT MORE DETAILS START
  const {
    loading: paymentDetailsLoading,
    paymentDetails,
    error: paymentDetailsError,
  } = useSelector((state) => state.paymentDetails);

  const handlePaymentDetails = (paymentReqId, payment_id) => {
    setPaymentId(payment_id);
    dispatch(getSelfPaymentDetails(paymentReqId, payment_id));
  };

  // console.log(paymentDetails?.payment_request?.payment?.status);

  useEffect(() => {
    if (paymentDetailsError) {
      toast.error(paymentDetailsError);
      dispatch(clearPaymentErrors());
    }
    if (paymentDetails?.success) {
      if (paymentDetails?.payment_request?.payment?.status === "Credit") {
        dispatch(updateUserPayment(paymentId));
        alert(paymentDetails?.payment_request?.payment?.status);
      }
    }
  }, [
    paymentDetailsError,
    dispatch,
    paymentDetails?.success,
    paymentDetails?.payment_request?.payment?.status,
    paymentId,
  ]);

  // GET PAYMENT MORE DETAILS ENDS
  // GET PAYMENT MORE DETAILS ENDS

  // UPDATE PAYMENT DONE DATA BY USER STRAT
  // UPDATE PAYMENT DONE DATA BY USER STRAT
  const {
    loading: updatedPaymentDataLoading,
    updatedPaymentData,
    error: updatedPaymentDataError,
  } = useSelector((state) => state.updateUserPaymentReducer);

  // console.log("updatedPaymentDataLoading " + updatedPaymentDataLoading);
  // console.log(updatedPaymentData);

  useEffect(() => {
    if (updatedPaymentDataError) {
      toast.error(updatedPaymentDataError);
      dispatch(clearPaymentErrors());
    }
  }, [updatedPaymentDataError, dispatch]);
  // UPDATE PAYMENT DONE DATA BY USER ENDS
  // UPDATE PAYMENT DONE DATA BY USER ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <StudentLayout sidebarLinks={courseSidebarLinks}>
            <div className="all_payments_main txt_color">
              <div className="title">
                <h2>All Payments Done By You</h2>
              </div>
              {!allPaymentsParam ? (
                <div className="no_payments_sec">
                  There is no payments done by you!
                </div>
              ) : (
                <div className="payment_table custonScroll">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Payment Id</th>
                        <th>Purpose</th>
                        <th>Payment Status</th>
                        <th>Payment Date & Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPaymentsParam?.map((item) => {
                        const dateString = moment(item?.payment_date);
                        const formattedPaymentDate =
                          dateString.format("DD-MMM-YYYY");
                        const formattedPaymentTime =
                          dateString.format("HH:mm:ss");
                        return (
                          <tr key={item._id}>
                            <td>
                              <p>{item?.payment_id}</p>
                            </td>
                            <td>
                              <p>{item?.for_course}</p>
                            </td>
                            <td>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    item?.payment_status === "Credit"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {item?.payment_status === "Credit"
                                  ? "Completed"
                                  : "Failed"}
                              </p>
                            </td>
                            <td>
                              <p>
                                {formattedPaymentDate +
                                  " " +
                                  formattedPaymentTime}
                              </p>
                            </td>
                            <td>
                              {item?.is_payment_valid ? (
                                <p
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  Verified!
                                </p>
                              ) : item?.payment_status !== "Credit" ? (
                                <p style={{ color: "red", fontWeight: "bold" }}>
                                  No Payments!
                                </p>
                              ) : (
                                <p>
                                  <button
                                    className="more_payment_details"
                                    onClick={() =>
                                      handlePaymentDetails(
                                        item?.payment_request_id,
                                        item?.payment_id
                                      )
                                    }
                                    disabled={
                                      paymentDetailsLoading ? true : false
                                    }
                                  >
                                    {paymentDetailsLoading ? (
                                      <ButtonLoader />
                                    ) : (
                                      "Verify Payment"
                                    )}
                                  </button>
                                </p>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </StudentLayout>
          {/* FOOTER START */}
          <StudentFooter />
          {/* FOOTER ENDS */}
        </>
      )}
    </Fragment>
  );
};

export default StudentPayments;
