import React, { Fragment, useEffect, useState } from "react";
import "../../styles/affiliateAnalytics.scss";
import Loader from "../../components/reusable/Loader";
import AffiliateLayout from "./AffiliateLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { affiliateSidebarLinks } from "./affiliateSidebarLinks";
import { AiOutlineMail } from "react-icons/ai";
import { FaRupeeSign } from "react-icons/fa";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ArcElement,
} from "chart.js";
import { defaultUser } from "../../assets/images";
import CustomInput from "../../components/reusable/CustomInput";
import CustomButton from "../../components/reusable/CustomButton";
import PerformanceCard from "../../components/student/PerformanceCard";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getAffiliateDashboard,
  getEnrolledUsersByAffiliate,
} from "../../redux/actions/userAction";
import { toast } from "react-toastify";
import { useAuth } from "../../context/userContext";
import { Link } from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip);

const AffiliateAnalytics = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();

  // console.log(auth?.user);

  // GET AFFILIATE DASHBOARD DATA START
  // GET AFFILIATE DASHBOARD DATA START
  const { loading, affiliateDashboard, error } = useSelector(
    (state) => state.affiliateDashboardData
  );

  const affiliateDashboardParam = affiliateDashboard?.dashboardData;

  const remainingRevenue =
    affiliateDashboardParam?.totalRevenue -
    affiliateDashboardParam?.deductedRevenue;

  useEffect(() => {
    dispatch(getAffiliateDashboard());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [
    error,
    dispatch,
    affiliateDashboard?.success,
    affiliateDashboard?.message,
  ]);
  // GET AFFILIATE DASHBOARD DATA ENDS
  // GET AFFILIATE DASHBOARD DATA ENDS

  //   const labels = Utils.months({ count: 12 });
  const barChartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          affiliateDashboardParam?.totalRevenue,
          0,
          0,
          0,
          0,
          0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // DOUGHNUT STATE DATA
  const doughnutState = {
    labels: ["Finally Enrolled", "Only Clicked"],
    datasets: [
      {
        backgroundColor: ["#44DA5C", "#f2f2f2"],
        hoverBackgroundColor: ["#329E43", "#C7C7C7"],
        data: [40, 100],
      },
    ],
  };

  const [withdrawAmount, setWithdrawAmount] = useState();

  const handleWithdrawalSubmit = () => {
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Email sent through Affiliate Withdrawal Form",
      Body:
        "<h1>This Email sent through Affiliate Withdrawal Form</h1> <br/> <p>Full Name:" +
        auth?.user?.first_name +
        "</P> <p><b>Mobile Number:</b> " +
        auth?.user?.phone +
        "</p> <p><b>Email Id:</b> " +
        auth?.user?.email +
        "</p> <p><b>Bank Account Number:</b> " +
        auth?.user?.aff_bank_acc_no +
        "</p> <p><b>Bank Branch:</b> " +
        auth?.user?.aff_bank_branch +
        "</p><p><b>Bank Name:</b> " +
        auth?.user?.aff_bank_name +
        "</p><p><b>Banking Mobile Number:</b> " +
        auth?.user?.aff_bank_reg_mob +
        "</p><p><b>UPI Id:</b> " +
        auth?.user?.aff_upi_id +
        "</p><p><b>Withdrawal Amount:</b> " +
        "₹" +
        withdrawAmount +
        "</p>",
    };

    if (withdrawAmount && withdrawAmount <= remainingRevenue) {
      if (withdrawAmount >= 2000) {
        if (window.Email) {
          window.Email.send(config).then(() => {
            toast.success("Withdrawal Request Sent Successfully.");
            setWithdrawAmount("");
          });
        }
      } else {
        toast.error("Minimum ₹2000 will deduct.");
      }
    } else {
      toast.error("Please enter a valid amount.");
    }
  };

  // GET ALL ENROLLED USERS BY AFFILIATE START
  // GET ALL ENROLLED USERS BY AFFILIATE START
  const {
    loading: enrolledUsersLoading,
    enrolledUsers,
    error: enrolledUsersError,
  } = useSelector((state) => state.getEnrolledUsers);

  const enrolledUsersParam = enrolledUsers?.enrolledUsers;

  useEffect(() => {
    dispatch(getEnrolledUsersByAffiliate());
  }, [dispatch]);

  useEffect(() => {
    if (enrolledUsersError) {
      toast.error(enrolledUsersError);
      dispatch(clearErrors());
    }
  }, [dispatch, enrolledUsersError]);
  // GET ALL ENROLLED USERS BY AFFILIATE ENDS
  // GET ALL ENROLLED USERS BY AFFILIATE ENDS
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AffiliateLayout>
          <ProfileLayout sidebarLinks={affiliateSidebarLinks}>
            <section className="affiliate_analytics_main txt_color">
              <div className="title">
                <h2>Analytics and payments</h2>
              </div>

              <div className="rev_students_stats">
                <div className="revenue_sec">
                  <h2 className="revCard_heading">Total Revenue Stats</h2>
                  <p className="total_revCard_rate">
                    {affiliateDashboardParam?.totalRevenue} INR
                  </p>
                  <div className="revenue_bar_chart">
                    <Bar data={barChartData} />
                  </div>
                  {/* <div className="color_range">
                    <p className="low">
                      <span className="red"></span>
                      <span>Low</span>
                    </p>
                    <p className="moderate">
                      <span className="blue"></span>
                      <span>Moderate</span>
                    </p>
                    <p className="high">
                      <span className="green"></span>
                      <span>High</span>
                    </p>
                  </div> */}
                </div>
                <div className="enrolled_click_sec">
                  <div className="total_clicks">
                    <h2>Total Clicks</h2>
                    <p>
                      {affiliateDashboardParam?.totalClicks}{" "}
                      <span>last month </span>
                    </p>
                    <div className="dognut_chart">
                      <Doughnut data={doughnutState} />
                    </div>
                  </div>
                  <div className="enrolled_students">
                    <h2>Students Enrolled</h2>
                    <p>
                      {enrolledUsersParam?.length} <span>last month</span>
                    </p>
                    <div className="students_list">
                      <h2>Recent Enrollments</h2>
                      <div className="recent_students">
                        {enrolledUsersParam?.map((item, idx) => (
                          <div className="single_student_row" key={idx}>
                            <div>
                              <span>
                                <img src={defaultUser} alt="defaultUser" />
                              </span>
                              <span>{item?.userName}</span>
                            </div>
                            <Link
                              className="mail_btn"
                              to={`mailto:${item?.userEmail}`}
                            >
                              <AiOutlineMail />
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="balance_course_stats">
                <div className="balance_withdraw_stat">
                  <div className="balance">
                    <h2>Balance</h2>
                    <p>
                      {affiliateDashboardParam?.totalRevenue -
                        affiliateDashboardParam?.deductedRevenue}{" "}
                      INR
                    </p>
                  </div>
                  <div className="withdraw">
                    <h2>Withdraw</h2>

                    <CustomInput
                      icon={<FaRupeeSign />}
                      type="number"
                      onChange={(e) => setWithdrawAmount(e.target.value)}
                      name="withdrawAmount"
                      value={withdrawAmount}
                      placeholder="Enter amount"
                    />
                    {/* <p className="bank_detail_alert">
                      You have not entered your bank details!
                    </p> */}
                    <CustomButton
                      className="payment_btn"
                      title="Withdraw Request"
                      onClick={handleWithdrawalSubmit}
                      disabled={false}
                    />
                  </div>
                </div>
                {/* <div className="rev_course_stat">
                  <h2>Revenue Stats by courses</h2>
                  <p>Revenue percentage in accordance with courses</p>
                  {[
                    { title: "NDA", range: "10" },
                    { title: "SSB Interview", range: "30.14" },
                    { title: "Agniveer + SSB", range: "50.75" },
                    { title: "CDS", range: "20.32" },
                    { title: "UPSC", range: "70.32" },
                  ].map((item, idx) => (
                    <div className="revenueByCourse_sec" key={idx}>
                      <PerformanceCard
                        title={item.title}
                        chartLine={true}
                        range={`${item.range}%`}
                      />
                    </div>
                  ))}
                </div> */}
              </div>
            </section>
          </ProfileLayout>
        </AffiliateLayout>
      )}
    </Fragment>
  );
};

export default AffiliateAnalytics;
