import React from "react";
import "../styles/aboutUs.scss";
import "../styles/guruSwiper.scss";
import Layout from "../components/reusable/Layout";
import {
  facebookIcon,
  instaIcon,
  logo_mobile,
  mailIcon,
  twitterIcon,
} from "../assets/images";
import { Swiper } from "swiper/react";
import { Autoplay, EffectCards, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { FaEnvelope, FaFacebookF, FaTwitter } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { meetTeamArray } from "../dummyContent";
// import EducatorCard from "../components/about/EducatorCard";
import CustomHelmet from "../components/reusable/Helmet";
import OurGuru from "../components/home/OurGuru";
import { useLocale } from "../context/locale";

const AboutUs = () => {
  const [t] = useLocale();
  return (
    <Layout>
      <CustomHelmet
        keywords="About us Alpha Regiment"
        description="Alpha Regiment is a platform uniting aspirants, retired officers, teachers, physical trainers, and retired military doctors, providing the best resource for defense preparation."
        author="Team Alpha Regiment"
        title="Alpha Regiment: About Us."
        canonical={window?.location?.href}
      />
      <section className="about_main txt_color">
        {/* ABOUT HERO SECTION START */}
        <div className="abt_hero_sec">
          <div className="glass_bg"></div>
          <div className="hero_heading">
            <h1>Alpha Regiment Turns Aspirants into Warriors</h1>
          </div>

          <img className="hero_logo" src={logo_mobile} alt="logo_mobile" />
        </div>
        {/* ABOUT HERO SECTION ENDS */}

        {/* WHY WE DO WHAT WE DO SECTION START */}
        <div className="why_do_section">
          <div className="container">
            <div className="title">
              <h2>WHY WE DO WHAT WE DO</h2>
            </div>
            <p className="content">
              At Alpha Regiment, we are committed to fulfilling the dreams of
              children who envision themselves as soldiers marching in a parade
              or heroes in a military movie. We believe that every child has the
              potential to serve their motherland and we strive to help them
              achieve this goal by providing them with the necessary support to
              get accepted into their desired military academy. We are dedicated
              to going above and beyond to make this dream a reality for them,
              and we will spare no effort in doing so. Jai Hind!
            </p>
          </div>
        </div>
        {/* WHY WE DO WHAT WE DO SECTION ENDS */}

        {/* Meet YOUR GURUS START */}
        {/* <section className="meet_guru_main">
          <div className="container">
            <div className="title">
              <h2>{t("meetMentorHeading")}</h2>
            </div>
          </div>
          <OurGuru />
        </section> */}
        {/* Meet YOUR GURUS ENDS */}

        {/* MEET THE TEAM RESPONSIBLE FOR THIS CHANGE SECTION START */}
        <div className="meet_team">
          <div className="container">
            <div className="title">
              <h2>MEET THE TEAM RESPONSIBLE FOR THIS CHANGE</h2>
            </div>

            <div className="meet_team_content">
              <Swiper
                effect={"cards"}
                grabCursor={true}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                centeredSlides={true}
                slidesPerView={"auto"}
                // coverflowEffect={{
                //   rotate: 50,
                //   stretch: 0,
                //   depth: 100,
                //   modifier: 1,
                //   slideShadows: true,
                // }}
                pagination={true}
                loop={true}
                loopPreventsSliding={true}
                modules={[Autoplay, EffectCards, Pagination]}
                className="guru_swiper"
              >
                {meetTeamArray?.map((item, idx) => (
                  <SwiperSlide key={idx}>
                    <div className="myCard">
                      <div className="img_sec">
                        <img
                          src={item.img}
                          alt="guru_pic"
                          className="main_img"
                        />
                      </div>
                      <div className="content_card">
                        <div className="profile_abt">
                          <div className="pro_pic">
                            <img src={item.img} alt="guru_pic" />
                          </div>
                          <div className="guru_abt">
                            <h2>{item.name}</h2>
                            <p>{item.type}</p>
                            <div className="social_icons">
                              {item.facebook && (
                                <Link
                                  target="_blank"
                                  className="facebook"
                                  to={item.facebook}
                                >
                                  <FaFacebookF />
                                </Link>
                              )}
                              {item.twitter && (
                                <Link
                                  target="_blank"
                                  className="twitter"
                                  to={item.twitter}
                                >
                                  <FaTwitter />
                                </Link>
                              )}
                              {item.gmail && (
                                <Link
                                  target="_blank"
                                  className="gmail"
                                  to={`mailto:${item.gmail}`}
                                >
                                  <FaEnvelope />
                                </Link>
                              )}
                              {item.whatsapp && (
                                <Link
                                  target="_blank"
                                  className="whatsapp"
                                  to={`https://wa.me/${item.whatsapp}`}
                                >
                                  <IoLogoWhatsapp />
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <p className="guru_txt">{item.text}</p> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        {/* MEET THE TEAM RESPONSIBLE FOR THIS CHANGE SECTION ENDS */}

        {/* OUR TEAM OF EDUCATORS SECTION START */}
        {/* <div className="edu_team_main">
          <div className="container">
            <div className="title">
              <h2>Our team of educators</h2>
            </div>

            <div className="educator_team_content">
              {eductorsTeam?.map((item) => {
                return (
                  <EducatorCard
                    key={item.id}
                    img={item.image}
                    name={item.name}
                    education={item.qualification}
                  />
                );
              })}
            </div>
          </div>
        </div> */}
        {/* OUR TEAM OF EDUCATORS SECTION ENDS */}

        {/* CONNECT WITH US START */}
        <div className="connect_us_main">
          <div className="container">
            <div className="title">
              <h2>Connect with us</h2>
            </div>
            <div className="connect_us_content">
              <Link
                title="Twitter"
                target="_blank"
                className="twitterIcon"
                to="https://twitter.com/AlphaRegiment"
              >
                <img src={twitterIcon} alt="twitterIcon" />
              </Link>
              <Link
                title="Facebook"
                target="_blank"
                className="facebookIcon"
                to="https://facebook.com/AlphaRegimentOfficial"
              >
                <img src={facebookIcon} alt="facebookIcon" />
              </Link>
              <Link
                title="Email"
                target="_blank"
                className="mailIcon"
                to="mailto:hello@alpharegiment.com"
              >
                <img src={mailIcon} alt="mailIcon" />
              </Link>
              <Link
                title="Instagram"
                target="_blank"
                className="instaIcon"
                to="https://www.instagram.com/AlphaRegimentOfficial/"
              >
                <img src={instaIcon} alt="instaIcon" />
              </Link>
            </div>
          </div>
        </div>
        {/* CONNECT WITH US ENDS */}
      </section>
    </Layout>
  );
};

export default AboutUs;
