import React, { useState } from "react";
import "../../styles/termCondition.scss";
import { Link, useNavigate, NavLink } from "react-router-dom";
// import { logo_main } from "../assets/images";
import logo from "../../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../../components/home/pageLinks";
import CustomHelmet from "../../components/reusable/Helmet";
const OfficerTerms = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  return (

    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Term and Condition Alpha Regiment"
        description="Retired officers who are DIPR trained and have served on various selection boards can join us. Please read the terms and conditions before creating your course on Alpha Regiment."
        author="Team Alpha Regiment"
        title="Officer's Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>Officer Terms</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${showSideNav ? "showProfile_sidebar" : ""
              }`}
          >
            <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button>
            <div className="sidebar_content">

              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>Terms and Conditions for Officers on Alpha Regiment</h1>
                <p>Last updated: October 05, 2023</p>
              </div>
              <div className="privacy_text">
                <h2 className="sub_heading">1. Introduction</h2>
                <p>
                  These Terms and Conditions (hereinafter referred to as "Terms")
                  outline the agreement between <b>Alpha Regiment</b>, [a Subsidiary
                  of <b>Arcaptor Technologies Private Limited</b>] an online and
                  offline <b>SSB/AFSB/NSB/FSB</b> Interview preparationplatform
                  (hereinafter referred to as "Alpha Regiment," "we," or "our"), and
                  the retired Interviewing Officers(IOs),Group Testing
                  Officers(GTOs), and Psychologists(hereinafter referred to as
                  <b> "Officers"</b>) who register and offer theirservices on our
                  platform. By registeringand participating as an Officer on Alpha
                  Regiment, you agree to abide by these Terms.
                </p>

                <h2 className="sub_heading">2. Registration and Eligibility</h2>

                <h2 className="child_heading">
                  2.1. To register as an Officer on Alpha Regiment, you must meet
                  the following eligibility criteria:
                </h2>
                <ul>
                  <li>
                    Be a retired Interviewing Officer (IO), Group Testing Officer
                    (GTO), or Psychologist with relevant expertise.
                  </li>
                  <li>
                    Possess the necessary qualifications and certifications required
                    for yourspecific role
                  </li>
                  <li>
                    Provide accurate and up-to-date information during the
                    registration process.
                  </li>
                </ul>

                <h2 className="sub_heading">3. Fee Structure and Payment</h2>
                <h2 className="child_heading">
                  3.1. Alpha Regiment functions as a marketplace for SSB aspirants.
                  Upon a student's enrolment, the corresponding fee amount will be
                  instantly credited to the Officer's account. This amount will be
                  visible in the Officer's dashboard after deducting the platform
                  fees. Officers have the flexibility to withdraw the earned amount
                  to their bank account at any time. Please note that it may take up
                  to 48 working hours to reflect the withdrawn amount in the
                  Officer's bank account after a withdrawal request on our platform.
                </h2>

                <h2 className="child_heading">3.2. Commission Rates:</h2>
                <ul>
                  <li>
                    For online mock and coaching/guidance services, the commission
                    rate is 25% of the total fees collected from students.
                  </li>
                  <li>
                    For offline services, if Officers use their own house or office
                    to conduct classes or organize mock interviews, the commission
                    rate remains at 25%.
                  </li>
                  <li>
                    {" "}
                    Group Testing Officers(GTOs) cannot conduct mocks or coaching in
                    their own premises, as it necessitates proper grounds. They are
                    required to utilize our nearest facilities. In such cases, the
                    platform fees increase to 40% of the total course or mock fees.
                    This includes the helper facility provided by Alpha Regiment to
                    assist Officers during their classes or mock sessions when
                    utilizing our offline facilities.
                  </li>
                  <li>
                    In addition, Interviewing Officers (IOs) and Psychologists also
                    have the option to utilize our offline facility for their
                    services. In such cases, the platform fees will be 40 percent of
                    the total course fees. This fee structure ensures consistency
                    across all Officers using Alpha Regiment's offline facilities.
                  </li>
                </ul>

                <h2 className="child_heading">
                  3.3. Collaboration with Other Officers:
                </h2>
                <ul>
                  <li>
                     In the event that an Officer wishes to launch a course in
                    collaboration with another Officer, the fees collected will be
                    distributed in the same ratio as determined by them during the
                    course creation process.
                  </li>
                  <li>
                    It's important to note that the fee distribution ratio agreed
                    upon during course creation cannot be edited or modified at a
                    later stage.
                  </li>
                </ul>

                <h2 className="sub_heading">4. Services Offered by Officers</h2>
                <h2 className="child_heading">
                  4.1. As an Officer on Alpha Regiment, you have the flexibility to
                  choose your preferred date and time for offering services,
                  aligning with yourschedule and availability.
                </h2>
                <h2 className="child_heading">
                  4.2. To ensure the integrity and professionalism of yourservices,
                  please adhere to the following guidelines:
                </h2>
                <ul>
                  <li>
                    You will not associate part-time or full-time with any other
                    institute or platform offering SSB preparation services.
                  </li>
                  <li>
                    You will refrain from approaching students to upsell any
                    services outside of Alpha Regiment's platform.
                  </li>
                  <li>
                    You will maintain strict confidentiality and will not share any
                    student's details with third parties
                  </li>
                  <li>
                    You are prohibited from sharing fellow Officers' details with
                    anyone, ensuring their privacy and security.
                  </li>
                  <li>
                    You commit to giving your utmost effort to assist students in
                    their pursuit of recommendations.
                  </li>
                  <li>
                    It is not permissible to use Alpha Regiment's branding at your
                    physical location, even if you conduct offline interviews and
                    psychology tests.
                  </li>
                </ul>
                <p>
                  These guidelines help maintain the professionalism, privacy, and
                  exclusivity of Alpha Regiment's platform, ensuring a positive
                  experience for all users.
                </p>

                <h2 className="sub_heading">5. Intellectual Property</h2>
                <p>
                  Any content, materials, or resources created and shared by
                  Officers on Alpha Regiment are subject to the platform's
                  intellectual property policies. You grant Alpha Regiment a
                  nonexclusive license to use, display, and distribute such
                  materials for the benefit of our users.
                </p>

                <h2 className="sub_heading">6. Termination</h2>
                <p>
                  Alpha Regiment reserves the right to terminate the partnership
                  with an Officer at any time and for any reason, including but not
                  limited to violations of these Terms, unprofessional conduct, or
                  any other actions that may harm the reputation or integrity of the
                  platform
                </p>

                <div className="sub_heading">7. Dispute Resolution</div>
                <p>
                  In the event of disputes or disagreements between Alpha Regiment
                  and an Officer, both parties agree to engage in good faith
                  negotiations to resolve the issue amicably.
                </p>

                <div className="sub_heading">8. Amendments</div>
                <p>
                  Alpha Regiment reserves the right to amend these Terms at its
                  discretion. Any changes will be communicated to Officers in a
                  timely manner.
                </p>

                <div className="sub_heading">9. Governing Law</div>
                <ul>
                  <li>
                    These Terms shall be governed by and construed and enforced in
                    accordance with the laws of India. Subject to other provisions
                    in this Clause, courts in Haryana and / or Rajasthan shall have
                    exclusive jurisdiction over all issues arising out of these
                    Terms or the use of the Services.
                  </li>
                  <li>
                    Any controversies, conflicts, disputes, or differences arising
                    out of these Terms shall be resolved by arbitration in Haryana
                    and / or Rajasthan in accordance with the Arbitration and
                    Conciliation Act, 1996 for the time being in force, which is
                    deemed to be incorporated by reference in this Clause. The
                    tribunal shall consist of 1 (one) arbitrator appointed by the
                    Company. The language of the arbitration shall be English.
                  </li>
                  <li>
                    The parties to the arbitration shall keep the arbitration
                    confidential and not disclose to any person, other than on a
                    need to basis or to legal advisors, unless required to do so by
                    law. The decision of the arbitrator shall be final and binding
                    on all the Parties hereto.
                  </li>
                  <li>
                    Each party to the arbitration shall bear its own costs with
                    respect to any dispute.
                  </li>
                </ul>
                <p>
                  By registering as an Officer on Alpha Regiment, you acknowledge
                  that you have read, understood, and agreed to these Terms and
                  Conditions. If you do not agree with any of the terms outlined
                  herein, you should not proceed with registration or participation
                  on the platform.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default OfficerTerms;
