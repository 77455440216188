export const RAZOR_PAYMENT_REQUEST = "RAZOR_PAYMENT_REQUEST";
export const RAZOR_PAYMENT_SUCCESS = "RAZOR_PAYMENT_SUCCESS";
export const RAZOR_PAYMENT_FAIL = "RAZOR_PAYMENT_FAIL";

export const RAZOR_KEY_REQUEST = "RAZOR_KEY_REQUEST";
export const RAZOR_KEY_SUCCESS = "RAZOR_KEY_SUCCESS";
export const RAZOR_KEY_FAIL = "RAZOR_KEY_FAIL";

export const RAZOR_PAYMENT_VERIFY_REQUEST = "RAZOR_PAYMENT_VERIFY_REQUEST";
export const RAZOR_PAYMENT_VERIFY_SUCCESS = "RAZOR_PAYMENT_VERIFY_SUCCESS";
export const RAZOR_PAYMENT_VERIFY_FAIL = "RAZOR_PAYMENT_VERIFY_FAIL";

export const CLEAR_RAZOR_ERROR = "CLEAR_RAZOR_ERROR";
export const CLEAR_RAZOR_STATE = "CLEAR_RAZOR_STATE";
