import React, { Fragment, useEffect } from "react";
import "../../styles/OfficerCourseReviews.scss";
import OfficerLayout from "./OfficerLayout";
import Loader from "../../components/reusable/Loader";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import { MdFilterList } from "react-icons/md";
import CourseRevCard from "./CourseRevCard";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import { useSelector } from "react-redux";
import {
  getOfficerSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import OfficerNoDataCard from "./OfficerNoDataCard";
import { reviewNoFound } from "../../assets/images";

const OfficerCourseReviews = () => {
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const {
    loading,
    error,
    courses: data,
  } = useSelector((state) => state.officerSsbCourses);

  // const { loading, error, courses: data } = officerSsbCourses;

  useEffect(() => {
    dispatch(getOfficerSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, error, showNotification]);

  const reviewList = data.courses;

  const reviewsArray = reviewList?.map((item) => item.reviews);

  const reviewCount = reviewsArray?.flat();

  // console.log(reviewList);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <div className="txt_color officer_course_review">
              <div className="title">
                <h2>Course Reviews</h2>
                <button title="Filter reviews">
                  <MdFilterList />
                </button>
              </div>

              {reviewCount < 1 ? (
                <OfficerNoDataCard
                  image={reviewNoFound}
                  txt="Please request your students to evaluate your content. This contributes to your ranking and aids in attracting more students."
                />
              ) : (
                <div className="reviewListing_main">
                  {reviewList?.map((item, idx) =>
                    item.reviews?.map((ele) => (
                      <CourseRevCard
                        key={ele?._id}
                        data={ele}
                        createBy={item}
                      />
                    ))
                  )}
                </div>
              )}
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OfficerCourseReviews;
