import React, { useState } from "react";
import "../styles/termCondition.scss";
import { Link, useNavigate, NavLink } from "react-router-dom";
// import { logo_main } from "../assets/images";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomHelmet from "../components/reusable/Helmet";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";
const TeacherTerms = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Teacher Term and Condition Alpha Regiment"
        description="Expert teachers looking to partner with us and launch their courses on the Alpha Regiment platform should read the terms and conditions before making a final decision."
        author="Team Alpha Regiment"
        title="Teacher's Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>teacher term</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>TEACHER TERMS</h1>
                <p>
                  These Teachers’ Terms were last updated on February 10, 2023.
                </p>
              </div>
              <div className="privacy_text">
                <p>
                  Upon registration as a teacher on the Alpha Regiment platform,
                  you hereby consent to adhere to the stipulations delineated in
                  these Teachers' Terms ("Terms"). These Terms delineate the
                  pertinent facets of the Alpha Regiment platform relevant to
                  educators and are hereby incorporated by reference into our
                  overarching Terms of Use, which govern the utilization of our
                  Services. Any capitalized terms unspecified herein shall be
                  construed in accordance with the definitions provided in the
                  Terms of Use. It is imperative to note that, as a teacher, you
                  are entering into a contractual agreement directly with Alpha
                  Regiment (Arcaptor Technologies Private Limited), irrespective
                  of whether another Alpha Regiment subsidiary administers
                  payments to you.
                </p>
                <div className="teacher_terms">
                  <ul>
                    <li>
                      <h2 className="heading">1. Teachers’ Obligations</h2>
                      <p>
                        As a teacher, you bear full responsibility for all
                        content you contribute, encompassing lectures, quizzes,
                        assignments, and related materials ("Submitted
                        Content"). By utilizing our platform, you affirm and
                        assure the following:
                      </p>
                      <div className="sub_term">
                        <p>
                          <strong>Accuracy of Information:</strong> You commit
                          to providing and maintaining accurate account details
                          throughout your engagement with our platform.
                        </p>
                        <p>
                          <strong>Ownership and Authorization:</strong> You
                          assert ownership or possess requisite licenses,
                          rights, consents, and permissions to authorize Alpha
                          Regiment's use of your Submitted Content in accordance
                          with our Terms and Terms of Use.
                        </p>
                        <p>
                          <strong>Intellectual Property Compliance:</strong>{" "}
                          Your Submitted Content will not infringe upon or
                          misappropriate any third party's intellectual property
                          rights.
                        </p>
                        <p>
                          <strong>Qualifications and Expertise:</strong> You
                          possess the necessary qualifications, credentials, and
                          expertise to deliver the instructional services
                          offered through your Submitted Content, aligning with
                          industry standards.
                        </p>
                        <p>
                          <strong>Quality of Service:</strong> You will ensure
                          that the quality of service provided meets the
                          standards expected within your field of expertise and
                          instructional services at large.
                        </p>
                        <br />
                        <br />
                        <span>
                          Furthermore, you warrant that you will not engage in
                          the following prohibited activities:
                        </span>
                        <br />
                        <br />
                        <p>
                          Posting or providing inappropriate, offensive,
                          discriminatory, or defamatory content.
                        </p>
                        <p>
                          Transmitting unauthorized advertising, promotional
                          materials, or solicitation through the platform.
                        </p>
                        <p>
                          Using the platform for purposes unrelated to tutoring,
                          teaching, or instructional services.
                        </p>
                        <p>
                          Engaging in activities requiring third-party licenses
                          or royalties without appropriate authorization.
                        </p>
                        <p>
                          Circumventing platform functionalities or interfering
                          with other teachers' services.
                        </p>
                        <p>
                          Impersonating others or gaining unauthorized access to
                          user accounts.
                        </p>
                        <p>
                          Abusing Alpha Regiment resources or support services
                          provided.
                        </p>
                        <br />
                        <br />
                        <span>
                          Failure to adhere to these obligations may result in
                          disciplinary action, including account suspension or
                          termination, as outlined in our Terms of Use.
                        </span>
                        <br />
                        <br />
                      </div>
                    </li>
                    <li>
                      <h2 className="heading">2. License to Alpha Regiment</h2>
                      <p>
                        By uploading or submitting any content ("Submitted
                        Content") to Alpha Regiment, you hereby grant Alpha
                        Regiment a non-exclusive, worldwide, royalty-free
                        license to use, reproduce, modify, adapt, publish,
                        translate, distribute, and display such Submitted
                        Content in connection with the operation of Alpha
                        Regiment's services. This license includes the right for
                        Alpha Regiment to sublicense your Submitted Content to
                        third parties, including students directly and through
                        third-party platforms.
                      </p>
                      <br />
                      <p>
                        You retain all ownership rights in your Submitted
                        Content, and you may remove or delete it from Alpha
                        Regiment's services at any time. However, Alpha
                        Regiment's sublicensing rights with respect to new users
                        will terminate 60 days after the removal of your
                        Submitted Content, except as otherwise agreed or
                        required by law.
                      </p>
                      <br />
                      <p>
                        Alpha Regiment may use all or any part of your Submitted
                        Content for quality control, marketing, promotional, and
                        operational purposes. Additionally, by submitting your
                        content, you grant Alpha Regiment permission to use your
                        name, likeness, voice, and image for marketing,
                        promotional, and operational activities related to Alpha
                        Regiment's services, without any rights of privacy,
                        publicity, or other similar rights, to the extent
                        permitted by applicable law.
                      </p>
                      <br />
                    </li>
                    <li>
                      <h2 className="heading">3. Trust & Safety</h2>
                      <h3 className="sub_heading">
                        3.1 Trust & Safety Policies :
                      </h3>
                      <p>
                        By accessing or utilizing Alpha Regiment's services, you
                        consent to adhere to our Trust & Safety policies,
                        including our Restricted Topics policy and other content
                        quality standards, as may be prescribed by Alpha
                        Regiment periodically. It is your responsibility to
                        periodically review these policies to ensure compliance
                        with any updates. You acknowledge that your usage of the
                        Services is contingent upon Alpha Regiment's discretion,
                        which may grant or deny approval at its sole discretion.
                      </p>
                      <br />
                      <p>
                        We reserve the right, without prior notice, to remove
                        content, suspend payouts, and/or prohibit instructors
                        from utilizing the platform for any reason, including
                        but not limited to situations where:
                      </p>
                      <br />
                      <div className="sub_term">
                        <p>
                          An instructor or content fails to comply with our
                          policies or legal terms, including the Terms of Use.
                        </p>
                        <p>
                          Content falls below our quality standards or adversely
                          impacts the student experience.
                        </p>
                        <p>
                          An instructor engages in behavior that could tarnish
                          Alpha Regiment's reputation or subject it to public
                          disrepute, contempt, scandal, or ridicule.
                        </p>
                        <p>
                          An instructor collaborates with a marketer or business
                          partner who contravenes Alpha Regiment's policies.
                        </p>
                        <p>
                          An instructor utilizes the Services in a manner
                          constituting unfair competition, such as promoting
                          their off-site business in violation of Alpha
                          Regiment's policies.
                        </p>
                        <br />
                        <br />
                        <span>
                          Decisions regarding such matters are made at the sole
                          discretion of Alpha Regiment.
                        </span>
                        <br />
                        <br />
                      </div>
                      <br />
                      <br />
                      <h3 className="sub_heading">
                        3.2 Co-Instructors and Teaching Assistants :
                      </h3>
                      <p>
                        As per the terms of Alpha Regiment's platform, users are
                        afforded the privilege to designate additional
                        individuals as co-instructors or teaching assistants for
                        the management of Submitted Content. It is imperative
                        that when exercising this capability, users adhere to
                        the Co-Instructor Relationship Rules and Guidelines
                        established by Alpha Regiment. By appointing a
                        co-instructor or teaching assistant, users acknowledge
                        and grant authorization for these individuals to
                        undertake actions that may impact the user's Alpha
                        Regiment account and Submitted Content.
                      </p>
                      <p>
                        It is to be noted that any violations of Alpha
                        Regiment's terms and policies by appointed
                        co-instructors or teaching assistants may have
                        repercussions on the user's Alpha Regiment account and
                        Submitted Content. Alpha Regiment does not assume
                        responsibility for adjudicating disputes or providing
                        counsel between users and their appointed individuals.
                      </p>
                      <p>
                        Furthermore, in instances where co-instructors are
                        entitled to a revenue share, said share will be deducted
                        from the user's earned revenue share in accordance with
                        the ratios specified in the user's Course Management
                        settings at the time of purchase.
                      </p>
                      <p>
                        These provisions are binding and integral components of
                        the contractual relationship between users and Alpha
                        Regiment, as governed by the platform's Terms of Use.
                      </p>
                      <br />
                      <h3 className="sub_heading">
                        3.3 Relationship to Other Users :
                      </h3>
                      <p>
                        Teachers maintain an indirect contractual association
                        with students, and thus are privy only to information
                        furnished through the Services. It is agreed that such
                        data shall solely be employed for the provision of
                        services within the Alpha Regiment platform, without
                        soliciting additional personal information or storing
                        students' data externally. The teacher undertakes to
                        indemnify Alpha Regiment against any claims arising from
                        the utilization of students' personal data."
                      </p>
                      <br />
                      <h3 className="sub_heading">
                        3.4 Anti-Piracy Measures :
                      </h3>
                      <p>
                        Alpha Regiment collaborates with anti-piracy vendors to
                        safeguard your content against unauthorized use. By
                        utilizing this protection, you hereby authorize Alpha
                        Regiment and our anti-piracy vendors to act as your
                        designated agents for enforcing copyrights pertaining to
                        your content. This includes initiating notice and
                        takedown procedures in accordance with relevant
                        copyright laws such as the Digital Millennium Copyright
                        Act and section 52 of the copyright act, as well as
                        other measures aimed at protecting your rights.
                      </p>
                      <p>
                        You acknowledge and grant Alpha Regiment and our
                        anti-piracy vendors primary authority to initiate legal
                        actions, including filing notices, on your behalf to
                        uphold your copyright interests.
                      </p>
                      <p>
                        You consent to Alpha Regiment and our anti-piracy
                        vendors retaining the aforementioned rights unless
                        explicitly revoked by sending an email to
                        takedowns@alpharegiment.com with the subject line:
                        "Revoke Anti-Piracy Protection Rights" from the email
                        address associated with your account. Any revocation of
                        rights will be deemed effective 48 hours following
                        receipt by Alpha Regiment.
                      </p>
                      <br />
                    </li>
                    <li>
                      <h2 className="heading">4. Payments</h2>
                      <h3 className="sub_heading">4.1 Revenue Share :</h3>
                      <p>
                        Upon a student's enrollment in your course, the gross
                        amount of the sale, defined as the sum received by Alpha
                        Regiment from the student ("Gross Amount"), is
                        calculated. Your entitlement to revenue share
                        constitutes 60% of the Gross Amount, subject to
                        deductions including but not limited to student refunds.
                        In the event of any adjustment to this payment rate, a
                        notification period of 30 days will be provided via
                        prominent communication channels, such as email or
                        postings within our Services, to ensure transparency and
                        compliance with contractual obligations.
                      </p>
                      <br />
                      <h3 className="sub_heading">4.2 Teacher Coupons :</h3>
                      <p>
                        The Alpha Regiment platform affords instructors the
                        capability to generate coupon codes for providing
                        discounts or free access to specific items of their
                        Submitted Content, as allowed within the Services and at
                        Alpha Regiment's prevailing rates. These coupon codes
                        are subject to predetermined limits and are strictly
                        prohibited from being sold on third-party platforms or
                        exchanged for compensation.
                      </p>
                      <br />
                      <h3 className="sub_heading">4.3 Receiving Payments :</h3>
                      <p>
                        To ensure prompt payment, it is necessary for you to
                        possess a bank account equipped with net banking. and
                        must keep us informed of the correct email associated
                        with your account. You must also provide any identifying
                        information or tax documentation (Such as PAN) necessary
                        for payment of amounts due, and you agree that we have
                        the right to withhold appropriate taxes from your
                        payments. We reserve the right to withhold payments or
                        impose other penalties if we do not receive proper
                        identifying information or tax documentation from you.
                        You understand and agree that you are ultimately
                        responsible for any taxes on your income.
                      </p>
                      <p>
                        The teacher shall have the prerogative to initiate the
                        withdrawal of any student fees upon completion of the
                        course. In cases where the course duration exceeds one
                        month, the teacher may submit a withdrawal request
                        proportionate to the monthly fees, calculated based on
                        the total amount and duration of the course.
                      </p>
                      <p>
                        Upon submitting a withdrawal request, payment will be
                        processed within 10 days.
                        <br />
                        We reserve the right not to pay out funds in the event
                        of identified fraud, violations of intellectual property
                        rights, or other violations of the law.
                      </p>
                      <br />
                      <h3 className="sub_heading">4.4 Refunds :</h3>
                      <p>
                        By utilizing our platform, you acknowledge and consent
                        to the fact that students hold the right to request a
                        refund in accordance with the provisions outlined in our
                        Refund Policy. In instances where a refund is granted,
                        teachers shall not receive any revenue from the affected
                        transactions.
                      </p>
                      <br />
                      <p>
                        In the event that a student requests a refund subsequent
                        to the disbursement of relevant instructor payments, we
                        retain the discretion to pursue one of the following
                        courses of action:
                      </p>
                      <p>
                        1. Deducting the refunded amount from the subsequent
                        payment remitted to the instructor; or
                      </p>
                      <p>
                        2. In scenarios where no further payments are owed to
                        the instructor or the payments are insufficient to cover
                        the refunded amounts, mandating the instructor to
                        reimburse any sums refunded to students for the
                        instructor's Submitted Content.
                      </p>
                      <br />
                      <p>
                        These measures are implemented to uphold the integrity
                        of our refund process and ensure equitable treatment of
                        both students and instructors.
                      </p>
                      <br />
                    </li>
                    <li>
                      <h2 className="heading">5. Trademarks</h2>
                      <p>
                        Usage of Alpha Regiment's trademarks is permitted for
                        published instructors, subject to the following
                        provisions:
                      </p>
                      <div className="sub_term">
                        <p>
                          You are required to utilize only the trademark images
                          provided by Alpha Regiment, as outlined in our
                          guidelines.
                        </p>
                        <p>
                          For offline teaching activities, instructors must
                          exclusively employ the banner design furnished by the
                          Alpha Regiment.
                        </p>
                        <p>
                          Trademarks may only be utilized in conjunction with
                          the promotion and sale of instructors' Submitted
                          Content on Alpha Regiment or their participation on
                          the platform.
                        </p>
                        <p>
                          Instructors must promptly cease trademark usage upon
                          request by Alpha Regiment.
                        </p>
                        <br />
                        <br />
                        <span>Prohibited actions include:</span>
                        <br />
                        <br />
                        <p>
                          Misleading or disparaging use of Alpha Regiment's
                          trademarks.
                        </p>
                        <p>
                          Implying endorsement, sponsorship, or approval of
                          instructors' Submitted Content or services by Alpha
                          Regiment.
                        </p>
                        <p>
                          Using Alpha Regiment's trademarks in connection with
                          unlawful or objectionable material.
                        </p>
                      </div>
                      <p>
                        These provisions are integral to our terms and
                        conditions and serve to uphold the integrity of Alpha
                        Regiment's brand.
                      </p>
                      <br />
                    </li>
                    <li>
                      <h2 className="heaading">6. Deleting Your Account</h2>
                      <p>
                        Procedures for terminating your instructor account are
                        provided herein. Reasonable commercial efforts will be
                        made to settle any outstanding payments due to you prior
                        to account termination. It is acknowledged that in
                        instances where students have previously enrolled in
                        your Submitted Content, access to your name and
                        Submitted Content may persist post-account deletion.
                        Assistance with account deletion can be sought through
                        our Support Center in case of any difficulties.
                      </p>
                      <br />
                    </li>
                    <li>
                      <h2 className="heaading">7. Miscellaneous Legal Terms</h2>
                      <h3 className="sub_heading">
                        7.1 Updating These Terms :
                      </h3>
                      <p>
                        Alpha Regiment reserves the unilateral right to amend or
                        revise these Terms at any time, at its sole discretion,
                        to clarify operational procedures or incorporate new
                        features. Material changes will be communicated through
                        prominent means such as email notifications to the
                        address associated with your account or postings within
                        our Services. Such modifications shall become effective
                        upon posting, superseding all previous versions. Your
                        continued use of our Services post-amendment implies
                        acceptance of the revised Terms.
                      </p>
                      <br />
                      <h3 className="sub_heading">
                        7.2 Relationship Between Us :
                      </h3>
                      <p>
                        You and we agree that no joint venture, partnership,
                        employment, contractor, or agency relationship exists
                        between us.
                      </p>
                      <br />
                      <h3 className="sub_heading">7.3 Survival :</h3>
                      <p>
                        The following sections shall survive the expiration or
                        termination of these Terms: Sections 2 (License to Alpha
                        Regiment), 3.3 (Relationship to Other Users), 4.3
                        (Receiving Payments), 4.4 (Refunds), 6 (Deleting Your
                        Account), and 7 (Miscellaneous Legal Terms).
                      </p>
                      <br />
                    </li>
                    <li>
                      <h2 className="heading">8. How to Contact Us</h2>
                      <p>
                        For formal inquiries, please direct correspondence to
                        our Support Team via email at support@alpharegiment.com.
                        We welcome your queries, grievances, and input regarding
                        our Services, in accordance with our Terms and
                        Conditions.
                      </p>
                      <br />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default TeacherTerms;
