export const GET_OFFICER_COURSES_REQUEST = "GET_OFFICER_COURSES_REQUEST";
export const GET_OFFICER_COURSES_SUCCESS = "GET_OFFICER_COURSES_SUCCESS";
export const GET_OFFICER_COURSES_FAIL = "GET_OFFICER_COURSES_FAIL";

export const POST_TIMLINE_REQUEST = "POST_TIMLINE_REQUEST";
export const POST_TIMLINE_SUCCESS = "POST_TIMLINE_SUCCESS";
export const POST_TIMLINE_FAIL = "POST_TIMLINE_FAIL";
export const CLEAR_POST_TIMLINE_STATE = "CLEAR_POST_TIMLINE_STATE";

export const UPDATE_TIMLINE_REQUEST = "UPDATE_TIMLINE_REQUEST";
export const UPDATE_TIMLINE_SUCCESS = "UPDATE_TIMLINE_SUCCESS";
export const UPDATE_TIMLINE_FAIL = "UPDATE_TIMLINE_FAIL";
export const CLEAR_UPDATE_TIMLINE_STATE = "CLEAR_UPDATE_TIMLINE_STATE";


export const GET_POST_TIMLINE_LIST_REQUEST = "GET_POST_TIMLINE_LIST_REQUEST";
export const GET_POST_TIMLINE_LIST_SUCCESS = "GET_POST_TIMLINE_LIST_SUCCESS";
export const GET_POST_TIMLINE_LIST_FAIL = "GET_POST_TIMLINE_LIST_FAIL";


export const GET_POST_TIMLINE_BY_OFFICER_REQUEST = "GET_POST_TIMLINE_BY_OFFICER_REQUEST";
export const GET_POST_TIMLINE_BY_OFFICER_SUCCESS = "GET_POST_TIMLINE_BY_OFFICER_SUCCESS";
export const GET_POST_TIMLINE_BY_OFFICER_FAIL = "GET_POST_TIMLINE_BY_OFFICER_FAIL";

export const DELETE_TIMLINE_REQUEST = "DELETE_TIMLINE_REQUEST";
export const DELETE_TIMLINE_SUCCESS = "DELETE_TIMLINE_SUCCESS";
export const DELETE_TIMLINE_FAIL = "DELETE_TIMLINE_FAIL";
export const DELETE_TIMLINE_STATE = "DELETE_TIMLINE_STATE";


export const LIKE_POST_TIMLINE_REQUEST = "LIKE_POST_TIMLINE_REQUEST";
export const LIKE_POST_TIMLINE_SUCCESS = "LIKE_POST_TIMLINE_SUCCESS";
export const LIKE_POST_TIMLINE_FAIL = "LIKE_POST_TIMLINE_FAIL";
export const LIKE_POST_TIMLINE_CLEAR_ERROR = "LIKE_POST_TIMLINE_CLEAR_ERROR";
export const LIKE_POST_TIMLINE_CLEAR_STATE = "LIKE_POST_TIMLINE_CLEAR_STATE";


export const GET_OFFICER_REVENUE_REQUEST = "GET_OFFICER_REVENUE_REQUEST";
export const GET_OFFICER_REVENUE_SUCCESS = "GET_OFFICER_REVENUE_SUCCESS";
export const GET_OFFICER_REVENUE_FAIL = "GET_OFFICER_REVENUE_FAIL";

export const CLEAR_OFFICER_ERROR = "CLEAR_OFFICER_ERROR";
export const CLEAR_OFFICER_STATE = "CLEAR_OFFICER_STATE";