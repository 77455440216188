import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import OfficerLayout from "./OfficerLayout";
// import { useAuth } from "../../context/userContext";
import {
  FaExpeditedssl,
  FaInfoCircle,
  FaPercent,
  FaPlus,
  FaRupeeSign,
  FaUpload,
  FaUserEdit,
} from "react-icons/fa";
import CustomInput from "../../components/reusable/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getSsbCourseDetails,
  ssbClearCourseupdateState,
  ssbClearErrors,
  ssbCourseUpdateAction,
} from "../../redux/actions/ssbCourseAction";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../components/reusable/CustomSelect";
import CustomTextarea from "../../components/reusable/CustomTextarea";
// import { toast } from "react-toastify";
import "../../styles/editSsbCourse.scss";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNotification } from "../../context/notificationContext";
import { RxCross2 } from "react-icons/rx";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { BsCurrencyRupee } from "react-icons/bs";
import CustomMultiSelect from "../../components/reusable/CustomMultiSelect";
import { MdDateRange, MdOutlinePercent } from "react-icons/md";
import CustomButton from "../../components/reusable/CustomButton";
import CustomDateInput from "../../components/reusable/CustomDateInput";
import {
  dossierClearErrors,
  getDossierAction,
} from "../../redux/actions/dossierAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { GrClose } from "react-icons/gr";
import CustomCheckbox from "../../components/reusable/CustomCheckbox";
import { thumbnail } from "../../assets/images";
import { clearErrors, officerListAction } from "../../redux/actions/userAction";
import { useAuth } from "../../context/userContext";

const OfficerEditCourse = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [, , showNotification] = useNotification();
  const officerSsbCourseDetails = useSelector(
    (state) => state.getSsbCourseDetails
  );
  const { loading, error, courseDetails: data } = officerSsbCourseDetails;
  const details = data?.course;
  const [editable, setEditable] = useState(false);
  //   const [type, setType] = useState(details?.type);
  const [feature, setFeature] = useState("");
  const [featureList, setFeatureList] = useState(
    details?.featureList ? details?.featureList : []
  );
  // HANDLE collaborator add in list
  const [collaborator, setCollaborator] = useState(
    details?.collaborator ? details?.collaborator : []
  );
  const [collaboratorName, setCollaboratorName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [collabError, setCollabError] = useState("");
  // COLLABORATOR OPTION VALUE STATE
  const [collaboratorOption, setCollaboratorOption] = useState("no");

  const [weekDay, setWeekDay] = useState("");
  const [time, setTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [content, setContent] = useState("");
  const [contentList, setContentList] = useState([]);

  const [tatDossier, setTatDossier] = useState();
  const [watDossier, setWatDossier] = useState();
  const [srtDossier, setSrtDossier] = useState();
  const [sdtDossier, setSdtDossier] = useState();
  const [timeError, setTimeError] = useState("");
  const [timeList, setTimeList] = useState([]);
  const [courseIncludes, setCourseIncludes] = useState(details?.courseInclude);
  const [includesInput, setIncludesInput] = useState(false);
  const [customInclude, setCustomInclude] = useState("");
  const [customIncludeArray, setCustomIncludeArray] = useState("");

  // COURSE DATA
  const [type, setType] = useState({
    value: details?.type,
    label: details?.type,
  });
  const [courseLabel, setCourseLabel] = useState({});
  const [courseMode, setCourseMode] = useState({});
  const [classType, setClassType] = useState();
  const [category, setCategory] = useState();
  const [image, setImage] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(thumbnail);

  const handleAvatarChange = (e) => {
    if (e.target.name === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader?.readyState === 2) {
          setAvatarPreview(reader?.result);
          setImage(reader?.result);
        }
      };
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const [recurringClass, setRecurringClass] = useState({});

  const [formData, setFormData] = useState({
    title: details?.title,
    originalPrice: details?.originalPrice,
    discount: details?.discount,
    discountedPrice: details?.discountedPrice,
    startDate: details?.startDate,
    description: details?.description,
    shortDescription: details?.shortDescription,
    discountValidity: details?.discountValidity,
    bannervideoLink: details?.bannervideoLink,
    googleLocation: details?.googleLocation,
    reviewTime: details?.reviewTime,
    monthlyClassDate: details?.monthlyClassDate,
    weeklyClassDay: details?.weeklyClassDay,
    courseDuration: details?.courseDuration,
    courseLanguage:
      courseLabel !== "SSB Coaching" ? "English" : details?.courseLanguage,
  });

  // DESTRCUTURE ALL DATA
  const {
    title,
    description,
    originalPrice,
    discount,
    startDate,
    shortDescription,
    discountValidity,
    googleLocation,
    reviewTime,
    bannervideoLink,
    weeklyClassDay,
    monthlyClassDate,
    courseLanguage,
    courseDuration,
  } = formData;

  useEffect(() => {
    setContentList(details?.courseContent);
    setType({
      value: details?.type,
      label: details?.type,
    });

    setCourseMode({
      value: details?.courseMode,
      label: details?.courseMode,
    });
    setClassType(details?.classType);
    setCategory(details?.category);
    setImage(details?.thumbnail);
    setAvatarPreview(details?.thumbnail?.url);
    setFeatureList(details?.featureList);
    setCollaborator(details?.collaborator);
    setCourseIncludes(details?.courseInclude);
    setFormData({
      title: details?.title,
      originalPrice: details?.originalPrice,
      discount: details?.discount,
      discountedPrice: details?.discountedPrice,
      startDate: details?.startDate,
      description: details?.description,
      shortDescription: details?.shortDescription,
      discountValidity: details?.discountValidity,
      bannervideoLink: details?.bannervideoLink,
      googleLocation: details?.googleLocation,
      reviewTime: details?.reviewTime,
      monthlyClassDate: details?.monthlyClassDate,
      weeklyClassDay: details?.weeklyClassDay,
      courseDuration: details?.courseDuration,
      courseLanguage:
        courseLabel !== "SSB Coaching" ? "English" : details?.courseLanguage,
    });
  }, [details]);

  useEffect(() => {
    setCourseLabel(category);
  }, [category]);

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // VALIDATE THE DATE INPUT
  const getCurrentDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // MONTH LIST
  const monthArray = Array.from({ length: 30 }, (_, idx) => ({
    option: `${idx + 1}`,
  }));

  monthArray.push({ option: `Last Day of Month` });

  // HANDLE FEATURE ADDS START
  const handleAddFeature = () => {
    if (!feature) {
      showNotification("error", "Please add a feature");
    } else if (featureList.length >= 6) {
      showNotification("error", "You can add maximum 6 feature");
    } else {
      setFeatureList([...featureList, { feature: feature }]);
      setFeature("");
    }
  };

  // HANDLE REMOVE FEATURE
  const handleRemoveFeature = (index) => {
    const updatedFeatureList = featureList.filter((_, i) => i !== index);
    // Update the state with the new array
    setFeatureList(updatedFeatureList);
  };

  //   ADD COLABORATOR IN LIST
  const handleRadioChange = (e) => {
    setCollaboratorOption(e.target.value);
  };

  const handleAddCollaborator = () => {
    if (!collaboratorName || !percentage) {
      showNotification("error", "Please Add name and collaborator percent.");
    } else if (
      collaborator.some((itm) => itm.value === collaboratorName?.value)
    ) {
      setCollabError("You cannot add same collaborator multiple time");
      setTimeout(() => {
        setCollabError("");
      }, 4000);
    } else {
      const totalPercentage = collaborator.reduce(
        (sum, collaborator) => sum + parseFloat(collaborator.percentage || 0),
        0
      );
      if (totalPercentage + parseFloat(percentage) >= 100) {
        showNotification(
          "error",
          "Total percentage cannot be greater than 100%."
        );
      } else {
        setCollaborator([
          ...collaborator,
          {
            label: collaboratorName?.label,
            value: collaboratorName?.value,
            percentage: parseFloat(percentage),
          },
        ]);
        setPercentage("");
        setCollaboratorName("");
      }
    }
  };

  // HANDLE collaborator add in list

  // DELETe COLLABORATOR

  const handleDeleteCollab = (item) => {
    var newData = collaborator;
    newData.splice(item, 1);
    setCollaborator([...newData]);
    showNotification("success", `Index number ${item + 1} deleted`);
  };

  // DELETe COLLABORATOR ENDS

  useEffect(() => {
    dispatch(getSsbCourseDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch]);

  // GET DOSSIER LIST STATE FROM DOSSIER REDUCER
  const getDossier = useSelector((state) => state.getDossier);

  const {
    loading: dossierLoading,
    error: dossierError,
    dossierData,
  } = getDossier;

  const dossierList = dossierData?.dossier;

  // EXTRACT TAT  DOSSIER ID FROM dossier LIST ARRAY
  let tatDossierArray = [];

  dossierList
    ?.filter((itm) => itm?.label === 1)
    ?.forEach((ele) => {
      tatDossierArray.push({
        label: ele?.title,
        value: ele?._id,
      });
    });

  // EXTRACT WAT DOSSIER ID FROM dossier LIST ARRAY
  let watDossierArray = [];

  dossierList
    ?.filter((itm) => itm?.label === 2)
    ?.forEach((ele) => {
      watDossierArray.push({
        label: ele?.title,
        value: ele?._id,
      });
    });

  // EXTRACT SDT DOSSIER ID FROM dossier LIST ARRAY
  let sdtDossierArray = [];

  dossierList
    ?.filter((itm) => itm?.label === 4)
    ?.forEach((ele) => {
      sdtDossierArray.push({
        label: ele?.title,
        value: ele?._id,
      });
    });

  // EXTRACT SRT DOSSIER ID FROM dossier LIST ARRAY
  let srtDossierArray = [];

  dossierList
    ?.filter((itm) => itm?.label === 3)
    ?.forEach((ele) => {
      srtDossierArray.push({
        label: ele?.title,
        value: ele?._id,
      });
    });

  // GET DOSSIER LIST
  useEffect(() => {
    dispatch(getDossierAction());
  }, [dispatch]);

  useEffect(() => {
    if (dossierError) {
      showNotification("error", dossierError);
      dispatch(dossierClearErrors());
    }
  }, [dispatch, dossierError, showNotification]);

  const [dossier, setDossier] = useState({
    tat: tatDossier?.value,
    wat: watDossier?.value,
    srt: srtDossier?.value,
    sdt: sdtDossier?.value,
  });
  useEffect(() => {
    setDossier({
      tat: tatDossier?.value,
      wat: watDossier?.value,
      srt: srtDossier?.value,
      sdt: sdtDossier?.value,
    });
  }, [sdtDossier, tatDossier, srtDossier, watDossier]);

  // CREATE NEW DOSSIER REDIRECT
  if (tatDossier?.label === "Create New") {
    navigate(tatDossier?.value);
  }
  if (watDossier?.label === "Create New") {
    navigate(watDossier?.value);
  }
  if (srtDossier?.label === "Create New") {
    navigate(srtDossier?.value);
  }
  if (sdtDossier?.label === "Create New") {
    navigate(sdtDossier?.value);
  }

  // COURSE INCLUDES ON CHNAGE

  const [checkboxStates, setCheckboxStates] = useState({
    phn_call: false,
    video_call: false,
    dossier: false,
    question: false,
    stdy_material: false,
    mock_interview: false,
    live_gd: false,
    mock_psycho_test: false,
    gto_simulator: false,
    full_length_tat: false,
    full_length_wat: false,
    full_length_srt: false,
    full_length_sd: false,
    checked_dossier: false,
    phn_call_physclogist: false,
    video_call_physclogist: false,
  });

  useEffect(() => {
    setCheckboxStates({
      phn_call: details?.courseInclude.includes("Written Feedback")
        ? true
        : false,
      video_call: details?.courseInclude.includes("Written Feedback")
        ? true
        : false,
      dossier: details?.courseInclude.includes("Recording of interview")
        ? true
        : false,
      question: details?.courseInclude.includes(
        "Can ask questions from GTO & Psychologist as well."
      )
        ? true
        : false,
      stdy_material: details?.courseInclude.includes("Study Material")
        ? true
        : false,
      mock_interview: details?.courseInclude.includes("Mock Interview")
        ? true
        : false,
      live_gd: details?.courseInclude.includes("Live GD") ? true : false,
      mock_psycho_test: details?.courseInclude.includes("Mock Psychology Test")
        ? true
        : false,
      gto_simulator: details?.courseInclude.includes("3D GTO simulator")
        ? true
        : false,
      full_length_tat: details?.courseInclude.includes(
        "Full Length TAT(11+1 images)"
      )
        ? true
        : false,
      full_length_wat: details?.courseInclude.includes(
        "Full Length WAT(60 words)"
      )
        ? true
        : false,
      full_length_srt: details?.courseInclude.includes(
        "Full Length SRT(60 SRT)"
      )
        ? true
        : false,
      full_length_sd: details?.courseInclude.includes("Full Length SD")
        ? true
        : false,
      checked_dossier: details?.courseInclude.includes("Checked Dossier")
        ? true
        : false,
      phn_call_physclogist: details?.courseInclude.includes(
        "Phone Call By Psychologist"
      )
        ? true
        : false,
      video_call_physclogist: details?.courseInclude.includes(
        "Video Call By Psychologist"
      )
        ? true
        : false,
    });
  }, [details?.courseInclude]);

  // ADD COURSE INCLUDE TO STATE COURSEINCLUDES
  const handleCourseIncludes = (e) => {
    const { id, value } = e.target;
    const valueExists = courseIncludes.includes(value);
    if (valueExists) {
      const newCourseIncludes = courseIncludes.filter((item) => item !== value);
      setCourseIncludes(newCourseIncludes);
    } else {
      setCourseIncludes([...courseIncludes, value]);
    }
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [id]: !valueExists,
    }));
  };

  // ADD INCLUDE STRING FROM CUSTOM ARRAY
  const handleAddIncludes = () => {
    const valueExists = courseIncludes.includes(customInclude);
    if (valueExists) {
      showNotification("error", "Already added");
    } else {
      setCourseIncludes([...courseIncludes, customInclude]);
      setCustomIncludeArray([...customIncludeArray, customInclude]);
      setCustomInclude("");
    }
  };

  // REMOVE INCLUDE STRING FROM CUSTOM ARRAY
  const handleRemoveInclude = (idx) => {
    const updateIncludeList = courseIncludes.filter((_, i) => i !== idx);
    const customInclUpdateList = customIncludeArray.filter((_, i) => i !== idx);
    setCustomIncludeArray(customInclUpdateList);
    setCourseIncludes(updateIncludeList.concat(customInclUpdateList));
  };

  // ADD TIME TABLE IN ARRAY

  const handleAddTimeTable = () => {
    if (!weekDay || !time) {
      showNotification(
        "error",
        "Please Select Time and WeekDay for add in list"
      );
    } else {
      const existingTimeSlot = timeList.find(
        (slot) =>
          slot?.weekDay === weekDay &&
          slot.classTime.some(
            (existingSlot) =>
              existingSlot.time === time ||
              existingSlot.endTime === endTime ||
              (new Date(`2000-01-01 ${time}`) >=
                new Date(`2000-01-01 ${existingSlot.time}`) &&
                new Date(`2000-01-01 ${time}`) <=
                  new Date(`2000-01-01 ${existingSlot.endTime}`)) ||
              (new Date(`2000-01-01 ${endTime}`) >=
                new Date(`2000-01-01 ${existingSlot.time}`) &&
                new Date(`2000-01-01 ${endTime}`) <=
                  new Date(`2000-01-01 ${existingSlot.endTime}`))
          )
      );
      if (existingTimeSlot) {
        setTimeError(
          "A class already exists time slot. Please choose a time after this slot time."
        );
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else if (
        new Date(`2000-01-01 ${time}`) > new Date(`2000-01-01 ${endTime}`)
      ) {
        setTimeError("Start time cannot be greater than end time.");
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else if (time === endTime) {
        setTimeError("Start time and end time cannot be the same.");
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else {
        const existingDayIndex = timeList?.findIndex(
          (item) => item.weekDay === weekDay
        );

        if (existingDayIndex !== -1) {
          const updatedTimeList = [...timeList];
          updatedTimeList[existingDayIndex].classTime.push({ time, endTime });

          setTimeList(updatedTimeList);
        } else {
          setTimeList([
            ...timeList,
            {
              weekDay: weekDay,
              classTime: [{ time: time, endTime: endTime }],
            },
          ]);
        }
        // setSelectedTime({
        //   weekDay: weekDay,
        //   classTime: [
        //     ...classTime,
        //     { time: time, endTime: endTime, weekDay: weekDay },
        //   ],
        // });
        setTime("");
        setEndTime("");
      }
    }
  };

  // DELETE SINGLE TIME TABLE ROW FROM ARRAY
  const deleteTimeRow = (id) => {
    var newData = timeList;
    // var classData = classTime;
    // classData.splice(id,1)
    newData.splice(id, 1);
    setTimeList([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };

  // RECURRING CLASSES RADIO OPTION
  const [classesOption, setClassesOption] = useState("yes");

  const handleClassOption = (e) => {
    setClassesOption(e.target.value);
    setRecurringClass({});
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: "",
    }));
  };

  // HANDLE CONTENT ADDS START
  const handleAddContent = () => {
    if (!content) {
      showNotification("error", "Please add a learning content");
    } else if (contentList.length >= 10) {
      showNotification("error", "You can add maximum 10");
    } else {
      setContentList([...contentList, { content: content }]);
      setContent("");
    }
  };

  // HANDLE REMOVE CONTENT
  const handleRemoveContent = (index) => {
    const updatedFeatureList = contentList.filter((_, i) => i !== index);
    // Update the state with the new array
    setContentList(updatedFeatureList);
  };

  // GET ALL OFFICER LIST
  const {
    error: officerListError,
    loading: officerListLoading,
    userList,
  } = useSelector((state) => state.officerList);

  const officerList = userList?.officers;

  useEffect(() => {
    if (officerListError) {
      dispatch(clearErrors());
    }
  }, [dispatch, officerListError]);

  // EXTRACT OFFICERS ID FROM OFFICER LIST ARRAY
  let officerArray = [];

  officerList
    ?.filter((itm) => itm?._id !== auth?.user?._id)
    ?.forEach((ele) => {
      officerArray.push({
        label: ele?.first_name,
        value: ele?._id,
      });
    });

  useEffect(() => {
    dispatch(officerListAction());
  }, [dispatch]);

  // COURSE UPDATE ACTION

  // COURSE CREATE STATE AND FUNCTION
  const handleSubmit = (e) => {
    // const thumbnail = image;
    const isValidOriginalPrice =
      /^[1-9]\d*$/g.test(originalPrice) || originalPrice === "1";
    const intValue = parseInt(discount, 10);
    const isValidDiscount = isNaN(intValue) || intValue < 0 || intValue > 100;
    const courseType =
      category === "Mock Psychology"
        ? "Psychology"
        : category === "Mock Interview"
        ? "Interview"
        : "Complete SSB";
    if (title === "" || !courseMode || !type) {
      showNotification("error", "Please fill all required fields");
    } else if (formData.originalPrice === "" || !isValidOriginalPrice) {
      showNotification("error", "Please enter a valid price value");
    } else if (discount !== "" && isValidDiscount) {
      showNotification("error", "Please enter valid discount!");
    } else if (courseLabel === "SSB Course" && courseLanguage === "") {
      showNotification("error", "Please add course language");
    } else if (featureList.length < 4) {
      showNotification("error", "Please add minimum 4");
    }
    // else if (courseMode.label === "Offline") {
    //   setCurrentStep(currentStep + 2);
    // }
    else if (
      courseLabel === "Mock Psychology" &&
      courseMode?.label === "Online" &&
      (!tatDossier || !watDossier || !srtDossier || !sdtDossier)
    ) {
      showNotification("error", "Please select all dossiers");
    } else if (
      (courseLabel === "SSB Course" &&
        courseMode?.label === "Online" &&
        !classType) ||
      (courseLabel === "SSB Course" &&
        courseMode?.label === "Online" &&
        classType?.label === "Live" &&
        timeList.length < 1)
    ) {
      showNotification("error", "Please fill all required fields");
    } else if (courseLabel === "SSB Course" && formData.courseDuration === "") {
      showNotification("error", "Course duration required");
    } else if (formData.shortDescription.length < 125) {
      showNotification("error", "Add minimum 125 words in short description");
    } else if (formData.shortDescription.length > 200) {
      showNotification("error", "Add upto 200 words only in short description");
    } else if (
      type?.label !== "Interview" &&
      (category === "" ||
        formData.description === "" ||
        formData.shortDescription === "")
    ) {
      showNotification("error", "Please fill all required fields");
    } else if (
      type?.label === "Interview" &&
      ((courseMode?.label === "Offline" && formData.googleLocation === "") ||
        formData.description === "" ||
        formData.shortDescription === "")
    ) {
      showNotification("error", "Please fill all required fields");
    } else if (formData.description.length < 150) {
      showNotification("error", "Add minimum 150 words in description");
    } else if (
      (courseLabel === "SSB Course" || courseLabel === "GTO") &&
      classType?.label !== "Recorded" &&
      classesOption === "yes" &&
      formData.startDate === ""
    ) {
      showNotification("error", "Please fill startDate");
    } else if (
      (courseLabel === "SSB Course" || courseLabel === "GTO") &&
      classType?.label !== "Recorded" &&
      classesOption !== "yes" &&
      formData.recurringClass === ""
    ) {
      showNotification("error", "Please fill recurringClass");
    } else if (
      (courseLabel === "SSB Course" || courseLabel === "GTO") &&
      classType?.label !== "Recorded" &&
      classesOption !== "yes" &&
      formData.recurringClass &&
      formData?.recurringClass?.label === "Monthly" &&
      formData.monthlyClassDate === ""
    ) {
      showNotification("error", "Please fill all required fields");
    } else if (
      (courseLabel === "SSB Course" || courseLabel === "GTO") &&
      classType?.label !== "Recorded" &&
      classesOption !== "yes" &&
      formData.recurringClass &&
      formData?.recurringClass?.label !== "Weekly" &&
      formData.weeklyClassDay === ""
    ) {
      showNotification("error", "Please fill all required fields");
    } else if (
      courseLabel === "Mock Psychology" &&
      courseMode?.label === "Online" &&
      formData?.reviewTime === ""
    ) {
      showNotification("error", "Please select review time");
    } else if (courseLabel === "SSB Course" && contentList?.length < 4) {
      showNotification("error", "Please add minimum 4 content for the course");
    } else if (image === "") {
      showNotification("error", "Please fill all required field");
    } else if (type?.label === "Complete SSB" && bannervideoLink === "") {
      showNotification("error", "Please upload demo video");
    } else {
      const updatedPrice = parseInt(originalPrice);
      const updatesDiscount = parseInt(discount);
      dispatch(
        ssbCourseUpdateAction(
          id,
          title,
          shortDescription,
          description,
          updatedPrice,
          updatesDiscount,
          discountValidity,
          // discountedPrice,
          courseMode?.label,
          courseType,
          // thumbnail,
          startDate,
          featureList,
          timeList,
          category,
          bannervideoLink,
          collaborator,
          classType,
          googleLocation,
          reviewTime,
          courseIncludes,
          dossier,
          weeklyClassDay,
          monthlyClassDate,
          courseLanguage,
          contentList,
          courseDuration
        )
      );
    }
  };

  const {
    loading: updateLoading,
    error: updateError,
    courseData,
  } = useSelector((state) => state.ssbCourseUpdate);

  useEffect(() => {
    if (updateError) {
      showNotification("error", updateError);
    }
    if (courseData?.success) {
      showNotification("success", courseData?.message);
      dispatch(ssbClearCourseupdateState());
      navigate("/officer/courses");
    }
  }, [
    dispatch,
    showNotification,
    navigate,
    courseData?.message,
    courseData?.success,
    updateError,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <section className="ssb_edit_course txt_color">
              {/* <div className="container"> */}
              {/* <h2 className="profilePage_heading"></h2> */}
              <div className="user_details_main">
                <div className="user_pro_data">
                  <h2>
                    <div className="back">
                      <button
                        title="Go Back"
                        onClick={() => navigate("/officer/courses")}
                      >
                        <BiLeftArrowAlt />
                      </button>
                      {details?.title + " Course"}
                    </div>

                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="data_card">
                      <CustomInput
                        label="Course Title"
                        type="text"
                        placeholder="Ex:- Mock Interview By Colonel Sanjay"
                        name="title"
                        impStar="*"
                        readOnly={editable ? false : true}
                        tooltip="Ensure that the course name is self-explanatory, indicating the specific target entry exams (such as AFCAT, CDS, NDA) and clearly specifying the gender for which it is intended."
                        value={formData.title}
                        onChange={handleInputChange}
                      />

                      <CustomInput
                        icon={<BsCurrencyRupee />}
                        label="Price"
                        type="number"
                        placeholder="19000"
                        name="originalPrice"
                        impStar="*"
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        value={formData.originalPrice}
                      />
                      <CustomInput
                        icon={<FaPercent />}
                        label="Discount Percentage"
                        type="number"
                        placeholder=""
                        readOnly={editable ? false : true}
                        name="discount"
                        // impStar="*"
                        onChange={handleInputChange}
                        value={formData.discount}
                      />
                      <CustomInput
                        icon={<FaRupeeSign />}
                        label="Discount Price"
                        type="number"
                        readOnly={editable ? false : true}
                        placeholder=""
                        name="discountedPrice"
                        // impStar="*"
                        onChange={handleInputChange}
                        value={formData.discountedPrice}
                      />

                      {formData.discount !== "" && (
                        <CustomDateInput
                          icon={<MdDateRange />}
                          label="Discount valid till"
                          type="date"
                          placeholder="Forever"
                          name="discountValidity"
                          impStar=""
                          readOnly={editable ? false : true}
                          tooltip="In case forever discount ,leave blank this input"
                          onChange={handleInputChange}
                          value={formData.discountValidity}
                          disabledDate={getCurrentDate}
                        />
                      )}

                      {courseLabel === "SSB Coaching" && (
                        <CustomSelect
                          label="Course Language"
                          name="courseLanguage"
                          optionArray={[
                            { option: "English" },
                            { option: "Hindi" },
                          ]}
                          readOnly={editable ? false : true}
                          value={formData.courseLanguage}
                          onChange={handleInputChange}
                        />
                      )}

                      {/* <CustomSelect
                        label="Course Category"
                        name="category"
                        optionArray={[
                          { option: "Mock Psychology" },
                          { option: "Mock Interview" },
                          { option: "SSB Coaching" },
                          { option: "Mock SSB Interview" },
                        ]}
                        readOnly={editable ? false : true}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      /> */}

                      <div className="sc-irTswW izZXSN myInput course_feaure_list">
                        <label>
                          What Aspirant will learn (minimum 4)
                          <abbr className="impStar">*</abbr>
                        </label>
                        <div className="input_icon courseFeatures">
                          <input
                            className="input_text"
                            type="text"
                            placeholder="Add minimum 4"
                            name="feature"
                            value={feature}
                            readOnly={editable ? false : true}
                            onChange={(e) => setFeature(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleAddFeature();
                              }
                            }}
                          />
                          <button
                            title="Add More Feature"
                            onClick={handleAddFeature}
                          >
                            <FaPlus />
                          </button>
                        </div>
                        {featureList && featureList.length > 0 && (
                          <div className="feature_list">
                            <ul>
                              {featureList.map((ele, i) => (
                                <li key={i}>
                                  <div>
                                    <p>{ele.feature}</p>
                                    <span
                                      onClick={() => handleRemoveFeature(i)}
                                    >
                                      <RxCross2 />
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      {courseLabel === "Mock Psychology" &&
                        courseMode?.label === "Online" && (
                          <>
                            <CustomMultiSelect
                              label="Select TAT Dossier"
                              impStar="*"
                              isMulti={false}
                              value={tatDossier}
                              readOnly={editable ? false : true}
                              onChange={(val) => setTatDossier(val)}
                              optionArray={
                                dossierLoading ? (
                                  <ButtonLoader />
                                ) : !tatDossierArray.length ? (
                                  [
                                    {
                                      label: "Create New",
                                      value:
                                        "/officer/physco-dossier-tat-create",
                                    },
                                  ]
                                ) : (
                                  tatDossierArray
                                )
                              }
                            />

                            <CustomMultiSelect
                              label="Select WAT Dossier"
                              impStar="*"
                              isMulti={false}
                              value={watDossier}
                              onChange={(val) => setWatDossier(val)}
                              readOnly={editable ? false : true}
                              optionArray={
                                !watDossierArray.length
                                  ? [
                                      {
                                        label: "Create New",
                                        value:
                                          "/officer/physco-dossier-wat-create",
                                      },
                                    ]
                                  : watDossierArray
                              }
                            />
                            <CustomMultiSelect
                              label="Select SRT Dossier"
                              impStar="*"
                              isMulti={false}
                              value={srtDossier}
                              readOnly={editable ? false : true}
                              onChange={(val) => setSrtDossier(val)}
                              optionArray={
                                !srtDossierArray.length
                                  ? [
                                      {
                                        label: "Create New",
                                        value:
                                          "/officer/physco-dossier-srt-create",
                                      },
                                    ]
                                  : srtDossierArray
                              }
                            />
                            <CustomMultiSelect
                              label="Select SDT Dossier"
                              impStar="*"
                              isMulti={false}
                              value={sdtDossier}
                              readOnly={editable ? false : true}
                              onChange={(val) => setSdtDossier(val)}
                              optionArray={
                                !sdtDossierArray.length
                                  ? [
                                      {
                                        label: "Create New",
                                        value:
                                          "/officer/physco-dossier-sdt-create",
                                      },
                                    ]
                                  : sdtDossierArray
                              }
                            />
                          </>
                        )}
                      {courseLabel === "SSB Coaching" &&
                        courseMode?.label === "Online" && (
                          <>
                            {
                              <CustomSelect
                                label="Class Type"
                                impStar="*"
                                value={classType}
                                onChange={(e) => setClassType(e.target.value)}
                                // isMulti={false}
                                className={"select_input"}
                                readOnly={editable ? false : true}
                                optionArray={
                                  courseMode.label === "Online"
                                    ? [
                                        {
                                          option: "Live",
                                        },
                                        {
                                          option: "Recorded",
                                        },
                                        {
                                          option: "Hybrid(Live + Recorded)",
                                        },
                                      ]
                                    : [
                                        {
                                          option: "Hybrid(Live + Recorded)",
                                        },
                                      ]
                                }
                              />
                            }
                          </>
                        )}

                      {classType === "Live" && (
                        <div className="batch_timeTable">
                          <div className="title_label">Create Class Time</div>
                          <div className="add_timeTable">
                            <div>
                              <div className="timetable_tile">
                                <span>
                                  <label>Select Weekday :</label>
                                  <select
                                    name="weekDay"
                                    value={weekDay}
                                    onChange={(e) => setWeekDay(e.target.value)}
                                  >
                                    <option value="">Select Week Day</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thrusday">Thrusday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                  </select>
                                </span>
                                <div className="time_select">
                                  <span>
                                    <label>Start Time :</label>
                                    <input
                                      type="time"
                                      name="time"
                                      value={time}
                                      onChange={(e) => setTime(e.target.value)}
                                    />
                                  </span>
                                  <span>
                                    <label>to</label>
                                  </span>
                                  <span>
                                    <label>End Time :</label>
                                    <input
                                      type="time"
                                      name="endTime"
                                      value={endTime}
                                      onChange={(e) =>
                                        setEndTime(e.target.value)
                                      }
                                    />
                                  </span>
                                </div>

                                <button
                                  // title="Add New Time Table"
                                  className="add_time"
                                  onClick={() => handleAddTimeTable()}
                                >
                                  <FaPlus />
                                </button>
                              </div>
                              {timeError && (
                                <p className="error_msg">{timeError}</p>
                              )}
                              {timeList?.length > 0 && (
                                <div className="time_list">
                                  <h5>Time List:</h5>
                                  {timeList?.map((item, idx) => (
                                    <p key={idx}>
                                      <div>
                                        <span>{item?.weekDay}</span>
                                        {item?.classTime?.map((ele, id) => (
                                          <span key={id}>
                                            {ele.time} - {ele.endTime}
                                          </span>
                                        ))}
                                      </div>

                                      <span onClick={() => deleteTimeRow(idx)}>
                                        <GrClose />
                                      </span>
                                    </p>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {courseLabel === "SSB Coaching" && (
                        <CustomInput
                          label="Course Duration(In Months)"
                          type="number"
                          placeholder=""
                          impStar="*"
                          name="courseDuration"
                          value={formData?.courseDuration}
                          onChange={handleInputChange}
                        />
                      )}

                      <CustomTextarea
                        label="Course Short Description"
                        className="course_feaure_list"
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Upto 200 words and add minimum 125 words"
                        name="shortDescription"
                        impStar="*"
                        value={formData.shortDescription}
                        fullWidth
                        textLength={200}
                      />

                      {courseMode?.label === "Offline" && (
                        <CustomInput
                          label="Your location"
                          type="text"
                          placeholder=""
                          name="googleLocation"
                          impStar="*"
                          onChange={handleInputChange}
                          value={formData.googleLocation}
                        />
                      )}

                      {(courseLabel === "SSB Coaching" ||
                        type?.label === "GTO") &&
                        classType !== "Recorded" && (
                          <div className="start_date">
                            <div className="btn_group">
                              <div>
                                <label
                                  className={
                                    classesOption === "yes" ? "checked" : ""
                                  }
                                >
                                  <input
                                    type="radio"
                                    value="yes"
                                    checked={classesOption === "yes"}
                                    onChange={(e) => handleClassOption(e)}
                                  />
                                  One Time Course
                                </label>
                                <p>OR</p>
                                <label
                                  className={
                                    classesOption === "recurring"
                                      ? "checked"
                                      : ""
                                  }
                                >
                                  <input
                                    type="radio"
                                    value="recurring"
                                    checked={classesOption === "recurring"}
                                    onChange={(e) => handleClassOption(e)}
                                  />
                                  Recurring Course
                                </label>
                              </div>
                            </div>
                            {classesOption === "yes" ? (
                              <CustomDateInput
                                icon=""
                                label="Course Start Date"
                                type="date"
                                placeholder=""
                                name="startDate"
                                impStar="*"
                                onChange={handleInputChange}
                                value={formData.startDate}
                                disabledDate={getCurrentDate}
                              />
                            ) : (
                              <CustomMultiSelect
                                label="Course Start Every"
                                value={recurringClass}
                                defaultValue={recurringClass}
                                onChange={(val) => setRecurringClass(val)}
                                isMulti={false}
                                impStar="*"
                                optionArray={[
                                  {
                                    value: 1,
                                    label: "Monthly",
                                  },
                                  {
                                    value: 2,
                                    label: "Weekly",
                                  },
                                ]}
                              />
                            )}
                            {recurringClass?.label === "Monthly" && (
                              <CustomSelect
                                label="Starts on"
                                impStar="*"
                                name="monthlyClassDate"
                                onChange={handleInputChange}
                                value={formData.monthlyClassDate}
                                optionArray={monthArray}
                              />
                            )}
                            {recurringClass?.label === "Weekly" && (
                              <CustomSelect
                                label="Starts on"
                                name="weeklyClassDay"
                                onChange={handleInputChange}
                                optionArray={[
                                  {
                                    id: 1,
                                    option: "Monday",
                                  },
                                  {
                                    id: 2,
                                    option: "Tuesday",
                                  },
                                  {
                                    id: 3,
                                    option: "Wednesday",
                                  },
                                  {
                                    id: 4,
                                    option: "Thrusday",
                                  },
                                  {
                                    id: 5,
                                    option: "Friday",
                                  },
                                  {
                                    id: 6,
                                    option: "Saturday",
                                  },
                                  {
                                    id: 7,
                                    option: "Sunday",
                                  },
                                ]}
                                impStar=""
                                value={formData?.weeklyClassDay}
                                readOnly=""
                                placeholder=""
                                classStyle=""
                                borderColor=""
                              />
                            )}
                          </div>
                        )}

                      <CustomTextarea
                        label="Course Description"
                        className="course_feaure_list"
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Upto 1000 words and add minimum 150 words"
                        name="description"
                        impStar="*"
                        value={formData.description}
                        fullWidth
                        // textLength={1000}
                        textCount={1000}
                      />

                      {courseLabel === "Mock Psychology" &&
                        courseMode?.label === "Online" && (
                          <>
                            <CustomInput
                              label="Time needed to review the dossier(in Hrs)"
                              type="number"
                              placeholder="Ex :- 4 Hrs"
                              name="reviewTime"
                              impStar="*"
                              onChange={handleInputChange}
                              value={formData.reviewTime}
                            />
                          </>
                        )}
                      {courseLabel === "SSB Coaching" && (
                        <div
                          className="sc-irTswW izZXSN myInput course_feaure_list"
                          style={{ marginBottom: "20px" }}
                        >
                          <label>
                            Course Content (minimum 4)
                            <abbr className="impStar">*</abbr>
                          </label>
                          <div className="input_icon courseFeatures">
                            <input
                              className="input_text"
                              type="text"
                              placeholder="Add minimum 4"
                              name="content"
                              value={content}
                              onChange={(e) => setContent(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleAddContent();
                                }
                              }}
                            />
                            <button
                              title="Add More Content"
                              onClick={handleAddContent}
                            >
                              <FaPlus />
                            </button>
                          </div>
                          {contentList && contentList.length > 0 && (
                            <div className="feature_list">
                              <ul>
                                {contentList.map((ele, i) => (
                                  <li key={i}>
                                    <div>
                                      <p>{ele.content}</p>
                                      <span
                                        onClick={() => handleRemoveContent(i)}
                                      >
                                        <RxCross2 />
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}

                      {(courseLabel === "SSB Coaching" ||
                        courseLabel === "Complete Mock SSB") && (
                        <div className="collaborator_names">
                          <div className="btn_group">
                            <p>
                              Invite in this course
                              <div className="tooltip">
                                <FaInfoCircle />
                                <span className="tooltiptext">
                                  If adding an officer improves course quality,
                                  invite them, but they must be part of your
                                  Alpha Regiment Connection. add in the i button
                                  of invitation.
                                </span>
                              </div>
                            </p>
                            <div>
                              <label
                                className={
                                  collaboratorOption === "yes" ? "checked" : ""
                                }
                              >
                                <input
                                  type="radio"
                                  value="yes"
                                  checked={collaboratorOption === "yes"}
                                  onChange={(e) => handleRadioChange(e)}
                                />
                                Yes
                              </label>
                              <label
                                className={
                                  collaboratorOption === "no" ? "checked" : ""
                                }
                              >
                                <input
                                  type="radio"
                                  value="no"
                                  checked={collaboratorOption === "no"}
                                  onChange={(e) => handleRadioChange(e)}
                                />
                                No
                              </label>
                            </div>
                          </div>
                          {collaboratorOption === "yes" && (
                            <>
                              <div className="collab_input">
                                <CustomMultiSelect
                                  label="You can invites only your contacts"
                                  // impStar="*"
                                  value={collaboratorName}
                                  onChange={(val) => setCollaboratorName(val)}
                                  isMulti={false}
                                  optionArray={officerArray}
                                  // tooltip=""
                                  loading={officerListLoading}
                                />
                                <CustomInput
                                  label="Revenue in share"
                                  type="text"
                                  placeholder="Ex:5%"
                                  name="percentage"
                                  // impStar="*"
                                  icon={<MdOutlinePercent />}
                                  value={percentage}
                                  onChange={(e) =>
                                    setPercentage(e.target.value)
                                  }
                                />
                              </div>
                              <CustomButton
                                onClick={() => handleAddCollaborator()}
                                title="Add"
                                className="add_btn"
                              />
                              {collabError !== "" && (
                                <p
                                  className="error_msg"
                                  style={{ color: "red" }}
                                >
                                  {collabError}
                                </p>
                              )}
                              <div className="total_collabs">
                                <span>
                                  <p>Original Price: Rs {originalPrice}</p>
                                  {formData?.discount !== "" && (
                                    <p>Discount Per: {formData?.discount} %</p>
                                  )}
                                  {formData?.discountedPrice !== "" && (
                                    <p>
                                      Dis.Price: Rs {formData?.discountedPrice}{" "}
                                    </p>
                                  )}
                                  {/* {
                                <p>
                                  {((formData?.discountedPrice
                                      ? formData?.discountedPrice
                                      : originalPrice) *
                                      percentage) /
                                    100}
                                </p>
                              } */}
                                </span>
                              </div>
                            </>
                          )}

                          {collaborator.length > 0 && (
                            <>
                              <div className="timing_table">
                                <p>Collaborator List</p>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>%</th>
                                      <th>Amount</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {collaborator?.map((ele, index) => {
                                      const discountedPercent =
                                        ((formData?.discountedPrice
                                          ? formData?.discountedPrice
                                          : originalPrice) *
                                          ele?.percentage) /
                                        100;
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <p>{ele?.label}</p>
                                          </td>
                                          <td>
                                            <p>{ele?.percentage}</p>
                                          </td>
                                          <td>
                                            <p>
                                              <BsCurrencyRupee />{" "}
                                              {discountedPercent}
                                            </p>
                                          </td>
                                          <td>
                                            <button
                                              title="Delete Collab"
                                              onClick={() =>
                                                handleDeleteCollab(index)
                                              }
                                            >
                                              <RiDeleteBin5Fill />
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      <CustomInput
                        className="course_feaure_list"
                        label="Demo Video"
                        type="text"
                        placeholder="Youtube embed video link"
                        name="bannervideoLink"
                        tooltip="Ensure the video meets the following specifications: HD resolution, a preferred duration of 4 to 5 minutes, maintain an aspect ratio of 1:9, and limit the file size to a maximum of 100MB."
                        value={formData?.bannervideoLink?.name}
                        onChange={handleInputChange}
                      />

                      <div className="include_content">
                        <p>This includes</p>
                        {type?.label === "Interview" && (
                          <>
                            <CustomCheckbox
                              label="Written Feedback"
                              id="phn_call"
                              checked={checkboxStates.phn_call}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Recording of interview"
                              id="dossier"
                              checked={checkboxStates.dossier}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Can ask questions from GTO & Psychologist as well."
                              id="question"
                              checked={checkboxStates.question}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                          </>
                        )}
                        {courseLabel === "Mock Psychology" && (
                          <>
                            <CustomCheckbox
                              label="Full Length TAT(11+1 images)"
                              id="full_length_tat"
                              checked={checkboxStates.full_length_tat}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Full Length WAT(60 words)"
                              id="full_length_wat"
                              checked={checkboxStates.full_length_wat}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Full Length SRT(60 SRT)"
                              id="full_length_srt"
                              checked={checkboxStates.full_length_srt}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Full Length SD"
                              id="full_length_sd"
                              checked={checkboxStates.full_length_sd}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Phone Call By Psychologist"
                              id="phn_call_physclogist"
                              checked={checkboxStates.phn_call_physclogist}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Video Call By Psychologist"
                              id="video_call_physclogist"
                              checked={checkboxStates.video_call_physclogist}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Checked Dossier"
                              id="checked_dossier"
                              checked={checkboxStates.checked_dossier}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                          </>
                        )}

                        {(courseLabel === "SSB Coaching" ||
                          courseLabel === "Complete Mock SSB") && (
                          <>
                            <CustomCheckbox
                              label="Live GD"
                              id="live_gd"
                              checked={checkboxStates.live_gd}
                              onChange={(e) => handleCourseIncludes(e)}
                            />

                            <CustomCheckbox
                              label="Mock Interview"
                              id="mock_interview"
                              checked={checkboxStates.mock_interview}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="3D GTO simulator"
                              id="gto_simulator"
                              checked={checkboxStates.gto_simulator}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                          </>
                        )}

                        {(courseLabel === "SSB Coaching" ||
                          courseLabel === "Complete Mock SSB") && (
                          // ||
                          //   type?.label === "Psychology"
                          <>
                            <CustomCheckbox
                              label="Mock Psychology Test"
                              id="mock_psycho_test"
                              checked={checkboxStates.mock_psycho_test}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                            <CustomCheckbox
                              label="Study Material"
                              id="stdy_material"
                              checked={checkboxStates.stdy_material}
                              onChange={(e) => handleCourseIncludes(e)}
                            />
                          </>
                        )}

                        {customIncludeArray?.length > 0 && (
                          <div className="include_list">
                            {customIncludeArray?.map((ele, idx) => (
                              <div>
                                <button
                                  onClick={() => handleRemoveInclude(idx)}
                                >
                                  <RxCross2 />
                                </button>
                                <p key={idx}>{ele}</p>
                              </div>
                            ))}
                          </div>
                        )}

                        <div className="add_more_includes">
                          {includesInput && (
                            <>
                              <div className="include_input">
                                <CustomInput
                                  icon=""
                                  label="Add custom"
                                  type="text"
                                  onChange={(e) =>
                                    setCustomInclude(e.target.value)
                                  }
                                  placeholder=""
                                  passwordMessage=""
                                  name=""
                                  value={customInclude}
                                  impStar=""
                                />
                                <CustomButton
                                  title={<FaPlus />}
                                  width="50px"
                                  onClick={() => handleAddIncludes()}
                                />
                              </div>
                            </>
                          )}
                          <CustomButton
                            title={includesInput ? "Cancel" : "Add More"}
                            icon={!includesInput && <FaPlus />}
                            width="130px"
                            className="cancel_btn"
                            onClick={() => setIncludesInput(!includesInput)}
                          />
                        </div>
                      </div>
                      <div className="thumbnail">
                        <div className="profile_pic">
                          <img
                            src={avatarPreview ? avatarPreview : thumbnail}
                            alt="alpha user profile pic"
                          />
                          <label htmlFor="uploadProfilePic">
                            <FaUpload /> Upload Thumbnail (aspect ratio 16:9)
                          </label>
                          <CustomInput
                            id="uploadProfilePic"
                            label="Upload Course Image"
                            type="file"
                            impStar="*"
                            name="image"
                            accept="image/*"
                            onChange={handleAvatarChange}
                          />
                        </div>
                      </div>
                      {editable && (
                        <div className="update_btn">
                          <CustomButton
                            title={updateLoading ? <ButtonLoader /> : "Update"}
                            onClick={handleSubmit}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </section>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OfficerEditCourse;
