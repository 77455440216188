export const pageLinks = [
    {
        id: 1,
        title: "Terms of use",
        link: "/term-conditions",
    },
    {
        id: 2,
        title: "Privacy Policy",
        link: "/privacy-policies",
    },
    {
        id: 4,
        title: "AR School Terms & Condition",
        link: "/ar-school-term-conditions",
    },
    {
        id: 5,
        title: "Intellectual Property Policy",
        link: "/property-policies",
    },
    {
        id: 6,
        title: "Teachers Terms",
        link: "/teacher-term-conditions",
    },
    {
        id: 7,
        title: "Officers Term",
        link: "/officer-term-conditions",
    },
    {
        id: 8,
        title: "Medical Partner Terms",
        link: "/medical-partner-term-conditions",
    },
    {
        id: 9,
        title: "Physical Coach Terms",
        link: "/physical-coach-term-conditions",
    },
    {
        id: 10,
        title: "Cyber Partner Terms",
        link: "/cyber-partner-term-conditions",
    },
    {
        id: 11,
        title: "Franchisee Terms",
        link: "/franchise-partner-term-conditions",
    },
    {
        id: 12,
        title: "Accommodation Partners",
        link: "/accommodation-partners",
    },
];
