import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/reusable/Layout";
import "../../styles/scheduleInterview.scss";
import CustomButton from "../../components/reusable/CustomButton";
import { MdDateRange } from "react-icons/md";
import CustomDateInput from "../../components/reusable/CustomDateInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/userContext";
import {
  clearCalenderErrors,
  getOfficerAvalability,
} from "../../redux/actions/officerCalenderAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
const ScheduleInterview = () => {
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { image, courseData } = location?.state;
  const [, , showNotification] = useNotification();
  const [dateSelect, setDateSelect] = useState(null);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const handleAddTime = (itm) => {
    setStartTime(itm?.startTime);
    setEndTime(itm?.endTime);
  };

  // VALIDATE THE DATE INPUT
  const getCurrentDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    dispatch(getOfficerAvalability(courseData?.createdBy?._id));
  }, [courseData?.createdBy?._id, dispatch]);

  const {
    // eslint-disable-next-line
    loading: avalabilityLoading,
    error: avalabilityError,
    officerAvalability,
  } = useSelector((state) => state.getOfficerTimeAvalability);

  // console.log(officerAvalability);

  useEffect(() => {
    if (avalabilityError) {
      dispatch(clearCalenderErrors());
    }
  }, [avalabilityError, dispatch]);

  const [slotList, setSlotList] = useState();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setDateSelect(value);
    const weekdayCheck = moment(value).format("dddd");
    const filteredSlots = officerAvalability?.availability?.find(
      (availability) => {
        const availabilityWeekday = moment(availability.weekDay, "dddd").format(
          "dddd"
        );
        return availabilityWeekday === weekdayCheck;
      }
    );

    if (filteredSlots) {
      const data = filteredSlots?.classTime.map((time) => ({ ...time }));
      generateSlotIntervals(data);
    } else {
      setSlotList([]);
    }
  };

  const generateTimeIntervals = (startTime, endTime) => {
    const intervals = [];
    let current = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    while (current.isBefore(end)) {
      const nextHour = current.clone().add(1, "hour");
      intervals.push({
        startTime: current,
        endTime: nextHour,
      });
      current = nextHour;
    }
    return intervals;
  };

  const generateSlotIntervals = (slotList) => {
    slotList.forEach((slot) => {
      const { time: startTime, endTime } = slot;
      const intervals = generateTimeIntervals(startTime, endTime);
      setSlotList(intervals);
    });
  };


  const scheduleRedirect = () => {
    // const dateConvert = moment(dateSelect).format(
    //   "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    // );
    const formattedStartTime = moment(startTime, "HH:mm");
    const formattedEndTime = moment(endTime, "HH:mm");
    if (dateSelect && startTime && endTime) {
      const data = {
        officerId: courseData?.createdBy?._id,
        formattedDate: formattedStartTime,
        formattedStartTime: formattedStartTime,
        formattedEndTime: formattedEndTime,
        title: courseData?.type,
        student: auth?.user?._id,
      };
      // console.log(data);

      localStorage.setItem("eventObject", JSON.stringify(data));
     
      navigate(`/ssb-course-details/${courseData?.slug}`, {
        state: {
          modal: true,
          courseData: courseData,
          piq: image,
        },
      });
    } else {
      showNotification("error", "please fill required fields");
    }
  };

  return (
    <Fragment>
      <Layout>
        <div className="container">
         
        </div>
      </Layout>
    </Fragment>
  );
};

export default ScheduleInterview;
