import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import "../../styles/imgLazyLoad.scss";
import { logo_mobile } from "../../assets/images";

const ImgLazyLoad = ({ src, alt, placeholder, custom_class }) => {

  return (
    <ProgressiveImage
      src={src}
      placeholder={placeholder ? placeholder : logo_mobile}
    >
      {(src, loading) => (
        <img
          className={`${custom_class} image${loading ? " loading" : " loaded"}`}
          src={src}
          alt={alt}
        />
      )}
    </ProgressiveImage>
  );
};

export default ImgLazyLoad;
