import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { defaultUser, logo_main, logo_mobile } from "../../assets/images";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { useAuth } from "../../context/userContext";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { logout } from "../../redux/actions/userAction";
import CustomButton from "../reusable/CustomButton";
import { FaShoppingCart } from "react-icons/fa";

const StudentHeader = () => {
  const [userDropdown, setUserDropdown] = useState(false);
  const [openToggler, setOpenToggler] = useState(false);

  const [auth] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  // GET CART ITEMS USING CART REDUCER
  const { cartItems } = useSelector((state) => state.cart);

  return (
    <div className="header_main student_header_nav">
      <div className="container">
        <div className="nav_bar_sec">
          <div className="logo">
            <Link to="/">
              <img
                className="desktop"
                src={logo_main}
                alt="Alpha Regiment desktop_logo"
              />
              <img
                className="mobile"
                src={logo_mobile}
                alt="Alpha Regiment mobile_logo"
              />
            </Link>
          </div>
          <div className="open_navbar_btn">
            <GiHamburgerMenu onClick={() => setOpenToggler(!openToggler)} />
          </div>
          <div className={`nav_links ${openToggler ? "open_navbar" : ""}`}>
            <div className="close_menu_btn">
              <AiOutlineClose onClick={() => setOpenToggler(!openToggler)} />
            </div>

            {auth?.user?._id && (
              <div className="user_details_nav_link_mobile">
                <div className="userDetails">
                  <div className="profileImg">
                    <img
                      src={
                        auth?.user?.profile?.url
                          ? auth?.user?.profile?.url
                          : defaultUser
                      }
                      alt={auth?.user?.first_name}
                    />
                  </div>
                  <div className="userName">
                    {" "}
                    {auth?.user?.first_name?.slice(0, 10)}
                  </div>
                </div>
                <div className="auth_user_links">
                  <Link
                    to={
                      auth?.user?.role === "16" ||
                      auth?.user?.role === "17" ||
                      auth?.user?.role === "18"
                        ? "/officer/profile"
                        : auth?.user?.role === "12" || auth?.user?.role === "13"
                        ? "/teacher/profile"
                        : "/student/profile"
                    }
                  >
                    Profile
                  </Link>
                  <CustomButton title="Log Out" onClick={handleLogout} />
                </div>
              </div>
            )}
            {/* <div className="nav_link">
              <NavLink to="/student/lms">Student Dashboard</NavLink>
            </div>
            <div className="nav_link">
              <NavLink
                to="https://blog.alpharegiment.com"
                target="_blank"
                rel="nofollow noopener"
              >
                News & Articles
              </NavLink>
            </div>
            <div className="nav_link">
              <NavLink
                to="https://qna.alpharegiment.com"
                target="_blank"
                rel="nofollow noopener"
              >
                QnA
              </NavLink>
            </div>
            <div className="nav_link">
              <NavLink to="/products">Products</NavLink>
            </div> */}
            {auth?.user?.role === "11" && (
              <div
                className="cart_btn"
                onClick={() => navigate("/student/cart")}
              >
                <FaShoppingCart />
                <p>{cartItems?.length}</p>
              </div>
            )}
            <div className="nav_link dropdown_nav">
              {auth?.user?._id && (
                <div className="auth_profile_dropdown">
                  <button
                    className={`sign_up_btn dashboard_btn ${
                      auth?.user?.first_name ? "profile_header_btn" : ""
                    }`}
                    onClick={() => setUserDropdown(!userDropdown)}
                  >
                    <img
                      src={
                        auth?.user?.profile?.url
                          ? auth?.user?.profile?.url
                          : defaultUser
                      }
                      alt={auth?.user?.first_name}
                    />
                  {auth?.user?.first_name?.slice(0, 10)}
                    {userDropdown ? (
                      <BsFillCaretUpFill />
                    ) : (
                      <BsFillCaretDownFill />
                    )}
                  </button>
                  <div
                    className={`nav_dropdown_links ${
                      userDropdown ? "show_nav_dropdown" : ""
                    }`}
                  >
                    <Link
                      to={
                        auth?.user?.role === "16" ||
                        auth?.user?.role === "17" ||
                        auth?.user?.role === "18"
                          ? "/officer/profile"
                          : auth?.user?.role === "12" ||
                            auth?.user?.role === "13"
                          ? "/teacher/profile"
                          : "/student/profile"
                      }
                    >
                      Profile
                    </Link>
                    <button className="logout_btn" onClick={handleLogout}>
                      logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentHeader;
