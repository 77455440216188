import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { defaultUser, workshop_hero } from "../assets/images";
import "../styles/friendProfile.scss";
import { CgCalendarDates } from "react-icons/cg";
import { arRole } from "../utils/userRole";
import { BsPersonFill, BsPersonFillAdd, BsShareFill } from "react-icons/bs";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { MdPsychologyAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNotification } from "../context/notificationContext";
import { useSelector } from "react-redux";
import {
  clearFollowErrors,
  clearFollowState,
  clearFriendProfileError,
  getFollowerList,
  getFriendDetails,
  sendFollowRequest,
} from "../redux/actions/followAction";
import moment from "moment";
import Loader from "../components/reusable/Loader";
import { RWebShare } from "react-web-share";
import CustomButton from "../components/reusable/CustomButton";
import ButtonLoader from "../components/reusable/ButtonLoader";
import { useAuth } from "../context/userContext";
import {
  getSsbCoursesByOfficerId,
  ssbClearErrors,
} from "../redux/actions/ssbCourseAction";
import ComponentLoading from "../components/reusable/ComponentLoading";
import { BiLeftArrowAlt } from "react-icons/bi";
import { FaRegThumbsUp } from "react-icons/fa";
import {
  clearOfficerErrors,
  getTimelinePostByOfficerAction,
  likeTimelinePostAction,
} from "../redux/actions/officerAction";
const ShareProfile = () => {
  const { id } = useParams();
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();

  const [activeTab, setActiveTab] = useState("courses");

  const { error, loading, friendDetails } = useSelector(
    (state) => state.getFriendProfile
  );
  const handleActiveTab = (active) => {
    setActiveTab(active);
  };
  useEffect(() => {
    dispatch(getFriendDetails(id));
  }, [dispatch, id]);

  const joinedTime = moment(friendDetails?.user?.createdAt).format("MMMM YYYY");

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearFriendProfileError());
    }
  }, [dispatch, showNotification, error]);

  const {
    error: requestError,
    loading: requestLoading,
    followInfo,
  } = useSelector((state) => state.sendRequest);

  const handleRequestSend = (id) => {
    if (!auth?.user?._id) {
      showNotification("error", "Please login before follow the officer");
      navigate("/login");
    } else {
      dispatch(sendFollowRequest(id));
    }
  };

  // SSB COURSE DETAILS HANDLER
  const handleSsbDetailsPage = (data) => {
    const { slug } = data;
    // const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    // const formattedMode = courseMode.toLowerCase().replace(/\s+/g, "-");
    // const formattedCategory = category.toLowerCase().replace(/\s+/g, "-");
    const seoFriendlyUrl = `/ssb-course-details/${slug}`;
    navigate(seoFriendlyUrl);
  };

  // HANDLE FOLLOW ERROR
  useEffect(() => {
    if (requestError) {
      showNotification("error", requestError);
      dispatch(clearFollowErrors());
    }
    if (followInfo?.success) {
      showNotification("success", followInfo?.message);
      dispatch(clearFollowState());
      dispatch(getFollowerList());
    }
  }, [
    requestError,
    showNotification,
    dispatch,
    followInfo?.message,
    followInfo?.success,
  ]);

  // GET ALL POST BY  OFFICER  ID
  const {
    loading: officerPostLoading,
    error: officerPostError,
    postData,
  } = useSelector((state) => state.getAllPostByOfficer);

  // const { loading, error, courses: data } = officerSsbCourses;

  useEffect(() => {
    dispatch(getTimelinePostByOfficerAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (officerPostError) {
      showNotification("error", officerPostError);
      dispatch(clearOfficerErrors());
    }
  }, [dispatch, officerPostError, showNotification]);

  const {
    loading: officerCourseLoading,
    error: officerCourseError,
    courses: data,
  } = useSelector((state) => state.getCoursesByOfficerId);

  useEffect(() => {
    dispatch(getSsbCoursesByOfficerId(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (officerCourseError) {
      showNotification("error", officerCourseError);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, officerCourseError, showNotification]);

  // HANDLE VIEW PROFILE
  const handleViewFriend = (itm) => {
    navigate(`/friend-profile/${itm?.createdBy?._id}`);
  };

  const [likeData, setLikeData] = useState([]);

  // HANDLE POST LIKE
  const handlePostLike = (id) => {
    const postId = postData?.posts.find((post) => post._id === id);
    if (auth?.user?._id) {
      const postIdInLikes = postId?.likes.find(
        (like) => like._id === auth?.user?._id
      );
      const filterId = likeData?.includes(postIdInLikes?._id);
      if (filterId) {
        const filterData = likeData?.filter(
          (itm) => itm !== postIdInLikes?._id
        );
        setLikeData(filterData);
      } else {
        setLikeData((prevData) => [...prevData, postIdInLikes?._id]);
      }
      dispatch(likeTimelinePostAction(postId?._id));
    } else {
      navigate(`/login`, { state: `/view-profile/${postId?.createdBy?._id}` });
    }
  };

  // const handlePostLike = (postId) => {
  //   if (auth?.user?._id) {
  //     const post = postData?.posts.find(post => post._id === postId);
  //     const postIdInLikes = post?.likes.map(like => like._id);

  //     if (postIdInLikes.includes(auth.user._id)) {
  //       const updatedLikes = post.likes.filter(like => like._id !== auth.user._id);
  //       const updatedPost = { ...post, likes: updatedLikes };
  //       const updatedPosts = postData?.posts.map(p => p._id === postId ? updatedPost : p);
  //       setPosts(updatedPosts);
  //     } else {
  //       const updatedLikes = [...post.likes, { _id: auth.user._id }];
  //       const updatedPost = { ...post, likes: updatedLikes };
  //       const updatedPosts = postData?.posts.map(p => p._id === postId ? updatedPost : p);
  //       setPosts(updatedPosts);
  //     }
  //     dispatch(likeTimelinePostAction(auth?.user?._id));
  //   } else {
  //     navigate(`/login`, { state: { returnUrl: `/view-profile/${postId}` } });
  //   }
  // };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="friend_timeline_main txt_color">
          <div className="title">
            <button title="Go Back" onClick={() => navigate(-1)}>
              <BiLeftArrowAlt />
            </button>
            {friendDetails?.user?.first_name} Timeline
          </div>
          <div className="container">
            <div className="officer_timeline_content">
              <div className="officerProfile">
                {/* // PROFILE CONTENT START */}
                <div className="profileCover">
                  <img src={workshop_hero} alt="alpha officer profile cover" />
                </div>
                <div className="profilePic">
                  <img
                    src={
                      friendDetails?.user?.profile?.url
                        ? friendDetails?.user?.profile?.url
                        : defaultUser
                    }
                    alt="alpha officer profile"
                  />
                </div>

                <div className="userDetails">
                  <div className="buttonGrp">
                    {/* {connectionList?.connections?.length > 0 && (
                      <CustomButton title="Message" />
                    )} */}

                    <div className="shareBtn">
                      <CustomButton
                        className=""
                        title={requestLoading ? <ButtonLoader /> : "Follow"}
                        onClick={() =>
                          handleRequestSend(friendDetails?.user?._id)
                        }
                        disabled=""
                        preIcon={requestLoading ? "" : <BsPersonFillAdd />}
                      />
                      <RWebShare
                        data={{
                          url: `${window.location.origin}/view-profile/${id}`,
                          title: `Share ${friendDetails?.user?.first_name} profile`,
                        }}
                        onClick={() => console.log("user")}
                      >
                        <CustomButton
                          className="shareButton"
                          preIcon={<BsShareFill />}
                          tooltip="Share Friend Profile"
                        />
                      </RWebShare>
                    </div>
                  </div>
                  <div className="general">
                    <h2 className="name">{friendDetails?.user?.first_name}</h2>
                    <p className="uniqueCode">
                      {friendDetails?.user?.couponCode}
                    </p>
                    <p className="tag">
                      {friendDetails?.user?.role === "16" ? (
                        <GiPoliceOfficerHead />
                      ) : friendDetails?.user?.role === "17" ? (
                        <BsPersonFill />
                      ) : (
                        friendDetails?.user?.role === "18" && (
                          <MdPsychologyAlt />
                        )
                      )}
                      {arRole(friendDetails?.user?.role)}
                    </p>
                    <p className="bio">{friendDetails?.user?.teacherBio}</p>
                  </div>
                </div>

                <div className="locationBirth">
                  <p>
                    <CgCalendarDates /> Joined {joinedTime}{" "}
                  </p>
                </div>

                <div className="timeline_tabs">
                  <ul className="tabs">
                    <li
                      className={`${activeTab === "all_post" ? "active" : ""}`}
                      onClick={() => handleActiveTab("all_post")}
                    >
                      <p>Posts</p>
                    </li>
                    <li
                      className={`${activeTab === "courses" ? "active" : ""}`}
                      onClick={() => handleActiveTab("courses")}
                    >
                      <p>Courses</p>
                    </li>
                  </ul>

                  {activeTab === "all_post" &&
                    (officerPostLoading ? (
                      <ComponentLoading />
                    ) : postData?.posts?.length > 0 ? (
                      <div className="post_list">
                        {postData?.posts?.map((itm) => (
                          <div className="post_card" key={itm?._id}>
                            <img
                              src={
                                itm?.createdBy?.profile?.url
                                  ? itm?.createdBy?.profile?.url
                                  : defaultUser
                              }
                              alt={itm?.createdBy?.first_name}
                            />
                            <div className="post_details">
                              <div className="title">
                                <div className="name">
                                  <p onClick={() => handleViewFriend(itm)}>
                                    {itm?.createdBy?.first_name}
                                  </p>
                                  <span>
                                    {moment(itm?.createdAt).format(
                                      "MMMM DD[,] YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <p>{itm?.label}</p>
                              {itm?.thumbnail?.url !== "" && (
                                <img
                                  src={itm?.thumbnail?.url}
                                  alt="postimage"
                                />
                              )}
                              <div className="post_footer">
                                <CustomButton
                                  title={`${
                                    itm?.likes?.length > 0
                                      ? itm?.likes?.length
                                      : ""
                                  } Like`}
                                  className={`like_btn ${
                                    itm?.likes &&
                                    itm?.likes?.some(
                                      (like) =>
                                        like?._id === auth?.user?._id ||
                                        likeData?.includes(like?._id)
                                    )
                                      ? "active"
                                      : ""
                                  }`}
                                  width="100px"
                                  onClick={() => handlePostLike(itm?._id)}
                                  icon={<FaRegThumbsUp />}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p style={{ textAlign: "center" }}>No post yet</p>
                    ))}

                  {activeTab === "courses" && (
                    <div className="course_list">
                      {officerCourseLoading ? (
                        <ComponentLoading />
                      ) : data?.courses?.length > 0 ? (
                        data?.courses?.map((item) => (
                          <div className="course_card" key={item?._id}>
                            <img src={item?.thumbnail?.url} alt="user" />
                            <p>{item?.title}</p>
                            <div className="view_btn">
                              <CustomButton
                                title="View Detail"
                                width="110px"
                                className="btn"
                                onClick={() => handleSsbDetailsPage(item)}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="txt_color">No Course Created Yet</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ShareProfile;
