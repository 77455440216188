import React, { Fragment, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import "../../styles/oTeacherMcqCreate.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { FaPlus } from "react-icons/fa";
import CustomButton from "../../components/reusable/CustomButton";
import {
  clearMcqErrors,
  clearMcqState,
  createNewMCQ,
  getMcqById,
} from "../../redux/actions/mcqAction";
import { useSelector } from "react-redux";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import CustomSelect from "../../components/reusable/CustomSelect";
import Loader from "../../components/reusable/Loader";

const OTeacherMcqEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [currentStep, setCurrentStep] = useState(1);

  //   GET MCQ SET DETAILS

  useEffect(() => {
    if (id) {
      dispatch(getMcqById(id));
    }
  }, [id, dispatch]);

  const {
    loading: getLoading,
    error: getError,
    mcqDetail,
  } = useSelector((state) => state.getMcqDetails);

  // console.log(mcqDetail);

  useEffect(() => {
    if (getError) {
      dispatch(clearMcqErrors());
    }
  }, [getError, dispatch]);

  //   GET MCQ SET DETAILS

  const [formData, setFormData] = useState({
    title: "",
    testTime: "",
    type: "",
  });

  const { title, testTime, type } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // QUESTION ADDING FUNCTIONS STARTS

  const [questionList, setQuestionList] = useState([]);
  const [question, setQuestion] = useState("");
  const [option, setOption] = useState();
  const [optionList, setOptionList] = useState([]);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(null);

  const handleOptionChange = (index) => {
    setCorrectOptionIndex(index);
  };

  const handleAddOption = () => {
    setOptionList([...optionList, { option: option, isRight: false }]);
    setOption("");
  };

  const handleRemoveOption = (id) => {
    const updatedOptionList = optionList.filter((_, i) => i !== id);
    setOptionList(updatedOptionList);
  };

  const handleAddQuestionList = () => {
    if (correctOptionIndex !== null) {
      const updatedOptions = optionList.map((opt, index) => ({
        ...opt,
        isRight: index === correctOptionIndex,
      }));
      setQuestionList([
        ...questionList,
        { question: question, options: updatedOptions },
      ]);
      setQuestion("");
      setOptionList([]);
      setCorrectOptionIndex(null);
    } else {
      showNotification("error", "Please select a correct option.");
    }
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestionList = questionList.filter((_, i) => i !== index);
    setQuestionList(updatedQuestionList);
  };

  // QUESTION ADDING FUNCTIONS ENDS

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  // const [partialQuestion, setPartialQuestion] = useState([]);

  const [optionMarks, setOptionMarks] = useState("");
  const handleAddPartialOption = () => {
    if (optionMarks === "" || option === "") {
      showNotification("error", "Please add both value");
    } else {
      setOptionList([...optionList, { option: option, marks: optionMarks }]);
      setOption("");
      setOptionMarks("");
    }
  };

  // console.log(optionList);
  const handleRemovePartialOption = (id) => {
    const updatedOptionList = optionList.filter((_, i) => i !== id);
    setOptionList(updatedOptionList);
  };

  const handleAddPartialQuestionList = () => {
    setQuestionList([
      ...questionList,
      { question: question, options: optionList },
    ]);
    setQuestion("");
    setOptionList([]);
  };
  const handleDeletePartialQuestion = (index) => {
    const updatedQuestionList = questionList.filter((_, i) => i !== index);
    setQuestionList(updatedQuestionList);
  };

  const handleNext = () => {
    if (currentStep < 3) {
      if (currentStep === 1 && (title === "" || type === "")) {
        showNotification("error", "Please require all fields");
      }
      if (currentStep === 2 && type === "MCQ" && questionList?.length < 1) {
        showNotification("error", "Please add question");
      } else if (
        currentStep === 2 &&
        type === "Partial credit questions" &&
        questionList?.length < 1
      ) {
        showNotification("error", "Please add question");
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  let totalSteps = 3;
  // progressbar width

  const calculateProgress = () => {
    return (currentStep / totalSteps) * 100;
  };

  const handleSubmit = () => {
    dispatch(createNewMCQ(title, testTime, questionList));
  };

  const { loading, error, mcqCreate } = useSelector((state) => state.createMcq);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearMcqErrors());
    }
    if (mcqCreate?.success) {
      showNotification("success", mcqCreate?.message);
      dispatch(clearMcqState());
      navigate("/teacher/mcq");
    }
  }, [
    navigate,
    dispatch,
    error,
    showNotification,
    mcqCreate?.success,
    mcqCreate?.message,
  ]);

  return (
    <Fragment>
      {getLoading ? (
        <Loader />
      ) : (
        <div className="oTeacher_create_mcq">
          <div className="header">
            <div className="title">
              Create MCQ's - Step {currentStep}
              <button title="Go Back" onClick={() => navigate(-1)}>
                <RxCross2 />
              </button>
            </div>

            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{ width: `${calculateProgress()}%` }}
              ></div>
            </div>
          </div>
          <div className="container">
            <div>
              <div className="create_course_content">
                <div className="courseForm">
                  {currentStep === 1 && (
                    <>
                      <CustomInput
                        label="Enter Title"
                        placeholder="20 min quick revision test"
                        impStar="*"
                        type="text"
                        value={formData?.title}
                        name="title"
                        onChange={handleInputChange}
                      />
                      <CustomSelect
                        label="Type"
                        onChange={handleInputChange}
                        value={formData?.type}
                        name="type"
                        optionArray={[
                          {
                            option: "Partial credit questions",
                          },
                          {
                            option: "MCQ",
                          },
                        ]}
                      />
                    </>
                  )}
                  {currentStep === 2 && formData?.type === "MCQ" && (
                    <div className="questions">
                      <div className="sc-irTswW izZXSN myInput">
                        <label>
                          Question {questionList?.length + 1}
                          <abbr className="impStar">*</abbr>
                        </label>
                        <div className="input_icon">
                          <input
                            className="input_text"
                            type="text"
                            placeholder="What are the main thing to do in ssb"
                            name="question"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="sc-irTswW izZXSN myInput option_add">
                        <label>
                          Add Option
                          <abbr className="impStar">*</abbr>
                        </label>
                        <div className="input_icon">
                          <input
                            className="input_text"
                            type="text"
                            placeholder="What are the main thing to do in ssb"
                            name="option"
                            value={option}
                            onChange={(e) => setOption(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleAddOption();
                              }
                            }}
                          />
                          <button
                            className="add_option"
                            onClick={() => handleAddOption()}
                          >
                            <FaPlus />
                          </button>
                        </div>
                      </div>

                      <p>Note : Select one option as correct answer</p>

                      {optionList?.length > 0 && (
                        <div className="option_list">
                          {optionList.map((option, index) => (
                            <div key={index}>
                              <div>
                                <input
                                  type="radio"
                                  id={`option${index}`}
                                  name="options"
                                  checked={correctOptionIndex === index}
                                  onChange={() => handleOptionChange(index)}
                                />
                                <label htmlFor={`option${index}`}>
                                  {option.option}
                                </label>
                              </div>
                              <button onClick={() => handleRemoveOption(index)}>
                                <RxCross2 />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {optionList?.length > 0 && (
                        <div className="add_question_btn">
                          <CustomButton
                            title="Add More Question"
                            width="200px"
                            onClick={() => handleAddQuestionList()}
                          />
                        </div>
                      )}

                      {questionList?.length > 0 && (
                        <div className="question_listing">
                          {questionList?.map((ele, idx) => (
                            <div className="question_Card" key={idx}>
                              <div>
                                <p>
                                  <span>Question {idx + 1} :</span>{" "}
                                  <span>{ele?.question}</span>
                                </p>
                                <ul>
                                  <p>Options :</p>
                                  {ele?.options?.map((itm, id) => (
                                    <li key={id}>
                                      {id + 1}. <span>{itm?.option}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <button onClick={() => handleDeleteQuestion(idx)}>
                                <RxCross2 />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  {currentStep === 2 &&
                    formData?.type === "Partial credit questions" && (
                      <div className="questions">
                        <div className="sc-irTswW izZXSN myInput">
                          <label>
                            Question {questionList?.length + 1}
                            <abbr className="impStar">*</abbr>
                          </label>
                          <div className="input_icon">
                            <input
                              className="input_text"
                              type="text"
                              placeholder="What are the main thing to do in ssb"
                              name="question"
                              value={question}
                              onChange={(e) => setQuestion(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="sc-irTswW izZXSN myInput option_add">
                          <label>
                            Add Option
                            <abbr className="impStar">*</abbr>
                          </label>
                          <div className="input_icon">
                            <input
                              className="input_text"
                              type="text"
                              placeholder="What are the main thing to do in ssb"
                              name="option"
                              value={option}
                              onChange={(e) => setOption(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleAddPartialOption();
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="sc-irTswW izZXSN myInput option_add">
                          <label>
                            Add marks for option
                            <abbr className="impStar">*</abbr>
                          </label>
                          <div className="input_icon">
                            <input
                              className="input_text"
                              type="number"
                              placeholder="Ex: 5"
                              name="optionMarks"
                              value={optionMarks}
                              onChange={(e) => setOptionMarks(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleAddPartialOption();
                                }
                              }}
                            />

                            <button
                              className="add_option"
                              onClick={() => handleAddPartialOption()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                        {/* <p>Note : Select one option as correct answer</p> */}

                        {optionList?.length > 0 && (
                          <div className="option_list">
                            {optionList.map((option, index) => (
                              <div key={index}>
                                <div>
                                  <p>
                                    {index + 1}.
                                    <span>
                                      {option.option} ({option.marks + "marks"})
                                    </span>
                                    {/* <span>
                                      {option?.marks}
                                    </span> */}
                                  </p>
                                </div>
                                <button
                                  onClick={() =>
                                    handleRemovePartialOption(index)
                                  }
                                >
                                  <RxCross2 />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}

                        {optionList?.length > 0 && (
                          <div className="add_question_btn">
                            <CustomButton
                              title="Save Question"
                              width="200px"
                              onClick={() => handleAddPartialQuestionList()}
                            />
                          </div>
                        )}

                        {questionList?.length > 0 && (
                          <div className="question_listing">
                            {questionList?.map((ele, idx) => (
                              <div className="question_Card" key={idx}>
                                <div>
                                  <p>
                                    <span>Question {idx + 1} :</span>{" "}
                                    <span>{ele?.question}</span>
                                  </p>
                                  <ul>
                                    <p>Options :</p>
                                    {ele?.options?.map((itm, id) => (
                                      <li key={id}>
                                        {id + 1}. <span>{itm?.option}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <button
                                  onClick={() =>
                                    handleDeletePartialQuestion(idx)
                                  }
                                >
                                  <RxCross2 />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                  {currentStep === 3 && (
                    <>
                      <div className="review_details">
                        <h2 className="txt-center">Review your test details</h2>
                        <div className="review_wrap">
                          <ol>
                            <li>
                              <div>
                                <p>Test Title :</p>
                                <p>{formData?.title}</p>
                              </div>
                            </li>
                            {/* <li>
                                <div>
                                  <p>Test Time :</p>
                                  <p>{formData?.testTime} minutes</p>
                                </div>
                              </li> */}
                          </ol>

                          <ol>
                            <li>
                              <div className="feature">
                                <span>{type} List :</span>
                                <div className="feature_list">
                                  {questionList?.map((ele, idx) => (
                                    <span key={idx}>
                                      {idx + 1}. {ele?.question}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                      <div className="createBtn">
                        <CustomButton
                          title={loading ? <ButtonLoader /> : `Create MCQ'S`}
                          onClick={handleSubmit}
                          // disabled={true}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="navigation_buttons">
              {currentStep > 1 && (
                <button onClick={handlePrev} className="prev">
                  Previous
                </button>
              )}
              {currentStep < 3 && (
                <button onClick={handleNext} className="next">
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OTeacherMcqEdit;
