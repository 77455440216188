import React, { useState } from 'react'
import { RiCloseFill, RiMenuUnfoldLine } from 'react-icons/ri';
import { useAuth } from '../../context/userContext';
// import TeacherHeader from './TeacherHeader';
import ImgLazyLoad from '../reusable/ImgLazyLoad';
import { NavLink } from 'react-router-dom';
import { defaultUser } from '../../assets/images';
import "../../styles/teacherLayout.scss";
import Header from '../reusable/Header';
const TeacherLayout = ({ children, sidebarLinks }) => {
  const [showSideNav, setShowSideNav] = useState(false);

  const [auth] = useAuth();
  return (
    <section className="teacher_layout_section">
      {/* STUDENT HEADER START */}
      {/* <TeacherHeader /> */}
      <Header />
      {/* STUDENT HEADER ENDS */}

      <section className="teacher_layout_main">
        <button
          className="open_close_sidebar"
          onClick={() => setShowSideNav(!showSideNav)}
        >
          {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
        </button>
        <div
          className={`profile_sidebar ${showSideNav ? "showProfile_sidebar" : ""
            }`}
        >
          <button
            className="open_close_sidebar navClose_btn"
            onClick={() => setShowSideNav(false)}
          >
            <RiCloseFill />
          </button>
          <div className="sidebar_content">
            <div className="user_profileCard">
              {/* <img src={defaultUser} alt="alpha regiment User" /> */}
              <ImgLazyLoad
                src={
                  auth?.user?.profile?.url
                    ? auth?.user?.profile?.url
                    : defaultUser
                }
                alt="alpha regiment user"
              />
              <h1>
              {auth?.user?.first_name} {auth?.user?.middle_name}{" "}
              {auth?.user?.last_name}
              </h1>
            </div>
            {sidebarLinks?.map((item) => {
              return (
                <div className="profile_sidebarLink" key={item.id}>
                  <NavLink to={item?.link}>{item?.title}</NavLink>
                </div>
              );
            })}
          </div>
        </div>
        <div className="profile_content">{children}</div>
      </section>
      
    </section>
  )
}

export default TeacherLayout