import axios from "axios";
import { axiosInstance } from "../../utils/axiosConfig";
import {
  SSB_COURSES_CREATE_FAIL,
  SSB_COURSES_CREATE_SUCCESS,
  SSB_COURSES_CREATE_REQUEST,
  SSB_COURSES_FAIL,
  SSB_COURSES_REQUEST,
  SSB_COURSES_SUCCESS,
  SSB_CLEAR_ERRORS,
  // SSB_COURSE_DETAIL_FAIL,
  // SSB_COURSE_DETAIL_REQUEST,
  // SSB_COURSE_DETAIL_SUCCESS,
  SSB_CLEAR_STATE,
  OFFICER_SSB_COURSES_REQUEST,
  OFFICER_SSB_COURSES_SUCCESS,
  OFFICER_SSB_COURSES_FAIL,
  SSB_COURSE_DETAIL_REQUEST,
  SSB_COURSE_DETAIL_SUCCESS,
  SSB_COURSE_DETAIL_FAIL,
  OFFICER_SSB_COURSES_DELETE_SUCCESS,
  OFFICER_SSB_COURSES_DELETE_REQUEST,
  OFFICER_SSB_COURSES_DELETE_FAIL,
  SSB_COURSE_ADD_REVIEW_REQUEST,
  SSB_COURSE_ADD_REVIEW_SUCCESS,
  SSB_COURSE_ADD_REVIEW_FAIL,
  SSB_COURSE_DELETE_REVIEW_REQUEST,
  SSB_COURSE_DELETE_REVIEW_SUCCESS,
  SSB_COURSE_DELETE_REVIEW_FAIL,
  SSB_CLEAR_DELETE_STATE,
  SSB_RATING_CLEAR_ERRORS,
  SSB_RATING_CLEAR_DELETE_STATE,
  SSB_RATING_CLEAR_STATE,
  SSB_COURSES_PAUSE_REQUEST,
  SSB_COURSES_PAUSE_SUCCESS,
  SSB_COURSES_PAUSE_FAIL,
  SSB_COURSES_BY_OFFICER_ID_REQUEST,
  SSB_COURSES_BY_OFFICER_ID_SUCCESS,
  SSB_COURSES_BY_OFFICER_ID_FAIL,
  SSB_COURSE_COUPON_CREATE_REQUEST,
  SSB_COURSE_COUPON_CREATE_SUCCESS,
  SSB_COURSE_COUPON_CREATE_FAIL,
  CLEAR_CREATE_SSB_COUPON_STATE,
  SSB_COURSES_UPDATE_REQUEST,
  SSB_COURSES_UPDATE_SUCCESS,
  SSB_COURSES_UPDATE_FAIL,
  SSB_COURSE_CLEAR_UPDATE_STATE,
} from "../constants/ssbCourseConstant";

export const ssbCourseCreateAction =
  (
    title,
    shortDescription,
    description,
    originalPrice,
    discount,
    discountedPrice,
    discountValidity,
    courseMode,
    type,
    thumbnail,
    startDate,
    featureList,
    timeTable,
    category,
    bannervideoLink,
    collaborator,
    classType,
    googleLocation,
    reviewTime,
    courseInclude,
    dossier,
    weeklyClassDay,
    monthlyClassDate,
    courseLanguage,
    courseContent,
    courseDuration
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SSB_COURSES_CREATE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/ssb/create-course",
        {
          title,
          shortDescription,
          description,
          originalPrice,
          discount,
          discountValidity,
          discountedPrice,
          courseMode,
          type,
          thumbnail,
          startDate,
          featureList,
          timeTable,
          category,
          bannervideoLink,
          collaborator,
          classType,
          googleLocation,
          reviewTime,
          courseInclude,
          dossier,
          weeklyClassDay,
          monthlyClassDate,
          courseLanguage,
          courseContent,
          courseDuration,
        },
        config
      );
      dispatch({
        type: SSB_COURSES_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SSB_COURSES_CREATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE SSB COURSE

export const ssbCourseUpdateAction =
  (
    id,
    title,
    shortDescription,
    description,
    originalPrice,
    discount,
    discountValidity,
    // discountedPrice,
    courseMode,
    type,
    // thumbnail,
    startDate,
    featureList,
    timeTable,
    category,
    bannervideoLink,
    collaborator,
    classType,
    googleLocation,
    reviewTime,
    courseInclude,
    dossier,
    weeklyClassDay,
    monthlyClassDate,
    courseLanguage,
    courseContent,
    courseDuration
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SSB_COURSES_UPDATE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/ssb/update-course/${id}`,
        {
          title,
          shortDescription,
          description,
          originalPrice,
          discount,
          discountValidity,
          // discountedPrice,
          courseMode,
          type,
          // thumbnail,
          startDate,
          featureList,
          timeTable,
          category,
          bannervideoLink,
          collaborator,
          classType,
          googleLocation,
          reviewTime,
          courseInclude,
          dossier,
          weeklyClassDay,
          monthlyClassDate,
          courseLanguage,
          courseContent,
          courseDuration,
        },
        config
      );
      dispatch({
        type: SSB_COURSES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SSB_COURSES_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL COURSES
export const getAllSsbCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: SSB_COURSES_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/ssb/courses", config);

    dispatch({
      type: SSB_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SSB_COURSES_FAIL,
      payload: error.response.data.message,
    });
  }
};
// GET COURSES BY OFFICER ID
export const getSsbCoursesByOfficerId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SSB_COURSES_BY_OFFICER_ID_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/ssb/created-courses/${id}`,
      config
    );

    dispatch({
      type: SSB_COURSES_BY_OFFICER_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SSB_COURSES_BY_OFFICER_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET OFFICER COURSES
export const getOfficerSsbCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: OFFICER_SSB_COURSES_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/ssb/my-courses", config);

    dispatch({
      type: OFFICER_SSB_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OFFICER_SSB_COURSES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET SSB COURSE DETAILS
export const getSsbCourseDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SSB_COURSE_DETAIL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/ssb/course/${id}`, config);

    dispatch({
      type: SSB_COURSE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SSB_COURSE_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE SSB COURSE
export const deletSsbCourse = (id) => async (dispatch) => {
  try {
    dispatch({
      type: OFFICER_SSB_COURSES_DELETE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/ssb/course/${id}`, config);

    dispatch({
      type: OFFICER_SSB_COURSES_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OFFICER_SSB_COURSES_DELETE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// PAUSE SSB COURSE
export const pauseSsbCourse =
  (id, pauseForDays, isPause) => async (dispatch) => {
    try {
      dispatch({
        type: SSB_COURSES_PAUSE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/ssb/course/${id}`,
        {
          pauseForDays,
          isPause,
        },
        config
      );

      dispatch({
        type: SSB_COURSES_PAUSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SSB_COURSES_PAUSE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// SSB COURSE RATING ADD
export const ssbCourseAddRatingAction =
  (courseId, rating, comment) => async (dispatch) => {
    try {
      dispatch({
        type: SSB_COURSE_ADD_REVIEW_REQUEST,
      });
      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/ssb/course/review/${courseId}`,
        {
          rating,
          comment,
        },
        config
      );
      dispatch({
        type: SSB_COURSE_ADD_REVIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SSB_COURSE_ADD_REVIEW_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// SSB COURSE RATING DELETE
export const deleteSsbCourseRatingAction = (courseId) => async (dispatch) => {
  try {
    dispatch({
      type: SSB_COURSE_DELETE_REVIEW_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/ssb/course/review/${courseId}`,
      config
    );
    dispatch({
      type: SSB_COURSE_DELETE_REVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SSB_COURSE_DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CREATE COURSE COUPON REQUEST
export const createSsbCourseCouponAction =
  (id, couponPercentage, couponValidity) => async (dispatch) => {
    try {
      dispatch({
        type: SSB_COURSE_COUPON_CREATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/ssb/update-course/${id}`,
        { couponPercentage, couponValidity },
        config
      );

      dispatch({
        type: SSB_COURSE_COUPON_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SSB_COURSE_COUPON_CREATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE COURSE COUPON
export const deleteSsbCourseCouponAction =
  (id, coupons) => async (dispatch) => {
    try {
      dispatch({
        type: SSB_COURSE_COUPON_CREATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/ssb/update-course/${id}`,
        { coupons },
        config
      );

      dispatch({
        type: SSB_COURSE_COUPON_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SSB_COURSE_COUPON_CREATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR DELETE STATE ACTION FUNCTION
export const ssbClearCoupenState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CREATE_SSB_COUPON_STATE });
};
// CLEAR ERROR ACTION FUNCTION
export const ssbClearErrors = () => async (dispatch) => {
  dispatch({ type: SSB_CLEAR_ERRORS });
};

// CLEAR DELETE STATE ACTION FUNCTION
export const ssbClearDeleteState = () => async (dispatch) => {
  dispatch({ type: SSB_CLEAR_DELETE_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const ssbClearState = () => async (dispatch) => {
  dispatch({ type: SSB_CLEAR_STATE });
};

// CLEAR RATING ERROR ACTION FUNCTION
export const ssbClearRatingErrors = () => async (dispatch) => {
  dispatch({ type: SSB_RATING_CLEAR_ERRORS });
};

// CLEAR RATING DELETE STATE ACTION FUNCTION
export const ssbClearRatingDeleteState = () => async (dispatch) => {
  dispatch({ type: SSB_RATING_CLEAR_DELETE_STATE });
};

// SSB CLEAR UPDATE STATE
export const ssbClearCourseupdateState = () => async (dispatch) => {
  dispatch({ type: SSB_COURSE_CLEAR_UPDATE_STATE });
};

// CLEAR RATING ERROR ACTION FUNCTION
export const ssbClearRatingState = () => async (dispatch) => {
  dispatch({ type: SSB_RATING_CLEAR_STATE });
};
