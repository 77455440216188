import React, { Fragment } from 'react'
import Loader from '../../components/reusable/Loader'
import { useSelector } from 'react-redux';
import TeacherLayout from '../../components/arTeacher/TeacherLayout';
import { arTeacherSidebarLinks } from './arTeacherLinks';
import TeacherFooter from '../../components/arTeacher/TeacherFooter';

const ArTeacherTimeTable = () => {
    const { loading } = useSelector((state) => state.profile);
    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <TeacherLayout sidebarLinks={arTeacherSidebarLinks}>


                    {/* FOOTER START */}
                    <TeacherFooter />
                    {/* FOOTER ENDS */}
                </TeacherLayout>
            )}
        </Fragment>
    )
}

export default ArTeacherTimeTable