export const sidebarLinks = [
  {
    id: 1,
    title: "Profile",
    link: "/student/profile",
  },
 
  {
    id: 2,
    title: "Enrolled Courses",
    link: "/student/enrolled-courses",
  },
  {
    id: 4,
    title: "Chatbox",
    link: "/student/chatbox",
  },
  // {
  //   id: 5,
  //   title: "News & Articles",
  //   link: "https://blog.alpharegiment.com",
  // },
  // {
  //   id: 6,
  //   title: "QnA",
  //   link: "https://qna.alpharegiment.com",
  // },
  {
    id: 7,
    title: "Cart",
    link: "/student/cart",
  },
  {
    id: 8,
    title: "Wishlist",
    link: "/student/wishlist",
  },
  {
    id: 9,
    title: "Live Classes",
    link: "/student/course-classes",
  },
  // {
  //   id:10,
  //   title:"Mock Test",
  //   link:"/student/mock-test"
  // }
  // {
  //   id: 8,
  //   title: "Orders",
  //   link: "/",
  // },
];
