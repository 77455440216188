export const notification = [
  {
    id: 1,
    name: "Chella",
    location: "Lucknow",
    time: "5 hours ago",
  },
  {
    id: 2,
    name: "Rahul",
    location: "rewari",
    time: "1 hours ago",
  },
  {
    id: 3,
    name: "Pankaj",
    location: "Gurgaon",
    time: "12 hours ago",
  },
  {
    id: 4,
    name: "Vishal",
    location: "Shimla",
    time: "30 min ago",
  },
];
