import React, { Fragment, useState } from "react";
import Layout from "../../components/reusable/Layout";
import "../../styles/studentPiq.scss";
import { noCourse } from "../../assets/images";
import CustomButton from "../../components/reusable/CustomButton";
import PopupPrompt from "../../components/reusable/PopupPrompt";
import piqPdf from "../../images/PIQ.pdf";
import { useNotification } from "../../context/notificationContext";
import { useLocation, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import {
//   clearCalenderErrors,
//   clearCalenderState,
//   createOfficerCalenderAction,
// } from "../../redux/actions/officerCalenderAction";
// import { useSelector } from "react-redux";
// import { useAuth } from "../../context/userContext";
const StudentPiq = () => {
  const [, , showNotification] = useNotification();
  const navigate = useNavigate();
  
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const courseData = location?.state;

  const [image, setImage] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // HANDLE DOWNLOAD PIQ
  const handleDownload = () => {
    fetch(piqPdf)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "alpha-regiment-student-piq.pdf";
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        showNotification("success", "PIQ download successfully");
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  // HANDLE UPLOAD PIQ STARTS
  // const { title, date, startTime, endTime, student } = data;
  const handleUploadPIQ = () => {
    if (image !== undefined) {
      const dataToSend = {
        image,
        courseData: courseData,
      };
      navigate("/student/schedule", { state: dataToSend });
    } else {
      showNotification("error", "Please select a pdf to upload");
    }
  };

  // const { error, loading, eventData } = useSelector(
  //   (state) => state.createCalender
  // );

  // useEffect(() => {
  //   if (error) {
  //     dispatch(clearCalenderErrors());
  //   }
  //   if (eventData?.success) {
  //     showNotification("success", eventData?.message);
  //     dispatch(clearCalenderState());
  //     navigate("/student/schedule");
  //   }
  // }, [
  //   error,
  //   dispatch,
  //   navigate,
  //   eventData?.message,
  //   eventData?.success,
  //   showNotification,
  // ]);

  return (
    <Fragment>
      <Layout>
        <div className="container">
          <div className="student_piq_section">
            <div className="title">
              <p> Upload PIQ</p>
            </div>
            <div className="banner">
              <img src={noCourse} alt="noCourse" />
            </div>
            <div className="data_txt">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            <div className="upload_btn">
              <CustomButton title="Download PIQ" onClick={handleDownload} />
              <CustomButton
                title="Upload PIQ"
                onClick={() => setShowModal(!showModal)}
              />
            </div>
          </div>
        </div>

        {/* UPLOAD PIQ MODAL STARTS */}
        {showModal && (
          <PopupPrompt
            confirmBtnTxt="Upload"
            cancelBtnTxt="Cancel"
            className="coupen_card"
            title="Upload PIQ"
            confirmOnclick={() => handleUploadPIQ()}
            cancelOnclick={() => setShowModal(false)}
            image={image}
            imageLabel="Select PIQ Form"
            impStar="*"
            fileAccept=".pdf"
            onFileChange={(e) => handleFileChange(e)}
          />
        )}
      </Layout>
    </Fragment>
  );
};

export default StudentPiq;
