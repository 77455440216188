import React from "react";
import "../../styles/promotionPopup.scss";

const PromotionPopup = ({
  isNotificationVisible,
  currentNotificationIndex,
  data,
}) => {
  return (
    <div
      className={`promotion-popup show ${isNotificationVisible ? "show" : ""}`}
    >
      {isNotificationVisible && data?.length > 0 && (
        <div className="promotion">
          <span style={{ color: "#FFA500" }}>
            {data[currentNotificationIndex]?.name} from{" "}
            {data[currentNotificationIndex]?.location} joined
          </span>
          <span style={{ color: "yellow" }}>
            <b>Alpha Regiment</b>
          </span>
          <p style={{ color: "white" }}>
            {data[currentNotificationIndex]?.time}
          </p>
        </div>
      )}
    </div>
  );
};

export default PromotionPopup;
