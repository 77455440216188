import {
  CLEAR_OFFICER_ERROR,
  CLEAR_POST_TIMLINE_STATE,
  CLEAR_UPDATE_TIMLINE_STATE,
  DELETE_TIMLINE_FAIL,
  DELETE_TIMLINE_REQUEST,
  DELETE_TIMLINE_STATE,
  DELETE_TIMLINE_SUCCESS,
  GET_OFFICER_COURSES_FAIL,
  GET_OFFICER_COURSES_REQUEST,
  GET_OFFICER_COURSES_SUCCESS,
  GET_OFFICER_REVENUE_FAIL,
  GET_OFFICER_REVENUE_REQUEST,
  GET_OFFICER_REVENUE_SUCCESS,
  GET_POST_TIMLINE_BY_OFFICER_FAIL,
  GET_POST_TIMLINE_BY_OFFICER_REQUEST,
  GET_POST_TIMLINE_BY_OFFICER_SUCCESS,
  GET_POST_TIMLINE_LIST_FAIL,
  GET_POST_TIMLINE_LIST_REQUEST,
  GET_POST_TIMLINE_LIST_SUCCESS,
  LIKE_POST_TIMLINE_CLEAR_ERROR,
  LIKE_POST_TIMLINE_FAIL,
  LIKE_POST_TIMLINE_REQUEST,
  LIKE_POST_TIMLINE_SUCCESS,
  POST_TIMLINE_FAIL,
  POST_TIMLINE_REQUEST,
  POST_TIMLINE_SUCCESS,
  UPDATE_TIMLINE_FAIL,
  UPDATE_TIMLINE_REQUEST,
  UPDATE_TIMLINE_SUCCESS,
} from "../constants/officerConstant";

export const officerCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OFFICER_COURSES_REQUEST:
      return {
        loading: true,
        courseList: {},
      };
    case GET_OFFICER_COURSES_SUCCESS:
      return {
        loading: false,
        courseList: action.payload,
      };
    case GET_OFFICER_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_OFFICER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const postTimelineReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_TIMLINE_REQUEST:
      return {
        loading: true,
        postData: {},
      };
    case POST_TIMLINE_SUCCESS:
      return {
        loading: false,
        postData: action.payload,
      };
    case POST_TIMLINE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_OFFICER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_POST_TIMLINE_STATE:
      return {
        loading: false,
        postData: {},
      };
    default:
      return state;
  }
};

export const updateTimelineReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TIMLINE_REQUEST:
      return {
        loading: true,
       updateData: {},
      };
    case UPDATE_TIMLINE_SUCCESS:
      return {
        loading: false,
       updateData: action.payload,
      };
    case UPDATE_TIMLINE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_OFFICER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_UPDATE_TIMLINE_STATE:
      return {
        loading: false,
       updateData: {},
      };
    default:
      return state;
  }
};

export const likePostTimelineReducer = (state = {}, action) => {
  switch (action.type) {
    case LIKE_POST_TIMLINE_REQUEST:
      return {
        loading: true,
        postData: {},
      };
    case LIKE_POST_TIMLINE_SUCCESS:
      return {
        loading: false,
        postData: action.payload,
      };
    case LIKE_POST_TIMLINE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case LIKE_POST_TIMLINE_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const getAllPostTimelineReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_POST_TIMLINE_LIST_REQUEST:
      return {
        loading: true,
        postData: {},
      };
    case GET_POST_TIMLINE_LIST_SUCCESS:
      return {
        loading: false,
        postData: action.payload,
      };
    case GET_POST_TIMLINE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_OFFICER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const getAllPostByOfficerReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_POST_TIMLINE_BY_OFFICER_REQUEST:
      return {
        loading: true,
        postData: {},
      };
    case GET_POST_TIMLINE_BY_OFFICER_SUCCESS:
      return {
        loading: false,
        postData: action.payload,
      };
    case GET_POST_TIMLINE_BY_OFFICER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_OFFICER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET OFFICER REVENUE REDUCER STARTS
export const getOfficerRevenueReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OFFICER_REVENUE_REQUEST:
      return {
        loading: true,
        revenueData: {},
      };
    case GET_OFFICER_REVENUE_SUCCESS:
      return {
        loading: false,
        revenueData: action.payload,
      };
    case GET_OFFICER_REVENUE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_OFFICER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
// GET OFFICER REVENUE REDUCER ENDS

export const deleteTimeLinePostReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TIMLINE_REQUEST:
      return {
        loading: true,
        deleteData: {},
      };
    case DELETE_TIMLINE_SUCCESS:
      return {
        loading: false,
        deleteData: action.payload,
      };
    case DELETE_TIMLINE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_OFFICER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_TIMLINE_STATE:
      return {
        deleteData: {},
        loading: false,
      };

    default:
      return state;
  }
};
