import React, { useEffect, useState } from "react";
import { FaCaretUp, FaMicrophone } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import updateCallStatus from "../../../../redux/actions/updateCallStatus";
import ActionButtonCaretDropdown from "../ActionButtonCaretDropdown";
import getDevices from "../../utils/getDevices";
import addStream from "../../../../redux/actions/addStream";
import startAudioStream from "./startAudioStream";

const AudioButton = ({ smallFeedEl }) => {
  const dispatch = useDispatch();
  const [caretOpen, setCaretOpen] = useState(false);
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  const [audioDeviceList, setAudioDeviceList] = useState([]);


  let micText;
  if (callStatus.audio === "off") {
    micText = "Join Audio";
  } else if (callStatus.audio === "enabled") {
    micText = "Mute";
  } else {
    micText = "Unmute";
  }

  useEffect(() => {
    const getDevicesAsync = async() => {
      const devices = await getDevices();
      setAudioDeviceList(devices.audioOutputDevices.concat(devices.audioInputDevices));
    }
    if(caretOpen){
      getDevicesAsync()
    }
  }, [caretOpen])

  const startStopAudio = () => {
    if (callStatus.audio === "enabled") {
      // update redux callStatus
      dispatch(updateCallStatus("audio", "disabled"));
      // set the stream is disabled
      const tracks = streams.localStream.stream.getAudioTracks();
      tracks.forEach((t) => (t.enabled = false));
    } else if (callStatus.audio === "disabled") {
      // update redux callStatus
      dispatch(updateCallStatus("audio", "enabled"));
      // set the stream is enabled
      const tracks = streams.localStream.stream.getAudioTracks();
      tracks.forEach((t) => (t.enabled = true));
    }else {
      changeAudioDevice({target:{value: "inputdefault"}})
      // add the tracks
      startAudioStream(streams)
    }
  }
  
  const changeAudioDevice =async (e) => {
    // the user changed desired audio input or audio output
    //1. we need to get the deviceId
    const deviceId = e.target.value.slice(5);
    const audioType = e.target.value.slice(0, 5);
    if(audioType === 'output'){
      // (sort of out of order). update the smallFeedEl 
      // we are now done! we dont care the out for any other reason
      smallFeedEl.current.setSinkId(deviceId)
    }else if(audioType === 'input'){
      //2. we need to getUserMedia (permission)
      const newConstaints = {
        audio: {deviceId: {exact: deviceId}},
        video: callStatus.videoDevice === "default" ? true : {deviceId: {exact: callStatus.videoDevice}}
      }
      const stream = await navigator.mediaDevices.getUserMedia(newConstaints);
      //3. update redux with that audioDevice, and that audio is enabled
      dispatch(updateCallStatus('audioDevice', deviceId))
      dispatch(updateCallStatus('audio', "enabled"))
       //5. we need to update localStream in streams
      dispatch(addStream('localStream', stream)) 
      //6. add tracks 
      const [audioTrack] = stream.getAudioTracks()
      // come back to this later
      for(const s in streams){
        if(s !== 'localStream'){
          const senders = streams[s].peerConnection.getSenders();
          const sender = senders.find((s)=> {
            if(s.track){
              return s.track.kind === audioTrack.kind
            }else {
              return false
            }
          });
          sender.replaceTrack(audioTrack)
        }
      }
    }
  };

  return (
    <div className="button-wrapper d-inline-block">
      <FaCaretUp className="choose-audio caretBtn" onClick={() => setCaretOpen(!caretOpen)} />
      <div className="button mic" onClick={startStopAudio}>
        <FaMicrophone />
        <div className="btn-text">{micText}</div>
      </div>
      {caretOpen ? (
        <ActionButtonCaretDropdown
          defaultValue={callStatus.audioDevice}
          onChangeHandler={changeAudioDevice}
          deviceList={audioDeviceList}
          type="audio"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AudioButton;
