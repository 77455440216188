export const VIDEOCALL_LINK_REQUEST = "VIDEOCALL_LINK_REQUEST";
export const VIDEOCALL_LINK_SUCCESS = "VIDEOCALL_LINK_SUCCESS";
export const VIDEOCALL_LINK_FAIL = "VIDEOCALL_LINK_FAIL";

export const VIDEOCALL_LINK_VALIDATE_REQUEST = "VIDEOCALL_LINK_VALIDATE_REQUEST";
export const VIDEOCALL_LINK_VALIDATE_SUCCESS = "VIDEOCALL_LINK_VALIDATE_SUCCESS";
export const VIDEOCALL_LINK_VALIDATE_FAIL = "VIDEOCALL_LINK_VALIDATE_FAIL";

export const PRO_VIDEOCALL_LINK__REQUEST = "PRO_VIDEOCALL_LINK__REQUEST";
export const PRO_VIDEOCALL_LINK__SUCCESS = "PRO_VIDEOCALL_LINK__SUCCESS";
export const PRO_VIDEOCALL_LINK__FAIL = "PRO_VIDEOCALL_LINK__FAIL";

export const CLEAR_VIDEOCALL_ERROR = "CLEAR_VIDEOCALL_ERROR";
export const CLEAR_VIDEOCALL_STATE = "CLEAR_VIDEOCALL_STATE";