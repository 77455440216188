import React from "react";
import CustomHelmet from "../../components/reusable/Helmet";

const DilbaagVisitingCard = () => {
  return (
    <div>
      <CustomHelmet redirectLink="0; url=https://alpharegiment.com/dilbaag/" />
    </div>
  );
};

export default DilbaagVisitingCard;
