import React, { Fragment, useEffect, useState } from "react";
import "../../styles/OfficerProfile.scss";
import Loader from "../../components/reusable/Loader";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/userContext";
import {
  clearErrors,
  clearUpdateState,
  removeAvatar,
  updateUserProfile,
  userProfile,
} from "../../redux/actions/userAction";
import {
  FaExpeditedssl,
  FaInfoCircle,
  FaUpload,
  FaUserEdit,
  FaWhatsappSquare,
  FaCopy,
  FaWhatsapp,
  FaTwitter,
  FaInstagram,
  FaFacebookF,
} from "react-icons/fa";
// import { LuCopy } from "react-icons/lu";
import CustomInput from "../../components/reusable/CustomInput";
import CustomSelect from "../../components/reusable/CustomSelect";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import UploadProfilePicForm from "../../components/student/UploadProfilePicForm";
import { defaultUser } from "../../assets/images";
import OfficerLayout from "./OfficerLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { arRole } from "../../utils/userRole";
import { useNotification } from "../../context/notificationContext";
import PopupPrompt from "../../components/reusable/PopupPrompt";
import {
  createSsbCourseCouponAction,
  deleteSsbCourseCouponAction,
  getOfficerSsbCourses,
  ssbClearCoupenState,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
const OfficerProfile = () => {
  const [uploadProfileModal, setUploadProfileModal] = useState(false);
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const { loading } = useSelector((state) => state.profile); // GET PROFILE STATE FROM REDUCER STORE
  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile); // GET PROFILE STATE FROM REDUCER STORE
  const [editable, setEditable] = useState(false);

  // GET LOCALSTORAGE DATA
  const localStorageData = localStorage.getItem("alphaToken")
    ? JSON.parse(localStorage.getItem("alphaToken"))
    : "";

  // UPDATE PROFILE STATE
  const [profileData, setProfileData] = useState({
    first_name: auth?.user?.first_name,
    email: auth?.user?.email,
    phone: auth?.user?.phone,
    dob: auth?.user?.dob,
    gender: auth?.user?.gender,
    address: auth?.user?.address,
    aff_occupation: auth?.user?.aff_occupation,
    aff_state: auth?.user?.aff_state,
    aff_bank_name: auth?.user?.aff_bank_name,
    aff_bank_ifsc: auth?.user?.aff_bank_ifsc,
    aff_bank_acc_no: auth?.user?.aff_bank_acc_no,
    aff_bank_branch: auth?.user?.aff_bank_branch,
    aff_bank_reg_mob: auth?.user?.aff_bank_reg_mob,
    aff_upi_id: auth?.user?.aff_upi_id,
    teachMode: auth?.user?.teachMode,
    teacherBio: auth?.user?.teacherBio,
    timeTable: auth?.user?.timeTable,
    teachTopic: auth?.user?.teachTopic,
    mapCoordinates: auth?.user?.mapCoordinates,
    facebook: auth?.user?.socialLinks?.facebook,
    instagram: auth?.user?.socialLinks?.instagram,
    twitter: auth?.user?.socialLinks?.twitter,
    whatsapp: auth?.user?.socialLinks?.whatsapp,
    couponCode: auth?.user?.couponCode,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  // PROFILE UPDATE HANDLER
  const handleProfileUpdate = () => {
    dispatch(
      updateUserProfile({
        first_name: profileData?.first_name,
        middle_name: "",
        last_name: "",
        email: profileData?.email,
        phone: profileData?.phone,
        dob: profileData?.dob,
        address: profileData?.address,
        gender: profileData?.gender,
        profile: profileData?.profile,
        fatherName: "",
        motherName: "",
        parentEmail: "",
        teacherBio: profileData?.teacherBio,
        subSpec: "",
        assignSubjects: "",
        aff_occupation: "",
        aff_state: "",
        aff_bank_name: profileData?.aff_bank_name,
        aff_bank_ifsc: profileData?.aff_bank_ifsc,
        aff_bank_acc_no: profileData?.aff_bank_acc_no,
        aff_bank_branch: profileData?.aff_bank_branch,
        aff_bank_reg_mob: profileData?.aff_bank_reg_mob,
        aff_upi_id: profileData?.aff_upi_id,
        parentWhatsAppNo: "",
        timeTable: auth?.user?.timeTable,
        facebook: profileData?.facebook,
        instagram: profileData?.instagram,
        twitter: profileData?.twitter,
        whatsapp: profileData?.whatsapp,
        language: auth?.user?.language,
      })
    );
  };

  // HANLDE SUCCESS MESSAGE FOR UPDATE OFFICER DETAILS
  useEffect(() => {
    if (userInfo?.success) {
      setEditable(false);
      showNotification("success", userInfo?.message);
      dispatch(userProfile(localStorageData?.token));
      dispatch(clearUpdateState());
    }
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    localStorageData?.token,
    showNotification,
    updateError,
    userInfo?.message,
    userInfo?.success,
  ]);

  // DELETE PROFILE PIC
  const {
    loading: removeProfileLoading,
    avatarInfo: removeProfileInfo,
    error: removeProfileError,
  } = useSelector((state) => state.removeAvatar);

  const deleteProfilePic = () => {
    dispatch(removeAvatar());
  };

  useEffect(() => {
    if (removeProfileInfo?.success) {
      showNotification("success", removeProfileInfo?.message);
      // setTimeout(() => {
      dispatch(userProfile()); // GET UPDATED USER DETAILS
      dispatch(clearUpdateState());
      // }, 3000);
    }
  }, [
    dispatch,
    showNotification,
    removeProfileError,
    removeProfileInfo?.message,
    removeProfileInfo?.success,
  ]);

  const handleButtonClick = (profile) => {
    if (!profile) {
      showNotification("warning", `Please add url before click this`);
    } else {
      if (!isNaN(profile)) {
        const formattedProfile = `https://api.whatsapp.com/send?phone=${profile}`;
        window.open(formattedProfile, "_blank");
      } else {
        const formattedProfile = profile.startsWith("http")
          ? profile
          : `https://${profile}`;
        window.open(formattedProfile, "_blank");
      }
    }
  };

  const [copy, setCopy] = useState(false);
  const handleCopyText = () => {
    const inputValue = profileData?.couponCode
      ? profileData?.couponCode
      : auth?.user?.couponCode;
    if (inputValue) {
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          showNotification(
            "success",
            `Coupen ${inputValue} copied to clipboad`
          );
          setCopy(true);
        })
        .catch((err) => {
          showNotification("error", "Unable to copy to clipboard.", err);
        });
    } else {
      showNotification("warning", "Nothing to copy!");
    }
  };

  const handleCopyLink = () => {
    const inputValue = window.location.href + "?" + profileData?.couponCode;
    if (inputValue) {
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          showNotification("success", `Link ${inputValue} copied to clipboad`);
          setCopy(true);
        })
        .catch((err) => {
          showNotification("error", "Unable to copy to clipboard.", err);
        });
    } else {
      showNotification("warning", "Nothing to copy!");
    }
  };
  // ADD COUPEN CODE
  const [addCoupen, setAddCoupen] = useState(false);
  const [courseId, setCourseId] = useState();
  const [discountValidity, setDiscountValidity] = useState();
  const [discountPrecentage, setDiscountPrecentage] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [popupMsg, setPopupMsg] = useState({});

  useEffect(() => {
    if (profileData?.couponCode !== "") {
      setCurrentUrl(
        window.location.href + "?" + profileData?.couponCode
          ? profileData?.couponCode
          : auth?.user?.couponCode
      );
    }
  }, [profileData?.couponCode, auth?.user?.couponCode]);

  // VALIDATE THE DATE INPUT
  const disabledDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // FETCH COURSE LIST FOR ADDING COUPEN
  const {
    loading: courseLoading,
    error,
    courses,
  } = useSelector((state) => state.officerSsbCourses);

  // FILTER THOSE COURSES WHO HAS COUPON
  const couponCourses = courses?.courses?.filter((item) => {
    return (
      item?.coupons &&
      item?.coupons?.code !== undefined &&
      item?.coupons?.code !== ""
    );
  });
  // FILTER THOSE COURSES WHO HAS COUPON  ENDS

  let courseListArray = [];

  courses?.courses?.forEach((ele) => {
    courseListArray.push({
      label: ele?.title,
      value: ele?._id,
    });
  });

  const [courseFound, setCourseFound] = useState(false);

  // check course coupon created or not

  useEffect(() => {
    if (courseId) {
      courses?.courses?.forEach((course) => {
        if (course._id === courseId?.value) {
          if (course.coupons && course.coupons.code !== "") {
            setCourseFound(true);
          } else {
            setCourseFound(false);
          }
        }
      });
    }
  }, [courseId, courses?.courses]);

  useEffect(() => {
    dispatch(getOfficerSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, error, showNotification]);

  // ADD A NEW COUPEN FUNCTION STARTS
  const handleAddCoupen = () => {
    const id = courseId?.value;
    if (id === undefined) {
      showNotification(
        "error",
        "Please select a course for which you want to create a coupon"
      );
    }else if (discountValidity === "") {
      showNotification(
        "error",
        "Please keep some discount validity for create coupon"
      );
    }
     else if (discountPrecentage === "") {
      showNotification(
        "error",
        "Please keep some discount percent for create coupon"
      );
    }  else {
      dispatch(
        createSsbCourseCouponAction(id, discountPrecentage, discountValidity)
      );
    }
  };
  const {
    loading: couponLoading,
    error: couponError,
    couponData,
  } = useSelector((state) => state.createSsbCourseCoupon);

  useEffect(() => {
    if (couponError) {
      showNotification("error", couponError);
      dispatch(ssbClearErrors());
    }
    if (couponData?.success) {
      showNotification("success", couponData?.message);
      setAddCoupen(false);
      dispatch(ssbClearCoupenState());
      dispatch(userProfile(localStorageData?.token));
    }
  }, [
    showNotification,
    dispatch,
    couponError,
    couponData?.success,
    couponData?.message,
    localStorageData?.token,
  ]);

  const handleDelete = (id) => {
    const coupons = {};
    dispatch(deleteSsbCourseCouponAction(id, coupons));
  };

  return (
    <Fragment>
      {loading || courseLoading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <section className="officer_profile_section txt_color">
              <h2 className="profilePage_heading">
                Officer ({arRole(auth?.user?.role)}) - Info
              </h2>

              <div className="user_details_main">
                <div className="user_pro_data">
                  <h2>
                    Profile
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="data_card">
                      <CustomInput
                        label="Full Name"
                        type="text"
                        placeholder="Full Name"
                        impStar={editable ? "*" : ""}
                        name="first_name"
                        value={
                          editable
                            ? profileData?.first_name
                            : auth.user?.first_name
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      <CustomInput
                        label="Email (user id)"
                        type="email"
                        placeholder="example@email.com"
                        impStar={editable ? "*" : ""}
                        name="email"
                        value={editable ? profileData?.email : auth.user?.email}
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Whatsapp Number"
                        type="number"
                        placeholder="0987654321"
                        impStar={editable ? "*" : ""}
                        name="phone"
                        value={editable ? profileData?.phone : auth.user?.phone}
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      <CustomSelect
                        label="Gender"
                        name="gender"
                        impStar={editable ? "*" : ""}
                        value={
                          editable ? profileData?.gender : auth.user?.gender
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        optionArray={[
                          {
                            id: 1,
                            option: "Male",
                          },
                          {
                            id: 2,
                            option: "Female",
                          },
                        ]}
                      />
                      <CustomTextarea
                        label="Short Bio"
                        type="text"
                        placeholder="tell us yourself in (max 250 words)..."
                        impStar={editable ? "*" : ""}
                        name="teacherBio"
                        value={
                          editable
                            ? profileData?.teacherBio
                            : auth.user?.teacherBio
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        fullWidth={true}
                      />
                    </div>
                    <div className="profile_picSec">
                      <div className="user_pro_pic">
                        <button
                          className="absolute_btn deleteImage_btn"
                          title="Remove Profile Picture"
                          onClick={() => deleteProfilePic()}
                        >
                          {removeProfileLoading ? (
                            <ButtonLoader />
                          ) : (
                            <RiDeleteBinLine />
                          )}
                        </button>
                        <ImgLazyLoad
                          src={
                            auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          alt="alpha regiment user"
                        />
                        <button
                          title="Upload Profile Picture"
                          className="absolute_btn uploadImage_btn"
                          onClick={() => setUploadProfileModal(true)}
                        >
                          <FaUpload />
                        </button>
                      </div>
                      <div className="social_links">
                        <button
                          className="facebook"
                          title="Facebook"
                          onClick={() =>
                            handleButtonClick(auth?.user?.socialLinks?.facebook)
                          }
                        >
                          <AiFillFacebook />
                        </button>
                        <button
                          className="insta"
                          title="Instagram"
                          onClick={() =>
                            handleButtonClick(
                              auth?.user?.socialLinks?.instagram
                            )
                          }
                        >
                          <AiFillInstagram />
                        </button>
                        <button
                          className="twitter"
                          title="Twitter"
                          onClick={() =>
                            handleButtonClick(auth?.user?.socialLinks?.twitter)
                          }
                        >
                          <AiFillTwitterSquare />
                        </button>
                        <button
                          className="whatsapp"
                          title="Whatsapp"
                          onClick={() =>
                            handleButtonClick(auth?.user?.socialLinks?.whatsapp)
                          }
                        >
                          <FaWhatsappSquare />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user_pro_data" style={{ marginTop: "50px" }}>
                  <h2>Link & Coupon</h2>
                  <div>
                    <div className="data_card linkCouponSec">
                      <CustomInput
                        label="Universal Link"
                        type="text"
                        value={currentUrl}
                        placeholder="Universal Link"
                        tooltip="Your universal referral link earns commissions across the site, with discounts for students."
                        borderColor={editable ? "#f2f2f2" : ""}
                        readOnly={true}
                        copyBtn={copy ? <FaCopy /> : <FaCopy />}
                        handleCopy={() =>
                          handleCopyLink(profileData?.couponCode)
                        }
                      />

                      <CustomInput
                        label="Universal Coupon Code"
                        type="text"
                        placeholder="Universal Coupon Code"
                        tooltip="Your universal coupon code earns commissions across the site, with discounts for students."
                        value={
                          profileData?.couponCode
                            ? profileData?.couponCode
                            : auth?.user?.couponCode
                        }
                        borderColor={editable ? "#f2f2f2" : ""}
                        readOnly={true}
                        copyBtn={copy ? <FaCopy /> : <FaCopy />}
                        handleCopy={() =>
                          handleCopyText(profileData?.couponCode)
                        }
                      />

                      <div className="tableSec">
                        <div className="batch_timing_main txt_color">
                          <div className="timing_calendar">
                            <div className="title">
                              <h2>
                                Your Coupon Code{" "}
                                <div className="tooltip">
                                  <FaInfoCircle />
                                  <span className="tooltiptext">
                                    Coupon code for your courses only ( Maximum
                                    10 active code)
                                  </span>
                                </div>
                              </h2>
                              <CustomButton
                                title="Create Coupon"
                                width="150px"
                                className="coupen_btn"
                                onClick={() => setAddCoupen(true)}
                              />
                            </div>

                            <div className="note">
                              <strong>Note :</strong>
                              <p>
                                Remember only one coupon code can be created for
                                each course.
                              </p>
                            </div>

                            {couponCourses?.length > 0 && (
                              <div className="timing_table custonScroll">
                                <table className="table" cellSpacing="0">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>For Course</th>
                                      <th>Coupon Code</th>
                                      <th>Dis. Per.</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {couponCourses?.map((ele, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          <p>{idx + 1}</p>
                                        </td>
                                        <td>
                                          <p>{ele?.title}</p>
                                        </td>
                                        <td>
                                          <p>
                                            <span className="white">
                                              {ele?.coupons?.code}
                                            </span>
                                          </p>
                                        </td>
                                        <td>
                                          <p>{ele?.coupons?.discount} %</p>
                                        </td>
                                        <td>
                                          <div className="buttons">
                                            <button
                                              onClick={() =>
                                                setPopupMsg({
                                                  show: true,
                                                  id: ele?._id,
                                                })
                                              }
                                            >
                                              <MdDelete />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user_pro_data" style={{ marginTop: "50px" }}>
                  <h2>Bank Account Info</h2>
                  <div>
                    <div className="data_card">
                      <CustomInput
                        label="Bank Name"
                        type="text"
                        placeholder="Bank Name"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_name"
                        value={
                          editable
                            ? profileData?.aff_bank_name
                            : auth.user?.aff_bank_name
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="IFSC Code"
                        type="text"
                        placeholder="IFSC Code"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_ifsc"
                        value={
                          editable
                            ? profileData?.aff_bank_ifsc
                            : auth.user?.aff_bank_ifsc
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Bank Account Number"
                        type="text"
                        placeholder="Bank Account Number"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_acc_no"
                        value={
                          editable
                            ? profileData?.aff_bank_acc_no
                            : auth.user?.aff_bank_acc_no
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Bank Branch"
                        type="text"
                        placeholder="Bank Branch"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_branch"
                        value={
                          editable
                            ? profileData?.aff_bank_branch
                            : auth.user?.aff_bank_branch
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Registered Mob Number"
                        type="text"
                        placeholder="Registered Mob Number"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_reg_mob"
                        value={
                          editable
                            ? profileData?.aff_bank_reg_mob
                            : auth.user?.aff_bank_reg_mob
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="UPI Id"
                        type="text"
                        placeholder="UPI Id"
                        impStar={editable ? "*" : ""}
                        name="aff_upi_id"
                        value={
                          editable
                            ? profileData?.aff_upi_id
                            : auth.user?.aff_upi_id
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      {/* {editable && (
                        <CustomButton
                          className="pro_update_btn"
                          title={updateLoading ? <ButtonLoader /> : `Update`}
                          width="100px"
                          onClick={handleProfileUpdate}
                        />
                      )} */}
                    </div>
                  </div>
                </div>
                <div
                  className="user_pro_data social_links_data"
                  style={{ marginTop: "50px" }}
                >
                  <h2>Social Links</h2>
                  <div>
                    <div className="data_card">
                      <CustomInput
                        label={"Facebook URL"}
                        type="text"
                        placeholder={`Enter Facebook URL`}
                        impStar={editable ? "*" : ""}
                        name={"facebook"}
                        value={
                          editable
                            ? profileData?.facebook
                            : auth?.user?.socialLinks?.facebook
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        icon={<FaFacebookF />}
                      />
                      <CustomInput
                        label={"Instagram URL"}
                        type="text"
                        placeholder={`Enter Instagram URL`}
                        impStar={editable ? "*" : ""}
                        name={"instagram"}
                        value={
                          editable
                            ? profileData?.instagram
                            : auth?.user?.socialLinks?.instagram
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        icon={<FaInstagram />}
                      />
                      <CustomInput
                        label={"X/Twitter URL"}
                        type="text"
                        placeholder={`Enter XYZ URL`}
                        impStar={editable ? "*" : ""}
                        name={"twitter"}
                        value={
                          editable
                            ? profileData?.twitter
                            : auth?.user?.socialLinks?.twitter
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        icon={<FaTwitter />}
                      />
                      <CustomInput
                        label={"Whatsapp URL"}
                        type="text"
                        placeholder={`Enter Whatsapp URL`}
                        impStar={editable ? "*" : ""}
                        name={"whatsapp"}
                        value={
                          editable
                            ? profileData?.whatsapp
                            : auth?.user?.socialLinks?.whatsapp
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        icon={<FaWhatsapp />}
                      />
                      {editable && (
                        <CustomButton
                          className="pro_update_btn"
                          title={updateLoading ? <ButtonLoader /> : `Update`}
                          width="100px"
                          onClick={handleProfileUpdate}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* UPLOAD PROFILE PICTURE FORM START */}

            {uploadProfileModal && (
              <section className="upload_profile_pic_main">
                <UploadProfilePicForm
                  setUploadProfileModal={setUploadProfileModal}
                />
              </section>
            )}

            {/* UPLOAD PROFILE PICTURE FORM ENDS */}

            {/* ADD COUPEN CODE VALIDITY STARTS */}

            {addCoupen && (
              <PopupPrompt
                confirmBtnTxt="Create"
                cancelBtnTxt="Cancel"
                className="coupen_card"
                title={"Add a new coupen"}
                loading={couponLoading}
                confirmOnclick={() => handleAddCoupen()}
                cancelOnclick={() => setAddCoupen(false)}
                courseList={courseListArray}
                courseValue={courseId}
                courseFound={courseFound}
                isCoupon={true}
                onCourseChange={(val) => setCourseId(val)}
                courseLabel={"Please Select course"}
                discountLabel="Coupen Validity"
                getCurrentDate={disabledDate}
                discountPercentLabel="Enter Discount Percentage"
                discountPrecentage={discountPrecentage}
                setDiscountPrecentage={(e) =>
                  setDiscountPrecentage(e.target.value)
                }
                discountValue={discountValidity}
                handleDiscountValidity={(e) =>
                  setDiscountValidity(e.target.value)
                }
              />
            )}

            {/* ADD COUPEN CODE VALIDITY STARTS */}

            {/* DELETE COURSE POPUP START */}
            {/* DELETE COURSE POPUP START */}
            {popupMsg?.show && (
              <PopupPrompt
                confirmBtnTxt="Confirm"
                cancelBtnTxt="Cancel"
                title="Are you sure! You want to delete this coupon."
                confirmOnclick={() => handleDelete(popupMsg?.id)}
                cancelOnclick={() => setPopupMsg(false)}
                loading={couponLoading}
                inputPlaceholder="DELETE"
                inputLabel="Type DELETE in capital letters"
              />
            )}
            {/* DELETE COURSE POPUP ENDS */}
            {/* DELETE COURSE POPUP ENDS */}
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OfficerProfile;
