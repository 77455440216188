import React from "react";
import ImgLazyLoad from "./ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import "../../styles/componentLoading.scss";

const ComponentLoading = () => {
  return (
    <div className="txt_color courseNotFound_main">
      {/* <h2 className="txt_center">{t("courseNotFoundTitle")}</h2> */}
      <h2 className="txt_center">Loading..</h2>

      <ImgLazyLoad
        className="img_load"
        src={courseNotFound}
        alt="courseNotFound"
      />
      <p>Please wait</p>
    </div>
  );
};

export default ComponentLoading;
