import React, { useState } from "react";
import "../styles/privacyPolicy.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomHelmet from "../components/reusable/Helmet";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color ">
      <CustomHelmet
        keywords="Privacy Policy Alpha Regiment"
        description="At Alpha Regiment, we prioritize user privacy and take all measures to secure their data. Please read our privacy policy for a better understanding."
        author="Team Alpha Regiment"
        title="Privacy Policies"
        canonical={window?.location?.href}
      />
      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>PRIVACY POLICY</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>Privacy Policy</h1>
                <p>Last updated: March 27, 2023</p>
              </div>
              <div className="privacy_text">
                <p>
                  Before availing any services from Alpha Regiment through our
                  Website/Application or the corresponding mobile application on
                  Google Play, please take the time to carefully read our
                  Privacy Policy outlined below. We collectively refer to the
                  Alpha Regiment App or Website where services are available as
                  the "Platform" for convenience purposes.
                </p>
                <p>
                  Please note that this Privacy Policy is subject to updates, so
                  we recommend that you review it periodically to stay informed
                  about our information collection and privacy protection
                  practices. By accessing the Alpha Regiment Platform, you agree
                  to be bound by the terms of this Privacy Policy.
                </p>
                <p>
                  When you use the Alpha Regiment Platform and/or provide us
                  with your information, you consent to the collection and use
                  of your disclosed information in accordance with this Privacy
                  Policy, including our sharing practices. We will notify you of
                  any changes to our Privacy Policy by posting them on this
                  page. If you do not agree with this Privacy Policy, please
                  discontinue using or accessing our <Link to="/">Website</Link>
                  .
                </p>

                <h2 className="heading">
                  Collection of Personally Identifiable Information{" "}
                </h2>
                <p>
                  In order to enhance your user experience, we collect certain
                  personally identifiable information from you. This information
                  includes your name, telephone number, email address, service
                  address, IP address, device and network information,
                  college/institution details and location, user-uploaded photos
                  and IDs, demographic information such as postcode,
                  preferences, and interests, and any other personal information
                  you provide us when booking a service or participating in
                  customer surveys and offers.
                </p>
                <p>
                  Additionally, when you register to create a profile and use
                  our website and services, we may ask for your name, username,
                  password, email address and college/university name. You may
                  also choose to provide a profile picture. Your username,
                  country, and specialty will be stored on an unencrypted
                  server, while your password is cryptographically hashed and
                  your email address is encrypted. Together, these elements
                  constitute your "Personal User Information," which we collect
                  and hold for the purpose of administering your use of our
                  application.{" "}
                </p>
                <p>
                  By using our services, you agree to be solely responsible for
                  maintaining the confidentiality of your Personal User
                  Information and not allowing anyone else to use it to access
                  the Services. You also agree to bear all damages or losses
                  that may occur as a result of activities that take place in
                  your account and to ensure that any information you submit
                  complies with our terms and conditions, and is accurate and
                  up-to-date.
                </p>
                <p>
                  If you become aware of any unauthorized use of your Personal
                  User Information or any other security breach, you must
                  immediately notify us by email at{" "}
                  <Link to="mailto:support@alpharegiment.com">
                    support@alpharegiment.com
                  </Link>
                  . Please note that we are not liable for any harm that arises
                  from the theft of your Personal User Information that is under
                  your control or from any disclosure or use of your Personal
                  User Information by another person or entity.{" "}
                </p>
                <p>
                  When you provide personal information to us, you are no longer
                  anonymous to us. We may use this information for internal
                  research on demographics, interests, and behavior to better
                  serve our customers. The information is aggregated and
                  analyzed on a non-personally identifiable basis. Certain
                  fields on our platform may be mandatory while others are
                  optional, and it is up to you to decide whether to provide us
                  with the information.{" "}
                </p>
                <p>
                  You have the option not to provide us with any personal
                  information, but in that case, we may not be able to provide
                  you with some or all of our services. You can browse our
                  website without revealing any personal information, but we may
                  automatically track certain information about you based on
                  your behavior on our website, such as the URLs you visited and
                  your IP address.
                </p>
                <p>
                  We use cookies and other data collection devices on our
                  website to analyze web page flow, measure promotional
                  effectiveness, and enhance security. Cookies are small files
                  placed on your hard drive that assist us in providing our
                  services, and certain features may only be available through
                  the use of cookies. You may choose to decline our cookies if
                  your browser permits, but this may limit your ability to use
                  certain features of our website. Additionally, third parties
                  may place cookies on certain pages of our website, and we do
                  not control their use of cookies.
                </p>

                <h2 className="heading">Use of Personal Information</h2>
                <p>
                  Our website collects information for various purposes to
                  enhance your experience with us. Firstly, we use it to locate
                  third-party service providers and for internal record keeping.
                  Secondly, we utilize the information to improve our products
                  and services, and to send you periodic promotional emails or
                  messages about new products, special offers, or other
                  interesting information. Additionally, we may customize the
                  website according to your interests and use personal
                  information to resolve disputes, promote safe service, measure
                  consumer interest, detect and prevent fraudulent activity, and
                  enforce our terms and conditions. Lastly, we use your IP
                  address to diagnose server problems and to gather general
                  demographic information.
                </p>

                <h2 className="heading">Sharing of Personal Information</h2>
                <p>
                  We value your privacy and will only share your information in
                  specific circumstances. Firstly, we may share your information
                  with payment service providers, regulatory authorities, and
                  third-party agencies if required to do so. Secondly, we may
                  disclose your personal information in response to subpoenas,
                  court orders, or other legal process, or to protect the rights
                  and safety of our customers and the general public.
                  Additionally, if we merge with or are acquired by another
                  business entity, we may share or sell some or all of the
                  collected information with them. However, the new entity must
                  comply with our Privacy Policy. We do not disclose personal
                  information about identifiable individuals to advertisers, but
                  we may provide them with aggregate and/or anonymized
                  information to reach their target audience. Lastly, if you
                  subscribe to any <b>Alpha Regiment</b> plan, you may need to
                  provide a copy of a valid government-issued identification
                  document to access the platform.
                </p>

                <h2 className="heading">Information Safety</h2>
                <p>
                  We take the security and protection of your information very
                  seriously. All data is saved and stored on secure servers that
                  are password- and PIN-protected to prevent unauthorized
                  access. Once we have your information, we adhere to strict
                  security guidelines to ensure it remains safe and protected
                  against any unauthorized access.
                </p>

                <h2 className="heading">Choice/Opt-Out</h2>
                <p>
                  We respect your right to control the communications you
                  receive from us. As such, we offer all customers the option to
                  opt-out of receiving non-essential promotional or
                  marketing-related communications from us and our partners,
                  after providing us with their personal information. If you
                  wish to remove your contact information from all lists and
                  newsletters, please send an email to{" "}
                  <Link to="mailto:support@alpharegiment.com">
                    support@alpharegiment.com
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
