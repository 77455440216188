import React from "react";
import "./workshopHeroSection.scss";
import { defaultUser } from "../../assets/images";
import {
  FaTwitter,
  FaFacebookF,
  FaEnvelope,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const WorkshopHero = () => {

  return (
    <div className="hero_section">
      <div className="container">
        <div className="confrence_sec">
          <div className="confrence_subsec_1">
            <h1>
              Freelancer <span>CONFERENCE</span> <span>2022</span>
            </h1>
            <p>
              Feugiat primis ligula risus auctor in ultrice ligula purus ipsum
              primis and cubilia augue vitae laoreet dolor augue and cubilia
              augue egestas undo ipsum vitae integer blandit magna
            </p>
          </div>
          <div className="confrence_subsec_2">
            <div className="conf_month">September 23-26</div>
            <div className="adrr">
              <p>PREMIER BUSINESS CENTER</p>
              <p>MELBOURNE, AUSTRALIA</p>
            </div>
            <p className="description">
              Feugiat primis ligula risus auctor in ultrice ligula purus ipsum
              primis in cubilia augue vitae laoreet dolor augue in cubilia augue
              egestas an ipsum vitae emo integer mollis lorem primis
            </p>
          </div>
        </div>
        <div className="social_speakers_sec">
          <div className="social_links">
            <p>
              <span>More Info: </span>
              <span>
                <Link
                  title="Twitter"
                  target="_blank"
                  className="twitterIcon"
                  to="https://twitter.com/AlphaRegiment"
                >
                  <FaTwitter />
                </Link>
              </span>
              <span>
                <Link
                  title="Facebook"
                  target="_blank"
                  className="facebookIcon"
                  to="https://facebook.com/AlphaRegimentOfficial"
                >
                  <FaFacebookF />
                </Link>
              </span>
              <span>
                <Link
                  title="Email"
                  target="_blank"
                  className="mailIcon"
                  to="mailto:hello@alpharegiment.com"
                >
                  <FaEnvelope />
                </Link>
              </span>
              <span>
                <Link
                  title="Instagram"
                  target="_blank"
                  className="instaIcon"
                  to="https://www.instagram.com/AlphaRegimentOfficial/"
                >
                  <FaInstagram />
                </Link>
              </span>
            </p>
          </div>
          <div className="speakers_sec">
            <p className="head">The Speakers:</p>
            <div className="spkr_all">
              <div className="spkr_card">
                <img src={defaultUser} alt="ravinderYadavHome" />
                <p className="name">Ravinder Yadav</p>
                <p className="designation">CEO</p>
              </div>
              <div className="spkr_card">
                <img src={defaultUser} alt="drDilbaagSinghHome" />
                <p className="name">Dr Dilbaag Singh</p>
                <p className="designation">Manaing Director</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopHero;
