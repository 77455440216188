import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useAuth } from "../../context/userContext";
import WhatsappFloating from "./WhatsappFloating";

const Layout = ({ children }) => {
  const [auth] = useAuth();
  return (
    <div>
      {auth?.user?.role !== "16" &&
        auth?.user?.role !== "17" &&
        auth?.user?.role !== "18" &&
        auth?.user?.role !== "12" &&
        auth?.user?.role !== "13" && <WhatsappFloating />}
      {/* WHATSAPP FLOATING ICON */}
      <Header />
      <main style={{ minHeight: "70vh" }}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
