import React, { Fragment, useEffect, useState } from "react";
import "../styles/register.scss";
import CustomHelmet from "../components/reusable/Helmet";
import CustomInput from "../components/reusable/CustomInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearErrors,
  clearState,
  userRegisterAction,
} from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "../components/reusable/Loader";
import { useAuth } from "../context/userContext";
// import CustomSelect from "../components/reusable/CustomSelect";
import Loader from "../components/reusable/Loader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import { axiosInstance } from "../utils/axiosConfig";
// import { FcGoogle } from "react-icons/fc";
import { FaUpload } from "react-icons/fa";
import { BiLeftArrowAlt } from "react-icons/bi";
// import CustomSelect from "../components/reusable/CustomSelect";
import CustomTextarea from "../components/reusable/CustomTextarea";
import { defaultUser } from "../assets/images";
import PopupPrompt from "../components/reusable/PopupPrompt";
// import CustomMultiSelect from "../components/reusable/CustomMultiSelect";
import Layout from "../components/reusable/Layout";

// OFFICER REGISTRATION FORM
const TeacherRegister = () => {
  const registerHandler = () => {
    navigate("/register");
  };
  const [showPassword, setshowPassword] = useState(false);
  const [popupMsg, setPopupMsg] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profile, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(defaultUser);

  const handleAvatarChange = (e) => {
    if (e.target.name === "profile") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // const profileUploadSubmit = (e) => {
  //   e.preventDefault();
  //   if (profile !== "") {
  //     alert("done profile pic");
  //   } else {
  //     toast.error("Please Select a profile picture...");
  //   }
  // };

  const location = useLocation();
  const redirect = location.search ? location.search.split("=")[1] : "/";
  // const [role, setRole] = useState("13");
  // CONSTANT TEACHER ROLE
  const role = "13";
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [registerData, setRegisterData] = useState({
    first_name: "",
    email: "",
    phone: "",
    bio: "",
    password: "",
    confirmPassword: "",
  });

  // USER REGISTRATION DATA GET BY API
  const {
    userInfo,
    error: registerError,
    loading: registerLoading,
  } = useSelector((state) => state.register);
  const [auth] = useAuth(); // GET USER FROM USER CONTEXT

  // deStructure The Data
  const { first_name, email, phone, bio, password, confirmPassword } =
    registerData;
  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setRegisterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setAcceptTerms(event.target.checked);
  };

  const handleRegister = () => {
    if (
      !first_name ||
      !email ||
      !phone ||
      !password ||
      !confirmPassword ||
      !acceptTerms
    ) {
      toast.error("Please fill in all required fields and accept the terms.");
    } else if (password !== confirmPassword) {
      toast.error("Password and confirm password must be the same!");
    } else {
      const teacherBio = registerData.bio;
      dispatch(
        userRegisterAction(
          first_name,
          "",
          email,
          phone,
          password,
          profile,
          role,
          teacherBio,
          "",
          {},
          {}
        )
      );
    }
  };

  useEffect(() => {
    if (registerError) {
      toast.error(registerError);
      dispatch(clearErrors());
    }
    if (userInfo?.success) {
      // toast.success(userInfo?.message);
      navigate("/login");
      dispatch(clearState());
    }
    if (auth?.user?._id) {
      navigate(redirect);
    }
  }, [
    registerError,
    userInfo?.success,
    userInfo?.message,
    navigate,
    dispatch,
    auth?.user?._id,
    redirect,
  ]);

  return (
    <Fragment>
      {registerLoading ? (
        <Loader />
      ) : (
        <>
          <CustomHelmet
            keywords="Sign up Alpha Regiment "
            description="Use your battlefield experience in the classroom with Alpha Regiment to train future warriors. Empowering retired officers, inspiring the next generation of leaders."
            author="Team Alpha Regiment"
            title="Alpha Regiment :  Teachers Registration"
            canonical={window?.location?.href}
          />

          <section className="signup_sec_main">
            <div className="container">
              <Layout>
                <div className="signUpWrapper">
                  <div className="goback">
                    <span
                      onClick={() => registerHandler("")}
                      title="Click here to go back"
                    >
                      <BiLeftArrowAlt />
                    </span>
                    <span>Back</span>
                  </div>
                  <div className="signUp_content">
                    <div className="title">
                      <h1>REGISTER AS TEACHER</h1>
                    </div>

                    <div
                      className="signUp_form"
                      id="officerForm"
                      // ref={officerFormRef}
                    >
                      <h2 className="subheading">Teacher Details</h2>
                      <div className="student_form">
                        <div className="halfForm">
                          <div>
                            <CustomInput
                              label="Full Name"
                              impStar="*"
                              type="text"
                              placeholder="Ex: Vikram Kumar"
                              value={first_name}
                              name="first_name"
                              onChange={handleInputChange}
                            />
                            <CustomInput
                              label="Email (Username)"
                              type="email"
                              impStar="*"
                              placeholder="Ex: example@gmail.com"
                              value={email}
                              name="email"
                              onChange={handleInputChange}
                            />
                            <CustomInput
                              label="Whatsapp number"
                              type="number"
                              impStar="*"
                              placeholder="Ex: 9876555432"
                              value={phone}
                              name="phone"
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="uploadPicSec">
                            <p>
                              Please upload profile picture
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <div className="profile_pic">
                              <img
                                src={
                                  avatarPreview ? avatarPreview : defaultUser
                                }
                                alt="alpha user profile pic"
                              />
                              <label htmlFor="uploadProfilePic">
                                <FaUpload /> Select File
                              </label>
                              <CustomInput
                                id="uploadProfilePic"
                                label="Upload Profile Picture"
                                type="file"
                                impStar="*"
                                name="profile"
                                accept="image/*"
                                onChange={handleAvatarChange}
                              />
                            </div>
                          </div>
                        </div>

                        <CustomTextarea
                          label="Bio (Please write about yourself)"
                          type="text"
                          impStar="*"
                          onChange={handleInputChange}
                          placeholder="Word limit 300 words..."
                          name="bio"
                          value={bio}
                          fullWidth
                        />

                        <CustomInput
                          label="Create Password"
                          type={showPassword ? "text" : "password"}
                          impStar="*"
                          placeholder="Choose strong password"
                          eyeIcon={
                            showPassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )
                          }
                          value={password}
                          name="password"
                          onChange={handleInputChange}
                          setshowPassword={setshowPassword}
                        />
                        <CustomInput
                          label="Confirm Password"
                          type={showPassword ? "text" : "password"}
                          impStar="*"
                          placeholder="Re-enter password"
                          eyeIcon={
                            showPassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )
                          }
                          value={confirmPassword}
                          name="confirmPassword"
                          onChange={handleInputChange}
                          setshowPassword={setshowPassword}
                        />
                      </div>

                      <label className="pricacyAgreeNote" htmlFor="acceptTerms">
                        <input
                          type="checkbox"
                          required
                          name="acceptTerms"
                          checked={acceptTerms}
                          id="acceptTerms"
                          onChange={handleCheckboxChange}
                        />
                        By submitting this form you agree to the{" "}
                        <Link to="/privacy-policies">privacy policy</Link> &{" "}
                        <Link to="/teacher-term-conditions">
                          terms and condition
                        </Link>
                        .
                      </label>

                      <button
                        title="Alpha Officer Registration Button"
                        onClick={() => handleRegister()}
                        className={`register_submit_btn ${
                          !acceptTerms ? "disabled" : ""
                        }`}
                      >
                        Submit
                      </button>

                      <p className="have_account">
                        Already have an account?{" "}
                        <Link to="/login">Login Now!</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Layout>
            </div>

            {popupMsg && (
              <PopupPrompt
                title="We appreciate your intention of becoming  part of the Alpha Regiment, 
                before that we need to confirm that you really served in the armed forces. 
                We'll contact you in one or two days to show us proof that you were in armed forces. 
                Jai Hind!"
                // lightTitle={true}
                cancelBtnTxt="Okay"
                cancelOnclick={() => setPopupMsg(false)}
              />
            )}
          </section>
        </>
      )}
    </Fragment>
  );
};

export default TeacherRegister;
