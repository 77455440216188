import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { LocaleProvider } from "./context/locale";
import { Provider } from "react-redux";
import store from "./redux/store";
import { UserProvider } from "./context/userContext";
import { hydrate } from "react-dom";
import { NotificationProvider } from "./context/notificationContext";
import { ChatProvider } from "./context/chatContext";


const myApp = (
  <Provider store={store}>
    <BrowserRouter>
      <NotificationProvider>
        {/* {NOTIFICATION SHOW PROVIDER} */}
        <LocaleProvider>
          {/* LANGUAGE CHANGE CONTEXT */}
          <UserProvider>
            {/* USER PROFILE DATA CONTEXT */}
            <ChatProvider>
            <App />
            </ChatProvider>
          </UserProvider>
        </LocaleProvider>
      </NotificationProvider>
    </BrowserRouter>
  </Provider>
);

const rootElement = document.getElementById("alpha");

if (rootElement.hasChildNodes()) {
  hydrate(myApp, rootElement);
} else {
  const root = createRoot(rootElement);
  root.render(myApp);
}
