import React from "react";
import styled from "styled-components";

const FooterMain = styled.div`
  margin-top: 100px;
  @media screen and (max-width: 575px) {
    margin-top: 50px;
  }
  &:before {
    content: "";
    display: block;
    margin: 0 auto;
    border-top: 1px solid #f2f2f2;
    width: 70%;
  }

  > p {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    padding: 50px;
    @media screen and (max-width: 575px) {
      padding: 30px;
      font-size: 10px;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
  }
`;

const TeacherFooter = () => {
  return (
    <FooterMain className="teacher_footer_main txt_color">
      <p>By Alpha Regiment</p>
    </FooterMain>
  );
};

export default TeacherFooter;