import React, { Fragment, useEffect, useState } from "react";
import "../../styles/studentCart.scss";
import { sidebarLinks } from "../student/studentsSidebarLinks";
import CustomButton from "../../components/reusable/CustomButton";
import { wishList } from "../../assets/images";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { useCookies } from "react-cookie";
import { useLocale } from "../../context/locale";
import {
  checkCouponCodeValidation,
  clearCouponCode,
} from "../../redux/actions/userAction";
import { toast } from "react-toastify";
import {
  clearPaymentErrors,
  clearPaymentState,
  
  getAllPayments,
} from "../../redux/actions/instaMojoAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import CustomInput from "../../components/reusable/CustomInput";
import { BsThreeDots } from "react-icons/bs";
import {
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
} from "../../redux/actions/wishListAction";
import Loader from "../../components/reusable/Loader";
import { useNotification } from "../../context/notificationContext";
import StudentLayout from "../../components/student/StudentLayout";
import StudentFooter from "../../components/student/StudentFooter";
import {
  clearRazorErrors,
  clearRazorState,
  createRazorPayment,
  getRazorKey,
  razorPaymentverification,
} from "../../redux/actions/razorpayAction";
import { useAuth } from "../../context/userContext";

// POPUP FOR COUPON CODE AND PAYMENT
const CouponPaymentPopup = ({
  showCouponPopup,
  setShowCouponPopup,
  courseDetails,
}) => {
  // const navigate = useNavigate()
  const [t] = useLocale();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [couponCode, setCouponCode] = useState("");
  const [showEmiTable, setShowEmiTable] = useState(false);
  const dispatch = useDispatch();
  const payment = courseDetails?.discounted_price;
  const courseId = courseDetails?.id;
  const discountePercentage = courseDetails?.affiliate_percentage;
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [initialData, setInitialData] = useState({});

  const closeModal = () => {
    // window.location.reload();
    dispatch(clearCouponCode());
    setShowCouponPopup(false);
    setShowEmiTable(false);
    setCouponCode("");
  };

  // CHECK COUPON CODE IS VALID OR NOT START
  // CHECK COUPON CODE IS VALID OR NOT START
  const handleCodeCheck = () => {
    if (couponCode) {
      dispatch(checkCouponCodeValidation(couponCode));
    } else {
      toast.warning("Please fill Coupon Code!");
    }
  };

  const { loading, isValidCoupon, error } = useSelector(
    (state) => state.checkCouponCode
  );

  let discountedAmount = payment;
  if (isValidCoupon?.success) {
    const discounteAmount = (payment * discountePercentage) / 100;
    discountedAmount = payment - discounteAmount;
  } else {
    discountedAmount = payment;
  }

  useEffect(() => {
    if (error) {
      setCouponCode("");
    }
  }, [error]);
  // CHECK COUPON CODE IS VALID OR NOT ENDS
  // CHECK COUPON CODE IS VALID OR NOT ENDS

  // GET RAZOR PAY KEY START
  const {
    key: razorKey,
    error: razorKeyError,
    loading: razorKeyLoading,
  } = useSelector((state) => state.razorKey);

  const razorPaykey = razorKey?.key;

  useEffect(() => {
    dispatch(getRazorKey());
  }, [dispatch]);

  useEffect(() => {
    if (razorKeyError) {
      dispatch(clearRazorErrors());
    }
  }, [dispatch, razorKeyError]);
  // GET RAZOR PAY KEY ENDS

  // CREATE PAYEMENT INSTANCE START
  // CREATE PAYEMENT INSTANCE START
  const {
    payment: razorPayment,
    error: razorError,
    loading: razorLoading,
  } = useSelector((state) => state.razorPayment);

  const paymentDone = (amount, for_course, couponCode, emiId, seatBooking) => {

    const ssbCourse = courseDetails?.isSsb
      ? courseDetails?.isSsb
      : false;
    setInitialData({
      for_course,
      ssbCourse,
      amount,
      couponCode,
      emiId,
      seatBooking,
    });
    dispatch(
      createRazorPayment(
        for_course,
        ssbCourse,
        amount,
        couponCode,
        emiId,
        seatBooking
      )
    );
  };

  useEffect(() => {
    if (razorPayment?.success) {
      // INTILSE RAZOR PAY FOR PAYENT POPUP START
      const intialiseRazorPay = (order) => {
        var options = {
          key: razorPaykey,
          amount: order.amount,
          currency: "INR",
          order_id: order.id,
          handler: function (response) {
            setPaymentResponse(response);
          },
          prefill: {
            name: auth?.user?.first_name,
            email: auth?.user?.email,
            contact: auth?.user?.phone,
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      };
      intialiseRazorPay(razorPayment?.order);
      // INTILSE RAZOR PAY FOR PAYENT POPUP ENDS
      dispatch(clearRazorState());
    }
    if (razorError) {
      showNotification("error", razorError);
      dispatch(clearRazorErrors());
    }
  }, [
    dispatch,
    razorPaykey,
    razorError,
    razorPayment?.success,
    razorPayment?.order,
    showNotification,
    auth,
  ]);
  // CREATE PAYEMENT INSTANCE ENDS
  // CREATE PAYEMENT INSTANCE ENDS

  // VERIFY PAYMENT START
  // VERIFY PAYMENT START
  const {
    payment: verifyPayment,
    error: verifyPaymentError,
    loading: verifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  useEffect(() => {
    if (paymentResponse && initialData) {
      dispatch(
        razorPaymentverification(
          paymentResponse.razorpay_payment_id,
          paymentResponse.razorpay_order_id,
          paymentResponse.razorpay_signature,
          initialData?.for_course,
          initialData?.ssbCourse,
          initialData?.couponCode,
          initialData?.emiId,
          initialData?.seatBooking
        )
      );
    }
  }, [paymentResponse, initialData, dispatch]);

  useEffect(() => {
    if (verifyPayment?.success) {
      showNotification("success", verifyPayment?.message);
      dispatch(clearRazorState());
    }
    if (verifyPaymentError) {
      showNotification("error", verifyPaymentError);
      dispatch(clearRazorErrors());
    }
  }, [verifyPayment, showNotification, dispatch, verifyPaymentError]);
  // VERIFY PAYMENT ENDS
  // VERIFY PAYMENT ENDS

  // GET COUPON CODE FROM COOKIES START
  // GET COUPON CODE FROM COOKIES START
  const [cookies] = useCookies([]);

  useEffect(() => {
    const myCookieValue = cookies.alphaCouponCodeCookie;
    setCouponCode(myCookieValue);
  }, [cookies]);
  // GET COUPON CODE FROM COOKIES ENDS
  // GET COUPON CODE FROM COOKIES ENDS

  // GET ALL PAYMENTS DONE BY SELF USER START
  // GET ALL PAYMENTS DONE BY SELF USER START
  const {
    //  eslint-disable-next-line
    loading: allPaymentsLoading,
    allPayments,
    error: allPaymentsError,
  } = useSelector((state) => state.allPaymentsDoneByUser);

  const allPaymentsParam = allPayments?.paymentDetails;

  // CHECK FOR SEAT BOOKING
  const seatBookingFees = 500;
  let isSeatBookingDone = [];
  allPaymentsParam?.forEach((payment) => {
    isSeatBookingDone.push(payment?.seatBooking);
  });

  useEffect(() => {
    dispatch(getAllPayments());
  }, [dispatch]);

  useEffect(() => {
    if (allPaymentsError) {
      // toast.error(allPaymentsError);
      dispatch(clearPaymentErrors());
    }
  }, [allPaymentsError, dispatch]);
  // GET ALL PAYMENTS DONE BY SELF USER ENDS
  // GET ALL PAYMENTS DONE BY SELF USER ENDS

  return (
    <section
      className={`coupon_payment_popup_main bg_color txt_color ${
        showCouponPopup ? "showPaymentModal" : ""
      }`}
    >
      {showCouponPopup && (
        <>
          <button className="close_modal" onClick={() => closeModal()}>
            <AiOutlineClose />
          </button>
          <div>
            <p className="coupon_code_txt">
              If you have coupon for discount fill here!
            </p>
            {error && <p className="error_line">{error}</p>}
            {isValidCoupon?.success && (
              <p className="succes_line">{isValidCoupon?.message}</p>
            )}
            <div className="couponCode_sec">
              <CustomInput
                type="text"
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Coupon Code"
                name="couponCode"
                value={couponCode}
              />
              <button
                className={`check_couponCode_btn ${
                  couponCode?.length <= 0 ? "disabled" : ""
                }`}
                onClick={() => handleCodeCheck()}
              >
                {loading ? <ButtonLoader /> : "Apply Coupon"}
              </button>
            </div>
            <div className="pay_withoutCoupon">
              {isValidCoupon?.success && (
                <p style={{ color: "green", fontWeight: "bold" }}>
                  This is {isValidCoupon?.isValidCoupon?.name}'s Coupon Code.
                  you get {discountePercentage}% discount for this Course.
                </p>
              )}
              <div className="all_payment_btns">
                <CustomButton
                  className=""
                  title={`Pay ₹${discountedAmount}`}
                  onClick={() =>
                    paymentDone(
                      discountedAmount,
                      courseId,
                      couponCode,
                      null,
                      false
                    )
                  }
                />
                {courseDetails?.isEmiAvail && (
                  <>
                    <CustomButton
                      className="emi_pay_btn"
                      title={t("pay_in_emi")}
                      onClick={() => setShowEmiTable(!showEmiTable)}
                    />
                    {showEmiTable && (
                      <div className="emi_table_wrapper">
                        <div className="emi_table custonScroll">
                          <div className="title">
                            EMI Installments for {courseDetails?.title}.
                            <AiOutlineClose
                              onClick={() => setShowEmiTable(false)}
                            />
                          </div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>EMI Title</th>
                                <th>EMI Amount</th>
                                <th>EMI Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {courseDetails?.allEmis?.map((item, idx) => {
                                let paymentEmiIdData = [];
                                allPaymentsParam?.forEach((payment) => {
                                  paymentEmiIdData.push(payment?.emiId);
                                });

                                return (
                                  <tr key={item._id}>
                                    <td>
                                      <p>{idx + 1}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiName}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiAmount}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiDuration}days</p>
                                    </td>
                                    <td>
                                      {paymentEmiIdData.includes(item._id) ? (
                                        <p
                                          className="txt_center"
                                          style={{ color: "yellow" }}
                                        >
                                          Wait For Verification
                                        </p>
                                      ) : (
                                        <button
                                          className="pay__emi_btn"
                                          onClick={() =>
                                            paymentDone(
                                              item?.emiAmount,
                                              courseId,
                                              couponCode,
                                              item._id,
                                              false
                                            )
                                          }
                                        >
                                          Pay
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isSeatBookingDone.includes(true) ? (
                  <p style={{ marginTop: "20px", color: "yellow" }}>
                    Seat Booked! Wait for verification.
                  </p>
                ) : (
                  <CustomButton
                    className="seat_book_btn"
                    title={`Book Your Seat`}
                    onClick={() =>
                      paymentDone(
                        seatBookingFees,
                        courseId,
                        couponCode,
                        null,
                        true
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const WishList = () => {
  const [showAction, setshowAction] = useState({});
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [courseData, setcourseData] = useState({});
  const [, , showNotification] = useNotification();
  const dispatch = useDispatch();
  const [auth] = useAuth();

  const { loading, error, allWishList } = useSelector(
    (state) => state.getWishList
  );

  useEffect(() => {
    if(auth?.user?._id){
      dispatch(getAllWishListAction());
    }
  }, [dispatch,auth?.user?._id]);

  useEffect(() => {
    if (error) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, error]);

  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  const handleRemoveWishListItem = ({ id, isSsb }) => {
    dispatch(deleteWishlistItem(id, isSsb));
  };

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  const handlePopuup = (data) => {
    setShowCouponPopup(true);
    setcourseData(data);
  };

  // COURSE PAYMENT HANDLERS START
  // COURSE PAYMENT HANDLERS START
  const {
    //  eslint-disable-next-line
    loading: paymentLoading,
    payment,
    error: paymentError,
  } = useSelector((state) => state.coursePayment);

  useEffect(() => {
    if (paymentError) {
      toast.error(paymentError);
      dispatch(clearPaymentErrors());
    }

    if (payment?.success) {
      window.location.href = payment?.payment_request?.longurl;
      dispatch(clearPaymentState());
    }

    if (!payment?.success) {
      if (payment?.message?.phone) {
        toast.error(payment?.message?.phone[0]);
      }
      if (payment?.message?.amount) {
        toast.error(payment?.message?.amount[0]);
      }
      dispatch(clearPaymentState());
    }
  }, [
    paymentError,
    dispatch,
    payment?.success,
    payment?.payment_request?.longurl,
    payment?.message,
  ]);

  // COURSE PAYMENT HANDLERS ENDS
  // COURSE PAYMENT HANDLERS ENDS
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <StudentLayout sidebarLinks={sidebarLinks}>
            <section className="student_cart_items txt_color">
              <div className="title">
                <h2>Wishlist</h2>
              </div>

              {(allWishList?.wishlist?.ssbCourse?.length > 0 ||
                allWishList?.wishlist?.course?.length) > 0 ? (
                <div className="cart_item_list">
                  {allWishList?.wishlist?.course.length > 0 &&
                    allWishList?.wishlist?.course.map((item) => {
                      return (
                        <div className="cart_item" key={item?._id}>
                          <div className="item_thumb">
                            <img
                              src={item?.thumbnail?.url}
                              alt={item?.thumbnail?.url}
                            />
                          </div>
                          <div className="item_name">
                            <Link
                              to={
                                item?.ssbCourse
                                  ? `/ssb-course-details/${item.id}`
                                  : `/course-detail/${item.id}`
                              }
                            >
                              {item?.title}
                            </Link>
                          </div>
                          <div className="item_price">
                            ₹{" "}
                            {item?.discountedPrice
                              ? Math.ceil(item?.discountedPrice)
                              : Math.ceil(item?.orignalPrice)}
                          </div>
                          <div className="item_action">
                            <CustomButton
                              className="enroll_btn"
                              title="Enroll Now"
                              onClick={() =>
                                handlePopuup({
                                  id: item._id,
                                  title: item.title,
                                  discounted_price: item.discounted_price,
                                 
                                  isEmiAvail: item.isEmiAvail,
                                  allEmis: item.allEmis,
                                  affiliate_percentage:
                                    item.affiliate_percentage,
                                })
                              }
                            />
                            <div className="more_action">
                              <button
                                className="showListBtn"
                                onClick={() =>
                                  setshowAction({ show: true, id: item?._id })
                                }
                                title="Show More Actions"
                              >
                                <BsThreeDots />
                              </button>
                              <div
                                className={`more_action_list ${
                                  showAction.show && showAction.id === item?._id
                                    ? "showAction"
                                    : ""
                                }`}
                              >
                                <CustomButton
                                  className="remove_cart_btn"
                                  title={
                                    deleteLoading ? (
                                      <ButtonLoader />
                                    ) : (
                                      <AiFillDelete />
                                    )
                                  }
                                  onClick={() =>
                                    handleRemoveWishListItem({
                                      id: item?._id,
                                      isSsb: false,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {allWishList?.wishlist?.ssbCourse.length > 0 &&
                    allWishList?.wishlist?.ssbCourse.map((item) => {
                      return (
                        <div className="cart_item" key={item?._id}>
                          <div className="item_thumb">
                            <img
                              src={item?.thumbnail?.url}
                              alt={item?.thumbnail?.url}
                            />
                          </div>
                          <div className="item_name">
                            <Link to={`/course-details/${item.id}`}>
                              {item?.title}
                            </Link>
                          </div>
                          <div className="item_price">
                            ₹ {item?.discountedPrice}
                            {/* <strike>₹ {item?.originalPrice}</strike> */}
                          </div>
                          <div className="item_action">
                            <CustomButton
                              className="enroll_btn"
                              title="Enroll Now"
                              onClick={() =>
                                handlePopuup({
                                  id: item?._id,
                                  title: item?.title,
                                  discounted_price: item?.discountedPrice,
                                  isEmiAvail: item?.isEmiAvail,
                                  allEmis: item?.allEmis,
                                  affiliate_percentage:
                                    item?.affiliate_percentage,
                                    isSsb:item?.ssbCourse
                                })
                              }
                            />
                            <div className="more_action">
                              <button
                                className="showListBtn"
                                onClick={() =>
                                  setshowAction({ show: true, id: item?._id })
                                }
                                title="Show More Actions"
                              >
                                <BsThreeDots />
                              </button>
                              <div
                                className={`more_action_list ${
                                  showAction.show && showAction.id === item?._id
                                    ? "showAction"
                                    : ""
                                }`}
                              >
                                <CustomButton
                                  className="remove_cart_btn"
                                  title={
                                    deleteLoading ? (
                                      <ButtonLoader />
                                    ) : (
                                      <AiFillDelete />
                                    )
                                  }
                                  onClick={() =>
                                    handleRemoveWishListItem({
                                      id: item?._id,
                                      isSsb: true,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="no_item_cart">
                  <img src={wishList} alt="wishlist" />
                  <p>
                    At ease, cadet! Your Course Wishlist is awaiting your
                    command. Dive into our catalog and select your educational
                    arsenal with precision. Carry on!
                  </p>
                  <Link to="/ssb-courses">Course Page</Link>
                </div>
              )}
            </section>

            {/* COUPON CODE POPUP START */}

            <CouponPaymentPopup
              showCouponPopup={showCouponPopup}
              setShowCouponPopup={setShowCouponPopup}
              courseDetails={courseData}
            />
            {/* COUPON CODE POPUP ENDS */}
          </StudentLayout>
          {/* FOOTER START */}
          <StudentFooter />
          {/* FOOTER ENDS */}
        </>
      )}
    </Fragment>
  );
};

export default WishList;
