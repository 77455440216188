import React, { useRef, useState } from "react";
import "../../styles/cyberPartnerHome.scss";
import { grow1, grow2, grow3 } from "../../assets/images";
import cyberPartnerHero from "../../images/cyberPartnerBg.png";
import CustomButton from "../../components/reusable/CustomButton";
import { BsChevronRight } from "react-icons/bs";
import { affiliateTestimonialArray } from "../../dummyContent";
import CustomInput from "../../components/reusable/CustomInput";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import Footer from "../../components/reusable/Footer";
import CustomHelmet from "../../components/reusable/Helmet";
import Carousel from "react-elastic-carousel";
import TestimonialMobile from "../../components/reusable/TestimonialMobile";
import { toast } from "react-toastify";
import { useLocale } from "../../context/locale";
import { Trans } from "react-i18next";
import AffiliateNav from "../affiliate/AffiliateNav";
import videoIcon from "../../images/video_icon.png";
import { AiOutlineClose } from "react-icons/ai";

// Popup for Know More Video Of Batch
const MoreDetailBatchVideo = ({ showDetailVideo, setshowDetailVideo }) => {
  return (
    <section
      className={`more_batch_details_modal ${
        showDetailVideo ? "show_video_Mocal" : ""
      } bg_color txt_color`}
    >
      <div className="container">
        {showDetailVideo && (
          <div className="video_content">
            <button
              className="close_modal"
              onClick={() => setshowDetailVideo(false)}
            >
              <AiOutlineClose />
            </button>
            <div>
              <div
                style={{
                  left: 0,
                  width: "100%",
                  height: 0,
                  position: "relative",
                  paddingBottom: "56.25%",
                }}
              >
                <figure
                  style={{
                    left: 0,
                    width: "100%",
                    height: 0,
                    position: "relative",
                    paddingBottom: "56.25%",
                    marginBlockEnd: 0,
                    marginBlockStart: 0,
                    marginInlineStart: 0,
                    marginInlineEnd: 0,
                  }}
                >
                  <iframe
                    title="How can Cyber Cafe owners become our partners? Alpha Regiment"
                    src="https://www.youtube.com/embed/_FpYBy6PvkY"
                    scrolling="no"
                    style={{
                      border: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      overflow: "hidden",
                      borderRadius: 5,
                    }}
                    allowFullScreen={1}
                    allow="autoplay"
                  />
                </figure>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const CyberPartner = () => {
  const [t] = useLocale();
  const [showDetailVideo, setshowDetailVideo] = useState(false);

  const letsGrowContent = [
    {
      id: 1,
      title: t("affiliateHelpingOtherHeading"),
      image: grow1,
      text: t("affiliateHelpingOtherDesc"),
    },
    {
      id: 2,
      title: t("affiliateEarningHeading"),
      image: grow2,
      text: (
        <Trans
          i18nkey="affiliateEarningDesc"
          components={{
            MyLink: (
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://alpharegiment.com/affiliate-commission"
              >
                Click here
              </a>
            ),
          }}
        >
          {t("affiliateEarningDesc")}
        </Trans>
      ),
    },
    {
      id: 3,
      title: t("affiliatePaymentHeading"),
      image: grow3,
      text: t("affiliatePaymentDesc"),
    },
  ];

  // testimonial slider breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
  ];

  // FORM DATA FOR AFFILIATE REQUEST
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    occupation: "",
    currentLocation: "",
    promotion: "",
  });

  // DESTURUCTURE
  const { fullName, email, phone, occupation, currentLocation, promotion } =
    formData;

  // GET FORM INPUT DATA CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // SUBMIT AFFILIATE FORM DATA ON MAIL
  const handleSubmit = () => {
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Email sent through affiliate form",
      Body:
        "<h1>This Email sent through affiliate form</h1> <br/> <p>Full Name:" +
        fullName +
        "</P> <p><b>Mobile Number:</b> " +
        phone +
        "</p> <p><b>Email Id:</b> " +
        email +
        "</p> <p><b>Occupation:</b> " +
        occupation +
        "</p> <p><b>Current Location:</b> " +
        currentLocation +
        "</p><p><b>Promotion:</b> " +
        promotion +
        "</p>",
    };
    if (
      fullName &&
      email &&
      phone &&
      occupation &&
      currentLocation &&
      promotion
    ) {
      if (window.Email) {
        window.Email.send(config).then(() => {
          toast.success("Request Sent Successfully.");
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            occupation: "",
            currentLocation: "",
            promotion: "",
          });
        });
      }
    } else {
      toast.error("Please fill your query.");
    }
  };

  // HANDLE ONCLICK PAGE SCROLL ON FORM
  const scrollFormRef = useRef(null);

  const handleScrollToForm = () => {
    scrollFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="cyber_partner_main txt_color">
      <CustomHelmet
        keywords={t("affiliateMetaKeywords")}
        description="A simple and easy way to make money is by referring Alpha Regiment to people. Get 5% of every sale you refer. There's no limit on how much you can earn."
        author={t("affiliateMetaAuthor")}
        title="Join Alpha Regiment to Increse the profit of your cyber cafe?"
        canonical={window?.location?.href}
      />

      {/* AFFILIATE NAVBAR START */}
      <AffiliateNav />
      {/* AFFILIATE NAVBAR ENDS */}

      {/* Affiliate Hero Section Start */}
      <div className="affilaite_hero">
        <div className="container">
          <div className="hero_layout">
            <div className="txt_sec">
              <h1>{t("cyberPage_heroHead")}</h1>
              <CustomButton
                className="affiliate_hero_btn"
                title={t("cyberPage_btnText")}
                icon={<BsChevronRight />}
                onClick={handleScrollToForm}
              />
            </div>
            <div className="img_sec" onClick={() => setshowDetailVideo(true)}>
              <img
                className="main_hero_img"
                src={cyberPartnerHero}
                alt="cyberPartnerHero"
              />
              <img className="abs_vdo_icon" src={videoIcon} alt="videoIcon" />
            </div>
          </div>
        </div>
      </div>
      {/* Affiliate Hero Section Ends */}

      {/* LETS GROW SECTION START */}
      <div className="lets_grow">
        <div className="container">
          <div className="title">
            <h2>{t("affiliateGrowHeading")}</h2>
            <p>{t("affiliateGrowSubHead")}</p>
          </div>

          <div className="lets_grow_content">
            {letsGrowContent.map((item) => (
              <div key={item.id} className="letsGrowCard">
                <img src={item.image} alt={item.title} />
                <h2>{item.title}</h2>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* LETS GROW SECTION ENDS */}

      {/* TESTIMONIAL SECTION START */}
      <div className="affiliate_testimonil">
        <div className="container">
          <div className="title">
            <h2>{t("testimonial_heading")}</h2>
          </div>
          <div className="bg_color">
            {affiliateTestimonialArray.map((item) => {
              return (
                <div className="testimonial" key={item.id}>
                  <div className="img_sec">
                    <img src={item.img} alt={item.text} />
                  </div>
                  <div className="content_sec">
                    <div className="cardTitle">
                      <h2>{item.title}</h2>
                      <p>{item.subtitle}</p>
                    </div>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            })}
            <Carousel breakPoints={breakPoints} className="testimonial_mobile">
              {affiliateTestimonialArray.map((item) => {
                return (
                  <TestimonialMobile
                    key={item.id}
                    img={item.img}
                    title={item.title}
                    business={item.subtitle}
                    description={item.text}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      {/* TESTIMONIAL SECTION ENDS */}

      {/* Become an affiliate section start  */}
      <div className="affiliate_form_main" ref={scrollFormRef}>
        <div className="container">
          <div className="title">
            <h2>Become an partner</h2>
          </div>
          <div className="form_content">
            <div className="text_sec">
              <p>{t("cyberPage_FormDescFirst")}</p>
              <p>{t("affiliateFormDescSecond")}</p>
              <div className="border_div"></div>
            </div>
            <div className="form_sec">
              <CustomInput
                icon=""
                label="Name"
                type="text"
                placeholder="Ex: Ashish verma"
                name="fullName"
                value={fullName}
                onChange={handleInputChange}
              />
              <CustomInput
                icon=""
                label="Email"
                type="email"
                placeholder="Ex: ashishverma123@gmail.com"
                name="email"
                value={email}
                onChange={handleInputChange}
              />
              <CustomInput
                icon=""
                label="Phone number"
                type="number"
                placeholder="Ex: 9876555432"
                name="phone"
                value={phone}
                onChange={handleInputChange}
              />
              <CustomInput
                icon=""
                label="Occupation"
                type="text"
                placeholder="Ex: Cyber cafe owner"
                name="occupation"
                value={occupation}
                onChange={handleInputChange}
              />
              <CustomInput
                icon=""
                label="Curent location"
                type="text"
                placeholder="Ex: MG road, Gurgaon"
                name="currentLocation"
                value={currentLocation}
                onChange={handleInputChange}
              />
              <CustomTextarea
                icon=""
                label="Promotion"
                type="text"
                placeholder="How do you intend to promote us? Your monthly estimate for referrals.."
                name="promotion"
                value={promotion}
                onChange={handleInputChange}
              />

              <CustomButton
                onClick={handleSubmit}
                title="Submit"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Become an affiliate section ends  */}

      {/* FOOTER SECTION START */}
      <Footer />
      {/* FOOTER SECTION ENDS */}

      {/* POPUP FOR DETAIL VIDEO OF BATCH START */}
      <MoreDetailBatchVideo
        showDetailVideo={showDetailVideo}
        setshowDetailVideo={setshowDetailVideo}
      />
      {/* POPUP FOR DETAIL VIDEO OF BATCH ENDS */}
    </section>
  );
};

export default CyberPartner;
