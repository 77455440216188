import {
  CHECK_OFFICER_AVAILABILTIY_FAIL,
  CHECK_OFFICER_AVAILABILTIY_REQUEST,
  CHECK_OFFICER_AVAILABILTIY_SUCCESS,
  GET_OFFICER_CALENDER_FAIL,
  GET_OFFICER_CALENDER_REQUEST,
  GET_OFFICER_CALENDER_SUCCESS,
  OFFICER_CALENDER_CLEAR_ERRORS,
  OFFICER_CALENDER_CLEAR_STATE,
  OFFICER_CALENDER_CREATE_FAIL,
  OFFICER_CALENDER_CREATE_REQUEST,
  OFFICER_CALENDER_CREATE_SUCCESS,
  OFFICER_CALENDER_UPDATE_CLEAR_STATE,
  OFFICER_CALENDER_UPDATE_FAIL,
  OFFICER_CALENDER_UPDATE_REQUEST,
  OFFICER_CALENDER_UPDATE_SUCCESS,
} from "../constants/officerCalenderConstant";

export const createCalenderReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFICER_CALENDER_CREATE_REQUEST:
      return {
        loading: true,
        eventData: {},
      };
    case OFFICER_CALENDER_CREATE_SUCCESS:
      return {
        loading: false,
        eventData: action.payload,
      };
    case OFFICER_CALENDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case OFFICER_CALENDER_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case OFFICER_CALENDER_CLEAR_STATE:
      return {
        loading: false,
        eventData: {},
      };

    default:
      return state;
  }
};

export const updateCalenderReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFICER_CALENDER_UPDATE_REQUEST:
      return {
        loading: true,
        eventData: {},
      };
    case OFFICER_CALENDER_UPDATE_SUCCESS:
      return {
        loading: false,
        eventData: action.payload,
      };
    case OFFICER_CALENDER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case OFFICER_CALENDER_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case OFFICER_CALENDER_UPDATE_CLEAR_STATE:
      return {
        loading: false,
        eventData: {},
      };

    default:
      return state;
  }
};

// GET ALL COURSES REDUCER
export const getOfficerCalenderEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OFFICER_CALENDER_REQUEST:
      return {
        loading: true,
        officerEventData: {},
      };
    case GET_OFFICER_CALENDER_SUCCESS:
      return {
        loading: false,
        officerEventData: action.payload,
      };
    case GET_OFFICER_CALENDER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case OFFICER_CALENDER_CLEAR_ERRORS:
      return {
        officerEventData: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const getOfficerTimeAvalabilityReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_OFFICER_AVAILABILTIY_REQUEST:
      return {
        loading: true,
        officerAvalability: {},
      };
    case CHECK_OFFICER_AVAILABILTIY_SUCCESS:
      return {
        loading: false,
        officerAvalability: action.payload,
      };
    case CHECK_OFFICER_AVAILABILTIY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case OFFICER_CALENDER_CLEAR_ERRORS:
      return {
        officerAvalability: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
