import React, { Fragment, useState, useEffect } from "react";
import "../../styles/mockTest.scss";
import {
  StudentProgressMonitoring,
  alphaSchoolHero,
  bookDemo,
  embranceOfficer,
  focusPassionOfficer,
} from "../../assets/images";
import CustomButton from "../../components/reusable/CustomButton";
import audioBell from "../../audio/notification-bell.wav";

const questions = [
  {
    id: 1,
    image: alphaSchoolHero,
  },
  {
    id: 2,
    image: StudentProgressMonitoring,
  },
  {
    id: 3,
    image: bookDemo,
  },
  {
    id: 4,
    image: alphaSchoolHero,
  },
  {
    id: 5,
    image: embranceOfficer,
  },
  {
    id: 6,
    image: focusPassionOfficer,
  },
  // {
  //   id: 7,
  //   image: "",
  // },
];

const wordArray = new Array(10).fill("").map((_, index) => `hello ${index}`);

// DUMMY SITUTATION ARRAY
const situation = [
  {
    id: 1,
    srt: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 2,
    srt: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 3,
    srt: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 4,
    srt: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 5,
    srt: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
];

const MockStudent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const notification = new Audio(audioBell);

  const [tatInstruction, setTatInstruction] = useState(true);
  const [tatTimer, setTatTimer] = useState(30);
  const [typingTimer, setTypingTimer] = useState(240);
  const [showImage, setShowImage] = useState(false);
  const [showTypingText, setShowTypingText] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    let timer;
    if (!tatInstruction && tatTimer > 0) {
      timer = setTimeout(() => {
        setTatTimer((prev) => prev - 1);
      }, 1000);
    } else if (!tatInstruction && tatTimer === 0) {
      setShowTypingText(true);
      setShowImage(false);
      setTypingTimer(240);
    }
    return () => clearTimeout(timer);
  }, [tatInstruction, tatTimer]);

  useEffect(() => {
    let timer;
    if (!tatInstruction && showTypingText && typingTimer > 0) {
      timer = setTimeout(() => {
        setTypingTimer((prev) => prev - 1);
      }, 1000);
    } else if (
      activeStep === 0 &&
      !tatInstruction &&
      typingTimer === 0 &&
      tatTimer === 0
    ) {
      notification.play();
      setShowTypingText(false);
      setShowImage(true);
      setTatTimer(30);
      setCurrentImageIndex((prev) => prev + 1);
    } else if (activeStep === 0 && currentImageIndex > questions?.length) {
      notification.play();
      setActiveStep(activeStep + 1);
    }
    return () => clearTimeout(timer);
  }, [showTypingText, typingTimer, tatInstruction]);

  const handleTatTest = () => {
    setTatInstruction(false);
    setShowImage(true);
  };

  // console.log(currentImageIndex);

  // HANDLE WAT TEST STATE AND FUNCTIONS
  const [watInstruction, setWatInstruction] = useState(true);
  const [watTimer, setWatTimer] = useState(15);
  const [showWord, setShowWord] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    let timer;
    if (!watInstruction && watTimer > 0) {
      timer = setTimeout(() => {
        setWatTimer((prev) => prev - 1);
      }, 1000);
    }
    if (activeStep === 1 && !watInstruction && watTimer === 0) {
      notification.play();
      setWatTimer(15);
      setCurrentWordIndex((prev) => prev + 1);
    }
    if (activeStep === 1 && currentWordIndex > wordArray?.length) {
      notification.play();
      setActiveStep(activeStep + 1);
      setSrtInstruction(true);
    }
    return () => clearTimeout(timer);
  }, [watInstruction, watTimer]);

  const handleWatTest = () => {
    setWatInstruction(false);
    setShowWord(true);
  };

  // HANDLE WAT TEST FUNCTIONS ENDS

  // HANDLE SRT TEST FUBCTION STARTS
  const [srtInstruction, setSrtInstruction] = useState(true);
  const [srtTimer, setSrtTimer] = useState(1800);
  const [showSrt, setShowSrt] = useState(false);

  useEffect(() => {
    let timer;
    if (!srtInstruction && srtTimer > 0) {
      timer = setTimeout(() => {
        setSrtTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [srtInstruction, srtTimer]);

  const handleSrtTest = () => {
    setSrtInstruction(false);
    setShowSrt(true);
  };

  // HANDLE SRT TEST FUBCTION STARTS

  // GO TO NEXT TEST STEP
  const goToNext = () => {
    if (activeStep === 0) {
      setActiveStep(1);
      setWatInstruction(true);
    }
    if (activeStep === 1) {
      setActiveStep(2);
    }
  };

  // FORMAT THE TIME IN MUTES AND SECONDS

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${padTime(minutes)}:${padTime(seconds)}`;
  };

  const padTime = (time) => {
    return time.toString().padStart(2, "0");
  };

  return (
    <Fragment>
      <section className="mock_text_wrapper">
        <div className="container">
          {/* HANLDE TAT LOGICS STARTS  */}
          {activeStep === 0 && tatInstruction && (
            <div className="test_instruction">
              <h2>TAT Instruction</h2>
              <ul>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
              </ul>
              <div className="start_test_btn">
                <CustomButton
                  title="Start Test"
                  width="120px"
                  onClick={handleTatTest}
                />
              </div>
            </div>
          )}

          {activeStep === 0 &&
            !tatInstruction &&
            (showTypingText || showImage) &&
            currentImageIndex < questions?.length && (
              <div className="test_start">
                {showTypingText && currentImageIndex <= questions?.length && (
                  <p>Start Writing...</p>
                )}
                {showImage && currentImageIndex < questions?.length && (
                  <div className="question_list">
                    <img
                      src={questions[currentImageIndex]?.image}
                      alt={questions[currentImageIndex]?.image}
                    />
                  </div>
                )}
              </div>
            )}

          {/* GO TO WAT BUTTON  */}
          {activeStep === 0 && questions?.length < currentImageIndex && (
            <div className="next_test">
              <CustomButton
                title="Go To WAT Test"
                width="120px"
                onClick={() => goToNext()}
              />
            </div>
          )}

          {/* HANDLE TAT LOGICS ENDS */}

          {/* HANLDE WAT LOGICS STARTS  */}
          {activeStep === 1 && watInstruction && (
            <div className="test_instruction">
              <h2>WAT Instruction</h2>
              <ul>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
              </ul>
              <div className="start_test_btn">
                <CustomButton
                  title="Start Test"
                  width="120px"
                  onClick={handleWatTest}
                />
              </div>
            </div>
          )}

          {activeStep === 1 && !watInstruction && (
            <div className="test_start">
              {/* {showTypingWord &&
                  currentWordIndex <= wordArray?.length - 1 && (
                    <p>Start Writing...</p>
                  )} */}
              {showWord && currentWordIndex < wordArray?.length && (
                <div className="word_list">
                  <p>{wordArray[currentWordIndex]}</p>
                </div>
              )}
            </div>
          )}

          {/* HANLDE WAT LOGICS ENDS  */}

          {/* HANDLE SRT TEST LOGICS STARTS  */}

          {activeStep === 2 && srtInstruction && (
            <div className="test_instruction">
              <h2>SRT Instruction</h2>
              <ul>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
                <li>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups. Lorem ipsum is placeholder text commonly used
                  in the graphic, print, and publishing industries for
                  previewing layouts and visual mockups.
                </li>
              </ul>
              <div className="start_test_btn">
                <CustomButton
                  title="Start Test"
                  width="120px"
                  onClick={handleSrtTest}
                />
              </div>
            </div>
          )}

          {activeStep === 2 && !srtInstruction && showSrt && (
            <div className="test_start">
              {/* {showTypingSrt && <p>Start Writing...</p>} */}
              {showSrt && (
                <ul className="srt_list">
                  {situation?.map((ele, idx) => (
                    <li key={idx}>
                      <p>{ele?.srt}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {/* HANDLE SRT TEST LOGICS Ends  */}
        </div>
      </section>
    </Fragment>
  );
};

export default MockStudent;
