import React, { Fragment, useEffect, useState } from "react";
import { BsPersonFillAdd } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import {
  clearFollowErrors,
  clearRemoveFollowState,
  getFollowerList,
  removeFollower,
} from "../../redux/actions/followAction";
import { defaultUser, followerImg } from "../../assets/images";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import Loader from "../../components/reusable/Loader";
const OFollowerList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // GET ALL FOLLOWERS
  const {
    loading: followerLoading,
    error: followerError,
    followList,
  } = useSelector((state) => state.followerList);

  const followers = followList?.follows?.followers;

  useEffect(() => {
    dispatch(getFollowerList());
  }, [dispatch]);

  useEffect(() => {
    if (followerError) {
      showNotification("error", followerError);
      dispatch(clearFollowErrors());
    }
  }, [followerError, showNotification, dispatch]);

  const handleViewProfile = (data) => {
    navigate(`/friend-profile?${data?._id}`);
  };

  const [removeId, setRemoveId] = useState();
  const handleRemoveFollower = (id) => {
    setRemoveId(id);
    dispatch(removeFollower(id));
  };

  const {
    error: removeFollowError,
    loading: removeFollowLoading,
    followInfo,
  } = useSelector((state) => state.removeRequest);

  useEffect(() => {
    if (removeFollowError) {
      showNotification("error", removeFollowError);
      dispatch(clearFollowErrors());
    }
    if (followInfo?.success) {
      showNotification("success", followInfo?.message);
      dispatch(getFollowerList());
      dispatch(clearRemoveFollowState());
    }
  }, [
    removeFollowError,
    followInfo?.success,
    followInfo?.message,
    dispatch,
    showNotification,
  ]);

  return (
    <Fragment>
      {followerLoading ? (
        <Loader />
      ) : (
        <div className="officer_follower_main txt_color">
          <div className="title">
            <button title="Go Back" onClick={() => navigate(-1)}>
              <BiLeftArrowAlt />
            </button>
            Followers
          </div>
          <div className="container">
            <div className="officer_timeline_content">
              {followers?.length > 0 ? (
                <div className="officersList">
                  {followers?.map((item, id) => (
                    <div className="officerRow" key={id}>
                      <div className="profileName">
                        <span>
                          <img
                            src={
                              item?.profile?.url
                                ? item?.profile?.url
                                : defaultUser
                            }
                            alt="alpha officer"
                          />
                        </span>
                        <span>{item?.first_name}</span>
                      </div>
                      <div className="officerTag">
                        {item?.role === "18" && <p>Psychologist</p>}
                        {item?.role === "17" && <p>I/O</p>}
                        {item?.role === "16" && <p>GTO</p>}
                      </div>
                      <div className="connectBtn">
                        <CustomButton
                          className=""
                          title="View Profile"
                          onClick={() => handleViewProfile(item)}
                          preIcon={<BsPersonFillAdd />}
                        />
                        <CustomButton
                          className=""
                          title={
                            removeId === item?._id && removeFollowLoading ? (
                              <ButtonLoader />
                            ) : (
                              "Remove"
                            )
                          }
                          onClick={() => handleRemoveFollower(item?._id)}
                          preIcon={<BsPersonFillAdd />}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <OfficerNoDataCard
                  txt="Alpha teacher share compelling content to attract aspiring followers and grow your audience."
                  image={followerImg}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OFollowerList;
