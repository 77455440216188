import React, { useEffect } from 'react';
import "../../styles/notification.scss";
import { useNotification } from '../../context/notificationContext';
import { RxCross2 } from "react-icons/rx";
import { useRef } from 'react';

const NotificationPrompt = () => {
    // GET NOTIFICATION STATE FROM NOTIFICATION CONTEXT
    const [notification, visible, showNotification, hideNotification] = useNotification();
    const timerRef = useRef(null);
    const startTimer = () => {
        timerRef.current = setTimeout(() => {
            hideNotification();
        }, 7000);
    };

    useEffect(() => {
        startTimer();
        return () => {
            clearTimeout(timerRef.current);
        };
    }, [notification]);

    const handleMouseEnter = () => {
        clearTimeout(timerRef.current);
    };


    const handleMouseLeave = () => {
        startTimer();
    };
    return (
        <div
            className={`notification ${notification?.type} ${visible ? 'visible' : ''}`}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <p>{notification?.message}</p>
            <span onClick={hideNotification}>
                <RxCross2 />
            </span>
        </div>
    );
};

export default NotificationPrompt;
