import React, { useRef, useState } from "react";
import "../../styles/franchiseHome.scss";
import FranchiseNav from "./FranchiseNav";
import {
  appWeb,
  focoModel,
  franchisiHero,
  holoMap,
  noRoyality,
  support,
} from "../../assets/images";
import { BsChevronRight } from "react-icons/bs";
import CustomButton from "../../components/reusable/CustomButton";
import CustomCard from "../../components/reusable/CustomCard";
import { frenchiseTestimonialArray } from "../../dummyContent";
import { FiDownload } from "react-icons/fi";
import Footer from "../../components/reusable/Footer";
import CustomInput from "../../components/reusable/CustomInput";
import TestimonialMobile from "../../components/reusable/TestimonialMobile";
import Carousel from "react-elastic-carousel";
import CustomHelmet from "../../components/reusable/Helmet";
import { useLocale } from "../../context/locale";
import { toast } from "react-toastify";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";

const FranchiseHome = () => {
  const [t] = useLocale();

  // testimonial slider breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
  ];

  // WHY ALPHA REGIMENT _ FRENCHISE HOME PAGE
  const frenchiseWhyAlpha = [
    {
      id: 1,
      title: t("franchiseBestROI"),
      image: focoModel,
      desc: t("franchiseBestROIdesc"),
    },
    {
      id: 2,
      title: t("royalityFreeHeading"),
      image: noRoyality,
      desc: t("royalityFreeDesc"),
    },
    {
      id: 3,
      title: t("bearLossHeading"),
      image: appWeb,
      desc: t("bearLossDesc"),
    },
    {
      id: 4,
      title: t("technologyHeading"),
      image: support,
      desc: t("technologyDesc"),
    },
  ];

  // HANDLE ONCLICK PAGE SCROLL ON FORM
  const scrollFormRef = useRef(null);

  const handleScrollToForm = () => {
    scrollFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // FORM DATA FOR AFFILIATE REQUEST
  const [formData, setFormData] = useState({
    fullName: "",
    qualification: "",
    email: "",
    landLocation: "",
    phone: "",
    address: "",
    land: "",
  });

  // DESTRUCTURE DATA
  const { fullName, qualification, email, landLocation, phone, address, land } =
    formData;

  // GET FORM INPUT DATA CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // SUBMIT FRANCHISE FORM DATA ON MAIL
  const handleSubmit = () => {
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Email sent through franchise form",
      Body:
        "<h1>This Email sent through franchise form</h1> <br/> <p>Full Name:" +
        fullName +
        "</P> <p><b>Mobile Number:</b> " +
        phone +
        "</p> <p><b>Email Id:</b> " +
        email +
        "</p> <p><b>Land Location:</b> " +
        landLocation +
        "</p> <p><b>Qualification:</b> " +
        qualification +
        "</p><p><b>Land:</b> " +
        land +
        "</p><p><b>Full Address:</b> " +
        address +
        "</p>",
    };

    if (
      fullName &&
      qualification &&
      email &&
      landLocation &&
      phone &&
      address &&
      land
    ) {
      if (window.Email) {
        window.Email.send(config).then(() => {
          toast.success("Request Sent Successfully.");
          setFormData({
            fullName: "",
            qualification: "",
            email: "",
            landLocation: "",
            phone: "",
            address: "",
            land: "",
          });
        });
      }
    } else {
      toast.error("Please fill your query.");
    }
  };

  return (
    <section className="franchise_home_main txt_color">
      <CustomHelmet
        title={t("franchiseMetaTitle")}
        keywords={t("franchiseMetaKeywords")}
        description={t("franchiseMetaDescription")}
        author={t("franchiseMetaAuthor")}
        canonical={window?.location?.href}
      />

      {/* NAVBAR START */}
      <FranchiseNav />
      {/* NAVBAR ENDS */}

      {/* FRANCHISE HOME SECTION START */}
      <div className="hero_section_main">
        <div className="container">
          <div className="hero_layout">
            <div className="txt_sec">
              <h1>{t("franChisePageHeading")}</h1>
              <CustomButton
                className="affiliate_hero_btn"
                title={t("applyHereBtn")}
                icon={<BsChevronRight />}
                onClick={handleScrollToForm}
              />
            </div>
            <div className="img_sec">
              {/* <img src={franchisiHero} alt="franchisiHero" /> */}
              <ImgLazyLoad src={franchisiHero} alt="franchisiHero" />
            </div>
          </div>
        </div>
      </div>
      {/* FRANCHISE HOME SECTION ENDS */}

      {/* WE ARE GROWING EVERYDAY SECTION START */}
      <div className="growing_section">
        <div className="container">
          {/* <div className="heading_sec">
            <h2>We are growing Everyday</h2>
          </div> */}
          <div className="map_marker_content">
            {/* <img className="holoMap" src={holoMap} alt="holoMap" /> */}
            <ImgLazyLoad src={holoMap} alt="holoMap" custom_class="holoMap" />
          </div>
        </div>
      </div>
      {/* WE ARE GROWING EVERYDAY SECTION ENDS */}

      {/* Why be a part of alpha regiment section start */}
      <div className="why_alpha_main">
        <div className="container">
          <div className="title">
            <h2>{t("franchise_AlphaRegiment_part")}</h2>
          </div>
          <div className="why_alpha_content">
            {frenchiseWhyAlpha?.map((item) => (
              <CustomCard
                key={item.id}
                img={item.image}
                title={item.title}
                description={item.desc}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Why be a part of alpha regiment section ends */}

      {/* TESTIMONIAL SECTION START */}
      <div className="affiliate_testimonil">
        <div className="container">
          <div className="title">
            <h2>{t("testimonial_heading")}</h2>
          </div>
          <div className="bg_color">
            {frenchiseTestimonialArray?.map((item) => {
              return (
                <div className="testimonial" key={item.id}>
                  <div className="img_sec">
                    {/* <img src={item.img} alt={item.text} /> */}
                    <ImgLazyLoad src={item.img} alt={item.title} />
                  </div>
                  <div className="content_sec">
                    <div className="cardTitle">
                      <h2>{item.title}</h2>
                      <p>{item.subtitle}</p>
                    </div>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            })}

            <Carousel breakPoints={breakPoints} className="testimonial_mobile">
              {frenchiseTestimonialArray.map((item) => {
                return (
                  <TestimonialMobile
                    key={item.id}
                    img={item.img}
                    title={item.title}
                    business={item.subtitle}
                    description={item.text}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      {/* TESTIMONIAL SECTION ENDS */}

      {/* TERMS AND CONDITIONS SECTION START */}
      <div className="term_condition">
        <div className="container">
          <div className="title">
            <h2>{t("franchise_getMoreDetails")}</h2>
          </div>
          <div className="term_content">
            <p>{t("getMoreDetailsPara")}</p>
            <CustomButton
              className="download_btn"
              title="Download"
              icon={<FiDownload />}
              width="50%"
            />
          </div>
        </div>
      </div>
      {/* TERMS AND CONDITIONS SECTION ENDS */}

      {/* APPLY FOR FRANCHISE SECTION START */}
      <div className="apply_franchise_form" ref={scrollFormRef}>
        <div className="container">
          <div className="title">
            <h2>{t("franchise_applyBtn")}</h2>
          </div>
          <div className="franchise_form">
            <CustomInput
              label="Name"
              type="text"
              placeholder="Ex: Ashish verma"
              name="fullName"
              value={fullName}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Qualification"
              type="text"
              placeholder="Ex: Graduate"
              name="qualification"
              value={qualification}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Email"
              type="email"
              placeholder="Ex: ashishverma123@gmail.com"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Land Location"
              type="text"
              placeholder="Ex: MG road, Gurgaon"
              name="landLocation"
              value={landLocation}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Phone number"
              type="number"
              placeholder="Ex: 9876555432"
              name="phone"
              value={phone}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Address"
              type="text"
              placeholder="Enter your address.."
              name="address"
              value={address}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Land"
              type="text"
              placeholder="Ex: 800 Sqft"
              name="land"
              value={land}
              onChange={handleInputChange}
            />
            <CustomButton
              onClick={handleSubmit}
              className="franchise_submit"
              title="Submit"
            />
          </div>
        </div>
      </div>
      {/* APPLY FOR FRANCHISE SECTION ENDS */}

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER ENDS */}
    </section>
  );
};

export default FranchiseHome;
