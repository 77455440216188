import {
  CLEAR_RAZOR_ERROR,
  CLEAR_RAZOR_STATE,
  RAZOR_KEY_FAIL,
  RAZOR_KEY_REQUEST,
  RAZOR_KEY_SUCCESS,
  RAZOR_PAYMENT_FAIL,
  RAZOR_PAYMENT_REQUEST,
  RAZOR_PAYMENT_SUCCESS,
  RAZOR_PAYMENT_VERIFY_FAIL,
  RAZOR_PAYMENT_VERIFY_REQUEST,
  RAZOR_PAYMENT_VERIFY_SUCCESS,
} from "../constants/razorpayConstants";

// CREATE PAYEMENT REDUCER
export const razorPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case RAZOR_PAYMENT_REQUEST:
      return {
        loading: true,
        payment: {},
      };
    case RAZOR_PAYMENT_SUCCESS:
      return {
        loading: false,
        payment: action.payload,
      };
    case RAZOR_PAYMENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_RAZOR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_RAZOR_STATE:
      return {
        loading: false,
        payment: {},
        error: null,
      };

    default:
      return state;
  }
};

// GET RAZOR KEY REDUCER
export const razorKeyReducer = (state = {}, action) => {
  switch (action.type) {
    case RAZOR_KEY_REQUEST:
      return {
        loading: true,
        key: {},
      };
    case RAZOR_KEY_SUCCESS:
      return {
        loading: false,
        key: action.payload,
      };
    case RAZOR_KEY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_RAZOR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_RAZOR_STATE:
      return {
        loading: false,
        key: {},
        error: null,
      };

    default:
      return state;
  }
};

// VERIFY RAZOR PAYMENT REDUCER
export const verifyRazorPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case RAZOR_PAYMENT_VERIFY_REQUEST:
      return {
        loading: true,
        payment: {},
      };
    case RAZOR_PAYMENT_VERIFY_SUCCESS:
      return {
        loading: false,
        payment: action.payload,
      };
    case RAZOR_PAYMENT_VERIFY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_RAZOR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_RAZOR_STATE:
      return {
        loading: false,
        payment: {},
        error: null,
      };

    default:
      return state;
  }
};
