import React, { Fragment, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import "../../styles/officerWorkinghrs.scss";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/reusable/CustomButton";
import {
  clearErrors,
  clearUpdateState,
  updateUserProfile,
  userProfile,
} from "../../redux/actions/userAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import Loader from "../../components/reusable/Loader";
import { GrClose } from "react-icons/gr";
import { FaPlus } from "react-icons/fa";

const CreateWorkingHrs = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [auth] = useAuth();
  const profileData = auth?.user;
  const [, , showNotification] = useNotification();
  const { loading } = useSelector((state) => state.profile); // GET PROFILE STATE FROM REDUCER STORE
  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile); // GET PROFILE STATE FROM REDUCER STORE

  const [time, setTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [weekDay, setWeekDay] = useState("");

  // PROFILE UPDATE HANDLER
  const [timeData, setTimeData] = useState(profileData?.timeTable);
  const [timeError, setTimeError] = useState("");
  const [timeList, setTimeList] = useState([]);

  const handleAddTimeTable = () => {
    if (!weekDay || !time || !endTime) {
      showNotification(
        "error",
        "Please Select Time and WeekDay for add in list"
      );
      return;
    }

    const existingTimeSlot = timeData.find(
      (slot) =>
        slot?.weekDay === weekDay &&
        slot.classTime.some(
          (existingSlot) =>
            existingSlot.time === time ||
            existingSlot.endTime === endTime ||
            (new Date(`2000-01-01 ${time}`) >=
              new Date(`2000-01-01 ${existingSlot.time}`) &&
              new Date(`2000-01-01 ${time}`) <=
                new Date(`2000-01-01 ${existingSlot.endTime}`)) ||
            (new Date(`2000-01-01 ${endTime}`) >=
              new Date(`2000-01-01 ${existingSlot.time}`) &&
              new Date(`2000-01-01 ${endTime}`) <=
                new Date(`2000-01-01 ${existingSlot.endTime}`))
        )
    );
    if (existingTimeSlot) {
      setTimeError(
        "A class already exists time slot. Please choose a time after this slot time."
      );
      setTimeout(() => {
        setTimeError("");
      }, 3000);
    } else if (new Date(`2000-01-01 ${time}`) > new Date(`2000-01-01 ${endTime}`)) {
      setTimeError("Start time cannot be greater than end time.");
      setTimeout(() => {
        setTimeError("");
      }, 3000);
    } else if (time === endTime) {
      setTimeError("Start time and end time cannot be the same.");
      setTimeout(() => {
        setTimeError("");
      }, 3000);
    } else {
      const existingDayIndex = timeData?.findIndex(
        (item) => item.weekDay === weekDay
      );
      if (existingDayIndex !== -1) {
        const updatedTimeList = [...timeData];
        updatedTimeList[existingDayIndex].classTime.push({ time, endTime });
        setTimeList(updatedTimeList);
      } else {
        setTimeList([
          ...timeData,
          // ...timeList,
          {
            weekDay: weekDay,
            classTime: [
              {
                time: time,
                endTime: endTime,
              },
            ],
          },
        ]);
        setTimeData([
          ...timeData,
          {
            weekDay: weekDay,
            classTime: [
              {
                time: time,
                endTime: endTime,
              },
            ],
          },
        ]);

      }
      setTime("");
      setEndTime("");
    }
  };

  // DELETE SINGLE TIME TABLE ROW FROM ARRAY
  const deleteTimeRow = (id) => {
    var newData = timeList;
    newData.splice(id, 1);
    setTimeList([...newData]);
    // setTimeData([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };

  const handleProfileUpdate = () => {
    if (!timeList.length) {
      showNotification(
        "error",
        `Please add weekday, start time, end time in list & try again`
      );
      return;
    }
    
    dispatch(
      updateUserProfile({
        first_name: profileData?.first_name,
        middle_name: "",
        last_name: "",
        email: profileData?.email,
        phone: profileData?.phone,
        dob: profileData?.dob,
        address: profileData?.address,
        gender: profileData?.gender,
        profile: profileData?.profile,
        fatherName: "",
        motherName: "",
        parentEmail: "",
        teacherBio: profileData?.teacherBio,
        subSpec: "",
        assignSubjects: "",
        aff_occupation: "",
        aff_state: "",
        aff_bank_name: profileData?.aff_bank_name,
        aff_bank_ifsc: profileData?.aff_bank_ifsc,
        aff_bank_acc_no: profileData?.aff_bank_acc_no,
        aff_bank_branch: profileData?.aff_bank_branch,
        aff_bank_reg_mob: profileData?.aff_bank_reg_mob,
        aff_upi_id: profileData?.aff_upi_id,
        parentWhatsAppNo: "",
        timeTable: timeList,
        facebook: profileData?.facebook,
        instagram: profileData?.instagram,
        twitter: profileData?.twitter,
        whatsapp: profileData?.whatsapp,
        language: auth?.user?.language,
      })
    );
  };

  // HANLDE SUCCESS MESSAGE FOR UPDATE OFFICER DETAILS
  useEffect(() => {
    if (userInfo?.success) {
      showNotification("success", userInfo?.message);
      dispatch(userProfile());
      dispatch(clearUpdateState());
      navigate(-1);
    }
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    showNotification,
    updateError,
    navigate,
    userInfo?.message,
    userInfo?.success,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="officer_createTimeWork_main">
          <div className="header">
            <div className="title">
              Create Working Time Table
              <button title="Go Back" onClick={() => navigate(-1)}>
                <RxCross2 />
              </button>
            </div>
          </div>
          <div className="container">
            <div>
              <div className="create_course_content">
                <div className="add_timeTable">
                  <div>
                    <div className="timetable_tile">
                      <span>
                        <label>Select Weekday :</label>
                        <select
                          name="weekDay"
                          value={weekDay}
                          onChange={(e) => setWeekDay(e.target.value)}
                        >
                          <option value="">Select Week Day</option>
                          <option value="Monday">Monday</option>
                          <option value="Tuesday">Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thrusday">Thrusday</option>
                          <option value="Friday">Friday</option>
                          <option value="Saturday">Saturday</option>
                          <option value="Sunday">Sunday</option>
                        </select>
                      </span>
                      <div className="time_select">
                        <span>
                          <label>Start Time :</label>
                          <input
                            type="time"
                            name="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </span>
                        <span>
                          <label>to</label>
                        </span>
                        <span>
                          <label>End Time :</label>
                          <input
                            type="time"
                            name="endTime"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                          />
                        </span>
                      </div>
                      {/* {timeList?.length > 0 && ( */}
                      <button
                        // title="Add New Time Table"
                        className="add_time"
                        onClick={() => handleAddTimeTable()}
                      >
                        <FaPlus />
                      </button>
                      {/* )} */}
                    </div>
                    {timeError && <p className="error_msg">{timeError}</p>}
                    {timeList?.length > 0 && (
                      <div className="time_list">
                        <h5>Time List:</h5>
                        {timeList?.map((item, idx) => (
                          <p key={idx}>
                            <div>
                              <span>{item?.weekDay}</span>
                              {item?.classTime?.map((ele, id) => (
                                <span key={id}>
                                  {ele.time} - {ele.endTime}
                                </span>
                              ))}
                            </div>
                            <span onClick={() => deleteTimeRow(idx)}>
                              <GrClose />
                            </span>
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="submit_btn">
                  <CustomButton
                    onClick={() => handleProfileUpdate()}
                    title={updateLoading ? <ButtonLoader /> : "Create"}
                    width="99px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CreateWorkingHrs;
