import axios from "axios";
import {
  CLEAR_OFFICER_ERROR,
  CLEAR_OFFICER_STATE,
  CLEAR_POST_TIMLINE_STATE,
  CLEAR_UPDATE_TIMLINE_STATE,
  DELETE_TIMLINE_FAIL,
  DELETE_TIMLINE_REQUEST,
  DELETE_TIMLINE_STATE,
  DELETE_TIMLINE_SUCCESS,
  GET_OFFICER_COURSES_FAIL,
  GET_OFFICER_COURSES_REQUEST,
  GET_OFFICER_COURSES_SUCCESS,
  GET_OFFICER_REVENUE_FAIL,
  GET_OFFICER_REVENUE_REQUEST,
  GET_OFFICER_REVENUE_SUCCESS,
  GET_POST_TIMLINE_BY_OFFICER_FAIL,
  GET_POST_TIMLINE_BY_OFFICER_REQUEST,
  GET_POST_TIMLINE_BY_OFFICER_SUCCESS,
  GET_POST_TIMLINE_LIST_FAIL,
  GET_POST_TIMLINE_LIST_REQUEST,
  GET_POST_TIMLINE_LIST_SUCCESS,
  LIKE_POST_TIMLINE_CLEAR_ERROR,
  LIKE_POST_TIMLINE_CLEAR_STATE,
  LIKE_POST_TIMLINE_FAIL,
  LIKE_POST_TIMLINE_REQUEST,
  LIKE_POST_TIMLINE_SUCCESS,
  POST_TIMLINE_FAIL,
  POST_TIMLINE_REQUEST,
  POST_TIMLINE_SUCCESS,
  UPDATE_TIMLINE_FAIL,
  UPDATE_TIMLINE_REQUEST,
  UPDATE_TIMLINE_SUCCESS,
} from "../constants/officerConstant";

export const getOfficerCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_OFFICER_COURSES_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/ssb/my-courses`,
      config
    );

    dispatch({
      type: GET_OFFICER_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_OFFICER_COURSES_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const postTimelineAction = (label, thumbnail) => async (dispatch) => {
  try {
    dispatch({
      type: POST_TIMLINE_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/create-post`,
      {
        label,
        thumbnail,
      },
      config
    );
    dispatch({
      type: POST_TIMLINE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_TIMLINE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateTimelineAction = (id,label, thumbnail) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_TIMLINE_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/update-post/${id}`,
      {
        label,
        thumbnail,
      },
      config
    );
    dispatch({
      type: UPDATE_TIMLINE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_TIMLINE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const deleteTimelineAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TIMLINE_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API}/delete-post/${id}`,
      config
    );
    dispatch({
      type: DELETE_TIMLINE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_TIMLINE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const likeTimelinePostAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LIKE_POST_TIMLINE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/like-post/${id}`,
      {},
      config
    );

    dispatch({
      type: LIKE_POST_TIMLINE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIKE_POST_TIMLINE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllTimelinePostAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_POST_TIMLINE_LIST_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/all-posts`,
      config
    );

    dispatch({
      type: GET_POST_TIMLINE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_POST_TIMLINE_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getTimelinePostByOfficerAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POST_TIMLINE_BY_OFFICER_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/created-post/${id}`,
      config
    );

    dispatch({
      type: GET_POST_TIMLINE_BY_OFFICER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_POST_TIMLINE_BY_OFFICER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET OFFICER REVENUE ACTION

export const getOfficerRevenueAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_OFFICER_REVENUE_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/my-revenue`,
      config
    );

    dispatch({
      type: GET_OFFICER_REVENUE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_OFFICER_REVENUE_FAIL,
      payload: error.response.data.message,
    });
  }
};



export const clearOfficerErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_OFFICER_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearOfficerState = () => async (dispatch) => {
  dispatch({ type: CLEAR_OFFICER_STATE });
};

export const clearPostTimelineState = () => async (dispatch) => {
  dispatch({ type: CLEAR_POST_TIMLINE_STATE });
};
export const clearUpdateTimelineState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_TIMLINE_STATE });
};

// DELETE TIMELINE STATE CLEAR
export const clearDeletePostTimelineState = () => async (dispatch) => {
  dispatch({ type: DELETE_TIMLINE_STATE });
};

// POST LIKE ERROR AND STATE CLEAR
export const clearLikePostErrors = () => async (dispatch) => {
  dispatch({ type: LIKE_POST_TIMLINE_CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearLikePostState = () => async (dispatch) => {
  dispatch({ type: LIKE_POST_TIMLINE_CLEAR_STATE });
};
