import React from "react";

const CustomProgressBar = ({ progress }) => {
  const progressBarStyle = {
    width: `${progress}%`,
    height: "10px",
    backgroundColor: "#081336",
    borderRadius: "4px",
    transition: "width 0.3s ease-in-out",
  };
  const progressLabel = {
    color: "#081336",
    margin: "10px 0px",
    fontSize:"16px",
    fontWeight:"600"
  };
  return (
    <>
      <p style={progressLabel}>Data Uploading .. {progress} %</p>
      <div
        style={{
          border: "1px solid #081336",
          width: "100%",
          borderRadius: "4px",
        }}
      >
        <div style={progressBarStyle}></div>
      </div>
    </>
  );
};

export default CustomProgressBar;
