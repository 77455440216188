export const GET_ALL_LIVE_LIST_REQUEST = "GET_ALL_LIVE_LIST_REQUEST"; 
export const GET_ALL_LIVE_LIST_SUCCESS = "GET_ALL_LIVE_LIST_SUCCESS"; 
export const GET_ALL_LIVE_LIST_FAIL = "GET_ALL_LIVE_LIST_FAIL"; 


export const CREATE_LIVE_CLASS_REQUEST = "CREATE_LIVE_CLASS_REQUEST";
export const CREATE_LIVE_CLASS_SUCCESS = "CREATE_LIVE_CLASS_SUCCESS";
export const CREATE_LIVE_CLASS_FAIL = "CREATE_LIVE_CLASS_FAIL";


export const START_LIVE_CLASS_REQUEST = "START_LIVE_CLASS_REQUEST";
export const START_LIVE_CLASS_SUCCESS = "START_LIVE_CLASS_SUCCESS";
export const START_LIVE_CLASS_FAIL = "START_LIVE_CLASS_FAIL";
export const CLEAR_START_CLASS_STATE = "CLEAR_START_CLASS_STATE";
export const CLEAR_START_CLASS_ERROR = "CLEAR_START_CLASS_ERROR";


export const CLEAR_LIVE_CLASS_ERROR = "CLEAR_LIVE_CLASS_ERROR";
export const CLEAR_LIVE_CLASS_STATE = "CLEAR_LIVE_CLASS_STATE";

