import {
  ALL_COURSES_FAIL,
  ALL_COURSES_REQUEST,
  ALL_COURSES_SUCCESS,
  CLEAR_ERRORS,
  COURSE_ADD_REVIEW_FAIL,
  COURSE_ADD_REVIEW_REQUEST,
  COURSE_ADD_REVIEW_SUCCESS,
  COURSE_DELETE_REVIEW_FAIL,
  COURSE_DELETE_REVIEW_REQUEST,
  COURSE_DELETE_REVIEW_SUCCESS,
  COURSE_DETAIL_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS,
  COURSE_LONG_DETAIL_FAIL,
  COURSE_LONG_DETAIL_REQUEST,
  COURSE_LONG_DETAIL_SUCCESS,
  COURSE_META_DETAIL_FAIL,
  COURSE_META_DETAIL_REQUEST,
  COURSE_META_DETAIL_SUCCESS,
  MY_COURSES_FAIL,
  MY_COURSES_REQUEST,
  MY_COURSES_SUCCESS,
  RATING_CLEAR_DELETE_STATE,
  RATING_CLEAR_ERRORS,
  RATING_CLEAR_STATE,
} from "../constants/courseConstant";

// GET ALL COURSES REDUCER
export const getAllCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_COURSES_REQUEST:
      return {
        loading: true,
        courses: {},
      };
    case ALL_COURSES_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case ALL_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        courses: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// /GET MY COURSES
export const getMyCoursesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_COURSES_REQUEST:
      return {
        loading: true,
        courses: {},
      };
    case MY_COURSES_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case MY_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        courses: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET COURSE DETAILS REDUCER
export const getCourseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DETAIL_REQUEST:
      return {
        loading: true,
        courseDetails: {},
      };
    case COURSE_DETAIL_SUCCESS:
      return {
        loading: false,
        courseDetails: action.payload,
      };
    case COURSE_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET COURSE LONG DETAILS
export const getCourseLongDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_LONG_DETAIL_REQUEST:
      return {
        loading: true,
        courseLongDetails: {},
      };
    case COURSE_LONG_DETAIL_SUCCESS:
      return {
        loading: false,
        courseLongDetails: action.payload,
      };
    case COURSE_LONG_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        courseLongDetails: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET COURSE LONG DETAILS
export const getCourseMetaDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_META_DETAIL_REQUEST:
      return {
        loading: true,
        courseMeta: {},
      };
    case COURSE_META_DETAIL_SUCCESS:
      return {
        loading: false,
        courseMeta: action.payload,
      };
    case COURSE_META_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        courseMeta: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// ADD SSB COURSE RATING
export const addCourseRatingReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ADD_REVIEW_REQUEST:
      return {
        loading: true,
        courseReview: {},
      };
    case COURSE_ADD_REVIEW_SUCCESS:
      return {
        loading: false,
        courseReview: action.payload,
      };
    case COURSE_ADD_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RATING_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RATING_CLEAR_STATE:
      return {
        courseReview: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};


// DELETE SSB COURSE RATING
export const deleteCourseRatingReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DELETE_REVIEW_REQUEST:
      return {
        loading: true,
        courseReview: {},
      };
    case COURSE_DELETE_REVIEW_SUCCESS:
      return {
        loading: false,
        courseReview: action.payload,
      };
    case COURSE_DELETE_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RATING_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RATING_CLEAR_DELETE_STATE:
      return {
        courseReview: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
