import React, { useState } from "react";
import CustomHelmet from "../components/reusable/Helmet";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";

const RefundPolicy = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Privacy Policy Alpha Regiment"
        description="Honestly, we want to serve each of our students and customers to our best capabilities. So, to give the best services we do collect some of your non-sensitive data, please read our Privacy Policy to know more."
        author="Team Alpha Regiment"
        title="Our Privacy Policy, please read || Alpha Regiment"
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>refund policy</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${showSideNav ? "showProfile_sidebar" : ""
              }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">

              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>REFUND & cancellation Policy</h1>
                <p>Last updated: April 29, 2023</p>
              </div>
              <div className="privacy_text">
                <p>
                  Please note that all purchases of our products and services are
                  non-refundable. This includes online batches and services. If you
                  accidentally purchase the wrong batch, you may request to change
                  it to another batch of equal value within 10 days of the purchase.
                  We strongly recommend that you carefully review the complete
                  system before making a payment for books. In the event that a
                  product is damaged or lost during shipping, we will provide a
                  replacement. However, please note that we cannot offer refunds for
                  purchases where the wrong address is provided or where the books
                  are not accepted by the student.
                </p>
              </div>
            </div>
          </div>

        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default RefundPolicy;
