import React, { Fragment, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import "../../styles/editWorkinghrs.scss";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/reusable/CustomButton";
import {
  clearErrors,
  clearUpdateState,
  updateUserProfile,
  userProfile,
} from "../../redux/actions/userAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import Loader from "../../components/reusable/Loader";
import { GrClose } from "react-icons/gr";
import { FaPlus } from "react-icons/fa";

const EditWorkingHrs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editData = location.state;
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const profileData = auth?.user;
  const [, , showNotification] = useNotification();
  const { loading } = useSelector((state) => state.profile); // GET PROFILE STATE FROM REDUCER STORE
  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile); // GET PROFILE STATE FROM REDUCER STORE

  const [time, setTime] = useState("");
  const [endTime, setEndTime] = useState("");

  // PROFILE UPDATE HANDLER
  const [timeError, setTimeError] = useState("");
  //   INITAL VALUE OF TIME TABLE FOR ANY WEEKDAY
  const [timeList, setTimeList] = useState(editData);
  const [timeTable, setTimeTable] = useState([]);

  useEffect(() => {
    setTimeTable(profileData?.timeTable);
  }, [profileData?.timeTable]);

  const handleAddTimeTable = () => {
    if (!time) {
      showNotification(
        "error",
        "Please Select Time and WeekDay for add in list"
      );
    } else {
      const existingTimeSlot = timeList?.classTime?.some(
        (existingSlot) =>
          existingSlot.time === time ||
          existingSlot.endTime === endTime ||
          (new Date(`2000-01-01 ${time}`) >=
            new Date(`2000-01-01 ${existingSlot.time}`) &&
            new Date(`2000-01-01 ${time}`) <=
              new Date(`2000-01-01 ${existingSlot.endTime}`)) ||
          (new Date(`2000-01-01 ${endTime}`) >=
            new Date(`2000-01-01 ${existingSlot.time}`) &&
            new Date(`2000-01-01 ${endTime}`) <=
              new Date(`2000-01-01 ${existingSlot.endTime}`))
      );
      if (existingTimeSlot) {
        setTimeError(
          "A class already exists time slot. Please choose a time after this slot time."
        );
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else if (
        new Date(`2000-01-01 ${time}`) > new Date(`2000-01-01 ${endTime}`)
      ) {
        setTimeError("Start time cannot be greater than end time.");
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else if (time === endTime) {
        setTimeError("Start time and end time cannot be the same.");
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else {
        const filterData = timeTable?.filter(
          (item) => item.weekDay !== editData?.weekDay
        );
        setTimeTable(filterData);
        let newData = {
          weekDay: editData.weekDay,
          classTime: [
            ...(timeList.classTime || []),
            {
              time: time,
              endTime: endTime,
            },
          ],
        };
        setTimeList(newData);
        setTimeTable([...filterData, newData]);
        setTime("");
        setEndTime("");
      }
    }
  };


  // DELETE SINGLE TIME TABLE ROW FROM ARRAY
  const deleteTimeRow = (id) => {
    const newData = { ...timeList };
    newData.classTime.splice(id, 1);
    setTimeList(newData);
    const filterData = timeTable?.filter(
      (item) => item.weekDay !== editData?.weekDay
    );
    setTimeTable([...filterData, newData]);
    showNotification("success", `Deleted`);
  };

  const handleProfileUpdate = () => {
    if (!timeList?.classTime?.length) {
      showNotification("error", `Please add start time & end time in list & try again`);
      return;
    }

    dispatch(
      updateUserProfile({
        first_name: profileData?.first_name,
        middle_name: "",
        last_name: "",
        email: profileData?.email,
        phone: profileData?.phone,
        dob: profileData?.dob,
        address: profileData?.address,
        gender: profileData?.gender,
        profile: profileData?.profile,
        fatherName: "",
        motherName: "",
        parentEmail: "",
        teacherBio: profileData?.teacherBio,
        subSpec: "",
        assignSubjects: "",
        aff_occupation: "",
        aff_state: "",
        aff_bank_name: profileData?.aff_bank_name,
        aff_bank_ifsc: profileData?.aff_bank_ifsc,
        aff_bank_acc_no: profileData?.aff_bank_acc_no,
        aff_bank_branch: profileData?.aff_bank_branch,
        aff_bank_reg_mob: profileData?.aff_bank_reg_mob,
        aff_upi_id: profileData?.aff_upi_id,
        parentWhatsAppNo: "",
        timeTable: timeTable,
        facebook: profileData?.facebook,
        instagram: profileData?.instagram,
        twitter: profileData?.twitter,
        whatsapp: profileData?.whatsapp,
        language: auth?.user?.language,
      })
    );
  };

  // HANLDE SUCCESS MESSAGE FOR UPDATE OFFICER DETAILS
  useEffect(() => {
    if (userInfo?.success) {
      showNotification("success", userInfo?.message);
      dispatch(userProfile());
      dispatch(clearUpdateState());
      navigate(-1);
    }
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    showNotification,
    updateError,
    navigate,
    userInfo?.message,
    userInfo?.success,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="officer_editTimeWork_main">
          <div className="header">
            <div className="title">
              Edit {editData?.weekDay} Working Time
              <button title="Go Back" onClick={() => navigate(-1)}>
                <RxCross2 />
              </button>
            </div>
          </div>
          <div className="container">
            <div>
              <div className="edit_time_content">
                <div className="add_timeTable">
                  <div>
                    <div className="timetable_tile">
                      <div className="time_select">
                        <span>
                          <label>Start Time :</label>
                          <input
                            type="time"
                            name="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </span>
                        <span>
                          <label>to</label>
                        </span>
                        <span>
                          <label>End Time :</label>
                          <input
                            type="time"
                            name="endTime"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                          />
                        </span>
                      </div>
                      <button
                        // title="Add New Time Table"
                        className="add_time"
                        onClick={() => handleAddTimeTable()}
                      >
                        <FaPlus />
                      </button>
                    </div>
                    {timeError && <p className="error_msg">{timeError}</p>}

                    <div className="time_list">
                      <h5>Time List:</h5>
                      <div>
                        <span>{timeList?.weekDay}</span>
                        {timeList?.classTime?.map((ele, id) => (
                          <div key={id}>
                            <span>
                              {ele.time} - {ele.endTime}
                            </span>
                            <span onClick={() => deleteTimeRow(id)}>
                              <GrClose />
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit_btn">
                  <CustomButton
                    onClick={() => handleProfileUpdate()}
                    title={updateLoading ? <ButtonLoader /> : "Update"}
                    width="99px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EditWorkingHrs;
