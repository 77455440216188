import React, { Fragment, useEffect, useState } from "react";
// import "../styles/allCousesscss.js";
import Layout from "../components/reusable/Layout";
import CustomInput from "../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
import { RiMenuUnfoldLine, RiCloseFill } from "react-icons/ri";
// import SingleCourseCard from "../components/course/SingleCourseCard";
// import { courseContent } from "../dummyContent";
import { useLocation, useNavigate } from "react-router-dom";
import CustomHelmet from "../components/reusable/Helmet";
import {
  // GDCourse,
  // clerkCourse,
  // techCourse,
  courseNotFound,
} from "../assets/images";
import { useLocale } from "../context/locale";
import { CourseSection } from "../styles/allCousesscss.js";
import ImgLazyLoad from "../components/reusable/ImgLazyLoad";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllCourses } from "../redux/actions/courseAction";
import Loader from "../components/reusable/Loader";
import CourseCard from "../components/course/CourseCard";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../redux/actions/wishListAction.js";
import { useAuth } from "../context/userContext.js";
import { useNotification } from "../context/notificationContext.js";
import ButtonLoader from "../components/reusable/ButtonLoader.js";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import Slider from "react-slick";

const AllCourses = () => {
  const [t] = useLocale();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const allCourses = courseContent; // Store all course contents in one state
  const [searchString, setSearchString] = useState(""); // Search String for search the courses
  const [selectedCategory, setSelectedCategory] = useState(""); // search by categories
  const [activeCategory, setActiveCategory] = useState(0); // search by categories
  const [courseMode, setCourseMode] = useState("");
  const [wishListId, setWishListId] = useState();
  // const [courseMode, setCourseMode] = useState(""); // search by course mode

  // GET COURSES FROM BACKEND/APIS
  const dispatch = useDispatch();

  const { loading, courses, error } = useSelector(
    (state) => state.getAllCourses
  );

  const allApiCourses = courses?.courses;

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  // handle search change input
  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
  };

  const location = useLocation();

  let goalType = location.state;

  // if category in course page then GOAL Type will be empty string
  if (selectedCategory.length > 0) {
    goalType = "";
  }

  // Filter Courses by Search String or category wise
  let filterCourse = [];
  if (goalType) {
    filterCourse = allApiCourses?.filter((i) => {
      return (
        i.goalType.toLocaleLowerCase().includes(goalType.toLocaleLowerCase()) &&
        i.study_mode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase()) &&
        i.title.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      );
    });
  } else if (selectedCategory) {
    filterCourse = allApiCourses?.filter((i) => {
      return (
        i.study_mode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase()) &&
        i.courseCategory
          .toLocaleLowerCase()
          .includes(selectedCategory.toLocaleLowerCase()) &&
        i.goalType.toLocaleLowerCase().includes(goalType.toLocaleLowerCase()) &&
        i.title.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      );
    });
  } else {
    filterCourse = allApiCourses?.filter((i) => {
      return (
        i.title
          .toLocaleLowerCase()
          .includes(searchString.toLocaleLowerCase()) &&
        i.study_mode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase())
      );
    });
  }

  // Handle Course Details Page
  const handleDetailsPage = (data) => {
    const { title, study_mode, courseCategory, _id } = data;
    const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
    const formattedCategory = courseCategory.toLowerCase().replace(/\s+/g, "-");
    const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
    navigate(seoFriendlyUrl);
  };

  // HANDLE SELECT CATEGORY
  const handleSelectCategory = (category, activeNumber) => {
    setSelectedCategory(category);
    setActiveCategory(activeNumber);
    setShowSideNav(false);
  };

  // HANDLE REDIRECT TO SSB COURSES
  const handleNavigate = (activeNumber) => {
    navigate("/ssb-courses");
    setActiveCategory(activeNumber);
  };

  // get language param from localstorage
  const langParam = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";

  // CALCULATE DEFAULT CATEGORY
  let Airforce = [];
  let Military = [];
  let Police = [];
  let Army = [];
  let CAPF = [];
  let Navy = [];

  Airforce = allApiCourses?.filter((i) => {
    return i.courseCategory.includes("Air Force");
  });

  Military = allApiCourses?.filter((i) => {
    return i.courseCategory.includes("Military Education");
  });

  Navy = allApiCourses?.filter((i) => {
    return i.courseCategory.includes("Navy");
  });

  Army = allApiCourses?.filter((i) => {
    return i.courseCategory.includes("Army");
  });

  CAPF = allApiCourses?.filter((i) => {
    return i.courseCategory.includes("CAPF");
  });

  Police = allApiCourses?.filter((i) => {
    return i.courseCategory.includes("Police");
  });

  // CHECK WISHLIST EXIST OR NOT
  const {
    //  eslint-disable-next-line
    loading: getWishListLoading,
    //  eslint-disable-next-line
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.course?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    } else if (auth?.user?._id && existInWishList) {
      setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    } else if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES

  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
    dispatch,
  ]);

  // SLIDER SETTINGS
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", lineHeight: "16px" }}
        onClick={onClick}
      >
        <BiChevronsLeft />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", lineHeight: "16px" }}
        onClick={onClick}
      >
        <BiChevronsRight />
      </div>
    );
  };
  const SsbCourseSlider = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: filterCourse?.length > 3 ? 4 : filterCourse?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1091,
        settings: {
          slidesToShow: filterCourse?.length > 2 ? 3 : filterCourse?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: filterCourse?.length < 2 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  // WIDTH FOR SSB COURSES AND SETTING FOR THAT
  let BatchSliderWidth = "100%";
  let BatchSliderWidthMobile = "100%";

  if (filterCourse?.length < 4) {
    BatchSliderWidth = `${25 * filterCourse?.length}%`;
  }
  if (filterCourse?.length < 2 && window.innerWidth < 991) {
    BatchSliderWidthMobile = `${50 * filterCourse?.length}%`;
  }
  if (filterCourse?.length < 2 && window.innerWidth < 370) {
    BatchSliderWidthMobile = `${100}%`;
  }
  const currentPageUrl = window?.location?.href;
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="Enroll in the Alpha Regiment courses designed by the experts under the guidance of senior retired officers. We offer guidance for physical, and written exams as well as for medical."
            author="Team Alpha Regiment"
            title="Alpha Regiment Courses, is the best way to get closer to your goal"
            canonical={currentPageUrl}
          />
          <CourseSection className="all_courses_main">
            <div className="container">
              <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "online" ? "active" : ""}
                  onClick={() => setCourseMode("online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => setCourseMode("offline")}
                >
                  offline
                </button>
              </div>
            </div>
            <div className="all_course_content">
              <button
                className="open_course_nav"
                onClick={() => setShowSideNav(!showSideNav)}
                title={showSideNav ? "Close Button Bar" : "Open Button Bar"}
              >
                {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
              </button>
              <div className={`course_nav ${showSideNav ? "showSideNav" : ""}`}>
                <button
                  onClick={() => handleNavigate(1)}
                  className={activeCategory === 1 ? "active" : ""}
                >
                  SSB Interviews
                </button>
                <button
                  onClick={() => handleSelectCategory("Air Force", 2)}
                  className={activeCategory === 2 ? "active" : ""}
                >
                  {t("airforce_text")}{" "}
                  {Airforce?.length < 1 && <p>Coming Soon</p>}
                </button>
                <button
                  onClick={() => handleSelectCategory("Navy", 3)}
                  className={activeCategory === 3 ? "active" : ""}
                >
                  {t("navy_text")} {Navy?.length < 1 && <p>Coming Soon</p>}
                </button>
                <button
                  onClick={() => handleSelectCategory("Army", 4)}
                  className={activeCategory === 4 ? "active" : ""}
                >
                  {t("army_text")} {Army?.length < 1 && <p>Coming Soon</p>}
                </button>
                <button
                  onClick={() => handleSelectCategory("CAPF", 5)}
                  className={activeCategory === 5 ? "active" : ""}
                >
                  {t("capf_text")} {CAPF?.length < 1 && <p>Coming Soon</p>}
                </button>
                <button
                  onClick={() => handleSelectCategory("Police", 6)}
                  className={activeCategory === 6 ? "active" : ""}
                >
                  {t("police_text")} {Police?.length < 1 && <p>Coming Soon</p>}
                </button>
                <button
                  onClick={() => handleSelectCategory("Military Education", 7)}
                  className={activeCategory === 7 ? "active" : ""}
                >
                  {t("military_school_text")}{" "}
                  {Military?.length < 1 && <p>Coming Soon</p>}
                </button>
              </div>

              <div className="all_course_cards">
                {filterCourse?.length > 0 ? (
                  <div
                    className="slider-container"
                    style={{
                      width:
                        window.innerWidth >= 991
                          ? BatchSliderWidth
                          : BatchSliderWidthMobile,
                    }}
                  >
                    <Slider {...SsbCourseSlider}>
                      {filterCourse?.map((item, idx) => {
                        const courseTitile =
                          langParam === "en" ? item?.title : item?.titleHindi;
                        const courseDescription =
                          langParam === "en"
                            ? item?.description
                            : item?.descriptionHindi;
                        return (
                          <CourseCard
                            key={idx}
                            isActiveClass={true}
                            className="course_card"
                            title={courseTitile}
                            description={`${courseDescription?.substr(
                              0,
                              40
                            )}...`}
                            icon={item?.thumbnail?.url}
                            startingFrom={item?.starting_date}
                            price={item?.original_price}
                            discountedPrice={item?.discounted_price}
                            addWishList={() =>
                              handleAddtoWishlist({
                                itemId: item?._id,
                                isSsb: false,
                              })
                            }
                            deleteWishListLoading={
                              wishListId === item?._id &&
                              deleteLoading && <ButtonLoader />
                            }
                            existInWishList={allWishList?.wishlist?.course?.some(
                              (obj) => obj._id === item._id
                            )}
                            addWishListLoading={
                              wishListId === item?._id &&
                              addWishListLoading && <ButtonLoader />
                            }
                            onCourseView={() => handleDetailsPage(item)}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                ) : (
                  <div className="course_card txt_color courseNotFound_main">
                    <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                    <ImgLazyLoad src={courseNotFound} alt="courseNotFound" />
                    <p>{t("courseNotFoundDescription")}</p>
                  </div>
                )}
              </div>
            </div>
          </CourseSection>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllCourses;
