import React, { useEffect, useState } from "react";
import "../../styles/home.scss";
import { BsChevronRight } from "react-icons/bs";
import { ImTarget } from "react-icons/im";
import Carousel from "react-elastic-carousel";
import Layout from "../../components/reusable/Layout";
import {
  CommitmentSuccess,
  ComprehensiveFeedback,
  DedicatedMentors,
  GTOMastery,
  SsbCoaching,
  StartKnowing,
  // Tarun,
  VirtualOdyssey,
  alpha_regiment_txt,
  aninews,
  courseNotFound,
  dailyhunt,
  hasleFreeCommute,
  homeBanGif,
  immersiveEnvironment,
  latestly,
  // hero_banner,
  logo_mobile,
  lokmatLogo,
  medical,
  medical_exam,
  mockSsb,
  spokenEnglish,
  ssbBot,
  thePrint,
  zee5,
} from "../../assets/images";
import TestimonialCard from "./TestimonialCard";
import SoldierHeart from "./SoldierHeart";
import { AiOutlineClose } from "react-icons/ai";
// SLIDER
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import CustomHelmet from "../reusable/Helmet";
import { useLocale } from "../../context/locale";
import { testimonialArray } from "../../dummyContent";
import { useDispatch, useSelector } from "react-redux";
// import LatestNews from "./LatestNews";
import { useCookies } from "react-cookie";
import CourseCard from "../course/CourseCard";
import ImgLazyLoad from "../reusable/ImgLazyLoad";
import { getAllSsbCourses } from "../../redux/actions/ssbCourseAction";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import Slider from "react-slick";
import {
  clearTestimonialsErrors,
  getAllTestimonials,
} from "../../redux/actions/testimonialAction";
// import alphaImg from "../../images/alpha.png";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { useNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/userContext";
import PromotionPopup from "../../pages/ssb/PromotionPopup";
import CustomButton from "../reusable/CustomButton";
import { IoVideocam } from "react-icons/io5";

const Home = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const couponCode = urlParams.get("couponCode");
  const [, , showNotification] = useNotification();
  // const [cookies, setCookie] = useCookies([]);
  const [setCookie] = useCookies([]);

  const [auth] = useAuth();
  // const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    // Set cookie with 10-day expiration
    if (couponCode) {
      setCookie("alphaCouponCodeCookie", couponCode, {
        path: "/",
        expires: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000),
      });
    }
  }, [couponCode, setCookie]);

  const navigate = useNavigate();
  const [t] = useLocale();

  // show video state
  const [showVideo, setShowVideo] = useState(0);

  // Choose Category and redirect to cources page with Goal type filter
  const goalTypeRedirect = (goalType) => {
    if (goalType) {
      navigate("/ssb-courses", { state: goalType });
    }
  };

  const dispatch = useDispatch();

  // testimonial slider breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
  ];

  // GET ALL TESTIMONIALS LIST
  const allTestimonial = useSelector((state) => state.getAllTestimonial);
  const {
    loading: allTestimonialLoading,
    error: testimonialError,
    testimonialList: allTestimonialList,
  } = allTestimonial;

  const testimonialList = allTestimonialList?.testimonials;

  useEffect(() => {
    dispatch(getAllTestimonials());
  }, [dispatch]);

  useEffect(() => {
    if (testimonialError) {
      dispatch(clearTestimonialsErrors());
    }
  }, [dispatch, testimonialError]);

  // get language param from localstorage
  const langParam = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";

  // GET ALL SSB COURSES
  const allSsbCourses = useSelector((state) => state.getAllSsbCourses);
  const { loading: sbbCoursesLoading, courses: data } = allSsbCourses;
  const ssbCourses = data?.courses?.filter(
    (itm) =>
      (itm?.isActive && !itm?.isPause) ||
      (itm?.isActive && itm?.isPause && itm?.pauseForDate !== null)
  );

  // console.log(ssbCourses);

  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  // SLIDER SETTINGS
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", lineHeight: "16px" }}
        onClick={onClick}
      >
        <BiChevronsLeft />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", lineHeight: "16px" }}
        onClick={onClick}
      >
        <BiChevronsRight />
      </div>
    );
  };
  // SSB COURSE DETAILS HANDLER
  const handleSsbDetailsPage = (data) => {
    const { slug } = data;
    const seoFriendlyUrl = `/ssb-course-details/${slug}`;
    navigate(seoFriendlyUrl);
  };

  const SsbCourseSlider = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: ssbCourses?.length > 3 ? 4 : ssbCourses?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1091,
        settings: {
          slidesToShow: ssbCourses?.length > 2 ? 3 : ssbCourses?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: ssbCourses?.length < 2 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  // WIDTH FOR SSB COURSES AND SETTING FOR THAT
  let BatchSliderWidth = "100%";
  let BatchSliderWidthMobile = "100%";

  if (ssbCourses?.length < 4) {
    BatchSliderWidth = `${25 * ssbCourses?.length}%`;
  }
  if (ssbCourses?.length < 2 && window.innerWidth < 991) {
    BatchSliderWidthMobile = `${50 * ssbCourses?.length}%`;
  }
  if (ssbCourses?.length < 2 && window.innerWidth < 370) {
    BatchSliderWidthMobile = `${100}%`;
  }

  const [loadingCheck, setloadingCheck] = useState();

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    setloadingCheck(courseId);
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    // eslint-disable-next-line
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    showNotification,
    allWishListData?.success,
    allWishListData?.message,
  ]);

  // HANDLE ADD WISHLIST STATES

  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);

  // CHECK WISHLIST EXIST OR NOT
  const {
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const [promotion, setPromotion] = useState(false);

  const handleBotPage = () => {
    const token = auth?.token;
    window.open(`${process.env.REACT_APP_SSBGPT_LINK}/?token=${token}`, "_blank");
  };

  return (
    <Layout>
      <CustomHelmet
        keywords={t("homepage_Keywords")}
        description={t("homepage_Description")}
        author={t("homepage_Author")}
        title={t("homepage_Title")}
      />
      <div
        className="txt_color main_home_section"
        style={{ backgroundColor: "#0b1134" }}
      >
        {/* HERO SECTION START */}
        <div className="container">
          <div className="hero_section_main">
            <div className="hero_txt_sec">
              <h1 className="welcome">{t("welcome_content")}</h1>
              <img
                className="logo_img"
                src={logo_mobile}
                alt="Alpha Regiment logo_hero"
              />
              <img
                className="alpha_txt_img"
                src={alpha_regiment_txt}
                alt="Alpha Regiment alpha_regiment_txt"
              />
              <div className="earn_txt">
                <p>{t("earn_uniform")}</p>
                <p>{t("earn_respect")}</p>
              </div>
              <div className="target_btns">
                <button
                  className="hero_btn"
                  onClick={() => navigate("/ssb-courses")}
                >
                  {t("lockTargetBtnTxt")} <ImTarget className="icon" />
                </button>
                <button
                  className="add_btn"
                  onClick={() => navigate("/ssb-in-metaverse")}
                >
                  Metaverse Campus
                  {/* ("lockTargetBtnTxt")} <ImTarget className="icon" /> */}
                </button>
              </div>
            </div>

            <div className="hero_bann_sec">
              {/* <img
                src={homeBanGif}
                alt="Alpha Regiment hero_banner main banner"
              /> */}
              <img
                src={homeBanGif}
                alt="Alpha Regiment hero_banner main banner"
              />
              {/* <video autoPlay loop muted ref={home_vid_ref}>
                <source src={heroVideoMainMedium} type="video/mp4" />
                <source src={heroVideoMainMedium} type="video/ogg" />
                Your browser does not support the video tag.
              </video> */}
            </div>
          </div>
        </div>
        {/* HERO SECTION ENDS */}

        {/* COURSES START */}

        <div className="courses_slider">
          <div className="container">
            <div className="title">
              <h1>Courses</h1>
            </div>

            {sbbCoursesLoading || getWishListLoading ? (
              <div className="txt_color courseNotFound_main">
                {/* <h2 className="txt_center">{t("courseNotFoundTitle")}</h2> */}
                <h2 className="txt_center">Course Loading..</h2>

                <ImgLazyLoad
                  className={"img_load"}
                  src={courseNotFound}
                  alt="courseNotFound"
                />
                <p>Please wait</p>
              </div>
            ) : ssbCourses?.length > 0 ? (
              <div
                className="slider-container"
                style={{
                  width:
                    window.innerWidth >= 991
                      ? BatchSliderWidth
                      : BatchSliderWidthMobile,
                }}
              >
                <Slider {...SsbCourseSlider}>
                  {ssbCourses?.map((item, idx) => {
                    const roundedPrice = Math.ceil(item?.originalPrice);
                    const roundedDiscountedPrice = Math.ceil(
                      item?.discountedPrice
                    );
                    return (
                      <CourseCard
                        className="course_card"
                        key={idx}
                        title={item?.title}
                        description={item?.shortDescription}
                        icon={item?.thumbnail?.url}
                        startingFrom={item?.starting_date}
                        price={roundedPrice}
                        discountedPrice={roundedDiscountedPrice}
                        numOfReviews={item?.numOfReviews}
                        rating={item?.rating}
                        addWishList={() =>
                          handleAddtoWishlist({
                            itemId: item?._id,
                            isSsb: true,
                          })
                        }
                        existInWishList={allWishList?.wishlist?.ssbCourse?.some(
                          (obj) => obj._id === item._id
                        )}
                        loadingCheck={loadingCheck}
                        addWishListLoading={addWishListLoading}
                        onHomePageClick={() => handleSsbDetailsPage(item)}
                      />
                    );
                  })}
                </Slider>
              </div>
            ) : (
              <div className="txt_color courseNotFound_main">
                <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                <ImgLazyLoad
                  className={"img_load"}
                  src={courseNotFound}
                  alt="courseNotFound"
                />
                <p>{t("courseNotFoundDescription")}</p>
              </div>
            )}
          </div>
        </div>
        {/* COURSES ENDS  */}

        {/* ALPHA BOT  */}

        <div className="alpha_bot">
          <div className="container">
            <div className="title">
              <h1>Introducing SSB GPT</h1>
            </div>
            <div className="go_to_bot">
              <div className="left_sec">
                <img src={ssbBot} alt="alphabot" />
              </div>
              <div className="right_sec">
                <div>
                  <p>
                    I’m <strong>SSB GPT</strong> ,your dedicated chatbot here to
                    help you with all your queries and doubts related to the
                    SSB.
                    <br />
                    <br />
                    Whether you’re just starting your preparation or looking to
                    fine-tune your skills, I’m here to guide you every step of
                    the way.
                  </p>
                  <CustomButton
                    title="Start a chat"
                    className="gpt_btn"
                    onClick={() => handleBotPage()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ALPHA BOT  */}

        {/* WHY ALPHA REGIMENT START */}
        <section className="why_alpha_reg">
          <div className="container">
            <div className="title">
              <h1>
                {t("why_alpha_regiment")}
                {/* Why Alpha Regiment? */}
              </h1>
              <div></div>
            </div>

            <div className="services_cards">
              {/* DEDICATED MENTORS START */}
              <div className="metaverse_camp card_sec single_col_sec content_right">
                <div className="img_sec">
                  {showVideo === 1 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Virtual Campus"
                              src="https://www.youtube.com/embed/QRxH3tavpDs"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="details_sec border_right">
                  <h2 className="home_heading">
                    {/* {t("virtual_campus_heading")} */}
                    {t("dedicatedMentors")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("virtual_campus")} */}
                    {t("dedicatedMentorsTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                      onClick={() => setShowVideo(1)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={DedicatedMentors}
                        alt="Alpha Regiment virtudal campus"
                      />
                      {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* DEDICATED MENTORS ENDS */}

              {/* 3D SIMULATIONS START */}
              <div className="metaverse_camp card_sec single_col_sec">
                <div className="details_sec border_left">
                  <h2 className="home_heading">
                    {/* {t("personal_mantor_heading")} */}
                    {t("3DSimulator")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("personal_mantor")} */}
                    {t("3DSimulatorTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 2 ? "active" : ""}`}
                      onClick={() => setShowVideo(2)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={GTOMastery}
                        alt="Alpha Regiment personal mentor"
                      />
                      {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="img_sec">
                  {showVideo === 2 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="saarthi, your personal mentor"
                              src="https://www.youtube.com/embed/G6T11-FfYvE"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* 3D SIMULATIONS ENDS */}

              {/* WE START AFTER KNOWING YOU START */}
              <div className="metaverse_camp card_sec single_col_sec content_right">
                <div className="img_sec">
                  {showVideo === 3 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Physical exam guidance"
                              // src="https://www.youtube.com/embed/zHFEXFcC2cY"
                              src="https://www.youtube.com/embed/RFxsMa74QWI"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="details_sec border_right">
                  <h2 className="home_heading">
                    {/* {t("physical_exam_heading")} */}
                    {t("startAfterKnowing")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("physical_exam")} */}
                    {t("startAfterKnowingTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 3 ? "active" : ""}`}
                      onClick={() => setShowVideo(3)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={StartKnowing}
                        alt="Alpha Regiment physical exam"
                      />
                      {/* <ImgLazyLoad
                        src={physical_exam}
                        alt="Alpha Regiment physical exam"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* WE START AFTER KNOWING YOU ENDS */}

              {/* COMPREHANSIVE FEEDBACK START */}
              <div className="metaverse_camp card_sec single_col_sec ">
                <div className="details_sec border_left">
                  <h2 className="home_heading">
                    {/* {t("medical_exam_heading")} */}
                    {t("comprehensiveFeedback")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("medical_exam")} */}
                    {t("comprehensiveFeedbackTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 4 ? "active" : ""}`}
                      onClick={() => setShowVideo(4)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={ComprehensiveFeedback}
                        alt="Alpha Regiment medical exam"
                      />
                      {/* <ImgLazyLoad
                        src={medical_exam}
                        alt="Alpha Regiment medical exam"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="img_sec">
                  {showVideo === 4 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Medical exam guidance"
                              // src="https://www.youtube.com/embed/AJn6q1QnjiM"
                              src="https://www.youtube.com/embed/O6sZ3b_-VDM"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* COMPREHANSIVE FEEDBACK ENDS */}

              {/* COMMITMENT FOR YOUR SUCCESS START */}
              <div className="metaverse_camp card_sec single_col_sec content_right">
                <div className="img_sec">
                  {showVideo === 5 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Great Infrastructure"
                              // src="https://www.youtube.com/embed/ReKtwCugHeI"
                              src="https://www.youtube.com/embed/lAw1jcNv8nA"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="details_sec border_right">
                  <h2 className="home_heading">
                    {/* {t("offline_campus_heading")} */}
                    {t("commitmentSuccess")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("offline_campus")} */}
                    {t("commitmentSuccessTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 5 ? "active" : ""}`}
                      onClick={() => setShowVideo(5)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={CommitmentSuccess}
                        alt="Alpha Regiment offline exams"
                      />
                      {/* <ImgLazyLoad
                        src={offline_exam}
                        alt="Alpha Regiment offline exams"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* COMMITMENT FOR YOUR SUCCESS ENDS */}

              {/* NAVIGATING MEDICAL BOARD JOURNEY START */}
              <div className="metaverse_camp card_sec single_col_sec ">
                <div className="details_sec border_left">
                  <h2 className="home_heading">
                    {/* {t("medical_exam_heading")} */}
                    {t("navigatingMedicalBoard")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("medical_exam")} */}
                    {t("navigatingMedicalBoardTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 6 ? "active" : ""}`}
                      onClick={() => setShowVideo(6)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={medical_exam}
                        alt="Alpha Regiment medical exam"
                      />
                      {/* <ImgLazyLoad
                        src={medical_exam}
                        alt="Alpha Regiment medical exam"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="img_sec">
                  {showVideo === 6 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Medical exam guidance"
                              // src="https://www.youtube.com/embed/AJn6q1QnjiM"
                              src="https://www.youtube.com/embed/QuJTQytsAHI"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* NAVIGATING MEDICAL BOARD JOURNEY ENDS */}

              {/* EMMERSIVE TRAINING ENVIOURNMENT START */}
              <div className="metaverse_camp card_sec single_col_sec content_right">
                <div className="img_sec">
                  {showVideo === 7 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Great Infrastructure"
                              // src="https://www.youtube.com/embed/ReKtwCugHeI"
                              src="https://www.youtube.com/embed/aF13b-4k7XY"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="details_sec border_right">
                  <h2 className="home_heading">
                    {/* {t("offline_campus_heading")} */}
                    {t("immersiveEnvironment")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("offline_campus")} */}
                    {t("immersiveEnvironmentTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 7 ? "active" : ""}`}
                      onClick={() => setShowVideo(7)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={immersiveEnvironment}
                        alt="Alpha Regiment offline exams"
                      />
                      {/* <ImgLazyLoad
                        src={offline_exam}
                        alt="Alpha Regiment offline exams"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EMMERSIVE TRAINING ENVIOURNMENT ENDS */}

              {/* EMBARK ON A VIRTUAL ODYSSEY START */}
              <div className="metaverse_camp card_sec single_col_sec ">
                <div className="details_sec border_left">
                  <h2 className="home_heading">
                    {/* {t("medical_exam_heading")} */}
                    {t("embarkVirtualOdyssey")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("medical_exam")} */}
                    {t("embarkVirtualOdysseyTxt")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 8 ? "active" : ""}`}
                      onClick={() => setShowVideo(8)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={VirtualOdyssey}
                        alt="Alpha Regiment medical exam"
                      />
                      {/* <ImgLazyLoad
                        src={medical_exam}
                        alt="Alpha Regiment medical exam"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="img_sec">
                  {showVideo === 8 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(0)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Medical exam guidance"
                              // src="https://www.youtube.com/embed/AJn6q1QnjiM"
                              src="https://www.youtube.com/embed/nigkEA_kuYI"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* EMBARK ON A VIRTUAL ODYSSEY ENDS */}

              {/* EMMERSIVE TRAINING ENVIOURNMENT START */}
              <div className="metaverse_camp card_sec single_col_sec content_right">
                <div className="img_sec">
                  {showVideo === 9 && (
                    <div>
                      <button
                        className="close_modal"
                        onClick={() => setShowVideo(9)}
                      >
                        <AiOutlineClose />
                      </button>
                      <div>
                        <div
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <figure
                            style={{
                              left: 0,
                              width: "100%",
                              height: 0,
                              position: "relative",
                              paddingBottom: "56.25%",
                              marginBlockEnd: 0,
                              marginBlockStart: 0,
                              marginInlineStart: 0,
                              marginInlineEnd: 0,
                            }}
                          >
                            <iframe
                              title="Great Infrastructure"
                              // src="https://www.youtube.com/embed/ReKtwCugHeI"
                              src="https://www.youtube.com/embed/aF13b-4k7XY"
                              scrolling="no"
                              style={{
                                border: 0,
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                overflow: "hidden",
                                borderRadius: 5,
                              }}
                              allowFullScreen={1}
                              allow="autoplay"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="details_sec border_right">
                  <h2 className="home_heading">
                    {/* {t("offline_campus_heading")} */}
                    {t("HassleFreeCommuteStudents")}
                  </h2>
                  <p className="home_custom_para">
                    {/* {t("offline_campus")} */}
                    {t("HassleFreeCommuteStudentSub")}
                  </p>
                  <div className="btn_img_order">
                    <button
                      className={`demo_btn ${showVideo === 7 ? "active" : ""}`}
                      onClick={() => setShowVideo(7)}
                    >
                      <IoVideocam /> {t("diveDeeperBtn")}
                    </button>
                    <div className="">
                      <img
                        src={hasleFreeCommute}
                        alt="Alpha Regiment offline exams"
                      />
                      {/* <ImgLazyLoad
                        src={offline_exam}
                        alt="Alpha Regiment offline exams"
                        custom_class=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EMMERSIVE TRAINING ENVIOURNMENT ENDS */}
            </div>
          </div>
        </section>
        {/* WHY ALPHA REGIMENT ENDS */}

        {/* CHOOSE YOUR GOAL START */}
        <section className="choose_your_goal">
          <div className="container">
            <div className="title">
              <h2>{t("choose_your_goal_heading")}</h2>
            </div>
            {/* <div className="choose_goal_content">
              <div className="goal_card">
                <Link to="/courses" state="Armed Forces">
                  <h2>{t("armed_forces_heading")}</h2>
                  <img src={armed_force} alt="Alpha Regiment armed_forces" />
                  <p>{t("armed_force_desc")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("Armed Forces")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
              <div className="goal_card">
                <Link to="/courses" state="Para Military">
                  <h2>{t("para_military_heading")}</h2>
                  <img src={para_military} alt="Alpha Regiment para_militry" />
                  <p>{t("para_military_desc")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("Para Military")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
              <div className="goal_card">
                <Link to="/courses" state="Police">
                  <h2>{t("police_heading")}</h2>
                  <img src={police_goal} alt="Alpha Regiment armed_forces" />
                  <p>{t("police_desc")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("Police")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
              <div className="goal_card">
                <Link to="/courses" state="MILITARY SCHOOL">
                  <h2>{t("military_school_heading")}</h2>
                  <img
                    src={military_school}
                    alt="Alpha Regiment armed_forces"
                  />
                  <p>{t("military_school_desc")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("MILITARY SCHOOL")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
            </div> */}

            <div className="choose_goal_content">
              <div className="goal_card">
                <Link to="/ssb-courses" state="Mock SSB">
                  <h2>{t("mockSSB")} </h2>
                  <img src={mockSsb} alt="Alpha Regiment armed_forces" />
                  <p>{t("mockSSBTxt")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("Mock SSB")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
              <div className="goal_card">
                <Link to="/ssb-courses" state="SSB coaching">
                  <h2>{t("SSBCoaching")} </h2>
                  <img src={SsbCoaching} alt="Alpha Regiment para_militry" />
                  <p>{t("SSBCoachingTxt")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("SSB coaching")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
              <div className="goal_card">
                <Link to="/ssb-courses" state="Spoken English">
                  <h2>{t("spokenEnglish")} </h2>
                  {/* </Link> */}
                  <img src={spokenEnglish} alt="Alpha Regiment armed_forces" />
                  <p>{t("spokenEnglishTxt")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("Spoken English")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
              <div className="goal_card">
                <Link to="/ssb-courses" state="Medical">
                  <h2>{t("medicalHeading")} </h2>
                  <img src={medical} alt="Alpha Regiment armed_forces" />
                  <p>{t("medicalTxt")}</p>
                </Link>
                <button onClick={() => goalTypeRedirect("Medical")}>
                  {t("exploreBtn")} <BsChevronRight className="icon" />
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* CHOOSE YOUR GOAL ENDS */}

        {/* Media Barracks Start */}
        <div className="mediaBarracksMain">
          <div className="container">
            <div className="title">
              <h2>{t("inMediaHeading")}</h2>
            </div>

            <div className="mediaBarrackContent">
              <Link
                to="https://m.dailyhunt.in/news/india/english/ani+english-epaper-anieng/empowering+students+alpha+regiment+revolutionizes+defense+preparation+with+virtual+campus-newsid-n512914150"
                target="_blank"
              >
                <div className="imgCard">
                  <img
                    src={dailyhunt}
                    alt="alpha regiment daily hunt"
                    className="mediaLogo"
                  />
                </div>
              </Link>

              <Link
                to="https://theprint.in/ani-press-releases/empowering-students-alpha-regiment-revolutionizes-defense-preparation-with-virtual-campus/1642862/"
                target="_blank"
              >
                <div className="imgCard">
                  <img
                    src={thePrint}
                    alt="alpha regiment latestly"
                    className="mediaLogo"
                  />
                </div>
              </Link>

              <Link
                to="https://www.latestly.com/agency-news/business-news-empowering-students-alpha-regiment-revolutionizes-defense-preparation-with-virtual-campus-5226953.html"
                target="_blank"
              >
                <div className="imgCard">
                  <img
                    src={latestly}
                    alt="alpha regiment daily hunt"
                    className="mediaLogo"
                  />
                </div>
              </Link>

              <Link
                to="https://aninews.in/news/business/business/empowering-students-alpha-regiment-revolutionizes-defense-preparation-with-virtual-campus20230626152459/"
                target="_blank"
              >
                <div className="imgCard">
                  <img
                    src={aninews}
                    alt="alpha regiment daily hunt"
                    className="mediaLogo"
                  />
                </div>
              </Link>

              <Link
                to="https://www.zee5.com/articles/empowering-students-alpha-regiment-revolutionizes-defense-preparation-with-virtual-campus"
                target="_blank"
              >
                <div className="imgCard">
                  <img
                    src={zee5}
                    alt="alpha regiment daily hunt"
                    className="mediaLogo"
                  />
                </div>
              </Link>

              <Link
                to="https://www.lokmattimes.com/business/empowering-students-alpha-regiment-revolutionizes-defense-preparation-with-virtual-campus/"
                target="_blank"
              >
                <div className="imgCard">
                  <img
                    src={lokmatLogo}
                    alt="alpha regiment daily hunt"
                    className="mediaLogo"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Media Barracks Ends */}

        {/* TESTIMONIALS START */}
        <section className="testimonials_main">
          <div className="container">
            <div className="title">
              <h2>{t("testimonial_heading")}</h2>
            </div>
            <p className="testimonial_para">{t("few_words_text")}</p>
            <div className="testimonial_slider">
              <Carousel breakPoints={breakPoints}>
                {testimonialArray.map((item) => {
                  return (
                    <TestimonialCard
                      img={item.img}
                      iframeSrc={item.videoLink}
                      showModalKey={item.id}
                      key={item.id}
                    />
                  );
                })}
              </Carousel>
            </div>
          </div>
        </section>
        {/* TESTIMONIALS ENDS */}

        {/* Our soldiers heart content start */}
        <section className="soldiers_heart_content">
          <div className="container">
            <div className="content_background_color">
              <p className="title">Our soldiers heart content</p>
              <Swiper
                grabCursor={true}
                // autoplay={{
                //   delay: 4000,
                //   disableOnInteraction: false,
                // }}
                navigation={true}
                loop={true}
                loopPreventsSliding={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                {!allTestimonialLoading ? (
                  testimonialList?.map((item) => (
                    <SwiperSlide key={item?._id}>
                      <SoldierHeart
                        img={item?.profile?.url}
                        sodierName={item?.title}
                        subHeading={item?.selectedIn}
                        star={item?.givenStar}
                        text={
                          langParam === "hi"
                            ? item?.textHindi
                            : item?.textEnglish
                        }
                      />
                    </SwiperSlide>
                  ))
                ) : (
                  <div className="txt_color courseNotFound_main">
                    <h2 className="txt_center">Testimonial Loading..</h2>

                    <ImgLazyLoad
                      className={"img_load"}
                      src={courseNotFound}
                      alt="courseNotFound"
                    />
                    <p>Please wait</p>
                  </div>
                )}
              </Swiper>
            </div>
          </div>
        </section>
        {/* Our soldiers heart content Ends */}

        {/* Award And Recognition Section Start */}
        {/* <div className="awardRecogMain">
          <div className="container">
            <div className="title">
              <h2>{t("awardAndRecogHeading")}</h2>
            </div>

            <div className="awardRecogContetn">
              {awardRecogArr.map((ele, idx) => (
                <div className="imgCard" key={idx}>
                  <img src={ele.img} alt={ele.img} />
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* Award And Recognition Section Ends */}
      </div>

      <PromotionPopup
        txt="Metaverse campus will coming soon"
        promotion={promotion}
        cancelOnclick={() => setPromotion(false)}
      />
    </Layout>
  );
};

export default Home;
