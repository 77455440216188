import React, { Fragment, useEffect, useState } from "react";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import "../../styles/officerCalender.scss";
import CustomCalendar from "../../components/reusable/CustomCalendar";
import CustomModal from "../../components/reusable/CustomModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import {
  clearCalenderErrors,
  getOfficerCalender,
} from "../../redux/actions/officerCalenderAction";
import Loader from "../../components/reusable/Loader";
import { useAuth } from "../../context/userContext";
import { FaPlus } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  clearErrors,
  clearUpdateState,
  updateUserProfile,
  userProfile,
} from "../../redux/actions/userAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
// import { useNavigate } from "react-router-dom";
// import { FaPlus } from "react-icons/fa";
const OfficerCalender = () => {
  const dispatch = useDispatch();
  const [timeAction, setTimeAction] = useState({
    show: false,
    id: "",
  });
  const [, , showNotification] = useNotification();
  const [auth] = useAuth();
  const timeTable = auth?.user?.timeTable;
  const profileData = auth?.user;
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [eventData, setEventData] = useState();

  const { error, loading, officerEventData } = useSelector(
    (state) => state.getOfficerCalenderEvens
  );

  const eventList = officerEventData?.calendar?.events;

  const events = eventList?.map((event) => ({
    title: event.title,
    start: event.startTime,
    end: event.endTime,
    date: event.date,
    studentData: event.student,
  }));

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getOfficerCalender(auth?.user?._id));
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (error) {
      dispatch(clearCalenderErrors());
    }
  }, [dispatch, error]);

  const handleDateClick = (info) => {
    setShowModal(!showModal);
    setEventData(info);
  };

  const handleTeacherEvent = () => {
    setShowModal(false);
    navigate("/officer/interview-schedule", { state: eventData });
  };

  const handleEditTime = (data) => {
    navigate("/update-working-hrs", { state: data });
  };

  const handleDeleteTime = (idx) => {
    const newTimeList = [...timeTable];
    newTimeList.splice(idx, 1);

    dispatch(
      updateUserProfile({
        first_name: profileData?.first_name,
        middle_name: "",
        last_name: "",
        email: profileData?.email,
        phone: profileData?.phone,
        dob: profileData?.dob,
        address: profileData?.address,
        gender: profileData?.gender,
        profile: profileData?.profile,
        fatherName: "",
        motherName: "",
        parentEmail: "",
        teacherBio: profileData?.teacherBio,
        subSpec: "",
        assignSubjects: "",
        aff_occupation: "",
        aff_state: "",
        aff_bank_name: profileData?.aff_bank_name,
        aff_bank_ifsc: profileData?.aff_bank_ifsc,
        aff_bank_acc_no: profileData?.aff_bank_acc_no,
        aff_bank_branch: profileData?.aff_bank_branch,
        aff_bank_reg_mob: profileData?.aff_bank_reg_mob,
        aff_upi_id: profileData?.aff_upi_id,
        parentWhatsAppNo: "",
        timeTable: newTimeList,
        facebook: profileData?.facebook,
        instagram: profileData?.instagram,
        twitter: profileData?.twitter,
        whatsapp: profileData?.whatsapp,
        language: auth?.user?.language,
      })
    );
  };

  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile); // GET PROFILE STATE FROM REDUCER STORE

  // HANLDE SUCCESS MESSAGE FOR UPDATE OFFICER DETAILS
  useEffect(() => {
    if (userInfo?.success) {
      showNotification("success", userInfo?.message);
      dispatch(userProfile());
      dispatch(clearUpdateState());
    }
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    showNotification,
    updateError,
    navigate,
    userInfo?.message,
    userInfo?.success,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <OfficerLayout>
            <ProfileLayout sidebarLinks={officerSidebarLink}>
              <div className="officer_working_hr">
                <div className="title">
                  <h2>Working Hours</h2>
                  <button
                    title="Add Working hrs"
                    onClick={() => navigate("/create-working-hrs")}
                  >
                    <FaPlus />
                  </button>
                </div>
                <div className="working_section">
                  {timeTable?.map((ele, idx) => (
                    <div
                      className={`working_data ${
                        ele?.weekDay === "Sunday" ? "weekOff" : ""
                      }`}
                      key={ele?._id}
                    >
                      {updateLoading && timeAction.id === idx ? (
                        <ButtonLoader />
                      ) : (
                        <>
                          <div>
                            <h4>{ele?.weekDay}</h4>
                            {ele?.classTime?.map((itm, id) => (
                              <p key={id}>
                                <span>{itm?.time}</span>-
                                <span>{itm?.endTime}</span>
                              </p>
                            ))}
                          </div>
                          <p
                            className="edit"
                            onClick={() =>
                              setTimeAction({ show: true, id: idx })
                            }
                          >
                            {/* <FaEdit /> */}
                            <BsThreeDotsVertical />
                          </p>
                          <div
                            className={`actionSec bg_color ${
                              timeAction.show && timeAction.id === idx
                                ? "active"
                                : ""
                            }`}
                          >
                            <p
                              onClick={() => handleEditTime(ele)}
                              className="editAction"
                            >
                              Edit
                            </p>
                            <p
                              onClick={() => handleDeleteTime(idx)}
                              className="deleteAction"
                            >
                              Delete
                            </p>
                            <p
                              onClick={() =>
                                setTimeAction({ show: false, id: "" })
                              }
                              className="cancelAction"
                            >
                              Cancel
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="officer_calendar">
                <div className="title">
                  <h2>Calendar</h2>
                </div>
                <div className="calendar_section">
                  <CustomCalendar
                    events={events}
                    handleDateClick={handleDateClick}
                  />
                </div>
              </div>
            </ProfileLayout>
          </OfficerLayout>
          {showModal && (
            <CustomModal
              cancelBtnTxt="OK"
              title="Schedule Events"
              className="show_events"
              cancelOnclick={() => setShowModal(false)}
              tableData={eventData}
              handleTeacherEvent={handleTeacherEvent}
            />
          )}
        </>
      )}
    </Fragment>
  );
};

export default OfficerCalender;
