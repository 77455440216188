import React from "react";
import CustomHelmet from "../../components/reusable/Helmet";

const RavinderVisitingCard = () => {
  return (
    <div>
      <CustomHelmet redirectLink="0; url=https://alpharegiment.com/ravinder/" />
    </div>
  );
};

export default RavinderVisitingCard;
