// import { axiosInstance } from "../../utils/axiosConfig";
import {
  ADD_CART_ITEM,
  ERROR_CART_ITEM,
  REMOVE_CART_ITEM,
} from "../constants/cartConstant";

// ADD ITEMS TO CART ACTION FUNCTION
export const addItemsToCart = (courseDetails) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CART_ITEM,
      payload: {
        id: courseDetails?._id,
        title: courseDetails?.title,
        discountedPrice: courseDetails?.discountedPrice
          ? courseDetails?.discountedPrice
          : courseDetails?.discounted_price,
        orignalPrice: courseDetails?.originalPrice
          ? courseDetails?.originalPrice
          : courseDetails?.original_price,
        image: courseDetails?.thumbnail.url,
        isEmiAvail: courseDetails?.isEmiAvail,
        allEmis: courseDetails?.allEmis,
        starting_date: courseDetails?.starting_date,
        courseMode: courseDetails?.courseMode
          ? courseDetails?.courseMode
          : courseDetails?.study_mode,

        ssbCourse: courseDetails?.ssbCourse,
        affiliate_percentage: courseDetails?.affiliate_percentage,
      },
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  } catch (error) {
    dispatch({
      type: ERROR_CART_ITEM,
      payload: error.response.data.message,
    });
  }
};

// REMOVE ITEMS FROM CART
export const removeItemsFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};
