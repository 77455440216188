import React, { Fragment, useEffect, useState } from "react";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSsbCourseDetails,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import { useNotification } from "../../context/notificationContext";
import Loader from "../../components/reusable/Loader";
import "../../styles/addCurriculum.scss";
import CustomInput from "../../components/reusable/CustomInput";
import {
  FaEdit,
  FaPencilAlt,
  FaPlus,
  FaStickyNote,
} from "react-icons/fa";
import CustomButton from "../../components/reusable/CustomButton";
import { RiStickyNoteFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { gtoLecture, lectureLive, video_icon } from "../../assets/images";

// IMPORT REACT QUILL AND ITS CSS
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AiFillEye } from "react-icons/ai";
import PopupPrompt from "../../components/reusable/PopupPrompt";
import { GiJumpAcross, GiTeacher } from "react-icons/gi";

const AddCurriculum = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const { id } = useParams();

  const { loading, error, courseDetails } = useSelector(
    (state) => state.getSsbCourseDetails
  );
  // console.log(courseDetails);

  // FETCH COURSE DETAILS START
  useEffect(() => {
    if (id) {
      dispatch(getSsbCourseDetails(id));
    }
  }, [id, dispatch]);
  // FETCH COURSE DETAILS ENDS

  //   HANDLE COURSE FETCH ERROR START
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch, showNotification]);

  //   HANDLE COURSE FETCH ERROR ENDS
  const [sectionHeadingState, setSectionHeadingState] = useState(false);
  const [lectureHeadingState, setLectureHeadingState] = useState(false);
  const [contentAddState, setContentAddState] = useState(false);
  const [videoAddState, setVideoAddState] = useState(false);
  const [articleAddState, setArticleAddState] = useState(false);

  const [sectionHeading, setSectionHeading] = useState("");
  const [learingObjective, setLearingObjective] = useState("");
  // LECTURE ADDING STATE
  const [lectureHeadings, setLectureHeadings] = useState("");

  const [videoLink, setVideoLink] = useState();
  const [videoName, setVideoName] = useState();
  const [article, setArticle] = useState("");
  const [description, setDescription] = useState("");

  // LOGIC HANDLER STATES
  const [lectureList, setLectureList] = useState([]);
  const [lectureId, setLectureId] = useState(null);
  const [addLecture, setAddLecture] = useState(true);
  const [sectionList, setSectionList] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  const [addSection, setAddSection] = useState(true);
  const [viewLecture, setViewLecture] = useState(false);

  // SHOW MESSSAGE ON PUBLISH
  const [showPopUp, setShowPopUp] = useState(false);

  // HANLDE VIDEO CHANGE INPUT
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVideoName(file?.name);
    if (file) {
      setVideoLink(URL.createObjectURL(file));
    }
  };

  const handleArticleChange = (value) => {
    setArticle(value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  //EDIT LECTURE HANDING FUNCTION START
  const editLectureheading = (e) => {
    setLectureHeadingState(true);
  };

  const lectureHeadingHandler = (e) => {
    setLectureHeadings(e.target.value);
  };

  // LECTURE STORE ARRAY

  const handleNewLecture = () => {
    setAddLecture(true);
  };
  // SAVE LECTURE STARTS
  const handleAddLecture = () => {
    if (
      lectureHeadings !== "" &&
      (article !== undefined || videoLink !== undefined)
    ) {
      let lectureData = {
        lectureHeadings,
        article,
        videoLink,
        description,
      };

      if (lectureId !== null) {
        // Update existing object at lectureId index
        setLectureList((prevLectures) => {
          const updatedLectures = [...prevLectures];
          updatedLectures[lectureId] = lectureData;
          return updatedLectures;
        });
        setLectureId(null);
      } else {
        setLectureList((prevLectures) => [...prevLectures, lectureData]);
        setAddLecture(false);
      }
      // Reset form fields
      setLectureHeadings("");
      setArticle("");
      setDescription("");
      setVideoLink("");
      setLectureId(null); // Reset lectureId after handling
    } else {
      showNotification("error", "Please add require details");
    }
  };
  // SAVE LECTURE ENDS

  const handleUpdateLecture = () => {
    if (
      lectureHeadings !== "" &&
      (article !== undefined || videoLink !== undefined)
    ) {
      let lectureData = {
        lectureHeadings,
        article,
        videoLink,
        description,
      };
      setViewLecture(false);
      if (lectureId !== null) {
        // Update existing object at lectureId index
        setLectureList((prevLectures) => {
          const updatedLectures = [...prevLectures];
          updatedLectures[lectureId] = lectureData;
          return updatedLectures;
        });
        setLectureId(null);
      } else {
        setLectureList((prevLectures) => [...prevLectures, lectureData]);
      }

      // Reset form fields
      setLectureHeadings("");
      setArticle("");
      setDescription("");
      setVideoLink("");
      setLectureId(null); // Reset lectureId after handling
    } else {
      showNotification("error", "Please add require details");
    }
  };

  // const [editSection, setEditSection] = useState(false);
  // HANLDE ADD NEW SECTION START

  const handleAddSection = () => {
    setAddSection(true);
    setAddLecture(true);
  };
  // HANLDE ADD NEW SECTION ENDS

  // SAVE SECTION IN SECTION LIST
  const handleSaveSection = () => {
    if (!sectionHeading && !learingObjective && !lectureList?.length > 0) {
      showNotification("error", "Please add all required fields");
    } else {
      let sectionData = {
        heading: sectionHeading,
        about: learingObjective,
        lectures: lectureList,
      };
      if (sectionId !== null) {
        setSectionList((prevLectures) => {
          const updatedLectures = [...prevLectures];
          updatedLectures[sectionId] = sectionData;
          return updatedLectures;
        });
        setSectionId(null);
      } else {
        setSectionList((prevLectures) => [...prevLectures, sectionData]);
        setAddSection(false);
      }

      setSectionHeading("");
      setLearingObjective("");
      setLectureList([]);
    }
  };

  // HANDLE EDIT SECTION START
  const handleEditSection = (id) => {
    setSectionId(id);
    // setEditSection(true);
    const filterSection = sectionList.find((itm, idx) => idx === id);
    setSectionList(sectionList?.filter((itm, idx) => idx !== id));
    if (filterSection) {
      setSectionHeading(filterSection?.heading);
      setLearingObjective(filterSection?.about);
      setLectureList(filterSection?.lectures);
    }
  };

  // HANLDE DELETE SECTION START
  const handleDeleteSection = (id) => {
    const filterSection = sectionList?.filter((itm, idx) => idx !== id);
    setSectionList(filterSection);
  };

  const handleViewDetails = (id) => {
    setLectureId(id);
    setViewLecture(true);
    const filterData = lectureList.find((ele, idx) => idx === id);
    setLectureList(lectureList.filter((ele, idx) => idx !== id));
    if (filterData) {
      setLectureHeadings(filterData?.lectureHeadings);
      setArticle(filterData?.article);
      setVideoLink(filterData?.videoLink);
      setDescription(filterData?.description);
    }
  };

  //   REACT QUILL SETIINGS
  const style = {
    "& .qlSnow .qlStroke": { stroke: "white" },
  };

  const modules = {
    toolbar: [
      [{ header: [false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: ["white"] }, { background: ["transparent"] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const formats1 = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "bullet",
  ];

  const handlePublish = () => {
    setShowPopUp(true);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <section className="create_course_curriculum txt_color">
              <div className="title">
                <h2>Add Curriculum for {courseDetails?.course?.title}</h2>
                <button
                  title="Go Back"
                  onClick={() => navigate("/officer/curriculum")}
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="currciulum_content">
                {sectionList?.length > 0 &&
                  sectionList?.map((ele, index) => (
                    <div className="section_list" key={index}>
                      <div className="header">
                        <p>{ele?.heading}</p>
                        <div className="action_btns">
                          <button onClick={() => handleEditSection(index)}>
                            <FaEdit />
                          </button>
                          <button onClick={() => handleDeleteSection(index)}>
                            <MdDelete />
                          </button>
                        </div>
                      </div>
                      {ele?.about !== "" && (
                        <div className="learning">
                          <p>
                            <span>Objective :</span>
                            <span>{ele?.about}</span>
                          </p>
                        </div>
                      )}
                      {ele?.lectures?.map((itm, id) => (
                        <div className="lecture_content" key={id}>
                          <div className="title">
                            <p>
                              <span>Lecture Title :</span>
                              <span>{itm?.lectureHeadings}</span>
                            </p>
                          </div>
                          <div className="content">
                            {itm?.videoLink && (
                              <div className="video">
                                <p>Curriculum Video</p>
                                <div className="video_content">
                                  <video
                                    src={itm?.videoLink}
                                    width={300}
                                    height={300}
                                    controls
                                  />
                                </div>
                              </div>
                            )}

                            <div className="article">
                              <p>Lecture Article :</p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: itm?.article,
                                }}
                              />
                            </div>
                            <div className="article">
                              <p>Lecture Description :</p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: itm?.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                {addSection && (
                  <div className="curriculum_steps">
                    <div className="heading">
                      <div>
                        <p>Section {sectionList?.length + 1} :</p>
                        {!sectionHeadingState && (
                          <p
                            onClick={() =>
                              setSectionHeadingState(!sectionHeadingState)
                            }
                          >
                            <span>
                              <RiStickyNoteFill />
                            </span>
                            {sectionHeading !== "" ? (
                              <span>{sectionHeading}</span>
                            ) : (
                              <span>Introduction</span>
                            )}

                            <span className="edit">
                              <FaPencilAlt />
                            </span>
                            <span className="edit">
                              <MdDelete />
                            </span>
                          </p>
                        )}
                        {sectionHeadingState && (
                          <div>
                            <CustomInput
                              icon=""
                              label=""
                              type="text"
                              onChange={(e) =>
                                setSectionHeading(e.target.value)
                              }
                              placeholder="Introduction"
                              passwordMessage=""
                              name="sectionHeading"
                              value={sectionHeading}
                              impStar=""
                              readOnly=""
                              borderColor="#ffff"
                              textLength={80}
                            />
                            <CustomInput
                              icon=""
                              label="What will students be able to do at the end of this section?"
                              type="text"
                              onChange={(e) =>
                                setLearingObjective(e.target.value)
                              }
                              placeholder="Write learning objective"
                              passwordMessage=""
                              name="learingObjective"
                              value={learingObjective}
                              impStar=""
                              readOnly=""
                              borderColor="#ffff"
                            />
                          </div>
                        )}
                      </div>

                      {sectionHeadingState && (
                        <div className="btns">
                          <CustomButton
                            title="Cancel"
                            onClick={() =>
                              setSectionHeadingState(!sectionHeadingState)
                            }
                            className="cancel_btn"
                            //   disabled={sectionHeading.length > 80}
                          />
                          <CustomButton
                            title="Save"
                            onClick={() =>
                              setSectionHeadingState(!sectionHeadingState)
                            }
                            className="save_btn"
                            disabled={sectionHeading.length > 80}
                          />
                        </div>
                      )}
                    </div>

                    {lectureList?.length > 0 && (
                      <div className="lecture_list">
                        {lectureList?.map((ele, idx) => {
                          return (
                            <div className="lecture_box" key={idx}>
                              <p>Lecture {idx + 1} :</p>
                              <div className="title">
                                <h4>{ele?.lectureHeadings}</h4>
                                <CustomButton
                                  title="View"
                                  icon={<AiFillEye />}
                                  onClick={() => handleViewDetails(idx)}
                                  width="90px"
                                />
                              </div>
                              <div className="content">
                                {ele?.description !== "" && (
                                  <div className="article">
                                    <h2>Description :</h2>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.description,
                                      }}
                                    />
                                  </div>
                                )}
                                {ele?.article !== "" && (
                                  <div className="article">
                                    <h2>Article :</h2>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.article,
                                      }}
                                    />
                                  </div>
                                )}

                                {ele?.videoLink !== undefined && (
                                  <div className="article">
                                    <h2>Video : {videoName}</h2>
                                    <video
                                      src={ele?.videoLink}
                                      width={300}
                                      height={300}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {!viewLecture && addLecture && (
                      <div className="lecture_section">
                        <div className="lecture_title">
                          <div className="lecture_content_add">
                            <div>
                              <div>
                                <div>
                                  <p>Lecture {lectureList?.length + 1} :</p>
                                  {!lectureHeadingState && (
                                    <p onClick={editLectureheading}>
                                      <span>
                                        <RiStickyNoteFill />
                                      </span>
                                      {lectureHeadings !== "" ? (
                                        <span>{lectureHeadings}</span>
                                      ) : (
                                        <span>Lecture Title</span>
                                      )}
                                      <span className="edit">
                                        <FaPencilAlt />
                                      </span>
                                      <span className="edit">
                                        <MdDelete />
                                      </span>
                                    </p>
                                  )}
                                  {lectureHeadingState && (
                                    <CustomInput
                                      icon=""
                                      label=""
                                      type="text"
                                      onChange={(e) => lectureHeadingHandler(e)}
                                      placeholder="Enter lecture title"
                                      passwordMessage=""
                                      name="lectureHeadings"
                                      value={lectureHeadings}
                                      impStar=""
                                      readOnly=""
                                      borderColor="#ffff"
                                    />
                                  )}
                                </div>
                                <div className="content_btns">
                                  {!videoAddState && !articleAddState && (
                                    <CustomButton
                                      className="add_item"
                                      title={
                                        contentAddState
                                          ? "Select Content Type"
                                          : "Content"
                                      }
                                      onClick={() => {
                                        setContentAddState(!contentAddState);
                                        setLectureHeadingState(false);
                                      }}
                                      icon={
                                        contentAddState ? (
                                          <RxCross2 />
                                        ) : (
                                          <FaPlus />
                                        )
                                      }
                                      width={
                                        contentAddState ? "240px" : "140px"
                                      }
                                    />
                                  )}
                                  {videoAddState && (
                                    <CustomButton
                                      className="add_item"
                                      title="Add Video"
                                      onClick={() => {
                                        setVideoAddState(!videoAddState);
                                      }}
                                      icon={
                                        videoAddState ? (
                                          <RxCross2 />
                                        ) : (
                                          <FaPlus />
                                        )
                                      }
                                      width={videoAddState ? "200px" : "140px"}
                                    />
                                  )}
                                  {articleAddState && (
                                    <CustomButton
                                      className="add_item"
                                      title="Add Article"
                                      onClick={() => {
                                        setArticleAddState(!articleAddState);
                                      }}
                                      icon={
                                        articleAddState ? (
                                          <RxCross2 />
                                        ) : (
                                          <FaPlus />
                                        )
                                      }
                                      width={
                                        articleAddState ? "200px" : "140px"
                                      }
                                    />
                                  )}
                                  {/* <p
                                  onClick={() => {
                                    setAdditionalContentState(
                                      !additionalContentState
                                    );
                                  }}
                                >
                                  <FaAngleDown />
                                </p> */}
                                </div>
                              </div>
                              {lectureHeadingState && (
                                <div className="upload_video">
                                  <h2>
                                    {description === ""
                                      ? "Add Description"
                                      : "Edit Description"}
                                  </h2>
                                  <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats1}
                                    style={style}
                                    name="description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                  />
                                </div>
                              )}
                              {/* LECTURE HEADING SAVE AND CANCEL BUTTONS START */}
                              {lectureHeadingState && (
                                <div className="btns">
                                  <CustomButton
                                    title="Cancel"
                                    onClick={() => {
                                      setLectureHeadingState(
                                        !lectureHeadingState
                                      );
                                      setLectureHeadings("");
                                    }}
                                    className="cancel_btn"
                                  />
                                  <CustomButton
                                    title="Save"
                                    onClick={() =>
                                      setLectureHeadingState(
                                        !lectureHeadingState
                                      )
                                    }
                                    className="save_btn"
                                    disabled={lectureHeadings?.length > 80}
                                  />
                                </div>
                              )}
                              {/* LECTURE HEADING SAVE AND CANCEL BUTTONS ENDS */}
                            </div>
                          </div>

                          {/* LECTURE CONTENT PREVIEW START */}
                          <div className="preview">
                            {description !== undefined &&
                              description !== "" && (
                                <div className="article">
                                  <p>Lecture Description :</p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: description,
                                    }}
                                  />
                                </div>
                              )}

                            {videoLink !== undefined && videoLink !== "" && (
                              <div className="preview_video">
                                <p>Lecture Video : {videoName}</p>
                                <video
                                  src={videoLink}
                                  width={300}
                                  height={200}
                                  controls
                                />
                              </div>
                            )}

                            {article !== undefined && article !== "" && (
                              <div className="article">
                                <p>Lecture Article :</p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: article,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {/* lectrure content preview ends */}

                          {contentAddState && (
                            <>
                              <div className="file_type">
                                <button
                                  className="upload_btn"
                                  onClick={() => {
                                    setVideoAddState(true);
                                    setContentAddState(false);
                                  }}
                                >
                                  <img src={video_icon} alt="video_img" />
                                  <span>Video</span>
                                </button>
                                <button
                                  className="upload_btn"
                                  onClick={() => {
                                    setArticleAddState(true);
                                    setContentAddState(false);
                                  }}
                                >
                                  <FaStickyNote />
                                  <span>Article</span>
                                </button>
                                <button
                                  className="upload_btn"
                                  // onClick={() => {
                                  //   setVideoAddState(true);
                                  //   setContentAddState(false);
                                  // }}
                                >
                                  {/* <img src={gtoLecture} alt="video_img" /> */}
                                  <GiJumpAcross />
                                  <span>GTO</span>
                                </button>
                                <button
                                  className="upload_btn"
                                  // onClick={() => {
                                  //   setArticleAddState(true);
                                  //   setContentAddState(false);
                                  // }}
                                >
                                  {/* <img src={lectureLive} alt="video_img" /> */}
                                  <GiTeacher />
                                  <span>Live Classes</span>
                                </button>
                              </div>
                            </>
                          )}

                          {/* VIDEO SAVE AND CANCEL START */}
                          {videoAddState && (
                            <div className="upload_video">
                              <CustomInput
                                label={
                                  viewLecture
                                    ? "Edit Lecture Video"
                                    : "Lecture Video"
                                }
                                type="file"
                                accept="video/*"
                                placeholder=""
                                impStar="*"
                                name="videoLink"
                                tooltip="Ensure the video meets the following specifications: HD resolution, maintain an aspect ratio of 1:9, and limit the file size to a maximum of 1gb."
                                onChange={(e) => handleFileChange(e)}
                              />
                              <div className="btns">
                                <CustomButton
                                  title="Cancel"
                                  className="cancel_btn"
                                  onClick={() => {
                                    setVideoAddState(!videoAddState);
                                    setContentAddState(false);
                                    setVideoLink();
                                  }}
                                />
                                <CustomButton
                                  title="Save"
                                  className="save_btn"
                                  onClick={() => {
                                    setVideoAddState(!videoAddState);
                                    setContentAddState(false);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {/* VIDEO SAVE AND CANCEL START */}

                          {/* ARTICLE SAVE AND CANCEL START */}
                          {articleAddState && (
                            <div className="upload_video">
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                name="article"
                                value={article}
                                onChange={handleArticleChange}
                              />
                              <CustomButton
                                title="Save"
                                className="article_save_btn"
                                width="80px"
                                onClick={() => {
                                  setArticleAddState(!articleAddState);
                                  setContentAddState(false);
                                }}
                              />
                            </div>
                          )}
                          {/* ARTICLE SAVE AND CANCEL ENDS */}
                        </div>
                      </div>
                    )}

                    {/* EDIT LECTURE START */}
                    {viewLecture && (
                      <div className="lecture_section">
                        <div className="lecture_title">
                          <div className="lecture_content_add">
                            <div>
                              <div>
                                <div>
                                  <p>Lecture {lectureList?.length + 1} :</p>
                                  {!lectureHeadingState && (
                                    <p onClick={editLectureheading}>
                                      <span>
                                        <RiStickyNoteFill />
                                      </span>
                                      {lectureHeadings !== "" ? (
                                        <span>{lectureHeadings}</span>
                                      ) : (
                                        <span>Lecture Title</span>
                                      )}
                                      <span className="edit">
                                        <FaPencilAlt />
                                      </span>
                                      <span className="edit">
                                        <MdDelete />
                                      </span>
                                    </p>
                                  )}
                                  {lectureHeadingState && (
                                    <CustomInput
                                      icon=""
                                      label=""
                                      type="text"
                                      onChange={(e) => lectureHeadingHandler(e)}
                                      placeholder="Enter lecture title"
                                      passwordMessage=""
                                      name="lectureHeadings"
                                      value={lectureHeadings}
                                      impStar=""
                                      readOnly=""
                                      borderColor="#ffff"
                                    />
                                  )}
                                </div>
                                <div className="content_btns">
                                  {!videoAddState && !articleAddState && (
                                    <CustomButton
                                      className="add_item"
                                      title={
                                        contentAddState
                                          ? "Select Content Type"
                                          : "Content"
                                      }
                                      onClick={() => {
                                        setContentAddState(!contentAddState);
                                        setLectureHeadingState(false);
                                      }}
                                      icon={
                                        contentAddState ? (
                                          <RxCross2 />
                                        ) : (
                                          <FaPlus />
                                        )
                                      }
                                      width={
                                        contentAddState ? "240px" : "140px"
                                      }
                                    />
                                  )}
                                  {videoAddState && (
                                    <CustomButton
                                      className="add_item"
                                      title="Add Video"
                                      onClick={() => {
                                        setVideoAddState(!videoAddState);
                                      }}
                                      icon={
                                        videoAddState ? (
                                          <RxCross2 />
                                        ) : (
                                          <FaPlus />
                                        )
                                      }
                                      width={videoAddState ? "200px" : "140px"}
                                    />
                                  )}
                                  {articleAddState && (
                                    <CustomButton
                                      className="add_item"
                                      title="Add Article"
                                      onClick={() => {
                                        setArticleAddState(!articleAddState);
                                      }}
                                      icon={
                                        articleAddState ? (
                                          <RxCross2 />
                                        ) : (
                                          <FaPlus />
                                        )
                                      }
                                      width={
                                        articleAddState ? "200px" : "140px"
                                      }
                                    />
                                  )}
                                  {/* <p
                                  onClick={() => {
                                    setAdditionalContentState(
                                      !additionalContentState
                                    );
                                  }}
                                >
                                  <FaAngleDown />
                                </p> */}
                                </div>
                              </div>
                              {lectureHeadingState && (
                                <div className="upload_video">
                                  <h2>
                                    {description === ""
                                      ? "Add Description"
                                      : "Edit Description"}
                                  </h2>
                                  <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats1}
                                    style={style}
                                    name="description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                  />
                                </div>
                              )}
                              {/* LECTURE HEADING SAVE AND CANCEL BUTTONS START */}
                              {lectureHeadingState && (
                                <div className="btns">
                                  <CustomButton
                                    title="Cancel"
                                    onClick={() => {
                                      setLectureHeadingState(
                                        !lectureHeadingState
                                      );
                                      setLectureHeadings("");
                                    }}
                                    className="cancel_btn"
                                  />
                                  <CustomButton
                                    title="Save"
                                    onClick={() =>
                                      setLectureHeadingState(
                                        !lectureHeadingState
                                      )
                                    }
                                    className="save_btn"
                                    disabled={lectureHeadings?.length > 80}
                                  />
                                </div>
                              )}
                              {/* LECTURE HEADING SAVE AND CANCEL BUTTONS ENDS */}
                            </div>
                          </div>

                          {/* LECTURE CONTENT PREVIEW START */}
                          <div className="preview">
                            {description !== undefined &&
                              description !== "" && (
                                <div className="article">
                                  <p>Lecture Description :</p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: description,
                                    }}
                                  />
                                </div>
                              )}
                            {videoLink !== undefined && videoLink !== "" && (
                              <div className="preview_video">
                                <p>Lecture Video : {videoName}</p>
                                <video
                                  src={videoLink}
                                  width={300}
                                  height={200}
                                  controls
                                />
                              </div>
                            )}

                            {article !== undefined && article !== "" && (
                              <div className="article">
                                <p>Lecture Article :</p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: article,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {/* lectrure content preview ends */}

                          {/* ADD VIDEO STATE START */}
                          {contentAddState && (
                            <>
                              <div className="file_type">
                                <button
                                  className="upload_btn"
                                  onClick={() => {
                                    setVideoAddState(true);
                                    setContentAddState(false);
                                  }}
                                >
                                  <img src={video_icon} alt="video_img" />
                                  <span>Video</span>
                                </button>
                                <button
                                  className="upload_btn"
                                  onClick={() => {
                                    setArticleAddState(true);
                                    setContentAddState(false);
                                  }}
                                >
                                  <FaStickyNote />
                                  <span>Article</span>
                                </button>
                                <button
                                  className="upload_btn"
                                  // onClick={() => {
                                  //   setVideoAddState(true);
                                  //   setContentAddState(false);
                                  // }}
                                >
                                  <img src={gtoLecture} alt="video_img" />
                                  <span>GTO</span>
                                </button>
                                <button
                                  className="upload_btn"
                                  // onClick={() => {
                                  //   setArticleAddState(true);
                                  //   setContentAddState(false);
                                  // }}
                                >
                                  <img src={lectureLive} alt="video_img" />
                                  <span>Live Classes</span>
                                </button>
                              </div>
                            </>
                          )}
                          {/* ADD VIDEO STATE ENDS */}

                          {/* VIDEO SAVE AND CANCEL START */}
                          {videoAddState && (
                            <div className="upload_video">
                              <CustomInput
                                label="Upload Video"
                                type="file"
                                accept="video/*"
                                placeholder=""
                                impStar="*"
                                name="videoLink"
                                tooltip="Ensure the video meets the following specifications: HD resolution, maintain an aspect ratio of 1:9, and limit the file size to a maximum of 1gb."
                                onChange={(e) => handleFileChange(e)}
                              />
                              <div className="btns">
                                <CustomButton
                                  title="Cancel"
                                  className="cancel_btn"
                                  onClick={() => {
                                    setVideoAddState(!videoAddState);
                                    setContentAddState(false);
                                  }}
                                />
                                <CustomButton
                                  title="Save"
                                  className="save_btn"
                                  onClick={() => {
                                    setVideoAddState(!videoAddState);
                                    setContentAddState(false);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {/* VIDEO SAVE AND CANCEL START */}

                          {/* ARTICLE SAVE AND CANCEL START */}
                          {articleAddState && (
                            <div className="upload_video">
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                name="article"
                                value={article}
                                onChange={handleArticleChange}
                              />
                              <CustomButton
                                title="Save"
                                className="article_save_btn"
                                width="80px"
                                onClick={() => {
                                  setArticleAddState(!articleAddState);
                                  setContentAddState(false);
                                }}
                              />
                            </div>
                          )}
                          {/* ARTICLE SAVE AND CANCEL ENDS */}
                          {viewLecture && (
                            <CustomButton
                              className="update_lecture"
                              title="Update Lecture"
                              onClick={() => handleUpdateLecture()}
                              width="200px"
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {/* EDIT LECTURE ENDS */}

                    {!viewLecture && addLecture && (
                      <CustomButton
                        className="add_item"
                        title="Save Lecture"
                        onClick={() => handleAddLecture()}
                        // icon={<FaPlus />}
                        width="200px"
                      />
                    )}

                    {!viewLecture && !addLecture && (
                      <CustomButton
                        className="add_item"
                        title="Add Lecture"
                        onClick={() => handleNewLecture()}
                        icon={<FaPlus />}
                        width="200px"
                      />
                    )}
                  </div>
                )}

                {addSection && lectureList?.length > 0 && (
                  <div className="section_save">
                    <CustomButton
                      title="Save Section"
                      className="add_Section"
                      // width="200px"
                      onClick={() => handleSaveSection()}
                    />
                  </div>
                )}
                {!addSection && (
                  <div className="section_save">
                    <CustomButton
                      title="Add Section"
                      className="add_Section"
                      // width="200px"
                      icon={<FaPlus />}
                      onClick={() => handleAddSection()}
                    />
                  </div>
                )}

                {sectionList?.length > 0 && (
                  <div className="publish_btns">
                    <CustomButton
                      title="Save Curriculum"
                      className="add_Section"
                      // icon={<FaPlus />}
                      // onClick={console.log("")}
                    />
                    <CustomButton
                      title="Publish Curriculum"
                      className="add_Section"
                      // icon={<FaPlus />}
                      onClick={() => handlePublish()}
                    />
                  </div>
                )}
              </div>
            </section>
            {showPopUp && (
              <PopupPrompt
                cancelBtnTxt="OK"
                title="Congratulations! Your course has been forwarded to the admin for scrutiny. Once they give the green light, it's a go! Expect it to be live and available for student enrollment within the next three working days. Over and out."
                cancelOnclick={() => setShowPopUp(false)}
              />
            )}
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default AddCurriculum;
