import { OMockInter, OPhsy } from "../../../assets/images";

export const teacherOnlineCourse = [
    {
        id:1,
        label:"Written Test",
        image: OPhsy,
        category: "Online",
        courseCategory: "Written",
        tooltip:"Create an online written test."
    },
    {
        id:2,
        label:"Mock Test",
        image: OMockInter,
        category: "Online",
        courseCategory: "Mock",
        tooltip:"Create an online mock test."
    },
    {
        id:3,
        label:"Spoken English",
        image: OMockInter,
        category: "Online",
        courseCategory: "Spoken English",
        tooltip:"Create an online spoken english course."
    }
]