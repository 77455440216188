import { OMockInter, OPhsy } from "../../../assets/images";

export const teacherOfflineCourse = [
    {
        id:1,
        label:"Written Test",
        image: OPhsy,
        category: "Offline",
        courseCategory: "Written",
        tooltip:"Create an offline written test."
    },
    {
        id:2,
        label:"Mock Test",
        image: OMockInter,
        category: "Offline",
        courseCategory: "Mock",
        tooltip:"Create an offline mock test."
    },
    {
        id:3,
        label:"Spoken English",
        image: OMockInter,
        category: "Offline",
        courseCategory: "Spoken English",
        tooltip:"Create an offline spoken english course."
    }
]