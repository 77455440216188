import React, { useEffect, useState } from "react";
import { FaCaretUp, FaVideo } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import startLocalVideoStream from "./startLocalVideoStream";
import updateCallStatus from "../../../../redux/actions/updateCallStatus";
import getDevices from "../../utils/getDevices";
import addStream from "../../../../redux/actions/addStream";
import ActionButtonCaretDropdown from "../ActionButtonCaretDropdown";

const VideoButton = ({ smallFeedEl }) => {
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  const [pendingUpdate, setPendingUpdate] = useState(false);
  const [caretOpen, setCaretOpen] = useState(false);
  const [videoDeviceList, setVideoDeviceList] = useState([]);

  useEffect(() => {
    const getDevicesAsync = async() => {
      const devices = await getDevices();
      setVideoDeviceList(devices.videoDevices);
    }
    if(caretOpen){
      getDevicesAsync()
    }
  }, [caretOpen])
  

  const changeVideoDevice = async (e) => {
    // the user changed desired video device
    //1. we need to get the deviceId
    const deviceId = e.target.value;
    //2. we need to getUserMedia(permission)
    const newConstaints = {
      audio: callStatus.audioDevice === "default" ? true : {deviceId: {exact: callStatus.audioDevice}},
      video: {deviceId: {exact: deviceId}}
    }
    const stream = await navigator.mediaDevices.getUserMedia(newConstaints);
    //3. update redux with that videoDevice, and that video is enabled
    dispatch(updateCallStatus('videoDevice', deviceId))
    dispatch(updateCallStatus('video', "enabled"))
    //4. update the smallFeedEl
    smallFeedEl.current.srcObject = stream;
    //5. we need to update localStream in streams
    dispatch(addStream('localStream', stream)) 
    //6. add tracks 
    const [videoTrack] = stream.getVideoTracks()
    // come back to this later
    // if we stop the old tracks, and add the new tracks, that will mean
    // ...renegotiation
    for(const s in streams){
      if(s !== 'localStream'){
        const senders = streams[s].peerConnection.getSenders();
        const sender = senders.find((s)=> {
          if(s.track){
            return s.track.kind === videoTrack.kind
          }else {
            return false
          }
        });
        sender.replaceTrack(videoTrack)
      }
    }
  };

  const startStopVideo = () => {
    if (callStatus.video === "enabled") {
      // update redux callStatus
      dispatch(updateCallStatus("video", "disabled"));
      // set the stream is disabled
      const tracks = streams.localStream.stream.getVideoTracks();
      tracks.forEach((t) => (t.enabled = false));
    } else if (callStatus.video === "disabled") {
      // update redux callStatus
      dispatch(updateCallStatus("video", "enabled"));
      // set the stream is enabled
      const tracks = streams.localStream.stream.getVideoTracks();
      tracks.forEach((t) => (t.enabled = true));
    } else if (callStatus.haveMedia) {
      // we have the media show the feed
      smallFeedEl.current.srcObject = streams.localStream.stream;
      // add tracks to the existing peerConnections
      startLocalVideoStream(streams, dispatch);
    } else {
      setPendingUpdate(true);
    }
  };

  useEffect(() => {
    if (pendingUpdate && callStatus.haveMedia) {
      setPendingUpdate(false);
      smallFeedEl.current.srcObject = streams.localStream.stream;
      // Add tracks to the peerConnections
      startLocalVideoStream(streams, dispatch);
    }
  }, [pendingUpdate, callStatus.haveMedia, smallFeedEl, streams, dispatch]);

  return (
    <div className="button-wrapper video-button d-inline-block">
      <FaCaretUp
        className="choose-video caretBtn"
        onClick={() => setCaretOpen(!caretOpen)}
      />
      <div className="button camera" onClick={() => startStopVideo()}>
        <FaVideo />
        <div className="btn-text">
          {callStatus.video === "enabled" ? "Stop" : "Start"} Video
        </div>
      </div>
      {caretOpen ? <ActionButtonCaretDropdown  defaultValue={callStatus.videoDevice} onChangeHandler={changeVideoDevice} deviceList={videoDeviceList} type="video" /> : <></>}
    </div>
  );
};

export default VideoButton;
