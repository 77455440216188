import React, { Fragment, useEffect, useState } from "react";
import "../styles/resetPassword.scss";
import { forgotPassword } from "../assets/images";
import CustomHelmet from "../components/reusable/Helmet";
import Loader from "../components/reusable/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../components/reusable/CustomInput";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearUpdateState,
  resetPasswordAction,
} from "../redux/actions/userAction";

const ResetPassword = () => {
  const [showPassword, setshowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = data;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  // RESET PASSWORD START
  // RESET PASSWORD START
  const {
    loading,
    data: resetPasswordData,
    error,
  } = useSelector((state) => state.resetPassword);

  const handleUpdatePassword = () => {
    if (password !== confirmPassword) {
      toast.error("Password & confirm password must be same!");
    } else {
      dispatch(resetPasswordAction(token, password));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (resetPasswordData?.success) {
      toast.success(resetPasswordData?.message);
      dispatch(clearUpdateState());
      setData({
        password: "",
        confirmPassword: "",
      });
      navigate("/login");
    }
  }, [
    error,
    dispatch,
    resetPasswordData?.success,
    resetPasswordData?.message,
    navigate,
  ]);

  // RESET PASSWORD ENDS
  // RESET PASSWORD ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CustomHelmet
            keywords="Reset Password Alpha Regiment"
            description="Welcome again, Alpha Soldier, let's complete today's stuff so that tomorrow we will be free to serve the motherland."
            author="Team Alpha Regiment"
            title="Reset Password || Alpha Regiment"
          />
          <section className="resetPassword_main">
            <div className="container">
              <div className="resetPassword_form">
                <div className="login_form_inputs">
                  <div className="mobileTitle">
                    <h1>Reset Password</h1>
                  </div>
                  <div className="login_logo">
                    <img src={forgotPassword} alt="forgotPassword" />
                  </div>
                  <div className="form">
                    <div className="title">
                      <h1>Reset Password</h1>
                    </div>
                    <CustomInput
                      label="New Password"
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                      impStar="*"
                      eyeIcon={
                        showPassword ? <AiFillEyeInvisible /> : <AiFillEye />
                      }
                      setshowPassword={setshowPassword}
                    />
                    <CustomInput
                      label="Confirm New Password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={handleInputChange}
                      impStar="*"
                      eyeIcon={
                        showPassword ? <AiFillEyeInvisible /> : <AiFillEye />
                      }
                      setshowPassword={setshowPassword}
                    />

                    <button
                      className="login_submit_btn"
                      onClick={handleUpdatePassword}
                    >
                      Update
                    </button>

                    <button
                      className="go_home_btn"
                      onClick={() => navigate("/")}
                    >
                      Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Fragment>
  );
};

export default ResetPassword;
