import React, { useState } from "react";
import "../styles/termCondition.scss";
import { Link, useNavigate, NavLink } from "react-router-dom";
// import { logo_main } from "../assets/images";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomHelmet from "../components/reusable/Helmet";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";
const PhysicalCoachTerm = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Physical Partner Term and Condition Alpha Regiment"
        description="Retired military personnel who served as instructors at the Army Academy can join us. Please review the terms and conditions before applying."
        author="Team Alpha Regiment"
        title="Physical Partner Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>Physical partner term</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>PHYSICAL COACH TERMS</h1>
                <p>Last updated: March 1, 2024</p>
              </div>
              <div className="privacy_text">
                <p>
                  Welcome to Alpha Regiment, a premier platform for Armed Forces
                  Preparation! We are dedicated to maintaining the highest
                  standards of quality and professionalism. Below are the terms
                  and conditions that govern your role as a coach on our
                  platform:
                </p>
                <h3 className="sub_heading">1. Eligibility</h3>
                <p>
                  To become a coach at Alpha Regiment, you must either be a
                  retired trainer from the armed forces or possess a valid
                  certification from recognized bodies such as the Indian
                  Association of Physiotherapists (IAP), Indian Association of
                  Sports Medicine (IASM), or Indian Academy of Fitness Training
                  (IAFT).
                </p>
                <h3 className="sub_heading">2. Responsibilities</h3>
                <p>
                  As a coach, you are responsible for providing guidance and
                  support to students in the areas of exercise, nutrition, and
                  sports injury management. This includes both online and
                  offline coaching sessions.
                </p>
                <h3 className="sub_heading">3. Platform Guidelines</h3>
                <p>
                  {" "}
                  It is essential to adhere to Alpha Regiment's guidelines and
                  policies. Any attempt to bypass our platform or conduct
                  unauthorized activities may result in account termination and
                  potential legal action.
                </p>
                <h3 className="sub_heading">4. Medical Advice</h3>
                <p>
                  It is strictly prohibited to suggest or prescribe medicine or
                  drugs to students for purposes such as increasing stamina or
                  weight loss. Violation of this policy may result in immediate
                  action being taken against you.
                </p>
                <h3 className="sub_heading">5. Privacy</h3>
                <p>
                  We respect the privacy of our students. Any breach of student
                  privacy will result in the termination of your affiliation
                  with Alpha Regiment.
                </p>
                <h3 className="sub_heading">6. Feedback and Reviews</h3>
                <p>
                  The opinions and feedback of our students are highly valued.
                  Your ranking and enrollment opportunities are determined by
                  the reviews given by the students. Encourage students to
                  provide honest feedback and reviews.
                </p>
                <h3 className="sub_heading">7. Pricing</h3>
                <p>
                  You have the freedom to set your course prices. Please note
                  that Alpha Regiment charges a platform fee of 40%. Your
                  revenue will be 60% of the total course fees.
                </p>
                <h3 className="sub_heading">8. Refund Policy</h3>
                <p>
                  Alpha Regiment has a 7-day refund policy. If a student is
                  dissatisfied with your service, they can request a refund
                  within the first 7 days. Your first payout will be processed
                  after the 10th day of student enrollment.
                </p>
                <h3 className="sub_heading">9. Safety and Security</h3>
                <p>
                  If students are attending offline training at your location,
                  ensuring their safety and security is your responsibility.
                  This includes preventing injuries and addressing any safety
                  concerns.
                </p>
                <h3 className="sub_heading">10. Respect and Sensitivity</h3>
                <p>
                  As a coach, it is important to respect all students,
                  regardless of gender. Any form of discrimination or
                  insensitivity towards students will not be tolerated.
                </p>
                <p>
                  By agreeing to these terms, you acknowledge that you have read
                  and understood the guidelines set forth by Alpha Regiment. We
                  are committed to providing a safe and supportive environment
                  for both coaches and students.
                </p>
                <p>
                  Thank you for choosing Alpha Regiment as your platform for
                  physical coaching and training. Let's work together to achieve
                  our fitness goals!
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default PhysicalCoachTerm;
