export const courseSidebarLinks = [
  {
    id: 1,
    title: "Student Dashboard",
    link: "/student/lms",
  },
  // {
  //   id: 2,
  //   title: "Campus",
  //   link: "/student/campus",
  // },
  {
    id: 3,
    title: "Syllabus",
    link: "/student/syllabus",
  },
 
  {
    id: 4,
    title: "MCQs",
    link: "/student/mcqs",
  },
  {
    id: 5,
    title: "Physical",
    link: "/student/physical",
  },
  {
    id: 6,
    title: "Medical",
    link: "/student/medical",
  },
  // {
  //   id: 7,
  //   title: "Payments",
  //   link: "/student/payments",
  // },
  // {
  //   id: 8,
  //   title: "Support",
  //   link: "/student/support",
  // },
];
