import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

const MyInput = styled.div`
  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #f2f2f2;
    > .tooltip {
      margin-left: 5px;
      cursor: pointer;
      display: flex;
      > svg {
        color: #f2f2f2;
        line-height: 2;
      }
      > .tooltiptext {
        display: none;
        background: #fff;
        color: #081336;
        border-radius: 5px;
        padding: 6px;
        box-sizing: border-box;
        max-width: 200px;
        font-size: 12px;
        position: absolute;
        z-index: 9;
        font-weight: 400;
        margin-left: 14px;
      }
      &:hover {
        .tooltiptext {
          display: unset;
        }
      }
    }
    > .impStar {
      color: red;
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }
  .input_icon {
    position: relative;
    > .icon {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #a9b5bb;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }

    > .eyeIcon {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #a9b5bb;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
    > .copyIcon {
      height: 30px;
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #a9b5bb;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
    .wordLimit {
      position: absolute;
      top: 0;
      right: -25px;
      display: flex;
      align-items: center;
      justify-content: center;
      height:40px;
      background: red;
      padding:4px;
      border-radius:0px 4px 4px 0px;
      box-sizing: border-box;
      font-size: 12px;
    }
  }
  .input_text {
    display: block;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      115.85deg,
      rgba(255, 255, 255, 0.4) -185.19%,
      rgba(255, 255, 255, 0) 129.07%
    );
    backdrop-filter: blur(21px);
    border-radius: 4px;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    color: #a9b5bb;
    font-size: 14px;
    @media screen and (max-width: 575px) {
      height: 40px;
      font-size: 13px;
    }
  }
  .error_msg {
    color: red;
    font-size: 14px;
    margin: 0px 0px 20px;
  }
`;

const CustomInput = ({
  icon,
  label,
  type,
  onChange,
  placeholder,
  passwordMessage,
  name,
  value,
  impStar,
  readOnly,
  borderColor,
  accept,
  eyeIcon,
  setshowPassword,
  fullWidth,
  id,
  tooltip,
  textLength,
  copyBtn,
  handleCopy
}) => {
  return (
    <MyInput className="myInput" style={{ width: fullWidth ? "100%" : "" }}>
      {label && (
        <label>
          {label} {impStar && <abbr className="impStar">{impStar}</abbr>}
          {tooltip && (
            <div className="tooltip">
              <FaInfoCircle />
              <span className="tooltiptext">{tooltip}</span>
            </div>
          )}
        </label>
      )}
      <div className="input_icon">
        {icon && <span className="icon">{icon}</span>}
        <input
          accept={accept}
          className="input_text"
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          value={value}
          readOnly={readOnly}
          id={id}
          autoComplete="off"
          style={{
            border: borderColor && `1px solid ${borderColor}`,
            paddingLeft: icon && "50px",
          }}
        />
        {eyeIcon && (
          <span
            className="eyeIcon"
            onClick={() => setshowPassword((prevState) => !prevState)}
          >
            {eyeIcon}
          </span>
        )}
         {copyBtn && (
          <span
            className="copyIcon"
            onClick={handleCopy}
          >
            {copyBtn}
          </span>
        )}
        {textLength && (
          <p
            className="wordLimit"
            style={{
              background: value.length > textLength ? "red" : "white",
              color: value.length > textLength ? "white" : "#121D40",
            }}
          >
            {value.length}/{textLength}
          </p>
        )}
      </div>
      {passwordMessage && <p className="error_msg">{passwordMessage}</p>}
    </MyInput>
  );
};

export default CustomInput;
