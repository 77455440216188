export const doctorSidebarLinks = [
  {
    id: 1,
    title: "Profile",
    link: "/doctor/profile",
  },

  {
    id: 2,
    title: "Students",
    link: "/doctor/students",
  },

  {
    id: 3,
    title: "Revenue",
    link: "/doctor/payment-history",
  },
];
