import React, { useRef, useState } from "react";
import "../../styles/coachHome.scss";
import CustomHelmet from "../../components/reusable/Helmet";
import FranchiseNav from "../franchise/FranchiseNav";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import CustomButton from "../../components/reusable/CustomButton";
import { BsChevronRight } from "react-icons/bs";
import CoachHeroBanner from "../../images/CoachHeroBanner.gif";
import { useLocale } from "../../context/locale";
import { AiOutlineClose } from "react-icons/ai";
import { emptyCourse } from "../../assets/images";
import boostIncome from "../../images/boostIncome.png";
import exceptionalOrganization from "../../images/exceptionalOrganization.png";
import completedSteps from "../../images/completedSteps.gif";
import FocusPassion from "../../images/FocusPassion.png";
import coachtesti from "../../images/coachtesti.png";
import coachtesti1 from "../../images/coachtesti1.png";
import embraceFlexibility from "../../images/embraceFlexibility.png";
import TestimonialMobile from "../../components/reusable/TestimonialMobile";
import Carousel from "react-elastic-carousel";
import Footer from "../../components/reusable/Footer";
import CustomInput from "../../components/reusable/CustomInput";

const CoachHome = () => {
  const [t] = useLocale(); // FOR CHANGING LANGUAGE FROM CONTEXT
  const [showVideo, setShowVideo] = useState(0);

  // testimonial slider breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
  ];

  // HANDLE ONCLICK PAGE SCROLL ON FORM
  const scrollFormRef = useRef(null);

  const handleScrollToForm = () => {
    scrollFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // TESTIMONIALS FOR ========= FRANCHISE
  const testimonialArray = [
    {
      id: 1,
      videoLink: "https://www.youtube.com/embed/BEQCokuOwJ4",
      img: coachtesti,
      title: "Manmohan Singh",
      subtitle: "Physical Coach",
      text: "As a physical coach dedicated to preparing defense aspirants, working with the Alpha Regiment has been an incredible journey. The level of commitment and discipline among the aspirants is truly inspiring. Being part of their transformation into formidable candidates ready to serve our nation fills me with immense pride. The ethos of the Alpha Regiment resonates with my values, making every training session a fulfilling experience.",
    },
    {
      id: 2,
      videoLink: "https://www.youtube.com/embed/gIlhOHUuvps",
      img: coachtesti1,
      title: "Jayakumar",
      subtitle: "Training Instructor",
      text: "Training defense aspirants with the Alpha Regiment has been nothing short of exhilarating. The passion and determination displayed by these individuals are unmatched. Witnessing their progress, both physically and mentally, as they strive towards their goal of serving the country is incredibly rewarding. The camaraderie and sense of purpose within the Alpha Regiment create a motivating environment where excellence is not just encouraged but expected. It's an honor to contribute to their journey towards becoming elite defenders of our nation.",
    },
  ];

  // FORM DATA FOR SEND REQUEST
  const [formData, setFormData] = useState({
    fullName: "",
    qualification: "",
    email: "",
    experience: "",
    address: "",
  });
  const [resume, setResume] = useState();

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  // DESTRUCTURE DATA
  const { fullName, qualification, email, address, experience } = formData;

  // GET FORM INPUT DATA CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // HANDLE SUBMIT BUTTON
  const handleSubmit = () => {};

  return (
    <section className="coachHomeMain txt_color">
      <CustomHelmet
        keywords="Enhance your fitness with retired instructors at Alpha Regiment."
        description="Benefit from the renowned training techniques of Indian armed forces Ustads at Alpha Regiment, preparing you thoroughly before joining the forces."
        author="Team Alpha Regiment"
        title="Enhance your fitness with retired instructors at Alpha Regiment."
        canonical={window?.location?.href}
      />

      {/* NAVBAR START */}
      <FranchiseNav />
      {/* NAVBAR ENDS */}

      {/* HOME SECTION START */}
      <div className="hero_section_main">
        <div className="container">
          <div className="hero_layout">
            <div className="txt_sec">
              <h1>{t("shapeTomorrowHeroesTitle")}</h1>
              <CustomButton
                className="hero_btn"
                title={t("joinTeamAlphaBtn")}
                icon={<BsChevronRight />}
                onClick={handleScrollToForm}
              />
            </div>
            <div className="img_sec">
              <ImgLazyLoad src={CoachHeroBanner} alt="CoachHeroBanner" />
            </div>
          </div>
        </div>
      </div>
      {/* HOME SECTION ENDS */}

      {/* WHY ALPHA REGIMENT START */}
      <section className="why_alpha_reg">
        <div className="container">
          <div className="title">
            <h1>
              {t("whyOnlyAlpha")}
              {/* Why Alpha Regiment? */}
            </h1>
            <div></div>
          </div>

          <div className="services_cards">
            {/* SHAPE THE NATION"S FUTURE START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 1 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">
                  {t("shapeNationFutureHeading")}
                </h2>
                <p className="home_custom_para">{t("shapeNationFutureTxt")}</p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                    onClick={() => setShowVideo(1)}
                  >
                    {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img src={emptyCourse} alt="Alpha Regiment" />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* SHAPE THE NATION"S FUTURE ENDS */}

            {/* BOOST YOUR INCOME START */}
            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">{t("boostYourIncomeHeading")}</h2>
                <p className="home_custom_para">{t("boostYourIncomeTxt")}</p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 2 ? "active" : ""}`}
                    onClick={() => setShowVideo(2)}
                  >
                    {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={boostIncome}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 2 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* BOOST YOUR INCOME ENDS */}

            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 1 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">
                  {t("refineOrganizationHeading")}
                </h2>
                <p className="home_custom_para">{t("refineOrganizationTxt")}</p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                    onClick={() => setShowVideo(1)}
                  >
                    {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={exceptionalOrganization}
                      alt="Alpha Regiment virtudal campus"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION ENDS */}

            {/* EMBRACE FLEXIBILITY IN YOUR CAREER START */}
            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">
                  {t("embraceFlexibilityCareerHeading")}
                </h2>
                <p className="home_custom_para">
                  {t("embraceFlexibilityCareerTxt")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 2 ? "active" : ""}`}
                    onClick={() => setShowVideo(2)}
                  >
                    {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={embraceFlexibility}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 2 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* EMBRACE FLEXIBILITY IN YOUR CAREER ENDS */}

            {/* FOCUS ON YOUR PASSION START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 1 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">{t("focusYourPassionHeading")}</h2>
                <p className="home_custom_para">{t("focusYourPassionTxt")}</p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                    onClick={() => setShowVideo(1)}
                  >
                    {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={FocusPassion}
                      alt="Alpha Regiment virtudal campus"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* FOCUS ON YOUR PASSION ENDS */}
          </div>
        </div>
      </section>
      {/* WHY ALPHA REGIMENT ENDS */}

      {/* REPORT FOR DUTY SECTION START */}
      <div className="reportDutyMain">
        <div className="container">
          <div className="title">
            <h2>{t("reportForDutyTitle")}</h2>
          </div>

          <div className="reportDutyContent">
            <div className="leftSide">
              <img src={completedSteps} alt="Alpha Regiment report for duty" />
            </div>
            <div className="rightSide">
              <div className="steps">
                <div className="step">
                  <div>
                    <span>1</span>
                    <span>{t("reportForDutyStepOneHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepOneTxt")}
                  </span>
                </div>
                <div className="step">
                  <div>
                    <span>2</span>
                    <span>{t("reportForDutyStepTwoHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepTwoTxt")}
                  </span>
                </div>
                <div className="step">
                  <div>
                    <span>3</span>
                    <span>{t("reportForDutyStepThreeHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepThreeTxt")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* REPORT FOR DUTY SECTION ENDS */}

      {/* TESTIMONIAL SECTION START */}
      <div className="testimonilMain">
        <div className="container">
          <div className="title">
            <h2>{t("testimonial_heading")}</h2>
          </div>
          <div className="bg_color">
            {testimonialArray?.map((item) => {
              return (
                <div className="testimonial" key={item.id}>
                  <div className="img_sec">
                    {/* <img src={item.img} alt={item.text} /> */}
                    <ImgLazyLoad src={item.img} alt={item.title} />
                  </div>
                  <div className="content_sec">
                    <div className="cardTitle">
                      <h2>{item.title}</h2>
                      <p>{item.subtitle}</p>
                    </div>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            })}

            <Carousel breakPoints={breakPoints} className="testimonial_mobile">
              {testimonialArray.map((item) => {
                return (
                  <TestimonialMobile
                    key={item.id}
                    img={item.img}
                    title={item.title}
                    business={item.subtitle}
                    description={item.text}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      {/* TESTIMONIAL SECTION ENDS */}

      {/* APPLY FORM SECTION START */}
      <div className="apply_form" ref={scrollFormRef}>
        <div className="container">
          <div className="title">
            <h2>Step One - Registration</h2>
          </div>
          <div className="field_form">
            <CustomInput
              label="Name"
              type="text"
              placeholder="Ex: Ashish verma"
              name="fullName"
              impStar="*"
              value={fullName}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Qualification"
              type="text"
              placeholder="Ex: Graduate"
              name="qualification"
              impStar="*"
              value={qualification}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Email"
              type="email"
              placeholder="Ex: ashishverma123@gmail.com"
              name="email"
              impStar="*"
              value={email}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Address"
              type="text"
              placeholder="Enter your address.."
              name="address"
              impStar="*"
              value={address}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Experience"
              type="text"
              placeholder="About your experience"
              name="experience"
              impStar="*"
              value={experience}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Upload Resume"
              type="file"
              accept=".pdf"
              impStar="*"
              name="resume"
              value={resume && resume?.File?.name}
              onChange={handleFileChange}
            />
            <CustomButton
              onClick={handleSubmit}
              className="submitBtn"
              title="Submit"
            />
          </div>
        </div>
      </div>
      {/* APPLY FORM SECTION ENDS */}

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER ENDS */}
    </section>
  );
};

export default CoachHome;
