import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_CHAT_ERROR,
  CLEAR_CHAT_STATE,
  CREATE_CONVERSATION_FAIL,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  GET_MESSAGE_FAIL,
  GET_MESSAGE_REQUEST,
  GET_MESSAGE_SUCCESS,
  GET_MY_CONVERSATION_FAIL,
  GET_MY_CONVERSATION_REQUEST,
  GET_MY_CONVERSATION_SUCCESS,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAIL,
  UPDATE_MESSAGE_REQUEST,
  UPDATE_MESSAGE_SUCCESS,
} from "../constants/chatConstant";

// CREATE NEW CONVERSATION
export const createNewConversation = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_CONVERSATION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/chat/create-conversation/${userId}`,
      {},
      config
    );

    dispatch({
      type: CREATE_CONVERSATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CONVERSATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET MY CONVERSATIONS
export const getMyConversation = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_MY_CONVERSATION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/chat/my-conversations`, config);

    dispatch({
      type: GET_MY_CONVERSATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_MY_CONVERSATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// SEND NEW MESSAGE
export const newMessage = (messageData) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_MESSAGE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/chat/new-message`,
      { messageData },
      config
    );

    dispatch({
      type: SEND_MESSAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_MESSAGE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET MESSAGE BY CONVERSATION
export const getMessageByConversation =
  (conversationId) => async (dispatch) => {
    try {
      dispatch({
        type: GET_MESSAGE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.get(
        `/chat/get-messages/${conversationId}`,
        config
      );

      dispatch({
        type: GET_MESSAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MESSAGE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET MESSAGE BY CONVERSATION
export const updateMessageAction = (messageId, status) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MESSAGE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/chat/update-message/${messageId}`,
      {
        status,
      },
      config
    );

    dispatch({
      type: UPDATE_MESSAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MESSAGE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR CHAT ERRORS
export const clearChatError = () => async (dispatch) => {
  dispatch({ type: CLEAR_CHAT_ERROR });
};

// CLEAR CHAT STATE
export const clearChatState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CHAT_STATE });
};
