import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CustomHelmet from "../components/reusable/Helmet";
import { AiOutlineArrowLeft } from "react-icons/ai";
import logo from "../images/logo_main.png";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";

const AccommodationPartners = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Franchise Term and Condition Alpha Regiment"
        description="Generate passive income from your PG or home while supporting students. Join Alpha Regiment to contribute to aspiring defense candidates' success."
        author="Team Alpha Regiment"
        title="Generate passive income from your PG or home while supporting students' educational journey."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>Accommodation Partners</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>Accommodation Partners’ Terms</h1>
                <p>Last updated: February 10, 2023</p>
              </div>
              <div className="privacy_text">
                <div className="sub_heading">
                  1. Accurate Information and Facilities Maintenance
                </div>
                <ul>
                  <li>
                    Accommodation partners must provide accurate information
                    about their premises and facilities. Any changes to the
                    facilities must be communicated promptly.
                  </li>
                  <li>
                    Downgrading facilities after subscription is not permitted.
                    If downgrades occur, partners must maintain previously
                    promised facilities until the end of the subscription
                    period.
                  </li>
                  <li>
                    Upgrading facilities without increasing charges is
                    acceptable, provided the previous subscription terms are
                    honored.
                  </li>
                </ul>

                <div className="sub_heading">
                  2. Privacy and Legal Responsibilities
                </div>
                <ul>
                  <li>
                    Any breach of privacy will be considered a serious offense.
                    Alpha Regiment reserves the right to terminate or remove the
                    partner from the website in case of privacy violations.
                  </li>
                  <li>
                    In the event of legal issues arising due to privacy
                    breaches, the accommodation partner is solely responsible
                    for handling legal matters at their own expense.
                  </li>
                  <li>
                    Alpha Regiment reserves the right to pursue legal action
                    against the partner for any discrepancies found, regardless
                    of whether students report the issue directly.
                  </li>
                </ul>

                <div className="sub_heading">3. Student Reviews</div>
                <ul>
                  <li>
                    Students have the right to give honest reviews without
                    interference from accommodation partners.
                  </li>
                  <li>
                    Accommodation partners are prohibited from soliciting or
                    recording student reviews.
                  </li>
                </ul>

                <div className="sub_heading">4. Monitoring and Compliance</div>
                <ul>
                  <li>
                    Representatives from the Alpha Regiment may visit the
                    accommodation premises at any time, with or without
                    disclosure of their identity.
                  </li>
                  <li>
                    Any irregularities found may lead to the termination of
                    further business between Alpha Regiment and the
                    accommodation partner.
                  </li>
                </ul>

                <div className="sub_heading">
                  5. Student Flexibility and Payouts
                </div>
                <ul>
                  <li>
                    Students have the option to change accommodation facilities
                    if they are unsatisfied.
                  </li>
                  <li>
                    Accommodation partners will only be charged for the total
                    number of days a student stays, not for the entire month.
                  </li>
                  <li>
                    Payouts will be made to the partner's company account at the
                    end of the month or upon the student's departure, whichever
                    comes first.
                  </li>
                </ul>

                <div className="sub_heading">6. Data Handling and Privacy</div>
                <ul>
                  <li>
                    Alpha Regiment will conduct ID checks and store student data
                    securely on its servers.
                  </li>
                  <li>
                    Accommodation partners may request student contact details
                    for legitimate purposes, but Alpha Regiment reserves the
                    right to approve or deny such requests.
                  </li>
                  <li>
                    Partners cannot challenge Alpha Regiment's decisions
                    regarding the release of student data.
                  </li>
                </ul>
                <div className="sub_heading">
                  7. Accurate Account Information
                </div>
                <ul>
                  <li>
                    Accommodation partners must provide accurate details during
                    registration, including distance from the Institute, room
                    type, amenities, and other relevant information.
                  </li>
                  <li>
                    Misinformation may result in termination or non-activation
                    of the account.
                  </li>
                </ul>
                <p>
                  By registering as an accommodation partner with Alpha
                  Regiment, you agree to abide by these terms and conditions.
                  Violation of these terms may result in the termination of the
                  partnership and legal action.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default AccommodationPartners;
