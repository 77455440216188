export const officerSidebarLink = [
  {
    id: 1,
    title: "Chatbox",
    link: "/officer/timeline",
  },
  {
    id: 8,
    title: "Profile",
    link: "/officer/profile",
  },
  {
    id: 10,
    title: "Calendar",
    link: "/officer/calendar",
  },
  // {
  //   id: 2,
  //   title: "Time Table",
  //   link: "/officer/time-table",
  // },
  // {
  //   id: 3,
  //   title: "Dossiers",
  //   link: "/officer/upload-dossier",
  // },
  {
    id: 4,
    title: "Courses",
    link: "/officer/courses",
    dropdown: [
      {
        id: 41,
        title: "Create Online Courses",
        action: "Online",
      },
      {
        id: 42,
        title: "Create Offline Courses",
        action: "Offline",
      },
      {
        id: 42,
        title: "Create Resources",
        action: "Resources",
      },
      {
        id: 44,
        title: "Course List",
        action: "List",
      },
    ],
  },
  {
    id: 5,
    title: "Course Curriculum",
    link: "/officer/curriculum",
  },

  {
    id: 6,
    title: "Students",
    link: "/officer/students",
  },
  {
    id: 7,
    title: "Reviews",
    link: "/officer/reviews",
  },

  {
    id: 9,
    title: "Revenue",
    link: "/officer/payment-history",
  },
  // {
  //   id: 10,
  //   title: "Social Links",
  //   link: "/officer/social-links",
  // },
  // {
  //   id: 11,
  //   title: "Note",
  //   link: "/officer/note",
  // },
  // {
  //   id: 12,
  //   title: "Live Classes",
  //   link: "/officer/live-classes",
  // },
];
