import React, { Fragment } from "react";
import "../../styles/studentCampus.scss";
import StudentFooter from "../../components/student/StudentFooter";
import StudentLayout from "../../components/student/StudentLayout";
import { courseSidebarLinks } from "./courseSidebarLinks";
import qr_img from "../../images/qr_img.png";
import { ImAndroid, ImAppleinc } from "react-icons/im";
import { Link } from "react-router-dom";
import { MdInstallDesktop } from "react-icons/md";
import campusCardBg from "../../images/campusCardBg.png"

const StudentCampus = () => {
  return (
    <Fragment>
      <StudentLayout sidebarLinks={courseSidebarLinks}>
        <div className="metaverse_campus_main txt_color">
          <img src={campusCardBg} alt="campusCardBg" className="absolute_image" />

          <div className="title">
            <h2>Metaverse Campus</h2>
          </div>

          <div className="qr_content">
            <div className="android_qr">
              <img src={qr_img} alt="qr_img" />
              <p>Scan QR code for joining campus on Android</p>
              <ImAndroid />
            </div>
            <div className="ios_qr">
              <img src={qr_img} alt="qr_img" />
              <p>Scan QR code for joining campus on IOS</p>
              <ImAppleinc />
            </div>
          </div>

          <div className="desktop_link">
            <div className="title">
              <h2>Desktop Link</h2>
            </div>
            <Link to="/">
              http://alpharegimentm_metaverse campus
              <MdInstallDesktop />
            </Link>
          </div>
        </div>
      </StudentLayout>
      {/* FOOTER START */}
      <StudentFooter />
      {/* FOOTER ENDS */}
    </Fragment>
  );
};

export default StudentCampus;
