import React, { useState } from "react";
import styled from "styled-components";
import { FaVideo } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import CustomButton from "../../components/reusable/CustomButton";
import { toast } from "react-toastify";

const PopupMain = styled.section`
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
  transition: 0.4s;
  box-sizing: border-box;
  padding: 0;

  &.show_popup {
    top: 0;
    left: 0;
  }

  > .closeBtn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    transition: 0.4s;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #081336;
    z-index: 2;
    border: 1px solid #f2f2f2;

    &:hover {
      background-color: #081336;
      color: #f2f2f2;
    }
  }

  > .students_content {
    width: 720px;
    background: #081336;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 767px) {
      width: 540px;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding-top: 60px;
    }

    > .generalDetails,
    > .statusRow {
      display: flex;
      align-items: center;
      column-gap: 20px;
      margin-bottom: 15px;

      @media screen and (max-width: 767px){
        flex-direction: column;
        row-gap: 15px;
        align-items: start;
      }

      > div {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px){
          display: unset;
        }

        > span {
          &:first-child {
            margin-right: 5px;
            font-weight: 700;
            white-space: nowrap;
            letter-spacing: 2px;
            font-size: 18px;
          }
        }
      }
    }

    >.date_schedule{
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      @media screen and (max-width: 767px){
        display: unset;
      }

      > span {
        &:first-child {
          margin-right: 5px;
          font-weight: 700;
          white-space: nowrap;
          letter-spacing: 2px;
          font-size: 18px;
        }
    }

  }
  >.buttonSec{
    display: flex;
    align-items: center;
    justify-content: center;

    >button{
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      transition: 0.4s;
      border-radius: 5px;
      background-color: #f2f2f2;
      color: #081336;
      z-index: 2;
      border: 1px solid #f2f2f2;
      padding: 7px 15px;
      text-transform: uppercase;
  
      &:hover {
        background-color: #081336;
        color: #f2f2f2;
      }

      &:not(:last-child){
        margin-right: 20px;
      }
    }
  }

  >.feedbackTextArea{
    padding: 20px 0;

    >.feedbackBtn{
      margin-right: auto;
      margin-left: auto;
      display: block;
    }
  }

  .red {
    color: red;
    font-weight: 500;
  }

  .green {
    color: green;
    font-weight: 500;
  }

  .white {
    color: #f2f2f2;
  }
`;

const EnrolledStudentDetailsPopup = ({ showPopup, setShowPopup ,setSchedulePopUp}) => {
  const [feedbackPopup, setfeedbackPopup] = useState(false);

  function handleModalClose() {
    setShowPopup(false);
    setfeedbackPopup(false);
  }
  return (
    <PopupMain
      className={`enrolled_studentsDetailsModal ${
        showPopup ? "show_popup" : ""
      }`}
    >
      <button
        title="Close Popup"
        className="closeBtn"
        onClick={handleModalClose}
      >
        <IoClose />
      </button>
      {showPopup && (
        <div className="students_content">
          <div className="generalDetails">
            <div className="student_name">
              <span>Student name:</span> <span>Pankaj Yadav</span>
            </div>
            <div className="course_name">
              <span>Course Name:</span> <span>Shaurya GD Full Course</span>
            </div>
          </div>
          <div className="statusRow">
            <div className="date_enrolled">
              <span>Date Enrolled:</span> <span>September 22, 2023</span>
            </div>
            <div className="status">
              <span>Status:</span> <span className="red">Pending</span>
            </div>
          </div>
          <div className="date_schedule">
            <span>Schedule Date/Time:</span>{" "}
            <span>Monday, October 10, 2023 at 05:00 PM</span>
          </div>
          <div className="buttonSec">
            <button
              title="Give Feedback"
              onClick={() => setfeedbackPopup(!feedbackPopup)}
            >
              Give Feedback
            </button>
            <button title="Personal Information Questionnaire">PIQ</button>
            <button title="Video Call">
              <FaVideo />
            </button>
            <button title="Schedule" onClick={setSchedulePopUp}>
             Re-Schedule
            </button>
          </div>
          {feedbackPopup && (
            <div className="feedbackTextArea">
              <div className="closeFeedbackForm"></div>
              <CustomTextarea
                // label="Feedback"
                type="text"
                // onChange=""
                placeholder={`Please write a feedback for Pankaj Yadav in maximum 1000 words.`}
                name="feedBack"
                fullWidth
                rows="6"
              />
              <CustomButton
                className="feedbackBtn"
                title="Sumit Feedback"
                onClick={() => {
                  toast.success("Feedback Submitted");
                  setfeedbackPopup(false);
                }}
                disabled={false}
              />
            </div>
          )}
        </div>
      )}
    </PopupMain>
  );
};

export default EnrolledStudentDetailsPopup;
