import React, { useRef, useState } from "react";
import "../styles/accomodationHomeMain.scss";
import CustomHelmet from "../components/reusable/Helmet";
import FranchiseNav from "./franchise/FranchiseNav";
import ImgLazyLoad from "../components/reusable/ImgLazyLoad";
import CustomButton from "../components/reusable/CustomButton";
import { BsChevronRight } from "react-icons/bs";
import { useLocale } from "../context/locale";
import { AiOutlineClose } from "react-icons/ai";
import exceptionalOrganization from "../images/exceptionalOrganization.png";
import completedSteps from "../images/completedSteps.gif";
import writingRoom from "../images/writingRoom.gif";
import verifiedStudents from "../images/verifiedStudents.png";
import zeroCommission from "../images/zeroCommission.png";
import pgOwner1 from "../images/pgOwner1.jpeg";
import pgOwner2 from "../images/pgOwner2.jpeg";
import TestimonialMobile from "../components/reusable/TestimonialMobile";
import Carousel from "react-elastic-carousel";
import Footer from "../components/reusable/Footer";
import CustomInput from "../components/reusable/CustomInput";
import CustomSelect from "../components/reusable/CustomSelect";
import { IoVideocam } from "react-icons/io5";

const AccommodationHome = () => {
  const [t] = useLocale(); // FOR CHANGING LANGUAGE FROM CONTEXT
  const [showVideo, setShowVideo] = useState(0);

  // testimonial slider breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
  ];

  // HANDLE ONCLICK PAGE SCROLL ON FORM
  const scrollFormRef = useRef(null);

  const handleScrollToForm = () => {
    scrollFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // TESTIMONIALS
  const testimonialArray = [
    {
      id: 1,
      videoLink: "https://www.youtube.com/embed/BEQCokuOwJ4",
      img: pgOwner1,
      title: "Aarav Patel",
      subtitle: "PG Owner",
      text: "Our association with the Alpha Regiment has been incredibly rewarding. Hosting driven and disciplined students has significantly boosted our occupancy rates and income. The support and seamless coordination from the Alpha Regiment team have made this partnership a resounding success.",
    },
    {
      id: 2,
      videoLink: "https://www.youtube.com/embed/gIlhOHUuvps",
      img: pgOwner2,
      title: "Devika Sharma",
      subtitle: "PG Owner",
      text: "Being part of Alpha Regiment has been a game-changer for us. We host focused, ambitious students with solid backgrounds, resulting in a remarkable increase in our occupancy rates and revenue. The coordination and services provided by the Alpha Regiment team have been exceptional, making our partnership incredibly rewarding.",
    },
  ];

  // FORM DATA FOR SEND REQUEST
  const [formData, setFormData] = useState({
    fullName: "",
    wantToRent: "",
    email: "",
    numberOfRooms: "",
    address: "",
  });
  const [roomImages, setRoomImages] = useState();

  const handleFileChange = (e) => {
    setRoomImages(e.target.files[0]);
  };

  // DESTRUCTURE DATA
  const { fullName, wantToRent, email, address, numberOfRooms } = formData;

  // GET FORM INPUT DATA CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // HANDLE SUBMIT BUTTON
  const handleSubmit = () => {};

  return (
    <section className="accomodationHomeMain txt_color">
      <CustomHelmet
        keywords="Generate passive income from PG / Home while Supporting the students."
        description="Generate passive income from your PG or home while supporting students. Join Alpha Regiment and contribute to the future of students."
        author="Team Alpha Regiment"
        title="Generate passive income from PG / Home while Supporting the students."
        canonical={window?.location?.href}
      />

      {/* NAVBAR START */}
      <FranchiseNav />
      {/* NAVBAR ENDS */}

      {/* HOME SECTION START */}
      <div className="hero_section_main">
        <div className="container">
          <div className="hero_layout">
            <div className="txt_sec">
              <h1>{t("accommodationHomeTitle")}</h1>
              <CustomButton
                className="hero_btn"
                title={t("joinTeamAlphaBtn")}
                icon={<BsChevronRight />}
                onClick={handleScrollToForm}
              />
            </div>
            <div className="img_sec">
              <ImgLazyLoad src={writingRoom} alt="writingRoom" />
            </div>
          </div>
        </div>
      </div>
      {/* HOME SECTION ENDS */}

      {/* WHY ALPHA REGIMENT START */}
      <section className="why_alpha_reg">
        <div className="container">
          <div className="title">
            <h1>
              {t("whyOnlyAlpha")}
              {/* Why Alpha Regiment? */}
            </h1>
            <div></div>
          </div>

          <div className="services_cards">
            {/* SHAPE THE NATION"S FUTURE START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 1 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">
                  {t("accommodationWhyAlphaTitle1")}
                </h2>
                <p className="home_custom_para">
                  {t("accommodationWhyAlphaTxt1")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                    onClick={() => setShowVideo(1)}
                  >
                     <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={zeroCommission}
                      alt="Alpha Regiment zeroCommission"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* SHAPE THE NATION"S FUTURE ENDS */}

            {/* BOOST YOUR INCOME START */}
            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">
                  {t("accommodationWhyAlphaTitle2")}
                </h2>
                <p className="home_custom_para">
                  {t("accommodationWhyAlphaTxt2")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 2 ? "active" : ""}`}
                    onClick={() => setShowVideo(2)}
                  >
                     <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={verifiedStudents}
                      alt="Alpha Regiment verifiedStudents"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 2 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* BOOST YOUR INCOME ENDS */}

            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 1 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">
                  {t("accommodationWhyAlphaTitle3")}
                </h2>
                <p className="home_custom_para">
                  {t("accommodationWhyAlphaTxt3")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                    onClick={() => setShowVideo(1)}
                  >
                     <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={exceptionalOrganization}
                      alt="Alpha Regiment virtudal campus"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION ENDS */}
          </div>
        </div>
      </section>
      {/* WHY ALPHA REGIMENT ENDS */}

      {/* REPORT FOR DUTY SECTION START */}
      <div className="reportDutyMain">
        <div className="container">
          <div className="title">
            <h2>{t("reportForDutyTitle")}</h2>
          </div>

          <div className="reportDutyContent">
            <div className="leftSide">
              <img src={completedSteps} alt="Alpha Regiment report for duty" />
            </div>
            <div className="rightSide">
              <div className="steps">
                <div className="step">
                  <div>
                    <span>1</span>
                    <span>{t("reportForDutyStepOneHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepOneTxt")}
                  </span>
                </div>
                <div className="step">
                  <div>
                    <span>2</span>
                    <span>{t("reportForDutyStepTwoHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepTwoTxt")}
                  </span>
                </div>
                <div className="step">
                  <div>
                    <span>3</span>
                    <span>{t("reportForDutyStepThreeHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepThreeTxt")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* REPORT FOR DUTY SECTION ENDS */}

      {/* TESTIMONIAL SECTION START */}
      <div className="testimonilMain">
        <div className="container">
          <div className="title">
            <h2>{t("testimonial_heading")}</h2>
          </div>
          <div className="bg_color">
            {testimonialArray?.map((item) => {
              return (
                <div className="testimonial" key={item.id}>
                  <div className="img_sec">
                    {/* <img src={item.img} alt={item.text} /> */}
                    <ImgLazyLoad src={item.img} alt={item.title} />
                  </div>
                  <div className="content_sec">
                    <div className="cardTitle">
                      <h2>{item.title}</h2>
                      <p>{item.subtitle}</p>
                    </div>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            })}

            <Carousel breakPoints={breakPoints} className="testimonial_mobile">
              {testimonialArray.map((item) => {
                return (
                  <TestimonialMobile
                    key={item.id}
                    img={item.img}
                    title={item.title}
                    business={item.subtitle}
                    description={item.text}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      {/* TESTIMONIAL SECTION ENDS */}

      {/* APPLY FORM SECTION START */}
      <div className="apply_form" ref={scrollFormRef}>
        <div className="container">
          <div className="title">
            <h2>Step One - Registration</h2>
          </div>
          <div className="field_form">
            <CustomInput
              label="Name"
              type="text"
              placeholder="Ex: Ashish verma"
              name="fullName"
              impStar="*"
              value={fullName}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Email"
              type="email"
              placeholder="Ex: ashishverma123@gmail.com"
              name="email"
              impStar="*"
              value={email}
              onChange={handleInputChange}
            />

            <CustomSelect
              label="Want to rent"
              name="wantToRent"
              onChange={handleInputChange}
              optionArray={[{ option: "Home" }, { option: "PG" }]}
              impStar="*"
              value={wantToRent}
            />

            <CustomInput
              label="No of rooms"
              type="number"
              placeholder="No of rooms"
              name="numberOfRooms"
              impStar="*"
              value={numberOfRooms}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Address"
              type="text"
              placeholder="Enter your address.."
              name="address"
              impStar="*"
              value={address}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Upload images of rooms and washrooms"
              type="file"
              accept="image/*"
              impStar="*"
              name="roomImages"
              value={roomImages && roomImages?.File?.name}
              onChange={handleFileChange}
            />
            <CustomButton
              onClick={handleSubmit}
              className="submitBtn"
              title="Submit"
            />
          </div>
        </div>
      </div>
      {/* APPLY FORM SECTION ENDS */}

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER ENDS */}
    </section>
  );
};

export default AccommodationHome;
