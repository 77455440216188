import React, { Fragment } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/home/Home";
import Contact from "./pages/Contact";
import CourseYouJoin from "./pages/CourseYouJoin";
import AllCourses from "./pages/AllCourses";
// import CourseDetails from "./pages/CourseDetails";
import Login from "./pages/Login";
import PaymentOptions from "./pages/PaymentOptions";
import VerificationPage from "./pages/VerificationPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import StudentProfile from "./pages/StudentProfile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy.js";
import TermCondition from "./pages/TermCondition";
import ScrollToTop from "./components/reusable/ScrollToTop";
import PageNotFound from "./pages/404";
import AboutUs from "./pages/AboutUs";
import FranchiseHome from "./pages/franchise/FranchiseHome";
// import Private from "./components/privateRoutes/Private";
import StudentLMS from "./pages/student/StudentLMS";
import StudentCMS from "./pages/student/StudentCMS";
import StudentPhysical from "./pages/student/StudentPhysical";
import { useSelector } from "react-redux";
import Loader from "./components/reusable/Loader";
import RedirectSpinner from "./components/privateRoutes/RedirectSpinner";
import StudentMedical from "./pages/student/StudentMedical";
import StudentCampus from "./pages/student/StudentCampus";
import LandingPage from "./pages/LandingPage";
import Welcome from "./pages/Welcome";
import Workshop99 from "./pages/Workshop99";
import CyberPartner from "./pages/cyber-partner/CyberPartner";
import AffiliateProfile from "./pages/affiliate/AffiliateProfile";
import AffiliateCourseLinks from "./pages/affiliate/AffiliateCourseLinks";
import AffiliateFeedback from "./pages/affiliate/AffiliateFeedback";
import AffiliateDashboard from "./pages/affiliate/AffiliateDashboard";
import RavinderVisitingCard from "./pages/visitingCards/RavinderVisitingCard.js";
import DilbaagVisitingCard from "./pages/visitingCards/DilbaagVisitingCard.js";
import AffiliateAnalytics from "./pages/affiliate/AffiliateAnalytics";
import StudentPayments from "./pages/student/StudentPayments";
import Cart from "./pages/cart/Cart";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import SsbCourses from "./pages/ssb/SsbCourses";
import OfficerProfile from "./pages/officer/OfficerProfile";
import OfficerTerms from "./pages/officer/OfficerTerms";
import OfficerPaymentHistory from "./pages/officer/OfficerPaymentHistory";
import OfficerCourseReviews from "./pages/officer/OfficerCourseReviews";
import OfficerStudentList from "./pages/officer/OfficerStudentList";
import OfficerCourseList from "./pages/officer/OfficerCourseList";
import OfficerTimeline from "./pages/officer/OfficerTimeline";
import OfficerTimeTable from "./pages/officer/OfficerTimeTable";
import UploadDossier from "./pages/officer/UploadDossier";
import CourseDetail from "./pages/CourseDetail";
import EnrollCoures from "./pages/student/EnrollCoures.js";
import ArTeacherProfile from "./pages/arTeacher/arTeacherProfile.js";
import ArTeacherTimeTable from "./pages/arTeacher/arTeacherTimeTable.js";
import OtherTeacherProfile from "./pages/otherTeacher/otherTeacherProfile.js";
import OtherTeacherEarning from "./pages/otherTeacher/otherTeacherEarning.js";
// import TermOfUse from "./pages/termOfUse.js";
import TeacherTerms from "./pages/teacherTerms.js";
import PropertyPolicy from "./pages/propertyPolicy.js";
import MedicalPartnerTerm from "./pages/MedicalPartnerTerm.js";
import CyberPartnerTerm from "./pages/CyberPartnerTerm.js";
import PhysicalCoachTerm from "./pages/PhysicalCoachTerm.js";
import FrenchiTerm from "./pages/FrenchiTerm.js";
import AccommodationPartners from "./pages/AccommodationPartners.js";
import ArTeacherSocial from "./pages/arTeacher/arTeacherSocialMedia.js";
import OfficerRegister from "./pages/OfficerRegister.js";
import TeacherRegister from "./pages/TeacherRegister.js";
import Register from "./pages/Register.js";
import StudentRegister from "./pages/StudentRegister.js";
import CreateTimeTable from "./pages/officer/CreateTimeTable.js";
// import OfficerSocialLinks from "./pages/officer/OfficerSocialLinks.js";
import PhycoDossierTatCreate from "./pages/officer/PhycoDossierTatCreate.js";
import SsbCourseDetails from "./pages/ssb/SsbCourseDetails.js";
import PhycoDossierWatCreate from "./pages/officer/PhycoDossierWatCreate.js";
import OfficerNote from "./pages/officer/OfficerNote.js";
import OfficerEditCourse from "./pages/officer/OfficerEditCourse.js";
import NotificationPrompt from "./components/reusable/NotificationPrompt.js";
import CourseRating from "./pages/CourseRating.js";
import PhycoDossierSrtCreate from "./pages/officer/PhyscoDossierSrtCreate.js";
import PhycoDossierSdCreate from "./pages/officer/PhyscoDossierSdCreate.js";
import ViewDossier from "./pages/officer/ViewDossier.js";
import CreateDossier from "./pages/officer/CreateDossier.js";
import GTOCreateCourse from "./pages/officer/GTOCreateCourse.js";
// import IoCreateCourse from "./pages/officer/IoCreateCourse.js";
// import PsychoCreateCourse from "./pages/officer/PsychologyCreateCourse.js";
import CourseCurriculum from "./pages/officer/CourseCurriculum.js";
import AddCurriculum from "./pages/officer/AddCurriculum.js";
import WishList from "./pages/cart/WishList.js";
import LiveClassCreate from "./pages/officer/LiveClassCreate.js";
import MeetingJoin from "./pages/MeetingJoin.js";
import LiveClassList from "./pages/officer/LiveClassList.js";
import StudentLiveClasses from "./pages/student/StudentLiveClasses.js";
import ArSchoolTerm from "./pages/ArScoolTerm.js";
import CoachHome from "./pages/coach/CoachHome.js";
import TeacherHome from "./pages/TeacherHome.js";
import MedicalHome from "./pages/MedicalHome.js";
import AccommodationHome from "./pages/AccommodationHome.js";
import OfficerHome from "./pages/OfficerHome.js";
import AlphaSchool from "./pages/AlphaSchool.js";
import MockStudent from "./pages/student/MockStudent.js";
// import MockTestList from "./pages/student/MockTestList.js";
import { useAuth } from "./context/userContext.js";
import EditDossier from "./pages/officer/EditDossier.js";
import OfficerFollowing from "./pages/officer/officerTimeline/OfficerFollowing.js";
import OfficerFollower from "./pages/officer/officerTimeline/OfficerFollower.js";
// import FollowRequest from "./pages/officer/officerTimeline/FollowRequest.js";
import ViewProfile from "./pages/officer/officerTimeline/ViewProfile.js";
import ConnectionRequest from "./pages/officer/officerConnection/ConnectionRequest.js";
import OfficerCalender from "./pages/officer/OfficerCalender.js";
import StudentPiq from "./pages/student/StudentPiq.js";
import ScheduleInterview from "./pages/student/ScheduleInterview.js";
import StudentChatbox from "./pages/student/StudentChatbox.js";
import ShareProfile from "./pages/ShareProfile.js";
import BlogDetail from "./pages/student/BlogDetail.js";
import CreateWorkingHrs from "./pages/officer/CreateWorkingHrs.js";
import OtherTeacherTimeline from "./pages/otherTeacher/otherTeacherChatbox.js";
import OtherTeacherCalender from "./pages/otherTeacher/otherTeacherTimeTable.js";
import OTeacherCourseList from "./pages/otherTeacher/OTeacherCourseList.js";
import OTeacherCurriculum from "./pages/otherTeacher/OTeacherCurriculum.js";
import OTeacherAddCurriculum from "./pages/otherTeacher/OTeacherAddCurriculum.js";
// import OTeacherStudents from "./pages/otherTeacher/OTeacherStudents.js";
import OfficerSchedule from "./pages/officer/officerSchedule.js";
import EditWorkingHrs from "./pages/officer/EditWorkinghrs.js";
import OCourseCreate from "./pages/otherTeacher/OCourseCreate.js";
import OFollowerList from "./pages/otherTeacher/OFollowerList.js";
import OFollowing from "./pages/otherTeacher/OFollowList.js";
import OConnectionList from "./pages/otherTeacher/OConnectionList.js";
import OTeacherReviews from "./pages/otherTeacher/OTeacherReviews.js";
import SsbCourseRating from "./pages/ssb/SsbCourseRating.js";
import OTeacherMcqList from "./pages/otherTeacher/otherTeacherMcq.js";
import OTeacherMcqCreate from "./pages/otherTeacher/OTeacherMcqCreate.js";
import DoctorProfile from "./pages/doctor/DoctorProfile.js";
import DoctorStudentList from "./pages/doctor/DoctorStudentList.js";
import DoctorRevenue from "./pages/doctor/doctorRevenue.js";
import OCourseUpdate from "./pages/otherTeacher/OTeacherCourseEdit.js";
import ChatInterface from "./pages/chat/ChatInterface.js";
import SsbCoaching from "./pages/ssb/SsbCoaching.js";
import MockSsbInterview from "./pages/ssb/MockSsbInterview.js";
import MockInterviewCourse from "./pages/ssb/MockInterviewCourse.js";
import MockPsychology from "./pages/ssb/MockPsychology.js";
import SpokenEnglish from "./pages/ssb/SpokenEnglish.js";
import MedicalCheckups from "./pages/ssb/MedicalCheckups.js";
import FitnessCoach from "./pages/ssb/FitnessCoach.js";
import MockGto from "./pages/ssb/MockGto.js";
import QuizAttempt from "./components/home/QuizAttempt.js";
// import Sitemap from "./sitemap.js";
import CallerVideoPage from "./pages/webRTC/videoComponents/CallerVideoPage.js";
import ProMainVideoPage from "./pages/webRTC/videoComponents/ProMainVideoPage.js";
import Sitemap from "./pages/sitemap.js";
import MetaverseCampus from "./components/home/MetaverseCampus.js";
import OTeacherMcqEdit from "./pages/otherTeacher/OTeacherMcqEdit.js";
// import Sitemap from "./sitemap.js";
// import { loginWithGoogle } from "./redux/actions/userAction";

const App = () => {
  const { loading, isAuthenticated, userInfo } = useSelector(
    (state) => state.profile
  );
  const [auth] = useAuth();
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg_color">
          <ToastContainer />
          <ScrollToTop />
          <NotificationPrompt />
          {/*NOTIFICATION MSG */}
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            {/* REGISTER ROUTES */}
            <Route path="/register" element={<Register />} />
            <Route path="/register/student" element={<StudentRegister />} />
            <Route path="/register/officer" element={<OfficerRegister />} />
            <Route path="/register/teacher" element={<TeacherRegister />} />
            <Route path="/payment-options" element={<PaymentOptions />} />
            <Route path="/verification" element={<VerificationPage />} />
            <Route path="/check-eligible-course" element={<CourseYouJoin />} />
            <Route path="/courses" element={<AllCourses />} />
            {/* <Route path="/ssb-gpt" element={<GptSearch />} /> */}

            <Route path="ssb-in-metaverse" element={<MetaverseCampus />} />
            <Route
              path="/ssb-course/rating/:id"
              element={<SsbCourseRating />}
            />
            <Route path="/ssb-courses" element={<SsbCourses />} />
            <Route path="/ssb-coaching" element={<SsbCoaching />} />
            <Route path="/mock-ssb-interview" element={<MockSsbInterview />} />
            <Route path="/mock-interview" element={<MockInterviewCourse />} />
            <Route path="/mock-psychology" element={<MockPsychology />} />
            <Route path="/mock-gto" element={<MockGto />} />
            <Route path="/spoken-english" element={<SpokenEnglish />} />
            <Route path="/medical-checkups" element={<MedicalCheckups />} />
            <Route path="/fitness-coach" element={<FitnessCoach />} />
            <Route
              path="/ssb-course-details/:id"
              element={<SsbCourseDetails />}
            />
            <Route path="/live-join/:role" element={<MeetingJoin />} />
            {/* <Route path="/course-details/:id" element={<CourseDetails />} /> */}
            <Route
              path="/course-detail/:query/:id"
              element={<CourseDetail />}
            />
            <Route path="/course/rating/:id" element={<CourseRating />} />
            <Route path="/property-policies" element={<PropertyPolicy />} />
            <Route path="/privacy-policies" element={<PrivacyPolicy />} />
            <Route path="/refund-policies" element={<RefundPolicy />} />
            <Route path="/term-conditions" element={<TermCondition />} />
            <Route
              path="/ar-school-term-conditions"
              element={<ArSchoolTerm />}
            />
            {/* <Route path="/term-of-use" element={<TermOfUse />} /> */}
            <Route path="/teacher-term-conditions" element={<TeacherTerms />} />
            <Route path="/officer-term-conditions" element={<OfficerTerms />} />
            <Route
              path="/medical-partner-term-conditions"
              element={<MedicalPartnerTerm />}
            />
            <Route
              path="/cyber-partner-term-conditions"
              element={<CyberPartnerTerm />}
            />
            <Route
              path="/physical-coach-term-conditions"
              element={<PhysicalCoachTerm />}
            />
            <Route
              path="/franchise-partner-term-conditions"
              element={<FrenchiTerm />}
            />
            <Route
              path="/accommodation-partners"
              element={<AccommodationPartners />}
            />
            <Route path="/view-profile/:id" element={<ShareProfile />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/shaurya-courses" element={<LandingPage />} />
            <Route path="/online-workshop" element={<Workshop99 />} />
            <Route path="/ravinder" element={<RavinderVisitingCard />} />
            <Route path="/dilbaag" element={<DilbaagVisitingCard />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/teacher/home" element={<TeacherHome />} />
            <Route path="/officer/home" element={<OfficerHome />} />
            <Route path="/alpha-school" element={<AlphaSchool />} />
            <Route path="/medical/home" element={<MedicalHome />} />
            <Route path="/accommodation/home" element={<AccommodationHome />} />
            <Route path="/blogs" element={<StudentCMS />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/quiz/:id" element={<QuizAttempt />} />

            <Route path="/sitemap" element={<Sitemap />} />
            {/* CHAT FUNCTIONALITY START */}
            {isAuthenticated && (
              <>
                <Route path="/chat-interface" element={<ChatInterface />} />
                <Route path="/join-call" element={<CallerVideoPage />} />
                <Route path="/join-video-pro" element={<ProMainVideoPage />} />
              </>
            )}
            {/* CHAT FUNCTIONALITY ENDS */}
            {isAuthenticated && (
              <Route path="friend-profile/:id" element={<ViewProfile />} />
            )}
            {/* COMMON ROUTES FOR ROLE 13 , 16 , 17, 18  STARTS */}
            {(auth?.user?.role === "13" ||
              auth?.user?.role === "16" ||
              auth?.user?.role === "17" ||
              auth?.user?.role === "18") && (
              <>
                <Route
                  path="/update-working-hrs"
                  element={<EditWorkingHrs />}
                />
                <Route
                  path="/create-working-hrs"
                  element={<CreateWorkingHrs />}
                />
              </>
            )}
            {/* COMMON ROUTES FOR ROLE 13 , 16 , 17, 18  ENDS */}
            {/* LOGGED_IN STUDENT ROUTES */}
            <Route
              path="/student"
              element={
                (isAuthenticated && userInfo?.user?.role === "10") ||
                userInfo?.user?.role === "11" ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="profile" element={<StudentProfile />} />
              <Route path="enrolled-courses" element={<EnrollCoures />} />
              <Route path="lms/:courseId" element={<StudentLMS />} />

              <Route path="chatbox" element={<StudentChatbox />} />
              <Route path="attend-test" element={<MockStudent />} />
              {/* <Route path="mock-test" element={<MockTestList />} /> */}
              <Route path="physical" element={<StudentPhysical />} />
              <Route path="medical" element={<StudentMedical />} />
              <Route path="campus" element={<StudentCampus />} />
              <Route path="payments" element={<StudentPayments />} />
              <Route path="piq" element={<StudentPiq />} />
              <Route path="schedule" element={<ScheduleInterview />} />
              <Route path="cart" element={<Cart />} />
              <Route path="wishlist" element={<WishList />} />
              <Route path="course-classes" element={<StudentLiveClasses />} />
            </Route>
            {/* DOCTOR ROUTES */}
            <Route
              path="/doctor"
              element={
                isAuthenticated && userInfo?.user?.role === "19" ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="profile" element={<DoctorProfile />} />
              <Route path="students" element={<DoctorStudentList />} />
              <Route path="payment-history" element={<DoctorRevenue />} />
            </Route>
            {/* OFFICER'S ROUTES */}
            <Route
              path="/officer"
              element={
                isAuthenticated &&
                (userInfo?.user?.role === "16" ||
                  userInfo?.user?.role === "17" ||
                  userInfo?.user?.role === "18") ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="profile" element={<OfficerProfile />} />
              {userInfo?.user?.isVerified && (
                <>
                  <Route
                    path="payment-history"
                    element={<OfficerPaymentHistory />}
                  />
                  <Route path="note" element={<OfficerNote />} />
                  <Route path="calendar" element={<OfficerCalender />} />

                  <Route
                    path="create-timetable"
                    element={<CreateTimeTable />}
                  />
                  <Route
                    path="interview-schedule"
                    element={<OfficerSchedule />}
                  />
                  <Route path="create-class" element={<LiveClassCreate />} />
                  <Route path="live-classes" element={<LiveClassList />} />
                  <Route path="reviews" element={<OfficerCourseReviews />} />
                  <Route path="students" element={<OfficerStudentList />} />
                  <Route path="courses" element={<OfficerCourseList />} />
                  <Route
                    path="create-course"
                    element={
                      // userInfo?.user?.role === "16" ? (
                      <GTOCreateCourse />
                      // ) : userInfo?.user?.role === "17" ? (
                      //   <IoCreateCourse />
                      // ) : (
                      //   <PsychoCreateCourse />
                      // )
                    }
                  />
                  <Route path="curriculum" element={<CourseCurriculum />} />
                  <Route
                    path="create-curriculum/:id"
                    element={<AddCurriculum />}
                  />
                  <Route
                    path="update-course/:id"
                    element={<OfficerEditCourse />}
                  />
                  {/* TIMELINE ROUTES STARTS */}
                  <Route path="timeline" element={<OfficerTimeline />} />

                  <Route path="following" element={<OfficerFollowing />} />
                  <Route path="follower" element={<OfficerFollower />} />
                  <Route path="connection" element={<ConnectionRequest />} />
                  {/* <Route path="follow-request" element={<FollowRequest />} /> */}
                  {/* TIMELINE ROUTES ENDS */}
                  <Route path="time-table" element={<OfficerTimeTable />} />
                  <Route path="live-classes" element={<LiveClassCreate />} />

                  <Route path="upload-dossier" element={<UploadDossier />} />
                  <Route path="create-dossier" element={<CreateDossier />} />
                  <Route
                    path="physco-dossier-tat-create"
                    element={<PhycoDossierTatCreate />}
                  />
                  <Route
                    path="physco-dossier-wat-create"
                    element={<PhycoDossierWatCreate />}
                  />
                  <Route
                    path="physco-dossier-srt-create"
                    element={<PhycoDossierSrtCreate />}
                  />
                  <Route
                    path="physco-dossier-sdt-create"
                    element={<PhycoDossierSdCreate />}
                  />
                  <Route path="view-dossier/:id" element={<ViewDossier />} />
                  <Route path="edit-dossier/:id" element={<EditDossier />} />
                </>
              )}
            </Route>
            {/* AFFILIATE ROUTES */}
            <Route path="/cyber-partner" element={<CyberPartner />} />
            <Route
              path="/affiliate"
              element={
                isAuthenticated && userInfo?.user?.role === "15" ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="profile" element={<AffiliateProfile />} />
              <Route path="dashboard" element={<AffiliateDashboard />} />
              <Route path="course-links" element={<AffiliateCourseLinks />} />
              <Route
                path="analytics-payments"
                element={<AffiliateAnalytics />}
              />
              <Route path="feedback" element={<AffiliateFeedback />} />
            </Route>
            {/* AR TEACHER ROUTES */}
            <Route
              path="/ar-teacher"
              // element={
              //   isAuthenticated && userInfo?.user?.role === "15" ? (
              //     <Outlet />
              //   ) : (
              //     <RedirectSpinner />
              //   )
              // }
            >
              <Route path="social-media" element={<ArTeacherSocial />} />
              <Route path="profile" element={<ArTeacherProfile />} />
              <Route path="timetable" element={<ArTeacherTimeTable />} />
            </Route>
            {/* OTHER TEACHER ROUTES */}
            <Route
              path="/teacher"
              element={
                isAuthenticated && userInfo?.user?.role === "13" ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="profile" element={<OtherTeacherProfile />} />
              <Route path="chatbox" element={<OtherTeacherTimeline />} />
              <Route path="connection" element={<OConnectionList />} />
              <Route path="follower" element={<OFollowerList />} />
              <Route path="following" element={<OFollowing />} />
              <Route path="calender" element={<OtherTeacherCalender />} />
              <Route path="courses" element={<OTeacherCourseList />} />

              <Route path="create-course" element={<OCourseCreate />} />
              <Route path="update-course/:id" element={<OCourseUpdate />} />

              <Route path="mcq" element={<OTeacherMcqList />} />
              <Route path="create-mcq" element={<OTeacherMcqCreate />} />
              <Route path="update-mcq/:id" element={<OTeacherMcqEdit />} />

              <Route path="curriculum" element={<OTeacherCurriculum />} />
              <Route
                path="create-curriculum"
                element={<OTeacherAddCurriculum />}
              />
              {/* /teacher/ */}
              <Route path="reviews" element={<OTeacherReviews />} />
              <Route path="revenue" element={<OtherTeacherEarning />} />
            </Route>
            {/* FRANCHISE ROUTES */}
            <Route path="/franchise/home" element={<FranchiseHome />} />
            {/* <Route path="/franchise" element={<Outlet />}>
            <Route path="home" element={<FranchiseHome />} />
          </Route> */}
            {/* COACH ROUTES START */}
            <Route path="/coach/home" element={<CoachHome />} />
            {/* COACH ROUTES ENDS */}
            {/* Redirect to */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      )}
    </Fragment>
  );
};

export default App;
