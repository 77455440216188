import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_DOSSIER_ERRORS,
  CLEAR_DOSSIER_STATE,
  DOSSIER_CREATE_FAIL,
  DOSSIER_CREATE_REQUEST,
  DOSSIER_CREATE_SUCCESS,
  DOSSIER_DELETE_FAIL,
  DOSSIER_DELETE_REQUEST,
  DOSSIER_DELETE_SUCCESS,
  DOSSIER_GET_BY_ID_FAIL,
  DOSSIER_GET_BY_ID_REQUEST,
  DOSSIER_GET_BY_ID_SUCCESS,
  DOSSIER_GET_FAIL,
  DOSSIER_GET_REQUEST,
  DOSSIER_GET_SUCCESS,
} from "../constants/dossierConstant";

export const dossierCreateAction =
  (
    label,
    title,
    description,
    instructionList,
    allWords,
    allSituations,
    allSdQuestions,
    images,
    onUploadProgress
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DOSSIER_CREATE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
        onUploadProgress,
      };
      const { data } = await axiosInstance.post(
        "/ssb/create-dossier",
        {
          label,
          title,
          description,
          instructionList,
          allWords,
          allSituations,
          allSdQuestions,
          images,
        },
        config
      );
      dispatch({
        type: DOSSIER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOSSIER_CREATE_FAIL,
        payload: error.response.data.mesaage,
      });
    }
  };

// GET OFFICER DOSSIER
export const getDossierAction = () => async (dispatch) => {
  try {
    dispatch({
      type: DOSSIER_GET_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/ssb/my-dossier", config);

    dispatch({
      type: DOSSIER_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOSSIER_GET_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET DOSSIER BY ID
export const getDossierByIdAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DOSSIER_GET_BY_ID_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/ssb/dossier/${id}`, config);

    dispatch({
      type: DOSSIER_GET_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOSSIER_GET_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE DOSSIER
export const deleteDossier = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DOSSIER_DELETE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/ssb/dossier/${id}`, config);

    dispatch({
      type: DOSSIER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOSSIER_DELETE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const dossierClearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOSSIER_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const dossierClearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOSSIER_STATE });
};
