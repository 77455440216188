import {
  OMockInter,
  OPhsy,
  OcompleteMock,
  OcompleteSbb,
  Odossier,
  Ogto,
} from "../../assets/images";

export const OnlineCourseCategoryList = [
  {
    value: 3,
    label: "Mock Psychology",
    image: OPhsy,
    category: "Online",
    courseCategory: "Psychology",
    tooltip:"Create an online mock psychology test."
  },
  {
    value: 6,
    label: "Mock Interview",
    image: OMockInter,
    category: "Online",
    courseCategory: "Interview",
    tooltip:"Create an online mock personal interview."
  },
  {
    value: 1,
    label: "SSB Coaching",
    image: OcompleteSbb,
    category: "Online",
    courseCategory: "Complete SSB",
    tooltip:"Create Courses, either live or recorded, that cover a wide range of subjects, including SSB interview techniques, SSB psychology, and GTO strategies."
  },
  {
    value: 2,
    label: "Mock SSB Interview",
    image: OcompleteMock,
    category: "Online",
    courseCategory: "Complete SSB",
    tooltip:"Conduct mock SSB with the help of 3D GTO simulation and metaverse."
  },
  {
    value: 4,
    label: "GTO Task",
    image: Ogto,
    category: "Resources",
    link:"",
    tooltip:"Create GTO tasks and save them for later use."
  },
  {
    value: 5,
    label: "Psychology Dossier",
    image: Odossier,
    category: "Resources",
    link:"/officer/upload-dossier",
    tooltip:"Create TAT, WAT, SRT, and SD for mock psychology."
  },
  
];
