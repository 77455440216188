import React from "react";
import styled from "styled-components";
// import { IoClose } from "react-icons/io5";

const PopupMain = styled.section`
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
  transition: 0.4s;
  box-sizing: border-box;
  padding: 0;

  &.show_popup {
    top: 0;
    left: 0;
  }

  

  > .students_content {
    width: 720px;
    background: #081336;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 767px) {
      width: 540px;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding-top: 60px;
    }

    > h2 {
      font-size: 24px;
      letter-spacing: 0.1em;
      margin:16px 0px;
      line-height: 44px;
      font-family: "casanova", sans-serif;
      color: #f2f2f2;
      width: 100%;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 22px;
      line-height: 2;
      margin-bottom: 20px;
      color: #fff;
    }
    >.close_btn_div{
      display:flex;
      justify-content:center;
      align-items:center;
      margin-bottom:20px;
      > .closeBtn {
        cursor: pointer;
        // position: absolute;
        // right: 15px;
        // top: 15px;
        width: 100px;
        height: 40px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        transition: 0.4s;
        border-radius: 5px;
        background-color: #f2f2f2;
        color: #081336;
        z-index: 2;
        border: 1px solid #f2f2f2;
    
        &:hover {
          background-color: #081336;
          color: #f2f2f2;
        }
      }
    }
  }
  > .buttonSec {
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      transition: 0.4s;
      border-radius: 5px;
      background-color: #f2f2f2;
      color: #081336;
      z-index: 2;
      border: 1px solid #f2f2f2;
      padding: 7px 15px;
      text-transform: uppercase;

      &:hover {
        background-color: #081336;
        color: #f2f2f2;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  > .feedbackTextArea {
    padding: 20px 0;

    > .feedbackBtn {
      margin-right: auto;
      margin-left: auto;
      display: block;
    }
  }

  .red {
    color: red;
    font-weight: 500;
  }

  .green {
    color: green;
    font-weight: 500;
  }

  .white {
    color: #f2f2f2;
  }
`;

const PromotionPopup = ({ promotion, cancelOnclick, txt }) => {
  //   function handleModalClose() {
  //     setpromotion(false);
  //   }
  return (
    <PopupMain
      className={`enrolled_studentsDetailsModal ${
        promotion ? "show_popup" : ""
      }`}
    >
      {promotion && (
        <div className="students_content">
          <h2>Alert</h2>
          <p>{txt}</p>
          <div className="close_btn_div">
            <button
              title="Close Popup"
              className="closeBtn"
              onClick={cancelOnclick}
            >
              {/* <IoClose /> */}
              OK
            </button>
          </div>
        </div>
      )}
    </PopupMain>
  );
};

export default PromotionPopup;
