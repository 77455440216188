import React, { Fragment, useEffect } from "react";
import "../../styles/officerPaymentHistory.scss";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import Loader from "../../components/reusable/Loader";
import { officerSidebarLink } from "./officerSidebarLink";
import { FaRupeeSign } from "react-icons/fa";
import CustomInput from "../../components/reusable/CustomInput";
import CustomButton from "../../components/reusable/CustomButton";
import CustomSelect from "../../components/reusable/CustomSelect";
import { BsFillFunnelFill, BsFillStickiesFill } from "react-icons/bs";
import { useState } from "react";
import {
  getOfficerSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import {
  clearOfficerErrors,
  getOfficerRevenueAction,
} from "../../redux/actions/officerAction";
const OfficerPaymentHistory = () => {
  // const loading = false;
  const dispatch = useDispatch();
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [courseName, setCourseName] = useState("");
  const [courseFilter, setCourseFilter] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filterValue, setFilterValue] = useState(false);
  // OPEN FILTER DROPDOWN
  const filterDropdown = () => {
    setIsOpenFilter(!isOpenFilter);
  };
  // SET FILTER VALUE
  const handleChange = (e) => {
    setFilterValue(e.target.value);
    setIsOpenFilter(!isOpenFilter);
  };

  const [, , showNotification] = useNotification();

  // GET OFFICER REVENUE STARTS
  const {
    loading: revenueLoading,
    error: revenueError,
    revenueData,
  } = useSelector((state) => state.getOfficerRevenue);

  const totalEarning = revenueData?.revenue;

  useEffect(() => {
    dispatch(getOfficerRevenueAction());
  }, [dispatch]);

  useEffect(() => {
    if (revenueError) {
      dispatch(clearOfficerErrors());
    }
  }, [dispatch, revenueError]);

  // GET OFFICER REVENUE ENDS

  const {
    loading: courseLoading,
    error,
    courses: data,
  } = useSelector((state) => state.officerSsbCourses);

  const courseList = data?.courses;

  // MAKE AN ARRAY FOR COURSE SLECT INPUT
  let optionArray = [];

  for (let i = 0; i < courseList?.length; i++) {
    let optionData = {
      value: courseList[i]?._id,
      option: courseList[i]?.title,
    };
    optionArray.push(optionData);
  }
  useEffect(() => {
    dispatch(getOfficerSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, error, showNotification]);

  // WITHDRAW REQUEST OF PAYEMNT
  const handleWithdrawalSubmit = () => {
    if (withdrawAmount < 1999) {
      showNotification("error", "Please enter amount above 2000");
    }
  };

  const [totalAmount, setTotalAmount] = useState(0);
  const handleFilter = (e) => {
    setCourseName(e);
    const filterRevenue = totalEarning?.txn?.filter(
      (itm) => itm?.ssbCourse?._id === e
    );
    setTotalAmount(filterRevenue?.reduce((acc, obj) => acc + obj.amount, 0));
    setCourseFilter(true);
  };

  return (
    <Fragment>
      {revenueLoading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <div className="officerRevenue txt_color">
              <div className="performance_panel">
                <div className="title">
                  <h2> Earnings</h2>
                </div>
                <div className="performance_cards_data">
                  <div className="earning_card">
                    <div className="total_earn">
                      <h2>Total Earning</h2>
                      <span>
                        <FaRupeeSign />
                      </span>
                    </div>
                    <p>{totalEarning?.totalRevenue} /-</p>
                    {/* <div className='progress-bar'>
                                        <div className='progress' style={{ width:"70%" }}></div>
                                    </div> */}
                  </div>
                  <div className="earning_card">
                    <div className="total_earn">
                      <h2>By Courses</h2>
                      <span>
                        <BsFillStickiesFill />
                      </span>
                    </div>
                    <CustomSelect
                      type="text"
                      fullWidth={"100%"}
                      placeholder={"Select course"}
                      // name="last_name"
                      value={courseName}
                      loading={courseLoading}
                      readOnly={false}
                      onChange={(e) => handleFilter(e.target.value)}
                      optionArray={optionArray}
                      borderColor={"#f2f2f2"}
                    />

                    <p>
                      {courseFilter ? totalAmount : totalEarning?.totalRevenue}
                      /-
                    </p>
                  </div>
                  <div className="earning_card">
                    <div className="total_earn">
                      <h2>Earnings Over Time</h2>
                      <div className="filter">
                        <span onClick={filterDropdown}>
                          <BsFillFunnelFill />
                        </span>
                        {isOpenFilter && (
                          <select
                            className="filterOption"
                            value={filterValue}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="yesterday">Yesterday</option>
                            <option value="last_week">Last Week</option>
                            <option value="last_month">Last Month</option>
                            <option value="last_year">Last Year</option>
                          </select>
                        )}
                      </div>
                    </div>
                    <p>{totalEarning?.totalRevenue} /-</p>
                  </div>
                  <div className="earning_card">
                    <div className="total_earn">
                      <h2>Referral Revenue</h2>
                      <div className="filter">
                        <span onClick={filterDropdown}>
                          <BsFillFunnelFill />
                        </span>
                      </div>
                    </div>
                    <p>{totalEarning?.totalRevenue} /-</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="officer_earning_checkout">
              <div className="balance_withdraw">
                <div className="balance_title">
                  <h2>Balance</h2>
                  <p>{totalEarning?.totalRevenue} INR</p>
                </div>
                <div className="withdraw">
                  <h2>Withdraw</h2>
                  <CustomInput
                    icon={<FaRupeeSign />}
                    type="number"
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                    name="withdrawAmount"
                    value={withdrawAmount}
                    placeholder="Enter amount"
                  />
                  <CustomButton
                    className="payment_btn"
                    title="Withdraw Request"
                    onClick={handleWithdrawalSubmit}
                    //   disabled={false}
                  />
                </div>
              </div>
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OfficerPaymentHistory;
