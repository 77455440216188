import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  setUpAffiliateDashboardReducer,
  removeProfilePic,
  updateProfilePicReducer,
  updateProfileReducer,
  userLoginReducer,
  userProfileReducer,
  userRegister,
  affiliateDashboardDataReducer,
  checkCouponCodeReducer,
  getEnrolledUsersReducer,
  getNotificationsReducer,
  getDownloadNotificationsReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  officerListReducer,
  enrollStudentListReducer,
  createCourseCouponReducer,
  userDetailsByIdReducer,
  userListByRoleReducer,
} from "./reducers/userReducer";
import { getRssBlogs } from "./reducers/rssBlogReducer";
import {
  createCoursePayment,
  getAllPaymentsDoneByUserReducer,
  getPaymentDetailsReducer,
  updateUserPaymentData,
  userPaymentData,
} from "./reducers/instaMojoReducer";
import {
  addCourseRatingReducer,
  deleteCourseRatingReducer,
  getAllCoursesReducer,
  getCourseDetailsReducer,
  getCourseLongDetailsReducer,
  getCourseMetaDetailsReducer,
  getMyCoursesByIdReducer,
} from "./reducers/courseReducer";
import { cartReducer } from "./reducers/cartReducer";
import {
  addSsbCourseRatingReducer,
  createSsbCourseCouponReducer,
  deleteSsbCourseRatingReducer,
  deleteSsbCourseReducer,
  getCoursesByOfficerIdReducer,
  getOfficerSsbCoursesReducer,
  getSsbCourseDetailsReducer,
  getSsbCoursesReducer,
  pauseSsbCourseReducer,
  ssbCourseCreateReducer,
  ssbCourseUpdateReducer,
} from "./reducers/ssbCourseReducer";
import {
  addDossierReducer,
  deleteDossierReducer,
  getDossierByIdReducer,
  getDossierReducer,
} from "./reducers/dossierReducer";
import { getAllTestimonialReducer } from "./reducers/testimonialReducer";
import {
  getLiveClassListReducer,
  liveClassCreateReducer,
  liveClassStartReducer,
} from "./reducers/liveClassReducer";
import {
  addWishlistReducer,
  deleteWishListReducer,
  getWishListReducer,
} from "./reducers/wishListReducer";
import {
  unfollowRequestReducer,
  followerListReducer,
  removeRequestReducer,
  sendRequestReducer,
  getFriendProfileReducer,
} from "./reducers/followReducer";
import {
  acceptConnectionReducer,
  rejectConnectionReducer,
  removeConnectionReducer,
  sendConnectReducer,
} from "./reducers/connectionReducer";
import {
  deleteTimeLinePostReducer,
  getAllPostByOfficerReducer,
  getAllPostTimelineReducer,
  getOfficerRevenueReducer,
  likePostTimelineReducer,
  officerCoursesReducer,
  postTimelineReducer,
  updateTimelineReducer,
} from "./reducers/officerReducer";
import {
  createBlogReducer,
  deleteBlogByAdminReducer,
  deleteCommentReducer,
  deleteReplyReducer,
  getBlogByIdReducer,
  getBlogsReducer,
  postCommentBlogReducer,
  replyCommentBlogReducer,
} from "./reducers/blogReducer";
import {
  createCalenderReducer,
  getOfficerCalenderEventsReducer,
  getOfficerTimeAvalabilityReducer,
  updateCalenderReducer,
} from "./reducers/officerCalenderReducer";
import {
  deleteCourseReducer,
  myCreatedCourseReducer,
  pauseCourseReducer,
  teacherCourseCreateReducer,
} from "./reducers/teacherReducer";
import {
  createMcqReducer,
  deleteMcqReducer,
  getMcqDetailsReducer,
  getMyselfMcqReducer,
  mcqUpdateReducer,
} from "./reducers/mcqReducer";
import {
  razorKeyReducer,
  razorPaymentReducer,
  verifyRazorPaymentReducer,
} from "./reducers/razorpayReducer";
import {
  createConversationReducer,
  getMessageReducer,
  myConversationReducer,
  sendMessageReducer,
  upadteMessageReducer,
} from "./reducers/chatReducer";
import { callStatusReducer } from "./reducers/callStatusReducer";
import streamsReducer from "./reducers/streamsReducer";
import {
  createVideoCallLink,
  proVideoCallLink,
  validateVideoCallLink,
} from "./reducers/videoCallReducer";

// get user from localStorage
const userFromLocalStorage = localStorage.getItem("alphaToken")
  ? JSON.parse(localStorage.getItem("alphaToken"))
  : {};

// get cartItems from localStorage
const cartItemLocalStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  register: userRegister,
  userLogin: userLoginReducer,
  profile: userProfileReducer,
  officerList: officerListReducer,
  userListByRole: userListByRoleReducer,
  getBlogs: getRssBlogs,
  updateProfile: updateProfileReducer,
  userAvatar: updateProfilePicReducer,
  removeAvatar: removeProfilePic,
  coursePayment: createCoursePayment,
  userPaymentData: userPaymentData,
  getAllCourses: getAllCoursesReducer,
  courseDetails: getCourseDetailsReducer,
  getAllTestimonial: getAllTestimonialReducer,
  forgotPassword: forgotPasswordReducer,
  userDetailsById: userDetailsByIdReducer,
  resetPassword: resetPasswordReducer,
  getCourseLongDetailsReducer: getCourseLongDetailsReducer,
  courseMetaDetailsReducer: getCourseMetaDetailsReducer,
  affiliateDashboard: setUpAffiliateDashboardReducer,
  affiliateDashboardData: affiliateDashboardDataReducer,
  checkCouponCode: checkCouponCodeReducer,
  allPaymentsDoneByUser: getAllPaymentsDoneByUserReducer,
  paymentDetails: getPaymentDetailsReducer,
  updateUserPaymentReducer: updateUserPaymentData,
  getEnrolledUsers: getEnrolledUsersReducer,
  createCourseCoupon: createCourseCouponReducer,
  getNotifications: getNotificationsReducer,
  getDownloadNotifications: getDownloadNotificationsReducer,
  cart: cartReducer,
  ssbCourseCreate: ssbCourseCreateReducer,
  ssbCourseUpdate: ssbCourseUpdateReducer,
  officerSsbCourses: getOfficerSsbCoursesReducer,
  getAllSsbCourses: getSsbCoursesReducer,
  getCoursesByOfficerId: getCoursesByOfficerIdReducer,
  getSsbCourseDetails: getSsbCourseDetailsReducer,
  deleteSsbCourse: deleteSsbCourseReducer,
  pauseSsbCourse: pauseSsbCourseReducer,
  addSsbCourseRating: addSsbCourseRatingReducer,
  deleteSsbCourseRating: deleteSsbCourseRatingReducer,
  deleteCourseRating: deleteCourseRatingReducer,
  addCourseRating: addCourseRatingReducer,
  addDossier: addDossierReducer,
  getDossier: getDossierReducer,
  getDossierById: getDossierByIdReducer,
  deleteDossier: deleteDossierReducer,
  getLiveClassList: getLiveClassListReducer,
  liveClassCreate: liveClassCreateReducer,
  liveClassStart: liveClassStartReducer,
  getWishList: getWishListReducer,
  addWishlist: addWishlistReducer,
  deleteWishList: deleteWishListReducer,
  enrollStudentList: enrollStudentListReducer,
  getMyCoursesById: getMyCoursesByIdReducer,
  sendRequest: sendRequestReducer,
  unFollowRequest: unfollowRequestReducer,
  removeRequest: removeRequestReducer,
  followerList: followerListReducer,

  sendConnect: sendConnectReducer,
  acceptConnection: acceptConnectionReducer,
  rejectConnection: rejectConnectionReducer,
  removeConnection: removeConnectionReducer,
  getFriendProfile: getFriendProfileReducer,

  officerCourses: officerCoursesReducer,
  postTimeline: postTimelineReducer,
  updateTimeline: updateTimelineReducer,
  likePostTimeline: likePostTimelineReducer,
  getAllPostTimeline: getAllPostTimelineReducer,
  getAllPostByOfficer: getAllPostByOfficerReducer,
  deleteTimeLinePost: deleteTimeLinePostReducer,

  createBlog: createBlogReducer,
  getBlogsList: getBlogsReducer,
  getBlogById: getBlogByIdReducer,
  deleteBlog: deleteBlogByAdminReducer,
  postCommentBlog: postCommentBlogReducer,
  replyCommentBlog: replyCommentBlogReducer,
  deleteComment: deleteCommentReducer,
  deleteReply: deleteReplyReducer,

  createCalender: createCalenderReducer,
  updateCalender: updateCalenderReducer,
  getOfficerCalenderEvens: getOfficerCalenderEventsReducer,
  getOfficerTimeAvalability: getOfficerTimeAvalabilityReducer,

  teacherCourseCreate: teacherCourseCreateReducer,
  pauseCourse: pauseCourseReducer,
  createSsbCourseCoupon: createSsbCourseCouponReducer,
  getOfficerRevenue: getOfficerRevenueReducer,
  teacherCreatedCourse: myCreatedCourseReducer,
  teacherdeleteCourse: deleteCourseReducer,
  getMyselfMcq: getMyselfMcqReducer,
  createMcq: createMcqReducer,
  razorPayment: razorPaymentReducer,
  razorKey: razorKeyReducer,
  verifyRazorPayment: verifyRazorPaymentReducer,

  deleteMcq: deleteMcqReducer,
  mcqUpdate: mcqUpdateReducer,
  getMcqDetails: getMcqDetailsReducer,

  createConversation: createConversationReducer,
  myConversations: myConversationReducer,
  sendNewMessage: sendMessageReducer,
  getMessages: getMessageReducer,
  upadteMessage: upadteMessageReducer,
  callStatus: callStatusReducer,
  streams: streamsReducer,
  videoCallLink: createVideoCallLink,
  validateVideoCall: validateVideoCallLink,
  proVideoCallLink: proVideoCallLink,
});

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromLocalStorage,
  },
  cart: {
    cartItems: cartItemLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
