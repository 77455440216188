export const TEACHER_COURSE_CREATE_REQUEST = "TEACHER_COURSE_CREATE_REQUEST";
export const TEACHER_COURSE_CREATE_SUCCESS = "TEACHER_COURSE_CREATE_SUCCESS";
export const TEACHER_COURSE_CREATE_FAIL = "TEACHER_COURSE_CREATE_FAIL";

export const GET_MY_CREATAED_COURSE_REQUEST = "GET_MY_CREATAED_COURSE_REQUEST";
export const GET_MY_CREATAED_COURSE_SUCCESS = "GET_MY_CREATAED_COURSE_SUCCESS";
export const GET_MY_CREATAED_COURSE_FAIL = "GET_MY_CREATAED_COURSE_FAIL";

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL";

export const TEACHER_COURSE_PAUSE_REQUEST = "TEACHER_COURSE_PAUSE_REQUEST";
export const TEACHER_COURSE_PAUSE_SUCCESS = "TEACHER_COURSE_PAUSE_SUCCESS";
export const TEACHER_COURSE_PAUSE_FAIL = "TEACHER_COURSE_PAUSE_FAIL";

export const TEACHER_COURSE_BY_ID_REQUEST = "TEACHER_COURSE_BY_ID_REQUEST";
export const TEACHER_COURSE_BY_ID_SUCCESS = "TEACHER_COURSE_BY_ID_SUCCESS";
export const TEACHER_COURSE_BY_ID_FAIL = "TEACHER_COURSE_BY_ID_FAIL";

export const TEACHER_COURSE_DELETE_REQUEST = "TEACHER_COURSE_DELETE_REQUEST";
export const TEACHER_COURSE_DELETE_SUCCESS = "TEACHER_COURSE_DELETE_SUCCESS";
export const TEACHER_COURSE_DELETE_FAIL = "TEACHER_COURSE_DELETE_FAIL";

export const TEACHER_COURSE_DETAIL_REQUEST = "TEACHER_COURSE_DETAIL_REQUEST";
export const TEACHER_COURSE_DETAIL_SUCCESS = "TEACHER_COURSE_DETAIL_SUCCESS";
export const TEACHER_COURSE_DETAIL_FAIL = "TEACHER_COURSE_DETAIL_FAIL";

export const TEACHER_CLEAR_ERRORS = "TEACHER_CLEAR_ERRORS";
export const TEACHER_CLEAR_DELETE_STATE = "TEACHER_CLEAR_DELETE_STATE"
export const TEACHER_CLEAR_STATE = "TEACHER_CLEAR_STATE";

