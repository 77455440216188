export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL";

export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";

export const GET_BLOG_BY_ID_REQUEST = "GET_BLOG_BY_ID_REQUEST";
export const GET_BLOG_BY_ID_SUCCESS = "GET_BLOG_BY_ID_SUCCESS";
export const GET_BLOG_BY_ID_FAIL = "GET_BLOG_BY_ID_FAIL";

export const POST_COMMENT_REQUEST = "POST_COMMENT_REQUEST";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const POST_COMMENT_FAIL = "POST_COMMENT_FAIL";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAIL = "DELETE_COMMENT_FAIL";

export const CLEAR_COMMENT_STATE =  "CLEAR_COMMENT_STATE" ;
export const CLEAR_COMMENT_ERROR =  "CLEAR_COMMENT_ERROR" ;


export const AUTHOR_REPLY_COMMENT_REQUEST = "AUTHOR_REPLY_COMMENT_REQUEST";
export const AUTHOR_REPLY_COMMENT_SUCCESS = "AUTHOR_REPLY_COMMENT_SUCCESS";
export const AUTHOR_REPLY_COMMENT_FAIL = "AUTHOR_REPLY_COMMENT_FAIL";

export const AUTHOR_REPLY_DELETE_REQUEST = "AUTHOR_REPLY_DELETE_REQUEST";
export const AUTHOR_REPLY_DELETE_SUCCESS = "AUTHOR_REPLY_DELETE_SUCCESS";
export const AUTHOR_REPLY_DELETE_FAIL = "AUTHOR_REPLY_DELETE_FAIL";

export const AUTHOR_REPLY_DELETE_STATE = "AUTHOR_REPLY_DELETE_STATE";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

export const CLEAR_BLOG_ERROR = "CLEAR_BLOG_ERROR";
export const CLEAR_BLOG_STATE = "CLEAR_BLOG_STATE";

export const CLEAR_DELETE_BLOG_STATE = "CLEAR_DELETE_BLOG_STATE";
