import React from "react";
import { FaEnvelope, FaFacebookF, FaTwitter } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";
import { Swiper as SwiperComponent } from "swiper/react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import styled from "styled-components";
import ImgLazyLoad from "../reusable/ImgLazyLoad";

const AboutOurGuru = styled.div`
  margin-top: 40px;
  /* .swiper {
    width: 100%;
    height: 100%;
  } */

  .swiper {
    height: 500px;

    @media screen and (max-width: 575px) {
      height: auto;
    }
  }

  .swiper-slide {
    width: 500px;
    box-sizing: border-box;

    @media screen and (max-width: 575px) {
      width: fit-content;
      margin: 0 15px;
    }

    .myCard .content_card {
      background: linear-gradient(
        115.85deg,
        rgba(255, 255, 255, 0.4) -185.19%,
        rgba(255, 255, 255, 0) 129.07%
      );
      backdrop-filter: blur(14.175px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 10.8px;
    }
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-left {
      background: transparent !important;
    }
  }

  .swiper-slide .myCard .img_sec {
    height: 200px;
    width: 100%;
    > img.main_img {
      display: none;
    }
  }

  .swiper-slide-active .myCard {
    .img_sec {
      height: auto !important;
      width: auto !important;
      > img.main_img {
        display: block;
        width: 200px;
        height: auto;
        margin: 0 auto;
        object-fit: contain;
      }
    }
  }

  .swiper-slide .myCard {
    > .content_card {
      padding: 10px;

      > .profile_abt {
        display: flex;

        > .pro_pic {
          width: 75px;
          height: 75px;
          background: linear-gradient(
            164.91deg,
            #9dacdb 51.42%,
            rgba(53, 64, 100, 0) 100%
          );
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;

          @media screen and (max-width: 575px) {
            width: 60px;
            height: 60px;
          }

          > img {
            width: 90%;
            height: 90%;
            object-fit: contain;
          }
        }

        > .guru_abt {
          > h2 {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 10px;
            @media screen and (max-width: 575px) {
              font-size: 13.2px;
              line-height: 120%;
              margin-bottom: 5px;
            }
          }
          > p {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            margin-bottom: 10px;
            @media screen and (max-width: 575px) {
              font-size: 8.4px;
              line-height: 120%;
            }
          }
          > .social_icons {
            display: flex;
            align-items: center;
            > a {
              color: #f2f2f2;
              margin: 0 10px;

              > svg {
                font-size: 20px;
                @media screen and (max-width: 575px) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }

      > .guru_txt {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        margin-top: 10px;
        line-height: 1.7;
        @media screen and (max-width: 575px) {
          font-size: 13px;
          line-height: 150%;
          letter-spacing: 0.15px;
        }
      }
    }
  }
`;

// Meet YOUR GURUS ARRAY START  ----------- Home Page
const meetGuruArray = [
  {
    id: 1,
    name: "Ravinder Yadav",
    img: "/images/ravinderHome.png",
    type: "Current Affairs",
    facebook: "https://www.facebook.com/ravinder43210",
    twitter: "",
    gmail: "ravinder@alpharegiment.com",
    whatsapp: "+918901484719",
    text: "Ravindra Yadav will assist you in grasping the intricacies of international relations, armed forces structures, and topics pertaining to current affairs.",
  },
  {
    id: 2,
    name: "Dr Dilbaag Singh",
    img: "/images/dilbaagHome.png",
    type: "Reasoning & OIR",
    facebook: "https://www.facebook.com/dilbaag510000",
    twitter: "",
    gmail: "dilbaag@alpharegiment.com",
    whatsapp: "+918116699428",
    text: "Dr. Dilbag Singh, having served as an Assistant Commandant in the BSF, will effortlessly simplify the OIR questions for you.",
  },
  {
    id: 3,
    name: "Vijay Kumar",
    img: "/images/vijayKumar.png",
    type: "Physical Coach",
    facebook: "",
    twitter: "",
    gmail: "",
    whatsapp: "",
    text: "Vijay Kumar, a former soldier from the esteemed CMP (Central Military Police), is entrusted with the vital duty of ensuring the optimal physical fitness of our students and upholding discipline on campus.",
  },
  {
    id: 4,
    name: "Lt Gen Ajai Kumar Sahgal",
    img: "/images/Lt_Gen_Ajai_Sahgal.png",
    type: "Interviewing Officer (IO)",
    facebook: "",
    twitter: "",
    gmail: "",
    whatsapp: "",
    text: "Lieutenant General Ajai Kumar Sahgal, AVSM, VSM (Retired), served as the Commandant of the Selection Centre Central in Bhopal. He also held the position of President of the 11 SSB Allahabad and 22 SSB Bhopal. With a remarkable career spanning 40 years in the Army, he retired in 2017 as the Director General of Army Air Defence.",
  },
  {
    id: 5,
    name: "Ravinder Yadav",
    img: "/images/ravinderHome.png",
    type: "Current Affairs",
    facebook: "https://www.facebook.com/ravinder43210",
    twitter: "",
    gmail: "ravinder@alpharegiment.com",
    whatsapp: "+918901484719",
    text: "Ravindra Yadav will assist you in grasping the intricacies of international relations, armed forces structures, and topics pertaining to current affairs.",
  },
  {
    id: 6,
    name: "Dr Dilbaag Singh",
    img: "/images/dilbaagHome.png",
    type: "Reasoning & OIR",
    facebook: "https://www.facebook.com/dilbaag510000",
    twitter: "",
    gmail: "dilbaag@alpharegiment.com",
    whatsapp: "+918116699428",
    text: "Dr. Dilbag Singh, having served as an Assistant Commandant in the BSF, will effortlessly simplify the OIR questions for you.",
  },
  {
    id: 7,
    name: "Vijay Kumar",
    img: "/images/vijayKumar.png",
    type: "Physical Coach",
    facebook: "",
    twitter: "",
    gmail: "",
    whatsapp: "",
    text: "Vijay Kumar, a former soldier from the esteemed CMP (Central Military Police), is entrusted with the vital duty of ensuring the optimal physical fitness of our students and upholding discipline on campus.",
  },
  {
    id: 8,
    name: "Lt Gen Ajai Kumar Sahgal",
    img: "/images/Lt_Gen_Ajai_Sahgal.png",
    type: "Interviewing Officer (IO)",
    facebook: "",
    twitter: "",
    gmail: "",
    whatsapp: "",
    text: "Lieutenant General Ajai Kumar Sahgal, AVSM, VSM (Retired), served as the Commandant of the Selection Centre Central in Bhopal. He also held the position of President of the 11 SSB Allahabad and 22 SSB Bhopal. With a remarkable career spanning 40 years in the Army, he retired in 2017 as the Director General of Army Air Defence.",
  },
];

const OurGuru = () => {
  return (
    <AboutOurGuru className="abt_guru_content">
      <SwiperComponent
        effect={"coverflow"}
        grabCursor={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={false}
        loop={true}
        loopPreventsSliding={true}
        modules={[Autoplay, EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {meetGuruArray?.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="myCard">
              <div className="img_sec">
                <ImgLazyLoad
                  src={item.img}
                  alt="Alpha Regiment guru_pic"
                  custom_class="main_img "
                />
              </div>
              <div className="content_card">
                <div className="profile_abt">
                  <div className="pro_pic">
                    <ImgLazyLoad src={item.img} alt="Alpha Regiment guru_pic" />
                  </div>
                  <div className="guru_abt">
                    <h2>{item.name}</h2>
                    <p>{item.type}</p>
                    <div className="social_icons">
                      {item.facebook && (
                        <Link
                          target="_blank"
                          className="facebook"
                          to={item.facebook}
                        >
                          <FaFacebookF />
                        </Link>
                      )}
                      {item.twitter && (
                        <Link
                          target="_blank"
                          className="twitter"
                          to={item.twitter}
                        >
                          <FaTwitter />
                        </Link>
                      )}
                      {item.gmail && (
                        <Link
                          target="_blank"
                          className="gmail"
                          to={`mailto:${item.gmail}`}
                        >
                          <FaEnvelope />
                        </Link>
                      )}
                      {item.whatsapp && (
                        <Link
                          target="_blank"
                          className="whatsapp"
                          to={`https://wa.me/${item.whatsapp}`}
                        >
                          <IoLogoWhatsapp />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <p className="guru_txt">{item.text}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </SwiperComponent>
    </AboutOurGuru>
  );
};

export default OurGuru;
