import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CallInfo = ({ apptInfo }) => {
  const callStatus = useSelector((state) => state.callStatus);
  const apptDate = Number(apptInfo.apptDate);
  const [momentText, setMomentText] = useState(moment(apptDate).fromNow());

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setMomentText(moment(apptDate).fromNow());
      console.log("Updating time");
    }, 5000);
    return () => {
      console.log("Clearing");
      clearInterval(timeInterval);
    };
  }, [apptDate]);

  return (
    <div className="call-info">
      {callStatus.audio === "enabled" && callStatus.video === "enabled" ? (
        <h1 style={{ fontSize: "18px" }}>
          Wait for call acceptance, {apptInfo.professionalsFullName} will accept
          the call.
        </h1>
      ) : (
        <h1 style={{ fontSize: "18px" }}>
          {apptInfo.professionalsFullName} has been notified.
          <br />
          Your appointment is {momentText}. Please turn on your camera and mic
          for start a call.
        </h1>
      )}
    </div>
  );
};

export default CallInfo;
