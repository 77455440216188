import peerConfiguration from "./stunServers";

const createPeerConnection = (addIce) => {
  return new Promise(async (resolve, reject) => {
    const peerConnection = await new RTCPeerConnection(peerConfiguration);
    // rtc peerconnection is the connection to the peer.
    // we may need more than one this time
    // we pass the config object, which is just stun servers
    // it will get us ice candidates
    const remoteStream = new MediaStream();
    peerConnection.addEventListener("signalingstatechange", (e) => {
      console.log("Signaling State change");
      console.log(e);
    });
    peerConnection.addEventListener("icecandidate", (e) => {
      console.log("Found ice candidates...");
      if (e.candidate) {
        // emit to socket server
        addIce(e.candidate);
      }
    });
    peerConnection.addEventListener("track", (e) => {
      console.log("Get a track from the remote!!");
      e.streams[0].getTracks().forEach((track) => {
        remoteStream.addTrack(track, remoteStream);
        console.log("Fingers Crossed...");
      });
    });
    resolve({ peerConnection, remoteStream });
  });
};

export default createPeerConnection;
