export const SSB_COURSES_CREATE_REQUEST = "SSB_COURSES_CREATE_REQUEST";
export const SSB_COURSES_CREATE_SUCCESS = "SSB_COURSES_CREATE_SUCCESS";
export const SSB_COURSES_CREATE_FAIL = "SSB_COURSES_CREATE_FAIL";

export const SSB_COURSES_UPDATE_REQUEST = "SSB_COURSES_UPDATE_REQUEST";
export const SSB_COURSES_UPDATE_SUCCESS = "SSB_COURSES_UPDATE_SUCCESS";
export const SSB_COURSES_UPDATE_FAIL = "SSB_COURSES_UPDATE_FAIL";
export const SSB_COURSE_CLEAR_UPDATE_STATE = "SSB_COURSE_CLEAR_UPDATE_STATE";


export const SSB_COURSES_REQUEST = "SSB_COURSES_REQUEST";
export const SSB_COURSES_SUCCESS = "SSB_COURSES_SUCCESS";
export const SSB_COURSES_FAIL = "SSB_COURSES_FAIL";

export const SSB_COURSES_PAUSE_REQUEST = "SSB_COURSES_PAUSE_REQUEST";
export const SSB_COURSES_PAUSE_SUCCESS = "SSB_COURSES_PAUSE_SUCCESS";
export const SSB_COURSES_PAUSE_FAIL = "SSB_COURSES_PAUSE_FAIL";

export const OFFICER_SSB_COURSES_REQUEST = "OFFICER_SSB_COURSES_REQUEST";
export const OFFICER_SSB_COURSES_SUCCESS = "OFFICER_SSB_COURSES_SUCCESS";
export const OFFICER_SSB_COURSES_FAIL = "OFFICER_SSB_COURSES_FAIL";

export const SSB_COURSES_BY_OFFICER_ID_REQUEST = "SSB_COURSES_BY_OFFICER_ID_REQUEST";
export const SSB_COURSES_BY_OFFICER_ID_SUCCESS = "SSB_COURSES_BY_OFFICER_ID_SUCCESS";
export const SSB_COURSES_BY_OFFICER_ID_FAIL = "SSB_COURSES_BY_OFFICER_ID_FAIL";

export const SSB_COURSE_COUPON_CREATE_REQUEST = "SSB_COURSE_COUPON_CREATE_REQUEST";
export const SSB_COURSE_COUPON_CREATE_SUCCESS = "SSB_COURSE_COUPON_CREATE_SUCCESS";
export const SSB_COURSE_COUPON_CREATE_FAIL = "SSB_COURSE_COUPON_CREATE_FAIL";
export const CLEAR_CREATE_SSB_COUPON_STATE = "CLEAR_CREATE_SSB_COUPON_STATE";

export const OFFICER_SSB_COURSES_DELETE_REQUEST = "OFFICER_SSB_COURSES_DELETE_REQUEST";
export const OFFICER_SSB_COURSES_DELETE_SUCCESS = "OFFICER_SSB_COURSES_DELETE_SUCCESS";
export const OFFICER_SSB_COURSES_DELETE_FAIL = "OFFICER_SSB_COURSES_DELETE_FAIL";

export const SSB_COURSE_DETAIL_REQUEST = "SSB_COURSE_DETAIL_REQUEST";
export const SSB_COURSE_DETAIL_SUCCESS = "SSB_COURSE_DETAIL_SUCCESS";
export const SSB_COURSE_DETAIL_FAIL = "SSB_COURSE_DETAIL_FAIL";

export const SSB_COURSE_ADD_REVIEW_REQUEST = "SSB_COURSE_ADD_REVIEW_REQUEST";
export const SSB_COURSE_ADD_REVIEW_SUCCESS = "SSB_COURSE_ADD_REVIEW_SUCCESS";
export const SSB_COURSE_ADD_REVIEW_FAIL = "SSB_COURSE_ADD_REVIEW_FAIL";

export const SSB_COURSE_DELETE_REVIEW_REQUEST = "SSB_COURSE_DELETE_REVIEW_REQUEST";
export const SSB_COURSE_DELETE_REVIEW_SUCCESS = "SSB_COURSE_DELETE_REVIEW_SUCCESS";
export const SSB_COURSE_DELETE_REVIEW_FAIL = "SSB_COURSE_DELETE_REVIEW_FAIL";

export const SSB_COURSE_META_DETAIL_REQUEST = "SSB_COURSE_META_DETAIL_REQUEST";
export const SSB_COURSE_META_DETAIL_SUCCESS = "SSB_COURSE_META_DETAIL_SUCCESS";
export const SSB_COURSE_META_DETAIL_FAIL = "SSB_COURSE_META_DETAIL_FAIL";

export const SSB_CLEAR_ERRORS = "SSB_CLEAR_ERRORS";
export const SSB_CLEAR_DELETE_STATE = "SSB_CLEAR_DELETE_STATE"
export const SSB_CLEAR_STATE = "SSB_CLEAR_STATE";


// rating error for ssb courses

export const SSB_RATING_CLEAR_ERRORS = "SSB_RATING_CLEAR_ERRORS";
export const SSB_RATING_CLEAR_DELETE_STATE = "SSB_RATING_CLEAR_DELETE_STATE"
export const SSB_RATING_CLEAR_STATE = "SSB_RATING_CLEAR_STATE";