import React, { Fragment, useState } from "react";
import "../../styles/studentLMS.scss";
import StudentLayout from "../../components/student/StudentLayout";
import PerformanceCard from "../../components/student/PerformanceCard";
import CustomButton from "../../components/reusable/CustomButton";
import OurGuru from "../../components/home/OurGuru";
import StudentFooter from "../../components/student/StudentFooter";
import { courseSidebarLinks } from "./courseSidebarLinks";

const StudentLMS = () => {
  const batchTimimgs = [
    {
      id: 1,
      time: "9-10 am",
      mon: "PHY-101",
      tue: "GSC-101",
      wed: "CHE-101",
      thu: "GEO-101",
      fri: "MOCK TEST",
      sat: "------",
      sun: "------",
    },
    {
      id: 2,
      time: "10-11 am",
      mon: "------",
      tue: "GSC-101",
      wed: "CHE-101",
      thu: "------",
      fri: "MOCK TEST",
      sat: "GEO-101",
      sun: "PHY-101",
    },
    {
      id: 3,
      time: "11-12 pm",
      mon: "PHY-101",
      tue: "------",
      wed: "CHE-101",
      thu: "GEO-101",
      fri: "------",
      sat: "GSC-101",
      sun: "MOCK TEST",
    },
    {
      id: 4,
      time: "12-01 pm",
      mon: "PHY-101",
      tue: "GSC-101",
      wed: "------",
      thu: "------",
      fri: "MOCK TEST",
      sat: "GEO-101",
      sun: "CHE-101",
    },
  ];

  const batchSubjects = [
    {
      id: 1,
      title: "PHY-101 : Physics for NDA",
    },
    {
      id: 2,
      title: "CHE-101 : chemistry for NDA",
    },
    {
      id: 3,
      title: "GSC-101 : General Science for NDA",
    },
    {
      id: 4,
      title: "GEO-101 : Geography for NDA",
    },
    {
      id: 5,
      title: "PHY-101 : Physics for NDA",
    },
    {
      id: 6,
      title: "CHE-101 : chemistry for NDA",
    },
    {
      id: 7,
      title: "GSC-101 : General Science for NDA",
    },
    {
      id: 8,
      title: "GEO-101 : Geography for NDA",
    },
  ];

  const allAnnounccements = [
    {
      id: 1,
      time: "3:00 pm",
      date: "Today",
      title:
        "Indian Army News: Read Indian Army news today. Indian army latest updates, Indian army top headlines, videos and more on The Economic Time",
    },
    {
      id: 2,
      time: "10:00 am",
      date: "Nov 14",
      title:
        "Indian Army News: Read Indian Army news today. Indian army latest updates, Indian army top headlines, videos and more on The Economic Time",
    },
    {
      id: 3,
      time: "1:00 pm",
      date: "Oct 24",
      title:
        "Indian Army News: Read Indian Army news today. Indian army latest updates, Indian army top headlines, videos and more on The Economic Time",
    },
    {
      id: 4,
      time: "3:00 pm",
      date: "Today",
      title:
        "Indian Army News: Read Indian Army news today. Indian army latest updates, Indian army top headlines, videos and more on The Economic Time",
    },
    {
      id: 5,
      time: "10:00 am",
      date: "Nov 14",
      title:
        "Indian Army News: Read Indian Army news today. Indian army latest updates, Indian army top headlines, videos and more on The Economic Time",
    },
    {
      id: 6,
      time: "1:00 pm",
      date: "Oct 24",
      title:
        "Indian Army News: Read Indian Army news today. Indian army latest updates, Indian army top headlines, videos and more on The Economic Time",
    },
  ];

  const [tabValue, setTabValue] = useState("all");
  const handleTabClick = ( tabName) => {
    setTabValue(tabName);
  };
  
  return (
    <Fragment>
      <StudentLayout sidebarLinks={courseSidebarLinks}>
        <div className="lms_main txt_color">
          <div className="performance_panel">
            <div className="title">
              <h2>Performance Panel</h2>
            </div>

            <div className="performance_cards_data">
              <PerformanceCard title="Academics" chartLine={true} range="40%">
                <h2>Monthly progress Stats</h2>
                <ul>
                  <li>Attendance</li>
                  <li>Mock test performance</li>
                  <li>MCQs and Quizzes</li>
                </ul>
              </PerformanceCard>
              <PerformanceCard title="Physical" chartLine={true} range="80%">
                <h2>Monthly progress Stats</h2>
                <ul>
                  <li>Running</li>
                  <li>Medical</li>
                  <li>Weekly tasks</li>
                </ul>
              </PerformanceCard>

              <div className="attendance_coins">
                <PerformanceCard
                  title="Overall Attendance"
                  circleChart={true}
                  attended="100"
                  totalClasses="120"
                />
                <PerformanceCard arCoinsNumber="53" title="AR Coin earned" />
              </div>
            </div>

            <div className="instuctions">
              <div className="color_inst">
                <p>
                  <span className="low"></span>
                  <span>Low</span>
                </p>
                <p>
                  <span className="medium"></span>
                  <span>Moderate</span>
                </p>
                <p>
                  <span className="excellent"></span>
                  <span>Excellent</span>
                </p>
              </div>
              <div className="text_inst">
                <ul>
                  <li>
                    Ar coins earned by Good performance in physical and
                    academics
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="batch_timing_main txt_color">
          <div className="title">
            <p>Time Table</p>
            <p>Batch: Morning</p>
          </div>

          <div className="timing_calendar">
            <div className="timing_table custonScroll">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>MON</th>
                    <th>TUE</th>
                    <th>WED</th>
                    <th>THU</th>
                    <th>FRI</th>
                    <th>SAT</th>
                    <th>SUN</th>
                  </tr>
                </thead>
                <tbody>
                  {batchTimimgs?.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <p>{item.time}</p>
                      </td>
                      <td>
                        <p>{item.mon}</p>
                      </td>
                      <td>
                        <p>{item.tue}</p>
                      </td>
                      <td>
                        <p>{item.wed}</p>
                      </td>
                      <td>
                        <p>{item.thu}</p>
                      </td>
                      <td>
                        <p>{item.fri}</p>
                      </td>
                      <td>
                        <p>{item.sat}</p>
                      </td>
                      <td>
                        <p>{item.sun}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="batch_subjects txt_color">
          <div className="title">
            <h2>Subjects</h2>
          </div>

          <div className="subject_data custonScroll">
            {batchSubjects?.map((item) => (
              <div className="subject_info" key={item.id}>
                <div className="subject_title">{item.title}</div>
                <div className="subject_buttons">
                  <CustomButton className="" title="Syllabus" />
                  <CustomButton className="" title="Study material" />
                  <CustomButton className="" title="MCQs" />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="announcement_sec_main txt_color">
          <div className="title">
            <h2>Announcements</h2>
          </div>

          <div className="announccement_buttons">
            <div
              className={`tab ${tabValue === "all" ? "active" : ""}`}
              onClick={() => handleTabClick("all")}
            >
              All
            </div>
            <div
              className={`tab ${tabValue === "academics" ? "active" : ""}`}
              onClick={() => handleTabClick("academics")}
            >
              Academics
            </div>
            <div
              className={`tab ${tabValue === "posts" ? "active" : ""}`}
              onClick={() => handleTabClick("posts")}
            >
              Blog posts
            </div>
          </div>

          <div className="annnounce_post_sec_main custonScroll">
            {
              tabValue === "all" &&
              allAnnounccements?.map((item) => (
                <div className="announccement_posts" key={item.id}>
                  <div className="announce_time">
                    <p>{item.date}</p>
                    <p>{item.time}</p>
                  </div>
                  <div className="announce_data">{item.title}</div>
                </div>
              ))
            }
            {
              tabValue === "academics" &&
              allAnnounccements?.map((item) => (
                <div className="announccement_posts" key={item.id}>
                  <div className="announce_time">
                    <p>{item.date}</p>
                    <p>{item.time}</p>
                  </div>
                  <div className="announce_data">{item.title}</div>
                </div>
              ))
            }
            {
              tabValue === "posts" &&
              allAnnounccements?.map((item) => (
                <div className="announccement_posts" key={item.id}>
                  <div className="announce_time">
                    <p>{item.date}</p>
                    <p>{item.time}</p>
                  </div>
                  <div className="announce_data">{item.title}</div>
                </div>
              ))
            }
            
          </div>
        </div>
        
      </StudentLayout>

      <div className="your_guru_section txt_color">
        <div className="title">
          <h2>Your Gurus</h2>
        </div>

        <div className="yourGuru_slider">
          <OurGuru />
        </div>
      </div>

      {/* FOOTER START */}
      <StudentFooter />
      {/* FOOTER ENDS */}
    </Fragment>
  );
};

export default StudentLMS;
