import React, { Fragment } from "react";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import "../../styles/CreateDossier.scss";
import { Link, useNavigate } from "react-router-dom";
import {  FaArrowLeft, FaIdCard, FaImages, FaMapSigns } from "react-icons/fa";
import { useAuth } from "../../context/userContext";
import OfficerNoDataCard from "./OfficerNoDataCard";
import { RiFileWordFill } from "react-icons/ri";
const CreateDossier = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  // GET DOSSIER LIST STATE FROM DOSSIER REDUCER

  return (
    <Fragment>
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <section className="officer_uploadDossier_create txt_color">
              <div className="title">
                <button
                  title="Back to dossier list"
                  onClick={() => navigate("/officer/upload-dossier")}
                >
                  <FaArrowLeft />
                </button>
                <h2>Select One</h2>
              </div>
              {!auth?.user?.isVerified ? (
                <div className="create_dossier">
                  <div className="create_card">
                    <Link
                      title="Click here to create tat test"
                      to="/officer/physco-dossier-tat-create"
                    >
                      <div className="btnBox">
                        <FaImages />
                        <p>Create TAT</p>
                      </div>
                    </Link>
                    <Link
                      title="Click here to create wat test"
                      to="/officer/physco-dossier-wat-create"
                    >
                      <div className="btnBox">
                        <RiFileWordFill />
                        <p>Create WAT</p>
                      </div>
                    </Link>
                    <Link
                      title="Click here to create srt test"
                      to="/officer/physco-dossier-srt-create"
                    >
                      <div className="btnBox">
                        <FaMapSigns />
                        <p>Create SRT</p>
                      </div>
                    </Link>
                    <Link
                      title="Click here to create sd test"
                      to="/officer/physco-dossier-sdt-create"
                    >
                      <div className="btnBox">
                        <FaIdCard />
                        <p>Create SDT</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                <OfficerNoDataCard
                  txt="The authorization to create dossier will be provided after we assess your
                   document and verify your service at SSB/AFSB/NSB. 
                   This process may take up to three days from the date of account creation."
                />
              )}
            </section>
          </ProfileLayout>
        </OfficerLayout>
    </Fragment>
  );
};

export default CreateDossier;
