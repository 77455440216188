import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
  margin-top: 30px;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border-top: 1px solid #f2f2f2;
    >p{
        color:#f2f2f2;
        text-align:center;
        font-size:14px;
    }
  }
`;
const OfficerFooter = () => {
  return (
    <FooterWrapper>
      <div className="container">
        <p>By Alpha Regiment</p>
      </div>
    </FooterWrapper>
  );
};

export default OfficerFooter;
