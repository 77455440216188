import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsStarFill } from "react-icons/bs";
import styled from "styled-components";
import { RxCross2 } from "react-icons/rx";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import {
  deleteSsbCourseRatingAction,
  getSsbCourseDetails,
  ssbClearErrors,
  ssbClearRatingErrors,
  ssbClearRatingState,
  ssbClearState,
  ssbCourseAddRatingAction,
} from "../../redux/actions/ssbCourseAction";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import CustomButton from "../../components/reusable/CustomButton";
import ReviewCard from "../../components/reusable/ReviewCard";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import CustomInput from "../../components/reusable/CustomInput";
import ButtonLoader from "../../components/reusable/ButtonLoader";

const PromptSection = styled.section`
  height: 100vh;
  width: 100%;
  background-color: rgba(8, 19, 54, 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;
  >div{
    background-color:#121D40;
    border:1px solid #f2f2f2;
    border-radius:5px;
    min-width:400px;
    padding:20px;
    @media(max-width:767px){
        padding:10px;
        min-width:100%;
    }
  }
 
  .title{
    margin:20px 0px;
    display:flex;
    justify-content:space-between;
    @media(max-width:767px){
        margin:10px 0px;
    }
    .close_btn{
        width:30px;
        height:30px;
        text-align:center;
        margin-left:auto;
        border:none;
        box-shadow:none;
        outline:none;
        background-color:#f2f2f2;
        >svg{
            color:#121D40;
        }
        
    }
    h4{
        font-size:16px;
        font-weight:500;
        color:#f2f2f2;
      }
    }
    
    .input_section{
        display:flex;
        column-gap:5px;
        @media(max-width:767px){
            flex-direction:column;
        }
        .myInput{
            width:100%;
           
            .input_text{
                height:40px;
            }
        }
        .rating_button{
            margin-left:auto;
            max-width:90px;
            height:40px;
        }
    }
  
  }
`;
const StarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Star = styled(BsStarFill)`
  font-size: 20px;
  fill: ${({ filled }) => (filled === "true" ? "#FFD700" : "#CCCCCC")};
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    fill: #ffd700;
  }
`;

const ReviewPrompt = ({
  onClose,
  rating,
  loading,
  review,
  setReview,
  handleStarClick,
  handlePostReview,
}) => {
  return (
    <Fragment>
      <PromptSection>
        <div>
          <div className="title">
            <h4>Please give your valuable review</h4>
            <button className="close_btn" onClick={onClose}>
              <RxCross2 />
            </button>
          </div>
          <StarContainer>
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                filled={star <= rating ? "true" : "false"}
                onClick={() => handleStarClick(star)}
              >
                &#9733;
              </Star>
            ))}
          </StarContainer>
          <div className="input_section">
            <CustomInput
              type="text"
              placeholder="Write your review"
              name="review"
              value={review}
              onChange={(e) => setReview(e.target.value)}
            />
            <CustomButton
              className="rating_button"
              title={loading ? <ButtonLoader /> : `Post`}
              icon={""}
              onClick={handlePostReview}
            />
          </div>
        </div>
      </PromptSection>
    </Fragment>
  );
};

const SsbCourseRating = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const { id: courseId } = useParams();
  // OPEN REVIEW FORM MODAL
  const [reviewForm, setReviewForm] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  // GET SSB COURSE DETAILS STATE
  const officerSsbCourseDetails = useSelector(
    (state) => state.getSsbCourseDetails
  );
  const { loading, error, courseDetails: data } = officerSsbCourseDetails;

  // GET SSB ADD RATING STATE
  const {
    courseReview,
    error: ssbAddReviewError,
    loading: ssbAddReviewLoading,
  } = useSelector((state) => state.addSsbCourseRating);

  // GET COURSE DETAILS
  useEffect(() => {
    dispatch(getSsbCourseDetails(courseId));
  }, [courseId, dispatch]);
  const getCourseById = data?.course;

  // CLEAR GET COURSE API ERROR
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch, showNotification]);

  // ADD COURSE RATING
  const handlePostReview = (e) => {
    e.preventDefault();
    dispatch(ssbCourseAddRatingAction(courseId, rating, review));
  };
  // CLOSE REVIEW MODAL
  const handleClose = () => {
    setReviewForm(false);
  };

  // CLEAR COURSE RATING ERROR OR ERROR STATE
  useEffect(() => {
    if (ssbAddReviewError) {
      showNotification("error", ssbAddReviewError);
      dispatch(ssbClearRatingErrors());
      handleClose();
    }
    if (courseReview?.success) {
      showNotification("success", courseReview?.message);
      dispatch(ssbClearRatingState());
      dispatch(getSsbCourseDetails(courseId));
      handleClose();
    }
  }, [
    ssbAddReviewError,
    courseReview?.success,
    courseReview?.message,
    dispatch,
    showNotification,
    courseId,
  ]);

  // GET SSB DELETE RATING STATE
  const {
    courseReview: courseRating,
    error: ssbDeleteReviewError,
    loading: ssbDeleteReviewLoading,
  } = useSelector((state) => state.deleteSsbCourseRating);

  // DELETE SSB COURSE RATING FUNCTION
  const handleReviewDelete = () => {
    dispatch(deleteSsbCourseRatingAction(courseId));
  };
  // CLEAR COURSE RATING ERROR OR ERROR STATE
  useEffect(() => {
    if (ssbDeleteReviewError) {
      showNotification("error", ssbDeleteReviewError);
      dispatch(ssbClearErrors());
    }
    if (courseRating?.success) {
      showNotification("success", courseRating?.message);
      dispatch(getSsbCourseDetails(courseId));
      dispatch(ssbClearState());
    }
  }, [
    ssbDeleteReviewError,
    courseRating?.success,
    courseRating?.message,
    dispatch,
    showNotification,
    courseId,
  ]);

  // FILTER REVIEW AS PER USER LOGIN ID
  const [sortedReviews, setSortedReviews] = useState([]);

  useEffect(() => {
    if (auth?.user?._id && getCourseById?.reviews) {
      const sorted = getCourseById.reviews
        .slice()
        .sort((a, b) =>
          a?.user?.id === auth?.user?._id
            ? -1
            : b?.user?.id === auth?.user?._id
            ? 1
            : 0
        );
      setSortedReviews(sorted);
    } else {
      setSortedReviews(getCourseById?.reviews || []);
    }
  }, [auth?.user?._id, getCourseById?.reviews]);

  // RATING ROUND OFF FUNCTION
  const reviewRating = getCourseById?.rating;

  const ratingRoundOff = (value) => {
    var multiplier = Math.pow(10, 1);
    return Math.round(value * multiplier) / multiplier;
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            title="Course Rating"
            keywords="Course keyword"
            description="Course description"
            author="Aplha regiment"
          />
          <div className="rating_wrapper">
            <div className="container">
              <div className="student_review">
                <div className="title">
                  <h4>{getCourseById?.title} Review</h4>
                  <div className="filter_box">
                    <label>Sort Reviews By:</label>
                    <select>
                      <option value="latest">All</option>
                      <option value="last-month">Last Month</option>
                      <option value="last-month">Latest</option>
                    </select>
                  </div>
                </div>
                <div className="heading">
                  <div className="rating">
                    <div>
                      <p>{ratingRoundOff(reviewRating)}</p>
                      <span>
                        <strong>
                          {getCourseById?.rating > 4 ? "Very Good" : "Good"}
                        </strong>
                        <p>({getCourseById?.numOfReviews}) reviews</p>
                      </span>
                    </div>
                    <p>We aim for 100% real review</p>
                  </div>
                  <CustomButton
                    className="rating_button"
                    title="Write a review"
                    icon={""}
                    onClick={() => setReviewForm(true)}
                  />
                </div>
                <div className="review_list">
                  {sortedReviews.length > 0 ? (
                    sortedReviews?.map((ele) => (
                      <ReviewCard
                        data={ele}
                        key={ele?._id}
                        ssbDeleteReviewLoading={ssbDeleteReviewLoading}
                        onReviewDelete={handleReviewDelete}
                      />
                    ))
                  ) : (
                    <OfficerNoDataCard txt="No review yet , for this course" />
                  )}
                </div>
              </div>
            </div>

            {reviewForm && (
              <ReviewPrompt
                onClose={handleClose}
                handleStarClick={handleStarClick}
                rating={rating}
                review={review}
                setRating={setRating}
                setReview={setReview}
                loading={ssbAddReviewLoading}
                handlePostReview={handlePostReview}
              />
            )}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default SsbCourseRating;
