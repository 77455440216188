import React, { useEffect, useState } from "react";
import {
  BsChevronRight,
  // BsFillHeartFill,
  BsHeartFill,
  // BsStar,
  // BsStarFill,
} from "react-icons/bs";
import { useLocale } from "../../context/locale";
import CustomButton from "../reusable/CustomButton";
import { Link, useNavigate } from "react-router-dom";
// import startDateBg from "../../images/startingdatebg.png";
import "../../styles/courseCard.scss";
import CustomRating from "../reusable/CustomRating";
import { addItemsToCart } from "../../redux/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPaymentErrors,
  clearPaymentState,
} from "../../redux/actions/instaMojoAction";
import { useNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/userContext";
import ButtonLoader from "../reusable/ButtonLoader";
// import ButtonLoader from "../reusable/ButtonLoader";
const CourseCard = ({
  id,
  title,
  isActiveClass,
  description,
  icon,
  onCourseView,
  onHomePageClick,
  className,
  discountedPrice,
  numOfReviews,
  rating,
  price,
  link,
  addWishList,
  existInWishList,
  loadingCheck,
  addWishListLoading,
  deleteWishListLoading,
}) => {
  const [t] = useLocale();
  const slicedDescription =
    description && description.length > 42
      ? `${description.slice(0, 42)}..`
      : description;
  const [, , showNotification] = useNotification();
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // RATING ROUND OFF
  const ratingRoundOff = (value) => {
    var multiplier = Math.pow(10, 1);
    return Math.round(value * multiplier) / multiplier;
  };

  // HOVER POPUP OPEN
  const [isHovered, setIsHovered] = useState(false);

  // COURSE PAYMENT HANDLERS AND PAYMENT LOADERS
  const {
    loading: paymentLoading,
    payment,
    error: paymentError,
  } = useSelector((state) => state.coursePayment);

  const paymentHandler = () => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/course-details/${id}` });
      showNotification("warning", "Please login before adding to cart!");
    } else {
      // setShowCouponPopup(true);
      dispatch(addItemsToCart(id));
      showNotification("success", "Item Added To Cart");
      navigate("/student/cart");
      // dispatch(createPayment(amount, purpose, courseId));
    }
  };

  useEffect(() => {
    if (paymentError) {
      showNotification("error", paymentError);
      dispatch(clearPaymentErrors());
    }

    if (payment?.success) {
      window.location.href = payment?.payment_request?.longurl;
      dispatch(clearPaymentState());
    }

    if (!payment?.success) {
      if (payment?.message?.phone) {
        showNotification("error", payment?.message?.phone[0]);
      }
      if (payment?.message?.amount) {
        showNotification("error", payment?.message?.amount[0]);
      }
      dispatch(clearPaymentState());
    }
  }, [
    paymentError,
    dispatch,
    payment?.success,
    payment?.payment_request?.longurl,
    payment?.message,
    payment?.message?.phone,
    payment?.message?.amount,
    showNotification,
  ]);
  return (
    <div
      className={!isActiveClass ?  `course_card_second ${isHovered ? "hovered" : ""} ${
        onHomePageClick && "pointer"
      } ${className}` : "ssb_course_card"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}

      // onClick={()=>navigate(`/course-details/${id}`)}
    >
      <div className="icon" onClick={onHomePageClick}>
        <img src={icon} alt={title} />
      </div>
      <div className="card_content">
        <h2 onClick={onHomePageClick}>
          {title.length > 14 ? title.slice(0, 15) + ".." : title}
        </h2>
        <p className="desc">{slicedDescription}</p>
        {numOfReviews === 0 || !numOfReviews ? (
          <p className="rating" onClick={onHomePageClick}>
            <span className="ratingStar">
              4.5
              <CustomRating val={ratingRoundOff(4.5)} readOnly={true} />
            </span>
          </p>
        ) : (
          <p className="rating" onClick={onHomePageClick}>
            <span className="ratingStar">
              {ratingRoundOff(rating)}
              <CustomRating val={ratingRoundOff(rating)} readOnly={true} />
            </span>
            <span className="review">({numOfReviews ? numOfReviews : ""})</span>
          </p>
        )}
        <p className="price" onClick={onHomePageClick}>
          {discountedPrice !== 0 ? (
            <>
              <span className="discountedPrice">₹{discountedPrice}</span>
              <strike className="actualPrice">
                {price === 0 ? "Free of cost" : "₹" + price}
              </strike>
            </>
          ) : (
            <>
              <span className="discountedPrice">
                {price === 0 ? "Free of cost" : "₹" + price}
              </span>
            </>
          )}
        </p>
        <div className="cart_btns">
          {(onCourseView || onHomePageClick) && (
            <CustomButton
              className="explore_btn"
              title={t("exploreBtn")}
              onClick={onCourseView ? onCourseView : onHomePageClick}
              icon={<BsChevronRight className="icon" />}
              width="100%"
            />
          )}
          {link && (
            <Link to={link} className="enroll_btn">
              Enroll Now
            </Link>
          )}
          <CustomButton
            className={`explore_btn ${
              existInWishList ? "already_added" : " add_wishlist"
            }`}
            tooltip={existInWishList ? "Already added" : "Add to wishlist"}
            onClick={addWishList}
            width="50px"
            // disabled={existInWishList}
            title={
              (loadingCheck === id && addWishListLoading) ||
              deleteWishListLoading ? (
                <ButtonLoader />
              ) : (
                <BsHeartFill />
              )
            }
          />
        </div>
      </div>
      {isHovered && (
        <div className="additional_details">
          <h2>{title}</h2>
          <p>{description}</p>
          <div className="cart_btns">
            <CustomButton
              className="explore_btn"
              title={<BsHeartFill />}
              disabled={paymentLoading ? true : false}
              onClick={() => paymentHandler(discountedPrice, title)}
              width="100%"
            />
            <button
              className="heartButton"
              // onClick={onClick}
              disabled={existInWishList}
              onClick={addWishList}
            >
              <BsHeartFill />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseCard;
