import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import "../../styles/ssbCoaching.scss";
import CustomInput from "../../components/reusable/CustomInput";
import {
  checkCouponCodeValidation,
  clearCouponCode,
  clearErrors,
  userListByRoleAction,
  userProfile,
} from "../../redux/actions/userAction";
import MedicalAndCoachCard from "../../components/reusable/MedicalAndCoachCard";
import CustomButton from "../../components/reusable/CustomButton";
import {
  clearRazorErrors,
  clearRazorState,
  createRazorPayment,
  getRazorKey,
  razorPaymentverification,
} from "../../redux/actions/razorpayAction";
import { useCookies } from "react-cookie";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";

const CouponPaymentPopup = ({
  showCouponPopup,
  setShowCouponPopup,
  courseDetails,
}) => {
  // const [t] = useLocale();
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  // const [showEmiTable, setShowEmiTable] = useState(false);
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const payment = courseDetails?.price;
  const discountePercentage = courseDetails?.price;
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [initialData, setInitialData] = useState({});

  const closeModal = () => {
    // window.location.reload();
    dispatch(clearCouponCode());
    setShowCouponPopup(false);
    // setShowEmiTable(false);
    setCouponCode("");
  };

  // GET RAZOR PAY KEY START
  const {
    key: razorKey,
    error: razorKeyError,
    // eslint-disable-next-line
    loading: razorKeyLoading,
  } = useSelector((state) => state.razorKey);

  const razorPaykey = razorKey?.key;

  useEffect(() => {
    dispatch(getRazorKey());
  }, [dispatch]);

  useEffect(() => {
    if (razorKeyError) {
      dispatch(clearRazorErrors());
    }
  }, [dispatch, razorKeyError]);
  // GET RAZOR PAY KEY ENDS

  // CHECK COUPON CODE IS VALID OR NOT START
  // CHECK COUPON CODE IS VALID OR NOT START
  const handleCodeCheck = () => {
    if (couponCode) {
      dispatch(checkCouponCodeValidation(couponCode));
    } else {
      showNotification("warning", "Please fill Coupon Code!");
    }
  };

  const { loading, isValidCoupon, error } = useSelector(
    (state) => state.checkCouponCode
  );

  let discountedAmount = payment;
  if (isValidCoupon?.success) {
    const discounteAmount = (payment * discountePercentage) / 100;
    discountedAmount = payment - discounteAmount;
  } else {
    discountedAmount = payment;
  }

  useEffect(() => {
    if (error) {
      setCouponCode("");
    }
  }, [error]);
  // CHECK COUPON CODE IS VALID OR NOT ENDS
  // CHECK COUPON CODE IS VALID OR NOT ENDS

  // GET COUPON CODE FROM COOKIES START
  // GET COUPON CODE FROM COOKIES START
  const [cookies] = useCookies([]);

  useEffect(() => {
    const myCookieValue = cookies.alphaCouponCodeCookie;
    setCouponCode(myCookieValue);
  }, [cookies]);
  // GET COUPON CODE FROM COOKIES ENDS
  // GET COUPON CODE FROM COOKIES ENDS

  // CREATE PAYEMENT INSTANCE START
  // CREATE PAYEMENT INSTANCE START
  const {
    payment: razorPayment,
    error: razorError,
    loading: razorLoading,
  } = useSelector((state) => state.razorPayment);

  const paymentDone = (
    amount,
    for_course,
    forDoctor,
    couponCode,
    emiId,
    seatBooking
  ) => {
    const ssbCourse = false;

    const amountRound = Math.ceil(amount);
    setInitialData({
      for_course,
      ssbCourse,
      amountRound,
      couponCode,
      emiId,
      seatBooking,
      forDoctor,
    });
    dispatch(
      createRazorPayment(
        for_course,
        ssbCourse,
        amountRound,
        couponCode,
        emiId,
        seatBooking,
        forDoctor
      )
    );
  };

  useEffect(() => {
    if (razorPayment?.success) {
      // INTILSE RAZOR PAY FOR PAYENT POPUP START
      const intialiseRazorPay = (order) => {
        var options = {
          key: razorPaykey,
          amount: order.amount,
          currency: "INR",
          order_id: order.id,
          handler: function (response) {
            setPaymentResponse(response);
          },
          prefill: {
            name: auth?.user?.first_name,
            email: auth?.user?.email,
            contact: auth?.user?.phone,
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      };
      intialiseRazorPay(razorPayment?.order);
      // INTILSE RAZOR PAY FOR PAYENT POPUP ENDS
      dispatch(clearRazorState());
    }
    if (razorError) {
      showNotification("error", razorError);
      dispatch(clearRazorErrors());
    }
  }, [
    dispatch,
    razorPaykey,
    razorError,
    razorPayment?.success,
    razorPayment?.order,
    showNotification,
    auth,
  ]);
  // CREATE PAYEMENT INSTANCE ENDS
  // CREATE PAYEMENT INSTANCE ENDS

  // VERIFY PAYMENT START
  // VERIFY PAYMENT START
  const {
    payment: verifyPayment,
    error: verifyPaymentError,
    // eslint-disable-next-line
    loading: verifyverifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  useEffect(() => {
    if (paymentResponse && initialData) {
      dispatch(
        razorPaymentverification(
          paymentResponse.razorpay_payment_id,
          paymentResponse.razorpay_order_id,
          paymentResponse.razorpay_signature,
          initialData?.for_course,
          initialData?.ssbCourse,
          initialData?.couponCode,
          initialData?.emiId,
          initialData?.seatBooking,
          initialData?.forDoctor
        )
      );
    }
  }, [paymentResponse, initialData, dispatch]);

  useEffect(() => {
    if (verifyPayment?.success) {
      showNotification("success", verifyPayment?.message);
      setShowCouponPopup(false);
      navigate("/verification", { state: courseDetails });
      dispatch(clearRazorState());
      dispatch(userProfile());
    }
    if (verifyPaymentError) {
      showNotification("error", verifyPaymentError);
      dispatch(clearRazorErrors());
    }
  }, [
    setShowCouponPopup,
    verifyPayment?.success,
    verifyPayment?.message,
    verifyPaymentError,
    showNotification,
    dispatch,
    navigate,
    courseDetails,
  ]);

  // VERIFY PAYMENT ENDS
  // VERIFY PAYMENT ENDS

  return (
    <section
      className={`coupon_payment_popup_main bg_color txt_color ${
        showCouponPopup ? "showPaymentModal" : ""
      }`}
    >
      {showCouponPopup && (
        <>
          <button className="close_modal" onClick={() => closeModal()}>
            <AiOutlineClose />
          </button>
          <div>
            <p className="coupon_code_txt">
              If you have coupon for discount fill here!
            </p>

            {error && <p className="error_line">{error}</p>}
            {isValidCoupon?.success && (
              <p className="succes_line">{isValidCoupon?.message}</p>
            )}

            <div className="couponCode_sec">
              <CustomInput
                type="text"
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Coupon Code"
                name="couponCode"
                value={couponCode}
              />
              <button
                className={`check_couponCode_btn ${
                  couponCode?.length <= 0 ? "disabled" : ""
                }`}
                onClick={() => handleCodeCheck()}
              >
                {loading ? <ButtonLoader /> : "Apply Coupon"}
              </button>
            </div>
            <div className="pay_withoutCoupon">
              {isValidCoupon?.success && (
                <p style={{ color: "green", fontWeight: "bold" }}>
                  This is {isValidCoupon?.isValidCoupon?.name}'s Coupon Code.
                  you get {discountePercentage}% discount for this Course.
                </p>
              )}
              <div className="all_payment_btns">
                <CustomButton
                  className=""
                  disabled={razorLoading ? true : false}
                  title={
                    razorLoading ? (
                      <ButtonLoader />
                    ) : (
                      `Pay ₹${Math.ceil(discountedAmount)}`
                    )
                  }
                  onClick={() =>
                    paymentDone(
                      discountedAmount,
                      null,
                      courseDetails?._id,
                      null,
                      null,
                      false
                    )
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const MedicalCheckups = () => {
  const currentPageUrl = window?.location?.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [t] = useLocale();

  // GET ALL DOCTOR LIST STARTS
  useEffect(() => {
    dispatch(userListByRoleAction(19));
  }, [dispatch]);
  const {
    error: userListError,
    loading: userListLoading,
    userList,
  } = useSelector((state) => state.userListByRole);

  useEffect(() => {
    if (userListError) {
      dispatch(clearErrors());
    }
  }, [dispatch, userListError]);

  const filterDoctorList = userList?.users;
  // GET ALL DOCTOR LIST ENDS
  // GET ALL DOCTOR LIST ENDS

  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [courseData, setcourseData] = useState({});
  const paymentHandler = (data) => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/ssb-courses` });
      showNotification("warning", "Please login before proceed to buy");
    } else {
      setShowCouponPopup(true);
      setcourseData(data);
    }
  };

  return (
    <Fragment>
      {userListLoading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="After receiving a recommendation, the next step is an SSB interview medical. At Alpha Regiment, consult with retired military doctors for any medical-related issues."
            author="Team Alpha Regiment"
            title="Get Your Medical Check Done by Retired Military Doctors at Alpha Regiment"
            canonical={currentPageUrl}
          />
          <section className="ssb_coaching_courses_main">
            <div className="container">
              {/* <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div> */}

              <div className="all_course_content">
                <div className="all_course_cards">
                  <h1 className="main_title">
                    <span onClick={() => navigate(-1)}>
                      <IoMdArrowRoundBack />
                    </span>{" "}
                    Medical Checkup: Ensuring Fitness for Service
                  </h1>
                  <p className="description">
                    One of the most crucial aspects of the SSB interview process
                    is the medical examination. Candidates who clear the
                    psychological, physical, and group tasks must undergo a
                    medical checkup to ensure they meet the health standards
                    required for a career in the armed forces. The medical exam
                    assesses the overall physical and mental fitness of the
                    candidate, ensuring they are capable of handling the demands
                    of military service.
                  </p>
                  {filterDoctorList?.length > 0 && (
                    <div className="slider_container">
                      {filterDoctorList?.map((itm, idx) => (
                        <MedicalAndCoachCard
                          key={idx}
                          data={itm}
                          onClick={() => paymentHandler(itm)}
                          tooltip="For a single appointment, medication and tests are not included."
                        />
                      ))}
                    </div>
                  )}

                  {filterDoctorList?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What Does the Medical Checkup Involve?</h2>
                </div>
                <div className="detail">
                  <p>The medical checkup typically involves several stages.</p>
                  <p>
                    The medical board for the Armed Forces typically includes a
                    variety of specialists who assess different aspects of a
                    candidate’s health. The following are the main types of
                    doctors and specialists involved in the medical examination
                    process:
                  </p>
                  <ul style={{ listStyle: "desimal", fontWeight: 700 }}>
                    <li>
                      General Medical Officer (GMO)
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          A General Medical Officer conducts the overall medical
                          examination and is responsible for assessing the
                          general health of the candidate, including their
                          physical fitness and medical history.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Ophthalmologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          Specializes in eye health. The ophthalmologist checks
                          for vision clarity, color blindness, and any other
                          eye-related conditions that could disqualify a
                          candidate, including visual acuity, refractive error,
                          and depth perception.
                        </li>
                      </ul>
                    </li>
                    <li>
                      ENT Specialist (Ear, Nose, and Throat)
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          Examine the candidate’s hearing abilities and check
                          for any issues related to the ears, nose, and throat,
                          such as chronic infections or any impairments that
                          could hinder a candidate’s ability to perform in
                          extreme environments.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Dentist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          A dentist checks the candidate’s oral health,
                          including teeth, gums, and overall dental condition.
                          Proper dental health is vital for service personnel,
                          as untreated dental issues can impact long-term
                          health.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Orthopedic Specialist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          The orthopedic specialist evaluates the
                          musculoskeletal system, checking for any bone or joint
                          problems, deformities, or past injuries that might
                          affect physical performance or endurance.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Cardiologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          The cardiologist examines the candidate’s heart
                          health, ensuring that there are no underlying heart
                          conditions that could pose a risk during rigorous
                          physical activities.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Dermatologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          A dermatologist evaluates the candidate’s skin health,
                          checking for any conditions like infections, rashes,
                          or allergies that could affect their ability to endure
                          harsh conditions.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Neurologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          The neurologist examines the nervous system, checking
                          for any disorders related to the brain, spinal cord,
                          and peripheral nerves, which could affect
                          coordination, strength, or endurance.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Endocrinologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          An endocrinologist may be consulted if there are
                          concerns about hormonal imbalances, including thyroid
                          conditions, diabetes, or other metabolic disorders
                          that could impact physical fitness.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Pulmonologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          The pulmonologist assesses lung function and
                          respiratory health, ensuring that there are no chronic
                          respiratory conditions, such as asthma or chronic
                          obstructive pulmonary disease (COPD), which could
                          affect physical stamina.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Urologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          The urologist checks for any issues related to the
                          urinary system and ensures there are no conditions
                          that might impede a candidate’s performance.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Gastroenterologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          The gastroenterologist evaluates the health of the
                          digestive system, including the stomach, intestines,
                          and liver, ensuring that no chronic digestive issues
                          exist that could interfere with a candidate’s health
                          during service.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Radiologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          A radiologist is involved in reviewing X-rays or other
                          imaging tests to detect any hidden issues, such as
                          fractures, organ abnormalities, or other health
                          concerns that might not be immediately apparent.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Hematologist
                      <ul
                        style={{ margin: "5px 0 0 20px", fontWeight: "normal" }}
                      >
                        <li>
                          A hematologist may be consulted to check for blood
                          disorders, anemia, or any conditions related to the
                          blood that could affect overall health and
                          performance.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    These specialists work together to form a comprehensive
                    medical assessment, ensuring that each candidate meets the
                    necessary health standards for military service. If a
                    candidate has concerns or pre-existing conditions, they may
                    need to consult specific specialists before undergoing the
                    official medical board evaluation.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    What Happens if a Candidate Fails the Medical Examination?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    If a candidate fails the medical examination, they are given
                    a medical category based on the severity of the condition.
                    Some candidates may be temporarily rejected and allowed to
                    reappear after receiving treatment or recovering from the
                    condition. In some cases, if the medical condition is
                    permanent, the candidate may be disqualified from the
                    selection process. However, there are options for appeal if
                    a candidate believes the medical verdict is unjust.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>How to Prepare for the SSB Medical Checkup?</h2>
                </div>
                <div className="detail">
                  <p>
                    To avoid any surprises during the medical examination,
                    candidates should take certain precautions:
                  </p>
                  <ul style={{ listStyle: "decimal" }}>
                    <li>
                      <b>Maintain Good Physical Health:</b> Regular exercise, a
                      balanced diet, and proper sleep can help improve overall
                      health and prevent disqualification due to physical
                      conditions.
                    </li>
                    <li>
                      <b>Address Pre-existing Medical Conditions:</b> If you
                      have any ongoing medical issues, seek medical advice and
                      get treatment beforehand to ensure that they do not affect
                      your eligibility.
                    </li>
                    <li>
                      <b>Keep a Healthy Lifestyle:</b> Avoid habits like smoking
                      or excessive drinking, which can negatively impact your
                      health and disqualify you during the medical checkup.
                    </li>
                    <li>
                      <b>Prepare Mentally:</b> Mental fitness is just as
                      important as physical fitness. Ensure you manage stress,
                      practice mindfulness, and take steps to stay emotionally
                      balanced.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>How Can Alpha Regiment Help You?</h2>
                </div>
                <div className="detail">
                  <p>
                    At <b>Alpha Regiment</b>, we go beyond traditional coaching
                    by offering personalized support for your medical
                    preparation. We understand that the medical examination can
                    be one of the most daunting stages of the SSB interview, and
                    that's why we provide you with unique access to retired
                    military doctors who have experience with medical boards.
                  </p>
                  <ul>
                    <li>
                      <b>Connect with Retired Military Doctors:</b> If you are
                      aware of any medical terms or conditions in advance, you
                      can schedule an online consultation with our retired
                      military doctors through <b>Alpha Regiment</b>. They will
                      provide expert advice, clarify any doubts, and guide you
                      on how to address any potential medical concerns before
                      the SSB exam.
                    </li>
                    <li>
                      <b>Post-Medical TR Assistance:</b> If you receive a
                      Temporary Rejection (TR) during the medical checkup,{" "}
                      <b>Alpha Regiment</b> offers a solution. You can visit the
                      specialized doctor from the relevant medical field to
                      discuss the points raised in your TR. Our network of
                      experienced doctors can help you understand the reasons
                      behind the TR and suggest ways to address them, whether
                      through treatment, further medical tests, or lifestyle
                      changes.
                    </li>
                    <li>
                      <b>Online Consultations:</b> For those who prefer to
                      consult from the comfort of their homes, our retired
                      military doctors are available for online consultations,
                      ensuring that you get professional guidance no matter
                      where you are.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Conclusion</h2>
                </div>
                <div className="detail">
                  <p>The medical checkup is a vital part of the SSB interview, designed to ensure that only physically and mentally fit candidates are selected for service in the Indian Armed Forces. By preparing in advance and maintaining good health, you can clear this stage with confidence and continue your journey toward becoming an officer in the defense forces.</p>
                  <br />
                  <p>With <b>Alpha Regiment</b>, you can now have the guidance and support of retired military doctors, ensuring you are fully prepared for the medical checkup and ready to face the SSB interview with confidence!</p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}

      {/* COUPON CODE POPUP START */}

      <CouponPaymentPopup
        showCouponPopup={showCouponPopup}
        setShowCouponPopup={setShowCouponPopup}
        courseDetails={courseData}
      />
      {/* COUPON CODE POPUP ENDS */}
    </Fragment>
  );
};

export default MedicalCheckups;
