import { useDispatch, useSelector } from "react-redux";
import updateCallStatus from "../../../redux/actions/updateCallStatus";
import { useNavigate } from "react-router-dom";
import { useChatAuth } from "../../../context/chatContext";

const HangupButton = ({ smallFeedEl, largeFeedEl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  const { socket } = useChatAuth();

  const hangupCall = () => {
    dispatch(updateCallStatus("current", "complete"));
    for (const s in streams) {
      if (streams[s].peerConnection) {
        streams[s].peerConnection.close();
        streams[s].peerConnection.onicecandidate = null;
        streams[s].peerConnection.onaddstream = null;
        streams[s].peerConnection = null;
      }
    }
    smallFeedEl.current.srcObject = null;
    largeFeedEl.current.srcObject = null;
    navigate(-1);
    socket.emit("endVideoCall", socket.id);
  };

  if (callStatus.current === "complete") {
    return <></>;
  }

  return (
    <button onClick={hangupCall} className="btn btn-danger hang-up">
      Hang Up
    </button>
  );
};

export default HangupButton;
