import React, { useRef, useState } from "react";
import "../styles/coachHome.scss";
import "../styles/alphaSchoolHome.scss";
import CustomHelmet from "../components/reusable/Helmet";
import FranchiseNav from "./franchise/FranchiseNav";
import ImgLazyLoad from "../components/reusable/ImgLazyLoad";
import CustomButton from "../components/reusable/CustomButton";
import { BsChevronRight } from "react-icons/bs";
import { useLocale } from "../context/locale";
import { AiOutlineClose } from "react-icons/ai";
import {
  EnhanceInstituteBranding,
  GainCompetive,
  StudentProgressMonitoring,
  alphaSchoolHero,
  bookDemo,
  elevateInstitute,
  empoweringSuccess,
  focusPassionOfficer,
} from "../assets/images";
import Footer from "../components/reusable/Footer";
import CustomInput from "../components/reusable/CustomInput";
import { IoVideocam } from "react-icons/io5";

const AlphaSchool = () => {
  const [t] = useLocale();
  const [showVideo, setShowVideo] = useState(0);

  // FORM DATA FOR SEND REQUEST
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    address: "",
  });

  // DESTRUCTURE DATA
  const { fullName, email, address } = formData;

  // GET FORM INPUT DATA CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // HANDLE SUBMIT BUTTON
  const handleSubmit = () => {};

  // HANDLE ONCLICK PAGE SCROLL ON FORM
  const scrollFormRef = useRef(null);

  const handleScrollToForm = () => {
    scrollFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="alphaSchoolHomeMain txt_color">
      <CustomHelmet
        keywords="Give Your Institute's Students an Edge in Competitive Exams with Alpha Regiment."
        description="Enhance your institute's success in competitive exams by joining Alpha Regiment. Our innovative platform offers comprehensive resources and expert guidance to give your students the competitive edge they need."
        author="Team Alpha Regiment"
        title="Give Your Institute's Students an Edge in Competitive Exams with Alpha Regiment."
        canonical={window?.location?.href}
      />

      {/* NAVBAR START */}
      <FranchiseNav />
      {/* NAVBAR ENDS */}

      {/* HOME SECTION START */}
      <div className="hero_section_main">
        <div className="container">
          <div className="hero_layout">
            <div className="txt_sec">
              <h1>{t("alphaSchoolHead")}</h1>
              <CustomButton
                className="hero_btn"
                title={t("BookDemoBtn")}
                icon={<BsChevronRight />}
                onClick={handleScrollToForm}
              />
            </div>
            <div className="img_sec">
              <ImgLazyLoad src={alphaSchoolHero} alt="ecoEducation" />
            </div>
          </div>
        </div>
      </div>
      {/* HOME SECTION ENDS */}

      {/* WHY ALPHA REGIMENT START */}
      <section className="why_alpha_reg">
        <div className="container">
          <div className="title">
            <h1>
              {t("whyOnlyAlpha")}
              {/* Why Alpha Regiment? */}
            </h1>
            <div></div>
          </div>

          <div className="services_cards">
            {/* SHAPE THE NATION"S FUTURE START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 1 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">
                  {t("EmpoweringStudentsCompetitive")}
                </h2>
                <p className="home_custom_para">
                  {t("EmpoweringStudentsCompetitiveSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                    onClick={() => setShowVideo(1)}
                  >
                    <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img src={empoweringSuccess} alt="Alpha Regiment" />
                  </div>
                </div>
              </div>
            </div>
            {/* SHAPE THE NATION"S FUTURE ENDS */}

            {/* BOOST YOUR INCOME START */}
            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">
                  {t("StudentProgressMonitoring")}
                </h2>
                <p className="home_custom_para">
                  {t("StudentProgressMonitoringSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 2 ? "active" : ""}`}
                    onClick={() => setShowVideo(2)}
                  >
                    <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={StudentProgressMonitoring}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 2 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* BOOST YOUR INCOME ENDS */}

            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 3 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">
                  {t("EnhanceInstituteBranding")}
                </h2>
                <p className="home_custom_para">
                  {t("EnhanceInstituteBrandingSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 3 ? "active" : ""}`}
                    onClick={() => setShowVideo(3)}
                  >
                    <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={EnhanceInstituteBranding}
                      alt="Alpha Regiment virtudal campus"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION ENDS */}

            {/* EMBRACE FLEXIBILITY IN YOUR CAREER START */}
            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">{t("ElevateYourInstitution")}</h2>
                <p className="home_custom_para">
                  {t("ElevateYourInstitutionSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 4 ? "active" : ""}`}
                    onClick={() => setShowVideo(4)}
                  >
                    <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={elevateInstitute}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 4 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* EMBRACE FLEXIBILITY IN YOUR CAREER ENDS */}

            {/* FOCUS ON YOUR PASSION START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 5 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">{t("GainCompetitiveEdge")}</h2>
                <p className="home_custom_para">
                  {t("GainCompetitiveEdgeSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 5 ? "active" : ""}`}
                    onClick={() => setShowVideo(5)}
                  >
                    <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={GainCompetive}
                      alt="Alpha Regiment virtudal campus"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* FOCUS ON YOUR PASSION ENDS */}

            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">{t("FocusOnYourPassion")}</h2>
                <p className="home_custom_para">{t("FocusOnYourPassionSub")}</p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 2 ? "active" : ""}`}
                    onClick={() => setShowVideo(2)}
                  >
                    <IoVideocam /> {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={focusPassionOfficer}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 2 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* WHY ALPHA REGIMENT ENDS */}

      {/* Demo Form STARTS */}
      <div className="apply_form" ref={scrollFormRef}>
        <div className="container">
          <div className="title">
            <h2>{t("BookDemoBtn")}</h2>
          </div>
          <div className="section_form">
            <div className="demo_txt">
              <ImgLazyLoad src={bookDemo} alt="ecoEducation" />
            </div>
            <div className="demo_form">
              <CustomInput
                label="Name"
                type="text"
                placeholder="Ex: Ashish verma"
                name="fullName"
                impStar="*"
                value={fullName}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Email"
                type="email"
                placeholder="Ex: ashishverma123@gmail.com"
                name="email"
                impStar="*"
                value={email}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Address"
                type="text"
                placeholder="Enter your address.."
                name="address"
                impStar="*"
                value={address}
                onChange={handleInputChange}
              />

              <CustomButton
                onClick={handleSubmit}
                className="submitBtn"
                title="Submit"
              />
            </div>
          </div>
        </div>
      </div>
      {/* DEMO FORM ENDS */}

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER ENDS */}
    </section>
  );
};

export default AlphaSchool;
