import React from "react";
import Helmet from "react-helmet";

const CustomHelmet = ({
  keywords,
  description,
  author,
  title,
  redirectLink,
  canonical,
}) => {
  return (
    <Helmet>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      <link rel="canonical" href={canonical} />
      <meta http-equiv="refresh" content={redirectLink} />
      <title>{title}</title>
    </Helmet>
  );
};

CustomHelmet.defaultProps = {
  title: "Alpha Regiment turn aspirants into Warriors || Alpha Regiment",
  keywords: "Alpha Regiment, Best platform for defense aspirants",
  description:
    "We at Alpha Regiment only Charise one dream of landing all the defense aspirants in the academies of their choice. Alpha Regiment is the one-stop solution for all defense aspirants",
  author: "Team Alpha Regiment",
};

export default CustomHelmet;
