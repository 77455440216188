// this hold all streams as object
// {
//     who,
//     stream = thing with tracks will plays in <video />,
//     peerConnection = actual webRTC connection
// }


// local, remote1, remote2

const streamsReducer = (state={}, action) => {
    if (action.type === "ADD_STREAM") {
      const copyState = { ...state };
      copyState[action.payload.who] = action.payload;
      return copyState;
    } else if (action.type === "LOGOUT_ACTION") {
      return {};
    } else {
      return state;
    }
  };
  
  export default streamsReducer;
  