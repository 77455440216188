import React, { useEffect, useState } from "react";
import Layout from "../reusable/Layout";
import CustomHelmet from "../reusable/Helmet";
// import { useLocale } from '../../context/locale';
import "../../styles/metaverse.scss";
import {
  ExploreSSbCampus,
  GetexpertGuidance,
  askSsbInterview,
  assessYouOlqs,
  connectPractice,
  connectPracticeImprove,
  masterGtotask,
  practiceTatPpdt,
  practiceWatSrt,
  unlimitedMockInterviewAi,
  unlimitedOirPracticeAi,
  unlockContent,
} from "../../assets/images";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
const MetaverseCampus = () => {
  const [showIcons, setShowIcons] = useState(false);
  const [buttonPosition, setButtonPosition] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setShowIcons(true);
      } else {
        setShowIcons(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScrollButton = () => {
      if (window.scrollY > 110) {
        setButtonPosition(true);
      } else {
        setButtonPosition(false);
      }
    };

    window.addEventListener("scroll", handleScrollButton);
    return () => {
      window.removeEventListener("scroll", handleScrollButton);
    };
  }, []);

  const currentURL = window.location.href;

  const handleShare = (platform) => {
    let shareURL = "";
    switch (platform) {
      case "facebook":
        shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentURL
        )}`;
        break;
      case "instagram":
        alert("Instagram does not support direct URL sharing.");
        return;
      case "whatsapp":
        shareURL = `https://wa.me/?text=${encodeURIComponent(currentURL)}`;
        break;
      case "linkedin":
        shareURL = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          currentURL
        )}`;
        break;
      default:
        return;
    }
    window.open(shareURL, "_blank");
  };

  return (
    <Layout>
      <CustomHelmet
        description="Experience SSB (Service Selection Board) in the Metaverse. Prepare for SSB, AFSB, NSB, and FSB interviews virtually before attending them.
      SSB (Service Selection Board) in Metaverse.
      "
        title="SSB (Service Selection Board) in Metaverse"
      />
      <div
        className="txt_color main_metaverse_section"
        style={{ backgroundColor: "#0b1134" }}
      >
        <div className="container">
          <div className="hero_section_main">
            <div className="hero_txt_sec">
              <h1 className="welcome">
                SSB (Service Selection Board) in Metaverse
              </h1>

              <div className="earn_txt">
                <p>
                  Now experience SSB, AFSB, NSB, and FSB interviews <br /> even
                  before attending them.
                </p>
              </div>
              <div
                className={`target_btns ${buttonPosition ? "visible" : ""}`}
              >
                <button className="hero_btn">Download from Google Play</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="video_section">
            <iframe
              title="Virtual Campus"
              src="https://www.youtube.com/embed/QRxH3tavpDs"
              scrolling="no"
              style={{
                border: 0,
                width: "100%",
                borderRadius: 5,
              }}
              allow="autoplay"
              allowFullScreen={0}
            />
          </div>
        </div>

        <div className="container">
          <div className="why_choose_us">
            <h1 className="title">Why Choose SSB Metaverse?</h1>
            <div className="card_list">
              <div className="card">
                <img src={ExploreSSbCampus} alt="explore campus" />
                <h2>Explore SSB Campus in Metaverse</h2>
                <p>
                  Familiarize yourself with the SSB interview environment and
                  process by exploring the complete campus in the Metaverse.
                </p>
              </div>
              <div className="card">
                <img src={connectPracticeImprove} alt="connect improve" />
                <h2>Connect, Practice, Improve</h2>
                <p>
                  Join other aspirants to practice English speaking and enhance
                  your communication skills effectively.
                </p>
              </div>
              <div className="card">
                <img src={connectPractice} alt="connect practice" />
                <h2>Connect and Practice</h2>
                <p>
                  Meet other aspirants to practice group discussions,
                  lecturettes, and story narrations in an immersive environment.
                </p>
              </div>

              <div className="card">
                <img src={assessYouOlqs} alt="connect practice" />
                <h2>Assess Your OLQs</h2>
                <p>
                  Take a simple MCQ test to evaluate your Officer Like Qualities
                  (OLQs) effectively
                </p>
              </div>
              <div className="card">
                <img src={practiceTatPpdt} alt="connect practice" />
                <h2>Practice TAT & PPDT</h2>
                <p>
                  Prepare effectively for your TAT & PPDT with comprehensive
                  exercises. Start your Preparation now!
                </p>
              </div>
              <div className="card">
                <img src={practiceWatSrt} alt="connect practice" />
                <h2>Practice WAT & SRT</h2>
                <p>
                  Prepare effectively for your WAT & SRT with comprehensive
                  exercises. Start your Preparation now!
                </p>
              </div>
              <div className="card">
                <img src={masterGtotask} alt="connect practice" />
                <h2>Master GTO Tasks</h2>
                <p>
                  Practice various GTO tasks and analyze structures to excel in
                  PGT, HGT, and FGT.
                </p>
              </div>
              <div className="card">
                <img src={unlimitedOirPracticeAi} alt="connect practice" />
                <h2>Unlimited OIR Test Practice</h2>
                <p>
                  Practice unlimited OIR tests and improve your OIR rating.
                  Enhance your skills and boost your confidence.
                </p>
              </div>

              <div className="card">
                <img src={unlimitedMockInterviewAi} alt="connect practice" />
                <h2>Unlimited Mock Interviews with AI</h2>
                <p>
                  Conduct unlimited mock personal interviews with our advanced
                  AI to sharpen your skills and boost your confidence.
                </p>
              </div>
              <div className="card">
                <img src={askSsbInterview} alt="connect practice" />
                <h2>Ask SSB Interview Questions from AI</h2>
                <p>
                  Get answers to your SSB interview queries instantly from our
                  AI-powered platform.
                </p>
              </div>
              <div className="card">
                <img src={GetexpertGuidance} alt="connect practice" />
                <h2>Get Expert Guidance</h2>
                <p>
                  Enhance your skills with realistic mock interviews and
                  psychological assessments guided by experienced retired
                  assessors.
                </p>
              </div>
              <div className="card">
                <img src={unlockContent} alt="connect practice" />
                <h2>Unlock Valuable Content</h2>
                <p>
                  Access videos and social media posts to enhance your learning
                  and engagement.
                </p>
              </div>
            </div>
          </div>
        </div>

        <ul className={`share_icon_list ${showIcons ? "visible" : "hidden"}`}>
          <li onClick={() => handleShare("facebook")}>
            <span>
              <FaFacebook />
            </span>
          </li>
          <li onClick={() => handleShare("instagram")}>
            <span>
              <FaInstagram />
            </span>
          </li>
          <li onClick={() => handleShare("whatsapp")}>
            <span>
              <FaWhatsapp />
            </span>
          </li>
          <li onClick={() => handleShare("linkedin")}>
            <span>
              <FaLinkedin />
            </span>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default MetaverseCampus;
