export const OFFICER_CALENDER_CREATE_REQUEST = "OFFICER_CALENDER_CREATE_REQUEST";
export const OFFICER_CALENDER_CREATE_SUCCESS = "OFFICER_CALENDER_CREATE_SUCCESS";
export const OFFICER_CALENDER_CREATE_FAIL = "OFFICER_CALENDER_CREATE_FAIL";

export const GET_OFFICER_CALENDER_REQUEST = "GET_OFFICER_CALENDER_REQUEST";
export const GET_OFFICER_CALENDER_SUCCESS = "GET_OFFICER_CALENDER_SUCCESS";
export const GET_OFFICER_CALENDER_FAIL = "GET_OFFICER_CALENDER_FAIL";

export const CHECK_OFFICER_AVAILABILTIY_REQUEST = "CHECK_OFFICER_AVAILABILTIY_REQUEST";
export const CHECK_OFFICER_AVAILABILTIY_SUCCESS = "CHECK_OFFICER_AVAILABILTIY_SUCCESS";
export const CHECK_OFFICER_AVAILABILTIY_FAIL = "CHECK_OFFICER_AVAILABILTIY_FAIL";

export const OFFICER_CALENDER_UPDATE_REQUEST = "OFFICER_CALENDER_UPDATE_REQUEST";
export const OFFICER_CALENDER_UPDATE_SUCCESS = "OFFICER_CALENDER_UPDATE_SUCCESS";
export const OFFICER_CALENDER_UPDATE_FAIL = "OFFICER_CALENDER_UPDATE_FAIL";

export const OFFICER_CALENDER_CLEAR_ERRORS = "OFFICER_CALENDER_CLEAR_ERRORS";
export const OFFICER_CALENDER_UPDATE_CLEAR_STATE = "OFFICER_CALENDER_UPDATE_CLEAR_STATE";
export const OFFICER_CALENDER_CLEAR_STATE = "OFFICER_CALENDER_CLEAR_STATE";
