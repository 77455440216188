import React, {  useState } from "react";
import "../styles/officerHome.scss";
import CustomHelmet from "../components/reusable/Helmet";
import FranchiseNav from "./franchise/FranchiseNav";
import ImgLazyLoad from "../components/reusable/ImgLazyLoad";
import CustomButton from "../components/reusable/CustomButton";
import { BsChevronRight } from "react-icons/bs";
import { useLocale } from "../context/locale";
import { AiOutlineClose } from "react-icons/ai";
import {
  embranceOfficer,
  focusPassionOfficer,
  helpStudents,
  joinAlphaO,
  nationalGto,
  officerHome,
  onlineGtoO,
} from "../assets/images";
import boostIncome from "../images/boostIncome.png";
import completedSteps from "../images/completedSteps.gif";
import Footer from "../components/reusable/Footer";
import { useNavigate } from "react-router-dom";
import { IoVideocam } from "react-icons/io5";

const OfficerHome = () => {
  const [t] = useLocale(); // FOR CHANGING LANGUAGE FROM CONTEXT
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(0);

  // HANDLE ONCLICK PAGE SCROLL ON FORM
  // const scrollFormRef = useRef(null);

  const handleScrollToForm = () => {
    // scrollFormRef.current?.scrollIntoView({ behavior: "smooth" });
    navigate("/register/officer");
  };

  return (
    <section className="officerHomeMain txt_color">
      <CustomHelmet
        keywords="Retired ex-SSB members help aspirants realize their dream of donning the uniform."
        description="Retired ex-SSB members guide and support aspirants in realizing their dream of donning the uniform. Join Alpha Regiment to connect with and reach more students."
        author="Team Alpha Regiment"
        canonical={window?.location?.href}
        title="Retired ex-SSB members help aspirants realize their dream of donning the uniform."
      />

      {/* NAVBAR START */}
      <FranchiseNav />
      {/* NAVBAR ENDS */}

      {/* HOME SECTION START */}
      <div className="hero_section_main">
        <div className="container">
          <div className="hero_layout">
            <div className="txt_sec">
              <h1>{t("helpAspirants")}</h1>
              <CustomButton
                className="hero_btn"
                title={t("joinTeamAlphaBtn")}
                icon={<BsChevronRight />}
                onClick={handleScrollToForm}
              />
            </div>
            <div className="img_sec">
              <ImgLazyLoad src={officerHome} alt="ecoEducation" />
            </div>
          </div>
        </div>
      </div>
      {/* HOME SECTION ENDS */}

      {/* WHY ALPHA REGIMENT START */}
      <section className="why_alpha_reg">
        <div className="container">
          <div className="title">
            <h1>
              {t("whyOnlyAlpha")}
              {/* Why Alpha Regiment? */}
            </h1>
            <div></div>
          </div>

          <div className="services_cards">
            {/* SHAPE THE NATION"S FUTURE START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 1 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">{t("HelpStudentsHeading")}</h2>
                <p className="home_custom_para">
                  {t("HelpStudentsHeadingSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 1 ? "active" : ""}`}
                    onClick={() => setShowVideo(1)}
                  >
                      {/* <IoVideocam /> */}
                    <IoVideocam />  {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img src={helpStudents} alt="Alpha Regiment" />
                  </div>
                </div>
              </div>
            </div>
            {/* SHAPE THE NATION"S FUTURE ENDS */}

            {/* BOOST YOUR INCOME START */}
            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">
                  {t("OnlineGTOSimulationPlatform")}
                </h2>
                <p className="home_custom_para">
                  {t("OnlineGTOSimulationPlatformSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 2 ? "active" : ""}`}
                    onClick={() => setShowVideo(2)}
                  >
                    <IoVideocam />  {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={onlineGtoO}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 2 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* BOOST YOUR INCOME ENDS */}

            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 3 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">
                  {t("NationwideGTOGroundAccess")}
                </h2>
                <p className="home_custom_para">
                  {t("NationwideGTOGroundAccessSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 3 ? "active" : ""}`}
                    onClick={() => setShowVideo(3)}
                  >
                    <IoVideocam />  {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={nationalGto}
                      alt="Alpha Regiment virtudal campus"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* REFINE YOUR SKILLS IN AN EXCEPTIONAL ORGANITION ENDS */}

            {/* EMBRACE FLEXIBILITY IN YOUR CAREER START */}
            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">
                  {t("EmbraceFlexibilityYourCareer")}
                </h2>
                <p className="home_custom_para">
                  {t("EmbraceFlexibilityYourCareerSub")}
                </p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 4 ? "active" : ""}`}
                    onClick={() => setShowVideo(4)}
                  >
                    <IoVideocam />  {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={embranceOfficer}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 4 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* EMBRACE FLEXIBILITY IN YOUR CAREER ENDS */}

            {/* FOCUS ON YOUR PASSION START */}
            <div className="metaverse_camp card_sec single_col_sec content_right">
              <div className="img_sec">
                {showVideo === 5 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="Virtual Campus"
                            src="https://www.youtube.com/embed/QRxH3tavpDs"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="details_sec border_right">
                <h2 className="home_heading">{t("BoostYourIncome")}</h2>
                <p className="home_custom_para">{t("BoostYourIncomeSub")}</p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 5 ? "active" : ""}`}
                    onClick={() => setShowVideo(5)}
                  >
                    <IoVideocam />  {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={boostIncome}
                      alt="Alpha Regiment virtudal campus"
                    />
                    {/* <ImgLazyLoad
                        src={virtual_campus}
                        alt="Alpha Regiment virtudal campus"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* FOCUS ON YOUR PASSION ENDS */}

            <div className="metaverse_camp card_sec single_col_sec">
              <div className="details_sec border_left">
                <h2 className="home_heading">{t("FocusOnYourPassion")}</h2>
                <p className="home_custom_para">{t("FocusOnYourPassionSub")}</p>
                <div className="btn_img_order">
                  <button
                    className={`demo_btn ${showVideo === 6 ? "active" : ""}`}
                    onClick={() => setShowVideo(6)}
                  >
                    <IoVideocam />  {t("diveDeeperBtn")}
                  </button>
                  <div className="">
                    <img
                      src={focusPassionOfficer}
                      alt="Alpha Regiment personal mentor"
                    />
                    {/* <ImgLazyLoad
                        src={personal_mentor}
                        alt="Alpha Regiment personal mentor"
                        custom_class=""
                      /> */}
                  </div>
                </div>
              </div>
              <div className="img_sec">
                {showVideo === 6 && (
                  <div>
                    <button
                      className="close_modal"
                      onClick={() => setShowVideo(0)}
                    >
                      <AiOutlineClose />
                    </button>
                    <div>
                      <div
                        style={{
                          left: 0,
                          width: "100%",
                          height: 0,
                          position: "relative",
                          paddingBottom: "56.25%",
                        }}
                      >
                        <figure
                          style={{
                            left: 0,
                            width: "100%",
                            height: 0,
                            position: "relative",
                            paddingBottom: "56.25%",
                            marginBlockEnd: 0,
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                            marginInlineEnd: 0,
                          }}
                        >
                          <iframe
                            title="saarthi, your personal mentor"
                            src="https://www.youtube.com/embed/G6T11-FfYvE"
                            scrolling="no"
                            style={{
                              border: 0,
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              overflow: "hidden",
                              borderRadius: 5,
                            }}
                            allowFullScreen={1}
                            allow="autoplay"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* WHY ALPHA REGIMENT ENDS */}

      {/* REPORT FOR DUTY SECTION START */}
      <div className="reportDutyMain">
        <div className="container">
          <div className="title">
            <h2>{t("reportForDutyTitle")}</h2>
          </div>

          <div className="reportDutyContent">
            <div className="leftSide">
              <img src={completedSteps} alt="Alpha Regiment report for duty" />
            </div>
            <div className="rightSide">
              <div className="steps">
                <div className="step">
                  <div>
                    <span>1</span>
                    <span>{t("reportForDutyStepOneHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepOneTxt")}
                  </span>
                </div>
                <div className="step">
                  <div>
                    <span>2</span>
                    <span>{t("reportForDutyStepTwoHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepTwoTxt")}
                  </span>
                </div>
                <div className="step">
                  <div>
                    <span>3</span>
                    <span>{t("reportForDutyStepThreeHeading")}</span>
                  </div>
                  <span className="content">
                    {t("reportForDutyStepThreeTxt")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* REPORT FOR DUTY SECTION ENDS */}

      <div className="join_alpha_now">
        <div className="container">
          <h2>Join Alpha Regiment</h2>
          {/* <h3>Protocol for Enlistment in Team Alpha</h3> */}
          <div className="hero_layout">
            <div className="img_sec">
              <ImgLazyLoad src={joinAlphaO} alt="join_alpha_now" />
            </div>
            <div className="txt_sec">
              <h1>{t("helpAspirants")}</h1>
              <CustomButton
                className="hero_btn"
                title={t("joinTeamAlphaBtn")}
                icon={<BsChevronRight />}
                onClick={handleScrollToForm}
              />
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER ENDS */}
    </section>
  );
};

export default OfficerHome;
