export const userRole = [
  //   {
  //     id: "10",
  //     option: "Super Admin",
  //   },
  {
    id: "11",
    option: "Student",
  },
  {
    id: "12",
    option: "Alpha Teacher",
  },
  {
    id: "13",
    option: "Teacher",
  },
  //   {
  //     id: "14",
  //     option: "STAFF",
  //   },
  {
    id: "15",
    option: "Affiliate",
  },
  {
    id: "16",
    option: "GTO",
  },
  {
    id: "17",
    option: "IO",
  },
  {
    id: "18",
    option: "Psychologist",
  },
];

export const roles = [
  {
    roleId: "10",
    title: "Super Admin",
  },
  {
    roleId: "11",
    title: "Student",
  },
  {
    roleId: "12",
    title: "AR Professor",
  },
  {
    roleId: "13",
    title: "Professor",
  },
  {
    roleId: "14",
    title: "Staff",
  },
  {
    roleId: "15",
    title: "Affiliate",
  },
  {
    roleId: "16",
    title: "GTO",
  },
  {
    roleId: "17",
    title: "IO",
  },
  {
    roleId: "18",
    title: "Psychologist",
  },
  {
    roleId: "19",
    title: "Doctor",
  },
];

export const arRole = (id) => {
  const user = roles.filter((ele) => id === ele.roleId);
  return user[0]?.title;
};
