import React, { Fragment, useEffect, useState } from "react";
import "../../styles/otherTeacherProfile.scss";
import Loader from "../../components/reusable/Loader";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/userContext";
import {
  clearErrors,
  clearUpdateState,
  removeAvatar,
  updateUserProfile,
  userProfile,
} from "../../redux/actions/userAction";
import {
  FaExpeditedssl,
  FaUpload,
  FaUserEdit
} from "react-icons/fa";
// import { LuCopy } from "react-icons/lu";
import CustomInput from "../../components/reusable/CustomInput";
import CustomSelect from "../../components/reusable/CustomSelect";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { RiDeleteBinLine } from "react-icons/ri";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import UploadProfilePicForm from "../../components/student/UploadProfilePicForm";
import { defaultUser } from "../../assets/images";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import { arRole } from "../../utils/userRole";
import { useNotification } from "../../context/notificationContext";
import { doctorSidebarLinks } from "./doctorSideBarLinks";
import OfficerLayout from "../officer/OfficerLayout";
const DoctorProfile = () => {
  const [uploadProfileModal, setUploadProfileModal] = useState(false);
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const { loading } = useSelector((state) => state.profile); // GET PROFILE STATE FROM REDUCER STORE
  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile); // GET PROFILE STATE FROM REDUCER STORE
  const [editable, setEditable] = useState(false);

  // GET LOCALSTORAGE DATA
  const localStorageData = localStorage.getItem("alphaToken")
    ? JSON.parse(localStorage.getItem("alphaToken"))
    : "";

  // UPDATE PROFILE STATE
  const [profileData, setProfileData] = useState({
    first_name: auth?.user?.first_name,
    email: auth?.user?.email,
    phone: auth?.user?.phone,
    dob: auth?.user?.dob,
    gender: auth?.user?.gender,
    address: auth?.user?.address,
    aff_occupation: auth?.user?.aff_occupation,
    aff_state: auth?.user?.aff_state,
    aff_bank_name: auth?.user?.aff_bank_name,
    aff_bank_ifsc: auth?.user?.aff_bank_ifsc,
    aff_bank_acc_no: auth?.user?.aff_bank_acc_no,
    aff_bank_branch: auth?.user?.aff_bank_branch,
    aff_bank_reg_mob: auth?.user?.aff_bank_reg_mob,
    aff_upi_id: auth?.user?.aff_upi_id,
    teachMode: auth?.user?.teachMode,
    teacherBio: auth?.user?.teacherBio,
    timeTable: auth?.user?.timeTable,
    teachTopic: auth?.user?.teachTopic,
    mapCoordinates: auth?.user?.mapCoordinates,
    facebook: auth?.user?.socialLinks?.facebook,
    instagram: auth?.user?.socialLinks?.instagram,
    twitter: auth?.user?.socialLinks?.twitter,
    whatsapp: auth?.user?.socialLinks?.whatsapp,
    couponCode: auth?.user?.couponCode,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  // PROFILE UPDATE HANDLER
  const handleProfileUpdate = () => {
    dispatch(
      updateUserProfile({
        first_name: profileData?.first_name,
        middle_name: "",
        last_name: "",
        email: profileData?.email,
        phone: profileData?.phone,
        dob: profileData?.dob,
        address: profileData?.address,
        gender: profileData?.gender,
        profile: profileData?.profile,
        fatherName: "",
        motherName: "",
        parentEmail: "",
        teacherBio: profileData?.teacherBio,
        subSpec: "",
        assignSubjects: "",
        aff_occupation: "",
        aff_state: "",
        aff_bank_name: profileData?.aff_bank_name,
        aff_bank_ifsc: profileData?.aff_bank_ifsc,
        aff_bank_acc_no: profileData?.aff_bank_acc_no,
        aff_bank_branch: profileData?.aff_bank_branch,
        aff_bank_reg_mob: profileData?.aff_bank_reg_mob,
        aff_upi_id: profileData?.aff_upi_id,
        parentWhatsAppNo: "",
        timeTable: auth?.user?.timeTable,
        facebook: profileData?.facebook,
        instagram: profileData?.instagram,
        twitter: profileData?.twitter,
        whatsapp: profileData?.whatsapp,
        language: auth?.user?.language,
      })
    );
  };

  // HANLDE SUCCESS MESSAGE FOR UPDATE OFFICER DETAILS
  useEffect(() => {
    if (userInfo?.success) {
      setEditable(false);
      showNotification("success", userInfo?.message);
      // setTimeout(() => {
      dispatch(userProfile(localStorageData?.token));
      dispatch(clearUpdateState());
      // }, 3000);
    }
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    localStorageData?.token,
    showNotification,
    updateError,
    userInfo?.message,
    userInfo?.success,
  ]);

  // DELETE PROFILE PIC
  const {
    loading: removeProfileLoading,
    avatarInfo: removeProfileInfo,
    error: removeProfileError,
  } = useSelector((state) => state.removeAvatar);

  const deleteProfilePic = () => {
    dispatch(removeAvatar());
  };

  useEffect(() => {
    if (removeProfileInfo?.success) {
      showNotification("success", removeProfileInfo?.message);
      // setTimeout(() => {
      dispatch(userProfile()); // GET UPDATED USER DETAILS
      dispatch(clearUpdateState());
      // }, 3000);
    }
  }, [
    dispatch,
    showNotification,
    removeProfileError,
    removeProfileInfo?.message,
    removeProfileInfo?.success,
  ]);



  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={doctorSidebarLinks}>
            <section className="other_teacher_profile_section txt_color">
              <h2 className="profilePage_heading">
                ({arRole(auth?.user?.role)}) - Info
              </h2>

              <div className="user_details_main">
                <div className="user_pro_data">
                  <h2>
                    Profile
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="data_card">
                      <CustomInput
                        label="Full Name"
                        type="text"
                        placeholder="Full Name"
                        impStar={editable ? "*" : ""}
                        name="first_name"
                        value={
                          editable
                            ? profileData?.first_name
                            : auth.user?.first_name
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      <CustomInput
                        label="Email (user id)"
                        type="email"
                        placeholder="example@email.com"
                        impStar={editable ? "*" : ""}
                        name="email"
                        value={editable ? profileData?.email : auth.user?.email}
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Whatsapp Number"
                        type="number"
                        placeholder="0987654321"
                        impStar={editable ? "*" : ""}
                        name="phone"
                        value={editable ? profileData?.phone : auth.user?.phone}
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      <CustomSelect
                        label="Gender"
                        name="gender"
                        impStar={editable ? "*" : ""}
                        value={
                          editable ? profileData?.gender : auth.user?.gender
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        optionArray={[
                          {
                            id: 1,
                            option: "Male",
                          },
                          {
                            id: 2,
                            option: "Female",
                          },
                        ]}
                      />
                      <CustomTextarea
                        label="Short Bio"
                        type="text"
                        placeholder="tell us yourself in (max 250 words)..."
                        impStar={editable ? "*" : ""}
                        name="teacherBio"
                        value={
                          editable
                            ? profileData?.teacherBio
                            : auth.user?.teacherBio
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        fullWidth={true}
                      />
                    </div>
                    <div className="profile_picSec">
                      <div className="user_pro_pic">
                        <button
                          className="absolute_btn deleteImage_btn"
                          title="Remove Profile Picture"
                          onClick={() => deleteProfilePic()}
                        >
                          {removeProfileLoading ? (
                            <ButtonLoader />
                          ) : (
                            <RiDeleteBinLine />
                          )}
                        </button>
                        <ImgLazyLoad
                          src={
                            auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          alt="alpha regiment user"
                        />
                        <button
                          title="Upload Profile Picture"
                          className="absolute_btn uploadImage_btn"
                          onClick={() => setUploadProfileModal(true)}
                        >
                          <FaUpload />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user_pro_data" style={{ marginTop: "50px" }}>
                  <h2>Bank Account Info</h2>
                  <div>
                    <div className="data_card">
                      <CustomInput
                        label="Bank Name"
                        type="text"
                        placeholder="Bank Name"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_name"
                        value={
                          editable
                            ? profileData?.aff_bank_name
                            : auth.user?.aff_bank_name
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="IFSC Code"
                        type="text"
                        placeholder="IFSC Code"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_ifsc"
                        value={
                          editable
                            ? profileData?.aff_bank_ifsc
                            : auth.user?.aff_bank_ifsc
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Bank Account Number"
                        type="text"
                        placeholder="Bank Account Number"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_acc_no"
                        value={
                          editable
                            ? profileData?.aff_bank_acc_no
                            : auth.user?.aff_bank_acc_no
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Bank Branch"
                        type="text"
                        placeholder="Bank Branch"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_branch"
                        value={
                          editable
                            ? profileData?.aff_bank_branch
                            : auth.user?.aff_bank_branch
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Registered Mob Number"
                        type="text"
                        placeholder="Registered Mob Number"
                        impStar={editable ? "*" : ""}
                        name="aff_bank_reg_mob"
                        value={
                          editable
                            ? profileData?.aff_bank_reg_mob
                            : auth.user?.aff_bank_reg_mob
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="UPI Id"
                        type="text"
                        placeholder="UPI Id"
                        impStar={editable ? "*" : ""}
                        name="aff_upi_id"
                        value={
                          editable
                            ? profileData?.aff_upi_id
                            : auth.user?.aff_upi_id
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      {editable && (
                        <CustomButton
                          className="pro_update_btn"
                          title={updateLoading ? <ButtonLoader /> : `Update`}
                          width="100px"
                          onClick={handleProfileUpdate}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* UPLOAD PROFILE PICTURE FORM START */}
            {uploadProfileModal && (
              <section className="upload_profile_pic_main">
                <UploadProfilePicForm
                  setUploadProfileModal={setUploadProfileModal}
                />
              </section>
            )}
            {/* UPLOAD PROFILE PICTURE FORM ENDS */}
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default DoctorProfile;
