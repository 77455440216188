import React, { useState } from "react";
import styled from "styled-components";
import video_icon from "../../images/video_icon.png";
import { AiOutlineClose } from "react-icons/ai";
import ImgLazyLoad from "../reusable/ImgLazyLoad";

const Card = styled.div`
  width: fit-content;
  width: 100%;
  border-radius: 8px;
  margin: 0 15px;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
  }

  > .video_icon {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .modal_video_view {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transform: translateX(-100%);
    transition: 0.4s;
    z-index: 101;

    &.showEmbedVid {
      transform: translateX(0);
    }

    > button.close__btn {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      z-index: 999;
    }

    > .embed_vid {
      width: 100%;
      height: 100vh;
      @media (max-width: 767px) {
        width: 90%;
        margin: 70px auto;
      }
      iframe {
        width: 100%;
        height: 100vh;

        @media (max-width: 767px) {
          width: 90%;
          height: fit-content;
        }
      }
    }
  }
`;

const TestimonialCard = ({ img, iframeSrc, showModalKey }) => {
  const [showModal, setShowModal] = useState(null);
  return (
    <Card className="testimonial_card">
      {/* <img src={img} alt="Alpha Regiment testimonialCard" /> */}
      <ImgLazyLoad
        src={img}
        alt="Alpha Regiment testimonialCard"
        custom_class=""
      />
      <img
        src={video_icon}
        alt="video ico"
        className="video_icon"
        onClick={() => setShowModal(showModalKey)}
      />
      <div
        className={`modal_video_view ${
          showModal === showModalKey ? "showEmbedVid" : ""
        }`}
      >
        <button className="close__btn" onClick={() => setShowModal(null)}>
          <AiOutlineClose />
        </button>
        {showModal === showModalKey && (
          <div className="embed_vid">
            <div>
              <div
                style={{
                  left: 0,
                  width: "100%",
                  height: 0,
                  position: "relative",
                  paddingBottom: "56.25%",
                }}
              >
                <figure
                  style={{
                    left: 0,
                    width: "100%",
                    height: 0,
                    position: "relative",
                    paddingBottom: "56.25%",
                    marginBlockEnd: 0,
                    marginBlockStart: 0,
                    marginInlineStart: 0,
                    marginInlineEnd: 0,
                  }}
                >
                  <iframe
                    src={iframeSrc}
                    scrolling="no"
                    title={iframeSrc}
                    style={{
                      border: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      overflow: "hidden",
                      borderRadius: 5,
                    }}
                    allowFullScreen={1}
                    allow="autoplay"
                  />
                  {/* <iframe
                    width="642"
                    height="361"
                    src="https://www.youtube.com/embed/BEQCokuOwJ4"
                    // src={iframeSrc}
                    title="Sachin Tyagi, Recommended by 5 AFSB for flying branch IAF on the 10th attempt."
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe> */}
                </figure>
              </div>
              {/* <iframe
                width="642"
                height="361"
                src="https://www.youtube.com/embed/BEQCokuOwJ4"
                // src={iframeSrc}
                title="Sachin Tyagi, Recommended by 5 AFSB for flying branch IAF on the 10th attempt."
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default TestimonialCard;
