import React from "react";
import "./whatHappen.scss";
import { Link } from "react-router-dom";
import { BsClock } from "react-icons/bs";
import CustomButton from "../../components/reusable/CustomButton";

const TabContentCard = () => {
  return (
    <div className="tab_content_card">
      <div className="time">
        <p>
          <span>8:45 AM</span>
          <span>9:00 AM</span>
        </p>
      </div>
      <div className="name_about">
        <div className="card_title">Crafting a Creative Culture</div>
        <div className="room_duration">
          <p>Room 104C</p>
          <p>
            <span>
              <BsClock />
            </span>
            <span>1h 35min</span>
          </p>
        </div>
        <div className="main_text_link">
          <Link to="#!">B.Williams - VIP Technology Sales</Link>
          <Link to="#!">J.Barnes - Founder & Executive Chairman</Link>
        </div>
        <div className="desc_text">
          Felis lacinia risus interdum auctor id viverra dolor congue ipsum at
          mauris iaculis luctus massa
        </div>
      </div>
    </div>
  );
};

const WhatHappen = () => {
  const whtHppnCardDummy = [1, 2, 3, 5, 5, 5, 2, 5, 5, 4, 5, 4, 5, 4, 5, 5, 7];
  return (
    <div className="what_happen_main">
      <div className="container">
        <div className="title">
          <h2>What Is Going To Happen?</h2>
          <p>
            Below you'll find the schedule for Eventer 2022. We'll keep this
            page regularly updated with all new speakers and sessions, so be
            sure to keep checking in!
          </p>
        </div>

        <div className="tab_header_btns">
          <div className="date_picker_btn active">Friday, 23 September</div>
          <div className="date_picker_btn">Saturday, 24 September</div>
          <div className="date_picker_btn">Sunday, 25 September</div>
        </div>

        <div className="what_happen_tab_content">
          {whtHppnCardDummy.map((item, idx) => (
            <TabContentCard key={idx} />
          ))}
        </div>

        <div className="reserve_seat_btn">
          <CustomButton
            className=""
            title="Reserve My Seat"
            // onClick=""
            // icon=""
            // disabled=""
            width="fit-content"
          />
        </div>
      </div>
    </div>
  );
};

export default WhatHappen;
