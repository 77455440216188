import React from "react";
import "../../styles/accordion.scss";
// import { BsChevronDown } from "react-icons/bs";
// import { Link } from "react-router-dom";

const CustomAccordion = ({ count,title ,subtitle}) => {
    // const [isOpen, setIsOpen] = useState(false);

    // const toggleAccordion = () => {
    //     setIsOpen(!isOpen);
    // };

    return (
        <div className={`accordion-box `}>
            <div className="accordion-header">
                <div>
                    {/* <BsChevronDown /> */}
                    <p>{count}.{" "}{title}</p>
                </div>

                <span>
                    <p>{subtitle}</p>
                </span>
            </div>
           
        </div>
    );
};

export default CustomAccordion