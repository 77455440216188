export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const USER_DETAILS_BY_ID_REQUEST = "USER_DETAILS_BY_ID_REQUEST";
export const USER_DETAILS_BY_ID_SUCCESS = "USER_DETAILS_BY_ID_SUCCESS";
export const USER_DETAILS_BY_ID_FAIL = "USER_DETAILS_BY_ID_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_LIST_BY_ROLE_REQUEST = "USER_LIST_BY_ROLE_REQUEST";
export const USER_LIST_BY_ROLE_SUCCESS = "USER_LIST_BY_ROLE_SUCCESS";
export const USER_LIST_BY_ROLE_FAIL = "USER_LIST_BY_ROLE_FAIL";

export const COURSE_COUPON_CREATE_REQUEST = "COURSE_COUPON_CREATE_REQUEST";
export const COURSE_COUPON_CREATE_SUCCESS = "COURSE_COUPON_CREATE_SUCCESS";
export const COURSE_COUPON_CREATE_FAIL = "COURSE_COUPON_CREATE_FAIL";
export const CLEAR_CREATE_COUPON_STATE = "CLEAR_CREATE_COUPON_STATE";

export const ENROLL_STUDENT_LIST_REQUEST = "ENROLL_STUDENT_LIST_REQUEST";
export const ENROLL_STUDENT_LIST_SUCCESS = "ENROLL_STUDENT_LIST_SUCCESS";
export const ENROLL_STUDENT_LIST_FAIL = "ENROLL_STUDENT_LIST_FAIL";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";

export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_FAIL = "UPDATE_AVATAR_FAIL";

export const REMOVE_AVATAR_REQUEST = "REMOVE_AVATAR_REQUEST";
export const REMOVE_AVATAR_SUCCESS = "REMOVE_AVATAR_SUCCESS";
export const REMOVE_AVATAR_FAIL = "REMOVE_AVATAR_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const AFFILIATE_DASHBOARD_REQUEST = "AFFILIATE_DASHBOARD_REQUEST";
export const AFFILIATE_DASHBOARD_SUCCESS = "AFFILIATE_DASHBOARD_SUCCESS";
export const AFFILIATE_DASHBOARD_FAIL = "AFFILIATE_DASHBOARD_FAIL";

export const GET_AFFILIATE_DASHBOARD_REQUEST =
  "GET_AFFILIATE_DASHBOARD_REQUEST";
export const GET_AFFILIATE_DASHBOARD_SUCCESS =
  "GET_AFFILIATE_DASHBOARD_SUCCESS";
export const GET_AFFILIATE_DASHBOARD_FAIL = "GET_AFFILIATE_DASHBOARD_FAIL";

export const COUPON_CODE_REQUEST = "COUPON_CODE_REQUEST";
export const COUPON_CODE_SUCCESS = "COUPON_CODE_SUCCESS";
export const COUPON_CODE_FAIL = "COUPON_CODE_FAIL";
export const CLEAR_COUPON_CODE = "CLEAR_COUPON_CODE";

export const GET_ENROLLED_USERS_REQUEST = "GET_ENROLLED_USERS_REQUEST";
export const GET_ENROLLED_USERS_SUCCESS = "GET_ENROLLED_USERS_SUCCESS";
export const GET_ENROLLED_USERS_FAIL = "GET_ENROLLED_USERS_FAIL";

export const GET_AFF_NOTIFICATION_REQUEST = "GET_AFF_NOTIFICATION_REQUEST";
export const GET_AFF_NOTIFICATION_SUCCESS = "GET_AFF_NOTIFICATION_SUCCESS";
export const GET_AFF_NOTIFICATION_FAIL = "GET_AFF_NOTIFICATION_FAIL";

export const GET_DOWNLOAD_NOTIFICATION_REQUEST =
  "GET_DOWNLOAD_NOTIFICATION_REQUEST";
export const GET_DOWNLOAD_NOTIFICATION_SUCCESS =
  "GET_DOWNLOAD_NOTIFICATION_SUCCESS";
export const GET_DOWNLOAD_NOTIFICATION_FAIL = "GET_DOWNLOAD_NOTIFICATION_FAIL";

export const CLEAR_PROFILE_ERRORS = "CLEAR_PROFILE_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_UPDATE_STATE = "CLEAR_UPDATE_STATE";
export const CLEAR_USER_LOADING = "CLEAR_USER_LOADING";
