import React, { useState } from "react";
import "../styles/termCondition.scss";
import { Link, useNavigate, NavLink } from "react-router-dom";
// import { logo_main } from "../assets/images";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomHelmet from "../components/reusable/Helmet";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";
const MedicalPartnerTerm = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Medical Partner Term and Condition Alpha Regiment"
        description="Retired military doctors can join us to help aspirants navigate the medical process. Please read the terms and conditions before applying."
        author="Team Alpha Regiment"
        title="Medical Partner Term & Condition."
        canonical={window?.location.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>Medical Partner terms</p>
          </div>
          <img src={logo} alt="logo" />
        </div>
        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>MEDICAL PARTNER TERMS</h1>
                <p>Last updated: February 10, 2023</p>
              </div>
              <div className="privacy_text">
                <p>
                  By creating a public profile as a medical officer on our
                  website, you affirm that you have served as a doctor in the
                  armed forces and have had the opportunity to assess aspirants
                  seeking to join the armed forces. It is crucial to note that
                  any erroneous advice provided may result in legal action,
                  either directly from the aspirant or initiated by the{" "}
                  <b>Alpha Regiment</b> .
                </p>
                <p>
                  As a retired military doctor, you bear the responsibility of
                  offering the best guidance to aspirants to help them realize
                  their aspirations of joining the armed forces. If you are
                  currently serving and have not disclosed your identity, any
                  charges imposed by the armed forces will be your sole
                  responsibility, and Alpha Regiment reserves the right to take
                  legal action for providing false information.
                </p>
                <p>
                  When creating your profile, all details, including your image,
                  rank, and experience in the armed forces, will be visible to
                  students. You are prohibited from requesting to hide this
                  information, as it is displayed to build trust with aspirants.
                </p>
                <p>
                  Direct contact with students is strictly prohibited. Any
                  consultation required by the student must be arranged through
                  Alpha Regiment. Attempts to bypass this arrangement may result
                  in the suspension of your profile.
                </p>
                <p>
                  Should any ambiguity or confusion arise due to incomplete
                  information on your profile, it is your responsibility to
                  address and clarify these issues with the student beforehand.
                  Students retain the freedom to review your services and
                  conduct, and under no circumstances may you request the
                  removal of such reviews.
                </p>
                <p>
                  Your ranking on our platform will be determined based on these
                  reviews. By agreeing to these terms, you acknowledge and
                  accept these conditions governing your partnership with Alpha
                  Regiment.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default MedicalPartnerTerm;
