import React, { Fragment } from "react";
import "../../styles/studentMedical.scss";
import StudentLayout from "../../components/student/StudentLayout";
import { courseSidebarLinks } from "./courseSidebarLinks";
import CustomCard from "../../components/reusable/CustomCard";
import StudentFooter from "../../components/student/StudentFooter";
import doctorImg from "../../images/doctorImg.png";
import CustomButton from "../../components/reusable/CustomButton";
import { BsDownload } from "react-icons/bs";
import medical1 from "../../images/medical1.png";
import medical2 from "../../images/medical2.png";
import medical3 from "../../images/medical3.png";

const StudentMedical = () => {
  const medicalSupport = [
    {
      id: 1,
      title: "Get notified for your medical",
      img: medical1,
      description:
        "We'll send you a reminder when your time is right to schedule your annual medical checkup.",
    },
    {
      id: 2,
      title: "Visit for medical checkup",
      img: medical2,
      description:
        "After getting notified about the medical checkup, visit our partner hospital for hassle-free medical test",
    },
    {
      id: 3,
      title: "Final medical report",
      img: medical3,
      description:
        "We have got retired military doctors who will review your report and provide you with feedback. You can download the report",
    },
  ];

  return (
    <Fragment>
      <StudentLayout sidebarLinks={courseSidebarLinks}>
        <div className="student_medical_main txt_color">
          {/* How our medical support works START */}
          <div className="medical_support_sec">
            <div className="title">
              <h2>How our medical support works</h2>
            </div>
            <div className="medical_support_content">
              {medicalSupport?.map((item) => (
                <CustomCard
                  key={item.id}
                  img={item.img}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>
          {/* How our medical support works ENDS */}

          {/* Your medical report analysis start */}
          <div className="medical_report_analysis">
            <div className="title">
              <h2>Your medical report analysis</h2>
            </div>
            <div className="medical_report_content">
              <div className="doctor_report">
                <div className="doctor_img">
                  <img src={doctorImg} alt="doctorImg" />
                </div>
                <div className="doctor_analyst">
                  <div className="title">Report Analyst</div>
                  <div className="doctor_name">Dr. Shrikanth bhandari</div>
                  <div className="doctor_speciality">MBBS, M.Bio</div>
                  <div className="doctor_desc">
                    I'm a retired military doctor. I've deployed to Iraq and
                    Afghanistan, done humanitarian missions at the border of
                    Pakistan and Afghanistan
                  </div>
                </div>
              </div>
              <div className="feedback_report">
                <div className="title">Analyst Feedback</div>
                <ul>
                  <li>Need improvement in multivitamins and bone density</li>
                  <li>
                    Body stats such as chest and shoulder muscle mass must be
                    imroved
                  </li>
                  <li>
                    Body stats such as chest and shoulder muscle mass must be
                    imroved
                  </li>
                </ul>
              </div>
            </div>
            <div className="download_report">
              <p>Downlaod the detailed report</p>
              <CustomButton title="Download" icon={<BsDownload />} />
            </div>
          </div>
          {/* Your medical report analysis ends */}
        </div>
      </StudentLayout>
      {/* FOOTER START */}
      <StudentFooter />
      {/* FOOTER ENDS */}
    </Fragment>
  );
};

export default StudentMedical;
