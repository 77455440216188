import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import { useNavigate } from "react-router-dom";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import CourseCard from "../../components/course/CourseCard";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import "../../styles/ssbCoaching.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
import { clearErrors, getAllCourses } from "../../redux/actions/courseAction";
import { IoMdArrowRoundBack } from "react-icons/io";
const MockSsbInterview = () => {
  const currentPageUrl = window?.location?.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [t] = useLocale();
  const {
    loading,
    courses: data,
    error: courseError,
  } = useSelector((state) => state.getAllCourses);

  let allSsbApiCourses = data?.courses?.filter(
    (itm) => itm?.courseCategory === "Spoken English"
  );
  const [courseMode, setCourseMode] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const handleSearchChange = (e) => {
    const filter = e.target.value;
    setSearchCategory(filter);
    const filterdata = allSsbApiCourses?.filter((i) => {
      return (
        i.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) &&
        i.courseMode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase())
      );
    });
    allSsbApiCourses = filterdata;
  };
  const handleFilterMode = (query) => {
    setCourseMode(query);
    const filterData = allSsbApiCourses?.filter((course) => {
      return course?.courseMode === query;
    });
    allSsbApiCourses = filterData;
  };

  // console.log(allSsbApiCourses);

  // CALL API WHEN PAGE LOAD

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(clearErrors());
    }
  }, [courseError, showNotification, dispatch]);

  const handleSsbDetailsPage = (data) => {
    const { title, study_mode, courseCategory, _id } = data;
    const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
    const formattedCategory = courseCategory.toLowerCase().replace(/\s+/g, "-");
    const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
    navigate(seoFriendlyUrl);
  };

  // CHECK WISHLIST EXIST OR NOT
  const [wishListId, setWishListId] = useState("");

  const {
    // eslint-disable-next-line
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    setWishListId("");
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES
  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="Improving spoken English enhances confidence and provides a competitive edge, especially since it's the primary medium of communication in SSB interviews. Strengthen your communication skills for success."
            author="Team Alpha Regiment"
            title="Enhance Your Communication Skills for SSB Interview with Expert Guidance || Alpha Regiment"
            canonical={currentPageUrl}
          />
          <section className="ssb_coaching_courses_main">
            <div className="container">
              <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div>

              <div className="all_course_content">
                <div className="all_course_cards">
                  <h1 className="main_title">
                    <span onClick={() => navigate(-1)}>
                      <IoMdArrowRoundBack />
                    </span>{" "}
                    Spoken English for SSB Interview
                  </h1>
                  <p className="description">
                    Effective communication plays a pivotal role in the SSB
                    interview process, where clarity of thought and the ability
                    to express ideas confidently can make a significant
                    difference. Spoken English skills are essential for tasks
                    like group discussions, personal interviews, and other
                    interactive activities that require you to articulate your
                    thoughts effectively.
                  </p>

                  {allSsbApiCourses?.length > 0 && (
                    <div className="slider_container">
                      {allSsbApiCourses?.map((item, idx) => {
                        const roundedPrice = Math.ceil(item?.originalPrice);
                        const roundedDiscountedPrice = Math.ceil(
                          item?.discountedPrice
                        );
                        return (
                          <CourseCard
                            key={idx}
                            isActiveClass={true}
                            className="course_card"
                            title={item?.title}
                            description={`${item?.description?.substr(
                              0,
                              40
                            )}...`}
                            icon={item?.thumbnail?.url}
                            startingFrom={item?.starting_date}
                            price={roundedPrice}
                            discountedPrice={roundedDiscountedPrice}
                            numOfReviews={item?.numOfReviews}
                            rating={item?.rating}
                            addWishList={() =>
                              handleAddtoWishlist({
                                itemId: item?._id,
                                isSsb: true,
                              })
                            }
                            deleteWishListLoading={
                              wishListId === item?._id &&
                              deleteLoading && <ButtonLoader />
                            }
                            existInWishList={allWishList?.wishlist?.ssbCourse?.some(
                              (obj) => obj._id === item._id
                            )}
                            addWishListLoading={
                              wishListId === item?._id &&
                              addWishListLoading && <ButtonLoader />
                            }
                            onHomePageClick={() => handleSsbDetailsPage(item)}
                          />
                        );
                      })}
                    </div>
                  )}

                  {allSsbApiCourses?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Why is Spoken English Important for SSB?</h2>
                </div>
                <div className="detail">
                  <ul style={{ listStyle: "decimal" }}>
                    <li>
                      <b>Clarity and Confidence:</b>
                      Strong spoken English ensures that you present your ideas
                      clearly, boosting your confidence in tasks like lectures,
                      group planning, and the interview.
                    </li>
                    <li>
                      <b>Professionalism:</b>
                      As a future officer, being proficient in English reflects
                      your preparedness for professional settings, where English
                      is often the medium of communication.
                    </li>
                    <li>
                      <b>Effective Expression:</b>
                      Your ability to express ideas concisely and persuasively
                      plays a vital role in tasks requiring leadership and
                      teamwork.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>How to Improve Spoken English for SSB Interviews?</h2>
                </div>
                <div className="detail">
                  <ul style={{ listStyle: "decimal" }}>
                    <li>
                      <b>Build Vocabulary and Grammar:</b>A good command of
                      vocabulary and grammar is the foundation of effective
                      communication. Practice using appropriate words and
                      sentence structures to improve fluency.
                    </li>
                    <li>
                      <b>Practice Speaking:</b>
                      Regularly practice speaking English in everyday
                      conversations, focusing on clarity and pronunciation.
                      Engage in mock interviews or group discussions to simulate
                      the SSB environment.
                    </li>
                    <li>
                      <b>Read and Listen:</b>
                      Reading newspapers, magazines, and books in English, and
                      listening to podcasts or news channels, can improve your
                      understanding of the language and help you learn how to
                      structure your responses.
                    </li>
                    <li>
                      <b>Focus on Body Language:</b>
                      Good communication isn’t just about words; it’s also about
                      non-verbal cues. Work on maintaining eye contact, using
                      gestures appropriately, and speaking with confidence.
                    </li>
                    <li>
                      <b>Get Feedback:</b>
                      Seek feedback from peers, mentors, or experts to identify
                      areas for improvement and work on them consistently.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="detail_summary">
                <div className="title">
                  <h2>How Can the Alpha Regiment Help You?</h2>
                </div>
                <div className="detail">
                  <p>
                    At <b>Alpha Regiment</b>, we offer a variety of spoken
                    English training options tailored to meet the specific needs
                    of SSB aspirants. You can enroll in any course that suits
                    your requirements, which you can find on this page. For
                    detailed information about any particular course, simply
                    visit the respective course detail page.
                  </p>
                  <p>
                    Our spoken English classes are available in various formats
                    to accommodate your learning preferences:
                  </p>
                  <ul style={{ listStyle: "disc" }}>
                    <li>
                      <b>Online Classes:</b> Convenient and flexible sessions
                      from the comfort of your home.
                    </li>
                    <li>
                      <b>Offline Classes:</b> In-person classes for a more
                      traditional learning experience.
                    </li>
                    <li>
                      <b>One-on-One Sessions:</b> Personalized attention to
                      focus on your individual strengths and areas of
                      improvement.
                    </li>
                    <li>
                      <b>Group Classes:</b> Collaborative learning in a group
                      setting to enhance communication and teamwork skills.
                    </li>
                    <li>
                      <b>Video Call & Phone Call:</b> Interactive sessions via
                      video or phone calls for those who prefer remote learning
                      but still want real-time interaction.
                    </li>
                  </ul>
                  <p>
                    Choose the format that works best for you and start
                    improving your spoken English skills today!
                  </p>
                  <p>
                    Mastering spoken English can be your key to standing out in
                    the SSB interview process. Enroll in Alpha Regiment’s Spoken
                    English program today and take a step closer to achieving
                    your dream of wearing the uniform!
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default MockSsbInterview;
