import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_DELETE_MCQ_STATE,
  CLEAR_MCQ_ERROR,
  CLEAR_MCQ_STATE,
  CREATE_MCQ_FAIL,
  CREATE_MCQ_REQUEST,
  CREATE_MCQ_SUCCESS,
  DELETE_MCQ_FAIL,
  DELETE_MCQ_REQUEST,
  DELETE_MCQ_SUCCESS,
  MCQ_DETAILS_FAIL,
  MCQ_DETAILS_REQUEST,
  MCQ_DETAILS_SUCCESS,
  MY_CREATED_MCQ_LIST_FAIL,
  MY_CREATED_MCQ_LIST_REQUEST,
  MY_CREATED_MCQ_LIST_SUCCESS,
  UPDATE_MCQ_FAIL,
  UPDATE_MCQ_REQUEST,
  UPDATE_MCQ_SUCCESS,
} from "../constants/mcqQuizConstant";

// CREATE NEW BATCH
export const createNewMCQ =
  (title, testTime, questions) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_MCQ_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-mcq`,
        {
          title,
          testTime,
          questions,
        },
        config
      );

      dispatch({
        type: CREATE_MCQ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_MCQ_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  // UPDATE MCQ LIST
export const getMcqById =
(id, title, testTime, questions) => async (dispatch) => {
  try {
    dispatch({
      type: MCQ_DETAILS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/mcq/${id}`,
      {
        title,
        testTime,
        questions,
      },
      config
    );

    dispatch({
      type: MCQ_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MCQ_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};


// UPDATE MCQ LIST
export const updateNewMCQ =
  (id, title, testTime, questions) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MCQ_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/mcq/${id}`,
        {
          title,
          testTime,
          questions,
        },
        config
      );

      dispatch({
        type: UPDATE_MCQ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MCQ_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL BATCHES
export const getMyMcqList = () => async (dispatch) => {
  try {
    dispatch({
      type: MY_CREATED_MCQ_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-created-mcqs`, config);

    dispatch({
      type: MY_CREATED_MCQ_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MY_CREATED_MCQ_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const deleteMcqAction = (mcqId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_MCQ_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`//mcq/${mcqId}`, config);
    dispatch({
      type: DELETE_MCQ_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_MCQ_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearMcqErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_MCQ_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearMcqState = () => async (dispatch) => {
  dispatch({ type: CLEAR_MCQ_STATE });
};

export const clearDeleteMcqState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_MCQ_STATE });
};
