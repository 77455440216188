import React, { useEffect, useState } from "react";
import "../../styles/officerCreateTimetable.scss";
import CustomButton from "../../components/reusable/CustomButton";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
// import CustomSelect from "../../components/reusable/CustomSelect";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import { FaPlus } from "react-icons/fa";
import { GrAdd, GrClose } from "react-icons/gr";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { clearErrors, clearUpdateState, updateUserProfile, userProfile } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/userContext";
import { toast } from "react-toastify";
import Joyride from 'react-joyride';
import ButtonLoader from "../../components/reusable/ButtonLoader";

const CreateTimeTable = () => {
    const navigate = useNavigate();

    // HANDLE TIME TABLE START
    // HANDLE TIME TABLE START
    const [timeTable, setTimeTable] = useState([]);
    const [weekDay, setWeekDay] = useState("");
    const [time, setTime] = useState("");
    const [classTime, setClassTime] = useState([]);
    const [selectedTime, setSelectedTime] = useState({});
    // INSTRUCTION STATE FOR FIRST TIME USER START
    const [showInstructions, setShowInstructions] = useState(true);
    // COUNT USER VISIT 
    const [visitCount, setVisitCount] = useState(0);
    const tourSteps = [
        {
            target: '.timetable_tile', // CSS selector for the target element
            content: 'Welcome! Select a weekday and time here.', // Step content
            placement: 'bottom', // Placement of the tooltip
        },
        {
            target: '.add_time',
            content: 'Click here to add the selected time to the list.',
            placement: 'bottom',
        },
        {
            target: '.add_time_btn',
            content: 'Finally, click here to add the time to the main list.',
            placement: 'bottom',
        },
    ];

    const dispatch = useDispatch();
    const [auth] = useAuth();
    const {
        loading: updateLoading,
        userInfo,
        error: updateError,
    } = useSelector((state) => state.updateProfile);

    // GET LOCALSTORAGE DATA
    const localStorageData = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

    // useEffect to check local storage and update showInstructions state
    useEffect(() => {
        const hasVisitedBefore = localStorage.getItem('hasVisitedTimeTablePage');
        if (hasVisitedBefore > 2) {
            setShowInstructions(false);
        }
        else {
            setVisitCount(visitCount + 1);
        }
    }, []);
    
    // ADD TIME TABLE IN ARRAY
    const handleAddTimeTable = () => {
        if (!weekDay || !time) {
            toast.error("Please Select Time and WeekDay for add in list");
        } else {
            setClassTime([...classTime, { time: time }]);
            setSelectedTime({
                weekDay: weekDay,
                classTime: [...classTime, { time: time }],
            });
            setTime("");
            localStorage.setItem('hasVisitedTimeTablePage', visitCount);
        }
    };

    // DELETE SINGLE TIME TABLE ROW FROM ARRAY
    const deleteTimeRow = (id) => {
        var newData = classTime;
        newData.splice(id, 1);
        setClassTime([...newData]);
        toast.success(`Index number ${id + 1} deleted`);
    };

    // ADD WEEKDAY AND TIMES IN MAIN LIST
    const addMainTimeTableList = () => {
        if (!weekDay || classTime?.length <= 0) {
            toast.error("Please Add Time and WeekDay for adding in main list.");
        } else {
            setTimeTable([
                ...timeTable,
                { weekDay: selectedTime?.weekDay, classTime: selectedTime?.classTime },
            ]);

            setWeekDay("");
            setTime("");
            setClassTime([]);
        }
    };

    // DELETE SINGLE ROW  FROM MAIN TIME TABLE ARRAY
    const deleteMainTimeTableRow = (id) => {
        var newData = timeTable;
        newData.splice(id, 1);
        setTimeTable([...newData]);
        toast.success(`Index number ${id + 1} deleted`);
    };
    // // HANDLE TIME TABLE ENDS

    // HANDLE TIMETABLE UPDATE START
    const handleProfileUpdate = () => {
        dispatch(
            updateUserProfile({
                first_name: auth?.user?.first_name,
                middle_name: "",
                last_name: "",
                email: auth?.user?.email,
                phone: auth?.user?.phone,
                dob: auth?.user?.dob,
                address: auth?.user?.address,
                gender: auth?.user?.gender,
                profile: auth?.user?.profile,
                fatherName: "",
                motherName: "",
                parentEmail: "",
                teacherBio: auth?.user?.teacherBio,
                subSpec: "",
                assignSubjects: "",
                aff_occupation: "",
                aff_state: "",
                aff_bank_name: auth?.user?.aff_bank_name,
                aff_bank_ifsc: auth?.user?.aff_bank_ifsc,
                aff_bank_acc_no: auth?.user?.aff_bank_acc_no,
                aff_bank_branch: auth?.user?.aff_bank_branch,
                aff_bank_reg_mob: auth?.user?.aff_bank_reg_mob,
                aff_upi_id: auth?.user?.aff_upi_id,
                parentWhatsAppNo: "",
                timeTable: timeTable,
                facebook: auth?.user?.socialLinks?.facebook,
                instagram: auth?.user?.socialLinks?.instagram,
                twitter: auth?.user?.socialLinks?.twitter,
                whatsapp: auth?.user?.socialLinks?.whatsapp,
                language: auth?.user?.language,
            })
        );
    };

    useEffect(() => {
        if (userInfo?.success) {
            toast.success("Time table add successfully");
            setTimeout(() => {
                dispatch(userProfile(localStorageData?.token)); // GET UPDATED USER DETAILS
                dispatch(clearUpdateState());
            }, 3000);
            navigate("/officer/time-table")
        }
        if (updateError) {
            toast.error(updateError);
            dispatch(clearErrors());
        }
    }, [
        dispatch,
        localStorageData?.token,
        userInfo?.success,
        userInfo?.message,
        updateError,
    ]);

    // useEffect(() => {
    //     handleAddTimeTable()
    // }, [time])

    return (
        <div className="officer_createTimeTable_main">
            <div className="title">
                <button title="Go Back" onClick={() => navigate(-1)}>
                    <BiLeftArrowAlt />
                </button>
                Create Time Table
            </div>
            <div className="container">
                <div className="create_course_content">
                    <div className="courseForm">
                        <div className="batch_timeTable">
                            <div className="title_label">Set Time table</div>
                            <div className="add_timeTable">
                                <div>
                                    <div className="timetable_tile">
                                        <span>
                                            <select
                                                name="weekDay"
                                                value={weekDay}
                                                onChange={(e) => setWeekDay(e.target.value)}
                                            >
                                                <option value="">Select Week Day</option>
                                                <option value="Monday">Monday</option>
                                                <option value="Tuesday">Tuesday</option>
                                                <option value="Wednesday">Wednesday</option>
                                                <option value="Thrusday">Thrusday</option>
                                                <option value="Friday">Friday</option>
                                                <option value="Saturday">Saturday</option>
                                                <option value="Sunday">Sunday</option>
                                            </select>
                                            {/* <button onClick={handleAddTimeTable}>
                                                <GrAdd />
                                            </button> */}
                                        </span>
                                        <span>
                                            <input
                                                type="time"
                                                name="time"
                                                value={time}
                                                onChange={(e) => setTime(e.target.value)}
                                            />


                                        </span>
                                        <button
                                            // title="Add New Time Table"
                                            className="add_time"
                                            onClick={() => handleAddTimeTable()}
                                        >
                                            <FaPlus />
                                        </button>
                                    </div>
                                    {classTime?.length > 0 && (
                                        <div className="time_list">
                                            <h5>Time List:</h5>
                                            {classTime?.map((item, idx) => (
                                                <p key={idx}>
                                                    {item.time}
                                                    <span onClick={() => deleteTimeRow(idx)}>
                                                        <GrClose />
                                                    </span>
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="btn_sec">
                                    <CustomButton
                                        onClick={addMainTimeTableList}
                                        title="Add In List"
                                        className="add_time_btn"
                                    />
                                </div>
                                <Joyride
                                    steps={tourSteps}
                                    run={visitCount <= 2}
                                    continuous
                                    showProgress
                                    showSkipButton
                                    disableOverlayClose
                                    styles={{
                                        tooltip: {
                                            color: "#f2f2f2"
                                        },
                                        options: {
                                            overlayColor: 'rgba(0, 0, 0, 0.5)',
                                            backgroundColor: '#121D40',
                                            color: '#f2f2f2',
                                        },
                                        buttonNext: {
                                            backgroundColor: 'transparent',
                                            color: '#f2f2f2',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '18px',
                                            border: '1px solid #f2f2f2',
                                            cursor: 'pointer',
                                            borderRadius: '8px',
                                            height: '40px',
                                        },
                                        buttonSkip: {
                                            backgroundColor: 'transparent',
                                            color: '#f2f2f2',
                                            border: '1px solid #f2f2f2',
                                            cursor: 'pointer',
                                            borderRadius: '8px',
                                            height: '40px',
                                            marginLeft: '10px',
                                        },
                                    }}
                                    callback={(data) => {

                                        if (data.status === 'finished') {
                                            setShowInstructions(false);
                                        }
                                    }}
                                />
                            </div>
                            <div className="batch_timeTable_data">
                                {timeTable?.map((item, idx) => {
                                    return (
                                        <div className="batch_timeCard" key={idx}>
                                            <div className="weekDay">
                                                <span>{item?.weekDay}</span>
                                                <span onClick={() => deleteMainTimeTableRow(idx)}>
                                                    <RiDeleteBin5Fill />
                                                </span>
                                            </div>
                                            <div className="batch_Time">
                                                {item?.classTime?.map((ele, id) => {
                                                    return <span key={id}>{ele?.time}</span>;
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <CustomButton
                        className="createBtn"
                        title={updateLoading ? <ButtonLoader /> : "Create Timetable"}
                        onClick={handleProfileUpdate}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateTimeTable;
