import axios from "axios";
import {
  UNFOLLOW_FAIL,
  UNFOLLOW_REQUEST,
  UNFOLLOW_SUCCESS,
  CLEAR_DELETE_FRIEND_STATE,
  CLEAR_FOLLOW_ERROR,
  CLEAR_FOLLOW_STATE,
  FOLLOW_REQUEST_FAIL,
  FOLLOW_REQUEST_SEND,
  FOLLOW_REQUEST_SUCCESS,
  GET_FOLLOWER_FAIL,
  GET_FOLLOWER_REQUEST,
  GET_FOLLOWER_SUCCESS,
  REMOVE_FOLLOWER_FAIL,
  REMOVE_FOLLOWER_REQUEST,
  REMOVE_FOLLOWER_SUCCESS,
  VIEW_FRIEND_PROFILE_REQUEST,
  VIEW_FRIEND_PROFILE_SUCCESS,
  VIEW_FRIEND_PROFILE_FAIL,
  CLEAR_FIREND_PROFILE_ERROR,
  CLEAR_FIREND_PROFILE_STATE,
} from "../constants/followRequest";

// SEND REQUEST
export const sendFollowRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FOLLOW_REQUEST_SEND,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/send-request/${id}`,
      config
    );

    dispatch({
      type: FOLLOW_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FOLLOW_REQUEST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// ACCEPT REQUEST
export const unfollowSomeone = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UNFOLLOW_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/unfollow/${id}`,
      config
    );

    dispatch({
      type: UNFOLLOW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UNFOLLOW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// ACCEPT REQUEST
export const removeFollower = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_FOLLOWER_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/remove-follower/${id}`,
      config
    );

    dispatch({
      type: REMOVE_FOLLOWER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_FOLLOWER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// VIEW FRIEND PROFILE
export const getFriendDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_FRIEND_PROFILE_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/user-detail/${id}`,
      config
    );

    dispatch({
      type: VIEW_FRIEND_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VIEW_FRIEND_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR FRIEND PROFILE ERROR ACTION FUNCTION
export const clearFriendProfileError = () => async (dispatch) => {
  dispatch({ type: CLEAR_FIREND_PROFILE_ERROR });
};

// CLEAR FRIEND PROFILE STATE  ACTION FUNCTION
export const clearFriendProfileState = () => async (dispatch) => {
  dispatch({ type: CLEAR_FIREND_PROFILE_STATE });
};

// GET MY FOLLOWERS
export const getFollowerList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOLLOWER_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/my-follows`,
      config
    );

    dispatch({
      type: GET_FOLLOWER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_FOLLOWER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearFollowErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_FOLLOW_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearFollowState = () => async (dispatch) => {
  dispatch({ type: CLEAR_FOLLOW_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearRemoveFollowState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_FRIEND_STATE });
};
