import React from "react";
import videoIcon from "../../images/video_icon.png";
import arIconBlack from "../../images/arBlackLogo.png";
import ImgLazyLoad from "../reusable/ImgLazyLoad";

const LearnBestCard = ({ img, title, description, language }) => {
  return (
    <div className="learn_best_card">
      <div className="learn_best_card_img">
        {/* <img src={img} alt={`Alpha Regiment Teacher ${title}`} /> */}
        <ImgLazyLoad
          src={img}
          alt={`Alpha Regiment Teacher ${title}`}
          custom_class=""
        />
        <img
          className="absoluteImg_top"
          src={arIconBlack}
          alt="Alpha Regiment Ar icon"
        />
        <img
          className="absoluteImg_bottom"
          src={videoIcon}
          alt="Alpha Regiment video icon"
        />
      </div>
      <div className="txt_deatils">
        <h2>{title}</h2>
        <p className="speciality">{description}</p>
        <p className="lang">{language}</p>
      </div>
    </div>
  );
};

export default LearnBestCard;
