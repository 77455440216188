import React from "react";
import "./workshopAbout.scss"
import { BsAlarm } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { FaHouseUser } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";

const WorkshopAbout = () => {
  return (
    <div className="about_sec_main">
      <div className="container">
        <div className="about_main_content">
          <div className="abt_txt">
            <p>[ 70+ HOURS OF TRAINING ]</p>
            <h2>Thousands of hours of learning in one location</h2>
            <ul>
              <li>
                An magnis nulla dolor sapien augue erat iaculis purus tempor
                magna ipsum vitae purus primis pretium ligula rutrum luctus
                blandit a porta justo integer. Feugiat a primis ultrice ligula
                risus auctor rhoncus purus ipsum primis
              </li>
              <li>
                Nemo ipsam egestas volute turpis dolores and aliquam quaerat
                sodales sapien undo pretium purus ligula tempus ipsum undo
                auctor and mauris lectus ipsum euismod purus and blandit
              </li>
            </ul>
          </div>
          <div className="abt_info_cards">
            <div className="infoCard">
              <div className="icon">
                <BsAlarm />
              </div>
              <p className="date">September 23-26 2022</p>
              <p className="time">[ 09:00 AM - 05:00 PM ]</p>
            </div>
            <div className="infoCard">
              <div className="icon">
                <GrLocation />
              </div>
              <p className="date">Wst. Conference Center</p>
              <p className="time">[ Melbourne, Australia ]</p>
            </div>
            <div className="infoCard">
              <div className="icon">
                <FiUsers />
              </div>
              <p className="date">38 Keynote Speakers</p>
              <p className="time">[ Top-notch experts ]</p>
            </div>
            <div className="infoCard">
              <div className="icon">
                <FaHouseUser />
              </div>
              <p className="date">782 Available Seats</p>
              <p className="time">[ Few tickets are left ]</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopAbout;
