import React, { Fragment, useEffect, useState } from "react";
import "../../styles/blogDetail.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import {
  clearBlogErrors,
  clearCommentState,
  clearCommenteError,
  clearDeleteCommentState,
  deleteBlogComment,
  deleteReplyComment,
  getAllBlogs,
  getBlogDetailsById,
  postBlogComment,
  replyBlogComment,
} from "../../redux/actions/blogAction";
import { useSelector } from "react-redux";
import Loader from "../../components/reusable/Loader";
import moment from "moment";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { defaultUser, staticImg } from "../../assets/images";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import { useAuth } from "../../context/userContext";
import CustomButton from "../../components/reusable/CustomButton";
import { arRole } from "../../utils/userRole";
import { MdPsychologyAlt } from "react-icons/md";
import { BsPersonFill, BsReplyAll, BsThreeDotsVertical } from "react-icons/bs";
import { GiPoliceOfficerHead } from "react-icons/gi";
import Layout from "../../components/reusable/Layout";
import { Helmet } from "react-helmet";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import CustomHelmet from "../../components/reusable/Helmet";
const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const [comment, setComment] = useState("");
  const [, , showNotification] = useNotification();
  const { loading, error, blogDetails } = useSelector(
    (state) => state.getBlogById
  );
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearBlogErrors());
    }
  }, [dispatch, showNotification, error]);
  useEffect(() => {
    dispatch(getBlogDetailsById(id));
  }, [dispatch, id]);

  //   GET ALL BLOG LIST
  const {
    loading: allBlogLoading,
    error: allBlogError,
    blogs,
  } = useSelector((state) => state.getBlogsList);

  useEffect(() => {
    if (allBlogError) {
      showNotification("error", allBlogError);
      dispatch(clearBlogErrors());
    }
  }, [dispatch, showNotification, allBlogError]);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  // POST COMMENT FUNCTION
  const {
    loading: postCommentLoading,
    error: postCommentError,
    blogComment,
  } = useSelector((state) => state.postCommentBlog);

  const handlePostComment = (id) => {
    if (auth?.user?._id) {
      if (comment !== "") {
        dispatch(postBlogComment(id, comment));
      } else {
        showNotification("error", "Please write your valuable comment");
      }
    } else {
      showNotification("warning", "Please login before comment on blog");
      navigate("/login", { state: `/blog/${id}` });
    }
  };

  useEffect(() => {
    if (postCommentError) {
      dispatch(clearCommenteError());
    }
    if (blogComment?.success) {
      showNotification("success", blogComment?.message);
      setComment("");
      dispatch(clearCommentState());
      dispatch(getBlogDetailsById(id));
    }
  }, [
    dispatch,
    id,
    showNotification,
    blogComment?.success,
    blogComment?.message,
    postCommentError,
  ]);

  const [seeCmnt, setSeeCmnt] = useState(3);
  const handleSeeMore = () => {
    setSeeCmnt(seeCmnt + 5);
  };

  // DELETE USER COMMENT ACTION
  const [unFollowId, setUnFollowId] = useState("");
  const [reply, setReply] = useState({
    show: false,
  });
  const [comntReply, setComntReply] = useState("");
  const [viewReply, setViewReply] = useState({
    show: false,
  });
  const [replyToggle, setReplyToggle] = useState({
    show: false,
  });
  const [settingToggle, setSettingToggle] = useState({
    show: false,
  });

  // console.log(viewReply);

  const handleToggleDelete = (id) => {
    setSettingToggle({
      show: !settingToggle?.show,
      id: id,
    });
  };

  const {
    loading: deleteLoading,
    error: deleteError,
    commentDelete,
  } = useSelector((state) => state.deleteComment);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(clearCommenteError());
    }
    if (commentDelete?.success) {
      showNotification("success", commentDelete?.message);
      setUnFollowId("");
      setSettingToggle({ show: false });
      dispatch(clearDeleteCommentState());
      dispatch(getBlogDetailsById(id));
    }
  }, [
    dispatch,
    showNotification,
    deleteError,
    commentDelete?.success,
    commentDelete?.message,
    id,
  ]);

  const handleDeleteCmnt = (id) => {
    setUnFollowId(id);
    dispatch(deleteBlogComment(id));
  };

  // HANDLE REPLY FUNCTION
  const {
    loading: replyLoading,
    replyComment,
    error: replyError,
  } = useSelector((state) => state.replyCommentBlog);

  useEffect(() => {
    if (replyError) {
      showNotification("error", replyError);
      dispatch(clearCommenteError());
    }
    if (replyComment?.success) {
      showNotification("success", replyComment?.message);
      setReply({ show: false });
      setUnFollowId("");
      dispatch(clearCommentState());
      dispatch(getBlogDetailsById(id));
    }
  }, [
    dispatch,
    showNotification,
    replyError,
    replyComment?.success,
    replyComment?.message,
    id,
  ]);

  const hadnleReplyComment = (data) => {
    setUnFollowId(data?._id);
    dispatch(replyBlogComment(data?._id, comntReply));
  };

  // HANDLE DELETE REPLY TOGGLE
  const handleToggleReplyDelete = (id) => {
    setReplyToggle({
      show: !replyToggle?.show,
      id: id,
    });
  };

  const {
    loading: replyDeleteLoading,
    replyDelete,
    error: replyDeleteError,
  } = useSelector((state) => state.deleteReply);

  useEffect(() => {
    if (replyDeleteError) {
      showNotification("error", replyDeleteError);
      dispatch(clearCommenteError());
    }
    if (replyDelete?.success) {
      showNotification("success", replyDelete?.message);
      setReply({ show: false });
      setUnFollowId("");
      dispatch(clearDeleteCommentState());
      dispatch(getBlogDetailsById(id));
    }
  }, [
    dispatch,
    showNotification,
    replyDeleteError,
    replyDelete?.success,
    replyDelete?.message,
    id,
  ]);

  const handleDeleteReply = (data) => {
    setUnFollowId(data?.replyId);
    dispatch(deleteReplyComment(data?.comntId, data?.replyId));
  };



  const [iframeContent, setIframeContent] = useState('');
  const [otherContent, setOtherContent] = useState('');

  useEffect(() => {
    if (blogDetails?.blog?.blogContent) {

      const url = blogDetails.blog.blogContent.match(/src="([^"]+)"/)[1];
      setIframeContent(url);

      const cleanedContent = blogDetails.blog.blogContent.replace(/<iframe.*?<\/iframe>/g, '');
      setOtherContent(cleanedContent);
    }
  }, [blogDetails]);


  return (
    <Fragment>
      {loading || allBlogLoading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            description={blogDetails?.blog?.metaDescription}
            title={blogDetails?.blog?.metaTitle}
            author="team Alpha Regiment"
            canonical={window?.location?.href}
          />
          <section className="blog_section">
            <div className="container">
              <div className="blog_wrapper txt_color">
                <div className="left_section">
                  {blogDetails?.blog?.image?.url && (
                    <div className="img_Section">
                      <img
                        src={blogDetails?.blog?.image?.url}
                        alt={blogDetails?.blog?.title}
                      />
                    </div>
                  )}

                  <div className="title">
                    <h2>{blogDetails?.blog?.title}</h2>
                    <p>
                      {moment(blogDetails?.blog?.updatedAt).format(
                        "MMMM DD[,] YYYY"
                      )}
                    </p>
                  </div>

                  <div className="blog_content">
                    <div
                      className="blog_content"
                      dangerouslySetInnerHTML={{
                        __html: otherContent,
                      }}
                    ></div>
                    <div
                      className="blog_content"
                      dangerouslySetInnerHTML={{
                        __html: iframeContent,
                      }}
                    ></div>
                    {
                      iframeContent &&
                      <iframe src={iframeContent} style={{marginTop:'20px'}} height="500" width="100%" />
                    }
                  </div>

                  <h4 className="created_by">Created By :</h4>

                  <div className="author_details">
                    <img
                      src={
                        blogDetails?.blog?.authorDetails?.author?.profile?.url
                          ? blogDetails?.blog?.authorDetails?.author?.profile
                            ?.url
                          : defaultUser
                      }
                      alt={`${blogDetails?.blog?.authorDetails?.author?.first_name} officer alpha regiment`}
                    />
                    <div className="txt_details">
                      <div className="top_content">
                        <div>
                          <p>
                            {
                              blogDetails?.blog?.authorDetails?.author
                                ?.first_name
                            }
                          </p>
                          <p>
                            {blogDetails?.blog?.authorDetails?.author?.role ===
                              "16" ? (
                              <GiPoliceOfficerHead />
                            ) : blogDetails?.blog?.authorDetails?.author
                              ?.role === "17" ? (
                              <BsPersonFill />
                            ) : (
                              blogDetails?.blog?.authorDetails?.author?.role ===
                              "18" && <MdPsychologyAlt />
                            )}
                            {arRole(
                              blogDetails?.blog?.authorDetails?.author?.role
                            )}
                          </p>
                        </div>
                        <div>
                          <p>Posted On :</p>
                          <p>
                            {moment(blogDetails?.blog?.updatedAt).format(
                              "MMMM DD[,] YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="subscribe_wrap">
                        {
                          blogDetails?.blog?.authorDetails?.socialLinks?.length > 0 &&
                          <>
                            <h4>Connect On:</h4>
                            <div className="follow_list">
                              {
                                blogDetails?.blog?.authorDetails?.socialLinks
                                  ?.instagram &&
                                <Link
                                  to={
                                    blogDetails?.blog?.authorDetails?.socialLinks
                                      ?.instagram
                                  }
                                  title="Instagram"
                                  rel="nofollow noopener"
                                  target="_blank"
                                >
                                  <FaInstagram />
                                </Link>
                              }

                              {
                                blogDetails?.blog?.authorDetails?.socialLinks
                                  ?.youtube &&
                                <Link
                                  to={
                                    blogDetails?.blog?.authorDetails?.socialLinks
                                      ?.youtube
                                  }
                                  title="Youtube"
                                  rel="nofollow noopener"
                                  target="_blank"
                                >
                                  <FaYoutube />
                                </Link>
                              }

                              {
                                blogDetails?.blog?.authorDetails?.socialLinks
                                  ?.facebook &&
                                <Link
                                  to={
                                    blogDetails?.blog?.authorDetails?.socialLinks
                                      ?.facebook
                                  }
                                  title="Facebook"
                                  rel="nofollow noopener"
                                  target="_blank"
                                >
                                  <FaFacebookF />
                                </Link>
                              }

                              {
                                blogDetails?.blog?.authorDetails?.socialLinks
                                  ?.twitter &&
                                <Link
                                  to={
                                    blogDetails?.blog?.authorDetails?.socialLinks
                                      ?.twitter
                                  }
                                  title="Twitter"
                                  rel="nofollow noopener"
                                  target="_blank"
                                >
                                  <FaTwitter />
                                </Link>
                              }

                            </div></>
                        }

                      </div>
                    </div>
                  </div>

                  <div className="post_cmnt">
                    <p className="title">Leave Comment</p>
                    <div className="post_data">
                      <div className="cmnt_details">
                        <img
                          src={
                            auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          alt="defaultUser"
                        />
                        <CustomTextarea
                          value={comment}
                          name="comment"
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Write your comment"
                        />
                      </div>
                    </div>
                    <CustomButton
                      onClick={() => handlePostComment(blogDetails?.blog?._id)}
                      className="post_cmnt_btn"
                      title={postCommentLoading ? <ButtonLoader /> : "Post"}
                      width="70px"
                    />
                  </div>

                  <div className="post_cmnt">
                    <p className="title">Recent Comment</p>
                    <div className="cmnts">
                      {blogDetails?.blog?.comments
                        ?.sort(
                          (a, b) =>
                            new Date(b.updatedAt) - new Date(a.updatedAt)
                        )
                        ?.slice(0, seeCmnt)
                        ?.map((itm) => (
                          <div className="post_data" key={itm?._id}>
                            <div className="cmnt_details">
                              <img
                                src={
                                  itm?.user?.profile?.url
                                    ? itm?.user?.profile?.url
                                    : defaultUser
                                }
                                alt="defaultUser"
                              />
                              <div className="cmnt_data">
                                <div className="user_details">
                                  <h6>{itm?.user?.first_name}</h6>
                                  <span>
                                    {moment(itm?.updatedAt).format(
                                      "DD/MMMM/YYYY"
                                    )}
                                  </span>
                                  <p>{itm?.comment}</p>
                                </div>
                                {auth?.user?._id === itm?.user?._id && (
                                  <div className="delete_btn">
                                    <span
                                      onClick={() =>
                                        handleToggleDelete(itm?._id)
                                      }
                                    >
                                      <BsThreeDotsVertical />
                                    </span>
                                    <div
                                      className={`post_seting ${settingToggle?.show &&
                                        settingToggle?.id === itm?._id
                                        ? "visible"
                                        : ""
                                        }`}
                                    >
                                      <p
                                        onClick={() =>
                                          handleDeleteCmnt(itm?._id)
                                        }
                                      >
                                        {deleteLoading &&
                                          unFollowId === itm?._id ? (
                                          <ButtonLoader />
                                        ) : (
                                          "Delete"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {itm?.reply?.length > 0 && (
                              <div className="replies">
                                {viewReply.show &&
                                  itm?._id === viewReply?.id &&
                                  itm?.reply?.map((ele) => (
                                    <div
                                      className="cmnt_details"
                                      key={ele?._id}
                                    >
                                      <img
                                        src={
                                          ele?.user?.profile?.url
                                            ? ele?.user?.profile?.url
                                            : defaultUser
                                        }
                                        alt="defaultUser"
                                      />
                                      <div className="cmnt_data">
                                        <div className="user_details">
                                          <h6>{ele?.user?.first_name}</h6>
                                          <span>
                                            {moment(ele?.updatedAt).format(
                                              "DD/MMMM/YYYY"
                                            )}
                                          </span>
                                          <p>{ele?.comment}</p>
                                        </div>
                                        {auth?.user?._id === ele?.user?._id && (
                                          <div className="delete_btn">
                                            <span
                                              onClick={() =>
                                                handleToggleReplyDelete(
                                                  ele?._id
                                                )
                                              }
                                            >
                                              <BsThreeDotsVertical />
                                            </span>
                                            <div
                                              className={`post_seting ${replyToggle?.show &&
                                                replyToggle?.id === ele?._id
                                                ? "visible"
                                                : ""
                                                }`}
                                            >
                                              <p
                                                onClick={() =>
                                                  handleDeleteReply({
                                                    comntId: itm?._id,
                                                    replyId: ele?._id,
                                                  })
                                                }
                                              >
                                                {replyDeleteLoading &&
                                                  unFollowId === ele?._id ? (
                                                  <ButtonLoader />
                                                ) : (
                                                  "Delete"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                <div className={viewReply.show ? "view_btn view" : "view"}>
                                  <button
                                    onClick={() =>
                                      setViewReply({
                                        show: !viewReply.show,
                                        id: itm?._id,
                                      })
                                    }
                                  >
                                    View {itm?.reply?.length} Reply
                                  </button>
                                </div>
                              </div>
                            )}

                            {/* {auth?.user?._id ===
                              blogDetails?.blog?.authorDetails?.author?._id && ( */}
                            <div className="reply">
                              {reply.show && reply?.id === itm?._id && (
                                <>
                                  <CustomTextarea
                                    value={comntReply}
                                    name="comntReply"
                                    onChange={(e) =>
                                      setComntReply(e.target.value)
                                    }
                                    placeholder="Write your reply"
                                  />
                                  <div className="reply_btns">
                                    <CustomButton
                                      title={
                                        replyLoading &&
                                          unFollowId === itm?._id ? (
                                          <ButtonLoader />
                                        ) : (
                                          "Post"
                                        )
                                      }
                                      width="130px"
                                      onClick={() => hadnleReplyComment(itm)}
                                    />
                                    <CustomButton
                                      title="Cancel"
                                      width="130px"
                                      onClick={() =>
                                        setReply({ show: false })
                                      }
                                    />
                                  </div>
                                </>
                              )}
                              {!reply.show && reply?.id !== itm?._id && (
                                <CustomButton
                                  title="Reply"
                                  width="130px"
                                  icon={<BsReplyAll />}
                                  onClick={() =>
                                    setReply({ id: itm?._id, show: true })
                                  }
                                />
                              )}
                            </div>
                            {/* )} */}
                          </div>
                        ))}
                    </div>
                    {blogDetails?.blog?.comments?.length > 3 && (
                      <CustomButton
                        onClick={() => handleSeeMore()}
                        className="see_cmnt_btn"
                        title="See More"
                        width="100px"
                      />
                    )}
                  </div>
                </div>

                <div className="right_section">
                  <div className="subscribe_wrap">
                    <h4>SUBSCRIBE & FOLLOW</h4>
                    <div className="follow_list">
                      <Link
                        to="https://www.instagram.com/alpharegimentofficial/"
                        title="Instagram"
                        rel="nofollow noopener"
                        target="_blank"
                      >
                        <FaInstagram />
                      </Link>
                      <Link
                        to="https://www.youtube.com/@alpharegiment"
                        title="Youtube"
                        rel="nofollow noopener"
                        target="_blank"
                      >
                        <FaYoutube />
                      </Link>
                      <Link
                        to="https://linkedin.com/company/alpha-regiment/"
                        title="Linkedin"
                        rel="nofollow noopener"
                        target="_blank"
                      >
                        <FaLinkedin />
                      </Link>
                      <Link
                        to="https://facebook.com/AlphaRegimentOfficial"
                        title="Facebook"
                        rel="nofollow noopener"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </Link>
                      <Link
                        to="https://twitter.com/AlphaRegiment "
                        title="Twitter"
                        rel="nofollow noopener"
                        target="_blank"
                      >
                        <FaTwitter />
                      </Link>
                    </div>
                  </div>
                  <div className="title">
                    <h4>MUST-READ ARTICLES</h4>
                  </div>
                  <div className="recent_blog">
                    {blogs?.blogs
                      ?.sort(
                        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                      )
                      .slice(0, 10)
                      ?.map((item) => (
                        <div className="blog_card" key={item?._id}>
                          <img src={item?.image ? item?.image?.url : staticImg} alt={item?.title} />
                          <div className="details">
                            <Link to={`/blog/${item?._id}`}>
                              {item?.title?.slice(0, 120)}
                            </Link>
                            <span>
                              {moment(item?.updatedAt).format(
                                "MMMM DD[,] YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default BlogDetail;
