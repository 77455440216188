import React, { Fragment, useState } from "react";
import "../../styles/officerCourseList.scss";
import Loader from "../../components/reusable/Loader";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import {
  FaRegPauseCircle,
  FaRegPlayCircle,
  // FaRegSadTear,
} from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { officerSidebarLink } from "./officerSidebarLink";
import { useNavigate } from "react-router-dom";
import {
  deletSsbCourse,
  getOfficerSsbCourses,
  pauseSsbCourse,
  ssbClearDeleteState,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment/moment";
import OfficerNoDataCard from "./OfficerNoDataCard";
import PopupPrompt from "../../components/reusable/PopupPrompt";
import { useNotification } from "../../context/notificationContext";
import {
  OfflineCreateCourse,
  OnlineCourseCreate,
  ResourceCreate,
  noCourse,
} from "../../assets/images";
import Joyride from "react-joyride";
import { OnlineCourseCategoryList } from "./OfficerOnlineCourseArray";
import { OfflineCourseCategoryList } from "./OfflineCourseCategory";

export const CourseCard = ({ image, category, onClick, tooltip }) => {
  return (
    <div className="course_add_card" onClick={onClick}>
      <div>
        <div>
          <img src={image} alt={image + "ssb"} />
        </div>
        <p>{category}</p>
      </div>
      <div className="hover_div">
        <p>{tooltip}</p>
      </div>
    </div>
  );
};

const OfficerCourseList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // SHOW POPUP MSG STATE
  const [popupMsg, setPopupMsg] = useState({});
  const [pauseModal, setPauseModal] = useState({});
  const [mockPause, setMockPause] = useState({});

  const {
    loading,
    error,
    courses: data,
  } = useSelector((state) => state.officerSsbCourses);

  // const { loading, error, courses: data } = officerSsbCourses;

  useEffect(() => {
    dispatch(getOfficerSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, error, showNotification]);

  // DELETE SSB COUIRSE BY ID START
  const {
    loading: courseDeleteLoading,
    course,
    error: courseDeleteError,
  } = useSelector((state) => state.deleteSsbCourse);

  // HANDLE DDELETE POPUP SHOW
  const handleDelete = (id) => {
    dispatch(deletSsbCourse(id));
  };

  useEffect(() => {
    if (courseDeleteError) {
      setPopupMsg(false);
      showNotification("error", courseDeleteError);
      dispatch(ssbClearErrors());
    }
    if (course?.success) {
      showNotification("success", course?.message);
      dispatch(getOfficerSsbCourses());
      setPopupMsg(false);
      dispatch(ssbClearDeleteState());
    }
  }, [
    courseDeleteError,
    dispatch,
    course?.message,
    course?.success,
    showNotification,
  ]);

  // DELETE SSB COUIRSE BY ID ENDS
  // DELETE SSB COUIRSE BY ID ENDS

  // VIEW COURSE DETAILS PAGE
  const handleViewDetails = (id) => {
    navigate(`/officer/update-course/${id}`);
  };

  const [pauseData, setPauseData] = useState({
    pauseType: "",
    numberOfDays: "",
  });

  const handlePauseChange = (e) => {
    const { value, name } = e.target;
    setPauseData({
      ...pauseData,
      [name]: value,
    });
  };

  // PAUSE REQUEST STATE FROM STORE
  const {
    loading: pauseLoading,
    error: pauseError,
    status: pauseStatus,
  } = useSelector((state) => state.pauseSsbCourse);

  // COURSE PAUSE REQUEST
  const handlePauseRequest = (id) => {
    const daysNumber = pauseData.numberOfDays;
    if (!pauseModal?.status && pauseData?.pauseType === "") {
      showNotification("error", "Please select pause type");
    } else if (
      !pauseModal?.status &&
      pauseData.pauseType === "Pause for days" &&
      pauseData?.numberOfDays === ""
    ) {
      showNotification("error", "Please choose number of days");
    } else if (
      pauseData.pauseType === "Pause for days" &&
      !pauseModal?.status &&
      (daysNumber < 1 || daysNumber === 0)
    ) {
      showNotification("error", "Please enter valid days number");
    } else {
      const daysNumber = pauseData.numberOfDays
        ? parseInt(pauseData.numberOfDays)
        : null;
      dispatch(pauseSsbCourse(id, daysNumber, !pauseModal?.status));
    }
  };
  // PAUSE MOCK COURSES
  const handlePauseMockRequest = (id) => {
    const daysNumber = pauseData.numberOfDays
      ? parseInt(pauseData.numberOfDays)
      : null;
    dispatch(pauseSsbCourse(id, daysNumber, !pauseModal?.status));
  };

  useEffect(() => {
    if (pauseError) {
      dispatch(ssbClearErrors());
      showNotification("error", pauseError);
    }
    if (pauseStatus?.success) {
      showNotification("success", pauseStatus?.message);
      dispatch(ssbClearDeleteState());
      setPauseModal(false);
      setMockPause(false);
      dispatch(getOfficerSsbCourses());
    }
  }, [
    dispatch,
    showNotification,
    pauseError,
    pauseStatus?.success,
    pauseStatus?.message,
  ]);

  const [showJoyride, setShowJoyride] = useState(true);
  const steps = [
    {
      target: ".title button",
      content: "Click here to add a new course",
      disableBeacon: true,
      placement: "bottom",
    },
  ];
  const customLocale = {
    last: "OK",
  };

  const handleCreateCourse = (data) => {
    navigate("/officer/create-course", { state: { existData: data } });
  };

  const handleCreateDossier = (link) => {
    if (link !== "") {
      navigate(link);
    }
  };

  const handleCancelPause = () => {
    setPauseModal(false);
    setMockPause(false);
    setPauseData({
      pauseType: "",
      numberOfDays: "",
    });
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <div className="online_course_card">
              <div className="title">
                <img src={OnlineCourseCreate} alt="online" />
                <p>Create Online Courses</p>
              </div>
              <div className="course_list">
                {OnlineCourseCategoryList?.filter(
                  (itm) => itm?.category === "Online"
                )?.map((ele, idx) => {
                  return (
                    <CourseCard
                      key={idx}
                      tooltip={ele?.tooltip}
                      image={ele?.image}
                      category={ele?.label}
                      onClick={() => handleCreateCourse(ele)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="online_course_card">
              <div className="title">
                <img src={OfflineCreateCourse} alt="online" />
                <p>Create Offline Courses</p>
              </div>
              <div className="course_list">
                {OfflineCourseCategoryList?.map((ele, idx) => {
                  return (
                    <CourseCard
                      key={idx}
                      tooltip={ele?.tooltip}
                      image={ele?.image}
                      category={ele?.label}
                      onClick={() => handleCreateCourse(ele)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="online_course_card">
              <div className="title">
                <img src={ResourceCreate} alt="online" />
                <p>Create Resources</p>
              </div>
              <div className="course_list">
                {OnlineCourseCategoryList?.filter(
                  (itm) => itm?.category === "Resources"
                )?.map((ele, idx) => {
                  return (
                    <CourseCard
                      key={idx}
                      tooltip={ele?.tooltip}
                      image={ele?.image}
                      category={ele?.label}
                      onClick={() => handleCreateDossier(ele?.link)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="txt_color officer_course_list">
              <div className="title">
                <h2>Course List</h2>
                {/* <button
                  title="Add New Course"
                  onClick={() => navigate("/officer/create-course")}
                >
                  <FaPlus />
                </button> */}
              </div>
              {!data?.courses?.length && (
                <Joyride
                  steps={steps}
                  continuous={true}
                  run={showJoyride}
                  showProgress={false}
                  showSkipButton={false}
                  locale={customLocale}
                  callback={(data) => {
                    if (data.type === "finished" || data.type === "skipped") {
                      setShowJoyride(false);
                    }
                  }}
                />
              )}

              <div className="batch_timing_main officer_course_content txt_color">
                {data?.courses?.length > 0 ? (
                  <div className="timing_calendar">
                    <div className="timing_table custonScroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Course Name</th>
                            <th>In Collaboration</th>
                            <th>Created Date & Time</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.courses?.map((ele, idx) => (
                            <tr key={ele._id}>
                              <td>
                                <p>{ele?.title}</p>
                              </td>
                              <td>
                                {ele?.collaborator?.map((item) => (
                                  <p key={item._id}>{item?.label}</p>
                                ))}
                              </td>
                              <td>
                                <p>
                                  {moment(ele?.createdAt).format(
                                    "MMMM-DD-YYYY [at] h:mm A"
                                  )}
                                </p>
                              </td>

                              <td className="txt_center">
                                <p>
                                  {ele?.isPause ? (
                                    <span
                                      className={`${
                                        ele?.isPause ? "red" : "green"
                                      }`}
                                    >
                                      Course Paused
                                    </span>
                                  ) : (
                                    <span
                                      className={`${
                                        ele?.status === "Approval Pending"
                                          ? "yellow"
                                          : ele?.status === "Active"
                                          ? "green"
                                          : "white"
                                      }`}
                                    >
                                      {ele?.status === "Active"
                                        ? "Running"
                                        : ele?.status}
                                    </span>
                                  )}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <button
                                    title="View/Edit Course"
                                    onClick={() => handleViewDetails(ele?._id)}
                                  >
                                    <AiFillEye />
                                  </button>
                                  <button
                                    title="Delete Course"
                                    onClick={() =>
                                      setPopupMsg({ show: true, id: ele._id })
                                    }
                                  >
                                    <MdDelete />
                                  </button>
                                  {ele?.category === "SSB Coaching" ||
                                  ele?.category === "Mock SSB Interview" ? (
                                    <button
                                      title={
                                        ele?.isPause
                                          ? "Continue this course"
                                          : "Pause this course"
                                      }
                                      onClick={() =>
                                        setPauseModal({
                                          show: true,
                                          id: ele?._id,
                                          status: ele?.isPause,
                                        })
                                      }
                                    >
                                      {ele?.isPause ? (
                                        <FaRegPlayCircle />
                                      ) : (
                                        <FaRegPauseCircle />
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      title={
                                        ele?.isPause
                                          ? "Continue this course"
                                          : "Pause this course"
                                      }
                                      onClick={() =>
                                        setMockPause({
                                          show: true,
                                          id: ele?._id,
                                          status: ele?.isPause,
                                        })
                                      }
                                    >
                                      {ele?.isPause ? (
                                        <FaRegPlayCircle />
                                      ) : (
                                        <FaRegPauseCircle />
                                      )}
                                    </button>
                                  )}
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <OfficerNoDataCard
                    txt="You are yet to add a course to your arsenal."
                    image={noCourse}
                  />
                )}
              </div>
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}

      {/* DELETE COURSE POPUP START */}
      {/* DELETE COURSE POPUP START */}
      {popupMsg.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title="Are you sure! You want to delete this course."
          confirmOnclick={() => handleDelete(popupMsg?.id)}
          cancelOnclick={() => setPopupMsg(false)}
          loading={courseDeleteLoading}
          inputPlaceholder="DELETE"
          inputLabel="Type DELETE in capital letters"
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}
      {/* DELETE COURSE POPUP ENDS */}

      {/* Pause COURSE POPUP START */}
      {pauseModal.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title={
            pauseModal?.status
              ? "Are you sure! You want to Continue this course."
              : "Are you sure! You want to Pause this course."
          }
          confirmOnclick={() => handlePauseRequest(pauseModal?.id)}
          cancelOnclick={() => handleCancelPause()}
          loading={pauseLoading}
          status={pauseModal?.status}
          inputPausePlaceholder="CONTINUE"
          inputPauseLabel="Type CONTINUE in capital letters"
          pauseTypeLabel="Select Pause Type"
          pauseType={pauseData?.pauseType}
          onPauseChange={handlePauseChange}
          pauseForDays="Enter Number Of Days"
          numberOfDays={pauseData?.numberOfDays}
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}

      {/* Pause COURSE POPUP START */}
      {mockPause.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title={
            mockPause?.status
              ? "Are you sure! You want to Continue this course."
              : "Are you sure! You want to Pause this course."
          }
          confirmOnclick={() => handlePauseMockRequest(mockPause?.id)}
          cancelOnclick={() => handleCancelPause()}
          loading={pauseLoading}
          status={mockPause?.status}
          inputPlaceholder={mockPause?.status ? "CONTINUE" : "PAUSE"}
          inputLabel={!mockPause?.status ? "Type PAUSE in capital letters" : "Type CONTINUE in capital letters"}
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}
    </Fragment>
  );
};

export default OfficerCourseList;
