import { axiosInstance } from "../../utils/axiosConfig";
import {
  ADD_WISHLIST_ITEM_FAIL,
  ADD_WISHLIST_ITEM_REQUEST,
  ADD_WISHLIST_ITEM_SUCCESS,
  CLEAR_DELETE_WISHLIST_ITEM_STATE,
  CLEAR_WISHLIST_ERROR,
  CLEAR_WISHLIST_STATE,
  DELETE_WISHLIST_ITEM_FAIL,
  DELETE_WISHLIST_ITEM_REQUEST,
  DELETE_WISHLIST_ITEM_SUCCESS,
  GET_WISHLIST_ITEM_FAIL,
  GET_WISHLIST_ITEM_REQUEST,
  GET_WISHLIST_ITEM_SUCCESS,
} from "../constants/wishListConstant";

export const addWishListAction = (courseId, isSsb) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_WISHLIST_ITEM_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      "/add-wishlist",
      {
        courseId,
        isSsb,
      },
      config
    );
    dispatch({
      type: ADD_WISHLIST_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_WISHLIST_ITEM_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL WishList
export const getAllWishListAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_WISHLIST_ITEM_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/wishlist-items", config);

    dispatch({
      type: GET_WISHLIST_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_WISHLIST_ITEM_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE WISHLIST ACTION
export const deleteWishlistItem = (id, isSsb) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_WISHLIST_ITEM_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/wishlist/${id}?isSsb=${isSsb}`,
      config
    );

    dispatch({
      type: DELETE_WISHLIST_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_WISHLIST_ITEM_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const wishListClearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_WISHLIST_ERROR });
};

// CLEAR DELETE STATE ACTION FUNCTION
export const wishListClearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_WISHLIST_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const wishListClearDeleteState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_WISHLIST_ITEM_STATE });
};
