import React, { useState } from "react";
import "../styles/contactUs.scss";
import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Contact = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    query: "",
  });

  // deStructure
  const { name, email, phone, query } = contactData;

  // input change function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData({
      ...contactData,
      [name]: value,
    });
  };

  // SUBMIT CONTACT DATA
  const handleSubmit = () => {
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Email sent through contact form",
      Body:
        "<h1>This Email sent through contact form</h1> <br/> <p>Full Name:" +
        name +
        "</P> <p><b>Mobile Number:</b> " +
        phone +
        "</p> <p><b>Email Id:</b> " +
        email +
        "</p> <p><b>Message:</b> " +
        query +
        "</p>",
    };
    if (name && email && phone && query) {
      if (window.Email) {
        window.Email.send(config).then(() => {
          toast.success("Message Sent Successfully.");
          setContactData({
            name: "",
            email: "",
            phone: "",
            query: "",
          });
        });
      }
    } else {
      toast.error("PLease fill your query.");
    }
  };
  return (
    <Layout>
      <CustomHelmet
        keywords="Contact Alpha Regiment"
        description="Call us; we are just a message away. Visit Alpha Regiment Campus, located in Farrukhnagar, Gurgaon."
        author="Team Alpha Regiment"
        title="Alpha Regiment: Contact Us."
        canonical={window?.location?.href}
      />
      <section className="contact_us_main txt_color">
        <div className="container">
          <div className="contact_content">
            <div className="contact_details">
              <div className="title">
                <h1>Contact Us</h1>
                <p>
                  Have a question? Need Some Help? Or just want to say hello?{" "}
                </p>
                <p>We would love to hear from you. </p>
              </div>

              <div className="location_address">
                <h2>WE ARE LOCATED AT </h2>
                <p>
                  Alpha Regiment Campus, Opposite JWL Cold storage,
                  Farrukhnaher, Gurugram, Haryana, 122506
                </p>
                <p>
                  Phone no:{" "}
                  <Link to={`tel:+91${process.env.REACT_APP_MOBILE_NUMBER}`}>
                    {process.env.REACT_APP_MOBILE_NUMBER}
                  </Link>
                </p>
                <p>
                  E-mail:{" "}
                  <Link to="mailto:hello@alpharegiment.com">
                    hello@alpharegiment.com
                  </Link>
                </p>
              </div>
            </div>
            <div className="contact_form">
              <div className="input_sec">
                <label>Name</label>
                <input
                  required
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Ex: Ashish verma"
                  name="name"
                  value={name}
                />
              </div>

              <div className="input_sec">
                <label>Email</label>
                <input
                  required
                  onChange={handleInputChange}
                  type="email"
                  placeholder="Ex: ashishverma123@gmail.com"
                  name="email"
                  value={email}
                />
              </div>

              <div className="input_sec">
                <label>Phone number</label>
                <input
                  required
                  onChange={handleInputChange}
                  type="number"
                  placeholder="Ex: 9876555432"
                  name="phone"
                  value={phone}
                />
              </div>

              <div className="input_sec">
                <label>Your query</label>
                <textarea
                  required
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Write your query here..."
                  name="query"
                  rows={5}
                  value={query}
                />
              </div>
              <button className="contact_submit_btn" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
