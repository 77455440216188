import React, { useState } from "react";
import "../../styles/footer.scss";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaEnvelope,
  FaPhoneAlt,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { BsAndroid2, BsApple } from "react-icons/bs";
import { toast } from "react-toastify";

const Footer = () => {
  // const todayDate = new Date();
  // let currentYear = todayDate.getFullYear();

  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackLoad, setFeedbackLoad] = useState(false);

  // SUBMIT FEEDBACK DATA
  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    await setFeedbackLoad(true);
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Email sent through Common Feedback form",
      Body:
        "<h3>This Email Email sent through Common Feedback form</h3> <br/><p><b>Feedback:</b> " +
        feedbackText +
        "</p>",
    };
    if (feedbackText) {
      if (window.Email) {
        window.Email.send(config).then(() => {
          toast.success("Feedback Sent Successfully.");
          setFeedbackText("");
        });
      }
    } else {
      toast.error("Please fill your query.");
    }
    setFeedbackLoad(false);
  };

  return (
    <div className="footer_main txt_color">
      <div className="container">
        <div className="footer_row">
          <div className="footer_links">
            <p className="linkHeadin">Legal & Policies</p>
            <Link to="/privacy-policies">Privacy Policy</Link>
            <Link to="/term-conditions">Terms & Conditions</Link>
            <Link to="/refund-policies">Refund Policy</Link>
            <br />
            <p className="linkHeadin">Company</p>
            <Link to="/about-us">About us</Link>
            <Link to="/contact">Contact Us</Link>
            <br />
            <p className="linkHeadin">Quick Links</p>
            <Link
              to="https://qna.alpharegiment.com"
              target="_blank"
              rel="nofollow noopener"
            >
              QnA
            </Link>
            <Link
              to="https://alpharegiment.com/blog2/"
              target="_blank"
              rel="nofollow noopener"
            >
              Hindi Blog
            </Link>
            <Link
              to="https://blog.alpharegiment.com/"
              target="_blank"
              rel="nofollow noopener"
            >
              English Blog
            </Link>
            <Link
              to="/sitemap"
              // target="_blank"
              // rel="nofollow noopener"
            >
              Sitemap
            </Link>

            {/* 
            <div className="nav_link">
              <NavLink to="/ssb-courses">SSB</NavLink>
            </div> */}

            {/* <Link to="/sitemap">Sitemap</Link> */}
          </div>
          <div className="feedback_form">
            <div>
              <h2>We value your feedback</h2>
              <input
                type="text"
                name="feedback"
                placeholder="Write your feedback..."
                onChange={(e) => setFeedbackText(e.target.value)}
                value={feedbackText}
              />
              <button
                className={`feedback_btn ${
                  feedbackLoad || feedbackText.length < 1 ? "disabled" : ""
                }`}
                disabled={feedbackLoad ? true : false}
                onClick={handleFeedbackSubmit}
              >
                feedback
              </button>
            </div>
          </div>
          <div className="footer_links">
            <p className="linkHeadin">Join Team Alpha</p>
            {/* <Link to="/products">Products</Link> */}

            <Link
              to="https://alpharegiment.com/we-are-hiring/"
              target="_blank"
              rel="noopener noreferrer"
            >
              We are hiring!
            </Link>
            {/* <Link to="/officer/home">Ex-SSB/AFSB Officers</Link> */}
            <Link to="/medical/home">Retd Military Doctor</Link>
            <Link to="/teacher/home">Teachers</Link>
            <Link to="/officer/home">Ex. SSB/AFSB Officers</Link>
            <Link to="/coach/home">Physical Coach</Link>

            <br />
            <p className="linkHeadin">Alpha Partner</p>
            <Link to="/accommodation/home">Accommodation Partner</Link>
            <Link to="/franchise/home">Franchisee</Link>
            <Link to="/alpha-school">Alpha School</Link>
            {/* <Link to="/affiliate/home">Affiliate</Link> */}
            <Link to="/cyber-partner">Cyber Partner</Link>
          </div>
        </div>
        <div className="social_links">
          <div className="app_link">
            <p>Connect with us</p>
            <div className="links">
              <Link to="https://www.instagram.com/alpharegimentofficial/" title="Instagram" rel="nofollow noopener">
                <FaInstagram />
              </Link>
              <Link to="https://www.youtube.com/@alpharegiment" title="Youtube" rel="nofollow noopener">
                <FaYoutube />
              </Link>
              <Link to="https://linkedin.com/company/alpha-regiment/" title="Linkedin" rel="nofollow noopener">
                <FaLinkedin />
              </Link>
              <Link
                to="https://facebook.com/AlphaRegimentOfficial"
                title="Facebook"
                rel="nofollow noopener"
              >
                <FaFacebookF />
              </Link>
              <Link
                to="https://twitter.com/AlphaRegiment "
                title="Twitter"
                rel="nofollow noopener"
              >
                <FaTwitter />
              </Link>
              <Link
                to="mailto:hello@alpharegiment.com"
                title="Email"
                rel="nofollow noopener"
              >
                <FaEnvelope />
              </Link>
              <Link to="tel:+919034864394" title="Call" rel="nofollow noopener">
                <FaPhoneAlt />
              </Link>
            </div>
          </div>
          <div className="app_link">
            <p>Download our official app</p>
            <div className="links">
              <Link
                to="#!"
                title="Download Android App"
                rel="nofollow noopener"
              >
                <BsAndroid2 />
              </Link>
              <Link to="#!" title="Download IOS App" rel="nofollow noopener">
                <BsApple />
              </Link>
            </div>
          </div>
        </div>
        <p className="copyright_txt">
          Copyright © ARCAPTOR TECHNOLOGIES PRIVATE LIMITED. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
