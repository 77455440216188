import React, { Fragment, useEffect, useState } from "react";
import OfficerLayout from "./OfficerLayout";
import CustomInput from "../../components/reusable/CustomInput";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { useLocation, useNavigate } from "react-router-dom";
// import CustomSelect from "../../components/reusable/CustomSelect";
import "../../styles/officerSchedule.scss";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  clearCalenderErrors,
  clearCalenderState,
  createOfficerCalenderAction,
} from "../../redux/actions/officerCalenderAction";
import moment from "moment";
import { useAuth } from "../../context/userContext";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";

const OfficerSchedule = () => {
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const location = useLocation();
  const navigate = useNavigate();
  const scheduleDate = location?.state;
  const [formData, setFormData] = useState({
    title: "",
    date: scheduleDate,
    startTime: "",
    student: "",
    image: "",
    endTime: "",
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { title, date, startTime, student, image, endTime } = formData;
  // HANDLE UPLOAD PIQ STARTS
  const handleSchedule = () => {
    const officerId = auth?.user?._id;
    const formattedStartTime = moment(startTime, "HH:mm");
    const formattedEndTime = moment(endTime, "HH:mm");
    dispatch(
      createOfficerCalenderAction(
        officerId,
        title,
        date,
        formattedStartTime,
        formattedEndTime,
        student,
        image
      )
    );
  };
  const { error, loading, eventData } = useSelector(
    (state) => state.createCalender
  );
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearCalenderErrors());
    }
    if (eventData?.success) {
      showNotification("success", eventData?.message);
      dispatch(clearCalenderState());
      navigate("/officer/calendar");
    }
  }, [
    navigate,
    dispatch,
    showNotification,
    error,
    eventData?.message,
    eventData?.success,
  ]);
  return (
    <Fragment>
      <OfficerLayout>
        <div className="container">
          <div className="schedule_officer_section">
            <div className="schedule_interview">
              <div className="title">
                <button title="Add Working hrs" onClick={() => navigate(-1)}>
                  <FaArrowLeft />
                </button>
                <p>Book A Time Slot</p>
              </div>
              <div className="data_form">
                <CustomInput
                  label="Enter Title"
                  type="text"
                  name="title"
                  placeholder="Enter title"
                  impStar="*"
                  value={formData.title}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Choose Start Time"
                  type="time"
                  name="startTime"
                  impStar="*"
                  value={formData.startTime}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Choose End Time"
                  type="time"
                  name="endTime"
                  impStar="*"
                  value={formData.endTime}
                  onChange={handleInputChange}
                />
              </div>
              <div className="upload_btn">
                <CustomButton
                  title={loading ? <ButtonLoader /> : "Schedule"}
                  onClick={() => handleSchedule()}
                />
              </div>
            </div>
          </div>
        </div>
      </OfficerLayout>
    </Fragment>
  );
};

export default OfficerSchedule;
