import React, { Fragment, useEffect } from "react";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import "../../styles/courseCurriculum.scss";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/notificationContext";
import { otherTeacherLinks } from "./otherTeacherLinks";
import OfficerLayout from "../officer/OfficerLayout";
import { useSelector } from "react-redux";
import { getMyCreatedCourseAction, teacherClearErrors } from "../../redux/actions/teacherAction";
import { useDispatch } from "react-redux";
import Loader from "../../components/reusable/Loader";
import { FaRegSadTear } from "react-icons/fa";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import { useAuth } from "../../context/userContext";
// import { RWebShare } from "react-web-share";
// import { IoMdShare } from "react-icons/io";

const OTeacherCurriculum = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [auth] = useAuth()
  const [, , showNotification] = useNotification();

  //   VIEW COURSE CURRICULUM
  const handleViewDetails = () => {
    showNotification("warning", "You have not added curriculum yet");
  };


  // GET MY CREATED COURSE START
  const {
    loading: teacherCourseLoading,
    courseData: teacherCourses,
    error: teacherCourseErr,
  } = useSelector((state) => state.teacherCreatedCourse);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getMyCreatedCourseAction(auth?.user?._id));
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (teacherCourseErr) {
      dispatch(teacherClearErrors());
      showNotification("error", teacherCourseErr);
    }
  }, [dispatch, showNotification, teacherCourseErr]);
  // GET MY CREATED COURSE ENDS

  return (
    <Fragment>
      {
        teacherCourseLoading ?
          <Loader />
          :
          <OfficerLayout>
            <ProfileLayout sidebarLinks={otherTeacherLinks}>
              <section className="course_curriculum txt_color">
                <div className="title">
                  <h2>Course Curriculum</h2>
                  {/* <button
                  title="Add New Course"
                  onClick={() => navigate("/officer/create-dossier")}
                >
                  <FaPlus />
                </button> */}
                </div>
                <div className="batch_timing_main officer_course_content txt_color">
                  {teacherCourses?.courses?.filter((itm) => itm?.status !== "Approval Pending")?.length > 0 ? (
                    <div className="timing_calendar">
                      <div className="timing_table custonScroll">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Course Name</th>
                              {/* <th>Created Date & Time</th> */}
                              {/* <th>Status</th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {teacherCourses?.courses?.filter((itm) => itm?.status !== "Approval Pending")?.map((ele, idx) => (
                              <tr>
                                <td>
                                  <p>{ele?.title}</p>
                                </td>

                                {/* <td className="txt_center">
                                <p>
                                  <span
                                    className={`${
                                      ele?.status === "Approval Pending"
                                        ? "yellow"
                                        : ele?.status === "Active"
                                        ? "green"
                                        : "white"
                                    }`}
                                  >
                                    {ele?.status}
                                  </span>
                                </p>
                              </td> */}
                                <td>
                                  <p>



                                    <button
                                      title="View/Edit Course"
                                      onClick={() => handleViewDetails("ele")}
                                    >
                                      {/* <AiFillEye /> */}
                                      View Curriculum
                                    </button>
                                    {/* <RWebShare
                                      data={{
                                        title: 'MCQ Quiz',
                                        text: 'Check out this MCQ quiz!',
                                        url: `${process.env.REACT_APP_URL}/quiz/${ele?._id}`,
                                      }}
                                      onClick={() => console.log("shared successfully!")}
                                    >
                                      <button
                                        title="Share OLQ's/MCQ's set" >
                                        Share OLQ's
                                      </button>
                                    </RWebShare> */}
                                    <button
                                      title="Add Curriculum"
                                      onClick={() =>
                                        navigate(`/teacher/create-curriculum`)
                                      }
                                    >
                                      {/* <AiFillEye /> */}
                                      Add Curriculum
                                    </button>
                                    {/* <button
                                      title="Delete Course"
                                      onClick={() =>
                                        setPopupMsg({ show: true, id: ele._id })
                                      }
                                    >
                                      Delete Curriculum
                                    </button> */}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <OfficerNoDataCard
                      txt="There is no course added yet"
                      icon={<FaRegSadTear />}
                    />
                  )}
                </div>
              </section>
            </ProfileLayout>
          </OfficerLayout>
      }

    </Fragment>
  );
};

export default OTeacherCurriculum;
