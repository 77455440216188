import React, { useState } from "react";
import CustomHelmet from "../components/reusable/Helmet";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";

const ArSchoolTerm = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Term & Condition Alpha Regiment"
        description="Institutes can collaborate with us to offer high-standard courses for all competitive exams under their branding to their students."
        author="Team Alpha Regiment"
        title="AR School Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>Alpha School Terms</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>Alpha School Terms</h1>
                <p>
                  These Alpha School Terms were last updated on February 10,
                  2023.
                </p>
              </div>
              <div className="privacy_text">
                <p>
                  Welcome to Alpha Regiment's Alpha School program, where
                  institutions collaborate with us to empower students in their
                  academic pursuits. By accessing or using our services, you
                  agree to comply with these terms. Please read them carefully
                  before proceeding.
                </p>
                {/* <br /> */}
             
                <h3 className="sub_heading">1. Institution Enrollment</h3>
                <p>
                  Institutions, including colleges and schools, may enroll in
                  Alpha School by Alpha Regiment by providing usernames and
                  passwords to their students. Each institution must enroll a
                  minimum of 5 students, who can access various courses offered
                  on our platform. These students may be preparing for different
                  exams, such as NDA and SSB interviews.
                </p>
                <br />
                <h3 className="sub_heading">
                  2. Government Recognition Requirement
                </h3>
                <p>
                  Participation in Alpha School and offering exam subscriptions
                  requires institutions to hold government recognition,
                  enhancing the credibility and integrity of the educational
                  experience.
                </p>
                <br />
                <h3 className="sub_heading">3. Subscription Models</h3>
                <p>Institutions have three subscription options:</p>
                <div className="sub_term">
                  <span>
                    1. Mobile/Desktop Access: Students can access courses on
                    their personal devices.
                  </span>
                  <span>
                    2. Classroom Installation: Schools install smart boards for
                    in-class learning during school hours.
                  </span>
                  <span>
                    3. Customized Institution Portal: Schools can opt for a
                    branded website tailored to their institution, requiring a
                    minimum enrollment of 20 students.
                  </span>
                </div>
                <p>
                  In instances where Alpha Regiment teachers' courses are
                  unlawfully copied or distributed on third-party platforms
                  without their consent, we take proactive measures to address
                  such unauthorized use. This may involve issuing takedown
                  notices, pursuing legal recourse, and providing support to
                  affected teachers in safeguarding their intellectual property
                  rights.
                </p>
                <br />
                <h3 className="sub_heading">4. Annual Subscription</h3>
                <p>
                  All subscriptions are annual-based. In the event of a student
                  dropout, institutions may reassign usernames and passwords to
                  other students during the subscription period.
                </p>
                <br />
                <h3 className="sub_heading">5. Usage Monitoring</h3>
                <p>
                  We track IP addresses and locations to ensure fair usage. If a
                  username and password are detected on multiple devices
                  simultaneously, indicating distribution among students, we
                  reserve the right to deactivate the account. In such cases,
                  institutions will be subject to fines for reactivation. Repeat
                  offenders may face account suspension or termination.
                </p>
                <br />
                <p>
                  By enrolling in Alpha School, institutions commit to providing
                  students with a conducive learning environment and adhering to
                  our terms and conditions. Let's embark on this educational
                  journey together!
                </p>
                <br />
                <p>
                  For any inquiries or assistance, please contact us at
                  support@alpharegiment.com.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default ArSchoolTerm;
