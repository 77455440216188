import React from "react";
import { medical_exam } from "../../assets/images";
import CustomRating from "./CustomRating";
import CustomButton from "./CustomButton";
import "../../styles/MedicalCard.scss";
import { FaInfoCircle } from "react-icons/fa";
const MedicalAndCoachCard = ({ data, tooltip, onClick }) => {
  // RATING ROUND OFF
  const ratingRoundOff = (value) => {
    var multiplier = Math.pow(10, 1);
    return Math.round(value * multiplier) / multiplier;
  };
  return (
    <div className="coach_card">
      <div className="dr_img">
        <img
          src={data?.profile?.url ? data?.profile?.url : medical_exam}
          alt="coach_img"
        />
      </div>

      <div className="coach_details">
        <h2>{data?.first_name}</h2>
        <p>{data?.teacherBio?.slice(0, 40) + ".."}</p>
        <p className="rating">
          <span className="ratingStar">
            4.5
            <CustomRating val={ratingRoundOff(4.5)} readOnly={true} />
          </span>
        </p>
        <p className="price">
          <span className="discountedPrice">
            {!data?.price || data?.price === 0
              ? "Free of cost"
              : "₹" + data?.price}
          </span>
          {tooltip && (
            <div className="tooltip">
              <FaInfoCircle />
              <span className="tooltiptext">{tooltip}</span>
            </div>
          )}
        </p>
        <div className="cart_btns">
          <CustomButton
            onClick={onClick}
            title="Book Now"
            className="enroll_btn"
          />
        </div>
      </div>
    </div>
  );
};

export default MedicalAndCoachCard;
