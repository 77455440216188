import React, { Fragment, useEffect, useState } from "react";
import "../styles/login.scss";
import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import CustomInput from "../components/reusable/CustomInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../redux/actions/userAction";
import { signIn } from "../assets/images";
import Loader from "../components/reusable/Loader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { useAuth } from "../context/userContext";
import { useNotification } from "../context/notificationContext";

const Login = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // destructure
  const { email, password } = loginData;
  // GEt User after trigger login function
  const { error, loading: loginLoading } = useSelector(
    (state) => state.userLogin
  );
  const location = useLocation();
  // const redirect = location.search
  //   ? location.search.split("=")[1]
  //   : "/student/profile";
  // let redirect = location.state ? location.state : "/student/profile";

  // REDIRECT URL
  let redirect = location.state && location.state;

  // console.log(location.state);
  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // Login Handler Function
  const handleLogin = () => {
    if (email && password) {
      dispatch(login(email, password));
    } else {
      showNotification("warning", "Please fill all details carefully.");
    }
  };

  useEffect(() => {
    // if (userInfo?.success) {
    //   toast.success(userInfo?.message);

    //   // if (location.state) {
    //   //   navigate(redirect);
    //   //   dispatch(clearState());
    //   // }
    //   // else if (loginAs === "Student") {
    //   //   navigate("/student/profile", { state: loginAs });
    //   // } else if (loginAs === "Affiliate") {
    //   //   navigate("/affiliate/profile");
    //   // }
    // }
    // if (redirect) {
    //   dispatch(clearLoading());
    // }
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [error, showNotification, dispatch]);
  useEffect(() => {
    if (auth?.user?._id && redirect) {
      navigate(redirect);
    } else if (auth?.user?.role === "10" || auth?.user?.role === "11") {
      navigate("/student/profile");
    } else if (auth?.user?.role === "13") {
      navigate("/teacher/profile");
    } else if (auth?.user?.role === "15") {
      navigate("/affiliate/profile");
    } else if (
      auth?.user?.role === "16" ||
      auth?.user?.role === "17" ||
      auth?.user?.role === "18"
    ) {
      navigate("/officer/profile");
    } else if (auth?.user?.role === "19") {
      navigate("/doctor/profile");
    }
  }, [auth?.user?.role, auth?.user?._id, navigate, redirect, location.state]);

  // LOGIN URL REQUEST WITH GOOGLE
  const googleLoginHadler = () => {
    window.open(`${process.env.REACT_APP_API}/googlelogin`, "_self");
  };

  return (
    <Fragment>
      {loginLoading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Login Alpha Regiment"
            description="Log in to unlock your path to making the dream of serving your motherland in uniform a reality."
            author="Team Alpha Regiment"
            title="Alpha Regiment: Login"
          />
          <section className="login_sec_main">
            <div className="container">
              <div className="login_form">
                <div className="mobileTitle">
                  <h1>SIGN IN</h1>
                </div>
                <div className="login_logo">
                  <img src={signIn} alt="signIn" />
                </div>
                <div className="login_form_inputs">
                  <div className="title">
                    <h1>SIGN IN</h1>
                  </div>
                  <button
                    className="googleLogin_btn"
                    onClick={googleLoginHadler}
                  >
                    <FcGoogle /> Continue With Google
                  </button>
                  <p
                    style={{
                      color: "white",
                      margin: "20px 0",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    OR
                  </p>
                  <CustomInput
                    label="Unique ID Or Email"
                    type="text"
                    placeholder="Ex: 5267711 / example@gmail.com"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    impStar="*"
                  />
                  <CustomInput
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                    impStar="*"
                    eyeIcon={
                      showPassword ? <AiFillEyeInvisible /> : <AiFillEye />
                    }
                    setshowPassword={setshowPassword}
                  />
                  {/* <CustomSelect
                    readOnly={!email || !password ? true : false}
                    optionArray={userRole}
                    label="Login As"
                    name="loginAs"
                    impStar="*"
                    onChange={handleInputChange}
                  /> */}
                  <button className="login_submit_btn" onClick={handleLogin}>
                    LOGIN
                  </button>
                  <div className="have_account">
                    <p>
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </p>
                    <p>
                      Don’t have an account?{" "}
                      <Link to="/register">Register</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default Login;
