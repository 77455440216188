import {
  CLEAR_DELETE_MCQ_STATE,
  CLEAR_MCQ_ERROR,
  CLEAR_MCQ_STATE,
  CLEAR_MCQ_UPDATE_STATE,
  CREATE_MCQ_FAIL,
  CREATE_MCQ_REQUEST,
  CREATE_MCQ_SUCCESS,
  DELETE_MCQ_FAIL,
  DELETE_MCQ_REQUEST,
  DELETE_MCQ_SUCCESS,
  MCQ_DETAILS_FAIL,
  MCQ_DETAILS_REQUEST,
  MCQ_DETAILS_SUCCESS,
  MY_CREATED_MCQ_LIST_FAIL,
  MY_CREATED_MCQ_LIST_REQUEST,
  MY_CREATED_MCQ_LIST_SUCCESS,
  UPDATE_MCQ_FAIL,
  UPDATE_MCQ_REQUEST,
  UPDATE_MCQ_SUCCESS,
} from "../constants/mcqQuizConstant";

export const createMcqReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_MCQ_REQUEST:
      return {
        loading: true,
        mcqCreate: {},
      };
    case CREATE_MCQ_SUCCESS:
      return {
        loading: false,
        mcqCreate: action.payload,
      };
    case CREATE_MCQ_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_MCQ_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_MCQ_STATE:
      return {
        loading: false,
        error: null,
        mcqCreate: {},
      };

    default:
      return state;
  }
};

export const getMcqDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case MCQ_DETAILS_REQUEST:
      return {
        loading: true,
        mcqDetail: {},
      };
    case MCQ_DETAILS_SUCCESS:
      return {
        loading: false,
        mcqDetail: action.payload,
      };
    case MCQ_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_MCQ_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const mcqUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MCQ_REQUEST:
      return {
        loading: true,
        mcqData: {},
      };
    case UPDATE_MCQ_SUCCESS:
      return {
        loading: false,
        mcqData: action.payload,
      };
    case UPDATE_MCQ_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_MCQ_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_MCQ_UPDATE_STATE:
      return {
        loading: false,
        mcqData: {},
      };

    default:
      return state;
  }
};

export const getMyselfMcqReducer = (state = {}, action) => {
  switch (action.type) {
    case MY_CREATED_MCQ_LIST_REQUEST:
      return {
        loading: true,
        mcqList: {},
      };
    case MY_CREATED_MCQ_LIST_SUCCESS:
      return {
        loading: false,
        mcqList: action.payload,
      };
    case MY_CREATED_MCQ_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_MCQ_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE SSB COURSE RATING
export const deleteMcqReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MCQ_REQUEST:
      return {
        loading: true,
        mcqDelete: {},
      };
    case DELETE_MCQ_SUCCESS:
      return {
        loading: false,
        mcqDelete: action.payload,
      };
    case DELETE_MCQ_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_MCQ_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_DELETE_MCQ_STATE:
      return {
        mcqDelete: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
