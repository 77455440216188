import React, { useState } from "react";
import { Fragment } from "react";
import styled from "styled-components";
import ButtonLoader from "./ButtonLoader";
import CustomInput from "./CustomInput";
import CustomMultiSelect from "./CustomMultiSelect";
import CustomDateInput from "./CustomDateInput";
import CustomProgressBar from "./CustomPorgressBar";
import CustomSelect from "./CustomSelect";

const PromptSection = styled.section`
  height: 100vh;
  width: 100%;
  background-color: rgba(8, 19, 54, 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;

  > .promt_mess_sec {
    background-color: #f2f2f2;
    padding: 50px 30px;
    box-sizing: border-box;
    border-radius: 16px;
    position: relative;
    width: 500px;

    @media screen and (max-width: 575px) {
      width: 100%;
      padding: 25px 15px;
    }
    label {
      color: #333;
    }
    .selectMulti__control {
      border: #333;
    }

    > .prompt-title {
      text-align: center;
      color: #081336;
      font-size: 20px;
      margin-bottom: 30px;
    }
    > .myInput {
      > label {
        color: #333;
      }
      input {
        border: 1px solid #081336;
        font-size: 18px;
      }
    }

    > .btn_grps {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      > button {
        width: 100px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #081336;
        cursor: pointer;
        font-size: 18px;

        &:first-child {
          color: #f2f2f2;
          background-color: red;
        }
        &:last-child {
          color: #f2f2f2;
          background-color: #081336;
        }

        &:hover {
          background-color: transparent;
          color: #081336;
        }
      }
    }
  }
`;

const PopupPrompt = ({
  confirmBtnTxt,
  cancelBtnTxt,
  className,
  confirmOnclick,
  cancelOnclick,
  title,
  lightTitle,
  loading,
  id,
  inputLabel,
  inputPlaceholder,
  courseList,
  courseValue,
  courseFound,
  onCourseChange,
  courseLabel,
  getCurrentDate,
  handleDiscountValidity,
  discountValue,
  discountLabel,
  discountPercentLabel,
  discountPrecentage,
  setDiscountPrecentage,
  imageLabel,
  onFileChange,
  impStar,
  isCoupon,
  progress,
  status,

  // PAUSE STATUS DATA
  pauseTypeLabel,
  pauseType,
  onPauseChange,
  pauseForDays,
  numberOfDays,
  inputPauseLabel,
  inputPausePlaceholder,
}) => {
  // HANDLE INPUT CHANGE
  const [inputString, setInputString] = useState("");
  return (
    <Fragment>
      {title && (
        <PromptSection>
          <div className={`promt_mess_sec ${className ? className : ""}`}>
            {!lightTitle ? (
              <h2 className="prompt-title">{title}</h2>
            ) : (
              <p className="prompt-title">{title}</p>
            )}

            {inputLabel && (
              <CustomInput
                label={inputLabel}
                placeholder={inputPlaceholder}
                value={inputString}
                onChange={(e) => setInputString(e.target.value)}
                type="text"
              />
            )}
             {inputPauseLabel && status && (
              <CustomInput
                label={inputPauseLabel}
                placeholder={inputPausePlaceholder}
                value={inputString}
                onChange={(e) => setInputString(e.target.value)}
                type="text"
              />
            )}

            {progress && <CustomProgressBar progress={progress} />}

            {!status && pauseTypeLabel && (
              <CustomSelect
                label={pauseTypeLabel}
                name="pauseType"
                optionArray={[
                  { option: "Pause Forever" },
                  { option: "Pause for days" },
                ]}
                value={pauseType}
                borderColor="rgb(8, 19, 54)"
                onChange={onPauseChange}
              />
            )}

            {!status && pauseForDays && pauseType === "Pause for days" && (
              <CustomInput
                label={pauseForDays}
                placeholder="Value count in days"
                name="numberOfDays"
                value={numberOfDays}
                onChange={onPauseChange}
                type="number"
              />
            )}

            {imageLabel && (
              <CustomInput
                label={imageLabel}
                impStar={impStar}
                onChange={onFileChange}
                type="file"
                accept=".pdf"
              />
            )}

            {courseList && (
              <CustomMultiSelect
                label={courseLabel}
                impStar="*"
                isMulti={false}
                value={courseValue}
                onChange={onCourseChange}
                optionArray={courseList}
              />
            )}

            {courseFound && (
              <p className="error_msg" style={{ marginBottom: "20px" }}>
                Are you certain about deleting the previous coupon code for the
                selected course? Remember, only one coupon code can be created
                for each course.
              </p>
            )}

            {discountLabel && !courseFound && (
              <CustomDateInput
                label={discountLabel}
                type="date"
                placeholder="Forever"
                name="discountValidity"
                impStar=""
                tooltip="In case forever validity ,leave blank this input"
                onChange={handleDiscountValidity}
                value={discountValue}
                disabledDate={getCurrentDate}
              />
            )}

            {discountPercentLabel && !courseFound && (
              <CustomInput
                label={discountPercentLabel}
                type="number"
                placeholder="Ex: 23%"
                name="discountPrecentage"
                impStar="*"
                onChange={setDiscountPrecentage}
                value={discountPrecentage}
              />
            )}

            {isCoupon && (
              <div className="btn_grps">
                {!courseFound && confirmOnclick && confirmBtnTxt !== "" && (
                  <button
                    onClick={confirmOnclick}
                    className={
                      inputPlaceholder &&
                      (inputString !== inputPlaceholder ? "disabled" : "")
                    }
                    disabled={
                      inputPlaceholder && inputString !== inputPlaceholder
                        ? true
                        : false
                    }
                  >
                    {loading ? <ButtonLoader /> : confirmBtnTxt}
                  </button>
                )}

                {cancelOnclick && (
                  <button onClick={cancelOnclick}>{cancelBtnTxt}</button>
                )}
              </div>
            )}

            {!isCoupon && (
              <div className="btn_grps">
                {confirmOnclick && confirmBtnTxt !== "" && !status && (
                  <button
                    onClick={confirmOnclick}
                    className={
                      inputPlaceholder &&
                      (inputString !== inputPlaceholder ? "disabled" : "")
                    }
                    disabled={
                      inputPlaceholder && inputString !== inputPlaceholder
                        ? true
                        : false
                    }
                  >
                    {loading ? <ButtonLoader /> : confirmBtnTxt}
                  </button>
                )}
                {confirmOnclick && confirmBtnTxt !== "" && status && (
                  <button
                    onClick={confirmOnclick}
                    className={
                      inputPausePlaceholder &&
                      (inputString !== inputPausePlaceholder ? "disabled" : "")
                    }
                    disabled={
                      inputPausePlaceholder && inputString !== inputPausePlaceholder
                        ? true
                        : false
                    }
                  >
                    {loading ? <ButtonLoader /> : confirmBtnTxt}
                  </button>
                )}

                {cancelOnclick && (
                  <button onClick={cancelOnclick}>{cancelBtnTxt}</button>
                )}
              </div>
            )}
          </div>
        </PromptSection>
      )}
    </Fragment>
  );
};

export default PopupPrompt;
