import React, { Fragment } from "react";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import "../../styles/otherTeacherCalender.scss";
import { otherTeacherLinks } from "./otherTeacherLinks";
import OfficerLayout from "../officer/OfficerLayout";
import CustomCalendar from "../../components/reusable/CustomCalendar";
import { FaEdit, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/userContext";
const OtherTeacherCalender = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();

  
  const handleEditTime = (data) => {
    navigate("/update-working-hrs", { state: data });
  };
  return (
    <Fragment>
      <OfficerLayout>
        <ProfileLayout sidebarLinks={otherTeacherLinks}>
          <div className="officer_working_hr">
            <div className="title">
              <h2>Working Hours</h2>
              <button
                title="Add Working hrs"
                onClick={() => navigate("/create-working-hrs")}
              >
                <FaPlus />
              </button>
            </div>
            <div className="working_section">
              {auth?.user?.timeTable?.map((ele) => (
                <div
                  className={`working_data ${
                    ele?.weekDay === "Sunday" ? "weekOff" : ""
                  }`}
                  key={ele?._id}
                >
                  <div>
                    <h4>{ele?.weekDay}</h4>
                    {ele?.classTime?.map((itm, id) => (
                      <p key={id}>
                        <span>{itm?.time}</span>-<span>{itm?.endTime}</span>
                      </p>
                    ))}
                  </div>
                  <p className="edit" onClick={() => handleEditTime(ele)}>
                    <FaEdit />
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="otherTeacher_calendar">
            <div className="title">
              <h2>Calendar</h2>
            </div>
            <div className="calendar_section">
              <CustomCalendar
              />
            </div>
          </div>
        </ProfileLayout>
      </OfficerLayout>
    </Fragment>
  );
};

export default OtherTeacherCalender;
