import React, { Fragment, useState } from "react";
import "../../styles/otherCourseList.scss";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { AiFillEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
// import { IoMdShare } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PopupPrompt from "../../components/reusable/PopupPrompt";
import { useNotification } from "../../context/notificationContext";
import { otherTeacherLinks } from "./otherTeacherLinks";
import OfficerLayout from "../officer/OfficerLayout";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import {
  clearDeleteMcqState,
  clearMcqErrors,
  deleteMcqAction,
  getMyMcqList,
} from "../../redux/actions/mcqAction";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import { noCourse } from "../../assets/images";
import Loader from "../../components/reusable/Loader";
// import { RWebShare } from "react-web-share";

export const CourseCard = ({ image, category, onClick, tooltip }) => {
  return (
    <div className="course_add_card" onClick={onClick}>
      <div>
        <div>
          <img src={image} alt={image + "ssb"} />
        </div>
        <p>{category}</p>
      </div>
      <div className="hover_div">
        <p>{tooltip}</p>
      </div>
    </div>
  );
};

const OTeacherMcqList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // SHOW POPUP MSG STATE
  const [popupMsg, setPopupMsg] = useState({});
  // VIEW COURSE DETAILS PAGE
  const handleViewDetails = (id) => {
    navigate(`/teacher/update-mcq/${id}`);
  };

  // GET MY CREATED MCQ START
  const { loading, error, mcqList } = useSelector(
    (state) => state.getMyselfMcq
  );

  useEffect(() => {
    dispatch(getMyMcqList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearMcqErrors());
      showNotification("error", error);
    }
  }, [dispatch, showNotification, error]);
  // GET MY CREATED MCQ ENDS

  // DELETE MCQ BY ID START

  const handleMCQDelete = (mcqId) => {
    if (mcqId) {
      dispatch(deleteMcqAction(mcqId));
    }
  };

  const {
    loading: deleteLoading,
    error: deleteError,
    mcqDelete,
  } = useSelector((state) => state.deleteMcq);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(clearMcqErrors());
    }
    if (mcqDelete?.success) {
      showNotification("success", mcqDelete?.message);
      dispatch(clearDeleteMcqState());
      dispatch(getMyMcqList());
    }
  });

  // DELETE MCQ BY ID ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={otherTeacherLinks}>
            <div className="txt_color oTeacher_course_list">
              <div className="title">
                <h2>MCQ Quiz List</h2>
                <button
                  title="Add New MCQ Quiz"
                  onClick={() => navigate("/teacher/create-mcq")}
                >
                  <FaPlus />
                </button>
              </div>

              <div className="batch_timing_main officer_course_content txt_color">
                <div className="timing_calendar">
                  <div className="timing_table custonScroll">
                    {!mcqList?.mcqs?.length ? (
                      <OfficerNoDataCard
                        txt="No any mcq set created yet"
                        image={noCourse}
                      />
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Created Date & Time</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mcqList?.mcqs?.map((ele, idx) => (
                            <tr key={ele?._id}>
                              <td>
                                <p>{ele?.title}</p>
                              </td>
                              <td>
                                <p>
                                  {moment(ele?.createdAt).format(
                                    "MMMM-DD-YYYY [at] h:mm A"
                                  )}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <button
                                    title="View/Edit MCQ quiz"
                                    onClick={() => handleViewDetails(ele?._id)}
                                  >
                                    <AiFillEye />
                                  </button>
                                  {/* <RWebShare
                                    data={{
                                      title: "MCQ Quiz",
                                      text: "Check out this MCQ quiz!",
                                      url: `${process.env.REACT_APP_URL}/quiz/${ele?._id}`,
                                    }}
                                    onClick={() =>
                                      console.log("shared successfully!")
                                    }
                                  >
                                    <button title="Share OLQ's/MCQ's set">
                                      <IoMdShare />
                                    </button>
                                  </RWebShare> */}
                                  <button
                                    title="Delete MCQ quiz"
                                    onClick={() =>
                                      setPopupMsg({ show: true, id: ele?._id })
                                    }
                                  >
                                    <MdDelete />
                                  </button>
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}

      {/* DELETE COURSE POPUP START */}
      {/* DELETE COURSE POPUP START */}
      {popupMsg.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title="Are you sure! You want to delete this MCQ set."
          confirmOnclick={() => handleMCQDelete(popupMsg?.id)}
          cancelOnclick={() => setPopupMsg(false)}
          loading={deleteLoading}
          inputPlaceholder="DELETE"
          inputLabel="Type DELETE in capital letters"
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}
      {/* DELETE COURSE POPUP ENDS */}

      {/* PAUSE COURSE POPUP ENDS */}
    </Fragment>
  );
};

export default OTeacherMcqList;
