import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_RAZOR_ERROR,
  CLEAR_RAZOR_STATE,
  RAZOR_KEY_FAIL,
  RAZOR_KEY_REQUEST,
  RAZOR_KEY_SUCCESS,
  RAZOR_PAYMENT_FAIL,
  RAZOR_PAYMENT_REQUEST,
  RAZOR_PAYMENT_SUCCESS,
  RAZOR_PAYMENT_VERIFY_FAIL,
  RAZOR_PAYMENT_VERIFY_REQUEST,
  RAZOR_PAYMENT_VERIFY_SUCCESS,
} from "../constants/razorpayConstants";

// CREATE PAYMENT POST REQUEST
export const createRazorPayment =
  (for_course, ssbCourse, amount, couponCode, emiId, seatBooking,forDoctor) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RAZOR_PAYMENT_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/payments/checkout",
        { for_course, ssbCourse, amount, couponCode, emiId, seatBooking,forDoctor },
        config
      );

      dispatch({
        type: RAZOR_PAYMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RAZOR_PAYMENT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET RAZOR PAY KEY - GET REQUEST
export const getRazorKey = () => async (dispatch) => {
  try {
    dispatch({
      type: RAZOR_KEY_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.get("/payments/razorpay-key", config);

    dispatch({
      type: RAZOR_KEY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RAZOR_KEY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// VERIFY RAZOR PAYMENT - POST REQUEST
export const razorPaymentverification =
  (
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature,
    for_course,
    ssbCourse,
    couponCode,
    emiId,
    seatBooking,
    forDoctor
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RAZOR_PAYMENT_VERIFY_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/payments/payment-verification",
        {
          razorpay_payment_id,
          razorpay_order_id,
          razorpay_signature,
          for_course,
          ssbCourse,
          couponCode,
          emiId,
          seatBooking,
          forDoctor,
        },
        config
      );

      dispatch({
        type: RAZOR_PAYMENT_VERIFY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RAZOR_PAYMENT_VERIFY_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR ERROR ACTION FUNCTION
export const clearRazorErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_RAZOR_ERROR });
};

// CLEAR START ACTION FUNCTION
export const clearRazorState = () => async (dispatch) => {
  dispatch({ type: CLEAR_RAZOR_STATE });
};
