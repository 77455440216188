import React, { useState } from "react";
import "./faqSection.scss";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

const FaqSection = () => {
  const [openFaq, setOpenFaq] = useState(-1);

  const handleShowAnswer = (id) => {
    if (openFaq === id) {
      setOpenFaq(-1);
    } else {
      setOpenFaq(id);
    }
  };
  return (
    <div className="faq_main_sec">
      <div className="container">
        <div className="title">
          <h2>Have Questions? Look Here.</h2>
          <p>
            Eventer 2022 gathered the brightest minds in the tech world. Learn
            new skills and get insights from experts and practitioners from all
            around!
          </p>
        </div>

        <div className="faq_qus_cont">
          {[1, 2, 3].map((item, idx) => (
            <div className="question_card" key={idx}>
              <div className="qus_line" onClick={() => handleShowAnswer(idx)}>
                <span>Which payment methods do you accept?</span>
                <span className={openFaq === idx ? "rotate_icon" : ""}>
                  <FaAngleDown />
                </span>
              </div>
              <div
                className={`ans_drop ${
                  openFaq === idx ? "show_faq_answer" : ""
                }`}
              >
                <ul>
                  <li>
                    An magnis nulla dolor sapien augue erat iaculis purus tempor
                    magna ipsum vitae purus primis pretium ligula rutrum luctus
                    blandit porta justo integer. Feugiat a primis ultrice ligula
                    risus auctor rhoncus purus ipsum primis
                  </li>
                  <li>
                    Nemo ipsam egestas volute turpis dolores ut aliquam quaerat
                    sodales sapien undo pretium purus ligula tempus ipsum undo
                    auctor a mauris lectus ipsum blandit porta justo integer
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="still_have_qus">
          Still have a question? <Link to="#!">Ask your question here</Link>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
