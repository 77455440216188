import React, { Fragment } from 'react'
import ProfileLayout from '../../components/reusable/ProfileLayout'
import OfficerLayout from './OfficerLayout'
import OfficerNoDataCard from './OfficerNoDataCard';
import { officerSidebarLink } from './officerSidebarLink';
const OfficerNote = () => {
    return (
        <Fragment>
            <OfficerLayout>
                <ProfileLayout sidebarLinks={officerSidebarLink}>
                    <div className="txt_color officer_course_list">
                        <div className="title">
                            <h2>Note</h2>
                        </div>
                        <OfficerNoDataCard
                            txt="The authorization to create courses will be provided after we assess your
                                 document and verify your service at SSB/AFSB/NSB. 
                                 This process may take up to three days from the date of account creation."
                        // icon={<FaRegSadTear />}
                        />

                    </div>
                </ProfileLayout>
            </OfficerLayout>
        </Fragment>
    )
}

export default OfficerNote