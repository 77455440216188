import {
  CLEAR_PAYMENT_ERRORS,
  CLEAR_PAYMENT_STATE,
  PAYMENT_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_DONE_REQUEST,
  PAYMENT_DONE_SUCCESS,
  PAYMENT_DONE_FAIL,
  GET_ALL_PAYMENT_REQUEST,
  GET_ALL_PAYMENT_SUCCESS,
  GET_ALL_PAYMENT_FAIL,
  GET_PAYMENT_DETAILS_REQUEST,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAIL,
  UPDATE_PAYMENT_DONE_REQUEST,
  UPDATE_PAYMENT_DONE_SUCCESS,
  UPDATE_PAYMENT_DONE_FAIL,
} from "../constants/instaMojoConstant";

export const createCoursePayment = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return {
        loading: true,
        payment: {},
      };
    case PAYMENT_SUCCESS:
      return {
        loading: false,
        payment: action.payload,
      };
    case PAYMENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PAYMENT_ERRORS:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PAYMENT_STATE:
      return {
        loading: false,
        error: null,
        payment: null,
      };

    default:
      return state;
  }
};

export const userPaymentData = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_DONE_REQUEST:
      return {
        loading: true,
        paymentData: {},
      };

    case PAYMENT_DONE_SUCCESS:
      return {
        loading: false,
        paymentData: action.payload,
      };

    case PAYMENT_DONE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PAYMENT_ERRORS:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PAYMENT_STATE:
      return {
        loading: false,
        error: null,
        paymentData: {},
      };

    default:
      return state;
  }
};

export const updateUserPaymentData = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_DONE_REQUEST:
      return {
        loading: true,
        updatedPaymentData: {},
      };

    case UPDATE_PAYMENT_DONE_SUCCESS:
      return {
        loading: false,
        updatedPaymentData: action.payload,
      };

    case UPDATE_PAYMENT_DONE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PAYMENT_ERRORS:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PAYMENT_STATE:
      return {
        loading: false,
        error: null,
        updatedPaymentData: {},
      };

    default:
      return state;
  }
};

export const getAllPaymentsDoneByUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PAYMENT_REQUEST:
      return {
        loading: true,
        allPayments: {},
      };

    case GET_ALL_PAYMENT_SUCCESS:
      return {
        loading: false,
        allPayments: action.payload,
      };

    case GET_ALL_PAYMENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PAYMENT_ERRORS:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PAYMENT_STATE:
      return {
        loading: false,
        error: null,
        allPayments: {},
      };

    default:
      return state;
  }
};

export const getPaymentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PAYMENT_DETAILS_REQUEST:
      return {
        loading: true,
        paymentDetails: {},
      };

    case GET_PAYMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        paymentDetails: action.payload,
      };

    case GET_PAYMENT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PAYMENT_ERRORS:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PAYMENT_STATE:
      return {
        loading: false,
        error: null,
        paymentDetails: {},
      };

    default:
      return state;
  }
};
