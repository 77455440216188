import React, { useEffect, useRef, useState } from "react";
import "../../../styles/videoCallComponent.scss";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addStream from "../../../redux/actions/addStream";
import updateCallStatus from "../../../redux/actions/updateCallStatus";
import CallInfo from "./CallInfo";
import ChatWindow from "./ChatWindow";
import ActionButtons from "./ActionButtons";
import createPeerConnection from "../utils/createPeerConnection";
import { useChatAuth } from "../../../context/chatContext";
import { clearVideoCallErrors, validateVideoCallLink } from "../../../redux/actions/videoCallAction";
import { useNotification } from "../../../context/notificationContext";
import { proVideoSocketListeners } from "../utils/proSocketListeners";

const ProMainVideoPage = () => {
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  console.log("streams", streams);
  // get query string finder hook
  const [searchParams, setSearchParams] = useSearchParams();
  const [apptInfo, setApptInfo] = useState({});
  const smallFeedEl = useRef(null);
  const largeFeedEl = useRef(null);
  const streamRef = useRef(null);
  const [, , showNotfication] = useNotification();
  const { socket } = useChatAuth()
  const [haveGottenIce, setHaveGottenIce] = useState(false);


  useEffect(() => {
    // fetch the user media
    const fetchMedia = async () => {
      const constraints = {
        video: true,
        audio: true,
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        dispatch(updateCallStatus("haveMedia", true));
        // dispatch will send this function to the redux dispatcher so all reducers
        // we will send 2 args, the who, and the stream
        dispatch(addStream("localStream", stream));
        const { peerConnection, remoteStream } = await createPeerConnection(addIce);
        // we dont know "WHO" we are talking to... yet.
        dispatch(addStream("remote1", remoteStream, peerConnection));
        // We have a peerConnection... let's make an offer!
        // EXCEPT, it's not time yet.
        // socket.emit...
        largeFeedEl.current.srcObject = remoteStream; // We have the remoteStream from the peerConnection. Set the video feed to be the remoteStream just created
      } catch (error) {
        console.log("Err in fetching user media", error);
      }
    };
    fetchMedia();
  }, [dispatch]);
   

  useEffect(() => {
    const getIceAsync = async () => {
      const uuid = searchParams.get('uuid')
      const iceCandidates = await socket.emitWithAck('getIce', uuid, 'professional')
      console.log("Ice Candidates received");
      console.log(iceCandidates);
      iceCandidates.forEach(iceC => {
        for(const s in streams){
          if(s !== 'localStream'){
            const pc = streams[s].peerConnection;
            pc.addIceCandidate(iceC)
            console.log("======Added Ice Candidate!!!");
          }
        }
      });
    }
    if(streams.remote1 && !haveGottenIce){
      setHaveGottenIce(true)
      getIceAsync()
      streamRef.current = streams;
    }
  }, [socket, searchParams, streams, haveGottenIce])
  

  useEffect(() => {
    const setAsyncOffer = async() =>{
      for(const s in streams){
        if(s !== 'localStream'){
          const pc = streams[s].peerConnection
          await pc.setRemoteDescription(callStatus.offer)
          console.log(pc.signalingState) // should be have remote offer
        }
      }
    }
    if(callStatus.offer && streams.remote1 && streams.remote1.peerConnection){
      setAsyncOffer()
    }
  }, [callStatus.offer, streams.remote1, streams])
  

  // CREATE ANSWER FUNCTION START
  useEffect(() => {
    // const createAnswerAsync = async() => {
    //   // we have audio & video, we can make an answer and setLocalDescription
    //   for(const s in streams){
    //     if(s !== 'localStream'){
    //       const pc = streams[s].peerConnection;
    //       const answer = await pc.createAnswer();
    //       await pc.setLocalDescription(answer);
    //       console.log(pc.signalingState); // have local answer
    //       dispatch(updateCallStatus('haveCreatedAnswer', true));
    //       dispatch(updateCallStatus('answer', answer));
    //       // emit the answer to server
    //       const uuid = searchParams.get('uuid')
    //       socket.emit('newAnswer', {answer, uuid})
    //     }
    //   }
    // }
    const createAnswerAsync = async () => {
      try {
        // Loop through streams to create and set local descriptions
        for (const s in streams) {
          if (s !== 'localStream') {
            const pc = streams[s].peerConnection;
            const answer = await pc.createAnswer();
            await pc.setLocalDescription(answer);
            console.log(pc.signalingState); // have local answer
            
            // Update call status
            dispatch(updateCallStatus('haveCreatedAnswer', true));
            dispatch(updateCallStatus('answer', answer));
            
            // Emit the answer to the server
            const uuid = searchParams.get('uuid');
            socket.emit('newAnswer', { answer, uuid });
          }
        }
      } catch (error) {
        console.error('Failed to create answer:', error);
      }
    };
    if(callStatus.audio === 'enabled' && callStatus.video === 'enabled' && !callStatus.haveCreatedAnswer){
      createAnswerAsync()
    }
  }, [callStatus.audio, callStatus.video, callStatus.haveCreatedAnswer, streams, dispatch, searchParams, socket])
  
  // CREATE ANSWER FUNCTION ENDS
 
  // DECODED DATA OF VIDEO CALL
  // const {data: VideoCallData, error: validateVideoCallErr, loading: validateVideoCallLoading} = useSelector(state=>state.validateVideoCall)

  // useEffect(() => {
  //   const token = searchParams.get("token");
  //   if(token){
  //    dispatch(validateVideoCallLink(token)) 
  //   }
  // }, [ dispatch, searchParams])

  // useEffect(() => {
  //   if(VideoCallData?.success){
  //     setApptInfo(VideoCallData?.decodedData)
  //   }
  //    if(validateVideoCallErr){
  //     showNotfication("error", validateVideoCallErr)
  //     dispatch(clearVideoCallErrors())
  //   }
  // }, [VideoCallData?.decodedData, showNotfication, VideoCallData?.success, dispatch, validateVideoCallErr])
  

  useEffect(() => {
    if (socket) {
      proVideoSocketListeners(socket, addIceCandidateToPc);
    }
  }, [socket, dispatch]);

  const addIceCandidateToPc = async(iceC) => {
    console.log("iceToClient in proMainVideoPage", iceC);
    // add an ice candidate from the remote, to the pc
    for(const s in streamRef.current){
        if(s !== 'localStream'){
            const pc = streamRef.current[s].peerConnection;
            await pc.addIceCandidate(iceC)
            console.log('Added an iceCandidate to existing page presence');
        }
    }
  }

  const addIce = (iceC) => {
    console.log("ProMainVideoPage iceC", iceC)
    // Emit a new ice candidate to signaling server
    // const socket = socketConnection(searchParams.get('token'))
    socket.emit('iceToServer', {
      iceC,
      who: "professional",
      uuid: searchParams.get('uuid')
    })
  };

  return (
    <div className="main-video-page">
      <div className="video-chat-wrapper">
        <video id="large-feed" ref={largeFeedEl} autoPlay controls playsInline ></video>
        <video id="own-feed" ref={smallFeedEl} autoPlay controls playsInline ></video>
        {
          callStatus.auido === "off" || callStatus.video === "off" ?
            <div className="call-info">
                <h1>
                    {searchParams.get('client')} is in waiting room.<br />
                    Call will start when audio and video are enabled.
                </h1>
            </div> 
            : 
            <></>
        }
        <ChatWindow />
      </div>
      <ActionButtons smallFeedEl={smallFeedEl} largeFeedEl={largeFeedEl} />
    </div>
  );
};

export default ProMainVideoPage;
