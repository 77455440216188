import React, { Fragment, useEffect, useState } from "react";
import "../styles/studentProfile.scss";
import { FaExpeditedssl, FaUserEdit, FaUpload } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAuth } from "../context/userContext";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/reusable/Loader";
import { defaultUser } from "../assets/images";
import CustomInput from "../components/reusable/CustomInput";
import CustomSelect from "../components/reusable/CustomSelect";
import CustomButton from "../components/reusable/CustomButton";
import {
  clearErrors,
  clearUpdateState,
  removeAvatar,
  updateUserProfile,
  userProfile,
} from "../redux/actions/userAction";
import ButtonLoader from "../components/reusable/ButtonLoader";
import { sidebarLinks } from "./student/studentsSidebarLinks";
import ImgLazyLoad from "../components/reusable/ImgLazyLoad";
import UploadProfilePicForm from "../components/student/UploadProfilePicForm";
import StudentLayout from "../components/student/StudentLayout";
import StudentFooter from "../components/student/StudentFooter";
import { useNotification } from "../context/notificationContext";

const StudentProfile = () => {
  const [uploadProfileModal, setUploadProfileModal] = useState(false);
  const [, , showNotfication] = useNotification();
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const { loading } = useSelector((state) => state.profile); // GET PROFILE STATE FROM REDUCER STORE
  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile); // GET PROFILE STATE FROM REDUCER STORE
  const [editable, setEditable] = useState(false);

  // UPDATE PROFILE STATE
  const [profileData, setProfileData] = useState({
    first_name: auth?.user?.first_name,
    middle_name: auth?.user?.middle_name,
    last_name: auth?.user?.last_name,
    email: auth?.user?.email,
    phone: auth?.user?.phone,
    dob: auth?.user?.dob,
    address: auth?.user?.address,
    gender: auth?.user?.gender,
    profile: auth?.user?.profile,
    fatherName: auth?.user?.fatherName,
    motherName: auth?.user?.motherName,
    parentEmail: auth?.user?.parentEmail,
    parentWhatsAppNo: auth?.user?.parentWhatsAppNo,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const {
    first_name,
    middle_name,
    last_name,
    email,
    phone,
    dob,
    address,
    gender,
    profile,
    fatherName,
    motherName,
    parentEmail,
    parentWhatsAppNo,
  } = profileData;
  // PROFILE UPDATE HANDLER
  const handleProfileUpdate = () => {
    dispatch(
      updateUserProfile({
        first_name,
        middle_name,
        last_name,
        email,
        phone,
        dob,
        address,
        gender,
        profile,
        fatherName,
        motherName,
        parentEmail,
        teacherBio: "",
        subSpec: "",
        assignSubjects: "",
        aff_occupation: "",
        aff_state: "",
        aff_bank_name: "",
        aff_bank_ifsc: "",
        aff_bank_acc_no: "",
        aff_bank_branch: "",
        aff_bank_reg_mob: "",
        aff_upi_id: "",
        parentWhatsAppNo,
        timeTable: auth?.user?.timeTable,
        facebook: auth?.user?.socialLinks?.facebook,
        instagram: auth?.user?.socialLinks?.instagram,
        twitter: auth?.user?.socialLinks?.twitter,
        whatsapp: auth?.user?.socialLinks?.whatsapp,
        language: auth?.user?.language,
      })
    );
  };

  useEffect(() => {
    if (userInfo?.success) {
      setEditable(false);
      showNotfication("success", userInfo?.message);
      setTimeout(() => {
        dispatch(userProfile()); // GET UPDATED USER DETAILS
        dispatch(clearUpdateState());
      }, 3000);
    }
    if (updateError) {
      showNotfication("error", updateError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    userInfo?.success,
    userInfo?.message,
    updateError,
    showNotfication,
  ]);

  // DELETE PROFILE PIC
  const {
    loading: removeProfileLoading,
    avatarInfo: removeProfileInfo,
    error: removeProfileError,
  } = useSelector((state) => state.removeAvatar);

  const deleteProfilePic = () => {
    dispatch(removeAvatar());
  };

  useEffect(() => {
    if (removeProfileError) {
      showNotfication("error", removeProfileError);
      dispatch(clearErrors());
    }
    if (removeProfileInfo?.success) {
      showNotfication("success", removeProfileInfo?.message);
      setTimeout(() => {
        dispatch(userProfile()); // GET UPDATED USER DETAILS
        dispatch(clearUpdateState());
      }, 3000);
    }
  }, [
    dispatch,
    removeProfileError,
    removeProfileInfo?.message,
    removeProfileInfo?.success,
    showNotfication,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <StudentLayout sidebarLinks={sidebarLinks}>
            {/* <ProfileLayout sidebarLinks={sidebarLinks}> */}
            <section className="student_profile_section txt_color">
              {/* <div className="container"> */}
              <h2 className="profilePage_heading">Account Info</h2>

              <div className="user_details_main">
                <div className="user_pro_data">
                  <h2>
                    Profile{" "}
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </h2>
                  <div>
                    <div className="data_card">
                      <CustomInput
                        label="First Name"
                        type="text"
                        placeholder="First Name"
                        impStar={editable ? "*" : ""}
                        name="first_name"
                        value={
                          editable
                            ? profileData?.first_name
                            : auth.user?.first_name
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Middle Name"
                        type="text"
                        placeholder="Middle Name"
                        name="middle_name"
                        value={
                          editable
                            ? profileData?.middle_name
                            : auth.user?.middle_name
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Last Name"
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        value={
                          editable
                            ? profileData?.last_name
                            : auth.user?.last_name
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Email"
                        type="email"
                        placeholder="example@email.com"
                        impStar={editable ? "*" : ""}
                        name="email"
                        value={editable ? profileData?.email : auth.user?.email}
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Whatsapp Number"
                        type="number"
                        placeholder="0987654321"
                        impStar={editable ? "*" : ""}
                        name="phone"
                        value={editable ? profileData?.phone : auth.user?.phone}
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Date Of Birth"
                        type="date"
                        placeholder="26-11-1998"
                        impStar={editable ? "*" : ""}
                        name="dob"
                        value={editable ? profileData?.dob : auth.user?.dob}
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomSelect
                        label="Gender"
                        name="gender"
                        impStar={editable ? "*" : ""}
                        value={
                          editable ? profileData?.gender : auth.user?.gender
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                        optionArray={[
                          {
                            id: 1,
                            option: "Male",
                          },
                          {
                            id: 2,
                            option: "Female",
                          },
                          {
                            id: 3,
                            option: "Others",
                          },
                        ]}
                      />
                      <CustomInput
                        label="Address"
                        type="text"
                        placeholder="Ex: MG Road, Gurugram"
                        impStar={editable ? "*" : ""}
                        name="address"
                        value={
                          editable ? profileData?.address : auth.user?.address
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Father Name"
                        type="text"
                        placeholder="Ex: Naresh Kumar"
                        name="fatherName"
                        value={
                          editable
                            ? profileData?.fatherName
                            : auth.user?.fatherName
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Mother Name"
                        type="text"
                        placeholder="Ex: Sushma Devi"
                        name="motherName"
                        value={
                          editable
                            ? profileData?.motherName
                            : auth.user?.motherName
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Parent Email"
                        type="email"
                        placeholder="Ex: example@email.com"
                        name="parentEmail"
                        value={
                          editable
                            ? profileData?.parentEmail
                            : auth.user?.parentEmail
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Parent WhatsAppNo"
                        type="number"
                        placeholder="Ex: 9876543210"
                        name="parentWhatsAppNo"
                        value={
                          editable
                            ? profileData?.parentWhatsAppNo
                            : auth.user?.parentWhatsAppNo
                        }
                        readOnly={editable ? false : true}
                        onChange={handleInputChange}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      {editable && (
                        <CustomButton
                          className="pro_update_btn"
                          title={updateLoading ? <ButtonLoader /> : `Update`}
                          width="100px"
                          onClick={handleProfileUpdate}
                        />
                      )}
                    </div>
                    <div className="profile_picSec">
                      <div className="user_pro_pic">
                        {editable && (
                          <button
                            className="absolute_btn deleteImage_btn"
                            title="Remove Profile Picture"
                            onClick={() => deleteProfilePic()}
                          >
                            {removeProfileLoading ? (
                              <ButtonLoader />
                            ) : (
                              <RiDeleteBinLine />
                            )}
                          </button>
                        )}

                        <ImgLazyLoad
                          src={
                            auth?.user?.profile?.url
                              ? auth?.user?.profile?.url
                              : defaultUser
                          }
                          alt="alpha regiment user"
                        />
                        {editable && (
                          <button
                            title="Upload Profile Picture"
                            className="absolute_btn uploadImage_btn"
                            onClick={() => setUploadProfileModal(true)}
                          >
                            <FaUpload />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </section>
            {/* UPLOAD PROFILE PICTURE FORM START */}
            {uploadProfileModal && (
              <section className="upload_profile_pic_main">
                <UploadProfilePicForm
                  setUploadProfileModal={setUploadProfileModal}
                />
              </section>
            )}

            {/* UPLOAD PROFILE PICTURE FORM ENDS */}
            {/* </ProfileLayout> */}
          </StudentLayout>
          {/* FOOTER START */}
          <StudentFooter />
          {/* FOOTER ENDS */}
        </>
      )}
    </Fragment>
  );
};

export default StudentProfile;
