import React, { Fragment, useEffect } from "react";
import "../../styles/officerCourseList.scss";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { AiFillEye } from "react-icons/ai";
import { doctorSidebarLinks } from "./doctorSideBarLinks";
import OfficerLayout from "../officer/OfficerLayout";
import { useAuth } from "../../context/userContext";
import { useDispatch } from "react-redux";
import {
  clearProfileErrors,
  userListByRoleAction,
} from "../../redux/actions/userAction";
import { useSelector } from "react-redux";
import Loader from "../../components/reusable/Loader";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";

const DoctorStudentList = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();

  useEffect(() => {
    dispatch(userListByRoleAction(11));
  }, [dispatch]);

  const { loading, error, userList } = useSelector(
    (state) => state.userListByRole
  );

  const studentList = userList?.users?.filter((user) =>
    user.doctorConsult?.some((consultant) => consultant._id === auth.user._id)
  );
  // console.log(studentList);

  useEffect(() => {
    if (error) {
      dispatch(clearProfileErrors());
    }
  }, [error, dispatch]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={doctorSidebarLinks}>
            <div className="txt_color officer_course_list">
              <div className="title">
                <h2>Checkup Student List</h2>
              </div>
              <div className="batch_timing_main officer_course_content txt_color">
                <div className="timing_calendar">
                  <div className="timing_table custonScroll">
                    {studentList?.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Student Name</th>
                            <th>Visit Date & Time</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentList?.map((ele) => (
                            <tr>
                              <td>
                                <p>{ele?.first_name}</p>
                              </td>
                              <td>
                                <p> 09-04-2024</p>
                              </td>

                              <td className="txt_center">
                                <p>
                                  {ele?.isQueryCompleted
                                    ? "Checked"
                                    : "Pending"}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <button title="View Details">
                                    <AiFillEye />
                                  </button>
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <OfficerNoDataCard txt="There was no student consultancy booked " />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default DoctorStudentList;
