import React, { Fragment, useEffect } from "react";
import { MdFilterList } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import { otherTeacherLinks } from "./otherTeacherLinks";
import Loader from "../../components/reusable/Loader";
import OfficerLayout from "../officer/OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { useAuth } from "../../context/userContext";
import {
  getMyCreatedCourseAction,
  teacherClearErrors,
} from "../../redux/actions/teacherAction";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import CourseRevCard from "../officer/CourseRevCard";
import { reviewNoFound } from "../../assets/images";

const OTeacherReviews = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  // GET MY CREATED COURSE START
  const {
    loading: teacherCourseLoading,
    courseData: teacherCourses,
    error: teacherCourseErr,
  } = useSelector((state) => state.teacherCreatedCourse);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getMyCreatedCourseAction(auth?.user?._id));
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (teacherCourseErr) {
      dispatch(teacherClearErrors());
      showNotification("error", teacherCourseErr);
    }
  }, [dispatch, showNotification, teacherCourseErr]);
  // GET MY CREATED COURSE ENDS

  const reviewList = teacherCourses?.courses;

  const reviewsArray = reviewList?.map((item) => item.reviews);

  const reviewCount = reviewsArray?.flat();

  //   console.log(reviewCount);

  return (
    <Fragment>
      {teacherCourseLoading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={otherTeacherLinks}>
            <div className="txt_color officer_course_review">
              <div className="title">
                <h2>Course Reviews</h2>
                <button title="Filter reviews">
                  <MdFilterList />
                </button>
              </div>

              {reviewCount < 1 ? (
                <OfficerNoDataCard
                  image={reviewNoFound}
                  txt="Please request your students to evaluate your content. This contributes to your ranking and aids in attracting more students."
                />
              ) : (
                <div className="reviewListing_main">
                  {reviewList?.map((item, idx) =>
                    item.reviews?.map((ele) => (
                      <CourseRevCard
                        key={ele?._id}
                        data={ele}
                        createBy={item}
                      />
                    ))
                  )}
                </div>
              )}
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OTeacherReviews;
