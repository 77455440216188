import {
  RSS_BLOGS_FAIL,
  RSS_BLOGS_REQUEST,
  RSS_BLOGS_SUCCESS,
} from "../constants/rssBlogConstant";

export const getRssBlogs = (state = {}, action) => {
  switch (action.type) {
    case RSS_BLOGS_REQUEST:
      return {
        loading: true,
        blogs: [],
      };
    case RSS_BLOGS_SUCCESS:
      return {
        loading: false,
        blogs: action.payload,
      };
    case RSS_BLOGS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
