import React, { Fragment, useState } from "react";
import "../../styles/affiliateFeedback.scss";
import { affiliateSidebarLinks } from "./affiliateSidebarLinks";
import AffiliateLayout from "./AffiliateLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import Loader from "../../components/reusable/Loader";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import CustomButton from "../../components/reusable/CustomButton";
import affi_feedbackBg from "../../images/affi_feedbackBg.png";
import { toast } from "react-toastify";

const AffiliateFeedback = () => {
  const loading = false;
  const [affiliateFeedback, setAffiliateFeedback] = useState("");

  // SUBMIT FEEDBACK DATA ON MAIL
  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
 
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Email sent through Affiliate Feedback form",
      Body:
        "<h3>This Email Email sent through Affiliate Feedback form</h3> <br/><p><b>Feedback:</b> " +
        affiliateFeedback +
        "</p>",
    };
    if (affiliateFeedback) {
      if (window.Email) {
        window.Email.send(config).then(() => {
          toast.success("Feedback Sent Successfully.");
          setAffiliateFeedback("");
        });
      }
    } else {
      toast.error("Please fill your query.");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AffiliateLayout>
          <ProfileLayout sidebarLinks={affiliateSidebarLinks}>
            <section className="affiliate_feedback_sec txt_color">
              <div className="title">
                <h2>Feedback</h2>
              </div>
              <div className="feedback_content">
                <img src={affi_feedbackBg} alt="affi_feedbackBg.png" />
                <p>How’s your experience with ALPHA REGIMENT?</p>
                <CustomTextarea
                  rows="7"
                  type="text"
                  onChange={(e) => setAffiliateFeedback(e.target.value)}
                  placeholder="Write Your Feedback.."
                  name="affiliate_feedback"
                  value={affiliateFeedback}
                />
                <CustomButton
                  title="Submit"
                  onClick={handleFeedbackSubmit}
                />
              </div>
            </section>
          </ProfileLayout>
        </AffiliateLayout>
      )}
    </Fragment>
  );
};

export default AffiliateFeedback;
