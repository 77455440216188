import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import {
  BsAlarm,
  BsCheckLg,
  BsChevronDown,
  BsChevronUp,
  BsExclamationCircle,
  BsFillAwardFill,
  BsFillPlayCircleFill,
  BsGlobe,
  BsPeopleFill,
  BsStarFill,
  BsSuitHeart,
} from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { hybrid_classes, noCourse } from "../../assets/images";
import video_icon from "../../images/video_icon.png";
import CustomButton from "../../components/reusable/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/userContext";
import { addItemsToCart } from "../../redux/actions/cartAction";
import { useLocale } from "../../context/locale";
import "../../styles/ssbCourseDetails.scss";
import {
  clearPaymentErrors,
  getAllPayments,
} from "../../redux/actions/instaMojoAction";
import Loader from "../../components/reusable/Loader";
import CustomHelmet from "../../components/reusable/Helmet";
import Layout from "../../components/reusable/Layout";
import CustomAccordion from "../../components/reusable/CustomAccordion";
import {
  FaCartPlus,
  FaChalkboardTeacher,
  FaInfoCircle,
  FaRegStar,
} from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { RiVidiconLine } from "react-icons/ri";
import {
  getAllSsbCourses,
  getSsbCourseDetails,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import { useNotification } from "../../context/notificationContext";
import CustomRating from "../../components/reusable/CustomRating";
import moment from "moment";
import { MockPsy } from "../officer/courseContentArray/MockPsy";
import { MockInterview } from "../officer/courseContentArray/MockInterview";
import { CompleteMockSSB } from "../officer/courseContentArray/CompleteMockSSb";
import {
  checkCouponCodeValidation,
  clearCouponCode,
  userProfile,
} from "../../redux/actions/userAction";
import { useCookies } from "react-cookie";
import CustomInput from "../../components/reusable/CustomInput";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { RWebShare } from "react-web-share";
import { arRole } from "../../utils/userRole";
import ComponentLoading from "../../components/reusable/ComponentLoading";
import {
  clearRazorErrors,
  clearRazorState,
  createRazorPayment,
  getRazorKey,
  razorPaymentverification,
} from "../../redux/actions/razorpayAction";
import {
  clearCalenderErrors,
  clearCalenderState,
  createOfficerCalenderAction,
  getOfficerAvalability,
} from "../../redux/actions/officerCalenderAction";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { notification } from "../../array/notificationList";
import PromotionPopup from "../../components/reusable/PromotionPopup";
// import PromotionPopup from "./PromotionPopup";

import piqPdf from "../../images/PIQ.pdf";
import PopupPrompt from "../../components/reusable/PopupPrompt";
import CustomDateInput from "../../components/reusable/CustomDateInput";
import { MdDateRange } from "react-icons/md";

const CouponPaymentPopup = ({
  showCouponPopup,
  setShowCouponPopup,
  courseDetails,
  handleSchedule,
}) => {
  // const [t] = useLocale();
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const payment = courseDetails?.discountedPrice
    ? courseDetails?.discountedPrice
    : courseDetails?.originalPrice;
  const courseId = courseDetails?._id;
  const discountePercentage = courseDetails?.discount;
  const [seatBook, setSeatBook] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    if (
      courseDetails?.category === "SSB Coaching" &&
      courseDetails?.courseMode === "Offline" &&
      courseDetails?.createdBy?.seatBooking
    ) {
      setSeatBook(true);
    } else {
      setSeatBook(false);
    }
  }, [
    courseDetails?.category,
    courseDetails?.courseMode,
    courseDetails?.createdBy?.seatBooking,
  ]);

  const closeModal = () => {
    // window.location.reload();
    dispatch(clearCouponCode());
    setShowCouponPopup(false);
    // setShowEmiTable(false);
    setCouponCode("");
  };

  // GET RAZOR PAY KEY START
  const {
    key: razorKey,
    error: razorKeyError,
    // eslint-disable-next-line
    loading: razorKeyLoading,
  } = useSelector((state) => state.razorKey);

  const razorPaykey = razorKey?.key;

  useEffect(() => {
    dispatch(getRazorKey());
  }, [dispatch]);

  useEffect(() => {
    if (razorKeyError) {
      dispatch(clearRazorErrors());
    }
  }, [dispatch, razorKeyError]);
  // GET RAZOR PAY KEY ENDS

  // CHECK COUPON CODE IS VALID OR NOT START
  // CHECK COUPON CODE IS VALID OR NOT START
  const handleCodeCheck = () => {
    if (couponCode) {
      dispatch(checkCouponCodeValidation(couponCode));
    } else {
      showNotification("warning", "Please fill Coupon Code!");
    }
  };

  const { loading, isValidCoupon, error } = useSelector(
    (state) => state.checkCouponCode
  );

  let discountedAmount = payment;
  

  if (isValidCoupon?.success) {
    const discounteAmount = (payment * discountePercentage) / 100;
    discountedAmount = payment - discounteAmount;
  } else {
    discountedAmount = payment;
  }

  useEffect(() => {
    if (error) {
      setCouponCode("");
    }
  }, [error]);
  // CHECK COUPON CODE IS VALID OR NOT ENDS
  // CHECK COUPON CODE IS VALID OR NOT ENDS

  // GET COUPON CODE FROM COOKIES START
  // GET COUPON CODE FROM COOKIES START
  const [cookies] = useCookies([]);

  useEffect(() => {
    const myCookieValue = cookies.alphaCouponCodeCookie;
    setCouponCode(myCookieValue);
  }, [cookies]);
  // GET COUPON CODE FROM COOKIES ENDS
  // GET COUPON CODE FROM COOKIES ENDS

  // GET ALL PAYMENTS DONE BY SELF USER START
  const {
    // eslint-disable-next-line
    loading: allPaymentsLoading,
    allPayments,
    error: allPaymentsError,
  } = useSelector((state) => state.allPaymentsDoneByUser);

  const allPaymentsParam = allPayments?.payments;
  // CHECK FOR SEAT BOOKING
  const seatBookingFees = 1000;
  let isSeatBookingDone = [];

  allPaymentsParam?.forEach((payment) => {
    isSeatBookingDone.push(payment?.seatBooking);
  });

  useEffect(() => {
    dispatch(getAllPayments());
  }, [dispatch]);

  useEffect(() => {
    if (allPaymentsError) {
      dispatch(clearPaymentErrors());
    }
  }, [allPaymentsError, dispatch]);
  // GET ALL PAYMENTS DONE BY SELF USER ENDS

  // CREATE PAYEMENT INSTANCE START
  // CREATE PAYEMENT INSTANCE START
  const {
    payment: razorPayment,
    error: razorError,
    loading: razorLoading,
  } = useSelector((state) => state.razorPayment);

  const paymentDone = (amount, for_course, couponCode, emiId, seatBooking) => {
    const ssbCourse = true;
    const amountRound = Math.ceil(amount);
    setInitialData({
      for_course,
      ssbCourse,
      amountRound,
      couponCode,
      emiId,
      seatBooking,
    });
    dispatch(
      createRazorPayment(
        for_course,
        ssbCourse,
        amountRound,
        couponCode,
        emiId,
        seatBooking
      )
    );
  };

  useEffect(() => {
    if (razorPayment?.success) {
      // INTILSE RAZOR PAY FOR PAYENT POPUP START
      const intialiseRazorPay = (order) => {
        var options = {
          key: razorPaykey,
          amount: order.amount,
          currency: "INR",
          order_id: order.id,
          handler: function (response) {
            setPaymentResponse(response);
          },
          prefill: {
            name: auth?.user?.first_name,
            email: auth?.user?.email,
            contact: auth?.user?.phone,
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      };
      intialiseRazorPay(razorPayment?.order);
      // INTILSE RAZOR PAY FOR PAYENT POPUP ENDS
      dispatch(clearRazorState());
    }
    if (razorError) {
      showNotification("error", razorError);
      dispatch(clearRazorErrors());
    }
  }, [
    dispatch,
    razorPaykey,
    razorError,
    razorPayment?.success,
    razorPayment?.order,
    showNotification,
    auth,
  ]);
  // CREATE PAYEMENT INSTANCE ENDS
  // CREATE PAYEMENT INSTANCE ENDS

  // VERIFY PAYMENT START
  // VERIFY PAYMENT START
  const {
    payment: verifyPayment,
    error: verifyPaymentError,
    // eslint-disable-next-line
    loading: verifyverifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  useEffect(() => {
    if (paymentResponse && initialData) {
      dispatch(
        razorPaymentverification(
          paymentResponse.razorpay_payment_id,
          paymentResponse.razorpay_order_id,
          paymentResponse.razorpay_signature,
          initialData?.for_course,
          initialData?.ssbCourse,
          initialData?.couponCode,
          initialData?.emiId,
          initialData?.seatBooking
        )
      );
    }
  }, [paymentResponse, initialData, dispatch]);

  useEffect(() => {
    if (verifyPayment?.success) {
      handleSchedule();
      // localStorage.removeItem("eventObject");
      showNotification("success", verifyPayment?.message);
      dispatch(clearRazorState());
    }
    if (verifyPaymentError) {
      showNotification("error", verifyPaymentError);
      dispatch(clearRazorErrors());
    }
  }, [
    verifyPayment?.success,
    verifyPayment?.message,
    verifyPaymentError,
    showNotification,
    setShowCouponPopup,
    dispatch,
    navigate,
    courseDetails,
    handleSchedule,
  ]);

  // VERIFY PAYMENT ENDS
  // VERIFY PAYMENT ENDS

  return (
    <section
      className={`coupon_payment_popup_main bg_color txt_color ${
        showCouponPopup ? "showPaymentModal" : ""
      }`}
    >
      {showCouponPopup && (
        <>
          <button className="close_modal" onClick={() => closeModal()}>
            <AiOutlineClose />
          </button>
          <div>
            {!seatBook ? (
              <p className="coupon_code_txt">
                If you have coupon for discount fill here!
              </p>
            ) : (
              <p className="coupon_code_txt">
                The remaining fees, including hostel charges of Rs. 400 per day
                (which also covers meals), can be settled directly at the
                counter upon your arrival/reporting.
              </p>
            )}

            {error && <p className="error_line">{error}</p>}
            {isValidCoupon?.success && (
              <p className="succes_line">{isValidCoupon?.message}</p>
            )}

            {!seatBook && (
              <div className="couponCode_sec">
                <CustomInput
                  type="text"
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Coupon Code"
                  name="couponCode"
                  value={couponCode}
                />
                <button
                  className={`check_couponCode_btn ${
                    couponCode?.length <= 0 ? "disabled" : ""
                  }`}
                  onClick={() => handleCodeCheck()}
                >
                  {loading ? <ButtonLoader /> : "Apply Coupon"}
                </button>
              </div>
            )}

            <div className="pay_withoutCoupon">
              {isValidCoupon?.success && (
                <p style={{ color: "green", fontWeight: "bold" }}>
                  This is {isValidCoupon?.isValidCoupon?.name}'s Coupon Code.
                  you get {discountePercentage}% discount for this Course.
                </p>
              )}
              <div className="all_payment_btns">
                {!seatBook && (
                  <CustomButton
                    className=""
                    disabled={razorLoading ? true : false}
                    title={
                      discountedAmount && razorLoading ? (
                        <ButtonLoader />
                      ) : (
                        `Pay ₹${Math.ceil(discountedAmount)}`
                      )
                    }
                    onClick={() =>
                      paymentDone(
                        discountedAmount,
                        courseId,
                        couponCode,
                        null,
                        false
                      )
                    }
                  />
                )}

                {courseDetails?.createdBy?.seatBooking &&
                  courseDetails?.courseMode === "Offline" && (
                    <CustomButton
                      className="seat_book_btn"
                      title={
                        seatBookingFees ? (
                          `Pay ${seatBookingFees} for seat booking`
                        ) : (
                          <ButtonLoader />
                        )
                      }
                      onClick={() =>
                        paymentDone(
                          seatBookingFees,
                          courseId,
                          couponCode,
                          null,
                          true
                        )
                      }
                    />
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const MoreDetailBatchVideo = ({
  videoUrl,
  showDetailVideo,
  setshowDetailVideo,
}) => {
  return (
    <section
      className={`more_batch_details_modal ${
        showDetailVideo ? "show_video_Mocal" : ""
      } bg_color txt_color`}
    >
      <div className="container">
        {showDetailVideo && (
          <div className="video_content">
            <button
              className="close_modal"
              onClick={() => setshowDetailVideo(false)}
            >
              <AiOutlineClose />
            </button>
            <div>
              <div
                style={{
                  left: 0,
                  width: "100%",
                  height: 0,
                  position: "relative",
                  paddingBottom: "56.25%",
                }}
              >
                <figure
                  style={{
                    left: 0,
                    width: "100%",
                    height: 0,
                    position: "relative",
                    paddingBottom: "56.25%",
                    marginBlockEnd: 0,
                    marginBlockStart: 0,
                    marginInlineStart: 0,
                    marginInlineEnd: 0,
                  }}
                >
                  <iframe
                    title="How can Cyber Cafe owners become our partners? Alpha Regiment"
                    src={videoUrl}
                    scrolling="no"
                    style={{
                      border: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      overflow: "hidden",
                      borderRadius: 5,
                    }}
                    allowFullScreen={1}
                    allow="autoplay"
                  />
                </figure>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const SsbCourseDetails = () => {
  const [t] = useLocale();
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const { id: courseId } = useParams();
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [courseData, setcourseData] = useState({});
  const [eventFormData, setEventFormData] = useState({});

  const {
    error: eventError,
    // eslint-disable-next-line
    loading: eventLoading,
    eventData,
  } = useSelector((state) => state.createCalender);

  useEffect(() => {
    if (eventError) {
      showNotification("error", eventError);
      dispatch(clearCalenderErrors());
    }
    if (eventData?.success) {
      showNotification("success", eventData?.message);
      dispatch(clearCalenderState());
    }
  }, [
    navigate,
    dispatch,
    showNotification,
    eventError,
    eventData?.message,
    eventData?.success,
  ]);

  const officerSsbCourseDetails = useSelector(
    (state) => state.getSsbCourseDetails
  );

  const { loading, error, courseDetails: data } = officerSsbCourseDetails;

  const getCourseById = data?.course;

  const handleSchedule = () => {
    const officerId = eventFormData?.officerId;
    const formattedDate = eventFormData?.formattedDate;
    const formattedStartTime = eventFormData?.formattedStartTime;
    const formattedEndTime = eventFormData?.formattedEndTime;
    const title = eventFormData?.title;
    const student = auth?.user?._id;
    const piq = new FormData();
    piq.append("file", image);
    if (piq) {
      dispatch(
        createOfficerCalenderAction(
          officerId,
          title,
          formattedDate,
          formattedStartTime,
          formattedEndTime,
          student,
          piq
        )
      );
    }
  };

  const {
    error: createEventError,
    // eslint-disable-next-line
    loading: createEventLoading,
    eventData: createEventData,
  } = useSelector((state) => state.createCalender);

  useEffect(() => {
    if (createEventError) {
      dispatch(clearCalenderErrors());
    }
    if (createEventData?.success) {
      showNotification("success", createEventData?.message);
      setShowCouponPopup(false);
      navigate("/verification", { state: getCourseById });

      dispatch(userProfile());
      dispatch(clearCalenderState());
    }
  }, [
    createEventError,
    dispatch,
    navigate,
    getCourseById,
    createEventData?.message,
    createEventData?.success,
    showNotification,
  ]);

  // PLAY COURSE VIDEO FUNCTION
  const [videoLink, setVideoLink] = useState("");
  const handleVideoClick = (data) => {
    if (data) {
      const match = data?.match(/[?&]v=([^&]+)/);
      if (match && match[1]) {
        const videoId = match[1];
        const embeddedLink = `https://www.youtube.com/embed/${videoId}`;
        setVideoLink(embeddedLink);
        setshowDetailVideo(true);
      }
    }
  };

  useEffect(() => {
    dispatch(getSsbCourseDetails(courseId));
  }, [courseId, dispatch]);

  const handleBoughtCourseDetail = (id) => {
    dispatch(getSsbCourseDetails(id));
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch, showNotification]);

  // CHNAGE RATING STAR COLOR ACCORDING TO RATING COUNT

  // COURSE PAYMENT HANDLERS AND PAYMENT LOADERS

  // ADD TO CART
  const handleAddToCart = (courseDetails) => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/ssb-course-details/${courseId}` });
      showNotification("warning", "Please login before adding to cart!");
    } else {
      dispatch(addItemsToCart(courseDetails));
      showNotification("success", "Item Added To Cart");
      navigate("/student/cart");
      // dispatch(createPayment(amount, purpose, courseId));
    }
  };

  const [showSection, setShowSection] = useState("course");

  const paymentHandler = (data) => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/ssb-course-details/${courseId}` });
      showNotification("warning", "Please login before proceed to buy");
    } else if (
      getCourseById?.type === "Psychology" ||
      getCourseById?.type === "Interview"
    ) {
      setShowSection("piq");
    } else {
      setShowCouponPopup(true);
      setcourseData(data);
    }
  };

  const {
    // eslint-disable-next-line
    payment: verifyPayment,
    // eslint-disable-next-line
    error: verifyPaymentError,
    loading: verifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  // accordion  function start
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedPurchase, setIsExpandedPurchase] = useState(false);
  const [showDetailVideo, setshowDetailVideo] = useState(false);
  const [isExpandTeacher, setIsExpandTeacher] = useState(false);
  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleContentPurchase = () => {
    setIsExpandedPurchase(!isExpandedPurchase);
  };
  const toggleTeacherExpand = () => {
    setIsExpandTeacher(!isExpandTeacher);
  };
  //   accordion  function ends

  // RATING ROUND OFF
  const ratingRoundOff = (value) => {
    var multiplier = Math.pow(10, 1);
    return Math.round(value * multiplier) / multiplier;
  };

  const [dateValue, setDateValue] = useState();
  const [hrsValue, setHrsValue] = useState();
  const [minValue, setMinValue] = useState();
  const [discountValid, setDiscountValid] = useState(false);

  useEffect(() => {
    const todayDate = new Date();
    const discountValidityDate = new Date(getCourseById?.discountValidity);
    if (getCourseById?.discountValidity !== null) {
      setDiscountValid(todayDate <= discountValidityDate);
    } else {
      setDiscountValid(true);
    }
  }, [getCourseById?.discountValidity]);

  useEffect(() => {
    if (
      getCourseById?.discountedPrice > 0 &&
      getCourseById?.discountValidity !== null
    ) {
      const currentDate = moment();
      const discountValidityDate = moment(getCourseById?.discountValidity);

      const differenceInDays = discountValidityDate.diff(currentDate, "days");
      setDateValue(differenceInDays);

      if (differenceInDays < 1) {
        setHrsValue(discountValidityDate.diff(currentDate, "hours"));
        setMinValue(discountValidityDate.diff(currentDate, "minutes"));
      } else {
        setHrsValue(null);
        setMinValue(null);
      }
    }
  }, [getCourseById?.discountedPrice, getCourseById?.discountValidity]);

  // FILTER SSB COURSES ON THE BEHALF OF ACTIVE CATEGORY
  // GET ALL SSB COURSES
  const allSsbCourses = useSelector((state) => state.getAllSsbCourses);
  const { loading: sbbCoursesLoading, courses } = allSsbCourses;
  const ssbCourses = courses?.courses;

  let boughtCourses = ssbCourses?.filter(
    (itm) =>
      itm?.category === getCourseById?.category &&
      itm?._id !== getCourseById?._id
  );
  if (!boughtCourses?.length) {
    boughtCourses = ssbCourses?.filter(
      (itm) => itm?._id !== getCourseById?._id
    );
  }

  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  const currentPageUrl = window?.location?.href;

  // CHECK WISHLIST EXIST OR NOT
  // const [wishListId, setWishListId] = useState("");

  const {
    // eslint-disable-next-line
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    // setWishListId("");
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      // setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      // setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    // eslint-disable-next-line
    loading: deleteLoading,

    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES
  const {
    // eslint-disable-next-line
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);

  // DUMMY USER POPUP
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [lastNotificationIndex, setLastNotificationIndex] = useState(-1);

  const getRandomIndex = (max) => {
    let randomIndex = currentNotificationIndex;
    while (randomIndex === currentNotificationIndex) {
      randomIndex = Math.floor(Math.random() * max);
    }
    return randomIndex;
  };

  const showNextRandomNotification = () => {
    if (notification?.length > 0) {
      let randomIndex = getRandomIndex(notification?.length);

      setCurrentNotificationIndex(randomIndex);
      setLastNotificationIndex(randomIndex);
      setIsNotificationVisible(true);

      setTimeout(() => {
        setIsNotificationVisible(false);
      }, 10000);
    }
  };

  useEffect(() => {
    const notificationInterval = setInterval(showNextRandomNotification, 30000);
    return () => {
      clearInterval(notificationInterval);
    };
    //eslint-disable-next-line
  }, [notification, currentNotificationIndex, lastNotificationIndex]);

  // HANDLE UPLOAD PIQ
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // HANDLE DOWNLOAD PIQ
  const handleDownload = () => {
    fetch(piqPdf)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "alpha-regiment-student-piq.pdf";
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        showNotification("success", "PIQ download successfully");
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  // HANDLE UPLOAD PIQ STARTS
  const handleUploadPIQ = () => {
    if (image !== undefined) {
      setShowModal(false);
      setShowSection("interview");
    } else {
      showNotification("error", "Please select a pdf to upload");
    }
  };

  // SCHEDULE INTERVIEW STARTS

  const [dateSelect, setDateSelect] = useState(null);

  const [startTime, setStartTime] = useState(null);

  const [endTime, setEndTime] = useState(null);
  const handleAddTime = (itm) => {
    setStartTime(itm?.startTime);
    setEndTime(itm?.endTime);
  };

  // VALIDATE THE DATE INPUT
  const getCurrentDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (getCourseById?.createdBy?._id) {
      dispatch(getOfficerAvalability(getCourseById?.createdBy?._id));
    }
  }, [dispatch, getCourseById?.createdBy?._id]);

  const {
    // eslint-disable-next-line
    loading: avalabilityLoading,
    error: avalabilityError,
    officerAvalability,
  } = useSelector((state) => state.getOfficerTimeAvalability);

  useEffect(() => {
    if (avalabilityError) {
      dispatch(clearCalenderErrors());
    }
  }, [avalabilityError, dispatch]);

  const [slotList, setSlotList] = useState();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setDateSelect(value);
    const weekdayCheck = moment(value).format("dddd");
    const filteredSlots = officerAvalability?.availability?.find(
      (availability) => {
        const availabilityWeekday = moment(availability.weekDay, "dddd").format(
          "dddd"
        );
        return availabilityWeekday === weekdayCheck;
      }
    );

    if (filteredSlots) {
      const data = filteredSlots?.classTime.map((time) => ({ ...time }));
      generateSlotIntervals(data);
    } else {
      setSlotList([]);
    }
  };

  const generateTimeIntervals = (startTime, endTime) => {
    const intervals = [];
    let current = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    while (current.isBefore(end)) {
      const nextHour = current.clone().add(1, "hour");
      intervals.push({
        startTime: current,
        endTime: nextHour,
      });
      current = nextHour;
    }
    return intervals;
  };

  const generateSlotIntervals = (slotList) => {
    slotList.forEach((slot) => {
      const { time: startTime, endTime } = slot;
      const intervals = generateTimeIntervals(startTime, endTime);
      setSlotList(intervals);
    });
  };

  const scheduleRedirect = () => {
    const formattedStartTime = moment(startTime, "HH:mm");
    const formattedEndTime = moment(endTime, "HH:mm");
    if (dateSelect && startTime && endTime) {
      setEventFormData({
        officerId: courseData?.createdBy?._id,
        formattedDate: formattedStartTime,
        formattedStartTime: formattedStartTime,
        formattedEndTime: formattedEndTime,
        title: courseData?.type,
        student: auth?.user?._id,
      });
      setShowSection("course");
      setShowCouponPopup(true);
    } else {
      showNotification("error", "please fill required fields");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            title={getCourseById?.title}
            keywords={getCourseById?.category}
            description={getCourseById?.shortDescription}
            author="Team Alpha Regiment"
            canonical={currentPageUrl}
          />
          {showSection === "course" && (
            <section className="ssb_course_wrapper">
              <div className="course_bg">
                <div className="container">
                  <nav className="bredcrumb_grid">
                    <ol>
                      <li>
                        <Link to="/">Home</Link>/
                      </li>
                      <li className="active">Course Detail</li>
                    </ol>
                  </nav>
                </div>
              </div>

              <div className="all_details">
                <div className="container">
                  <nav className="bredcrumb_grid">
                    <ol>
                      <li>
                        <Link to="/">Home</Link>/
                      </li>
                      <li className="active">Course Detail</li>
                    </ol>
                  </nav>
                  <div className="course_details">
                    <div className="right_section">
                      <div className="headig_txt">
                        <div
                          className="course_detail_img"
                          onClick={() =>
                            handleVideoClick(getCourseById?.bannervideoLink)
                          }
                        >
                          <img
                            className="main_hero_img"
                            src={getCourseById?.thumbnail?.url}
                            alt="cyberPartnerHero"
                          />
                          {getCourseById?.bannervideoLink !== "" && (
                            <img
                              className="video_icon"
                              src={video_icon}
                              alt="videoIcon"
                            />
                          )}
                        </div>

                        <h1>{getCourseById?.title}</h1>
                        <p>{getCourseById?.shortDescription}</p>
                        <div className="ratings">
                          {getCourseById?.numOfReviews > 0 ? (
                            <>
                              <div className="stars">
                                <p>{ratingRoundOff(getCourseById?.rating)}</p>
                                <CustomRating
                                  val={ratingRoundOff(getCourseById?.rating)}
                                  readOnly={true}
                                />
                                <Link
                                  to={`/ssb-course/rating/${getCourseById?._id}`}
                                >
                                  ( {getCourseById?.numOfReviews} )
                                </Link>
                              </div>
                              <div className="count">
                                <Link
                                  to={`/ssb-course/rating/${getCourseById?._id}`}
                                >
                                  See all reviews
                                </Link>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="stars">
                                <p>4.5</p>
                                <CustomRating
                                  val={ratingRoundOff(4.5)}
                                  readOnly={true}
                                />
                              </div>
                              <div className="count">
                                <Link
                                  to={`/ssb-course/rating/${getCourseById?._id}`}
                                >
                                  See all reviews
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="updates">
                          <ul>
                            <li>
                              <BsExclamationCircle />
                              <p>
                                Last Updated{" "}
                                {moment(getCourseById?.updatedAt).format(
                                  "MM/DD/YYYY"
                                )}
                              </p>
                            </li>
                            <li>
                              <BsGlobe />
                              <p>English</p>
                            </li>
                            <li>
                              {(getCourseById?.courseMode === "Online" && (
                                <FaChalkboardTeacher />
                              )) ||
                                (getCourseById?.courseMode === "Offline" && (
                                  <GiTeacher />
                                ))}
                              <p>{getCourseById?.courseMode}</p>
                            </li>
                            <li>
                              {(getCourseById?.classType === "Recorded" && (
                                <RiVidiconLine />
                              )) ||
                                (getCourseById?.classType === "Live" && (
                                  <RiVidiconLine />
                                )) ||
                                (getCourseById?.classType === "Hybrid" && (
                                  <img src={hybrid_classes} alt="" />
                                ))}
                              <p>{getCourseById?.classType}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="left_section_mob">
                        <div className="details">
                          <div className="price_text">
                            <ul className="prize">
                              {getCourseById?.discountedPrice > 0 &&
                                discountValid && (
                                  <li>
                                    <h4>
                                      Rs{" "}
                                      {Math.ceil(
                                        getCourseById?.discountedPrice
                                      )}
                                    </h4>
                                  </li>
                                )}

                              {getCourseById?.discountedPrice > 0 &&
                              discountValid ? (
                                <>
                                  <li>
                                    <strike>
                                      Rs{" "}
                                      {Math.ceil(getCourseById?.originalPrice)}
                                    </strike>
                                  </li>
                                  {/* <li> <p>70% off</p> </li> */}
                                </>
                              ) : (
                                <li>
                                  <h4>
                                    Rs {Math.ceil(getCourseById?.originalPrice)}
                                  </h4>
                                </li>
                              )}
                            </ul>
                            {getCourseById?.discountedPrice > 0 &&
                              getCourseById?.discountValidity &&
                              (dateValue > 0 ||
                                hrsValue > 0 ||
                                minValue > 0) && (
                                <span>
                                  <p>
                                    <strong>
                                      {dateValue < 15 && dateValue > 0 ? (
                                        <span>
                                          <BsAlarm /> {dateValue} days left at
                                          this price
                                        </span>
                                      ) : hrsValue > 0 && hrsValue !== null ? (
                                        <span>
                                          <BsAlarm /> {hrsValue} hours left at
                                          this price
                                        </span>
                                      ) : minValue > 0 && minValue !== null ? (
                                        <span>
                                          <BsAlarm /> {minValue} min left at
                                          this price
                                        </span>
                                      ) : null}
                                    </strong>
                                  </p>
                                </span>
                              )}

                            {(getCourseById?.pauseForDate !== null ||
                              !getCourseById?.pauseForDate) &&
                              (getCourseById?.category === "SSB Coaching" ||
                                getCourseById?.category ===
                                  "Mock SSB Interview") && (
                                <span>
                                  {/* <p> */}
                                  New Batch Starts on{" "}
                                  {moment(getCourseById?.pauseForDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                  {/* </p> */}
                                </span>
                              )}

                            <p className="money_back">
                              7-Days Money-Back Guarantee
                              <span className="tooltip">
                                <FaInfoCircle />
                                <span className="tooltiptext">
                                  The 7-day money-back guarantee applies solely
                                  to courses with a longer duration; it does not
                                  extend to Mock Interviews and Mock Psychology
                                  Tests, as these services are typically
                                  utilized within the initial 7-day period.
                                </span>
                              </span>
                            </p>
                            {getCourseById?.googleLocation !== "" && (
                              <div className="location_map">
                                <span>
                                  <CiLocationOn />
                                  <p>
                                    Enable your location to calculate the
                                    precious distance
                                  </p>
                                </span>

                                <div className="progress_sec">
                                  <CustomButton
                                    className="location_allow"
                                    title={t("Allow Location")}
                                    // onClick={onClick}
                                    icon={""}
                                  />
                                  <div className="progress-bar">
                                    <div
                                      className="progress"
                                      style={{ width: "40%" }}
                                    ></div>
                                  </div>
                                  <div className="distance_calculator">
                                    <p style={{ width: "68%" }}>
                                      12km km distance from your location
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {getCourseById?.courseInclude.length > 0 && (
                              <ul className="feature">
                                <h4>This course includes:</h4>
                                {getCourseById?.courseInclude?.map(
                                  (ele, id) => (
                                    <li key={id}>
                                      <IoCheckmarkDoneSharp />
                                      <p>{ele}</p>
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                            <ul className="share_things">
                              <li>
                                <RWebShare
                                  data={{
                                    url: `${window.location.origin}/view-profile/${getCourseById?._id}`,
                                    title: `Share ${getCourseById?.title} profile`,
                                  }}
                                  onClick={() => console.log("shared")}
                                >
                                  <p>Share</p>
                                </RWebShare>
                              </li>
                              <li>
                                <Link to="/">Gift this course</Link>
                              </li>
                              <li>
                                <Link to="/">Apply Coupen</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="what_to_learn">
                        <h4>Course Feature</h4>
                        <div className="points">
                          <ul>
                            {getCourseById?.featureList.map((ele) => (
                              <li key={ele?._id}>
                                <BsCheckLg />
                                <p>{ele?.feature}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {getCourseById?.category === "Mock Interview" && (
                        <div className="interview_content">
                          <div className="title">
                            <h4>Steps</h4>
                          </div>
                          <div className="rightSide">
                            <div className="steps">
                              <div className="step">
                                <div>
                                  <span>1</span>
                                  <span>Enroll</span>
                                </div>
                                <p>
                                  Enrolling in Mock Interview is easy with any
                                  payment method. If you encounter any issues,
                                  we're here to help.
                                </p>
                              </div>
                              <div className="step">
                                <div>
                                  <span>2</span>
                                  <span>Submit PIQ </span>
                                </div>

                                <p>
                                  You have the option to download the PIQ form
                                  as a PDF, fill it out, and then easily upload
                                  it either as an image or PDF file.
                                </p>
                              </div>
                              <div className="step">
                                <div>
                                  <span>3</span>
                                  <span>Schedule Interview</span>
                                </div>
                                <p>
                                  Select a date and time for your Interview that
                                  aligns with your schedule and is convenient
                                  for the officer as well.
                                </p>
                              </div>
                              <div className="step">
                                <div>
                                  <span>4</span>
                                  <span>Interview + Feedback</span>
                                </div>

                                <p>
                                  Enjoy the interview at your preferred date and
                                  time, and you'll receive feedback from the
                                  officer during the same session.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {getCourseById?.category === "Mock Psychology" && (
                        <div className="interview_content">
                          <div className="title">
                            <h4>Steps</h4>
                          </div>
                          <div className="rightSide">
                            <div className="steps">
                              <div className="step">
                                <div>
                                  <span>1</span>
                                  <span>Submit PIQ</span>
                                </div>
                                <p>
                                  ou have the option to download the PIQ form as
                                  a PDF, fill it out, and then easily upload it
                                  either as an image or PDF file.
                                </p>
                              </div>
                              <div className="step">
                                <div>
                                  <span>2</span>
                                  <span>Schedule Test</span>
                                </div>
                                <p>
                                  Select a date and time for your test that
                                  aligns with your schedule.
                                </p>
                              </div>
                              <div className="step">
                                <div>
                                  <span>3</span>
                                  <span>Attempt Test</span>
                                </div>
                                <p>
                                  The test will be timed and structured
                                  identically to the parameters of the actual
                                  SSB board.
                                </p>
                              </div>
                              <div className="step">
                                <div>
                                  <span>4</span>
                                  <span>Upload Dossier</span>
                                </div>
                                <p>
                                  Immediately after the test concludes, you will
                                  have a limited amount of time to capture
                                  photos of your written responses in your
                                  notebook, convert them into a PDF format, and
                                  then upload them.
                                </p>
                              </div>
                              <div className="step">
                                <div>
                                  <span>5</span>
                                  <span>Feedback</span>
                                </div>
                                <p>
                                  In conclusion, receive your checked Dossier
                                  along with feedback from the psychologist.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="course_content">
                        <h4>Course content</h4>
                        {getCourseById?.category === "Mock Psychology" && (
                          <>
                            <ul>
                              <li>
                                <p>4 Section </p>
                              </li>
                              <li>
                                <p>1hr 54m total length</p>
                              </li>
                            </ul>
                            <div className="accordion_courses">
                              {MockPsy.map((ele, idx) => (
                                <CustomAccordion
                                  key={idx}
                                  count={idx + 1}
                                  title={ele?.title}
                                  subtitle={ele?.subTitle}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        {getCourseById?.category === "Mock Interview" && (
                          <>
                            <ul>
                              {/* <li>
                              <p>4 Section </p>
                            </li> */}
                              <li>
                                <p>1hr 15m total length</p>
                              </li>
                            </ul>
                            <div className="accordion_courses">
                              {MockInterview.map((ele, idx) => (
                                <CustomAccordion
                                  key={idx}
                                  count={idx + 1}
                                  title={ele?.title}
                                  subtitle={ele?.subTitle}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        {getCourseById?.category === "Mock GTO" && (
                          <>
                            <ul>
                              <li>
                                <p>4 Section </p>
                              </li>
                              <li>
                                <p>1hr 54m total length</p>
                              </li>
                            </ul>
                            <div className="accordion_courses">
                              {MockInterview.map((ele, idx) => (
                                <CustomAccordion
                                  key={idx}
                                  count={idx + 1}
                                  title={ele?.title}
                                  subtitle={ele?.subTitle}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        {getCourseById?.category === "Complete Mock SSB" && (
                          <>
                            <ul>
                              {/* <li>
                              <p>4 Section </p>
                            </li> */}
                              <li>
                                <p>5 days total length</p>
                              </li>
                            </ul>
                            <div className="accordion_courses">
                              {CompleteMockSSB?.map((ele, idx) => (
                                <CustomAccordion
                                  key={idx}
                                  count={idx + 1}
                                  title={ele?.title}
                                  subtitle={ele?.subTitle}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        {getCourseById?.courseContent?.length > 0 && (
                          <div className="points">
                            <ul
                              className={
                                getCourseById?.courseContent?.length > 0
                                  ? "content_list"
                                  : ""
                              }
                            >
                              {getCourseById?.courseContent.map((ele) => (
                                <li key={ele?._id}>
                                  <BsCheckLg />
                                  <p>{ele?.content}</p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        className={`course_description ${
                          isExpanded ? "expanded" : ""
                        }`}
                      >
                        <h4>Details</h4>
                        <div
                          className={`content ${isExpanded ? "expanded" : ""}`}
                        >
                          <p>{getCourseById?.description}</p>
                        </div>
                        {getCourseById?.description?.length > 200 && (
                          <p className="toggle-button" onClick={toggleContent}>
                            {isExpanded ? (
                              <>
                                Read Less <BsChevronUp />
                              </>
                            ) : (
                              <>
                                Read More <BsChevronDown />
                              </>
                            )}
                          </p>
                        )}
                      </div>
                      <div className="student_purchase">
                        <h4>Students also bought</h4>

                        <div
                          className={`content ${
                            isExpandedPurchase ? "expanded" : ""
                          }`}
                        >
                          {sbbCoursesLoading ? (
                            <ComponentLoading />
                          ) : (
                            boughtCourses?.slice(0, 4)?.map((item) => (
                              <div className="pruchase_course" key={item?._id}>
                                <div className="course_title">
                                  <img
                                    src={item?.thumbnail?.url}
                                    alt=""
                                    className="course_ban"
                                  />
                                  <div className="course_det">
                                    <h5
                                      onClick={() =>
                                        handleBoughtCourseDetail(item?._id)
                                      }
                                    >
                                      {item?.title}
                                    </h5>
                                    <span>
                                      <p>
                                        Updated{" "}
                                        {moment(item?.updatedAt).format(
                                          "DD-MM-YYYY [at] hh:mm A"
                                        )}
                                      </p>
                                    </span>
                                    <div className="over_review mob_view">
                                      <ul>
                                        {item?.rating === 0 ? (
                                          <li>
                                            <span>
                                              <p>Not rated yet</p>
                                            </span>
                                          </li>
                                        ) : (
                                          <>
                                            <li>
                                              <span>
                                                <p>{item?.rating}</p>
                                                <BsStarFill />
                                              </span>
                                            </li>
                                            <li>
                                              <span>
                                                <BsPeopleFill />
                                                <p>{item?.numOfReviews}</p>
                                              </span>
                                            </li>
                                          </>
                                        )}

                                        <li>
                                          <div>
                                            <span>
                                              {item?.discountedPrice !== 0 && (
                                                <strong>
                                                  Rs{" "}
                                                  {Math.ceil(
                                                    item?.discountedPrice
                                                  )}
                                                </strong>
                                              )}
                                              {item?.discountedPrice !== 0 ? (
                                                <strike>
                                                  Rs{" "}
                                                  {Math.ceil(
                                                    item?.originalPrice
                                                  )}
                                                </strike>
                                              ) : (
                                                <strong>
                                                  Rs{" "}
                                                  {Math.ceil(
                                                    item?.originalPrice
                                                  )}
                                                </strong>
                                              )}
                                            </span>
                                            <span
                                              className="icon"
                                              onClick={() =>
                                                handleAddtoWishlist(item?._id)
                                              }
                                            >
                                              <BsSuitHeart />
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="over_review desk_view">
                                  <ul>
                                    {item?.rating === 0 ? (
                                      <li>
                                        <span>
                                          <p>Not rated yet</p>
                                        </span>
                                      </li>
                                    ) : (
                                      <>
                                        <li>
                                          <span>
                                            <p>{item?.rating}</p>
                                            <BsStarFill />
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <BsPeopleFill />
                                            <p>{item?.numOfReviews}</p>
                                          </span>
                                        </li>
                                      </>
                                    )}
                                    <li>
                                      <div>
                                        <span>
                                          {item?.discountedPrice !== 0 && (
                                            <strong>
                                              Rs {item?.discountedPrice}
                                            </strong>
                                          )}
                                          {item?.discountedPrice !== 0 ? (
                                            <strike>
                                              Rs{" "}
                                              {Math.ceil(item?.originalPrice)}
                                            </strike>
                                          ) : (
                                            <strong>
                                              Rs{" "}
                                              {Math.ceil(item?.originalPrice)}
                                            </strong>
                                          )}
                                        </span>
                                        <span
                                          className="icon"
                                          onClick={() =>
                                            handleAddtoWishlist(item?._id)
                                          }
                                        >
                                          <BsSuitHeart />
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                        {boughtCourses?.length > 2 && (
                          <button
                            className="toggle-button"
                            onClick={toggleContentPurchase}
                          >
                            {isExpandedPurchase ? (
                              <>
                                Show Less <BsChevronUp />
                              </>
                            ) : (
                              <>
                                Show More <BsChevronDown />
                              </>
                            )}
                          </button>
                        )}
                      </div>
                      <div className="business_mob">
                        <h4>Training 5 or more student?</h4>
                        <p>
                          Equip your students with unrestricted access to over
                          1000 elite Alpha Regiment Courses, available anytime,
                          anywhere, all customized under your banner if desired.
                        </p>
                        <CustomButton
                          className="buy_now"
                          title="Try Alpha School Now?"
                          // onClick={onClick}
                          icon={""}
                          width="100%"
                        />
                      </div>
                      <div className="teacher_details">
                        <h4>Teacher</h4>
                        <h5 className="name">
                          <Link
                            to={`/view-profile/${getCourseById?.createdBy?._id}`}
                          >
                            {getCourseById?.createdBy?.first_name}
                          </Link>
                        </h5>
                        <p>{arRole(getCourseById?.createdBy?.role)}</p>
                        <div className="profile_card">
                          <img
                            src={getCourseById?.createdBy?.profile?.url}
                            alt=""
                          />
                          <ul>
                            <li>
                              <BsStarFill />
                              <p>4.6 teacher rating</p>
                            </li>
                            <li>
                              <BsFillAwardFill />
                              <p>200 reviews</p>
                            </li>
                            <li>
                              <BsPeopleFill />
                              <p>1,112 students</p>
                            </li>
                            <li>
                              <BsFillPlayCircleFill />
                              <p>1 course</p>
                            </li>
                          </ul>
                        </div>
                        <div
                          className={`content ${
                            isExpandTeacher ? "expanded" : ""
                          }`}
                        >
                          <p>{getCourseById?.createdBy?.teacherBio}</p>
                        </div>
                        {getCourseById?.createdBy?.teacherBio?.length > 600 && (
                          <p
                            className="toggle-button"
                            onClick={toggleTeacherExpand}
                          >
                            {isExpandTeacher ? (
                              <>
                                Show Less <BsChevronUp />
                              </>
                            ) : (
                              <>
                                Show More <BsChevronDown />
                              </>
                            )}
                          </p>
                        )}
                      </div>
                      {getCourseById?.reviews?.length > 0 && (
                        <div className="rating_review">
                          <h4 className="title">Rating & Reviews</h4>
                          <div className="review_Wrap">
                            {getCourseById?.reviews
                              ?.slice(0, 6)
                              ?.map((ele, idx) => (
                                <div className="review_Card" key={idx}>
                                  <img
                                    src={ele?.user?.profile?.url}
                                    alt={ele?.user?.full_name}
                                  />
                                  <div className="rating_details">
                                    <div className="name">
                                      <p>
                                        <span>{ele?.user?.first_name}</span>
                                        <span>
                                          {moment(ele?.createdAt)?.format(
                                            "DD-MM-YYYY"
                                          )}
                                        </span>
                                      </p>
                                      <p>
                                        <FaRegStar /> {ele?.rating}
                                      </p>
                                    </div>
                                    <p>{ele?.comment}</p>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="count">
                            <Link
                              to={`/ssb-course/rating/${getCourseById?._id}`}
                            >
                              See all reviews ({getCourseById?.reviews?.length})
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="left_section">
                      <div
                        className="top_img"
                        onClick={() =>
                          handleVideoClick(getCourseById?.bannervideoLink)
                        }
                      >
                        <img
                          className="main_hero_img"
                          src={getCourseById?.thumbnail?.url}
                          alt="cyberPartnerHero"
                        />
                        {getCourseById?.bannervideoLink !== "" && (
                          <img
                            className="video_icon"
                            src={video_icon}
                            alt="videoIcon"
                          />
                        )}
                      </div>
                      <div className="details">
                        <div className="price_text">
                          <ul className="prize">
                            {getCourseById?.discountedPrice > 0 &&
                              discountValid && (
                                <li>
                                  <h4>
                                    Rs{" "}
                                    {Math.ceil(getCourseById?.discountedPrice)}
                                  </h4>
                                </li>
                              )}

                            {getCourseById?.discountedPrice > 0 &&
                            discountValid ? (
                              <>
                                <li>
                                  <strike>
                                    Rs {Math.ceil(getCourseById?.originalPrice)}
                                  </strike>
                                </li>
                                {/* <li> <p>70% off</p> </li> */}
                              </>
                            ) : (
                              <li>
                                <h4>
                                  Rs {Math.ceil(getCourseById?.originalPrice)}
                                </h4>
                              </li>
                            )}
                          </ul>
                          {getCourseById?.discountedPrice > 0 &&
                            getCourseById?.discountValidity &&
                            (dateValue > 0 || hrsValue > 0 || minValue > 0) && (
                              <span>
                                <p>
                                  <strong>
                                    {dateValue < 15 && dateValue > 0 ? (
                                      <span>
                                        <BsAlarm /> {dateValue} days left at
                                        this price
                                      </span>
                                    ) : hrsValue > 0 && hrsValue !== null ? (
                                      <span>
                                        <BsAlarm /> {hrsValue} hours left at
                                        this price
                                      </span>
                                    ) : minValue > 0 && minValue !== null ? (
                                      <span>
                                        <BsAlarm /> {minValue} min left at this
                                        price
                                      </span>
                                    ) : null}
                                  </strong>
                                </p>
                              </span>
                            )}

                          {getCourseById?.pauseForDate !== null &&
                            (getCourseById?.category === "SSB Coaching" ||
                              getCourseById?.category ===
                                "Mock SSB Interview") && (
                              <span>
                                New Batch Starts on{" "}
                                {moment(getCourseById?.pauseForDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            )}

                          {auth?.user?.courses?.some(
                            (itm) => itm?.course?._id === getCourseById?._id
                          ) ? (
                            <button className="buy_now" disabled>
                              Already Purchased
                            </button>
                          ) : (
                            <>
                              <button
                                className="add_cart"
                                disabled={verifyPaymentLoading ? true : false}
                                onClick={() => handleAddToCart(getCourseById)}
                              >
                                {verifyPaymentLoading
                                  ? "Loading..."
                                  : "Add To Cart"}
                              </button>
                              {(getCourseById?.category === "SSB Coaching" ||
                                getCourseById?.category ===
                                  "Mock SSB Interview") &&
                              getCourseById?.createdBy?.seatBooking &&
                              getCourseById?.courseMode === "Offline" ? (
                                <button
                                  className="buy_now"
                                  disabled={verifyPaymentLoading ? true : false}
                                  onClick={() => paymentHandler(getCourseById)}
                                >
                                  {verifyPaymentLoading
                                    ? "Loading..."
                                    : "Enroll / Book Seat"}
                                </button>
                              ) : (
                                <button
                                  className="buy_now"
                                  disabled={verifyPaymentLoading ? true : false}
                                  onClick={() => paymentHandler(getCourseById)}
                                >
                                  {verifyPaymentLoading
                                    ? "Loading..."
                                    : "Buy Now"}
                                </button>
                              )}
                            </>
                          )}
                          <p className="money_back">
                            7-Days Money-Back Guarantee
                            <div className="tooltip">
                              <FaInfoCircle />
                              <span className="tooltiptext">
                                The 7-day money-back guarantee applies solely to
                                courses with a longer duration; it does not
                                extend to Mock Interviews and Mock Psychology
                                Tests, as these services are typically utilized
                                within the initial 7-day period.
                              </span>
                            </div>
                          </p>
                          {getCourseById?.googleLocation !== "" && (
                            <div className="location_map">
                              <span>
                                <CiLocationOn />
                                <p>
                                  Enable your location to calculate the precious
                                  distance
                                </p>
                              </span>

                              <div className="progress_sec">
                                <CustomButton
                                  className="location_allow"
                                  title={t("Allow Location")}
                                  // onClick={onClick}
                                  icon={""}
                                />
                                <div className="progress-bar">
                                  <div
                                    className="progress"
                                    style={{ width: "40%" }}
                                  ></div>
                                </div>

                                <div className="distance_calculator">
                                  <p style={{ width: "68%" }}>
                                    12km km distance from your location
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                          {getCourseById?.courseInclude.length > 0 && (
                            <ul className="feature">
                              <h4>This course includes:</h4>
                              {getCourseById?.courseInclude?.map((ele, id) => (
                                <li key={id}>
                                  <IoCheckmarkDoneSharp />
                                  <p>{ele}</p>
                                </li>
                              ))}
                            </ul>
                          )}
                          <ul className="share_things">
                            <li>
                              <RWebShare
                                data={{
                                  url: `${window.location.origin}/view-profile/${getCourseById?._id}`,
                                  title: `Share ${getCourseById?.title} profile`,
                                }}
                                onClick={() => console.log("shared")}
                              >
                                <p>Share</p>
                              </RWebShare>
                            </li>
                            <li>
                              <Link to="/">Gift this course</Link>
                            </li>
                            <li>
                              <Link to="/">Apply Coupen</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="business">
                          <h4>Training 5 or more student?</h4>
                          <p>
                            Equip your students with unrestricted access to over
                            1000 elite Alpha Regiment Courses, available
                            anytime, anywhere, all customized under your banner
                            if desired.
                          </p>
                          <CustomButton
                            className="buy_now"
                            title="Try Alpha School Now?"
                            onClick={() => navigate("/alpha-school")}
                            icon={""}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cart_btns_mob">
                  {auth?.user?.courses?.some(
                    (itm) => itm?.course?._id === getCourseById?._id
                  ) ? (
                    <button className="buy_now" disabled>
                      Already Purchased
                    </button>
                  ) : (
                    <>
                      <button
                        className="add_cart"
                        disabled={verifyPaymentLoading ? true : false}
                        onClick={() => handleAddToCart(getCourseById)}
                      >
                        {verifyPaymentLoading ? (
                          <ButtonLoader />
                        ) : (
                          <FaCartPlus />
                        )}
                      </button>
                      {(getCourseById?.category === "SSB Coaching" ||
                        getCourseById?.category === "Mock SSB Interview") &&
                      getCourseById?.createdBy?.seatBooking &&
                      getCourseById?.courseMode === "Offline" ? (
                        <button
                          className="buy_now"
                          disabled={verifyPaymentLoading ? true : false}
                          onClick={() => paymentHandler(getCourseById)}
                        >
                          {verifyPaymentLoading ? (
                            <ButtonLoader />
                          ) : (
                            "Enroll / Book Seat"
                          )}
                        </button>
                      ) : (
                        <button
                          className="buy_now"
                          disabled={verifyPaymentLoading ? true : false}
                          onClick={() => paymentHandler(getCourseById)}
                        >
                          {verifyPaymentLoading ? "Loading..." : "Buy Now"}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
          )}

          {/* UPLOAS PIQ SECTION */}

          <div className="container">
            {showSection === "piq" && (
              <div className="student_piq_section">
                <div className="title">
                  <p> Upload PIQ</p>
                </div>
                <div className="banner">
                  <img src={noCourse} alt="noCourse" />
                </div>
                <div className="data_txt">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
                <div className="upload_btn">
                  <CustomButton title="Download PIQ" onClick={handleDownload} />
                  <CustomButton
                    title="Upload PIQ"
                    onClick={() => setShowModal(!showModal)}
                  />
                </div>
              </div>
            )}

            {showSection === "interview" && (
              <div className="schedule_interview_section">
                <div className="schedule_interview">
                  <div className="title">
                    <p>Schedule Interview</p>
                  </div>

                  <div className="data_form">
                    <CustomDateInput
                      icon={<MdDateRange />}
                      label="Choose Date"
                      type="date"
                      name="date"
                      impStar="*"
                      onChange={(e) => handleInputChange(e)}
                      value={dateSelect}
                      disabledDate={getCurrentDate}
                    />

                    <div className="slot_list">
                      {slotList?.length > 0 &&
                        slotList?.map((itm, idx) => {
                          const isDisabled =
                            officerAvalability?.bookedSlots?.some((slot) => {
                              const slotStartTime = moment(
                                slot.startTime
                              ).format("HH:MM");
                              const slotEndTime = moment(slot.endTime).format(
                                "HH:MM"
                              );
                              const itmStartTime = moment(itm.startTime).format(
                                "HH:MM"
                              );
                              const itmEndTime = moment(itm.endTime).format(
                                "HH:MM"
                              );
                              return (
                                (slotStartTime === itmStartTime ||
                                  slotStartTime < itmEndTime) &&
                                (slotEndTime === itmEndTime ||
                                  slotEndTime > itmStartTime)
                              );
                            });

                          const cardClass =
                            startTime === itm?.startTime
                              ? "slot_card active"
                              : "slot_card";

                          return (
                            <div
                              className={
                                !isDisabled ? "slot_card disabled" : cardClass
                              }
                              key={idx}
                              onClick={() => {
                                if (isDisabled) {
                                  handleAddTime(itm);
                                }
                              }}
                            >
                              <p>
                                {moment(itm.startTime).format("hh:mm")} -{" "}
                                {moment(itm.endTime).format("hh:mm")}
                              </p>
                            </div>
                          );
                        })}
                    </div>

                    {dateSelect !== null && slotList?.length < 1 && (
                      <p>No any time slot available for selected date</p>
                    )}
                  </div>
                  {dateSelect !== null && (
                    <div className="upload_btn">
                      <CustomButton
                        title="Schedule"
                        onClick={() => scheduleRedirect()}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* UPLOAD PIQ MODAL STARTS */}
          {showModal && (
            <PopupPrompt
              confirmBtnTxt="Upload"
              cancelBtnTxt="Cancel"
              className="coupen_card"
              title="Upload PIQ"
              confirmOnclick={() => handleUploadPIQ()}
              cancelOnclick={() => setShowModal(false)}
              image={image}
              imageLabel="Select PIQ Form"
              impStar="*"
              fileAccept=".pdf"
              onFileChange={(e) => handleFileChange(e)}
            />
          )}

          {/* <EnrollNowPopUp /> */}

          {/* <PromotionPopup
            txt="New batch start from 22April, 2024"
            promotion={promotion}
            cancelOnclick={() => setPromotion(false)}
          /> */}
        </Layout>
      )}

      {/* COUPON CODE POPUP START */}

      <CouponPaymentPopup
        showCouponPopup={showCouponPopup}
        setShowCouponPopup={setShowCouponPopup}
        courseDetails={getCourseById}
        handleSchedule={handleSchedule}
      />
      {/* COUPON CODE POPUP ENDS */}
      <MoreDetailBatchVideo
        videoUrl={videoLink}
        showDetailVideo={showDetailVideo}
        setshowDetailVideo={setshowDetailVideo}
      />

      {/* PROMOTION POPUP STARTS */}
      {notification?.length > 0 &&
        currentNotificationIndex < notification?.length - 1 && (
          <PromotionPopup
            isNotificationVisible={isNotificationVisible}
            currentNotificationIndex={currentNotificationIndex}
            data={notification}
          />
        )}
    </Fragment>
  );
};

export default SsbCourseDetails;
