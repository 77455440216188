import React, { Fragment, useEffect, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { BsPersonFill, BsPersonFillAdd, BsShareFill } from "react-icons/bs";
import { CgCalendarDates } from "react-icons/cg";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { MdPsychologyAlt } from "react-icons/md";
import CustomButton from "../../../components/reusable/CustomButton";
import { defaultUser, workshop_hero } from "../../../assets/images";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { arRole } from "../../../utils/userRole";
import moment from "moment";
import { RWebShare } from "react-web-share";
import { useDispatch } from "react-redux";
import {
  clearFollowErrors,
  clearFollowState,
  clearFriendProfileError,
  clearRemoveFollowState,
  getFollowerList,
  getFriendDetails,
  sendFollowRequest,
  unfollowSomeone,
} from "../../../redux/actions/followAction";
import { useSelector } from "react-redux";
import { useNotification } from "../../../context/notificationContext";
import Loader from "../../../components/reusable/Loader";
import "../../../styles/friendProfile.scss";
import {
  getSsbCoursesByOfficerId,
  ssbClearErrors,
} from "../../../redux/actions/ssbCourseAction";
import ComponentLoading from "../../../components/reusable/ComponentLoading";
import ButtonLoader from "../../../components/reusable/ButtonLoader";
import {
  clearOfficerErrors,
  getTimelinePostByOfficerAction,
  likeTimelinePostAction,
} from "../../../redux/actions/officerAction";
import { FaRegThumbsUp } from "react-icons/fa";
import { useAuth } from "../../../context/userContext";

const ViewProfile = () => {
  const { id } = useParams();
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("courses");
  const [likeData, setLikeData] = useState([]);
  const connectionList = location?.state?.follows;

  const { error, loading, friendDetails } = useSelector(
    (state) => state.getFriendProfile
  );
  const handleActiveTab = (active) => {
    setActiveTab(active);
  };
  useEffect(() => {
    dispatch(getFriendDetails(id));
  }, [dispatch, id]);

  const joinedTime = moment(friendDetails?.user?.createdAt).format("MMMM YYYY");

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearFriendProfileError());
    }
  }, [dispatch, showNotification, error]);

  const {
    loading: officerCourseLoading,
    error: officerCourseError,
    courses: data,
  } = useSelector((state) => state.getCoursesByOfficerId);

  // const { loading, error, courses: data } = officerSsbCourses;

  useEffect(() => {
    dispatch(getSsbCoursesByOfficerId(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (officerCourseError) {
      showNotification("error", officerCourseError);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, officerCourseError, showNotification]);

  // GET ALL POST BY OFFICER ID
  const {
    loading: officerPostLoading,
    error: officerPostError,
    postData,
  } = useSelector((state) => state.getAllPostByOfficer);

  // const { loading, error, courses: data } = officerSsbCourses;

  useEffect(() => {
    dispatch(getTimelinePostByOfficerAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (officerPostError) {
      showNotification("error", officerPostError);
      dispatch(clearOfficerErrors());
    }
  }, [dispatch, officerPostError, showNotification]);

  const {
    error: requestError,
    loading: requestLoading,
    followInfo,
  } = useSelector((state) => state.sendRequest);

  const handleRequestSend = (id) => {
    dispatch(sendFollowRequest(id));
  };

  // HANDLE FOLLOW ERROR
  useEffect(() => {
    if (requestError) {
      showNotification("error", requestError);
      dispatch(clearFollowErrors());
    }
    if (followInfo?.success) {
      showNotification("success", followInfo?.message);
      dispatch(clearFollowState());
      dispatch(getFollowerList());
    }
  }, [
    requestError,
    showNotification,
    dispatch,
    followInfo?.message,
    followInfo?.success,
  ]);

  // GET ALL FOLLOWERS
  const {
    loading: followerLoading,
    error: followerError,
    followList,
  } = useSelector((state) => state.followerList);

  const isFollowingExist = (id) => {
    const allFollwerings = followList?.follows?.followings;
    const isExist = allFollwerings?.some(
      (obj) => obj?._id?.toString() === id?.toString()
    );
    return isExist;
  };

  useEffect(() => {
    dispatch(getFollowerList());
  }, [dispatch]);

  useEffect(() => {
    if (followerError) {
      dispatch(clearFollowErrors());
    }
  }, [followerError, showNotification, dispatch]);

  const handleUnFollow = (id) => {
    dispatch(unfollowSomeone(id));
  };

  const {
    error: unFollowError,
    loading: unFollowLoading,
    followInfo: unFollowInfo,
  } = useSelector((state) => state.unFollowRequest);

  useEffect(() => {
    if (unFollowError) {
      showNotification("error", unFollowError);
      dispatch(clearFollowErrors());
    }
    if (unFollowInfo?.success) {
      showNotification("success", unFollowInfo?.message);
      dispatch(clearRemoveFollowState());
      dispatch(getFollowerList());
    }
  }, [
    unFollowError,
    showNotification,
    dispatch,
    unFollowInfo?.message,
    unFollowInfo?.success,
  ]);

  // HANDLE VIEW PROFILE
  const handleViewFriend = (itm) => {
    navigate(`/friend-profile/${itm?.createdBy?._id}`);
  };

  // HANDLE POST LIKE
  const handlePostLike = (id) => {
    const filterId = likeData?.includes(id);
    if (filterId) {
      const filterData = likeData?.filter((itm) => itm !== id);
      setLikeData(filterData);
    } else {
      setLikeData((prevData) => [...prevData, id]);
    }

    dispatch(likeTimelinePostAction(id));
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="friend_timeline_main txt_color">
          <div className="title">
            <button title="Go Back" onClick={() => navigate(-1)}>
              <BiLeftArrowAlt />
            </button>
            {friendDetails?.user?.first_name} Timeline
          </div>
          <div className="container">
            <div className="officer_timeline_content">
              <div className="officerProfile">
                {/* // PROFILE CONTENT START */}
                <div className="profileCover">
                  <img src={workshop_hero} alt="alpha officer profile cover" />
                </div>
                <div className="profilePic">
                  <img
                    src={
                      friendDetails?.user?.profile?.url
                        ? friendDetails?.user?.profile?.url
                        : defaultUser
                    }
                    alt="alpha officer profile"
                  />
                </div>

                <div className="userDetails">
                  <div className="buttonGrp">
                    {connectionList?.connections?.length > 0 && (
                      <CustomButton title="Message" />
                    )}
                    {isFollowingExist(friendDetails?.user?._id) ? (
                      <CustomButton
                        className=""
                        title={unFollowLoading ? <ButtonLoader /> : "Unfollow"}
                        onClick={() => handleUnFollow(friendDetails?.user?._id)}
                        disabled=""
                        preIcon={unFollowLoading ? "" : <BsPersonFillAdd />}
                      />
                    ) : (
                      <CustomButton
                        className=""
                        title={requestLoading ? <ButtonLoader /> : "Follow"}
                        onClick={() =>
                          handleRequestSend(friendDetails?.user?._id)
                        }
                        disabled=""
                        preIcon={requestLoading ? "" : <BsPersonFillAdd />}
                      />
                    )}
                    <div className="shareBtn">
                      <RWebShare
                        data={{
                          url: `${window.location.origin}/view-profile/${id}`,
                          title: `Share ${friendDetails?.user?.first_name} profile`,
                        }}
                        onClick={() => console.log("shared")}
                      >
                        <CustomButton
                          className="shareButton"
                          preIcon={<BsShareFill />}
                          tooltip="Share Friend Profile"
                        />
                      </RWebShare>
                    </div>
                  </div>
                  <div className="general">
                    <h2 className="name">{friendDetails?.user?.first_name}</h2>
                    <p className="uniqueCode">
                      {friendDetails?.user?.couponCode}
                    </p>
                    <p className="tag">
                      {friendDetails?.user?.role === "16" ? (
                        <GiPoliceOfficerHead />
                      ) : friendDetails?.user?.role === "17" ? (
                        <BsPersonFill />
                      ) : (
                        friendDetails?.user?.role === "18" && (
                          <MdPsychologyAlt />
                        )
                      )}
                      {arRole(friendDetails?.user?.role)}
                    </p>
                    <p className="bio">{friendDetails?.user?.teacherBio}</p>
                  </div>
                </div>

                <div className="locationBirth">
                  <p>
                    <CgCalendarDates /> Joined {joinedTime}{" "}
                  </p>
                </div>

                <div className="timeline_tabs">
                  <ul className="tabs">
                    <li
                      className={`${activeTab === "all_post" ? "active" : ""}`}
                      onClick={() => handleActiveTab("all_post")}
                    >
                      <p>Posts</p>
                    </li>
                    <li
                      className={`${activeTab === "courses" ? "active" : ""}`}
                      onClick={() => handleActiveTab("courses")}
                    >
                      <p>Courses</p>
                    </li>
                  </ul>
                  {activeTab === "all_post" &&
                    (officerPostLoading ? (
                      <ComponentLoading />
                    ) : postData?.posts?.length > 0 ? (
                      <div className="post_list">
                        {postData?.posts?.map((itm) => (
                          <div className="post_card" key={itm?._id}>
                            <img
                              src={
                                itm?.createdBy?.profile?.url
                                  ? itm?.createdBy?.profile?.url
                                  : defaultUser
                              }
                              alt={itm?.createdBy?.first_name}
                            />
                            <div className="post_details">
                              <div className="title">
                                <div className="name">
                                  <p onClick={() => handleViewFriend(itm)}>
                                    {itm?.createdBy?.first_name}
                                  </p>
                                  <span>
                                    {moment(itm?.createdAt).format(
                                      "MMMM DD[,] YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <p>{itm?.label}</p>
                              {itm?.thumbnail?.url !== "" && (
                                <img
                                  src={itm?.thumbnail?.url}
                                  alt="postimage"
                                />
                              )}
                              <div className="post_footer">
                                <CustomButton
                                  title={`${
                                    itm?.likes?.length > 0
                                      ? itm?.likes?.length
                                      : ""
                                  } Like`}
                                  className={`like_btn ${
                                    likeData?.includes(itm._id) ||
                                    (itm?.likes &&
                                      itm.likes.some(
                                        (like) => like._id === auth?.user?._id
                                      ))
                                      ? "active"
                                      : ""
                                  }`}
                                  width="100px"
                                  onClick={() => handlePostLike(itm?._id)}
                                  icon={<FaRegThumbsUp />}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p style={{ textAlign: "center" }}>No post yet</p>
                    ))}
                  {activeTab === "courses" && (
                    <div className="course_list">
                      {officerCourseLoading ? (
                        <ComponentLoading />
                      ) : data?.courses?.length > 0 ? (
                        data?.courses?.map((item) => (
                          <div className="course_card" key={item?._id}>
                            <img src={item?.thumbnail?.url} alt="user" />
                            <p>{item?.title}</p>
                            <div className="view_btn">
                              <CustomButton
                                title="View Detail"
                                width="110px"
                                className="btn"
                                onClick={() =>
                                  navigate(`/ssb-course-details/${item?._id}`)
                                }
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="txt_color">No Course Created Yet</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ViewProfile;
