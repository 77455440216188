import React from "react";
import AffiliateNav from "./AffiliateNav";
import Footer from "../../components/reusable/Footer";

const AffiliateLayout = ({ children }) => {
  return (
    <div>
      <AffiliateNav />
      <main style={{ minHeight: "70vh" }}>{children}</main>
      <Footer />
    </div>
  );
};

export default AffiliateLayout;
