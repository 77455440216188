import { axiosInstance } from "../../utils/axiosConfig";
import {
  ALL_TESTIMONIALS_FAIL,
  ALL_TESTIMONIALS_REQUEST,
  ALL_TESTIMONIALS_SUCCESS,
  TESTIMONIALS_CLEAR_ERRORS,
  TESTIMONIALS_CLEAR_STATE,
} from "../constants/testimonialConstant";

// GET ALL Testimonial
export const getAllTestimonials = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_TESTIMONIALS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/get-all-testimonials", config);

    dispatch({
      type: ALL_TESTIMONIALS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_TESTIMONIALS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearTestimonialsErrors = () => async (dispatch) => {
  dispatch({ type: TESTIMONIALS_CLEAR_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const clearTestimonialsState = () => async (dispatch) => {
  dispatch({ type: TESTIMONIALS_CLEAR_STATE });
};
