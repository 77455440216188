import React, { Fragment, useEffect } from "react";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import "../../styles/courseCurriculum.scss";
import { officerSidebarLink } from "./officerSidebarLink";
import {  FaRegSadTear } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import {
  getOfficerSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import Loader from "../../components/reusable/Loader";
// import moment from "moment";
import OfficerNoDataCard from "./OfficerNoDataCard";

const CourseCurriculum = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // SHOW POPUP MSG STATE
  // const [popupMsg, setPopupMsg] = useState({});
  // const [pauseModal, setPauseModal] = useState({});
  const {
    loading,
    error,
    courses: data,
  } = useSelector((state) => state.officerSsbCourses);
  // const { loading, error, courses: data } = officerSsbCourses;

  useEffect(() => {
    dispatch(getOfficerSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, error, showNotification]);

  //   VIEW COURSE CURRICULUM
  const handleViewDetails = () => {
    showNotification("warning","You have not added curriculum yet")
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <section className="course_curriculum txt_color">
              <div className="title">
                <h2>Course Curriculum</h2>
                {/* <button
                  title="Add New Course"
                  onClick={() => navigate("/officer/create-dossier")}
                >
                  <FaPlus />
                </button> */}
              </div>
              <div className="batch_timing_main officer_course_content txt_color">
                {data?.courses?.length > 0 ? (
                  <div className="timing_calendar">
                    <div className="timing_table custonScroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Course Name</th>
                            {/* <th>Created Date & Time</th> */}
                            {/* <th>Status</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.courses?.map((ele, idx) => (
                            <tr key={ele._id}>
                              <td>
                                <p>{ele?.title}</p>
                              </td>

                              {/* <td>
                                <p>
                                  {moment(ele?.createdAt).format(
                                    "MMMM-DD-YYYY"
                                  )}
                                </p>
                              </td> */}

                              {/* <td className="txt_center">
                                <p>
                                  <span
                                    className={`${
                                      ele?.status === "Approval Pending"
                                        ? "yellow"
                                        : ele?.status === "Active"
                                        ? "green"
                                        : "white"
                                    }`}
                                  >
                                    {ele?.status}
                                  </span>
                                </p>
                              </td> */}
                              <td>
                                <p>
                                  <button
                                    title="View/Edit Course"
                                    onClick={() => handleViewDetails(ele)}
                                  >
                                    {/* <AiFillEye /> */}
                                    View Curriculum
                                  </button>
                                  <button
                                    title="Add Curriculum"
                                    onClick={() =>
                                      navigate(`/officer/create-curriculum/${ele?._id}`)
                                    }
                                  >
                                    {/* <AiFillEye /> */}
                                    Add Curriculum
                                  </button>
                                  {/* <button
                                      title="Delete Course"
                                      onClick={() =>
                                        setPopupMsg({ show: true, id: ele._id })
                                      }
                                    >
                                      Delete Curriculum
                                    </button> */}
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <OfficerNoDataCard
                    txt="There is no course added yet"
                    icon={<FaRegSadTear />}
                  />
                )}
              </div>
            </section>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default CourseCurriculum;
