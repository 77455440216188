import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import { useNavigate } from "react-router-dom";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import CourseCard from "../../components/course/CourseCard";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import "../../styles/ssbCoaching.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
import { IoMdArrowRoundBack } from "react-icons/io";
const SsbCoaching = () => {
  const currentPageUrl = window?.location?.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [t] = useLocale();
  const { loading, courses, error } = useSelector(
    (state) => state.getAllSsbCourses
  );

  let allSsbApiCourses = courses?.courses?.filter(
    (itm) =>
      itm?.category === "SSB Coaching" &&
      ((itm?.isActive && !itm?.isPause) ||
        (itm?.isActive && itm?.isPause && itm?.pauseForDate !== null))
  );
  const [courseMode, setCourseMode] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const handleSearchChange = (e) => {
    const filter = e.target.value;
    setSearchCategory(filter);
    const filterdata = allSsbApiCourses?.filter((i) => {
      return (
        i.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) &&
        i.courseMode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase())
      );
    });
    allSsbApiCourses = filterdata;
  };
  const handleFilterMode = (query) => {
    setCourseMode(query);
    const filterData = allSsbApiCourses?.filter((course) => {
      return course?.courseMode === query;
    });
    allSsbApiCourses = filterData;
  };

  // console.log(allSsbApiCourses);

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch]);

  const handleSsbDetailsPage = (data) => {
    // if (categoryTitle === "Spoken English") {
    //   const { title, study_mode, courseCategory, _id } = data;
    //   const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    //   const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
    //   const formattedCategory = courseCategory
    //     .toLowerCase()
    //     .replace(/\s+/g, "-");
    //   const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
    //   navigate(seoFriendlyUrl);
    // } else {
    const { slug } = data;
    const seoFriendlyUrl = `/ssb-course-details/${slug}`;
    navigate(seoFriendlyUrl);
    // }
  };

  // CHECK WISHLIST EXIST OR NOT
  const [wishListId, setWishListId] = useState("");

  const {
    // eslint-disable-next-line
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    setWishListId("");
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES
  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="SSB Coaching is a proven technique for preparing for the SSB. Join SSB coaching on the Alpha Regiment platform for comprehensive preparation."
            author="Team Alpha Regiment"
            title="Prepare for Your SSB Interview with Senior Retired Officers' Guidance"
            canonical={currentPageUrl}
          />
          <section className="ssb_coaching_courses_main">
            <div className="container">
              <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div>

              <div className="all_course_content">
                <div className="all_course_cards">
                  <h1 className="main_title">
                  <span onClick={()=> navigate(-1)}><IoMdArrowRoundBack /></span>
                    SSB Coaching: A Holistic Approach to Success
                  </h1>
                  <p className="description">
                    SSB coaching is a well-established and effective method for
                    preparing aspirants for the SSB/AFSB/NSB interviews. Since
                    classroom learning, where teachers guide students
                    step-by-step, is a familiar and proven approach, coaching
                    adopts a similar model. Here, experienced retired officers
                    meticulously explain each aspect of the SSB process,
                    ensuring comprehensive understanding and preparation.
                  </p>

                  {allSsbApiCourses?.length > 0 && (
                    <div className="slider_container">
                      {allSsbApiCourses?.map((item, idx) => {
                        const roundedPrice = Math.ceil(item?.originalPrice);
                        const roundedDiscountedPrice = Math.ceil(
                          item?.discountedPrice
                        );
                        return (
                          <CourseCard
                            key={idx}
                            isActiveClass={true}
                            className="course_card"
                            title={item?.title}
                            description={`${item?.description?.substr(
                              0,
                              40
                            )}...`}
                            icon={item?.thumbnail?.url}
                            startingFrom={item?.starting_date}
                            price={roundedPrice}
                            discountedPrice={roundedDiscountedPrice}
                            numOfReviews={item?.numOfReviews}
                            rating={item?.rating}
                            addWishList={() =>
                              handleAddtoWishlist({
                                itemId: item?._id,
                                isSsb: true,
                              })
                            }
                            deleteWishListLoading={
                              wishListId === item?._id &&
                              deleteLoading && <ButtonLoader />
                            }
                            existInWishList={allWishList?.wishlist?.ssbCourse?.some(
                              (obj) => obj._id === item._id
                            )}
                            addWishListLoading={
                              wishListId === item?._id &&
                              addWishListLoading && <ButtonLoader />
                            }
                            onHomePageClick={() => handleSsbDetailsPage(item)}
                          />
                        );
                      })}
                    </div>
                  )}

                  {allSsbApiCourses?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is SSB Coaching?</h2>
                </div>
                <div className="detail">
                  <p>
                    SSB coaching is a comprehensive training program tailored to
                    guide aspirants in successfully clearing the SSB interview.
                    It provides a deep understanding of the assessors'
                    expectations and helps aspirants develop the Officer Like
                    Qualities (OLQs) essential for selection. The program
                    meticulously covers all the tasks conducted during the five
                    days of the SSB interview, along with strategies to perform
                    well in each task.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is the Duration of SSB Coaching?</h2>
                </div>
                <div className="detail">
                  <p>
                    The duration of SSB coaching programs can vary. To know the
                    exact duration of a specific course, please refer to the
                    course details page. Alpha Regiment is a learning and
                    teaching marketplace dedicated to defense aspirants in
                    India. Here, officers have the flexibility to design courses
                    and set their duration based on their expertise and
                    professional judgment.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    Is it Necessary to Stay on campus during SSB Coaching?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    Staying on campus is not mandatory for all courses. However,
                    some programs may require it for a more immersive
                    experience. Please check the course details page for
                    specific requirements.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Who Benefits Most from SSB Coaching?</h2>
                </div>
                <div className="detail">
                  <p>
                    SSB coaching is tailored to meet the needs of diverse
                    aspirants, such as:
                  </p>
                  <ul>
                    <li>
                      <b>Repeaters:</b> Aspirants who have attended multiple
                      SSBs but remain unclear about the reasons for their
                      rejections benefit greatly. Coaching identifies and
                      resolves their shortcomings, giving them a clearer path to
                      success.
                    </li>
                    <li>
                      <b>Self-Learners:</b> Candidates who have prepared
                      independently using online resources or books can use
                      coaching to validate their understanding and fine-tune
                      their performance under expert guidance.
                    </li>
                    <li>
                      <b>First-Timers:</b> For students wishing to gain
                      first-hand experience of the SSB process, coaching
                      provides a comprehensive introduction to each stage,
                      making them better prepared for their first attempt.
                    </li>
                    <li>
                      <b>Focused Evaluators:</b> Aspirants who have already
                      prepared extensively but want to assess their readiness
                      can benefit from detailed evaluation and practical
                      exercises in a controlled environment.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Why SSB Coaching Stands Out Compared to Other Methods</h2>
                </div>
                <div className="detail">
                  <ul>
                  <li>
                    <b>Personalized Learning:</b> SSB coaching emphasizes
                    individual evaluation. Each aspirant is assessed personally
                    by trained officers, providing a tailored learning
                    experience that focuses on their unique strengths and
                    weaknesses.
                  </li>
                  <li>
                    <b>Real-Time Feedback:</b> Unlike other methods, coaching
                    includes detailed feedback at every step, helping aspirants
                    understand where they stand and how to improve before their
                    actual SSB attempt.
                  </li>
                  <li>
                    <b>Hands-On Experience:</b> Coaching programs are designed
                    to replicate the SSB interview environment, enabling
                    aspirants to practice tasks like group discussions,
                    psychological tests, and interviews in realistic conditions.
                  </li>
                  <li>
                    <b>Holistic Preparation:</b> Coaching doesn’t just focus on
                    passing the SSB; it also helps build life skills like
                    leadership, teamwork, and communication, which are
                    invaluable in all spheres of life.
                  </li>
                  </ul>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Is SSB Coaching the Best Choice for Everyone?</h2>
                </div>
                <div className="detail">
                  <p>While SSB coaching is highly effective for most aspirants, some may find other preparation methods, such as self-study or mock SSBs, more suitable. At <b>Alpha Regiment</b>, we understand that each candidate is unique and offer a range of options, from personalized coaching to mock SSBs, ensuring every aspirant can find a method that works best for them.</p>
                  <p>Ready to take the next step in your SSB preparation journey? Enroll in SSB coaching with <b>Alpha Regiment</b> and unlock your potential to achieve your dream of serving the nation!</p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default SsbCoaching;
