export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";

export const PAYMENT_DONE_REQUEST = "PAYMENT_DONE_REQUEST";
export const PAYMENT_DONE_SUCCESS = "PAYMENT_DONE_SUCCESS";
export const PAYMENT_DONE_FAIL = "PAYMENT_DONE_FAIL";

export const UPDATE_PAYMENT_DONE_REQUEST = "UPDATE_PAYMENT_DONE_REQUEST";
export const UPDATE_PAYMENT_DONE_SUCCESS = "UPDATE_PAYMENT_DONE_SUCCESS";
export const UPDATE_PAYMENT_DONE_FAIL = "UPDATE_PAYMENT_DONE_FAIL";

export const GET_ALL_PAYMENT_REQUEST = "GET_ALL_PAYMENT_REQUEST";
export const GET_ALL_PAYMENT_SUCCESS = "GET_ALL_PAYMENT_SUCCESS";
export const GET_ALL_PAYMENT_FAIL = "GET_ALL_PAYMENT_FAIL";

export const GET_PAYMENT_DETAILS_REQUEST = "GET_PAYMENT_DETAILS_REQUEST";
export const GET_PAYMENT_DETAILS_SUCCESS = "GET_PAYMENT_DETAILS_SUCCESS";
export const GET_PAYMENT_DETAILS_FAIL = "GET_PAYMENT_DETAILS_FAIL";

export const CLEAR_PAYMENT_ERRORS = "CLEAR_PAYMENT_ERRORS";
export const CLEAR_PAYMENT_LOADING = "CLEAR_PAYMENT_LOADING";
export const CLEAR_PAYMENT_STATE = "CLEAR_PAYMENT_STATE";
