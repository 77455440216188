// import nda_img from "./images/nda_img.png";
// import AFCAT from "./images/AFCAT.png";
// import ACC from "./images/ACC.png";
// import SSCGD from "./images/SSCGD.png";
// import Upsc from "./images/Upsc.png";
// import SSBINTERVIEW from "./images/SSBINTERVIEW.png";
// import Union from "./images/Union.png";
// import MNS from "./images/MNS.png";
// import Agniveer from "./images/Agniveer.png";
// import CDSSSB from "./images/CDSSSB.png";
// import CDSSSB from "./images/CDSSSB.png";
import jobSupport from "./images/jobSupport.svg";
import latestStudy from "./images/latestStudy.svg";
import practical from "./images/practical.svg";
import quiz from "./images/quiz.svg";
import dilbaagWhite from "./images/dilbaagWhite.png";
import monuWhite from "./images/monuWhite.png";
import ravinderWhite from "./images/ravinderWhite.png";
import ajay_chem_white from "./images/ajay_chem_white.jpg";
// import learnbest1 from "./images/learnbest1.png";
// import learnbest2 from "./images/learnbest2.png";
// import learnbest3 from "./images/learnbest3.png";
import testimonial1 from "./images/testimonial1.png";
// import testimonial2 from "./images/testimonial2.png";
// import testimonial3 from "./images/testimonial3.png";
import testimonial4 from "./images/testimonial4.svg";
import currHighlight1 from "./images/currHighlight1.png";
import currHighlight3 from "./images/currHighlight3.png";
import arpitGupta from "./images/arpitGupta.jpeg";
import amartyaDhami from "./images/amartyaDhami.jpeg";
import mridulNair from "./images/mridulNair.jpeg";
import ravinderYadav from "./images/ravinderHome.png";
import monuGuru from "./images/monuHome.png";
import drDilbaagSingh from "./images/dilbaagHome.png";
import jayakumar from "./images/jayakumar.png";
import manmohan from "./images/manmohan.png";
import {
  appWeb,
  educator1,
  educator2,
  educator3,
  educator4,
  focoModel,
  noRoyality,
  support,
  arpitGupta1,
  joydeepBiswas,
  sachinTyagi,
  GDCourse,
  techCourse,
  clerkCourse,
  currentHappening3,
  currentHappening2,
  currentHappening1,
  currentHappening,
  hot_headline,
  hot_headline1,
  hot_headline2,
  hot_headline3,
  award1,
  award2,
  award3,
  award4,
} from "./assets/images";

// COURSE DUMMY ARRAY
export const courseContent = [
  {
    id: "1",
    title: "shauryaGdPageTitle",
    icon: GDCourse,
    description:
      "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
    largeDescription:
      "(Intro text about Course) Agnipath Scheme is a tour of duty style scheme introduced by the Government of India on 14 June 2022, for recruitment of soldiers below the rank of commissioned officers into the three services of the armed forces. All recruits will be hired only for a four year period. Personnel recruited under this system are to be called Agni veers which will be a new military rank.",
    goalType: "Armed Forces",
    studyMode: "Online",
    category: "Air Force | Navy | Army",
  },
  {
    id: "2",
    title: "Shaurya Tech",
    icon: techCourse,
    description:
      "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
    largeDescription:
      "(Intro text about Course) Agnipath Scheme is a tour of duty style scheme introduced by the Government of India on 14 June 2022, for recruitment of soldiers below the rank of commissioned officers into the three services of the armed forces. All recruits will be hired only for a four year period. Personnel recruited under this system are to be called Agni veers which will be a new military rank.",
    goalType: "Armed Forces",
    studyMode: "Online",
    category: "Air Force | Navy | Army",
  },
  {
    id: "3",
    title: "Shaurya Clerk",
    icon: clerkCourse,
    description:
      "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
    largeDescription:
      "(Intro text about Course) Agnipath Scheme is a tour of duty style scheme introduced by the Government of India on 14 June 2022, for recruitment of soldiers below the rank of commissioned officers into the three services of the armed forces. All recruits will be hired only for a four year period. Personnel recruited under this system are to be called Agni veers which will be a new military rank.",
    goalType: "Armed Forces",
    studyMode: "Online",
    category: "Air Force | Navy | Army",
  },
  // {
  //   id: "4",
  //   title: "ACC",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Para Military",
  //   studyMode: "offline",
  //   category: "Military Education",
  // },
  // {
  //   id: "5",
  //   title: "SSC GD",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Para Military",
  //   studyMode: "offline",
  //   category: "Army",
  // },
  // {
  //   id: "6",
  //   title: "SSC CPO",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Police",
  //   studyMode: "offline",
  //   category: "Police",
  // },
  // {
  //   id: "7",
  //   title: "UPSC CAPF AC",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Police",
  //   studyMode: "offline",
  //   category: "Police",
  // },
  // {
  //   id: "8",
  //   title: "SSB INTERVIEW",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "MILITARY SCHOOL",
  //   studyMode: "Online",
  //   category: "Military Education",
  // },
  // {
  //   id: "9",
  //   title: "MILITARY SCHOOL",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "MILITARY SCHOOL",
  //   studyMode: "offline",
  //   category: "Military Education",
  // },
  // {
  //   id: "10",
  //   title: "STATE POLICE SI",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Police",
  //   studyMode: "offline",
  //   category: "Police",
  // },
  // {
  //   id: "11",
  //   title: "STATE POLICE CONSTABLE ",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Police",
  //   studyMode: "offline",
  //   category: "Police",
  // },
  // {
  //   id: "12",
  //   title: "MNS",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Navy",
  //   studyMode: "Online",
  //   category: "Navy",
  // },
  // {
  //   id: "13",
  //   title: "Agniveer",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Armed Forces",
  //   studyMode: "offline",
  //   category: "Army",
  // },
  // {
  //   id: "14",
  //   title: "CDS+SSB",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "MILITARY SCHOOL",
  //   studyMode: "Online",
  //   category: "Military Education",
  // },
  // {
  //   id: "15",
  //   title: "AGNIveer+SSB",
  //   icon: "https://via.placeholder.com/1920x1080/eee?text=16:9",
  //   description:
  //     "The strength of our nation lies in its people, the power of its armed forces—who gave their lives for our freedom.",
  //   goalType: "Armed Forces",
  //   studyMode: "Online",
  //   category: "Army",
  // },
];

export const revoLearning = [
  {
    id: "1",
    title: "Job support",
    img: jobSupport,
    description:
      "We offer live classes through our system so you can experience the real world learning while being at comfort",
  },
  {
    id: "2",
    title: "Latest study material",
    img: latestStudy,
    description:
      "Take your learning to the next level. We keep our curriculum up to date with latest study materials and content.",
  },
  {
    id: "3",
    title: "Practical Practices",
    img: practical,
    description:
      "We're always looking for ways to make it easier. Turn knowledge into action with t practical learning experience.",
  },
  {
    id: "4",
    title: "Regular quizzes",
    img: quiz,
    description:
      "We're here for you. We'll give you quizzes and tests to make sure you've got the skills you need to ace your goal.",
  },
];

export const learnFromBest = [
  {
    id: 1,
    img: ravinderWhite,
    title: "Ravinder Yadav",
    expertise: "B.Tech",
    teachFor: "Science & Social Science",
    language: "Hindi/English",
  },
  {
    id: 2,
    img: dilbaagWhite,
    title: "Dr Dilbaag Singh",
    expertise: "Ex Assistant Commandant (BSF)",
    teachFor: "English",
    language: "Hindi/English",
  },
  {
    id: 3,
    img: monuWhite,
    title: "Monu Gothwal ",
    expertise: "Msc Maths",
    teachFor: "Reasoning & Mathematics",
    language: "Hindi/English",
  },
  {
    id: 4,
    img: ajay_chem_white,
    title: "Ajay Shekhawat",
    expertise: "B.Sc Chemistry",
    teachFor: "Chemistry",
    language: "Hindi/English",
  },
];

// TESTIMONIAL ARRAY
export const testimonialArray = [
  {
    id: 1,
    videoLink: "https://www.youtube.com/embed/BEQCokuOwJ4",
    img: arpitGupta1,
    title: "Arpit Gupta",
    subtitle: "Cyber cafe owner",
    text: "“I just wanted to share a quick note and let you know that you guys do a really good job. I'm glad I decided to work with you. It's really great how easy your websites are to update and manage.work with you. It's really great how easy your websites are to update and manage",
  },
  {
    id: 2,
    videoLink: "https://www.youtube.com/embed/gIlhOHUuvps",
    img: joydeepBiswas,
    title: "Joydeep Biswas",
    subtitle: "Cyber cafe owner",
    text: "“I just wanted to share a quick note and let you know that you guys do a really good job. I'm glad I decided to work with you. It's really great how easy your websites are to update and manage.work with you. It's really great how easy your websites are to update and manage",
  },
  {
    id: 3,
    videoLink: "https://www.youtube.com/embed/BEQCokuOwJ4",
    img: sachinTyagi,
    title: "Sachin Tyagi",
    subtitle: "Cyber cafe owner",
    text: "“I just wanted to share a quick note and let you know that you guys do a really good job. I'm glad I decided to work with you. It's really great how easy your websites are to update and manage.work with you. It's really great how easy your websites are to update and manage",
  },
];

// COURSE CURRICULUM HIGHLIGHTS
export const currHighlights = [
  {
    id: 1,
    title: "Previous 20 year paper solving practices",
    img: currHighlight1,
    description:
      "Practice with previous 20 year papers to help you learn how to read and extract information, learn how to understand a paper and identify the key points.",
  },
  {
    id: 2,
    title: "Complete Course and subject wise mock tests",
    img: currHighlight1,
    description:
      "Mock Test Series with Complete Course and Subject Wise Mock Tests. It can help you to prepare better for the respective course exam",
  },
  {
    id: 3,
    title: "Doubt Solving Sessions",
    img: currHighlight3,
    description:
      "Our Unlimited Doubt Solving sessions are designed to help you get clarity on any situation you may be facing.",
  },
];

// Our soldiers heart content
export const soldierHeart = [
  {
    id: 1,
    img: arpitGupta,
    name: "Arpit Gupta",
    subHeading: "Recommended SSB Bhopal",
    text: "I found the interview guidance provided by Alpha Regiment to be incredibly helpful during my SSB. Not only did it improve my confidence, but it also helped me identify and correct my mistakes. I'm truly grateful for the wonderful guidance that was provided, which made a significant difference in my success.",
  },
  {
    id: 2,
    img: amartyaDhami,
    name: "Amartya Dhami",
    subHeading: "Recommended from 17 SSB",
    text: "I feel incredibly fortunate to have discovered the Alpha Regiment platform at the right time. Through their mock interview service, I received valuable feedback on my performance and areas where I needed improvement. The Alpha Regiment team provided genuine and helpful advice on how to address my shortcomings and perform better in the actual interview. The mock interview experience was incredibly useful in helping me understand what the interviewer is looking for and how to give my best performance. I'm truly grateful to the Alpha Regiment team and would highly recommend the platform to any SSB aspirant. 🤗",
  },
  {
    id: 3,
    img: mridulNair,
    name: "Mridul Nair",
    subHeading: "Recommended SSC Pilot",
    text: "I am really thank full to Alpha Regiment for their guidance. Got recommended for the flying branch of Indian Air Force.",
  },
];

// Meet YOUR GURUS ARRAY START  ----------- Home Page
export const meetGuruArray = [
  {
    id: 1,
    name: "Ravinder Yadav",
    img: ravinderYadav,
    type: "B.Tech",
    facebook: "https://www.facebook.com/ravinder43210",
    twitter: "",
    gmail: "ravinder@alpharegiment.com",
    whatsapp: "+918901484719",
    text: "Ravinder a former B.Tech graduate who went on to successfully qualify multiple defense exams, including CDS, AFCAT, and UPSC AC. He even had the opportunity to participate in the UPSC interview process twice. However, He eventually found his calling in the field of teaching, which he is thoroughly enjoying now. ",
  },
  {
    id: 2,
    name: "Monu Gothwal",
    img: monuGuru,
    type: "Msc Maths",
    facebook: "",
    twitter: "https://twitter.com/monugothwal036",
    gmail: "monugothwal036@gmail.com",
    whatsapp: "+918059514884",
    text: "Monu Gothwal, a highly qualified educator with a Bachelor's and Master's degree in Mathematics. With five years of teaching experience under his belt, Monu Sir has a keen interest in teaching Reasoning and Arithmetic Mathematics. With his passion for teaching and strong subject expertise, Monu Sir is committed to helping his students achieve their academic goals. ",
  },
  {
    id: 3,
    name: "Dr Dilbaag Singh",
    img: drDilbaagSingh,
    type: "Ex Assistant Commandant (BSF)",
    facebook: "https://www.facebook.com/dilbaag510000",
    twitter: "",
    gmail: "dilbaag@alpharegiment.com",
    whatsapp: "+918116699428",
    text: "Dilbaag Singh, an accomplished educator with a Ph.D. in Education and over 17 years of teaching experience. Dilbaag Sir has an impressive background, having previously served as an Assistant Commandant in the Border Security Force (BSF). With his extensive knowledge and expertise, Dilbaag Sir has made significant contributions to the field of education. Dilbaag Sir is a dedicated professional who is committed to inspiring and guiding his students towards academic excellence. ",
  },
];

// OUR TEAM OF EDUCTORS ARRAY - about page
export const eductorsTeam = [
  {
    id: 1,
    image: educator1,
    name: "Abhishek sinha",
    qualification: "NDA maths",
  },
  {
    id: 2,
    image: educator2,
    name: "Yogi adityanath",
    qualification: "Current affairs",
  },
  {
    id: 3,
    image: educator3,
    name: "Shubham gupta",
    qualification: "SSB trainer",
  },
  {
    id: 4,
    image: educator4,
    name: "Rahul Kumar",
    qualification: "Physics",
  },
  {
    id: 5,
    image: educator4,
    name: "Abhishek sinha",
    qualification: "NDA maths",
  },
  {
    id: 6,
    image: educator3,
    name: "Yogi adityanath",
    qualification: "Current affairs",
  },
  {
    id: 7,
    image: educator2,
    name: "Shubham gupta",
    qualification: "SSB trainer",
  },
  {
    id: 8,
    image: educator1,
    name: "Rahul Kumar",
    qualification: "Physics",
  },
  {
    id: 9,
    image: educator1,
    name: "Abhishek sinha",
    qualification: "NDA maths",
  },
  {
    id: 10,
    image: educator2,
    name: "Yogi adityanath",
    qualification: "Current affairs",
  },
  {
    id: 11,
    image: educator3,
    name: "Shubham gupta",
    qualification: "SSB trainer",
  },
  {
    id: 12,
    image: educator4,
    name: "Rahul Kumar",
    qualification: "Physics",
  },
  {
    id: 13,
    image: educator4,
    name: "Abhishek sinha",
    qualification: "NDA maths",
  },
  {
    id: 14,
    image: educator3,
    name: "Yogi adityanath",
    qualification: "Current affairs",
  },
  {
    id: 15,
    image: educator2,
    name: "Shubham gupta",
    qualification: "SSB trainer",
  },
  {
    id: 16,
    image: educator1,
    name: "Rahul Kumar",
    qualification: "Physics",
  },
];

// WHY ALPHA REGIMENT _ FRENCHISE HOME PAGE
export const frenchiseWhyAlpha = [
  {
    id: 1,
    title: "Best in Class ROI",
    image: focoModel,
    desc: "Our present franchises are getting best in the class ROI (Return on Investment).",
  },
  {
    id: 2,
    title: "Minimum Royalty Fees",
    image: noRoyality,
    desc: "We take minimum royalty fees from our franchises so that they have room for growth because new business demands investment.",
  },
  {
    id: 3,
    title: "We bear the loss",
    image: appWeb,
    desc: "Till our franchises start making a profit we bear all the losses because their success is our responsibility ",
  },
  {
    id: 4,
    title: "Technology",
    image: support,
    desc: "To make any business big we need Technology and for small businesses getting Technology is a little difficult. We help our franchise in this aspect as well.",
  },
];

// TESTIMONIALS FOR ========= FRANCHISE
export const frenchiseTestimonialArray = [
  {
    id: 1,
    videoLink: "https://www.youtube.com/embed/BEQCokuOwJ4",
    img: manmohan,
    title: "Manmohan Singh",
    subtitle: "Franchise owner",
    text: "Alpha Regiment is an organization built on values. I feel privileged by being a part of this great organization, whatever they said before giving the franchise, they still keep those words even after years. I am a very happy Alpha Regiment franchise owner.",
  },
  {
    id: 2,
    videoLink: "https://www.youtube.com/embed/gIlhOHUuvps",
    img: jayakumar,
    title: "Jayakumar",
    subtitle: "Franchise owner",
    text: "Really good experience with the Alpha Regiment. They not only support your current business but also brings business for you. Great organization.",
  },
];

// AFFILIATE FOR ========= FRANCHISE
export const affiliateTestimonialArray = [
  {
    id: 1,
    videoLink: "https://www.youtube.com/embed/BEQCokuOwJ4",
    img: testimonial1,
    title: "Vaibhav Sharma",
    subtitle: "Cyber cafe owner",
    text: "I am from a rural background, so most of the youth prepare for defense jobs here. So I find no difficulty in selling Alpha arrangement courses as their quality is also great. I make a quite good amount of money.",
  },
  {
    id: 2,
    videoLink: "https://www.youtube.com/embed/gIlhOHUuvps",
    img: testimonial4,
    title: "Lucky Singh",
    subtitle: "Cyber cafe owner",
    text: "I am very happy with the Alpha regiment's affiliate program because the affiliates dashboard is quite user-friendly, and we get timely payments.",
  },
];

// Meet OUR TEAM ARRAY START =============== ABOUT PAGE
export const meetTeamArray = [
  {
    name: "Ravinder Yadav",
    img: ravinderYadav,
    type: "Chief Executive Officer",
    facebook: "https://www.facebook.com/ravinder43210",
    twitter: "",
    gmail: "ravinder@alpharegiment.com",
    whatsapp: "+918901484719",
    text: "Ravinder a former B.Tech graduate who went on to successfully qualify multiple defense exams, including CDS, AFCAT, and UPSC AC. He even had the opportunity to participate in the UPSC interview process twice. However, He eventually found his calling in the field of teaching, which he is thoroughly enjoying now. ",
  },
  {
    name: "Dr Dilbaag Singh",
    img: drDilbaagSingh,
    type: "Chief Managing Officer",
    facebook: "https://www.facebook.com/dilbaag510000",
    twitter: "",
    gmail: "dilbaag@alpharegiment.com",
    whatsapp: "+918116699428",
    text: "Dilbaag Singh, an accomplished educator with a Ph.D. in Education and over 17 years of teaching experience. Dilbaag Sir has an impressive background, having previously served as an Assistant Commandant in the Border Security Force (BSF). With his extensive knowledge and expertise, Dilbaag Sir has made significant contributions to the field of education. Dilbaag Sir is a dedicated professional who is committed to inspiring and guiding his students towards academic excellence. ",
  },
  {
    name: "Ravinder Yadav",
    img: ravinderYadav,
    type: "Chief Executive Officer",
    facebook: "https://www.facebook.com/ravinder43210",
    twitter: "",
    gmail: "ravinder@alpharegiment.com",
    whatsapp: "+918901484719",
    text: "Ravinder a former B.Tech graduate who went on to successfully qualify multiple defense exams, including CDS, AFCAT, and UPSC AC. He even had the opportunity to participate in the UPSC interview process twice. However, He eventually found his calling in the field of teaching, which he is thoroughly enjoying now. ",
  },
  {
    name: "Dr Dilbaag Singh",
    img: drDilbaagSingh,
    type: "Chief Managing Officer",
    facebook: "https://www.facebook.com/dilbaag510000",
    twitter: "",
    gmail: "dilbaag@alpharegiment.com",
    whatsapp: "+918116699428",
    text: "Dilbaag Singh, an accomplished educator with a Ph.D. in Education and over 17 years of teaching experience. Dilbaag Sir has an impressive background, having previously served as an Assistant Commandant in the Border Security Force (BSF). With his extensive knowledge and expertise, Dilbaag Sir has made significant contributions to the field of education. Dilbaag Sir is a dedicated professional who is committed to inspiring and guiding his students towards academic excellence. ",
  },
];

// CURRENT HAPPENINGS ARRAY ======= STUDENT CMS
export const currentHappeningArray = [
  {
    id: 1,
    title: "Drill in Army ground ",
    content:
      "The 18th edition of the Indo-US military drill will end on the first...",
    img: currentHappening,
  },
  {
    id: 2,
    title: "Drill in Army ground ",
    content:
      "The 18th edition of the Indo-US military drill will end on the first...",
    img: currentHappening1,
  },
  {
    id: 3,
    title: "Drill in Army ground ",
    content:
      "The 18th edition of the Indo-US military drill will end on the first...",
    img: currentHappening2,
  },
  {
    id: 4,
    title: "Drill in Army ground ",
    content:
      "The 18th edition of the Indo-US military drill will end on the first...",
    img: currentHappening3,
  },
];

// HOT HEADINGS ARRAY ============ STUDENT LMS
export const hotHeadingArray = [
  {
    id: 1,
    bgImg: hot_headline,
    title: "Indian Defence Forces ",
    content:
      "He was hinting at India's aim to become net exporter of defence technology and equipment. At the curtain raiser for the event..",
  },
  {
    id: 2,
    bgImg: hot_headline1,
    title: "Indian Defence Forces ",
    content:
      "He was hinting at India's aim to become net exporter of defence technology and equipment. At the curtain raiser for the event..",
  },
  {
    id: 3,
    bgImg: hot_headline2,
    title: "Indian Defence Forces ",
    content:
      "He was hinting at India's aim to become net exporter of defence technology and equipment. At the curtain raiser for the event..",
  },
  {
    id: 4,
    bgImg: hot_headline3,
    title: "Indian Defence Forces ",
    content:
      "He was hinting at India's aim to become net exporter of defence technology and equipment. At the curtain raiser for the event..",
  },
];

// AWARD AND RECOGNITION ======= HOME PAGE
export const awardRecogArr = [
  {
    img: award1,
  },
  {
    img: award2,
  },
  {
    img: award3,
  },
  {
    img: award4,
  },
];
