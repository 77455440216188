export const ALL_COURSES_REQUEST = "ALL_COURSES_REQUEST";
export const ALL_COURSES_SUCCESS = "ALL_COURSES_SUCCESS";
export const ALL_COURSES_FAIL = "ALL_COURSES_FAIL";

export const MY_COURSES_REQUEST = "MY_COURSES_REQUEST";
export const MY_COURSES_SUCCESS = "MY_COURSES_SUCCESS";
export const MY_COURSES_FAIL = "MY_COURSES_FAIL";

export const COURSE_DETAIL_REQUEST = "COURSE_DETAIL_REQUEST";
export const COURSE_DETAIL_SUCCESS = "COURSE_DETAIL_SUCCESS";
export const COURSE_DETAIL_FAIL = "COURSE_DETAIL_FAIL";

export const COURSE_PAUSE_REQUEST = "COURSE_PAUSE_REQUEST";
export const COURSE_PAUSE_SUCCESS = "COURSE_PAUSE_SUCCESS";
export const COURSE_PAUSE_FAIL = "COURSE_PAUSE_FAIL";

export const COURSE_LONG_DETAIL_REQUEST = "COURSE_LONG_DETAIL_REQUEST";
export const COURSE_LONG_DETAIL_SUCCESS = "COURSE_LONG_DETAIL_SUCCESS";
export const COURSE_LONG_DETAIL_FAIL = "COURSE_LONG_DETAIL_FAIL";

export const COURSE_META_DETAIL_REQUEST = "COURSE_META_DETAIL_REQUEST";
export const COURSE_META_DETAIL_SUCCESS = "COURSE_META_DETAIL_SUCCESS";
export const COURSE_META_DETAIL_FAIL = "COURSE_META_DETAIL_FAIL";

export const COURSE_ADD_REVIEW_REQUEST = "COURSE_ADD_REVIEW_REQUEST";
export const COURSE_ADD_REVIEW_SUCCESS = "COURSE_ADD_REVIEW_SUCCESS";
export const COURSE_ADD_REVIEW_FAIL = "COURSE_ADD_REVIEW_FAIL";


export const COURSE_DELETE_REVIEW_REQUEST = "COURSE_DELETE_REVIEW_REQUEST";
export const COURSE_DELETE_REVIEW_SUCCESS = "COURSE_DELETE_REVIEW_SUCCESS";
export const COURSE_DELETE_REVIEW_FAIL = "COURSE_DELETE_REVIEW_FAIL";

export const RATING_CLEAR_ERRORS = "RATING_CLEAR_ERRORS";
export const RATING_CLEAR_DELETE_STATE = "RATING_CLEAR_DELETE_STATE"
export const RATING_CLEAR_STATE = "RATING_CLEAR_STATE";



export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";
