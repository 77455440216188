export const CONNECTION_REQUEST_SEND = "CONNECTION_REQUEST_SEND";
export const CONNECTION_REQUEST_SUCCESS = "CONNECTION_REQUEST_SUCCESS";
export const CONNECTION_REQUEST_FAIL = "CONNECTION_REQUEST_FAIL";

export const GET_CONNECTION_REQUEST = "GET_CONNECTION_REQUEST";
export const GET_CONNECTION_SUCCESS = "GET_CONNECTION_SUCCESS";
export const GET_CONNECTION_FAIL = "GET_CONNECTION_FAIL";

export const ACCEPT_CONNECTION_REQUEST = "ACCEPT_CONNECTION_REQUEST";
export const ACCEPT_CONNECTION_SUCCESS = "ACCEPT_CONNECTION_SUCCESS";
export const ACCEPT_CONNECTION_FAIL = "ACCEPT_CONNECTION_FAIL";

export const REJECT_CONNECTION_REQUEST = "REJECT_CONNECTION_REQUEST";
export const REJECT_CONNECTION_SUCCESS = "REJECT_CONNECTION_SUCCESS";
export const REJECT_CONNECTION_FAIL = "REJECT_CONNECTION_FAIL";

export const REMOVE_CONNECTION_REQUEST = "REMOVE_CONNECTION_REQUEST";
export const REMOVE_CONNECTION_SUCCESS = "REMOVE_CONNECTION_SUCCESS";
export const REMOVE_CONNECTION_FAIL = "REMOVE_CONNECTION_FAIL";

export const CLEAR_REMOVE_CONNECTION_STATE = "CLEAR_REMOVE_CONNECTION_STATE";
export const CLEAR_CONNECTION_ERROR = "CLEAR_CONNECTION_ERROR";
export const CLEAR_CONNECTION_STATE = "CLEAR_CONNECTION_STATE";
