export const ADD_WISHLIST_ITEM_REQUEST = "ADD_WISHLIST_ITEM_REQUEST";
export const ADD_WISHLIST_ITEM_SUCCESS = "ADD_WISHLIST_ITEM_SUCCESS";
export const ADD_WISHLIST_ITEM_FAIL = "ADD_WISHLIST_ITEM_FAIL";

export const GET_WISHLIST_ITEM_REQUEST = "GET_WISHLIST_ITEM_REQUEST";
export const GET_WISHLIST_ITEM_SUCCESS = "GET_WISHLIST_ITEM_SUCCESS";
export const GET_WISHLIST_ITEM_FAIL = "GET_WISHLIST_ITEM_FAIL";


export const DELETE_WISHLIST_ITEM_REQUEST = "DELETE_WISHLIST_ITEM_REQUEST";
export const DELETE_WISHLIST_ITEM_SUCCESS = "DELETE_WISHLIST_ITEM_SUCCESS";
export const DELETE_WISHLIST_ITEM_FAIL = "DELETE_WISHLIST_ITEM_FAIL";
export const CLEAR_DELETE_WISHLIST_ITEM_STATE = "CLEAR_DELETE_WISHLIST_ITEM_STATE";

export const CLEAR_WISHLIST_ERROR = "CLEAR_WISHLIST_ERROR";
export const CLEAR_WISHLIST_STATE = "CLEAR_WISHLIST_STATE";