import React from "react";
import styled from "styled-components";

const CheckboxWrapper = styled.div`
  margin-bottom: 25px;
  display:flex;
  align-items:center;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #f2f2f2;
    margin-left:6px;
  }
  >input{
    width:16px;
    height:16px;
    
  }
`;
const CustomCheckbox = ({ label,checked, onChange, id }) => {
  return (
    <CheckboxWrapper>
      <input
        onChange={onChange}
        type="checkbox"
        checked={checked}
        id={id}
        name={id}
        value={label}
      />
      <label htmlFor={id}>{label}</label>
    </CheckboxWrapper>
  );
};

export default CustomCheckbox;
