import React from "react";
import CustomButton from "../../components/reusable/CustomButton";
import { MdOutlineReplyAll } from "react-icons/md";
import { Rating } from "react-simple-star-rating";
import { defaultUser } from "../../assets/images";
import styled from "styled-components";
import moment from "moment";

const RevCard = styled.div`
  padding: 15px;
  box-sizing: border-box;
  background: linear-gradient(
    116.1deg,
    rgba(255, 255, 255, 0.4) -106.73%,
    rgba(255, 255, 255, 0) 108.62%
  );
  border-radius: 20px;

  > .list_owner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 650px) {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
      row-gap: 15px;
    }

    > .details {
      display: flex;
      align-items: center;

      > div {
        &:first-child {
          padding: 3px;
          box-sizing: border-box;
          width: 60px;
          height: 60px;
          background: #f2f2f2;
          border-radius: 50%;
          margin-right: 10px;
          overflow: hidden;

          > img {
            width: 100%;
            border-radius: 50%;

            object-fit: cover;
          }
        }

        &:last-child {
          > .uName {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 5px;
          }

          > .stars {
            display: flex;
            align-items: center;

            > p {
              margin: 0 0 0 5px;
              font-size: 12px;
            }
          }
        }
      }
    }

    > .course_timeDate {
      > .enrolled_course {
        font-size: 15px;
        font-wight: 400;
        margin-bottom: 5px;
      }

      > .date {
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }

  > .review_text {
    font-size: 15px;
    margin: 15px 0;
    color: #f2f2f2ba;
    text-align: justify;
  }

  > .rev_replyBtn {
    margin: 0 20px 0 auto;
    border: none;
    background: tranaparent;
    width: fit-content;

    &:hover {
      background: transparent;
      color: #f2f2f2;
      text-shadow: -1px 2px 2px #fff;
    }
  }
`;

const CourseRevCard = ({ data, createBy }) => {
  return (
    <RevCard className="review_card">
      <div className="list_owner">
        <div className="details">
          <div>
            <img
              src={
                data?.user?.profile?.url
                  ? data?.user?.profile?.url
                  : defaultUser
              }
              alt="defaultUser"
            />
          </div>
          <div>
            <div className="uName">{data?.user?.first_name}</div>
            <div className="stars">
              <Rating
                initialValue={data?.rating}
                readonly={true}
                transition={true}
                allowFraction={true}
                size={20}
              />
              <p>{data?.rating}</p>
            </div>
          </div>
        </div>
        <div className="course_timeDate">
          {/* <div className="enrolled_course">
            Course Enrolled: Ravinder's Full SSB...
          </div> */}
          <div className="date">
            Date: {moment(data?.updatedAt).format("MMMM DD,YYYY")}
          </div>
        </div>
      </div>
      <p className="review_text">{data?.comment}</p>
      <CustomButton
        className="rev_replyBtn"
        title="Reply"
        // onClick=""
        // icon=""
        disabled={true}
        // width=""
        preIcon={<MdOutlineReplyAll />}
      />
    </RevCard>
  );
};

export default CourseRevCard;
