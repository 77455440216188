import { io } from "socket.io-client";

let socket;
const socketConnection = (jwt) => {
  if (socket && socket.connected) {
    return socket;
  } else {
    socket = io.connect(process.env.REACT_APP_API_URL, { auth: { jwt } });
    return socket;
  }
};

export default socketConnection;
