import React from "react";
import styled from "styled-components";

const WelcomeContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > .welcome_txt {
    font-size: 20px;
    text-align: center;
  }
`;

const Welcome = () => {
  return (
    <div className="bg_color txt_color">
      <div className="container">
        <WelcomeContainer>
          <div className="welcome_img"></div>
          <div className="welcome_txt">Welcome</div>
        </WelcomeContainer>
      </div>
    </div>
  );
};

export default Welcome;
