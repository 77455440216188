import React from "react";
import "./conferenceSpeaker.scss";
import {
  drDilbaagSinghHome,
  logo_mobile,
  ravinderYadavHome,
} from "../../assets/images";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";

const ConferenceSpeaker = () => {
  return (
    <div className="conf_spkrs_main">
      <div className="container">
        <div className="title">
          <h2>Conference Keynote Speakers</h2>
          <p>
            Eventer 2022 gathered the brightest minds in the tech world. Learn
            new skills and get insights from experts and practitioners from all
            around!
          </p>
        </div>

        <div className="spkrs_content">
          <div className="spkr_card_single">
            {/* <img src={ravinderYadavHome} alt="ravinderYadavHome" /> */}
            <ImgLazyLoad
              src={ravinderYadavHome}
              alt="ravinderYadavHome"
              placeholder={logo_mobile}
            />
            <div className="txt_section">
              <h2>Ravinder Yadav</h2>
              <p>chief executive officer</p>
            </div>
            <div className="overlap_sec"></div>
          </div>
          <div className="spkr_card_single">
            {/* <img src={drDilbaagSinghHome} alt="drDilbaagSinghHome" /> */}
            <ImgLazyLoad
              src={drDilbaagSinghHome}
              alt="drDilbaagSinghHome"
              placeholder={logo_mobile}
            />
            <div className="txt_section">
              <h2>Dr. Dilbaag Singh</h2>
              <p>chief managing officer</p>
            </div>
            <div className="overlap_sec"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceSpeaker;
