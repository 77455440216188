import {
  CLEAR_CHAT_ERROR,
  CLEAR_CHAT_STATE,
  CREATE_CONVERSATION_FAIL,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  GET_MESSAGE_FAIL,
  GET_MESSAGE_REQUEST,
  GET_MESSAGE_SUCCESS,
  GET_MY_CONVERSATION_FAIL,
  GET_MY_CONVERSATION_REQUEST,
  GET_MY_CONVERSATION_SUCCESS,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAIL,
  UPDATE_MESSAGE_REQUEST,
  UPDATE_MESSAGE_SUCCESS,
} from "../constants/chatConstant";

// CREATE NEW CONVERSATION ======
export const createConversationReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CONVERSATION_REQUEST:
      return {
        conversation: {},
        loading: true,
      };
    case CREATE_CONVERSATION_SUCCESS:
      return {
        loading: false,
        conversation: action.payload,
      };
    case CREATE_CONVERSATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_CHAT_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CHAT_STATE:
      return {
        loading: false,
        conversation: {},
      };

    default:
      return state;
  }
};

// GET MY CONVERSATIONS ======
export const myConversationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MY_CONVERSATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MY_CONVERSATION_SUCCESS:
      return {
        loading: false,
        conversation: action.payload,
      };
    case GET_MY_CONVERSATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_CHAT_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// SEND NEW MESSAGE ======
export const sendMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_MESSAGE_REQUEST:
      return {
        message: {},
        loading: true,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case SEND_MESSAGE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_CHAT_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CHAT_STATE:
      return {
        loading: false,
        message: {},
      };

    default:
      return state;
  }
};

// GET MESSAGES ======
export const getMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MESSAGE_REQUEST:
      return {
        message: {},
        loading: true,
      };
    case GET_MESSAGE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case GET_MESSAGE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_CHAT_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE MESSAGE ======
export const upadteMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MESSAGE_REQUEST:
      return {
        message: {},
        loading: true,
      };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case UPDATE_MESSAGE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_CHAT_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
      case CLEAR_CHAT_STATE:
      return {
        loading: false,
        message: {},
      };

    default:
      return state;
  }
};
