import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import { Link, useNavigate } from "react-router-dom";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import CourseCard from "../../components/course/CourseCard";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import "../../styles/ssbCoaching.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
import { IoMdArrowRoundBack } from "react-icons/io";
const MockSsbInterview = () => {
  const currentPageUrl = window?.location?.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [t] = useLocale();
  const { loading, courses, error } = useSelector(
    (state) => state.getAllSsbCourses
  );

  let allSsbApiCourses = courses?.courses?.filter(
    (itm) =>
      itm?.category === "Mock SSB Interview" &&
      ((itm?.isActive && !itm?.isPause) ||
        (itm?.isActive && itm?.isPause && itm?.pauseForDate !== null))
  );
  const [courseMode, setCourseMode] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const handleSearchChange = (e) => {
    const filter = e.target.value;
    setSearchCategory(filter);
    const filterdata = allSsbApiCourses?.filter((i) => {
      return (
        i.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) &&
        i.courseMode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase())
      );
    });
    allSsbApiCourses = filterdata;
  };
  const handleFilterMode = (query) => {
    setCourseMode(query);
    const filterData = allSsbApiCourses?.filter((course) => {
      return course?.courseMode === query;
    });
    allSsbApiCourses = filterData;
  };

  // console.log(allSsbApiCourses);

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch]);

  const handleSsbDetailsPage = (data) => {
    // if (categoryTitle === "Spoken English") {
    //   const { title, study_mode, courseCategory, _id } = data;
    //   const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    //   const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
    //   const formattedCategory = courseCategory
    //     .toLowerCase()
    //     .replace(/\s+/g, "-");
    //   const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
    //   navigate(seoFriendlyUrl);
    // } else {
    const { slug } = data;
    const seoFriendlyUrl = `/ssb-course-details/${slug}`;
    navigate(seoFriendlyUrl);
    // }
  };

  // CHECK WISHLIST EXIST OR NOT
  const [wishListId, setWishListId] = useState("");

  const {
    // eslint-disable-next-line
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    setWishListId("");
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES
  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="Enroll in a mock SSB interview to identify and improve your weaknesses, increasing your chances of being recommended in your next attempt."
            author="Team Alpha Regiment"
            title="Experience SSB Before Going: Enroll in a Mock SSB Interview"
            canonical={currentPageUrl}
          />
          <section className="ssb_coaching_courses_main">
            <div className="container">
              <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div>

              <div className="all_course_content">
                <div className="all_course_cards">
                  <h1 className="main_title"><span onClick={()=> navigate(-1)}><IoMdArrowRoundBack /></span> Mock SSB Interview</h1>
                  <p className="description">
                    Many repeater candidates express frustration that the SSB
                    doesn't inform them of the mistakes they made, which leads
                    to them being either conferenced out or screened out. A mock
                    SSB is an ideal solution for them. It provides an
                    opportunity to evaluate their preparation for the SSB
                    interview and helps identify their shortcomings, offering
                    clear guidance for improvement.
                  </p>

                  {allSsbApiCourses?.length > 0 && (
                    <div className="slider_container">
                      {allSsbApiCourses?.map((item, idx) => {
                        const roundedPrice = Math.ceil(item?.originalPrice);
                        const roundedDiscountedPrice = Math.ceil(
                          item?.discountedPrice
                        );
                        return (
                          <CourseCard
                            key={idx}
                            isActiveClass={true}
                            className="course_card"
                            title={item?.title}
                            description={`${item?.description?.substr(
                              0,
                              40
                            )}...`}
                            icon={item?.thumbnail?.url}
                            startingFrom={item?.starting_date}
                            price={roundedPrice}
                            discountedPrice={roundedDiscountedPrice}
                            numOfReviews={item?.numOfReviews}
                            rating={item?.rating}
                            addWishList={() =>
                              handleAddtoWishlist({
                                itemId: item?._id,
                                isSsb: true,
                              })
                            }
                            deleteWishListLoading={
                              wishListId === item?._id &&
                              deleteLoading && <ButtonLoader />
                            }
                            existInWishList={allWishList?.wishlist?.ssbCourse?.some(
                              (obj) => obj._id === item._id
                            )}
                            addWishListLoading={
                              wishListId === item?._id &&
                              addWishListLoading && <ButtonLoader />
                            }
                            onHomePageClick={() => handleSsbDetailsPage(item)}
                          />
                        );
                      })}
                    </div>
                  )}

                  {allSsbApiCourses?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is Mock SSB?</h2>
                </div>
                <div className="detail">
                  <p>
                    You can consider a mock SSB as equivalent to a real SSB
                    interview, with the key difference being that after the mock
                    SSB, all three assessors will review your performance
                    together and provide feedback on your shortcomings. This
                    feedback will help you identify and correct your mistakes,
                    better preparing you for your next SSB.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is the duration of Mock SSB?</h2>
                </div>
                <div className="detail">
                  <p>
                    Since the SSB interview is a 5-day program, the mock SSB
                    will also last a minimum of 5 days. Additional days may be
                    included for extra activities, such as detailed feedback
                    sessions or focused work on specific weaknesses.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    Is it necessary to stay in the hostels during the Mock SSB?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    The requirements can vary for each mock SSB. In some cases,
                    daily commuting is allowed, while in others, staying on
                    campus is mandatory. Be sure to read the guidelines
                    carefully. However, to best simulate the real SSB
                    experience, staying in the hostel is recommended.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    What if any aspirant gets screened out in the Mock SSB?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    In a mock SSB, you typically aren't informed about the
                    result at the screening stage. After completing the five-day
                    program, you'll be told whether your performance would have
                    resulted in being screened out or screened in at stage one.
                    Subsequently, in stage two, you'll learn whether you would
                    have made the final recommendation list or been conferenced
                    out.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Mock SSB is best for whom?</h2>
                </div>
                <div className="detail">
                  <p>
                    Mock SSBs can be the best solution for three categories of
                    aspirants.
                  </p>
                  <ol>
                    <li>
                      <b>Repeaters:</b>
                      For aspirants who have attended multiple SSBs but remain
                      unclear about why they are consistently being screened out
                      or conferenced out, a mock SSB can be invaluable. It helps
                      identify areas needing improvement, enabling them to
                      address these issues and move closer to realizing their
                      dream of serving the motherland.
                    </li>
                    <li>
                      <b>Do-it-yourself (DIY), type of students:</b>
                      Any student who has independently learned about the SSB
                      from various free online sources and completed their
                      preparation solo may now seek to assess their performance.
                      In such cases, a mock SSB can offer the most suitable
                      solution for them.
                    </li>
                    <li>
                      <b>Who wants to experience SSB, even before SSB:</b>
                      For any student desiring to gain first-hand experience of
                      the SSB interview before facing the actual board, a mock
                      SSB is the best solution.
                    </li>
                    <li>
                      <b>Who wants to Analyze the preparation:</b>
                      For any student who has dedicated significant effort to
                      preparation but seeks to evaluate whether they are ready
                      for recommendation or require further improvement, a mock
                      SSB offers the ideal solution. By performing in a
                      realistic environment and receiving feedback from real
                      assessors, students can accurately analyze their
                      preparation level.
                    </li>
                  </ol>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>How “Mock SSB” is better than coaching?</h2>
                </div>
                <div className="detail">
                  <ol>
                    <li>
                      <b>“Mock SSB” is a Personalised way of preparation:</b>
                      The SSB interview is a practical exam where each candidate
                      is evaluated individually by three officers on the same
                      Officer Like Qualities (OLQs), but through three distinct
                      methods. Therefore, personalized learning, where you are
                      assessed individually rather than as part of a group, can
                      be the most effective approach for you. One such method is
                      a Mock SSB, which simulates the actual interview process.
                    </li>
                    <li>
                      <b>Mock SSB checks your preparation:</b>A mock SSB allows
                      you to gauge your preparation level before attending the
                      actual Board, helping you avoid wasting a real attempt by
                      analyzing your readiness beforehand.
                    </li>
                    <li>
                      <b>Mock SSB is a practical way of Learning</b>
                      For many aspirants who learn best through hands-on
                      experience, a mock SSB can be highly beneficial. It
                      provides the opportunity to practice in an environment
                      that closely mirrors the real Board, allowing them to
                      perform the required tasks under realistic conditions.
                    </li>
                  </ol>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Is “Mock SSB” best in all situations?</h2>
                </div>
                <div className="detail">
                  <p>
                    Nothing in the universe is perfect for everyone. Some
                    students may find traditional <Link to="/ssb-coaching">SSB coaching</Link> more beneficial.
                    At Alpha Regiment, we cater to this preference by offering
                    coaching sessions as well.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default MockSsbInterview;
