import React from "react";
import styled from "styled-components";

const MyInput = styled.div`
  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #f2f2f2;
    > .impStar {
      color: red;
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }
  .input_icon {
    position: relative;
    > .icon {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #a9b5bb;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }

    > .eyeIcon {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #a9b5bb;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
  }
  .input_text {
    display: block;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      115.85deg,
      rgba(255, 255, 255, 0.4) -185.19%,
      rgba(255, 255, 255, 0) 129.07%
    );
    backdrop-filter: blur(21px);
    border-radius: 4px;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    color: #a9b5bb;
    font-size: 14px;
    @media screen and (max-width: 575px) {
      height: 40px;
      font-size: 13px;
    }
  }
`;

const CustomFileInput = ({
  icon,
  label,
  type,
  onChange,
  placeholder,
  name,
  value,
  impStar,
  readOnly,
  borderColor,
  accept,
  eyeIcon,
  setshowPassword,
  fullWidth,
  id,
}) => {
  return (
    <MyInput className="myInput" style={{ width: fullWidth ? "100%" : "" }}>
      {label && (
        <label>
          {label} {impStar && <abbr className="impStar">{impStar}</abbr>}
        </label>
      )}
      <div className="input_icon">
        {icon && <span className="icon">{icon}</span>}
        <input
          accept={accept}
          className="input_text"
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          value={value}
          readOnly={readOnly}
          id={id}
          multiple
          autoComplete="off"
          style={{
            border: borderColor && `1px solid ${borderColor}`,
            paddingLeft: icon && "50px",
          }}
        />
        
      </div>
    </MyInput>
  );
};

export default CustomFileInput;
