import { Fragment, useEffect, useState } from "react";
// import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/editDossierCreate.scss";
import CustomButton from "../../components/reusable/CustomButton";
import { FaPlus, FaUpload } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import CustomFileInput from "../../components/reusable/CustomFileInput";
import CustomInput from "../../components/reusable/CustomInput";
import { useNotification } from "../../context/notificationContext";
import {
  dossierClearErrors,
  getDossierByIdAction,
} from "../../redux/actions/dossierAction";
import { useDispatch, useSelector } from "react-redux";
// import ButtonLoader from "../../components/reusable/ButtonLoader";
import { MdDelete } from "react-icons/md";
import Loader from "../../components/reusable/Loader";
const EditDossier = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const { id } = useParams();

  const {
    error: getError,
    loading: getLoading,
    dossierData: data,
  } = useSelector((state) => state.getDossierById);

  const dossierById = data?.dossier;

  useEffect(() => {
    if (id) {
      dispatch(getDossierByIdAction(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (getError) {
      showNotification("error", getError);
      dispatch(dossierClearErrors());
    }
  }, [getError, dispatch, showNotification]);

  const [instruction, setInstruction] = useState("");
  const [instructionList, setInstructionList] = useState(
    dossierById ? dossierById.instructionList : []
  );
  const [formData, setFormData] = useState({
    title: dossierById?.title ? dossierById.title : "",
    description: dossierById?.description ? dossierById.description : "",
  });

  // INPUT ON CHANGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // DOSSIER WORD STATE
  const [word, setWord] = useState("");
  const [allWords, setAllWords] = useState(
    dossierById?.allWords ? dossierById.allWords : []
  );

  const [question, setQuestion] = useState("");
  const [allSdQuestions, setAllSdQuestions] = useState(
    dossierById?.allSdQuestions ? dossierById?.allSdQuestions : []
  );

  const [imageList, setImageList] = useState(
    dossierById?.allImages ? dossierById.allImages : []
  );

  const [situations, setSituations] = useState("");
  const [situtationList, setSitutationList] = useState(
    dossierById?.situtationList ? dossierById?.situtationList : []
  );

  useEffect(() => {
    if (dossierById) {
      setSitutationList(dossierById?.situtationList);
      setImageList(dossierById?.allImages);
      setAllSdQuestions(dossierById?.allSdQuestions);
      setAllWords(dossierById.allWords);
    }
  }, [
    dossierById,
    dossierById?.situtationList,
    dossierById?.allImages,
    dossierById?.allSdQuestions,
  ]);

  const handleAddWord = () => {
    if (!word) {
      showNotification("error", "Please add a word");
    } else if (word.includes(" ")) {
      showNotification(
        "error",
        "Please enter only a single word without spaces."
      );
    } else if (allWords.length >= 60) {
      showNotification("error", "You can add maximum 60 words");
    } else {
      setAllWords([...allWords, { word: word }]);
      setWord("");
    }
  };

  // HANDLE REMOVE LIST
  const handleRemoveAdd = (index) => {
    const updatedallWords = allWords.filter((_, i) => i !== index);
    setAllWords(updatedallWords);
  };

  // DRAG AND DROP FOR WORDS
  const handleDragWordStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleWordDrop = (e, currentIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedWords = [...allWords];
    const [draggedWord] = updatedWords.splice(draggedIndex, 1);
    updatedWords.splice(currentIndex, 0, draggedWord);
    setAllWords(updatedWords);
  };

  const handleWordDragOver = (e) => {
    e.preventDefault();
  };

  // DOSSIER TEST IMAGE
  const handleAvatarChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImageList((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const handleDragStartSituation = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDropSituation = (e, currentIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedWords = [...imageList];
    const [draggedWord] = updatedWords.splice(draggedIndex, 1);
    updatedWords.splice(currentIndex, 0, draggedWord);
    setImageList(updatedWords);
  };

  const handleDragOverSituation = (e) => {
    e.preventDefault();
  };

  const handleRemoveImage = (index) => {
    const updatedImageList = [...imageList];
    updatedImageList.splice(index, 1);
    setImageList(updatedImageList);
  };

  // HANDLE FEATURE ADDS START
  const handleAddInstruction = () => {
    if (!instruction) {
      showNotification("error", "Please add a instruction");
    } else {
      setInstructionList([...instructionList, { instruction: instruction }]);
      setInstruction("");
    }
  };

  // HANDLE REMOVE instruction
  const handleRemoveInstruction = (index) => {
    const updatedinstructionList = instructionList.filter(
      (_, i) => i !== index
    );
    // Update the state with the new array
    setInstructionList(updatedinstructionList);
  };

  // DOOSIER SD FUNCTIONS STARTS
  const handleAddQuestion = () => {
    if (!question) {
      showNotification("error", "Please add a question");
    } else if (allSdQuestions.length >= 5) {
      showNotification("error", "You can add only 5 questions");
    } else {
      setAllSdQuestions([...allSdQuestions, { question: question }]);
      setQuestion("");
    }
  };

  // DRAG AND DROP FOR WORDS
  const handleDragStartQuestion = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDropQuestion = (e, currentIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedWords = [...allSdQuestions];
    const [draggedWord] = updatedWords.splice(draggedIndex, 1);
    updatedWords.splice(currentIndex, 0, draggedWord);
    setAllSdQuestions(updatedWords);
  };

  const handleDragOverQuestion = (e) => {
    e.preventDefault();
  };

  // HANDLE REMOVE LIST
  const handleRemoveQuestion = (index) => {
    const updatedWordList = allSdQuestions.filter((_, i) => i !== index);
    // Update the sWATe with the new array
    setAllSdQuestions(updatedWordList);
  };

  // HANDLE SRT FUNCTION STARTS
  const handleAddsituations = () => {
    if (!situations) {
      showNotification("error", "Please add a situations");
    } else if (situtationList.length >= 60) {
      showNotification("error", "You can add only 60 situations");
    } else {
      setSitutationList([...situtationList, { situations: situations }]);
      setSituations("");
    }
  };
  // DRAG AND DROP FOR WORDS
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDrop = (e, currentIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedWords = [...situtationList];
    const [draggedWord] = updatedWords.splice(draggedIndex, 1);
    updatedWords.splice(currentIndex, 0, draggedWord);
    setSitutationList(updatedWords);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // HANDLE REMOVE LIST
  const handleRemovesituations = (index) => {
    const updatedWordList = situtationList.filter((_, i) => i !== index);
    // Update the sWATe with the new array
    setSitutationList(updatedWordList);
  };

  // FORM SUBMIT dossier CREATION
  const handleSubmit = () => {
    showNotification("error", "not api integrate yet");
  };


  return (
    <Fragment>
      {getLoading ? (
        <Loader />
      ) : (
        <div className="edit_dossier_create">
          <div className="header">
            <div className="title">
              Edit {dossierById?.title}
              <button
                title="Go Back"
                onClick={() => navigate("/officer/upload-dossier")}
              >
                <RxCross2 />
              </button>
            </div>
          </div>
          <div className="container">
            <div>
              <div className="create_dossier_content">
                <div className="dossierForm">
                  <CustomInput
                    label="Title"
                    type="text"
                    placeholder="SSB interview "
                    name="title"
                    impStar="*"
                    value={formData.title}
                    onChange={handleInputChange}
                  />

                  <CustomTextarea
                    label="Description"
                    type="text"
                    impStar="*"
                    placeholder="Upto 500 words..."
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    fullWidth
                  />
                  <div className="sc-irTswW izZXSN myInput">
                    <label>
                      Instruction <abbr className="impStar">*</abbr>
                    </label>
                    <div className="input_icon courseinstruction">
                      <input
                        className="input_text"
                        type="text"
                        placeholder="instruction..."
                        name="feature"
                        value={instruction}
                        onChange={(e) => setInstruction(e.target.value)}
                      />
                      <button
                        title="Add More Instruction"
                        onClick={handleAddInstruction}
                      >
                        <FaPlus />
                      </button>
                    </div>
                    {instructionList && instructionList.length > 0 && (
                      <div className="feature_list">
                        <ul>
                          {instructionList.map((ele, i) => (
                            <li key={i}>
                              <div>
                                <p>{ele.instruction}</p>
                                <span
                                  onClick={() => handleRemoveInstruction(i)}
                                >
                                  <RxCross2 />
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {imageList?.length > 0 && (
                    <div className="test_images">
                      <label>
                        Upload TAT images <abbr className="impStar">*</abbr>
                      </label>
                      <div className="profile_pic">
                        <label htmlFor="uploadProfilePic">
                          <FaUpload /> Select Images
                        </label>
                        <CustomFileInput
                          id="uploadProfilePic"
                          label="Upload Course Image"
                          type={"file"}
                          impStar="*"
                          name="image"
                          accept="image/*"
                          onChange={handleAvatarChange}
                        />
                        <div className="review_images">
                          {imageList?.map((image, index) => (
                            <div
                              key={index}
                              draggable
                              onDragStart={(e) => handleDragStart(e, index)}
                              onDrop={(e) => handleDrop(e, index)}
                              onDragOver={handleDragOver}
                            >
                              <p>Image : {index + 1}</p>
                              <img
                                key={index}
                                src={image?.url ? image?.url : image}
                                alt={`preview-${index}`}
                              />
                              <button onClick={() => handleRemoveImage(index)}>
                                <MdDelete />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {allWords?.length > 0 && (
                    <div className="wat_images">
                      <label>
                        Upload WAT Text <p>(Add 60 word one by one)</p>
                      </label>
                      <div className="input_icon courseFeatures">
                        <input
                          className="input_text"
                          type="text"
                          placeholder="Add any word..."
                          name="word"
                          value={word}
                          onChange={(e) => setWord(e.target.value)}
                        />
                        <button title="Add More Word" onClick={handleAddWord}>
                          <FaPlus />
                        </button>
                      </div>
                      {allWords && allWords.length > 0 && (
                        <div className="word_list">
                          {allWords.map((ele, i) => (
                            <div
                              key={i}
                              draggable
                              onDragStart={(e) => handleDragWordStart(e, i)}
                              onDrop={(e) => handleWordDrop(e, i)}
                              onDragOver={handleWordDragOver}
                            >
                              <p>{ele.word}</p>
                              <span onClick={() => handleRemoveAdd(i)}>
                                <RxCross2 />
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {allSdQuestions?.length > 0 && (
                    <div className="sd_images">
                      <label>
                        Upload SDT <p>(Add 5 questions)</p>
                      </label>
                      <div className="input_icon courseFeatures">
                        <input
                          className="input_text"
                          type="text"
                          placeholder="Add any qustion..."
                          name="question"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                        />
                        <button
                          title="Add More Feature"
                          onClick={handleAddQuestion}
                        >
                          <FaPlus />
                        </button>
                      </div>
                      {allSdQuestions && allSdQuestions.length > 0 && (
                        <div className="question_list">
                          <ul>
                            {allSdQuestions.map((ele, i) => (
                              <li
                                key={i}
                                draggable
                                onDragStart={(e) =>
                                  handleDragStartQuestion(e, i)
                                }
                                onDrop={(e) => handleDropQuestion(e, i)}
                                onDragOver={handleDragOverQuestion}
                              >
                                <div>
                                  <p>{ele?.question}</p>
                                  <span onClick={() => handleRemoveQuestion(i)}>
                                    <RxCross2 />
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                  {situtationList?.length > 0 && (
                    <div className="srt_images">
                      <label>
                        Upload SRT <p>(Add 60 situations)</p>
                      </label>
                      <div className="input_icon courseFeatures">
                        <input
                          className="input_text"
                          type="text"
                          placeholder="Add situations ..."
                          name="situations"
                          value={situations}
                          onChange={(e) => setSituations(e.target.value)}
                        />
                        <button
                          title="Add More Situations"
                          onClick={handleAddsituations}
                        >
                          <FaPlus />
                        </button>
                      </div>
                      {situtationList && situtationList.length > 0 && (
                        <div className="question_list">
                          <ul>
                            {situtationList.map((ele, i) => (
                              <li
                                key={i}
                                draggable
                                onDragStart={(e) =>
                                  handleDragStartSituation(e, i)
                                }
                                onDrop={(e) => handleDropSituation(e, i)}
                                onDragOver={handleDragOverSituation}
                              >
                                <div>
                                  <p>{ele?.situations}</p>
                                  <span
                                    onClick={() => handleRemovesituations(i)}
                                  >
                                    <RxCross2 />
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="createBtn">
                    <CustomButton
                      title="Update"
                      onClick={handleSubmit}
                      // disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EditDossier;
