import React, { useState } from "react";
import "../styles/termCondition.scss";
import { Link, useNavigate, NavLink } from "react-router-dom";
// import { logo_main } from "../assets/images";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomHelmet from "../components/reusable/Helmet";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";
const FrenchiTerm = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Franchise Term and Condition Alpha Regiment"
        description="Discover the terms and conditions for becoming a franchise partner with Alpha Regiment. Start your journey to support defense education."
        author="Team Alpha Regiment"
        title="Franchise Partner Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>Franchisee terms</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
                            className="open_close_sidebar navClose_btn"
                            onClick={() => setShowSideNav(false)}
                        >
                            <RiCloseFill />
                        </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>FRANCHISEE TERMS</h1>
                <p>Last updated: March 1, 2024</p>
              </div>
              <div className="privacy_text">
                <p>
                  Welcome to Alpha Regiment, a dynamic platform for nurturing
                  future leaders. We appreciate your interest in becoming a
                  franchise partner with us. To ensure a mutually beneficial and
                  successful partnership, we have outlined the following terms
                  and conditions for our franchisee partners:
                </p>
                <h3 className="sub_heading">1. Eligibility Criteria</h3>
                <ul>
                  <li>
                    Only individuals who are Ex-servicemen or retired from the
                    Central Armed Police Forces (CAPFs) are eligible to apply
                    for an Alpha Regiment franchise
                  </li>
                </ul>
                <h3 className="sub_heading">2. Infrastructure Requirements</h3>
                <ul>
                  <li>
                    A minimum of 1 acre of land is required to set up an Alpha
                    Regiment franchise.
                  </li>
                  <li>
                    The campus must have 4 classrooms with proper ventilation
                    and sitting arrangements. Along with separate Hostels for
                    boys and girls.
                  </li>
                  <li>
                    There should be proper access to washrooms and a consistent
                    water supply.
                  </li>
                  <li>
                    The campus should have a mess and kitchen facility for
                    students.
                  </li>
                  <li>
                    There should be two office rooms for administrative
                    purposes.{" "}
                  </li>
                </ul>
                <h3 className="sub_heading">3. Security and Safety Measures</h3>
                <ul>
                  <li>
                    The campus must be enclosed by a proper fence or boundary
                    wall.
                  </li>
                  <li>
                    The franchisee partner must ensure the presence of first aid
                    kits and fire extinguishers for emergencies.
                  </li>
                </ul>
                <h3 className="sub_heading">4. Location Requirements</h3>
                <ul>
                  <li>
                    The franchise location must have access to a
                    government-approved ground, Panchayat ground, or a road with
                    minimal traffic during the early morning hours (5 to 6
                    a.m.).
                  </li>
                </ul>
                <h3 className="sub_heading">5. Female Warden</h3>
                <ul>
                  <li>
                    If the franchise campus caters to female candidates, there
                    should be a female warden appointed for their safety and
                    well-being.
                  </li>
                </ul>
                <h3 className="sub_heading">6. Legal Compliance</h3>
                <ul>
                  <li>
                    Franchise owners must not have any criminal cases against
                    them. Any discovery of such cases after the franchise
                    agreement may result in franchise cancellation and legal
                    action.
                  </li>
                </ul>
                <p>
                  By agreeing to these terms, you acknowledge that you have
                  read, understood, and accepted the requirements for becoming
                  an Alpha Regiment franchisee partner.
                </p>
                <p>
                  Thank you for considering Alpha Regiment as your partner in
                  the journey of shaping young minds and leaders of tomorrow.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default FrenchiTerm;
