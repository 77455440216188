import React, { useState } from "react";
import CustomHelmet from "../components/reusable/Helmet";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";

const PropertyPolicy = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Property Policy Alpha Regiment"
        description="Alpha Regiment property policies apply to all accommodation partners. Please read them before planning to collaborate with us as an accommodation partner."
        author="Team Alpha Regiment"
        title="Property Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>PROPERTY PRIVACY</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>Intellectual Property Policy</h1>
                <p>
                  This Intellectual Property Policy was last updated on February
                  10, 2024.
                </p>
              </div>
              <div className="privacy_text">
                <p>
                  Alpha Regiment operates as a technology platform facilitating
                  the creation and dissemination of educational content centered
                  around the Indian armed forces and Police. Our online learning
                  marketplace hosts a vast array of courses contributed by
                  individuals from diverse backgrounds. It's imperative to
                  clarify that Alpha Regiment adopts a marketplace model wherein
                  content is not subjected to pre-screening for legal
                  compliance. However, we uphold a staunch commitment to
                  respecting the intellectual property rights of all parties
                  involved.
                </p>
                <br />
                <br />
                <h3 className="sub_heading">
                  1. Copyright and Trademark Infringement
                </h3>
                <p>
                  Alpha Regiment maintains a zero-tolerance policy towards
                  infringing activity on our platform. In the event of receiving
                  copyright or trademark infringement notices from content or
                  trademark owners, we undertake prompt and appropriate action
                  to address such claims. This includes, but is not limited to,
                  the removal or restriction of access to the infringing
                  content, as per applicable laws and regulations.
                </p>
                <br />
                <br />
                <h3 className="sub_heading">2. Teacher Obligations</h3>
                <p>
                  Teachers contributing content to Alpha Regiment affirm that
                  they possess the requisite authorization or rights to utilize
                  the content they post. By submitting content, teachers
                  implicitly warrant that they are not infringing upon the
                  intellectual property rights of others. Any violation of this
                  obligation may result in the removal of the infringing content
                  and potential termination of the teacher's privileges on our
                  platform.
                </p>
                <br />
                <br />
                <h3 className="sub_heading">3. Third-Party Platforms</h3>
                <p>
                  In instances where Alpha Regiment teachers' courses are
                  unlawfully copied or distributed on third-party platforms
                  without their consent, we take proactive measures to address
                  such unauthorized use. This may involve issuing takedown
                  notices, pursuing legal recourse, and providing support to
                  affected teachers in safeguarding their intellectual property
                  rights.
                </p>
                <br />
                <br />
                <h3 className="sub_heading">4. Reporting Infringement</h3>
                <p>
                  Should you become aware of any incidents of trademark or
                  intellectual property infringement on our platform, we
                  encourage you to bring them to our attention. Please reach out
                  to us at{" "}
                  <a href="mailto:support@alpharegiment.com" className="email_link">
                    support@alpharegiment.com
                  </a>
                  , providing detailed information regarding the alleged
                  infringement. We take all reports seriously and will
                  investigate and take appropriate action as necessary.
                </p>
                <br />
                <br />
                <p>
                  Alpha Regiment remains steadfast in its commitment to
                  upholding intellectual property rights and fostering a
                  conducive environment for learning and innovation. By adhering
                  to these policies, all users contribute to the integrity and
                  legitimacy of our platform, ensuring a harmonious and
                  respectful ecosystem for educational content creation and
                  dissemination.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default PropertyPolicy;
