import styled from "styled-components";
// @import "./colors.scss";

export const CourseSection = styled.section`
  margin-top: 50px;
  .search_course {
    display: flex;
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
    .swiper {
      height: 500px;

      @media screen and (max-width: 575px) {
        height: auto;
      }
    }

    .swiper-slide {
      width: 500px;
      box-sizing: border-box;

      @media screen and (max-width: 575px) {
        width: fit-content;
        margin: 0 15px;
      }

      .myCard .content_card {
        background: linear-gradient(
          115.85deg,
          rgba(255, 255, 255, 0.4) -185.19%,
          rgba(255, 255, 255, 0) 129.07%
        );
        backdrop-filter: blur(14.175px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 10.8px;
      }
      .swiper-slide-shadow-right,
      .swiper-slide-shadow-left {
        background: transparent !important;
      }
    }
    .myInput {
      width: 50%;
      margin-bottom: 0;
      margin-right: 15px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
      > .input_icon > .icon,
      input {
        height: 40px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    button {
      height: 50px;
      margin-right: 15px;
      background-color: transparent;
      color: #f2f2f2;
      text-transform: capitalize;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #f2f2f2;
      padding: 0 35px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      border-radius: 4px;
      cursor: pointer;
      height: 40px;
      transition: 0.4s;

      &.active,
      &:hover {
        background-color: #f2f2f2;
        color: #081336;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .all_course_content {
    display: flex;
    position: relative;
    margin-top: 50px;
    > button.open_course_nav {
      display: none;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 9px;
        gap: 5px;
        position: absolute;
        width: 44px;
        height: 44px;
        left: 0px;
        top: -53px;
        border: none;
        cursor: pointer;
        color: #f2f2f2;
        /* background-color: #121d40; */
        background: linear-gradient(
          115.85deg,
          rgba(255, 255, 255, 0.9) -185.19%,
          rgba(255, 255, 255, 0.1) 129.07%
        );
        border-radius: 0 8px 8px 0;
      }
    }
    > .showSideNav {
      left: 0 !important;
    }
    > .course_nav {
      width: 250px;
      background-color: #121d40;
      border-radius: 0 16px 16px 0;
      margin-right: 15px;
      z-index: 1;
      height: fit-content;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        position: absolute;
        left: -250px;
        top: 0;
        margin-right: 0;
        transition: 0.4s;
      }
      > button {
        font-family: "Montserrat", sans-serif;
        display: block;
        padding: 14px 40px;
        gap: 10px;
        width: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: #f2f2f2;
        height: 80px;
        font-size: 14px;
        transition: 0.4s;
        border-bottom: 1px solid transparent;
        border-image: linear-gradient(
          0.25turn,
          #96abe3,
          #96abe3,
          rgba(56, 2, 155, 0)
        );
        border-image-slice: 1;

        @media screen and (max-width: 767px) {
          height: 60px;
          padding: 10px;
        }

        &.active {
          background: linear-gradient(
            116.1deg,
            rgba(255, 255, 255, 0.4) -106.73%,
            rgba(255, 255, 255, 0) 108.62%
          );
        }
        &:hover {
          background: linear-gradient(
            116.1deg,
            rgba(255, 255, 255, 0.4) -106.73%,
            rgba(255, 255, 255, 0) 108.62%
          );
        }

        > p {
          background-color: #081336;
          border-radius: 12px;
          padding: 3px;
          font-size: 12px;
          filter: blur 30px;
          display: inline;
          border:1px solid #f2f2f2;
        }
      }
    }
    > .all_course_cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% - 250px);
      height: fit-content;
      // justify-content: space-evenly;
      row-gap: 30px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
      @media screen and (max-width: 575px) {
        justify-content: space-between;
        margin: 0 15px;
      }
      .additional_details {
        display: none;
      }

      // COURSE CARD CSS HERE

      > .courseNotFound_main {
        width: 80%;
        padding: 30px 15px;
        background-color: #121d40;
        border-radius: 16px;

        @media screen and (max-width: 575px) {
          width: 100%;
          border-radius: 8px;
        }

        > h2 {
          text-align: center;
          font-family: "casanova", sans-serif;
          letter-spacing: 0.1rem;
        }
        > img {
          width: 250px;
          display: block;
          margin: 0 auto 30px;
        }

        > p {
          font-size: 16px;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-weight: 400;

          @media screen and (min-width: 767px) {
            width: 370px;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
`;
