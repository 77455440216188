import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import Select from "react-select";
import styled from "styled-components";

const Div = styled.div`
  .selectMulti__input {
    color: #124b04 !important;
  }
  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: flex;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #124b04;
    > .tooltip {
      margin-left: 5px;
      cursor: pointer;
      display: flex;
      > svg {
        color: #124b04;
        line-height: 2;
      }
      > .tooltiptext {
        display: none;
        background: #fff;
        color: #081336;
        border-radius: 5px;
        padding: 6px;
        box-sizing: border-box;
        max-width: 200px;
        font-size: 12px;
        position: absolute;
        z-index: 9;
        font-weight: 400;
        margin-left: 14px;
      }
      &:hover {
        .tooltiptext {
          display: unset;
        }
      }
    }
    > .impStar {
      color: red;
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }

  .selectMulti {
    &__control {
      background: linear-gradient(
        115.85deg,
        rgba(255, 255, 255, 0.4) -185.19%,
        rgba(255, 255, 255, 0) 129.07%
      );
      border: 1px solid ${(props) => props.borderColor};
      min-height: 50px;
      box-shadow: none;

      @media screen and (max-width: 575px) {
        min-height: 40px;
      }

      &:hover {
        border: 1px solid ${(props) => props.borderColor} !important;
        box-shadow: none;
      }
    }

    &__single-value {
      color: #124b04;
      font-size: 13px;
    }

    &__multi-value__remove {
      color: red;
    }

    &__clear-indicator {
      color: #124b04;

      &:hover {
        color: #d8d8d8;
      }
    }

    &__option {
      background-color: #081336;
      color: #124b04;
      font-size: 13px;

      &--is-focused {
        background-color: white;
        color: #081336;
        cursor: pointer;
        background: linear-gradient(
          115.85deg,
          rgba(255, 255, 255, 0.4) -185.19%,
          rgba(255, 255, 255, 0) 129.07%
        );
      }
    }
  }

  .selectMulti__menu {
    > div {
      margin: 0;
      padding: 0;
      border-radius: 5px;
      overflow: hidden;
    }
  }
`;

const CustomMultiSelect = ({
  label,
  value,
  onChange,
  defaultValue,
  isMulti,
  optionArray,
  disabled,
  borderColor,
  impStar,
  classStyle,
  tooltip,
}) => {
  return (
    <Div
      className="custom_multi_select"
      borderColor={borderColor ? borderColor : "transparent"}
    >
      {label && (
        <label>
          {label} {impStar && <abbr className="impStar">{impStar}</abbr>}
          {tooltip && (
            <div className="tooltip">
              <FaInfoCircle />
              <span className="tooltiptext">{tooltip}</span>
            </div>
          )}
        </label>
      )}
      <Select
        defaultValue={defaultValue}
        inputProps={{
          autoComplete: "off",
          autoCorrect: "off",
          autoCapitalize: "off",
          spellCheck: "false",
        }}
        isMulti={isMulti}
        name="colors"
        options={optionArray}
        className="multi-select"
        classNamePrefix="selectMulti"
        value={value}
        onChange={onChange}
        isDisabled={disabled}
      />
    </Div>
  );
};

export default CustomMultiSelect;
