import React, { useState } from "react";
import "../../styles/ProfileLayout.scss";
import { NavLink } from "react-router-dom";
import { defaultUser } from "../../assets/images";
import { useAuth } from "../../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import ImgLazyLoad from "./ImgLazyLoad";
import PopupPrompt from "./PopupPrompt";

const ProfileLayout = ({ children, sidebarLinks }) => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [auth] = useAuth();

  const [showModal, setShowModal] = useState(false);
  const handleShowNotification = () => {
    setShowModal(!showModal);
  };

  return (
    <section className="profile_layout_main">
      <button
        className="open_close_sidebar"
        onClick={() => setShowSideNav(!showSideNav)}
      >
        {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
      </button>
      <div
        className={`profile_sidebar ${
          showSideNav ? "showProfile_sidebar" : ""
        }`}
      >
        <button
          className="open_close_sidebar navClose_btn"
          onClick={() => setShowSideNav(false)}
        >
          <RiCloseFill />
        </button>
        <div className="sidebar_content">
          <div className="user_profileCard">
            <ImgLazyLoad
              src={
                auth?.user?.profile?.url
                  ? auth?.user?.profile?.url
                  : defaultUser
              }
              alt="alpha regiment user"
            />
            <h1>
              {auth?.user?.first_name} {/* {auth?.user?.middle_name}{" "} */}{" "}
              {auth?.user?.last_name && auth?.user?.last_name}
            </h1>
          </div>

          {sidebarLinks?.map((item) => {
            return (
              <div className="profile_sidebarLink" key={item.id}>
                {auth?.user?.isVerified ? (
                  <NavLink to={item.link}>{item.title}</NavLink>
                ) : (
                  <p onClick={() => handleShowNotification()}>{item?.title}</p>
                )}
                {/* <NavLink to={item.link}>{item.title}</NavLink> */}
              </div>
            );
          })}
        </div>
      </div>
      <div className="profile_content">{children}</div>
      {showModal && (
        <PopupPrompt
          cancelBtnTxt="Ok"
          title="Your profile is not yet fully active as we are manually verifying all the details you have provided. Thank you for your patience and for being a part of the Alpha Regiment family. We will notify you via email and phone once your profile is fully active. This process may take a few days."
          cancelOnclick={() => setShowModal(false)}
        />
      )}
    </section>
  );
};

export default ProfileLayout;
