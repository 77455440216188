import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IoLogoWhatsapp } from "react-icons/io";

const FloatingIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 12;

  > a {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    border-radius: 100%;
    color: #25d366;
    background-color: #fff;
    transition: 0.4s;

    &:hover {
      color: #f2f2f2;
      background-color: #121d40;
    }
  }

  @media screen and (max-width: 575px) {
    bottom: 90px;
    right: 15px;

    > a {
      width: 40px;
      height: 40px;
      font-size: 25px;
    }
  }
`;

const WhatsappFloating = () => {
  return (
    <FloatingIcon>
      <Link
        to={`https://wa.me/+91${process.env.REACT_APP_MOBILE_NUMBER}`}
        target="_blank"
        rel="nofollow noopener"
        title="Whatsapp"
      >
        <IoLogoWhatsapp />
      </Link>
    </FloatingIcon>
  );
};

export default WhatsappFloating;
