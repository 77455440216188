import React, { Fragment, useEffect, useState } from "react";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import OfficerLayout from "./OfficerLayout";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/reusable/CustomInput";
import CustomDateInput from "../../components/reusable/CustomDateInput";
import { useNotification } from "../../context/notificationContext";
import {
  getOfficerSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import { useDispatch } from "react-redux";
import CustomMultiSelect from "../../components/reusable/CustomMultiSelect";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import "../../styles/createLiveClass.scss";
import CustomButton from "../../components/reusable/CustomButton";
import {
  clearLiveClassErrors,
  clearLiveClassState,
  createLiveClass,
} from "../../redux/actions/liveClassAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
// import "../../styled"

const LiveClassCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [courseId, setCourseId] = useState();
  const [liveClassData, setLiveClassData] = useState({
    title: "",
    subject: "",
    description: "",
    startTimeString: "",
    duration: "",
    course: "",
  });
  //   HANDLE INPUT CHANGE
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setLiveClassData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // VALIDATE THE DATE INPUT
  const getCurrentDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const {
    loading,
    error,
    courses: data,
  } = useSelector((state) => state.officerSsbCourses);

  let optionArray = [];

  for (let i = 0; i < data?.courses?.length; i++) {
    let optionData = {
      value: data?.courses[i]?._id,
      label: data?.courses[i]?.title,
    };
    optionArray.push(optionData);
  }

  useEffect(() => {
    dispatch(getOfficerSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(ssbClearErrors());
    }
  }, [dispatch, error, showNotification]);

  const { title, subject, startTimeString, description, duration } =
    liveClassData;

  const handleSubmit = () => {
    dispatch(
      createLiveClass(
        title,
        subject,
        startTimeString,
        description,
        parseInt(duration),
        courseId?.value
      )
    );
  };

  const {
    loading: createLoading,
    error: createError,
    classData,
  } = useSelector((state) => state.liveClassCreate);

  useEffect(() => {
    if (createError) {
      showNotification("error", createError);
      dispatch(clearLiveClassErrors());
    }
    if (classData?.success) {
      showNotification("success", classData?.message);
      navigate("/officer/live-classes");
      dispatch(clearLiveClassState());
    }
  }, [
    dispatch,
    showNotification,
    classData?.message,
    classData?.success,
    createError,
    navigate
  ]);

  return (
    <Fragment>
      <OfficerLayout>
        <ProfileLayout sidebarLinks={officerSidebarLink}>
          <div className="live_class_schedule">
            <div className="title">
              <h2>Create Class</h2>
              <button
                title="Add New Class"
                onClick={() => navigate("/officer/live-classes")}
              >
                <RxCross2 />
              </button>
            </div>
            <div className="create_class_form">
              <div className="child_form">
                <CustomInput
                  label="Title"
                  type="text"
                  placeholder="etc..."
                  name="title"
                  impStar="*"
                  onChange={handleInputChange}
                  value={liveClassData.title}
                />
                <CustomInput
                  label="Subject"
                  type="text"
                  placeholder="Ex:-.."
                  name="subject"
                  impStar="*"
                  onChange={handleInputChange}
                  value={liveClassData.subject}
                />
                <CustomDateInput
                  label="Start Time"
                  type="datetime-local"
                  placeholder="Date"
                  name="startTimeString"
                  impStar=""
                  onChange={handleInputChange}
                  value={liveClassData.startTimeString}
                  disabledDate={getCurrentDate}
                />
                <CustomInput
                  label="Duration (In minutes)"
                  type="number"
                  placeholder="60"
                  name="duration"
                  impStar="*"
                  onChange={handleInputChange}
                  value={liveClassData.duration}
                />
                <CustomMultiSelect
                  label="Course for class"
                  value={courseId}
                  defaultValue={courseId}
                  onChange={(val) => setCourseId(val)}
                  isMulti={false}
                  optionArray={optionArray}
                />
              </div>
              <CustomTextarea
                label="Class Description"
                type="text"
                onChange={handleInputChange}
                placeholder="Upto 500 words..."
                name="description"
                value={liveClassData.description}
                fullWidth
              />
              <div className="submit_btn">
                <CustomButton
                  title={createLoading ? <ButtonLoader /> : "Create Class"}
                  onClick={() => handleSubmit()}
                  width="130px"
                />
              </div>
            </div>
          </div>
        </ProfileLayout>
      </OfficerLayout>
    </Fragment>
  );
};

export default LiveClassCreate;
