import {
  CLEAR_LIVE_CLASS_ERROR,
  CLEAR_LIVE_CLASS_STATE,
  CLEAR_START_CLASS_STATE,
  CREATE_LIVE_CLASS_FAIL,
  CREATE_LIVE_CLASS_REQUEST,
  CREATE_LIVE_CLASS_SUCCESS,
  GET_ALL_LIVE_LIST_FAIL,
  GET_ALL_LIVE_LIST_REQUEST,
  GET_ALL_LIVE_LIST_SUCCESS,
  START_LIVE_CLASS_FAIL,
  START_LIVE_CLASS_REQUEST,
  START_LIVE_CLASS_SUCCESS,
} from "../constants/liveClassConstant";

// CREATE LIVE CLASS REDUCER
export const liveClassCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_LIVE_CLASS_REQUEST:
      return {
        loading: true,
        classData: {},
      };
    case CREATE_LIVE_CLASS_SUCCESS:
      return {
        loading: false,
        classData: action.payload,
      };
    case CREATE_LIVE_CLASS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_LIVE_CLASS_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_LIVE_CLASS_STATE:
      return {
        loading: false,
        classData: {},
      };

    default:
      return state;
  }
};

// START LIVE CLASS  REDUCER
export const liveClassStartReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LIVE_CLASS_REQUEST:
      return {
        loading: true,
        liveClass: {},
      };
    case START_LIVE_CLASS_SUCCESS:
      return {
        loading: false,
        liveClass: action.payload,
      };
    case START_LIVE_CLASS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_LIVE_CLASS_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_START_CLASS_STATE:
      return {
        loading: false,
        liveClass: {},
      };

    default:
      return state;
  }
};

// GET ALL LIVE CLASSES REDUCER
export const getLiveClassListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_LIVE_LIST_REQUEST:
      return {
        loading: true,
        classList: {},
      };
    case GET_ALL_LIVE_LIST_SUCCESS:
      return {
        loading: false,
        classList: action.payload,
      };
    case GET_ALL_LIVE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_LIVE_CLASS_ERROR:
      return {
        classList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
