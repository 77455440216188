import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_PAYMENT_ERRORS,
  PAYMENT_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  CLEAR_PAYMENT_STATE,
  PAYMENT_DONE_FAIL,
  PAYMENT_DONE_REQUEST,
  PAYMENT_DONE_SUCCESS,
  GET_ALL_PAYMENT_REQUEST,
  GET_ALL_PAYMENT_SUCCESS,
  GET_ALL_PAYMENT_FAIL,
  GET_PAYMENT_DETAILS_REQUEST,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS_FAIL,
  UPDATE_PAYMENT_DONE_REQUEST,
  UPDATE_PAYMENT_DONE_SUCCESS,
  UPDATE_PAYMENT_DONE_FAIL,
} from "../constants/instaMojoConstant";

export const createPayment =
  (amount, for_course, ssbCourse, couponCode, emiId, seatBooking) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PAYMENT_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/payments/checkout",
        { amount, for_course, ssbCourse, couponCode, emiId, seatBooking },
        config
      );

      dispatch({
        type: PAYMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const userPaymentData =
  (
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature,
    for_course,
    couponCode,
    emiId,
    seatBooking,
    ssbCourse
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PAYMENT_DONE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/payment-request-done",
        {
          razorpay_payment_id,
          razorpay_order_id,
          razorpay_signature,
          for_course,
          couponCode,
          emiId,
          seatBooking,
          ssbCourse,
        },
        config
      );

      dispatch({
        type: PAYMENT_DONE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_DONE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const updateUserPayment = (payment_id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PAYMENT_DONE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      "/update-payment-done",
      { payment_id },
      config
    );

    dispatch({
      type: UPDATE_PAYMENT_DONE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PAYMENT_DONE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllPayments = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PAYMENT_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/payments/created-payments", config);

    dispatch({
      type: GET_ALL_PAYMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PAYMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getSelfPaymentDetails =
  (payment_req_id, payment_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PAYMENT_DETAILS_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/payment-status",
        { payment_req_id, payment_id },
        config
      );

      dispatch({
        type: GET_PAYMENT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PAYMENT_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR PAYMENT ERRORS
export const clearPaymentErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PAYMENT_ERRORS });
};

// CLEAR PAYMENT ERRORS
export const clearPaymentState = () => async (dispatch) => {
  dispatch({ type: CLEAR_PAYMENT_STATE });
};
