import { Odossier } from "../../../assets/images";

export const teacherResources = [
  {
    value: 1,
    label: "MCQ",
    category: "mcq",
    image: Odossier,
    link: "/teacher/mcq",
    tooltip: "Create mcq and save them for later use.",
  },
];
