import React, { useState } from "react";
import "../styles/termCondition.scss";
import { Link, useNavigate, NavLink } from "react-router-dom";
// import { logo_main } from "../assets/images";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomHelmet from "../components/reusable/Helmet";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";

const TermCondition = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Term and Condition Alpha Regiment"
        description="At Alpha Regiment, we value transparency as it builds trust. Please read all terms and conditions before use to fully understand our policies."
        author="Team Alpha Regiment"
        title="Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
          <div className="top_bar">
            <div className="go_back">
              <Link onClick={() => navigate(-1)}>
                <AiOutlineArrowLeft />
              </Link>
              <p>terms & condition</p>
            </div>
            <img src={logo} alt="logo" />
          </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${showSideNav ? "showProfile_sidebar" : ""
              }`}
          >
            {/* <button
              className="open_close_sidebar navClose_btn"
              onClick={() => setShowSideNav(false)}
            >
              <RiCloseFill />
            </button> */}
            <div className="sidebar_content">

              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>Term & Conditions</h1>
                <p>Last updated: April 29, 2023</p>
              </div>
              <div className="privacy_text">
                <p>
                  This Terms and Conditions pertains to all Users who access the
                  Platform and are therefore required to read and comprehend the
                  Policy before sharing any Personal Information (as defined
                  hereinafter). The policy governs your use of{" "}
                  <b>'AlphaRegiment'</b> application, <b>AlphaRegiment</b> YouTube Channel, website (<Link to="/" target="_blank">
                    https://alphaRegiment.in
                  </Link>){" "}, and other associated applications, products,
                  websites, and services managed by the Company (Arcaptor Technologies Private Limited). By submitting
                  Personal Information, you consent to the use and processing of
                  such information in accordance with this Policy. Third-party
                  websites may place their own cookies or other files on Users'
                  computers, collect data or request personal information from
                  Users, for which <b>AlphaRegiment</b> is not responsible or
                  liable. Thus, <b>AlphaRegiment</b> does not make any
                  representations regarding the privacy practices or policies of
                  such third parties or the terms of use of such websites, nor does
                  it guarantee the accuracy, integrity, or quality of the
                  information, data, text, software, sound, photographs, graphics,
                  videos, messages, or other materials available on such
                  websites/applications. The inclusion or exclusion of a website
                  does not imply any endorsement by <b>AlphaRegiment</b> of the website, its provider, or the
                  information on the website/application. <b>AlphaRegiment</b>{" "}
                  encourages Users to read the privacy policies of each such
                  website/application and acknowledges that it is solely the
                  responsibility of such a third party to the User in this regard.{" "}
                  <b>AlphaRegiment</b> has taken reasonable precautions in
                  compliance with applicable Indian law and industry standards to
                  treat Personal Information as confidential and protect it from
                  unauthorized access, improper use or disclosure, modification, and
                  unlawful destruction or accidental loss of Personal Information.
                  We will not use or share your information with anyone except as
                  described in this Policy. Each time you use our Service, you agree
                  to the practices described in this Policy at that time.
                </p>

                <h2 className="sub_heading">USAGE AND RETENTION OF INFORMATION</h2>
                <p>
                  This passage outlines the information that is collected when a
                  user registers on the Application or Website/YouTube
                  Channel/Services/products. The information collected includes the
                  user's name, age, email address, location, phone number, password,
                  educational interests, transaction-related information, and
                  information provided when the user contacts the company for help.
                  This information can be categorized as "Personal Information,"
                  "Sensitive Personal Information," and "Associated Information."{" "}
                </p>
                <p>
                  The company may use this information to contact users, provide
                  services, send important information and marketing promotions, and
                  to detect and prevent fraudulent or illegal activities. The
                  company also uses this information to provide a personalized
                  experience for users, including customized study material and
                  recommendations. Additionally, the company may use the information
                  to determine the user's location, Internet service provider, and
                  to respond to inquiries and requests quickly and efficiently.
                </p>
                <p>
                  Finally, the company may communicate with users about its services
                  using email, push notifications, text messaging, and online
                  messaging channels. This includes sending users details about new
                  features and content available on the Website/Application, special
                  offers, promotional announcements, and surveys. The information
                  collected will be retained as long as necessary to provide the
                  requested services, and in accordance with applicable laws and
                  regulations.
                </p>

                <h2 className="sub_heading">СOOKIES </h2>
                <p>
                  We use cookies to uniquely identify your browser by sending small
                  files containing a string of characters to your computer. Cookies
                  assist in tracking your preferences, allowing you to log in
                  faster, and are aggregated to determine user trends. This data is
                  used to improve our offerings, such as providing more content in
                  areas of greater interest to a majority of users. While most
                  browsers are initially set up to accept cookies, you have the
                  option to reset your browser to refuse all cookies or to indicate
                  when a cookie is being sent. However, please note that some of our
                  features and services may not function properly if your cookies
                  are disabled.
                </p>

                <h2 className="sub_heading">
                  SHARING AND DISCLOSING PERSONAL INFORMATION
                </h2>
                <p>
                  We enlist the help of other companies, agents, or contractors
                  (collectively known as "Service Providers") to assist us in
                  delivering our services to you or to perform services on our
                  behalf. These Service Providers provide a range of services
                  including marketing, advertising, communications, infrastructure,
                  IT services, personalization and optimization of our services,
                  processing credit card transactions and other payment methods,
                  customer service, debt collection, data analysis, and consumer
                  surveys. While providing these services, these Service Providers
                  may have access to your personal or other information. However, we
                  do not permit them to use or disclose your personal information
                  except for the purpose of providing their services. We use your
                  personal information to ensure that our services are presented to
                  you in the most effective manner, to secure and improve the
                  Platform, to fulfill our obligations to you, and to communicate
                  with you via calls, texts, or emails for transactional, service,
                  or promotional purposes. If you do not wish to receive any
                  communication from us, you can opt out by contacting us.
                </p>

                <h2 className="sub_heading">INFORMATION SECURITY </h2>
                <p>
                  We highly value your privacy and do not sell, rent, or transfer
                  your personal information to third parties for marketing purposes
                  without your explicit consent. Your information is considered one
                  of our most important assets and we take the necessary measures to
                  ensure its protection through physical and technological security
                  devices. We also utilize third parties to verify and certify our
                  privacy principles. If you do not agree to the transfer or use of
                  your information in this manner, please refrain from using our
                  Website/Application. We do not share your personal information
                  that we have collected with any other company for marketing
                  purposes without your consent. However, we reserve the right to
                  disclose your personal information to any third party that makes a
                  legally-compliant request for its disclosure.
                </p>

                <h2 className="sub_heading">РUBLIC FORUMS</h2>
                <p>
                  By using certain features on our Website/Application/YouTube
                  Channel, such as discussion forums, any personal information that
                  you post or share, such as comments, messages, files, and photos,
                  will be available to all users and will become part of the public
                  domain. We advise you to use caution when sharing such
                  information, as it is done at your own risk. Please note that if
                  you disclose personal information in your profile or when posting
                  on our forums, this information may become publicly available.
                </p>

                <h2 className="sub_heading">CONSULTING</h2>
                <p>
                  To offer You our services, we engage third-party partners who
                  assist us with various tasks such as fulfilling orders, processing
                  payments, tracking site activity, conducting surveys, managing our
                  database, sending emails, and analyzing website performance
                  metrics in aggregate and for a limited number of recipients.{" "}
                </p>

                <h2 className="sub_heading">USER CUSTOMS </h2>
                <p>
                  You have the ability to control how we use your personal
                  information to communicate with you, including whether you receive
                  marketing materials, how we provide customized advertising, and
                  whether you stay signed in to your account. If you no longer want
                  to receive marketing communications from us, you can unsubscribe
                  using the link in the email or modify your communication
                  preferences accordingly. Additionally, you may email us to request
                  that we stop sending promotional content. Please note that we will
                  not sell or rent your personal information to third parties for
                  their marketing purposes without your explicit consent.{" "}
                </p>

                <h2 className="sub_heading">OWNERSHIP OF RIGHTS </h2>
                <p>
                  All rights, including copyright, pertaining to this
                  Website/Application, are either owned by us or licensed to us.
                  Without our permission, any use of this Website/Application or its
                  contents, including storing or copying it in part or in whole, is
                  strictly prohibited, except for your personal, non-commercial use.
                  You are prohibited from using any content available on or through
                  our Website/Application for commercial or public purposes,
                  including modifying, copying, distributing, transmitting,
                  displaying, printing, publishing, selling, licensing, creating
                  derivative works, or re-posting. By downloading copyrighted
                  material, you acknowledge that you do not acquire ownership
                  rights. The trademarks found on our Website/Application or on any
                  other Website/Application owned or operated in conjunction with
                  <b>AlphaRegiment</b>, are exclusive property of{" "}
                  <b>AlphaRegiment</b>, unless the trademark owner has licensed the
                  site to us. Any such license is for the exclusive benefit and use
                  of <b>AlphaRegiment</b>, unless otherwise indicated.{" "}
                </p>

                <h2 className="sub_heading">CONSENT </h2>
                <p>
                  This passage describes the privacy policy and terms and conditions
                  of a YouTube channel/ services/ products/ website/ application.
                  The policy states that users must be able to provide informed
                  consent before providing any personal information required for the
                  use of the platform. For minors, it is assumed that their
                  guardians/ parents have provided consent for the retention of
                  their data. By registering with the platform, users are consenting
                  to the collection, processing, storing, disclosing, and handling
                  of their information as described in the policy. The company may
                  process users' information in various ways, including collecting,
                  storing, deleting, using, combining, sharing, transferring, and
                  disclosing information, all of which will take place in India. If
                  a user resides outside India, their information will be processed
                  and stored in accordance with the applicable data protection laws
                  of India.{" "}
                </p>
                <p>
                  By submitting content on the platform, users grant the company a
                  worldwide, non-exclusive, royalty-free license to use, copy,
                  reproduce, process, adapt, modify, publish, transmit, display, and
                  distribute such material in any and all media or distribution
                  methods. Other users may also use the material in the same way as
                  any other content available through the platform. If a user
                  deletes their account, their material and name may still remain
                  available through the platform.
                </p>

                <h2 className="sub_heading">EXCLUSIVE SERVICE </h2>
                <p>
                  By using our website/application or any of our services, you agree
                  to indemnify, defend and hold harmless <b>AlphaRegiment</b> and
                  its officers, directors, employees, agents, licensors, suppliers
                  and any third-party information providers from and against all
                  losses, expenses, damages and costs, including reasonable
                  attorneys' fees, resulting from any violation of these Terms and
                  Conditions or any activity related to your account (including
                  negligent or wrongful conduct) by you or any other person
                  accessing the site using your account.{" "}
                </p>

                <h2 className="sub_heading">SECURITY </h2>
                <p>
                  The user acknowledges that the company is not responsible for the
                  content on the Website, Application, or YouTube Channel as it is
                  not the manufacturer of said content. Therefore, the company will
                  not be held liable for any repercussions resulting from the
                  content.
                </p>
                <p>
                  Under no circumstances shall the company, its officers, directors,
                  employees, partners, or agents be liable to the user or any third
                  party for any special, incidental, indirect, consequential, or
                  punitive damages whatsoever, including but not limited to loss of
                  use, data, or profits, or any other claim arising out of or in
                  connection with the user's use of or access to the Website,
                  Application, or YouTube Channel.
                </p>
                <p>
                  In the event of the user's breach of these Terms, they acknowledge
                  that the company may be irreparably harmed and may not have an
                  adequate remedy in money or damages. Therefore, the company shall
                  be entitled to obtain an injunction against such a breach from any
                  court of competent jurisdiction, and such relief shall not limit
                  its right to obtain other remedies. Any violation by the user of
                  the terms of this clause may result in immediate suspension or
                  termination of their accounts apart from any legal remedy that the
                  company can avail. In such instances, the company may also
                  disclose the user's account information if required by any
                  governmental or legal authority. The user understands that the
                  violation of these Terms could also result in civil or criminal
                  liability under applicable laws.
                </p>

                <h2 className="sub_heading">Refund Policy</h2>
                <p>
                  Please note that all purchases of our products and services are
                  non-refundable. This includes online batches and services. If you
                  accidentally purchase the wrong batch, you may request to change
                  it to another batch of equal value within 10 days of the purchase.
                  We strongly recommend that you carefully review the complete
                  system before making a payment for books. In the event that a
                  product is damaged or lost during shipping, we will provide a
                  replacement. However, please note that we cannot offer refunds for
                  purchases where the wrong address is provided or where the books
                  are not accepted by the student.{" "}
                </p>

                <h2 className="sub_heading">
                  JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION
                </h2>
                <p>
                  These Terms shall be governed by and construed and enforced in
                  accordance with the laws of India. Subject to other provisions in
                  this Clause, courts in Haryana and / or Rajasthan shall have
                  exclusive jurisdiction over all issues arising out of these Terms
                  or the use of the Services.
                </p>
                <p>
                  Any controversies, conflicts, disputes, or differences arising out
                  of these Terms shall be resolved by arbitration in Haryana and /
                  or Rajasthan in accordance with the Arbitration and Conciliation
                  Act, 1996 for the time being in force, which is deemed to be
                  incorporated by reference in this Clause. The tribunal shall
                  consist of 1 (one) arbitrator appointed by the Company. The
                  language of the arbitration shall be English.
                </p>
                <p>
                  The parties to the arbitration shall keep the arbitration
                  confidential and not disclose to any person, other than on a need
                  to basis or to legal advisors, unless required to do so by law.
                  The decision of the arbitrator shall be final and binding on all
                  the Parties hereto.
                </p>
                <p>
                  Each party to the arbitration shall bear its own costs with
                  respect to any dispute.
                </p>

                <h2 className="sub_heading">ACCOUNT DELETION </h2>
                <p>
                  To request the deletion of your account, please send an email to
                  our customer care service at{" "}
                  <Link to="mailto:support@alpharegiment.com">
                    support@alpharegiment.com
                  </Link>
                  . Once we receive your request, our team will reach out to you and
                  ask for your confirmation to proceed with the account deletion
                  process. Please note that we will delete your account within 96
                  hours after receiving your confirmation.{" "}
                </p>

                <h2 className="sub_heading">CONTACT US: </h2>
                <p>
                  If you have any inquiries or concerns regarding our Privacy
                  Policy, you can reach us through the following methods:
                </p>
                <ul>
                  <li>
                    By visiting our Website/Application and accessing the contact
                    page at{" "}
                    <Link to="/" target="_blank">
                      https://alphaRegiment.in
                    </Link>
                  </li>
                  <li>
                    By sending us an email at{" "}
                    <Link to="mailto:support@alphaRegiment.com">
                      support@alphaRegiment.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default TermCondition;
