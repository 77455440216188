  import React, { useState } from "react";
import "../../styles/header.scss";
// import { CiGlobe } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useLocale } from "../../context/locale";
import { useAuth } from "../../context/userContext";
import { defaultUser, logo_main, logo_mobile } from "../../assets/images";
import { logout } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../reusable/CustomButton";
import LangChangeModal from "./LangChangeModal";
import { FaShoppingCart } from "react-icons/fa";

const Header = () => {
  const [openToggler, setOpenToggler] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);
  const [langModalOpener, setLangModalOpener] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth(); // GET USER DETAILS FROM USER CONTEXT

  const [, changeLang] = useLocale(); // FOR CHANGING LANGUAGE FROM CONTEXT

  // FOR REGISTER PAGE CHANGE IN ROUTING
  const handleSignUpPage = () => {
    navigate("/register");
  };

  // FOR LOGIN PAGE CHANGE IN ROUTING
  const handleSignInPage = () => {
    navigate("/login");
  };

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  // LANGUAGE CHANGE HANDLER WITH CLOSE THE SIDE BAR IN MOBILE VIEW
  const changeLanguageHandler = (langParam) => {
    changeLang(langParam);
    setOpenToggler(false);
  };

  const localStorageLang = localStorage.getItem("lang");

  // const onLangChange = (e) => {
  //   console.log(e.target.value);
  // };

  // console.log(auth.user);

  // GET CART ITEMS USING CART REDUCER
  const { cartItems } = useSelector((state) => state.cart);
  return (
    <div className="header_main">
      <div className="container">
        <div className="nav_bar_sec">
          <div className="logo">
            <Link to="/">
              <img
                className="desktop"
                src={logo_main}
                alt="Alpha Regiment desktop_logo"
              />
              <img
                className="mobile"
                src={logo_mobile}
                alt="Alpha Regiment mobile_logo"
              />
            </Link>
          </div>
          <div className="open_navbar_btn">
            {/* <div style={{ marginRight: "15px" }}>
              <button
                className="change_lang"
                onClick={() => setLangModalOpener(true)}
                title="Change Default Language"
              >
                <CiGlobe />
              </button>
            </div> */}
            {auth?.user?.role === "11" && (
              <div
                className="cart_btn"
                onClick={() => navigate("/student/cart")}
              >
                <FaShoppingCart />
                <p>{cartItems?.length}</p>
              </div>
            )}
            <GiHamburgerMenu onClick={() => setOpenToggler(!openToggler)} />
          </div>
          <div className={`nav_links ${openToggler ? "open_navbar" : ""}`}>
            <div className="close_menu_btn">
              <AiOutlineClose onClick={() => setOpenToggler(!openToggler)} />
            </div>
            <div className="nav_link desktop">
              <Link to="/blogs">Blogs</Link>
            </div>
            {auth?.user?._id ? (
              <div className="user_details_nav_link_mobile">
                <div className="userDetails">
                  <div className="profileImg">
                    <img
                      src={
                        auth?.user?.profile?.url
                          ? auth?.user?.profile?.url
                          : defaultUser
                      }
                      alt={auth?.user?.first_name}
                    />
                  </div>
                  <div className="userName">
                    {auth?.user?.first_name?.slice(0, 10)}
                  </div>
                </div>
                <div className="auth_user_links">
                  <Link
                    to={
                      auth?.user?.role === "15"
                        ? "/affiliate/profile"
                        : auth?.user?.role === "16" ||
                          auth?.user?.role === "17" ||
                          auth?.user?.role === "18"
                        ? "/officer/profile"
                        : auth?.user?.role === "13"
                        ? "/teacher/profile"
                        : auth?.user?.role === "12"
                        ? "/ar-teacher/profile"
                        : auth?.user?.role === "19"
                        ? "/doctor/profile"
                        : "/student/profile"
                    }
                  >
                    Profile
                  </Link>
                  <CustomButton title="Log Out" onClick={handleLogout} />
                </div>
              </div>
            ) : (
              <img
                className="sideNavdesktopImg"
                src={logo_main}
                alt="Alpha Regiment desktop_logo"
              />
            )}
            {/* <div className="nav_link lang_btn_desktop">
              <button
                className="change_lang"
                onClick={() => setLangModalOpener(true)}
                title="Change Default Language"
              >
                <CiGlobe />
              </button>
            </div> */}
            {auth?.user?.role === "11" && (
              <div
                className="cart_btn"
                onClick={() => navigate("/student/cart")}
              >
                <FaShoppingCart />
                <p>{cartItems?.length}</p>
              </div>
            )}

            <div className="nav_link dropdown_nav">
              {auth?.user?._id ? (
                <div className="auth_profile_dropdown">
                  <button
                    className={`sign_up_btn dashboard_btn ${
                      auth?.user?.first_name ? "profile_header_btn" : ""
                    }`}
                    onClick={() => setUserDropdown(!userDropdown)}
                  >
                    <img
                      src={
                        auth?.user?.profile?.url
                          ? auth?.user?.profile?.url
                          : defaultUser
                      }
                      alt={auth?.user?.first_name}
                    />
                    {auth?.user?.first_name?.slice(0, 10)}
                    {userDropdown ? (
                      <BsFillCaretUpFill />
                    ) : (
                      <BsFillCaretDownFill />
                    )}
                  </button>
                  <div
                    className={`nav_dropdown_links ${
                      userDropdown ? "show_nav_dropdown" : ""
                    }`}
                  >
                    <Link
                      to={
                        auth?.user?.role === "15"
                          ? "/affiliate/profile"
                          : auth?.user?.role === "16" ||
                            auth?.user?.role === "17" ||
                            auth?.user?.role === "18"
                          ? "/officer/profile"
                          : auth?.user?.role === "13"
                          ? "/teacher/profile"
                          : auth?.user?.role === "12"
                          ? "/ar-teacher/profile"
                          : auth?.user?.role === "19"
                          ? "/doctor/profile"
                          : "/student/profile"
                      }
                    >
                      Profile
                    </Link>
                    <button className="logout_btn" onClick={handleLogout}>
                      logout
                    </button>
                  </div>
                </div>
              ) : (
                <div className="headerSign_btns">
                  <button className="sign_up_btn" onClick={handleSignInPage}>
                    Sign In
                  </button>
                  <button className="sign_up_btn" onClick={handleSignUpPage}>
                    Register
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* LANGUAGE MODAL START */}
      {langModalOpener && (
        <LangChangeModal
          setLangModalOpener={setLangModalOpener}
          langChanger={changeLanguageHandler}
          localStorageLang={localStorageLang}
        />
      )}
      {/* LANGUAGE MODAL ENDS */}
    </div>
  );
};

export default Header;
