import { axiosInstance } from "../../utils/axiosConfig";
import {
  ALL_COURSES_FAIL,
  ALL_COURSES_REQUEST,
  ALL_COURSES_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
  COURSE_ADD_REVIEW_FAIL,
  COURSE_ADD_REVIEW_REQUEST,
  COURSE_ADD_REVIEW_SUCCESS,
  COURSE_DELETE_REVIEW_FAIL,
  COURSE_DELETE_REVIEW_REQUEST,
  COURSE_DELETE_REVIEW_SUCCESS,
  COURSE_DETAIL_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS,
  COURSE_LONG_DETAIL_FAIL,
  COURSE_LONG_DETAIL_REQUEST,
  COURSE_LONG_DETAIL_SUCCESS,
  COURSE_META_DETAIL_FAIL,
  COURSE_META_DETAIL_REQUEST,
  COURSE_META_DETAIL_SUCCESS,
  MY_COURSES_FAIL,
  MY_COURSES_REQUEST,
  MY_COURSES_SUCCESS,
  RATING_CLEAR_DELETE_STATE,
  RATING_CLEAR_ERRORS,
  RATING_CLEAR_STATE,
} from "../constants/courseConstant";

// GET ALL COURSES
export const getAllCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_COURSES_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/courses", config);

    dispatch({
      type: ALL_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_COURSES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET MY COURSES
export const getMyCourses = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MY_COURSES_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-course/${id}`, config);

    dispatch({
      type: MY_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MY_COURSES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL COURSES
export const getCourseDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_DETAIL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/course/${id}`, config);

    dispatch({
      type: COURSE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL COURSES
export const getCourseLongDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LONG_DETAIL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/get-course-long-details/${id}`,
      config
    );

    dispatch({
      type: COURSE_LONG_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_LONG_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL COURSES
export const getCourseMetaDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_META_DETAIL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/get-course-meta-details/${id}`,
      config
    );

    dispatch({
      type: COURSE_META_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_META_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// SSB COURSE RATING ADD
export const courseAddRatingAction =
  (courseId, rating, comment) => async (dispatch) => {
    try {
      dispatch({
        type: COURSE_ADD_REVIEW_REQUEST,
      });
      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/course/review/${courseId}`,
        {
          rating,
          comment,
        },
        config
      );
      dispatch({
        type: COURSE_ADD_REVIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_ADD_REVIEW_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// SSB COURSE RATING DELETE
export const deleteCourseRatingAction = (courseId) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_DELETE_REVIEW_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/course/delete-review/${courseId}`,
      config
    );
    dispatch({
      type: COURSE_DELETE_REVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR RATING ERROR ACTION FUNCTION
export const ClearRatingErrors = () => async (dispatch) => {
  dispatch({ type: RATING_CLEAR_ERRORS });
};

// CLEAR RATING DELETE STATE ACTION FUNCTION
export const ClearRatingDeleteState = () => async (dispatch) => {
  dispatch({ type: RATING_CLEAR_DELETE_STATE });
};

// CLEAR RATING ERROR ACTION FUNCTION
export const ClearRatingState = () => async (dispatch) => {
  dispatch({ type: RATING_CLEAR_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
