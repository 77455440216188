import React from "react";
import styled from "styled-components";
import ImgLazyLoad from "./ImgLazyLoad";

const MyCard = styled.div`
  padding: 15px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #121d40;
  > .card_title {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #f2f2f2;
  }
  > .card_img {
    width: 100%;
    height: 120px;
    margin: 20px auto;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }
  }
  > p.card_desc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
  }
`;

const CustomCard = ({ img, title, description }) => {
  return (
    <MyCard className="myCard">
      <h2 className="card_title casanova_font">{title}</h2>
      <div className="card_img">
        {/* <img src={img} alt={title} /> */}
        <ImgLazyLoad
          src={img}
          alt={title}
          custom_class=""
        />
      </div>
      <p className="card_desc txt_color monserrat_font">{description}</p>
    </MyCard>
  );
};

export default CustomCard;
