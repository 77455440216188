import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./userContext";
import socketConnection from "./socketConnection";
const chatContext = createContext();

const ChatProvider = ({ children }) => {
  const [person, setPerson] = useState({});
  const [conversationId, setConversationId] = useState("");
  const socket = socketConnection()
  const [activeUsers, setActiveUsers] = useState([]);
  const [auth] = useAuth();
  const user = auth?.user;

  // // INITILAIZE SOCKET IO
  // useEffect(() => {
  //   const newSocket = io(process.env.REACT_APP_API_URL);
  //   setSocket(newSocket);
  // }, []);

  // CONNECT SOCKET
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        // console.log("User Connected", socket.id);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      if (user) {
        socket.emit("addUsers", user);
        socket.emit("userAddForCall", {proId: user?._id, fullName: user?.first_name, socketId: socket.id,});
      }
      socket.on("getUsers", (onlineUsers) => {
        setActiveUsers(onlineUsers);
        // console.log("onlineUsers", onlineUsers);
      });
    }
  }, [socket, user]);

  return (
    <chatContext.Provider value={{ person, setPerson, conversationId, setConversationId, socket, activeUsers, setActiveUsers }} >
      {children}
    </chatContext.Provider>
  );
};

const useChatAuth = (jwt) => useContext(chatContext);

export { useChatAuth, ChatProvider };
