import React from "react";
import "../styles/paymentOptions.scss";
import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import upi_icon from "../images/UPIicon.png";
import cardIcon from "../images/cardIcon.png";
import { useNavigate } from "react-router-dom";
import CustomStepper from "../components/reusable/CustomStepper";

const PaymentOptions = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <CustomHelmet />
      <section className="payment_sec_main">
        <div className="container">
          <div className="payment_content">
            <div className="title">
              <h1>PAYMENT</h1>
            </div>

            <section className="stepper_style">
              <CustomStepper activeStep={1} />
            </section>

            <div className="payment_opts">
              <h2 className="subheading">Choose Payment Options</h2>

              <div className="select_payment">
                <input type="radio" id="upi" name="paymentOption" value="upi" />
                <label htmlFor="upi">
                  <img src={upi_icon} alt="upi_icon" />
                  UPI
                </label>
              </div>
              <div className="select_payment">
                <input
                  type="radio"
                  id="card"
                  name="paymentOption"
                  value="card"
                />
                <label htmlFor="card">
                  <img src={cardIcon} alt="cardIcon" />
                  Card
                </label>
              </div>
              <div className="select_payment">
                <input
                  type="radio"
                  id="netBanking"
                  name="paymentOption"
                  value="netBanking"
                />
                <label htmlFor="netBanking">Net Banking</label>
              </div>
              <div className="select_payment">
                <input type="radio" id="emi" name="paymentOption" value="emi" />
                <label htmlFor="emi">EMI</label>
              </div>
            </div>
            <button onClick={() => navigate("/verification")}>
              next screen
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PaymentOptions;
