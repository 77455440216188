import React, { useState } from "react";
import "../../styles/affiliateNav.scss";
import { CiGlobe } from "react-icons/ci";
import { AiOutlineBell } from "react-icons/ai";
import { logo_main, logo_mobile } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useLocale } from "../../context/locale";
import { useAuth } from "../../context/userContext";
import { logout } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import LangChangeModal from "../../components/reusable/LangChangeModal";

const AffiliateNav = () => {
  const [langModalOpener, setLangModalOpener] = useState(false);
  const [t, changeLang] = useLocale(); // FOR CHANGING LANGUAGE FROM CONTEXT
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const localStorageLang = localStorage.getItem("lang");

  const changeLangByParam = (param) => {
    changeLang(param);
  };

  return (
    <div className="franchise_nav_main">
      <div className="container">
        <div className="navbar_section">
          <div className="nav_logo">
            <Link to="/">
              <img src={logo_main} alt="logo_main" className="logo_main" />
              <img
                src={logo_mobile}
                alt="logo_mobile"
                className="logo_mobile"
              />
            </Link>
          </div>
          <div className="links">
            {auth?.user?._id && (
              <button className="notification_button">
                <AiOutlineBell /> <span>4</span>
              </button>
            )}

            <button
              className="lang_button"
              onClick={() => setLangModalOpener(true)}
            >
              <CiGlobe /> {t("lang_btn_name")}
            </button>

            {auth?.user?._id && (
              <button className="logout_button" onClick={handleLogout}>
                Log Out
              </button>
            )}
          </div>
        </div>
      </div>

      {/* LANGUAGE MODAL START */}
      {langModalOpener && (
        <LangChangeModal
          setLangModalOpener={setLangModalOpener}
          langChanger={changeLangByParam}
          localStorageLang={localStorageLang}
        />
      )}

      {/* LANGUAGE MODAL ENDS */}
    </div>
  );
};

export default AffiliateNav;
