import React, { Fragment, useEffect, useState } from "react";
import "../../styles/otherTeacherTimeline.scss";
import { Link, useNavigate } from "react-router-dom";
// import { BiLeftArrowAlt } from "react-icons/bi";
import {
  BsPersonFill,
  BsPersonFillAdd,
  BsSearch,
  BsShareFill,
  // BsSearch,
  // BsPersonFillAdd,
  BsThreeDotsVertical,
} from "react-icons/bs";
import CustomButton from "../../components/reusable/CustomButton";
import { defaultUser,  workshop_hero } from "../../assets/images";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { CgCalendarDates } from "react-icons/cg";
import { MdPsychologyAlt } from "react-icons/md";
// import CustomInput from "../../components/reusable/CustomInput";
import { useAuth } from "../../context/userContext";
import moment from "moment";
import { arRole } from "../../utils/userRole";
import { useSelector } from "react-redux";
// import { clearErrors, officerListAction } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import { FaImage, FaRegThumbsUp } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import {
  clearDeletePostTimelineState,
  clearOfficerErrors,
  clearPostTimelineState,
  clearUpdateTimelineState,
  deleteTimelineAction,
  getAllTimelinePostAction,
  likeTimelinePostAction,
  postTimelineAction,
  updateTimelineAction,
} from "../../redux/actions/officerAction";
import ComponentLoading from "../../components/reusable/ComponentLoading";
import { RWebShare } from "react-web-share";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { otherTeacherLinks } from "./otherTeacherLinks";
import OfficerLayout from "../officer/OfficerLayout";
import {
  clearFollowErrors,
  clearFollowState,
  clearRemoveFollowState,
  getFollowerList,
  sendFollowRequest,
  unfollowSomeone,
} from "../../redux/actions/followAction";
import CustomInput from "../../components/reusable/CustomInput";
import { clearErrors, officerListAction } from "../../redux/actions/userAction";
import Loader from "../../components/reusable/Loader";
import { getMyCourses } from "../../redux/actions/courseAction";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const TextWithLinks = ({ value }) => {
  const renderTextWithLinks = () => {
    return value.split(/\s+/).map((word, index) => {
      if (isURL(word)) {
        return (
          <a href={word} key={index} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else {
        return <span key={index}>{word} </span>;
      }
    });
  };

  const isURL = (str) => {
    const pattern =
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$/i;
    return pattern.test(str);
  };

  return <div>{renderTextWithLinks()}</div>;
};

const OtherTeacherTimeline = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [searchOfficer, setSearchOfficer] = useState("");
  const [filterUser, setFilterUser] = useState();
  const [unFollowId, setUnFollowId] = useState();
  const [message, setMessage] = useState("");
  const [bioTxt, setBioTxt] = useState(300);
  const [image, setImage] = useState("");
  const [auth] = useAuth();
  const user = auth?.user;

  // HANLDE TABS ACTIVE
  const [activeTab, setActiveTab] = useState("all_post");
  const [likeData, setLikeData] = useState([]);
  const [settingToggle, setSettingToggle] = useState({
    show: false,
  });

  // GET ALL OFFICER LIST
  const {
    error: officerListError,
    loading: officerListLoading,
    userList,
  } = useSelector((state) => state.officerList);

  useEffect(() => {
    dispatch(officerListAction());
  }, [dispatch]);

  const officerList = userList?.officers?.filter(
    (itm) =>
      itm?._id !== user?._id &&
      (itm?.role === "12" ||
        itm?.role === "13" ||
        itm?.role === "16" ||
        itm?.role === "17" ||
        itm?.role === "18")
  );

  useEffect(() => {
    if (officerListError) {
      dispatch(clearErrors());
    }
  }, [dispatch, officerListError]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchOfficer(e.target.value);
    if (searchTerm.toLowerCase() === "io") {
      const filterOfficer = officerList.filter((item) => item.role === "17");
      setFilterUser(filterOfficer);
    } else if (searchTerm.toLowerCase() === "gto") {
      const filterOfficer = officerList.filter((item) => item.role === "16");
      setFilterUser(filterOfficer);
    } else if (searchTerm.toLowerCase() === "psychologist") {
      const filterOfficer = officerList.filter((item) => item.role === "18");
      setFilterUser(filterOfficer);
    } else if (searchTerm.toLowerCase() === "teacher") {
      const filterOfficer = officerList.filter((item) => item.role === "13");
      setFilterUser(filterOfficer);
    } else if (searchTerm && officerList) {
      const filterOfficer = officerList.filter(
        (item) =>
          (item.first_name &&
            item.first_name
              .toLowerCase()
              .includes(searchOfficer.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(searchOfficer.toLowerCase())) ||
          (item.last_name &&
            item.last_name.toLowerCase().includes(searchOfficer.toLowerCase()))
      );
      setFilterUser(filterOfficer);
    }
  };

  const handleToggleDelete = (id) => {
    setSettingToggle({
      show: !settingToggle?.show,
      id: id,
    });
  };

  const handleActiveTab = (active) => {
    if (active === "all_post") {
      dispatch(getAllTimelinePostAction());
    }
    if (active === "courses") {
      dispatch(getMyCourses(auth?.user?._id));
    }
    setActiveTab(active);
  };

  const joinedTime = moment(auth?.user?.createdAt).format("MMMM YYYY");

  // // HANDLE REQUEST SEND FUNCTION START

  // // GET ALL FOLLOWERS
  const {
    loading: followerLoading,
    error: followerError,
    followList,
  } = useSelector((state) => state.followerList);

  useEffect(() => {
    dispatch(getFollowerList());
  }, [dispatch]);

  useEffect(() => {
    if (followerError) {
      dispatch(clearFollowErrors());
    }
  }, [followerError, showNotification, dispatch]);

  // CHECK FOLLOWER EXIST OR NOT
  const isFollowingExist = (id) => {
    return followList?.follows?.followings.some(
      (follower) => follower._id === id
    );
  };

  const handleAvatarChange = (e) => {
    if (e.target.name === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader?.readyState === 2) {
          setImage(reader?.result);
        }
      };
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const handleRemove = () => {
    setImage("");
    setEditImage("");
  };

  const handleCreatePost = () => {
    dispatch(postTimelineAction(message, image));
  };

  const {
    error: postError,
    loading: postLoading,
    postData,
  } = useSelector((state) => state?.postTimeline);

  useEffect(() => {
    if (postError) {
      showNotification("error", postError);
      dispatch(clearOfficerErrors());
    }
    if (postData?.success) {
      showNotification("success", postData?.message);
      dispatch(clearPostTimelineState());
      dispatch(getAllTimelinePostAction());
      setImage("");
      setBioTxt("");
      setActiveTab("all_post");
    }
  }, [
    postError,
    showNotification,
    dispatch,
    postData?.message,
    postData?.success,
  ]);

  const {
    error: getPostListError,
    loading: getPostListLoading,
    postData: postList,
  } = useSelector((state) => state.getAllPostTimeline);

  useEffect(() => {
    dispatch(getAllTimelinePostAction());
  }, [dispatch]);

  useEffect(() => {
    if (getPostListError) {
      dispatch(clearOfficerErrors());
    }
  }, [getPostListError, dispatch]);

  const handlePostLike = (id) => {
    const filterId = likeData?.includes(id);
    if (filterId) {
      const filterData = likeData?.filter((itm) => itm !== id);
      setLikeData(filterData);
    } else {
      setLikeData((prevData) => [...prevData, id]);
    }

    dispatch(likeTimelinePostAction(id));
  };

  // HANLDE POST DELETE
  const handleDelete = (id) => {
    setUnFollowId(id);
    dispatch(deleteTimelineAction(id));
  };

  const {
    error: deletePostError,
    loading: deletePostLoading,
    deleteData,
  } = useSelector((state) => state.deleteTimeLinePost);

  useEffect(() => {
    if (deletePostError) {
      showNotification("error", deletePostError);
      dispatch(clearOfficerErrors());
    }
    if (deleteData?.success) {
      showNotification("success", deleteData?.message);
      dispatch(clearDeletePostTimelineState());
      setUnFollowId("");
      dispatch(getAllTimelinePostAction());
    }
  }, [
    deletePostError,
    showNotification,
    dispatch,
    deleteData?.success,
    deleteData?.message,
  ]);

  const handleViewFriend = (itm) => {
    navigate(`/friend-profile/${itm?.createdBy?._id}`);
  };

  // HANDLE EDIT POST

  const [editPost, setEditPost] = useState(false);
  const [editMessage, setEditMessage] = useState();
  const [editImage, setEditImage] = useState();

  const handleEdit = (data) => {
    setEditMessage(data?.label);
    setEditImage(data?.thumbnail?.url);
    setUnFollowId(data?._id);
    setEditPost(true);
  };

  const handleEditAvatarChange = (e) => {
    if (e.target.name === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader?.readyState === 2) {
          setEditImage(reader?.result);
        }
      };
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const handleUpdatePost = (id) => {
    dispatch(updateTimelineAction(id, message, image));
  };

  const {
    error: updateError,
    loading: updateLoading,
    updateData,
  } = useSelector((state) => state?.updateTimeline);

  useEffect(() => {
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearOfficerErrors());
    }
    if (updateData?.success) {
      showNotification("success", updateData?.message);
      dispatch(clearUpdateTimelineState());
      setSettingToggle({});
      dispatch(getAllTimelinePostAction());
      setEditPost(false);
      setActiveTab("all_post");
    }
  }, [
    updateError,
    showNotification,
    dispatch,
    updateData?.message,
    updateData?.success,
  ]);

  // UFOLLOW ANY USER FUNCITON
  const handleUnFollow = (id) => {
    setUnFollowId(id);
    dispatch(unfollowSomeone(id));
  };

  const {
    error: unFollowError,
    loading: unFollowLoading,
    followInfo: unFollowInfo,
  } = useSelector((state) => state.unFollowRequest);

  useEffect(() => {
    if (unFollowError) {
      showNotification("error", unFollowError);
      dispatch(clearFollowErrors());
    }
    if (unFollowInfo?.success) {
      showNotification("success", unFollowInfo?.message);
      dispatch(clearRemoveFollowState());
      dispatch(getFollowerList());
    }
  }, [
    unFollowError,
    showNotification,
    dispatch,
    unFollowInfo?.message,
    unFollowInfo?.success,
  ]);

  // HANDLE FOLLOW REQUEST
  const {
    error: requestError,
    loading: requestLoading,
    followInfo,
  } = useSelector((state) => state.sendRequest);

  const handleRequestSend = (id) => {
    if (!auth?.user?._id) {
      showNotification("error", "Please login before follow the officer");
      navigate("/login");
    } else {
      setUnFollowId(id);
      dispatch(sendFollowRequest(id));
    }
  };

  // HANDLE FOLLOW ERROR
  useEffect(() => {
    if (requestError) {
      showNotification("error", requestError);
      dispatch(clearFollowErrors());
    }
    if (followInfo?.success) {
      showNotification("success", followInfo?.message);
      dispatch(clearFollowState());
      dispatch(getFollowerList());
    }
  }, [
    requestError,
    showNotification,
    dispatch,
    followInfo?.message,
    followInfo?.success,
  ]);

  // GET SELF CREATED COURSES
  const {
    loading: myCourseLoading,
    error: myCourseError,
    courses,
  } = useSelector((state) => state.getMyCoursesById);

  // useEffect(() => {
  //   if (auth?.user?._id) {
  //     dispatch(getMyCourses());
  //   }
  // }, [auth?.user?._id, dispatch]);

  useEffect(() => {
    if (myCourseError) {
      dispatch(clearErrors());
    }
  }, [dispatch, myCourseError]);


  const handleDetailsPage = (data) => {
    const { title, study_mode, courseCategory, _id } = data;
    const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
    const formattedCategory = courseCategory.toLowerCase().replace(/\s+/g, "-");
    const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
    navigate(seoFriendlyUrl);
  };

  return (
    <Fragment>
      {followerLoading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={otherTeacherLinks}>
            <div className="otherTeacher_timeline_main txt_color">
              <div className="title">Chatbox</div>
              <div className="container">
                <div className="officer_timeline_content">
                  <div className="officerProfile">
                    {/* // PROFILE CONTENT START */}
                    <div className="profileCover">
                      <img
                        src={workshop_hero}
                        alt="alpha officer profile cover"
                      />
                    </div>
                    <div className="profilePic">
                      <img
                        src={
                          user?.profile?.url ? user?.profile?.url : defaultUser
                        }
                        alt="alpha officer profile"
                      />
                    </div>
                    <div className="userDetails">
                      <div className="buttonGrp">
                        <div className="follow_tab">
                          <Link to="/teacher/follower">
                            {`Followers (${
                              followList?.follows &&
                              followList?.follows?.followers
                                ? followList?.follows?.followers?.length
                                : 0
                            })`}
                          </Link>

                          <Link to="/teacher/following">{`Following (${
                            followList?.follows &&
                            followList?.follows?.followings
                              ? followList?.follows?.followings?.length
                              : 0
                          })`}</Link>
                          <Link to="/teacher/connection">{`Connections (${
                            followList?.follows &&
                            followList?.follows?.connections
                              ? followList?.follows?.connections?.filter(
                                  (itm) => itm?.status === "Accepted"
                                )?.length
                              : 0
                          })`}</Link>
                        </div>
                        <div className="shareBtn">
                          <RWebShare
                            data={{
                              url: `${window.location.origin}/view-profile/${user?._id}`,
                              title: `Share ${user?.first_name} profile`,
                            }}
                            onClick={() => console.log("user")}
                          >
                            <CustomButton
                              className="shareButton"
                              preIcon={<BsShareFill />}
                              tooltip="Share Friend Profile"
                            />
                          </RWebShare>
                        </div>
                      </div>
                      <div className="general">
                        <h2 className="name">{user?.first_name}</h2>
                        <p className="uniqueCode">{user?.couponCode}</p>
                        <p className="tag">
                          {user?.role === "16" ? (
                            <GiPoliceOfficerHead />
                          ) : user?.role === "17" ? (
                            <BsPersonFill />
                          ) : (
                            user?.role === "18" && <MdPsychologyAlt />
                          )}
                          {arRole(user?.role)}
                        </p>
                        <p className="bio">
                          {user?.teacherBio?.slice(0, bioTxt)}
                        </p>
                        {bioTxt === 300 ? (
                          <p
                            style={{
                              cursor: "pointer",
                              marginTop: "10px",
                              textDecoration: "underline",
                            }}
                            className="bio"
                            onClick={() => setBioTxt(user?.teacherBio?.length)}
                          >
                            Read More
                          </p>
                        ) : (
                          <p
                            style={{
                              cursor: "pointer",
                              marginTop: "10px",
                              textDecoration: "underline",
                            }}
                            className="bio"
                            onClick={() => setBioTxt(300)}
                          >
                            Read Less
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="locationBirth">
                      <p>
                        <CgCalendarDates /> Joined {joinedTime}
                      </p>
                    </div>
                    <div className="timeline_tabs">
                      <ul className="tabs">
                        <li
                          className={`${
                            activeTab === "create_post" ? "active" : ""
                          }`}
                          onClick={() => handleActiveTab("create_post")}
                        >
                          <p>Create Post</p>
                        </li>
                        <li
                          className={`${
                            activeTab === "all_post" ? "active" : ""
                          }`}
                          onClick={() => handleActiveTab("all_post")}
                        >
                          <p>All Posts</p>
                        </li>
                        <li
                          className={`${
                            activeTab === "courses" ? "active" : ""
                          }`}
                          onClick={() => handleActiveTab("courses")}
                        >
                          <p>Courses</p>
                        </li>
                        <li
                          className={`${
                            activeTab === "discover" ? "active" : ""
                          }`}
                          onClick={() => handleActiveTab("discover")}
                        >
                          <p>Discover People</p>
                        </li>
                      </ul>
                      {activeTab === "create_post" && (
                        <div className="post_insight">
                          <img
                            src={
                              user?.profile?.url
                                ? user?.profile?.url
                                : defaultUser
                            }
                            alt="alpha officer profile"
                          />
                          <div className="post_details">
                            <h2>Post On Your Timeline</h2>
                            <div className="post_form">
                              <CustomTextarea
                                placeholder="Enter something"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                              />
                              <div className="upload_img">
                                {image !== "" && (
                                  <div className="preview">
                                    <img src={image} alt="preview" />
                                    <button>
                                      <RxCross2
                                        onClick={() => handleRemove()}
                                      />
                                    </button>
                                  </div>
                                )}

                                <div className="imageInput">
                                  <label htmlFor="fileInput">
                                    <FaImage />
                                    <input
                                      type="file"
                                      id="fileInput"
                                      name="image"
                                      accept="image/*"
                                      onChange={(e) => handleAvatarChange(e)}
                                      style={{ display: "none" }}
                                    />
                                  </label>
                                  <div className="post_btn">
                                    <CustomButton
                                      title={
                                        postLoading ? <ButtonLoader /> : "Post"
                                      }
                                      className="btn"
                                      onClick={() => handleCreatePost()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "all_post" &&
                        (getPostListLoading ? (
                          <ComponentLoading />
                        ) : postList?.allPosts?.length > 0 ? (
                          <div className="post_list">
                            {postList?.allPosts?.map((itm) => (
                              <div className="post_card" key={itm?._id}>
                                {editPost && itm?._id === unFollowId ? (
                                  <>
                                    <img
                                      src={
                                        itm?.createdBy?.profile?.url
                                          ? itm?.createdBy?.profile?.url
                                          : defaultUser
                                      }
                                      alt={itm?.createdBy?.first_name}
                                    />
                                    <div className="post_form">
                                      <CustomTextarea
                                        placeholder="Enter something"
                                        value={editMessage}
                                        onChange={(e) =>
                                          setEditMessage(e.target.value)
                                        }
                                      />
                                      <div className="upload_img">
                                        {editImage !== "" && (
                                          <div className="preview">
                                            <img
                                              src={editImage}
                                              alt="preview"
                                            />
                                            <button>
                                              <RxCross2
                                                onClick={() => handleRemove()}
                                              />
                                            </button>
                                          </div>
                                        )}

                                        <div className="imageInput">
                                          <label htmlFor="fileInput">
                                            <FaImage />
                                            <input
                                              type="file"
                                              id="fileInput"
                                              name="editImage"
                                              accept="image/*"
                                              onChange={(e) =>
                                                handleEditAvatarChange(e)
                                              }
                                              style={{ display: "none" }}
                                            />
                                          </label>
                                          <div className="post_btn">
                                            <CustomButton
                                              title={
                                                updateLoading ? (
                                                  <ButtonLoader />
                                                ) : (
                                                  "Update"
                                                )
                                              }
                                              className="btn"
                                              onClick={() =>
                                                handleUpdatePost(itm?._id)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={
                                        itm?.createdBy?.profile?.url
                                          ? itm?.createdBy?.profile?.url
                                          : defaultUser
                                      }
                                      alt={itm?.createdBy?.first_name}
                                    />
                                    <div className="post_details">
                                      <div className="title">
                                        <div className="name">
                                          <p
                                            onClick={() =>
                                              handleViewFriend(itm)
                                            }
                                          >
                                            {itm?.createdBy?.first_name}
                                          </p>
                                          <span>
                                            {moment(itm?.createdAt).format(
                                              "MMMM DD[,] YYYY"
                                            )}
                                          </span>
                                        </div>
                                        {itm?.createdBy?._id ===
                                          auth?.user?._id && (
                                          <div className="delete_btn">
                                            <span
                                              onClick={() =>
                                                handleToggleDelete(itm?._id)
                                              }
                                            >
                                              <BsThreeDotsVertical />
                                            </span>
                                            <div
                                              className={`post_seting ${
                                                settingToggle?.show &&
                                                settingToggle?.id === itm?._id
                                                  ? "visible"
                                                  : ""
                                              }`}
                                            >
                                              <p
                                                onClick={() => handleEdit(itm)}
                                              >
                                                Edit
                                              </p>
                                              <p
                                                onClick={() =>
                                                  handleDelete(itm?._id)
                                                }
                                              >
                                                {deletePostLoading &&
                                                unFollowId === itm?._id ? (
                                                  <ButtonLoader />
                                                ) : (
                                                  "Delete"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <TextWithLinks value={itm?.label} />
                                      {itm?.thumbnail?.url !== "" && (
                                        <img
                                          src={itm?.thumbnail?.url}
                                          alt="postimage"
                                        />
                                      )}
                                      <div className="post_footer">
                                        <CustomButton
                                          title={`${
                                            itm?.likes?.length > 0
                                              ? itm?.likes?.length
                                              : ""
                                          } Like`}
                                          className={`like_btn ${
                                            likeData?.includes(itm._id) ||
                                            (itm?.likes &&
                                              itm.likes.some(
                                                (like) =>
                                                  like._id === auth?.user?._id
                                              ))
                                              ? "active"
                                              : ""
                                          }`}
                                          width="100px"
                                          onClick={() =>
                                            handlePostLike(itm?._id)
                                          }
                                          icon={<FaRegThumbsUp />}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <OfficerNoDataCard txt="Share compelling content to attract follower and grow your audience." />
                        ))}

                      {activeTab === "courses" &&
                        (myCourseLoading ? (
                          <div className="course_list">
                            <SkeletonTheme
                              baseColor="#121D40"
                              highlightColor="#96abe3"
                            >
                              {[1, 2, 3, 4].map((itm) => {
                                return (
                                  <div className="course_card" key={itm}>
                                    <Skeleton height={110} />
                                    <p>
                                      <Skeleton />
                                    </p>
                                    <Skeleton height={40} width={100} />
                                  </div>
                                );
                              })}
                            </SkeletonTheme>
                          </div>
                        ) : (
                          <div className="course_list">
                            {courses?.courses?.length > 0 ? (
                              courses?.courses?.map((item) => (
                                <div className="course_card" key={item?._id}>
                                  <img src={item?.thumbnail?.url} alt="user" />
                                  <p>{item?.title}</p>
                                  <div className="view_btn">
                                    <CustomButton
                                      title="View Detail"
                                      width="110px"
                                      className="btn"
                                      onClick={() =>
                                        handleDetailsPage(item)
                                      }
                                    />
                                  </div>
                                </div>
                              ))
                            ) : (
                              <OfficerNoDataCard txt="You are yet to add a course to your arsenal." />
                            )}
                          </div>
                        ))}

                      {activeTab === "discover" && (
                        <>
                          <div className="cnctWth">
                            <div className="cnctWth_content">
                              <p>Connect With:</p>
                              <div>
                                <CustomInput
                                  icon={<BsSearch />}
                                  type="text"
                                  onChange={(e) => handleSearch(e)}
                                  placeholder="Search"
                                  name="searchString"
                                  value={searchOfficer}
                                />
                              </div>
                            </div>
                          </div>

                          {/* LIST OF SEARCH OFFICER RESULT START */}
                          {officerListLoading ? (
                            <ComponentLoading />
                          ) : (
                            <div className="officersList">
                              {filterUser?.map((item, idx) => (
                                <div className="officerRow" key={idx}>
                                  <div className="profileName">
                                    <span>
                                      <img
                                        src={
                                          item?.profile?.url
                                            ? item?.profile?.url
                                            : defaultUser
                                        }
                                        alt="alpha officer"
                                      />
                                    </span>
                                    <span>{item?.first_name}</span>
                                  </div>
                                  <div className="officerTag">
                                    {item?.role === "13" && <p>Teacher</p>}
                                    {item?.role === "18" && <p>Psychologist</p>}
                                    {item?.role === "17" && <p>I/O</p>}
                                    {item?.role === "16" && <p>GTO</p>}
                                  </div>
                                  <div className="connectBtn">
                                    {isFollowingExist(item?._id) ? (
                                      <CustomButton
                                        className=""
                                        title={
                                          unFollowId === item?._id &&
                                          unFollowLoading ? (
                                            <ButtonLoader />
                                          ) : (
                                            "Unfollow"
                                          )
                                        }
                                        onClick={() =>
                                          handleUnFollow(item?._id)
                                        }
                                        disabled=""
                                        preIcon={
                                          unFollowId === item?._id &&
                                          unFollowLoading ? (
                                            ""
                                          ) : (
                                            <BsPersonFillAdd />
                                          )
                                        }
                                      />
                                    ) : (
                                      <CustomButton
                                        className=""
                                        title={
                                          unFollowId === item?._id &&
                                          requestLoading ? (
                                            <ButtonLoader />
                                          ) : (
                                            "Follow"
                                          )
                                        }
                                        onClick={() =>
                                          handleRequestSend(item?._id)
                                        }
                                        disabled=""
                                        preIcon={
                                          unFollowId === item?._id &&
                                          requestLoading ? (
                                            ""
                                          ) : (
                                            <BsPersonFillAdd />
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {/* LIST OF SEARCH OFFICER RESULT END */}
                        </>
                      )}
                    </div>
                    {/* // PROFILE CONTENT ENDS */}
                  </div>
                </div>
              </div>
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OtherTeacherTimeline;
