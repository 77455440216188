import axios from "axios";
import {
  CHECK_OFFICER_AVAILABILTIY_FAIL,
  CHECK_OFFICER_AVAILABILTIY_REQUEST,
  CHECK_OFFICER_AVAILABILTIY_SUCCESS,
  GET_OFFICER_CALENDER_FAIL,
  GET_OFFICER_CALENDER_REQUEST,
  GET_OFFICER_CALENDER_SUCCESS,
  OFFICER_CALENDER_CLEAR_ERRORS,
  OFFICER_CALENDER_CLEAR_STATE,
  OFFICER_CALENDER_CREATE_FAIL,
  OFFICER_CALENDER_CREATE_REQUEST,
  OFFICER_CALENDER_CREATE_SUCCESS,
  OFFICER_CALENDER_UPDATE_CLEAR_STATE,
  OFFICER_CALENDER_UPDATE_FAIL,
  OFFICER_CALENDER_UPDATE_REQUEST,
  OFFICER_CALENDER_UPDATE_SUCCESS,
} from "../constants/officerCalenderConstant";
import { axiosInstance } from "../../utils/axiosConfig";

export const createOfficerCalenderAction =
  (officerId, title, date, startTime, endTime, student, piq) =>
  async (dispatch) => {
    try {
      dispatch({
        type: OFFICER_CALENDER_CREATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/create-event/${officerId}`,
        {
          title,
          date,
          startTime,
          endTime,
          student,
          piq,
        },
        config
      );

      dispatch({
        type: OFFICER_CALENDER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OFFICER_CALENDER_CREATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const updateCalenderAction =
  (id, title, date, startTime, endTime, student, piq) => async (dispatch) => {
    try {
      dispatch({
        type: OFFICER_CALENDER_UPDATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/events/${id}`,
        {
          title,
          date,
          startTime,
          endTime,
          student,
          piq,
        },
        config
      );
      dispatch({
        type: OFFICER_CALENDER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OFFICER_CALENDER_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL COURSES
export const getOfficerCalender = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OFFICER_CALENDER_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-calendar/${id}`, config);

    dispatch({
      type: GET_OFFICER_CALENDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_OFFICER_CALENDER_FAIL,
      payload: error.response.data.message,
    });
  }
};


// CHECK OFFICER AVALABILITY
export const getOfficerAvalability = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CHECK_OFFICER_AVAILABILTIY_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/check-timeslot/${id}`, config);

    dispatch({
      type: CHECK_OFFICER_AVAILABILTIY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECK_OFFICER_AVAILABILTIY_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearCalenderErrors = () => async (dispatch) => {
  dispatch({ type: OFFICER_CALENDER_CLEAR_ERRORS });
};

export const clearCalenderUpdateState = () => async (dispatch) => {
  dispatch({ type: OFFICER_CALENDER_UPDATE_CLEAR_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearCalenderState = () => async (dispatch) => {
  dispatch({ type: OFFICER_CALENDER_CLEAR_STATE });
};
