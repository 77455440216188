import { COURSE_PAUSE_FAIL, COURSE_PAUSE_REQUEST, COURSE_PAUSE_SUCCESS } from "../constants/courseConstant";
import {
  DELETE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  GET_MY_CREATAED_COURSE_FAIL,
  GET_MY_CREATAED_COURSE_REQUEST,
  GET_MY_CREATAED_COURSE_SUCCESS,
  TEACHER_CLEAR_DELETE_STATE,
  TEACHER_CLEAR_ERRORS,
  TEACHER_CLEAR_STATE,
  TEACHER_COURSE_CREATE_FAIL,
  TEACHER_COURSE_CREATE_REQUEST,
  TEACHER_COURSE_CREATE_SUCCESS,
} from "../constants/teacherConstant";

export const teacherCourseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TEACHER_COURSE_CREATE_REQUEST:
      return {
        loading: true,
        courseData: {},
      };
    case TEACHER_COURSE_CREATE_SUCCESS:
      return {
        loading: false,
        courseData: action.payload,
      };
    case TEACHER_COURSE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TEACHER_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case TEACHER_CLEAR_STATE:
      return {
        loading: false,
        courseData: {},
      };

    default:
      return state;
  }
};

export const myCreatedCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MY_CREATAED_COURSE_REQUEST:
      return {
        loading: true,
        courseData: {},
      };
    case GET_MY_CREATAED_COURSE_SUCCESS:
      return {
        loading: false,
        courseData: action.payload,
      };
    case GET_MY_CREATAED_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TEACHER_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// PAUSE COURSE REDUCER
export const pauseCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_PAUSE_REQUEST:
      return {
        loading: true,
        status: {},
      };
    case COURSE_PAUSE_SUCCESS:
      return {
        loading: false,
        status: action.payload,
      };
    case COURSE_PAUSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TEACHER_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TEACHER_CLEAR_DELETE_STATE:
      return {
        status: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};


export const deleteCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COURSE_REQUEST:
      return {
        loading: true,
        courses: {},
      };
    case DELETE_COURSE_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case DELETE_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TEACHER_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    case TEACHER_CLEAR_STATE:
      return {
        loading: false,
        error: null,
        courses: {},
      };

    default:
      return state;
  }
};
