export const CompleteMockSSB = [
  {
    id: 1,
    title: "Officer Intelligence Rating Test (OIR)",
    subTitle: "1 hr",
  },
  {
    id: 2,
    title: "Picture Perception & Discussion Test (PPDT)",
    subTitle: "30 min",
  },
  {
    id: 3,
    title: "Psychology Test",
    subTitle: "2 hrs",
  },
  {
    id: 4,
    title: "Group Tasks (GTO)",
    subTitle: "10 hrs approx",
  },
  {
    id: 5,
    title: "Interview",
    subTitle: "1 hr approx",
  },
  {
    id: 6,
    title: "Conference ",
    subTitle: "Few hours",
  },
  {
    id: 7,
    title: "Feedback",
    subTitle: "1-2 days",
  },
];
