export const otherTeacherLinks = [
  {
    id: 1,
    title: "Chatbox",
    link: "/teacher/chatbox",
  },
  {
    id: 2,
    title: "Profile",
    link: "/teacher/profile",
  },
  {
    id: 3,
    title: "Calender",
    link: "/teacher/calender",
  },
  {
    id: 4,
    title: "Courses",
    link: "/teacher/courses",
  },
  {
    id: 5,
    title: "Course Curriculum",
    link: "/teacher/curriculum",
  },
  {
    id: 6,
    title: "Assignment",
    link: "/teacher/assignment",
  },
  {
    id: 7,
    title: "Revenue",
    link: "/teacher/revenue",
  },
  {
    id: 8,
    title: "Reviews",
    link: "/teacher/reviews",
  },
];
