import {
  ADD_WISHLIST_ITEM_FAIL,
  ADD_WISHLIST_ITEM_REQUEST,
  ADD_WISHLIST_ITEM_SUCCESS,
  CLEAR_DELETE_WISHLIST_ITEM_STATE,
  CLEAR_WISHLIST_ERROR,
  CLEAR_WISHLIST_STATE,
  DELETE_WISHLIST_ITEM_FAIL,
  DELETE_WISHLIST_ITEM_REQUEST,
  DELETE_WISHLIST_ITEM_SUCCESS,
  GET_WISHLIST_ITEM_FAIL,
  GET_WISHLIST_ITEM_REQUEST,
  GET_WISHLIST_ITEM_SUCCESS,
} from "../constants/wishListConstant";

export const addWishlistReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_WISHLIST_ITEM_REQUEST:
      return {
        loading: true,
        wishListData: {},
      };
    case ADD_WISHLIST_ITEM_SUCCESS:
      return {
        loading: false,
        wishListData: action.payload,
      };
    case ADD_WISHLIST_ITEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_WISHLIST_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_WISHLIST_STATE:
      return {
        loading: false,
        wishListData: {},
      };

    default:
      return state;
  }
};

// GET ALL WISHLIST REDUCER
export const getWishListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WISHLIST_ITEM_REQUEST:
      return {
        loading: true,
        allWishList: {},
      };
    case GET_WISHLIST_ITEM_SUCCESS:
      return {
        loading: false,
        allWishList: action.payload,
      };
    case GET_WISHLIST_ITEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_WISHLIST_ERROR:
      return {
        allWishList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};


export const deleteWishListReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_WISHLIST_ITEM_REQUEST:
        return {
          loading: true,
          allWishListData: {},
        };
      case DELETE_WISHLIST_ITEM_SUCCESS:
        return {
          loading: false,
          allWishListData: action.payload,
        };
      case DELETE_WISHLIST_ITEM_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_WISHLIST_ERROR:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case CLEAR_DELETE_WISHLIST_ITEM_STATE:
        return {
          allWishListData: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };