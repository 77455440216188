import React, { Fragment, useEffect } from "react";
import StudentLayout from "../../components/student/StudentLayout";
import { sidebarLinks } from "./studentsSidebarLinks";
import { FaRegSadTear } from "react-icons/fa";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import CustomButton from "../../components/reusable/CustomButton";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import { useSelector } from "react-redux";
import Loader from "../../components/reusable/Loader";
import {
  clearLiveClassErrors,
  getAllLiveClasses,
} from "../../redux/actions/liveClassAction";

import "../../styles/studentClasses.scss";
import StudentFooter from "../../components/student/StudentFooter";

const StudentLiveClasses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  const { loading, error, classList } = useSelector(
    (state) => state.getLiveClassList
  );
  const allClasses = classList?.classes;
  useEffect(() => {
    dispatch(getAllLiveClasses());
  }, [dispatch]);

  // HANLDE LIVE LIST ERROR
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearLiveClassErrors());
    }
  }, [dispatch, error, showNotification]);

  // START LIVE CLASS FUNCTION
  const handleStartClass = (data) => {
    if (data?.meetData?.meetingId !== null) {
      navigate("/live-join/0", { state: data?.meetData });
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <StudentLayout sidebarLinks={sidebarLinks}>
            <div className="student_class_list">
              <div className="title">
                <h2>Live Class List</h2>
              </div>
              <div className="batch_timing_main officer_live_class txt_color">
                {allClasses?.length > 0 ? (
                  <div className="timing_calendar">
                    <div className="timing_table custonScroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Class Title</th>
                            <th>Class Duration</th>
                            <th>Start Date & Time</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allClasses?.map((ele, idx) => (
                            <tr key={ele._id}>
                              <td>
                                <p>{ele?.title}</p>
                              </td>
                              <td>
                                <p>{ele?.timeData?.duration}</p>
                              </td>
                              <td>
                                <p>
                                  {moment(
                                    ele?.timeData?.startTimeString
                                  ).format("MMMM-DD-YYYY [at] h:mm A")}
                                </p>
                              </td>

                              <td className="txt_center">
                                <p>
                                  <span
                                    className={`${
                                      ele?.status !== "Upcoming"
                                        ? "red"
                                        : "green"
                                    }`}
                                  >
                                    {ele?.status}
                                  </span>
                                </p>
                              </td>
                              <td>
                                <p>
                                  <CustomButton
                                    title={ele?.status === "Upcoming" ? "Not Started" : "Join Class"}
                                    width="100px"
                                    className={ele?.status === "Upcoming" ? "not_started" : "join_now"}
                                    disabled={ele?.status === "Upcoming" ? true : false}
                                    onClick={() => handleStartClass(ele)}
                                  />
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <OfficerNoDataCard
                    txt="There is no live class for your purchase course yet"
                    icon={<FaRegSadTear />}
                  />
                )}
              </div>
            </div>
          </StudentLayout>

          <StudentFooter />
        </>
      )}
    </Fragment>
  );
};

export default StudentLiveClasses;
