export const MY_CREATED_MCQ_LIST_REQUEST = "MY_CREATED_MCQ_LIST_REQUEST";
export const MY_CREATED_MCQ_LIST_SUCCESS = "MY_CREATED_MCQ_LIST_SUCCESS";
export const MY_CREATED_MCQ_LIST_FAIL = "MY_CREATED_MCQ_LIST_FAIL";


export const CREATE_MCQ_REQUEST = "CREATE_MCQ_REQUEST";
export const CREATE_MCQ_SUCCESS = "CREATE_MCQ_SUCCESS";
export const CREATE_MCQ_FAIL = "CREATE_MCQ_FAIL";

export const MCQ_DETAILS_REQUEST = "MCQ_DETAILS_REQUEST";
export const MCQ_DETAILS_SUCCESS = "MCQ_DETAILS_SUCCESS";
export const MCQ_DETAILS_FAIL = "MCQ_DETAILS_FAIL";

export const UPDATE_MCQ_REQUEST = "UPDATE_MCQ_REQUEST";
export const UPDATE_MCQ_SUCCESS = "UPDATE_MCQ_SUCCESS";
export const UPDATE_MCQ_FAIL = "UPDATE_MCQ_FAIL";

export const DELETE_MCQ_REQUEST = "DELETE_MCQ_REQUEST";
export const DELETE_MCQ_SUCCESS = "DELETE_MCQ_SUCCESS";
export const DELETE_MCQ_FAIL = "DELETE_MCQ_FAIL";

export const CLEAR_MCQ_ERROR = "CLEAR_MCQ_ERROR";
export const CLEAR_MCQ_STATE = "CLEAR_MCQ_STATE";


export const CLEAR_MCQ_UPDATE_STATE = "CLEAR_MCQ_UPDATE_STATE";
export const CLEAR_DELETE_MCQ_STATE = "CLEAR_DELETE_MCQ_STATE";