import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import { Link, useNavigate } from "react-router-dom";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import CourseCard from "../../components/course/CourseCard";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import "../../styles/ssbCoaching.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
import { IoMdArrowRoundBack } from "react-icons/io";
const MockGto = () => {
  const currentPageUrl = window?.location?.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [t] = useLocale();
  const { loading, courses, error } = useSelector(
    (state) => state.getAllSsbCourses
  );

  let allSsbApiCourses = courses?.courses?.filter(
    (itm) =>
      itm?.category === "Mock GTO" &&
      ((itm?.isActive && !itm?.isPause) ||
        (itm?.isActive && itm?.isPause && itm?.pauseForDate !== null))
  );
  const [courseMode, setCourseMode] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const handleSearchChange = (e) => {
    const filter = e.target.value;
    setSearchCategory(filter);
    const filterdata = allSsbApiCourses?.filter((i) => {
      return (
        i.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) &&
        i.courseMode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase())
      );
    });
    allSsbApiCourses = filterdata;
  };
  const handleFilterMode = (query) => {
    setCourseMode(query);
    const filterData = allSsbApiCourses?.filter((course) => {
      return course?.courseMode === query;
    });
    allSsbApiCourses = filterData;
  };

  // console.log(allSsbApiCourses);

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch]);

  const handleSsbDetailsPage = (data) => {
    // if (categoryTitle === "Spoken English") {
    //   const { title, study_mode, courseCategory, _id } = data;
    //   const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    //   const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
    //   const formattedCategory = courseCategory
    //     .toLowerCase()
    //     .replace(/\s+/g, "-");
    //   const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
    //   navigate(seoFriendlyUrl);
    // } else {
    const { slug } = data;
    const seoFriendlyUrl = `/ssb-course-details/${slug}`;
    navigate(seoFriendlyUrl);
    // }
  };

  // CHECK WISHLIST EXIST OR NOT
  const [wishListId, setWishListId] = useState("");

  const {
    // eslint-disable-next-line
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    setWishListId("");
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES
  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="Schedule an offline mock GTO session to assess and improve your preparation for the GTO. Identify and address any areas needing improvement."
            author="Team Alpha Regiment"
            title="Schedule Your SSB Mock GTO Offline at Your Preferred Time."
            canonical={currentPageUrl}
          />
          <section className="ssb_coaching_courses_main">
            <div className="container">
              <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div>

              <div className="all_course_content">
                <div className="all_course_cards">
                  <h1 className="main_title"><span onClick={()=> navigate(-1)}><IoMdArrowRoundBack /></span> Mock GTO</h1>
                  <p className="description">
                    As an SSB interview aspirant, you know that GTO preparation
                    demands the most time and resources. This is because, in the
                    SSB, you spend almost one and a half days with the GTO,
                    which is significantly more compared to the time spent with
                    the IO and the Psychologist. If you wish to prepare on a
                    real GTO ground offline or remotely using a 3D GTO
                    simulation, a mock GTO can be very beneficial to you.
                  </p>
                  {allSsbApiCourses?.length > 0 && (
                    <div className="slider_container">
                      {allSsbApiCourses?.map((item, idx) => {
                        const roundedPrice = Math.ceil(item?.originalPrice);
                        const roundedDiscountedPrice = Math.ceil(
                          item?.discountedPrice
                        );
                        return (
                          <CourseCard
                            key={idx}
                            isActiveClass={true}
                            className="course_card"
                            title={item?.title}
                            description={`${item?.description?.substr(
                              0,
                              40
                            )}...`}
                            icon={item?.thumbnail?.url}
                            startingFrom={item?.starting_date}
                            price={roundedPrice}
                            discountedPrice={roundedDiscountedPrice}
                            numOfReviews={item?.numOfReviews}
                            rating={item?.rating}
                            addWishList={() =>
                              handleAddtoWishlist({
                                itemId: item?._id,
                                isSsb: true,
                              })
                            }
                            deleteWishListLoading={
                              wishListId === item?._id &&
                              deleteLoading && <ButtonLoader />
                            }
                            existInWishList={allWishList?.wishlist?.ssbCourse?.some(
                              (obj) => obj._id === item._id
                            )}
                            addWishListLoading={
                              wishListId === item?._id &&
                              addWishListLoading && <ButtonLoader />
                            }
                            onHomePageClick={() => handleSsbDetailsPage(item)}
                          />
                        );
                      })}
                    </div>
                  )}

                  {allSsbApiCourses?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is a Mock GTO?</h2>
                </div>
                <div className="detail">
                  <p>
                    A Mock GTO replicates the GTO conducted at SSB, AFSB, and
                    NSB. All tasks, from group discussion to the Final Group
                    Task (FGT), are conducted with the same level of difficulty
                    and in the presence of a senior GTO. The only difference is
                    that, in a Mock GTO, the GTO provides feedback on your
                    performance, identifies areas for improvement, and helps you
                    address your weaknesses.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is the duration of the Mock GTO?</h2>
                </div>
                <div className="detail">
                  <p>
                    Normally, the GTO is conducted over two days. On the first
                    day, indoor tasks such as group discussions and military
                    planning exercises (also known as group planning exercises)
                    are held. From the second day onwards, outdoor tasks like
                    progressive group tasks, individual obstacle tasks, and
                    others are conducted. However, in a Mock GTO, all activities
                    can be condensed into a single day to accommodate working
                    candidates. Some Mock GTOs may still span two days. In
                    summary, the duration of a Mock GTO can vary between one and
                    two days. Please review the course details before enrolling.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Do I need to wear a GTO dress?</h2>
                </div>
                <div className="detail">
                  <p>Yes, you need to come in GTO dress.</p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>How online mock GTO will be conducted?</h2>
                </div>
                <div className="detail">
                  <p>
                    At Alpha Regiment, we invested considerable time in research
                    and developed a tech-driven platform to make SSB interview
                    preparation affordable, effective, convenient, and
                    results-oriented. Early on, we recognized the need for an
                    online GTO solution, so we created a 3D GTO simulation. This
                    allows you to participate in online mock GTO sessions and
                    attend GTO classes.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    What are the accommodation arrangements if the Mock GTO is
                    conducted offline and lasts for 2 days?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    All our GTO grounds are either within the campus or very
                    close by. If you enroll in a two-day mock GTO, you won't
                    need to worry about accommodation—you can stay on our
                    campus. Please note that accommodation charges will apply,
                    separate from the mock GTO fees.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    What measures does the Alpha Regiment have in place to
                    prevent injuries and ensure safety while students perform
                    various GTO tasks?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    At Alpha Regiment, we prioritize the safety and security of
                    every aspirant. To ensure safety on the GTO ground, we have
                    implemented several measures:
                  </p>
                  <ol>
                    <li>
                      A GTO and retired army personnel are always present when
                      aspirants are on the ground.
                    </li>
                    <li>Structures are designed to have no sharp edges.</li>
                    <li>
                      We brief students in advance about injury-prone areas so
                      they remain cautious while performing tasks.
                    </li>
                    <li>Proper first aid arrangements are always available.</li>
                    <p>
                      Despite these precautions, we advise students not to take
                      unnecessary risks with their safety, as the real
                      assessment will be at the SSB. Stay safe and healthy for
                      the actual evaluation.
                    </p>
                  </ol>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    Can one also enroll separately in Mock Psychology and Mock
                    Interviews?{" "}
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    Yes, that is also possible. Please follow these links to
                    access the{" "}
                    <Link to="/mock-psychology">Mock Psychology</Link> and{" "}
                    <Link to="/mock-interview">Mock Interview</Link> pages.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default MockGto;
