import React from "react";
import "./registerSection.scss";
import CustomButton from "../../components/reusable/CustomButton";
import CustomSelect from "../../components/reusable/CustomSelect";
import CustomInput from "../../components/reusable/CustomInput";

const RegisterSection = () => {
  return (
    <div className="reg_for_attend txt_color">
      <div className="container">
        <div className="reg_content_main">
          <div className="form_sec">
            <div className="myForm_sec">
              <CustomInput
                type="text"
                // onChange=""
                placeholder="Enter Your Name*"
                name="fullName"
                value=""
              />
              <CustomInput
                type="text"
                // onChange=""
                placeholder="Enter Your Email*"
                name="email"
                value=""
              />
              <CustomInput
                type="number"
                // onChange=""
                placeholder="Enter Your Phone Number*"
                name="phoneNumber"
                value=""
              />
              <CustomSelect
                name="ticketType"
                // onChange=""
                optionArray={[
                  {
                    id: "1",
                    option: "1 day ticket",
                  },
                  {
                    id: "2",
                    option: "2 day ticket",
                  },
                  {
                    id: "3",
                    option: "Full ticket",
                  },
                ]}
                value=""
              />
              <CustomButton
                className="attent_form_btn"
                title="RESERVE MY SEAT"
              />
            </div>
          </div>
          <div className="text_sec">
            <h2 className="heading">Register to Attend</h2>
            <p className="premier_date">September 23-26 2022</p>
            <p className="premier_addr">
              Premier Business Center | Melbourne, Australia
            </p>
            <p className="premier_desc">
              We can't wait to see you in Melbourne this September! The Eventer
              Conference 2022 fee starts from $429 per person, but if you buy 4
              passes, you get one free!
            </p>
            <div className="premier_countDown">
              <p>
                <span>00</span>
                <span>Days</span>
              </p>
              <p>
                <span>00</span>
                <span>Hours</span>
              </p>
              <p>
                <span>00</span>
                <span>Minutes</span>
              </p>
              <p>
                <span>00</span>
                <span>Seconds</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSection;
