export const FOLLOW_REQUEST_SEND = "FOLLOW_REQUEST_SEND";
export const FOLLOW_REQUEST_SUCCESS = "FOLLOW_REQUEST_SUCCESS";
export const FOLLOW_REQUEST_FAIL = "FOLLOW_REQUEST_FAIL";

export const UNFOLLOW_REQUEST = "UNFOLLOW_REQUEST";
export const UNFOLLOW_SUCCESS = "UNFOLLOW_SUCCESS";
export const UNFOLLOW_FAIL = "UNFOLLOW_FAIL";

export const VIEW_FRIEND_PROFILE_REQUEST = "VIEW_FRIEND_PROFILE_REQUEST";
export const VIEW_FRIEND_PROFILE_SUCCESS = "VIEW_FRIEND_PROFILE_SUCCESS";
export const VIEW_FRIEND_PROFILE_FAIL = "VIEW_FRIEND_PROFILE_FAIL";
export const CLEAR_FIREND_PROFILE_ERROR = "CLEAR_FIREND_PROFILE_ERROR";
export const CLEAR_FIREND_PROFILE_STATE = "CLEAR_FIREND_PROFILE_STATE";

export const GET_FOLLOWER_REQUEST = "GET_FOLLOWER_REQUEST";
export const GET_FOLLOWER_SUCCESS = "GET_FOLLOWER_SUCCESS";
export const GET_FOLLOWER_FAIL = "GET_FOLLOWER_FAIL";

export const REMOVE_FOLLOWER_REQUEST = "REMOVE_FOLLOWER_REQUEST";
export const REMOVE_FOLLOWER_SUCCESS = "REMOVE_FOLLOWER_SUCCESS";
export const REMOVE_FOLLOWER_FAIL = "REMOVE_FOLLOWER_FAIL";

export const CLEAR_DELETE_FRIEND_STATE = "CLEAR_DELETE_FRIEND_STATE";
export const CLEAR_FOLLOW_ERROR = "CLEAR_FOLLOW_ERROR";
export const CLEAR_FOLLOW_STATE = "CLEAR_FOLLOW_STATE";
