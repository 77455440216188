// this function job is to update all peerConnections (addTracks) and update redux callStatus

const startAudioStream = (streams) => {
  const localStream = streams.localStream;
  console.log("localStream", localStream);
  for (const s in streams) {
    // s is the key
    if (s !== "localStream") {
      // We dont add tracks in localStream
      const curStream = streams[s];
      // addTracks to all  peerConnections
      localStream.stream.getAudioTracks().forEach((t) => {
        curStream.peerConnection.addTrack(t, localStream.stream);
      });
    }
  }
};

export default startAudioStream;
