import axios from "axios";
import {
  CONNECTION_REQUEST_FAIL,
  CONNECTION_REQUEST_SEND,
  CONNECTION_REQUEST_SUCCESS,
  ACCEPT_CONNECTION_FAIL,
  REJECT_CONNECTION_FAIL,
  REJECT_CONNECTION_REQUEST,
  REJECT_CONNECTION_SUCCESS,
  ACCEPT_CONNECTION_REQUEST,
  ACCEPT_CONNECTION_SUCCESS,
  REMOVE_CONNECTION_REQUEST,
  REMOVE_CONNECTION_SUCCESS,
  REMOVE_CONNECTION_FAIL,
  CLEAR_CONNECTION_ERROR,
  CLEAR_CONNECTION_STATE,
  CLEAR_REMOVE_CONNECTION_STATE,
} from "../constants/connectConstant";

// SEND REQUEST
export const sendConnectRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONNECTION_REQUEST_SEND,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/add-connection/${id}`,
      config
    );

    dispatch({
      type: CONNECTION_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONNECTION_REQUEST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// ACCEPT REQUEST
export const acceptConnectionRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACCEPT_CONNECTION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/accept-request/${id}`,
      config
    );

    dispatch({
      type: ACCEPT_CONNECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCEPT_CONNECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// REJECT REQUEST
export const rejectConnectionRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_CONNECTION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/reject-request/${id}`,
      config
    );

    dispatch({
      type: REJECT_CONNECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REJECT_CONNECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// REMOVE CONNECTION REQUEST
export const removeConnectionRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_CONNECTION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/remove-connection/${id}`,
      config
    );

    dispatch({
      type: REMOVE_CONNECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_CONNECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearConnectionErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CONNECTION_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearConnectionState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CONNECTION_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearRemoveConnectionState = () => async (dispatch) => {
  dispatch({ type: CLEAR_REMOVE_CONNECTION_STATE });
};
