import React, { useState } from "react";
import "../../styles/franchiseNav.scss";
import { CiGlobe } from "react-icons/ci";
import { logo_main, logo_mobile } from "../../assets/images";
import { Link } from "react-router-dom";
import { useLocale } from "../../context/locale";
import LangChangeModal from "../../components/reusable/LangChangeModal";

const FranchiseNav = () => {
  const [langModalOpener, setLangModalOpener] = useState(false);
  const [t, changeLang] = useLocale(); // FOR CHANGING LANGUAGE FROM CONTEXT

  const localStorageLang = localStorage.getItem("lang");

  const changeLangByParam = (param) => {
    changeLang(param);
  };
  return (
    <div className="franchise_nav_main">
      <div className="container">
        <div className="navbar_section">
          <div className="nav_logo">
            <Link to="/">
              <img src={logo_main} alt="logo_main" className="logo_main" />
              <img
                src={logo_mobile}
                alt="logo_mobile"
                className="logo_mobile"
              />
            </Link>
          </div>
          <div className="links">
            <button
              className="lang_button"
              onClick={() => setLangModalOpener(true)}
            >
              <CiGlobe />
            </button>
          </div>
        </div>
      </div>

      {/* LANGUAGE MODAL START */}
      {langModalOpener && (
        <LangChangeModal
          setLangModalOpener={setLangModalOpener}
          langChanger={changeLangByParam}
          localStorageLang={localStorageLang}
        />
      )}

      {/* LANGUAGE MODAL ENDS */}
    </div>
  );
};

export default FranchiseNav;
