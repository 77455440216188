import React, { Fragment, useEffect, useState } from "react";
import "../../styles/arTeacherProfile.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/userContext";
import { clearErrors, clearUpdateState, removeAvatar,  userProfile } from "../../redux/actions/userAction";
import { toast } from "react-toastify";
import Loader from "../../components/reusable/Loader";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import Layout from "../../components/reusable/Layout";
import { arTeacherSidebarLinks } from "./arTeacherLinks";
import { FaExpeditedssl, FaUpload, FaUserEdit } from "react-icons/fa";
import CustomInput from "../../components/reusable/CustomInput";
// import CustomSelect from "../../components/reusable/CustomSelect";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { RiDeleteBinLine } from "react-icons/ri";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import UploadProfilePicForm from "../../components/student/UploadProfilePicForm";
import { defaultUser } from "../../assets/images";
import CustomMultiSelect from "../../components/reusable/CustomMultiSelect";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import CustomSelect from "../../components/reusable/CustomSelect";

const ArTeacherProfile = () => {
    const [uploadProfileModal, setUploadProfileModal] = useState(false);

    const dispatch = useDispatch();
    const [auth] = useAuth();
    const { loading } = useSelector((state) => state.profile);
    const {
        loading: updateLoading,
        userInfo,
        error: updateError,
    } = useSelector((state) => state.updateProfile);
    const [editable, setEditable] = useState(false);

    // GET LOCALSTORAGE DATA
    const localStorageData = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

    // UPDATE PROFILE STATE
    const [profileData, setProfileData] = useState({
        first_name: auth?.user?.first_name,
        middle_name: auth?.user?.middle_name,
        last_name: auth?.user?.last_name,
        email: auth?.user?.email,
        phone: auth?.user?.phone,
        dob: auth?.user?.dob,
        gender: auth?.user?.gender,
        address: auth?.user?.address,
        fatherName: auth?.user?.fatherName,
        motherName: auth?.user?.motherName,
        parentEmail: auth?.user?.parentEmail,
        parentWhatsAppNo: auth?.user?.parentWhatsAppNo,
    });

    // INPUT CHNAGE HANDLER
    const handleInputChange = (e) => {
        const { value, name } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    // PROFILE UPDATE HANDLER
    const handleProfileUpdate = () => {
        // dispatch(
        //     updateUserProfile({
        //         first_name: profileData.first_name,
        //         middle_name: profileData.middle_name,
        //         last_name: profileData.last_name,
        //         email: profileData.email,
        //         phone: profileData.phone,
        //         dob: profileData.dob,
        //         gender: profileData.gender,
        //         address: profileData.address,
        //         fatherName: profileData.fatherName,
        //         motherName: profileData.motherName,
        //         parentEmail: profileData.parentEmail,
        //         parentWhatsAppNo: profileData.parentWhatsAppNo,
        //     })
        // );
    };

    useEffect(() => {
        if (userInfo?.success) {
            setEditable(false);
            toast.success(userInfo?.message);
            setTimeout(() => {
                dispatch(userProfile(localStorageData?.token)); // GET UPDATED USER DETAILS
                dispatch(clearUpdateState());
            }, 3000);
        }
        if (updateError) {
            toast.error(updateError);
            dispatch(clearErrors());
        }
    }, [
        dispatch,
        localStorageData?.token,
        userInfo?.success,
        userInfo?.message,
        updateError,
    ]);

    // DELETE PROFILE PIC
    const {
        loading: removeProfileLoading,
        avatarInfo: removeProfileInfo,
        error: removeProfileError,
    } = useSelector((state) => state.removeAvatar);

    const deleteProfilePic = () => {
        dispatch(removeAvatar());
    };

    useEffect(() => {
        if (removeProfileError) {
            toast.error(removeProfileError);
            dispatch(clearErrors());
        }
        if (removeProfileInfo?.success) {
            toast.success(removeProfileInfo?.message);
            setTimeout(() => {
                dispatch(userProfile()); // GET UPDATED USER DETAILS
                dispatch(clearUpdateState());
            }, 3000);
        }
    }, [
        dispatch,
        removeProfileError,
        removeProfileInfo?.message,
        removeProfileInfo?.success,
    ]);

    const location = useLocation();
    const navigate = useNavigate();

    // console.log("location", location?.state);

    useEffect(() => {
        if (location?.state === "Student") {
            navigate("/courses");
        }
    }, [location?.state, navigate]);

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Layout>
                    <ProfileLayout sidebarLinks={arTeacherSidebarLinks}>
                        <section className="arTeacher_profile_section txt_color">
                            {/* <div className="container"> */}
                            <h2 className="profilePage_heading">Account Info</h2>

                            <div className="user_details_main">
                                <div className="user_pro_data">
                                    <h2>
                                        Profile{" "}
                                        <button
                                            className={editable ? "activeBtn" : ""}
                                            onClick={() => setEditable(!editable)}
                                        >
                                            {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                                        </button>
                                    </h2>
                                    <div>
                                        <div className="data_card">
                                            <CustomInput
                                                label="Full Name"
                                                type="text"
                                                placeholder="Full Name"
                                                impStar={editable ? "*" : ""}
                                                name="first_name"
                                                value={
                                                    editable
                                                        ? profileData?.first_name
                                                        : auth.user?.first_name
                                                }
                                                readOnly={editable ? false : true}
                                                onChange={handleInputChange}
                                                borderColor={editable ? "#f2f2f2" : ""}
                                            />
                                            <CustomSelect
                                                label="Gender"
                                                classStyle={"select_gender"}
                                                type="text"
                                                placeholder="Gender"
                                                name="middle_name"
                                                value={
                                                    editable
                                                        ? profileData?.middle_name
                                                        : auth.user?.middle_name
                                                }
                                                readOnly={editable ? false : true}
                                                onChange={handleInputChange}
                                                borderColor={editable ? "#f2f2f2" : ""}
                                            />
                                         
                                            
                                             <CustomMultiSelect
                                                label="Language Name"
                                                type="text"
                                                placeholder="Language Name"
                                                name="last_name"
                                                value={
                                                    editable
                                                        ? profileData?.last_name
                                                        : auth.user?.last_name
                                                }
                                                readOnly={editable ? false : true}
                                                onChange={handleInputChange}
                                                borderColor={editable ? "#f2f2f2" : ""}
                                            />
                                            <CustomTextarea
                                                label="Bio"
                                                type="text"
                                                fullWidth={"100%"}
                                                placeholder="Bio"
                                                name="last_name"
                                                value={
                                                    editable
                                                        ? profileData?.last_name
                                                        : auth.user?.last_name
                                                }
                                                readOnly={editable ? false : true}
                                                onChange={handleInputChange}
                                                borderColor={editable ? "#f2f2f2" : ""}
                                            />



                                            {editable && (
                                                <CustomButton
                                                    className="pro_update_btn"
                                                    title={updateLoading ? <ButtonLoader /> : `Update`}
                                                    width="100px"
                                                    onClick={handleProfileUpdate}
                                                />
                                            )}
                                        </div>
                                        <div className="profile_picSec">
                                            <div className="user_pro_pic">
                                                <button
                                                    className="absolute_btn deleteImage_btn"
                                                    title="Remove Profile Picture"
                                                    onClick={() => deleteProfilePic()}
                                                >
                                                    {removeProfileLoading ? (
                                                        <ButtonLoader />
                                                    ) : (
                                                        <RiDeleteBinLine />
                                                    )}
                                                </button>
                                                <ImgLazyLoad
                                                    src={
                                                        auth?.user?.profile?.url
                                                            ? auth?.user?.profile?.url
                                                            : defaultUser
                                                    }
                                                    alt="alpha regiment user"
                                                />
                                                <button
                                                    title="Upload Profile Picture"
                                                    className="absolute_btn uploadImage_btn"
                                                    onClick={() => setUploadProfileModal(true)}
                                                >
                                                    <FaUpload />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </section>
                        {/* UPLOAD PROFILE PICTURE FORM START */}
                        {uploadProfileModal && (
                            <section className="upload_profile_pic_main">
                                <UploadProfilePicForm
                                    setUploadProfileModal={setUploadProfileModal}
                                />
                            </section>
                        )}

                        {/* UPLOAD PROFILE PICTURE FORM ENDS */}
                    </ProfileLayout>
                </Layout>
            )}
        </Fragment>
    );
};

export default ArTeacherProfile;