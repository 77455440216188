import React, { Fragment, useEffect } from "react";
import StudentLayout from "../../components/student/StudentLayout";
import StudentFooter from "../../components/student/StudentFooter";
import "../../styles/enrollCourses.scss";
import CustomButton from "../../components/reusable/CustomButton";
// import { useNavigate } from "react-router-dom";
import { sidebarLinks } from "./studentsSidebarLinks";
import { useAuth } from "../../context/userContext";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import { noEnroll } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clearChatError,
  clearChatState,
  createNewConversation,
} from "../../redux/actions/chatAction";
import { useSelector } from "react-redux";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { useNotification } from "../../context/notificationContext";
import { useChatAuth } from "../../context/chatContext";
const EnrollCoures = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotfication] = useNotification();
  // JOIN BOTH COURSES ARRAY
  const courses = auth?.user?.courses || [];
  const examPrepCourses = auth?.user?.examPrepCourses || [];

  // Concatenating both arrays into a new array
  const allCourses = courses?.concat(examPrepCourses);

  const langParam = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";

  const handleNavigateDashboard = (id) => {
    navigate(`/student/lms/${id}`);
  };
  const { person, setPerson } = useChatAuth();
  const handleChatOpen = (item) => {
    setPerson(item);
    dispatch(createNewConversation(item?._id));
  };

  const { loading, error, conversation } = useSelector(
    (state) => state.createConversation
  );

  useEffect(() => {
    if (error) {
      showNotfication("error", error);
      dispatch(clearChatError());
    }
    if (conversation?.success) {
      showNotfication("success", conversation?.message);
      dispatch(clearChatState());
      navigate(
        `/chat-interface?chatId=${conversation?.conversation?._id}`
      );
    }
  }, [
    dispatch,
    navigate,
    showNotfication,
    error,
    conversation?.success,
    conversation?.message,
    conversation?.conversation?._id,
  ]);

  return (
    <Fragment>
      <StudentLayout sidebarLinks={sidebarLinks}>
        <div className="enroll_courses">
          <h2>Enrolled Courses</h2>
          {allCourses && allCourses.length > 0 ? (
            <div className="enroll_item_list">
              {allCourses.map((item) => (
                <div className="cart_item" key={item?._id}>
                  <div className="item_thumb">
                    <img
                      src={item?.course?.thumbnail?.url}
                      alt={item?.course?.title}
                    />
                  </div>
                  <div className="item_name">
                    <div className="item_details">
                      <div className="title">
                        {langParam === "en"
                          ? item?.course?.title
                          : item?.course?.titleHindi}
                      </div>
                      <p>By : {item?.course?.createdBy?.first_name}</p>
                    </div>
                  </div>
                  <div className="item_action">
                    {item?.course?.category === "Mock Interview" ||
                    item?.course?.category === "Mock Psychology" ? (
                      <CustomButton
                        className="enroll_btn"
                        title={loading ? <ButtonLoader /> : "Schedule/Chat"}
                        width="200px"
                        onClick={() => handleChatOpen(item?.course?.createdBy)}
                      />
                    ) : (
                      <CustomButton
                        className="enroll_btn"
                        title={loading ? <ButtonLoader /> : "View Dashboard"}
                        width="200px"
                        onClick={() =>
                          handleNavigateDashboard(item?.course?._id)
                        }
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <OfficerNoDataCard
              txt={"You have not signed up for any courses as of now."}
              icon=""
              image={noEnroll}
              isButton={true}
            />
          )}

          {auth?.user?.doctorConsult?.length > 0 && (
            <h2 style={{ marginTop: "30px" }}>Medical Checkups</h2>
          )}

          <div className="enroll_item_list">
            {auth?.user?.doctorConsult?.map((item, idx) => (
              <div className="coach_card" key={idx}>
                <div className="dr_img">
                  <img src={item?.doctor?.profile?.url} alt="coach_img" />
                </div>

                <div className="coach_details">
                  <h2>{item?.doctor?.first_name}</h2>
                  <p>{item?.doctor?.teacherBio?.slice(0, 100) + ".."}</p>

                  <div className="cart_btns">
                    <CustomButton
                      className="enroll_btn"
                      title="Schedule/Chat"
                      width="200px"
                      onClick={() => handleChatOpen()}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </StudentLayout>
      {/* FOOTER START */}
      <StudentFooter />
      {/* FOOTER ENDS */}
    </Fragment>
  );
};

export default EnrollCoures;
