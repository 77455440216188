import React, { useState } from "react";
import "../styles/termCondition.scss";
import { Link, useNavigate, NavLink } from "react-router-dom";
// import { logo_main } from "../assets/images";
import logo from "../images/logo_main.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomHelmet from "../components/reusable/Helmet";
// import { useAuth } from "../context/userContext";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { pageLinks } from "../components/home/pageLinks";
const CyberPartnerTerm = () => {
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = useState(false);
  // const [auth] = useAuth();
  return (
    <section className="privacy_policy_main txt_color">
      <CustomHelmet
        keywords="Cyber Term and Condition Alpha Regiment"
        description="Explore the terms and conditions for becoming a cyber partner with Alpha Regiment. Join us and increase your earnings."
        author="Team Alpha Regiment"
        title="Cyber Partner Term & Condition."
        canonical={window?.location?.href}
      />

      <div className="w_bg">
        <div className="top_bar">
          <div className="go_back">
            <Link onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft />
            </Link>
            <p>Cyber Partner Term</p>
          </div>
          <img src={logo} alt="logo" />
        </div>

        <section className="policy_layout_main">
          <button
            className="open_close_sidebar"
            onClick={() => setShowSideNav(!showSideNav)}
          >
            {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
          </button>
          <div
            className={`profile_sidebar ${
              showSideNav ? "showProfile_sidebar" : ""
            }`}
          >
            {/* <button
                            className="open_close_sidebar navClose_btn"
                            onClick={() => setShowSideNav(false)}
                        >
                            <RiCloseFill />
                        </button> */}
            <div className="sidebar_content">
              {pageLinks?.map((item) => {
                return (
                  <div className="profile_sidebarLink" key={item.id}>
                    <NavLink to={item?.link}>{item?.title}</NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_content">
            <div className="privacy_content">
              <div className="title">
                <h1>CYBER PARTNER TERMS</h1>
                <p>Last updated: March 1, 2024</p>
              </div>
              <div className="privacy_text">
                <p>
                  Welcome to the Alpha Regiment Cyber Partner Program! We are
                  excited to have you on board as a partner in promoting our
                  courses to students. Please read and agree to the following
                  terms and conditions:
                </p>
                <h3 className="sub_heading">1. Banner Placement</h3>
                <p>
                  As a Cyber Partner, you are required to display the Alpha
                  Regiment banner prominently in your cyber cafe. The banner
                  must not be removed or tampered with at any time. Failure to
                  comply with this will result in the discontinuation of our
                  partnership.
                </p>
                <h3 className="sub_heading">2. Honesty and Integrity</h3>
                <p>
                  It is important to provide accurate and truthful information
                  about Alpha Regiment courses to students. Misleading students
                  or providing false information is strictly prohibited.
                </p>
                <h3 className="sub_heading">3. Commission Structure</h3>
                <p>
                  Cyber Partners will receive a commission of 5% on the total
                  course fees paid by students referred through their coupon
                  code. Alpha Regiment reserves the right to revise this
                  commission percentage at any time with advance notice.
                </p>
                <h3 className="sub_heading">4. Privacy and Data Security</h3>
                <p>
                  The privacy and security of our users, including Cyber
                  Partners, are of utmost importance to us. All data provided by
                  Cyber Partners will be stored securely. Bank details are
                  required for commission transfers and will be used solely for
                  this purpose.
                </p>
                <h3 className="sub_heading">5. Commission Withdrawal</h3>
                <p>
                  When a Cyber Partner requests a withdrawal of earned
                  commissions, it may take up to 10 working days for the amount
                  to be visible in their bank account. Please be patient as we
                  process your request.
                </p>
                <p>
                  By agreeing to these terms, you acknowledge that you have read
                  and understood the guidelines set forth by Alpha Regiment. We
                  are committed to providing a fair and transparent partnership
                  program for Cyber Partners.
                </p>
                <p>
                  Thank you for being a part of the Alpha Regiment Cyber Partner
                  Program. Let's work together to empower students through
                  quality education and training!
                </p>
              </div>
            </div>
          </div>
        </section>
        <Link to="/" className="goHome_btn">
          Home
        </Link>
      </div>
    </section>
  );
};

export default CyberPartnerTerm;
