import React, { Fragment, useEffect, useState } from "react";
import StudentLayout from "../../components/student/StudentLayout";
import { sidebarLinks } from "./studentsSidebarLinks";
import CustomButton from "../../components/reusable/CustomButton";
import { defaultUser, workshop_hero } from "../../assets/images";
import { FaRegThumbsUp } from "react-icons/fa";
import moment from "moment";
import { CgCalendarDates } from "react-icons/cg";
import { Link } from "react-router-dom";
import {
  clearOfficerErrors,
  getAllTimelinePostAction,
  likeTimelinePostAction,
} from "../../redux/actions/officerAction";
import { useSelector } from "react-redux";
import {
  clearFollowErrors,
  getFollowerList,
} from "../../redux/actions/followAction";
import { useNotification } from "../../context/notificationContext";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/userContext";
import { BiGlobeAlt } from "react-icons/bi";
import "../../styles/studentChatbox.scss";
import ComponentLoading from "../../components/reusable/ComponentLoading";
const TextWithLinks = ({ value }) => {
  const renderTextWithLinks = () => {
    return value.split(/\s+/).map((word, index) => {
      if (isURL(word)) {
        return (
          <a href={word} key={index} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else {
        return <span key={index}>{word} </span>;
      }
    });
  };

  const isURL = (str) => {
    const pattern =
      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$/i;
    return pattern.test(str);
  };

  return <div>{renderTextWithLinks()}</div>;
};

const StudentChatbox = () => {
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [auth] = useAuth();
  const user = auth?.user;
  const [likeData, setLikeData] = useState([]);
  // GET ALL OFFICER LIST
  const joinedTime = moment(auth?.user?.createdAt).format("MMMM YYYY");
  // GET ALL FOLLOWERS
  const {
    loading: followerLoading,
    error: followerError,
    followList,
  } = useSelector((state) => state.followerList);

  // FILTER OFFICER WHICH ARE ALREADY FOLLOW

  useEffect(() => {
    dispatch(getFollowerList());
  }, [dispatch]);

  useEffect(() => {
    if (followerError) {
      dispatch(clearFollowErrors());
    }
  }, [followerError, showNotification, dispatch]);

  const {
    error: getPostListError,
    loading: getPostListLoading,
    postData: postList,
  } = useSelector((state) => state.getAllPostTimeline);
  useEffect(() => {
    dispatch(getAllTimelinePostAction());
  }, [dispatch]);

  useEffect(() => {
    if (getPostListError) {
      dispatch(clearOfficerErrors());
    }
  }, [getPostListError, dispatch]);

  const handlePostLike = (id) => {
    const filterId = likeData?.includes(id);

    if (filterId) {
      const filterData = likeData?.filter((itm) => itm !== id);
      setLikeData(filterData);
    } else {
      setLikeData((prevData) => [...prevData, id]);
    }

    dispatch(likeTimelinePostAction(id));
  };

  const handleViewFriend = (itm) => {
    // console.log(itm);
  };

  return (
    <Fragment>
      <StudentLayout sidebarLinks={sidebarLinks}>
        <div className="student_timeline_main txt_color">
          <div className="title">
            {/* <button
              title="Go Back"
              onClick={() => navigate("/officer/profile")}
            >
              <BiLeftArrowAlt />
            </button> */}
            {user?.first_name}'s Timeline
          </div>
          <div className="container">
            <div className="student_timeline_content">
              <div className="officerProfile">
                {/* // PROFILE CONTENT START */}
                <div className="profileCover">
                  <img src={workshop_hero} alt="alpha officer profile cover" />
                </div>
                <div className="profilePic">
                  <img
                    src={user?.profile?.url ? user?.profile?.url : defaultUser}
                    alt="alpha officer profile"
                  />
                </div>
                <div className="userDetails">
                  <div className="buttonGrp">
                    <div className="follow_tab">
                      <Link to="/student/following">{`Following (${
                        followList?.follows && followList?.follows?.followings
                          ? followList?.follows?.followings?.length
                          : 0
                      })`}</Link>
                    </div>
                    {/* <div className="shareBtn">
                      <CustomButton
                        className="shareButton"
                        preIcon={<BsShareFill />}
                        tooltip="Share Your Profile"
                      />
                    </div> */}
                  </div>
                  <div className="general">
                    <h2 className="name">{user?.first_name}</h2>
                  </div>
                </div>
                <div className="locationBirth">
                  {/* <p>
                    <MdLocationOn /> Gurgaon, India
                  </p> */}
                  <p>
                    <BiGlobeAlt /> {moment(user?.dob).format("MMMM DD[,] YYYY")}
                  </p>
                  <p>
                    <CgCalendarDates /> Joined {joinedTime}
                  </p>
                </div>
                <div className="timeline_tabs">
                  <div className="post_list">
                    {getPostListLoading ? (
                      <ComponentLoading />
                    ) : (
                      postList?.allPosts?.map((itm) => (
                        <div className="post_card" key={itm?._id}>
                          <img
                            src={
                              itm?.createdBy?.profile?.url
                                ? itm?.createdBy?.profile?.url
                                : defaultUser
                            }
                            alt={itm?.createdBy?.first_name}
                          />
                          <div className="post_details">
                            <div className="title">
                              <div className="name">
                                <p onClick={() => handleViewFriend(itm)}>
                                  {itm?.createdBy?.first_name}
                                </p>
                                <span>
                                  {moment(itm?.createdAt).format(
                                    "MMMM DD[,] YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                            <TextWithLinks value={itm?.label} />
                            {itm?.thumbnail?.url !== "" && (
                              <img src={itm?.thumbnail?.url} alt="postimage" />
                            )}
                            <div className="post_footer">
                              <CustomButton
                                title={`${
                                  itm?.likes?.length > 0
                                    ? itm?.likes?.length
                                    : ""
                                } Like`}
                                className={`like_btn ${
                                  likeData?.includes(itm._id) ||
                                  (itm?.likes &&
                                    itm.likes.some(
                                      (like) => like._id === auth?.user?._id
                                    ))
                                    ? "active"
                                    : ""
                                }`}
                                width="100px"
                                onClick={() => handlePostLike(itm?._id)}
                                icon={<FaRegThumbsUp />}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                {/* // PROFILE CONTENT ENDS */}
              </div>
            </div>
          </div>
        </div>
      </StudentLayout>
    </Fragment>
  );
};

export default StudentChatbox;
