import React from "react";
import styled from "styled-components";
import CustomButton from "../../components/reusable/CustomButton";
import { useNavigate } from "react-router-dom";
const Wrapper = styled.div`
  // width:400px;
  border-radius: 12px;
  display: flex;
  margin: auto;
  justify-content: center;
  color: #f2f2f2;
  box-sizing: border-box;
  padding: 30px;
  background: linear-gradient(
    115.85deg,
    rgba(255, 255, 255, 0.4) -185.19%,
    rgba(255, 255, 255, 0) 129.07%
  );
  backdrop-filter: blur(21px);

  @media (max-width: 767px) {
    padding: 10px;
  }
  > .data_txt {
    text-align: center;
    h2 {
      color: #f2f2f2;
      letter-spacing: 1px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        padding: 10px;
      }
      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
    .icon {
      color: #f2f2f2;

      > svg {
        font-size: 32px;
        margin-bottom: 30px;
      }
    }
    .noDataImg {
      max-width: 220px;
    }
    > div {
      display: flex;
      justify-content: center;
    }
  }
`;
const OfficerNoDataCard = ({ txt, icon, isButton, image }) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="data_txt">
        {icon && <span className="icon">{icon}</span>}
        {image && <img className="noDataImg" src={image} alt="no data" />}
        <h2>{txt}</h2>
        {isButton && (
          <div>
            <CustomButton
              className="enroll_btn"
              title="Explore Courses"
              onClick={() => navigate("/courses")}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default OfficerNoDataCard;
