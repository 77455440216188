import React from "react";
import CustomButton from "../components/reusable/CustomButton";
import { useLocation, useParams } from "react-router-dom";
const MeetingJoin = () => {
  const { state } = useLocation();
  const { role } = useParams();
  const CLIENT_ID = "j384agYPRY6f9JIgyignAw";
  const CLIENT_SECRET = "keDIxWMmVgKyh8en1gzvMyscnFIYYeM7";
  const meetingData = {
    mn: state?.meetingId,
    psvrd: state?.password,
    hEmail: state?.purpose,
    leaveUrl: "http://localhost:3000",
    role: role,
  };

  const signature = async () => {
    try {
      const { ZoomMtg } = await import("@zoom/meetingsdk");
      ZoomMtg.generateSDKSignature({
        meetingNumber: meetingData?.mn,
        sdkKey: CLIENT_ID,
        sdkSecret: CLIENT_SECRET,
        role: meetingData?.role,
        success: function (res) {
          // setSignatureValue(res);
          startMeeting(res);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(signature);
  async function startMeeting(signatureId) {
    try {
      const { ZoomMtg } = await import("@zoom/meetingsdk");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      ZoomMtg.init({
        leaveUrl: meetingData?.leaveUrl,
        patchJsMedia: true,
        disableInvite: true,
        disableCallOut: true,
        disableJoinAudio: true,
        disableRecord: true,
        screenShare: false,
        isSupportAV: true,
        meetingInfo: ["topic", "host"],
        disableRecord: true,
        success: (success) => {
          ZoomMtg.join({
            signature: signatureId,
            sdkKey: CLIENT_ID,
            meetingNumber: meetingData?.mn,
            passWord: meetingData?.psvrd,
            userName: "user",
            userEmail: "user@gmail.com",
            tk: "",
            zak: "",
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="zoom_container">
      <CustomButton onClick={() => signature()}>Meetin Join</CustomButton>;
    </div>
  );
};

export default MeetingJoin;
