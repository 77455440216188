import axios from "axios";
import {
  CLEAR_ERRORS,
  CLEAR_PROFILE_ERRORS,
  CLEAR_STATE,
  CLEAR_USER_LOADING,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  CLEAR_UPDATE_STATE,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  REMOVE_AVATAR_REQUEST,
  REMOVE_AVATAR_SUCCESS,
  REMOVE_AVATAR_FAIL,
  AFFILIATE_DASHBOARD_REQUEST,
  AFFILIATE_DASHBOARD_SUCCESS,
  AFFILIATE_DASHBOARD_FAIL,
  GET_AFFILIATE_DASHBOARD_SUCCESS,
  GET_AFFILIATE_DASHBOARD_REQUEST,
  GET_AFFILIATE_DASHBOARD_FAIL,
  COUPON_CODE_REQUEST,
  COUPON_CODE_SUCCESS,
  COUPON_CODE_FAIL,
  CLEAR_COUPON_CODE,
  GET_ENROLLED_USERS_REQUEST,
  GET_ENROLLED_USERS_SUCCESS,
  GET_ENROLLED_USERS_FAIL,
  GET_AFF_NOTIFICATION_REQUEST,
  GET_AFF_NOTIFICATION_SUCCESS,
  GET_AFF_NOTIFICATION_FAIL,
  GET_DOWNLOAD_NOTIFICATION_REQUEST,
  GET_DOWNLOAD_NOTIFICATION_SUCCESS,
  GET_DOWNLOAD_NOTIFICATION_FAIL,
  USER_LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  ENROLL_STUDENT_LIST_REQUEST,
  ENROLL_STUDENT_LIST_SUCCESS,
  ENROLL_STUDENT_LIST_FAIL,
  COURSE_COUPON_CREATE_REQUEST,
  COURSE_COUPON_CREATE_SUCCESS,
  COURSE_COUPON_CREATE_FAIL,
  CLEAR_CREATE_COUPON_STATE,
  USER_DETAILS_BY_ID_REQUEST,
  USER_DETAILS_BY_ID_SUCCESS,
  USER_DETAILS_BY_ID_FAIL,
  USER_LIST_BY_ROLE_REQUEST,
  USER_LIST_BY_ROLE_SUCCESS,
  USER_LIST_BY_ROLE_FAIL,
} from "../constants/userConstant";
import { axiosInstance } from "../../utils/axiosConfig";

//   FOR USER REGISTRATION
export const userRegisterAction =
  (
    first_name,
    last_name,
    email,
    phone,
    password,
    profile,
    role,
    teacherBio,
    subSpec,
    assignSubjects,
    timeTable,
    document
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/register`,
        {
          first_name,
          last_name,
          email,
          phone,
          password,
          role,
          teacherBio,
          subSpec,
          assignSubjects,
          timeTable,
          profile,
          document,
        },
        config
      );
      dispatch({
        type: REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REGISTER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const login = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/login`,
      {
        email,
        password,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(
      "alphaToken",
      JSON.stringify(getState().userLogin?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    localStorage.removeItem("alphaToken");

    dispatch({ type: CLEAR_STATE });

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/profile`,
      config
    );

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER BY ID
export const userDeatilsById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_BY_ID_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/get-user-details/${id}`,
      config
    );

    dispatch({
      type: USER_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET OFFICER LIST
export const officerListAction = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/all-users`,
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER LIST BY ROLE

export const userListByRoleAction = (roleId) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_BY_ROLE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/user-by-role?roleId=${roleId}
      `,
      config
    );

    dispatch({
      type: USER_LIST_BY_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_BY_ROLE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CREATE COURSE COUPON REQUEST
export const createCourseCouponAction =
  (id, couponPercentage, couponValidity) => async (dispatch) => {
    try {
      dispatch({
        type: COURSE_COUPON_CREATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/course/${id}`,
        { couponPercentage, couponValidity },
        config
      );

      dispatch({
        type: COURSE_COUPON_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_COUPON_CREATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE COURSE COUPON REQUEST
export const deleteCourseCouponAction = (id, coupons) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_COUPON_CREATE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API}/course/${id}`,
      { coupons },
      config
    );

    dispatch({
      type: COURSE_COUPON_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_COUPON_CREATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET Enroll Student by officer id LIST
export const enrollStudentListAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ENROLL_STUDENT_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/enrolled-students/${id}`,
      config
    );

    dispatch({
      type: ENROLL_STUDENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ENROLL_STUDENT_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER PROFILE DETAILS FROM GOOGLE LOGIN
export const userGoogleProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const { data } = await axiosInstance.get("/me", {
      withCredentials: true,
    });

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER PROFILE
export const updateUserProfile =
  (
    first_name,
    middle_name,
    last_name,
    email,
    phone,
    dob,
    address,
    gender,
    profile,
    fatherName,
    motherName,
    parentEmail,
    teacherBio,
    subSpec,
    assignSubjects,
    aff_occupation,
    aff_state,
    aff_bank_name,
    aff_bank_ifsc,
    aaff_bank_acc_no,
    aaff_bank_branch,
    afaff_bank_reg_mob,
    aff_upi_id,
    parentWhatsAppNo,
    timeTable,
    facebook,
    instagram,
    twitter,
    whatsapp,
    language
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_UPDATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/update-profile`,
        first_name,
        middle_name,
        last_name,
        email,
        phone,
        dob,
        address,
        gender,
        profile,
        fatherName,
        motherName,
        parentEmail,
        teacherBio,
        subSpec,
        assignSubjects,
        aff_occupation,
        aff_state,
        aff_bank_name,
        aff_bank_ifsc,
        aaff_bank_acc_no,
        aaff_bank_branch,
        afaff_bank_reg_mob,
        aff_upi_id,
        parentWhatsAppNo,
        timeTable,
        facebook,
        instagram,
        twitter,
        whatsapp,
        language,
        config
      );

      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFILE_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE USER PROFILE PICTURE
export const updateAvatar = (profile) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AVATAR_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/upload-profile-pic`,
      { profile },
      config
    );

    dispatch({
      type: UPDATE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// REMOVE USER PROFILE PICTURE
export const removeAvatar = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_AVATAR_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API}/remove-profile-pic`,
      config
    );

    dispatch({
      type: REMOVE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// FORGOT PASSWORD
export const requestForgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const { data } = await axiosInstance.put("/forgot-password", { email });

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// RESET PASSWORD
export const resetPasswordAction = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    const { data } = await axiosInstance.put("/reset-password", {
      token,
      password,
    });

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// SETUP AFFILIATE DASHBOARD
export const setupAffiliateDashboard = () => async (dispatch) => {
  try {
    dispatch({
      type: AFFILIATE_DASHBOARD_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/setup-affiliate-dashboard`,
      {},
      config
    );

    dispatch({
      type: AFFILIATE_DASHBOARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AFFILIATE_DASHBOARD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET AFFILIATE DASHBOARD DATA
export const getAffiliateDashboard = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_AFFILIATE_DASHBOARD_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/affiliate-dashboard`, config);

    dispatch({
      type: GET_AFFILIATE_DASHBOARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_AFFILIATE_DASHBOARD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// VERIFY AFFILIATE COUPON
export const checkCouponCodeValidation = (couponCode) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_CODE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.post(
      `/check-coupon`,
      { couponCode },
      config
    );

    dispatch({
      type: COUPON_CODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COUPON_CODE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET AFFILIATE ENROLLED USERS
export const getEnrolledUsersByAffiliate = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ENROLLED_USERS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-enrolled-users`, config);

    dispatch({
      type: GET_ENROLLED_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ENROLLED_USERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET AFFILIATE NOTIFICATION DATA
export const getAllAffiliateNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_AFF_NOTIFICATION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-notifications`, config);

    dispatch({
      type: GET_AFF_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_AFF_NOTIFICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET AFFILIATE NOTIFICATION DATA
export const getAllDownloadNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOWNLOAD_NOTIFICATION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/get-download-notifications`,
      config
    );

    dispatch({
      type: GET_DOWNLOAD_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DOWNLOAD_NOTIFICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearProfileErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE_ERRORS });
  localStorage.removeItem("alphaToken");
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
// CLEAR CREATE COUPON STATE
export const clearCreateCouponState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CREATE_COUPON_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};

// CLEAR USER LOADING ACTION FUNCTION
export const clearLoading = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_LOADING });
};

// CLEAR USER LOADING ACTION FUNCTION
export const clearCouponCode = () => async (dispatch) => {
  dispatch({ type: CLEAR_COUPON_CODE });
};
