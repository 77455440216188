import React, { Fragment, useEffect, useState } from "react";
import "../../styles/studentCart.scss";
import { sidebarLinks } from "../student/studentsSidebarLinks";
import CustomButton from "../../components/reusable/CustomButton";
// import { GDCourse } from "../../assets/images";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeItemsFromCart } from "../../redux/actions/cartAction";
import { AiOutlineClose } from "react-icons/ai";
import { useCookies } from "react-cookie";
import { useLocale } from "../../context/locale";
import {
  checkCouponCodeValidation,
  clearCouponCode,
} from "../../redux/actions/userAction";
import {
  clearPaymentErrors,
  clearPaymentState,
  getAllPayments,
} from "../../redux/actions/instaMojoAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import CustomInput from "../../components/reusable/CustomInput";
import { BsThreeDots } from "react-icons/bs";
import { emptyCart } from "../../assets/images";
import StudentLayout from "../../components/student/StudentLayout";
import StudentFooter from "../../components/student/StudentFooter";
import { useNotification } from "../../context/notificationContext";
import {
  clearRazorErrors,
  clearRazorState,
  createRazorPayment,
  getRazorKey,
  razorPaymentverification,
} from "../../redux/actions/razorpayAction";
import { useAuth } from "../../context/userContext";

// POPUP FOR COUPON CODE AND PAYMENT
const CouponPaymentPopup = ({
  showCouponPopup,
  setShowCouponPopup,
  courseDetails,
}) => {
  // const navigate = useNavigate()
  const [t] = useLocale();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [couponCode, setCouponCode] = useState("");
  const [showEmiTable, setShowEmiTable] = useState(false);
  const dispatch = useDispatch();

  const payment = courseDetails?.discountedPrice ? courseDetails?.discountedPrice : courseDetails?.orignalPrice;
  const courseId = courseDetails?.id;
  const discountePercentage = courseDetails?.affiliate_percentage;
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [initialData, setInitialData] = useState({});

  const closeModal = () => {
    // window.location.reload();
    dispatch(clearCouponCode());
    setShowCouponPopup(false);
    setShowEmiTable(false);
    setCouponCode("");
  };

  // CHECK COUPON CODE IS VALID OR NOT START
  // CHECK COUPON CODE IS VALID OR NOT START
  const handleCodeCheck = () => {
    if (couponCode) {
      dispatch(checkCouponCodeValidation(couponCode));
    } else {
      showNotification("warning", "Please fill Coupon Code!");
    }
  };

  const { loading, isValidCoupon, error } = useSelector(
    (state) => state.checkCouponCode
  );

  let discountedAmount = payment;
  if (isValidCoupon?.success) {
    const discounteAmount = (payment * discountePercentage) / 100;
    discountedAmount = payment - discounteAmount;
  } else {
    discountedAmount = payment;
  }

  useEffect(() => {
    if (error) {
      setCouponCode("");
    }
  }, [error]);
  // CHECK COUPON CODE IS VALID OR NOT ENDS
  // CHECK COUPON CODE IS VALID OR NOT ENDS

  // GET RAZOR PAY KEY START
  const {
    key: razorKey,
    error: razorKeyError,
    // eslint-disable-next-line
    loading: razorKeyLoading,
  } = useSelector((state) => state.razorKey);

  const razorPaykey = razorKey?.key;

  useEffect(() => {
    dispatch(getRazorKey());
  }, [dispatch]);

  useEffect(() => {
    if (razorKeyError) {
      dispatch(clearRazorErrors());
    }
  }, [dispatch, razorKeyError]);
  // GET RAZOR PAY KEY ENDS

  // CREATE PAYEMENT INSTANCE START
  // CREATE PAYEMENT INSTANCE START
  const {
    payment: razorPayment,
    error: razorError,
    // eslint-disable-next-line
    loading: razorLoading,
  } = useSelector((state) => state.razorPayment);

  const paymentDone = (amount, for_course, couponCode, emiId, seatBooking) => {
    const ssbCourse = courseDetails?.ssbCourse
      ? courseDetails?.ssbCourse
      : false;
    setInitialData({
      for_course,
      ssbCourse,
      amount,
      couponCode,
      emiId,
      seatBooking,
    });
    dispatch(
      createRazorPayment(
        for_course,
        ssbCourse,
        amount,
        couponCode,
        emiId,
        seatBooking
      )
    );
  };

  useEffect(() => {
    if (razorPayment?.success) {
      // INTILSE RAZOR PAY FOR PAYENT POPUP START
      const intialiseRazorPay = (order) => {
        var options = {
          key: razorPaykey,
          amount: order.amount,
          currency: "INR",
          order_id: order.id,
          handler: function (response) {
            setPaymentResponse(response);
          },
          prefill: {
            name: auth?.user?.first_name,
            email: auth?.user?.email,
            contact: auth?.user?.phone,
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      };
      intialiseRazorPay(razorPayment?.order);
      // INTILSE RAZOR PAY FOR PAYENT POPUP ENDS
      dispatch(clearRazorState());
    }
    if (razorError) {
      showNotification("error", razorError);
      dispatch(clearRazorErrors());
    }
  }, [
    dispatch,
    razorPaykey,
    razorError,
    razorPayment?.success,
    razorPayment?.order,
    showNotification,
    auth,
  ]);
  // CREATE PAYEMENT INSTANCE ENDS
  // CREATE PAYEMENT INSTANCE ENDS

  // VERIFY PAYMENT START
  // VERIFY PAYMENT START
  const {
    payment: verifyPayment,
    error: verifyPaymentError,
    // eslint-disable-next-line
    loading: verifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  useEffect(() => {
    if (paymentResponse && initialData) {
      dispatch(
        razorPaymentverification(
          paymentResponse.razorpay_payment_id,
          paymentResponse.razorpay_order_id,
          paymentResponse.razorpay_signature,
          initialData?.for_course,
          initialData?.ssbCourse,
          initialData?.couponCode,
          initialData?.emiId,
          initialData?.seatBooking
        )
      );
    }
  }, [paymentResponse, initialData, dispatch]);

  useEffect(() => {
    if (verifyPayment?.success) {
      showNotification("success", verifyPayment?.message);
      setShowCouponPopup(false);
      dispatch(clearRazorState());
    }
    if (verifyPaymentError) {
      showNotification("error", verifyPaymentError);
      dispatch(clearRazorErrors());
    }
  }, [verifyPayment, showNotification, dispatch, verifyPaymentError]);
  // VERIFY PAYMENT ENDS
  // VERIFY PAYMENT ENDS

  // GET COUPON CODE FROM COOKIES START
  // GET COUPON CODE FROM COOKIES START
  const [cookies] = useCookies([]);

  useEffect(() => {
    const myCookieValue = cookies.alphaCouponCodeCookie;
    setCouponCode(myCookieValue);
  }, [cookies]);
  // GET COUPON CODE FROM COOKIES ENDS
  // GET COUPON CODE FROM COOKIES ENDS

  // GET ALL PAYMENTS DONE BY SELF USER START
  // GET ALL PAYMENTS DONE BY SELF USER START
  const {
    //  eslint-disable-next-line
    loading: allPaymentsLoading,
    allPayments,
    error: allPaymentsError,
  } = useSelector((state) => state.allPaymentsDoneByUser);

  const allPaymentsParam = allPayments?.paymentDetails;

  // CHECK FOR SEAT BOOKING
  const seatBookingFees = 500;
  let isSeatBookingDone = [];
  allPaymentsParam?.forEach((payment) => {
    isSeatBookingDone.push(payment?.seatBooking);
  });

  useEffect(() => {
    dispatch(getAllPayments());
  }, [dispatch]);

  useEffect(() => {
    if (allPaymentsError) {
      // toast.error(allPaymentsError);
      dispatch(clearPaymentErrors());
    }
  }, [allPaymentsError, dispatch]);
  // GET ALL PAYMENTS DONE BY SELF USER ENDS
  // GET ALL PAYMENTS DONE BY SELF USER ENDS

  return (
    <section
      className={`coupon_payment_popup_main bg_color txt_color ${
        showCouponPopup ? "showPaymentModal" : ""
      }`}
    >
      {showCouponPopup && (
        <>
          <button className="close_modal" onClick={() => closeModal()}>
            <AiOutlineClose />
          </button>
          <div>
            <p className="coupon_code_txt">
              If you have coupon for discount fill here!
            </p>
            {error && <p className="error_line">{error}</p>}
            {isValidCoupon?.success && (
              <p className="succes_line">{isValidCoupon?.message}</p>
            )}
            <div className="couponCode_sec">
              <CustomInput
                type="text"
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Coupon Code"
                name="couponCode"
                value={couponCode}
              />
              <button
                className={`check_couponCode_btn ${
                  couponCode?.length <= 0 ? "disabled" : ""
                }`}
                onClick={() => handleCodeCheck()}
              >
                {loading ? <ButtonLoader /> : "Apply Coupon"}
              </button>
            </div>
            <div className="pay_withoutCoupon">
              {isValidCoupon?.success && (
                <p style={{ color: "green", fontWeight: "bold" }}>
                  This is {isValidCoupon?.isValidCoupon?.name}'s Coupon Code.
                  you get {discountePercentage}% discount for this Course.
                </p>
              )}
              <div className="all_payment_btns">
                <CustomButton
                  className=""
                  title={`Pay ₹${discountedAmount}`}
                  onClick={() =>
                    paymentDone(
                      discountedAmount,
                      courseId,
                      couponCode,
                      null,
                      false
                    )
                  }
                />
                {courseDetails?.isEmiAvail && (
                  <>
                    <CustomButton
                      className="emi_pay_btn"
                      title={t("pay_in_emi")}
                      onClick={() => setShowEmiTable(!showEmiTable)}
                    />
                    {showEmiTable && (
                      <div className="emi_table_wrapper">
                        <div className="emi_table custonScroll">
                          <div className="title">
                            EMI Installments for {courseDetails?.title}.
                            <AiOutlineClose
                              onClick={() => setShowEmiTable(false)}
                            />
                          </div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>EMI Title</th>
                                <th>EMI Amount</th>
                                <th>EMI Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {courseDetails?.allEmis?.map((item, idx) => {
                                let paymentEmiIdData = [];
                                allPaymentsParam?.forEach((payment) => {
                                  paymentEmiIdData.push(payment?.emiId);
                                });

                                return (
                                  <tr key={item._id}>
                                    <td>
                                      <p>{idx + 1}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiName}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiAmount}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiDuration}days</p>
                                    </td>
                                    <td>
                                      {paymentEmiIdData.includes(item._id) ? (
                                        <p
                                          className="txt_center"
                                          style={{ color: "yellow" }}
                                        >
                                          Wait For Verification
                                        </p>
                                      ) : (
                                        <button
                                          className="pay__emi_btn"
                                          onClick={() =>
                                            paymentDone(
                                              item?.emiAmount,
                                              courseId,
                                              couponCode,
                                              item._id,
                                              false
                                            )
                                          }
                                        >
                                          Pay
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isSeatBookingDone.includes(true) ? (
                  <p style={{ marginTop: "20px", color: "yellow" }}>
                    Seat Booked! Wait for verification.
                  </p>
                ) : (
                  <CustomButton
                    className="seat_book_btn"
                    title={`Book Your Seat`}
                    onClick={() =>
                      paymentDone(
                        seatBookingFees,
                        courseId,
                        couponCode,
                        null,
                        true
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const Cart = () => {
  const navigate = useNavigate();
  const [showAction, setshowAction] = useState({});
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [courseData, setcourseData] = useState({});
  const [, , showNotification] = useNotification();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const removeCartItemHandler = (id) => {
    dispatch(removeItemsFromCart(id));
  };

  const handlePopuup = (data) => {
    setShowCouponPopup(true);
    setcourseData(data);
  };
  // SHOW DELETE BUTTON ON CLICK

  // COURSE PAYMENT HANDLERS START
  // COURSE PAYMENT HANDLERS START
  const {
    //  eslint-disable-next-line
    loading: paymentLoading,
    payment,
    error: paymentError,
  } = useSelector((state) => state.coursePayment);

  useEffect(() => {
    if (paymentError) {
      showNotification("error", paymentError);
      dispatch(clearPaymentErrors());
    }

    if (payment?.success) {
      if (payment?.payment_request?.longurl) {
        window.location.href = payment?.payment_request?.longurl;
      } else if (payment?.message) {
        showNotification("success", payment?.message);
      }
      dispatch(clearPaymentState());
    }

    if (!payment?.success) {
      if (payment?.message?.phone) {
        showNotification("error", payment?.message?.phone[0]);
      }
      if (payment?.message?.amount) {
        showNotification("error", payment?.message?.amount[0]);
      }
      dispatch(clearPaymentState());
    }
  }, [
    paymentError,
    dispatch,
    showNotification,
    payment?.success,
    payment?.payment_request?.longurl,
    payment?.message,
  ]);
  // COURSE PAYMENT HANDLERS ENDS
  // COURSE PAYMENT HANDLERS ENDS

  const handleCourseDetailPage = (data) => {
    if (data?.ssbCourse) {
      const { title, courseMode, category, _id } = data;
      const formattedTitle = title?.toLowerCase().replace(/\s+/g, "-");
      const formattedMode = courseMode?.toLowerCase().replace(/\s+/g, "-");
      const formattedCategory = category?.toLowerCase().replace(/\s+/g, "-");
      const seoFriendlyUrl = `/ssb-course-details/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
      navigate(seoFriendlyUrl);
    } else {
      const { title, study_mode, courseCategory, _id } = data;
      const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
      const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
      const formattedCategory = courseCategory
        .toLowerCase()
        .replace(/\s+/g, "-");
      const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
      navigate(seoFriendlyUrl);
    }
  };

  return (
    <Fragment>
      <StudentLayout sidebarLinks={sidebarLinks}>
        <section className="student_cart_items txt_color">
          <div className="title">
            <h2>Cart</h2>
          </div>
          {cartItems.length > 0 ? (
            <div className="cart_item_list">
              {cartItems?.map((item) => {
                return (
                  <div className="cart_item" key={item?._id}>
                    <div className="item_thumb">
                      <img src={item?.image} alt={item?.image} />
                    </div>
                    <div className="item_name">
                      <Link onClick={() => handleCourseDetailPage(item)}>
                        {item?.title}
                      </Link>
                    </div>
                    <div className="item_price">
                      ₹{" "}
                      {item?.discountedPrice
                        ? Math.ceil(item?.discountedPrice)
                        : Math.ceil(item?.orignalPrice)}
                    </div>
                    <div className="item_action">
                      <CustomButton
                        className="enroll_btn"
                        title="Enroll Now"
                        width="200px"
                        onClick={() => handlePopuup(item)}
                      />
                      <div className="more_action">
                        <button
                          className="showListBtn"
                          onClick={() =>
                            setshowAction({ show: true, id: item?.id })
                          }
                          title="Show More Actions"
                        >
                          <BsThreeDots />
                        </button>
                        <div
                          className={`more_action_list ${
                            showAction.show && showAction.id === item.id
                              ? "showAction"
                              : ""
                          }`}
                        >
                          <CustomButton
                            className="remove_cart_btn"
                            title={<AiFillDelete />}
                            onClick={() => removeCartItemHandler(item?.id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no_item_cart">
              <img src={emptyCart} alt="emptyCart" />
              <p>
                Attention, soldier! Your course cart is currently empty. Report
                to the course catalog and select your educational weaponry. Move
                swiftly, your mission awaits!
              </p>
              <Link to="/ssb-courses">Course Page</Link>
            </div>
          )}
        </section>

        {/* COUPON CODE POPUP START */}

        <CouponPaymentPopup
          showCouponPopup={showCouponPopup}
          setShowCouponPopup={setShowCouponPopup}
          courseDetails={courseData}
        />
        {/* COUPON CODE POPUP ENDS */}
      </StudentLayout>
      {/* FOOTER START */}
      <StudentFooter />
      {/* FOOTER ENDS */}
    </Fragment>
  );
};

export default Cart;
