import React from "react";
import { defaultUser } from "../../assets/images";
import styled from "styled-components";
import { BsStarFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import { useAuth } from "../../context/userContext";
import CustomRating from "./CustomRating";
import ButtonLoader from "./ButtonLoader";
const ReviewCardWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  margin-bottom: 30px;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  background: linear-gradient(
    115.85deg,
    rgba(255, 255, 255, 0.4) -185.19%,
    rgba(255, 255, 255, 0) 129.07%
  );
  backdrop-filter: blur(21px);
  @media (max-width: 767px) {
    flex-direction: column;
  }
  > .left_section {
    padding: 20px;
    border-right: 1px solid #f2f2f2;
    width: 250px;
    @media (max-width: 767px) {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      border-right: none;
      justify-content: space-between;
    }
    @media (max-width: 545px) {
      padding: 8px;
    }
    > .profile {
      display: flex;
      align-items: center;
      column-gap: 20px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
      @media (max-width: 545px) {
        column-gap: 8px;
      }
      > img {
        border-radius: 50%;
        border: 1px solid #f2f2f2;
        width: 80px;
        height: 80px;
        object-fit: cover;
        @media (max-width: 767px) {
          width: 55px;
          height: 55px;
        }
      }
      > div {
        > h2 {
          color: #f2f2f2;
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 14px;
          @media (max-width: 767px) {
            font-size: 15px;
            margin-bottom: 9px;
          }
        }
        > span {
          display: flex;
          align-items: center;
          column-gap: 5px;
          font-size: 14px;
          color: rgb(136, 136, 136);
          font-weight: 400;
          > svg {
            font-size: 16px;
          }
          @media (max-width: 767px) {
            font-size: 14px;
            > svg {
              font-size: 15px;
            }
          }
        }
      }
    }
    > .mob_rating {
      display: none;
      @media (max-width: 767px) {
        display: flex;
        column-gap: 6px;
        align-items: fles-start;
      }
      > div {
        background-color: #f2f2f2;
        border: none;
        color: #081336;
        font-weight: bold;
        width: 40px;
        height: 30px;
        border-radius: 8px;
        padding: 0px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
          margin-right: 6px;
        }
      }
      > button {
        background-color: #f2f2f2;
        color: red;
        border: none;
        outline: none;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        > svg {
          font-size: 16px;
        }
      }
    }
  }
  .right_section {
    width: calc(100% - 250px);
    padding: 20px;
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 545px) {
      padding: 8px;
    }
    > .comment_heading {
      display: flex;
      justify-content: space-between;
      align-items: fles-start;
      > span {
        font-size: 15px;
        &:last-child {
          display: flex;
          column-gap: 6px;
          align-items: fles-start;
          @media (max-width: 767px) {
            display: none;
          }
          > div {
            background-color: #f2f2f2;
            border: none;
            color: #081336;
            font-weight: bold;
            width: 40px;
            height: 30px;
            border-radius: 8px;
            padding: 0px 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            > svg {
              margin-right: 6px;
            }
          }
          > button {
            background-color: #f2f2f2;
            color: red;
            border: none;
            outline: none;
            border-radius: 8px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            > svg {
              font-size: 16px;
            }
          }
        }

        > p {
          color: rgb(136 136 136);
          margin-bottom: 20px;
          &:last-child {
            margin-top: 12px;
            > svg {
              color: gold;
              font-size: 20px;
              margin-right: 5px;
              @media (max-width: 767px) {
                font-size: 15px;
              }
            }
          }
        }
        > h2 {
          font-size: 22px;
          color: #f2f2f2;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 15px;
          }
        }
      }
    }
  }
`;
const ReviewCard = ({ data, ssbDeleteReviewLoading, onReviewDelete }) => {
  const [auth] = useAuth();
  return (
    <ReviewCardWrapper>
      <div className="left_section">
        <div className="profile">
          <img
            src={
              auth?.user?.profile?.url ? auth?.user?.profile?.url : defaultUser
            }
            alt=""
          />
          <div>
            <h2>{data?.user?.first_name}</h2>
          </div>
        </div>
        <span className="mob_rating">
          <div>
            <BsStarFill /> {data?.rating}
          </div>
          {auth?.user?._id === data?.user?._id ? (
            <button className="delete_cmnt" onClick={onReviewDelete}>
              {ssbDeleteReviewLoading ? <ButtonLoader /> : <MdDelete />}
            </button>
          ) : (
            ""
          )}
        </span>
      </div>
      <div className="right_section">
        <div className="comment_heading">
          <span>
            <p>
              Reviewed:
              {moment(data?.createdAt).format("MMMM D, YYYY [at] h:mm A")}
            </p>
            <h2>{data?.comment}</h2>

            <CustomRating val={data?.rating} readOnly={true} />
          </span>
          <span>
            <div>
              <BsStarFill /> {data?.rating}
            </div>
            {auth?.user?._id === data?.user?._id && (
              <button className="delete_cmnt" onClick={onReviewDelete}>
                <MdDelete />
              </button>
            )}
          </span>
        </div>
      </div>
    </ReviewCardWrapper>
  );
};

export default ReviewCard;
