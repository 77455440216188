import React, { Fragment } from "react";
import Loader from "../../components/reusable/Loader";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import "../../styles/uploadDossier.scss";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaPlus } from "react-icons/fa";
import OfficerNoDataCard from "./OfficerNoDataCard";
import PopupPrompt from "../../components/reusable/PopupPrompt";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import {
  deleteDossier,
  dossierClearErrors,
  dossierClearState,
  getDossierAction,
} from "../../redux/actions/dossierAction";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import { CiEdit } from "react-icons/ci";
import { emptyCourse } from "../../assets/images";
const UploadDossier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const [, , showNotification] = useNotification();
  const [filterList, setFilterList] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [popupMsg, setPopupMsg] = useState({});

  // GET DOSSIER LIST STATE FROM DOSSIER REDUCER
  const getDossier = useSelector((state) => state.getDossier);

  const { loading, error, dossierData: data } = getDossier;

  // GET DOSSIER LIST
  useEffect(() => {
    dispatch(getDossierAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(dossierClearErrors());
    }
  }, [dispatch, error, showNotification]);

  // FILTER DOSSIER LIST ON CLICK
  const handleListFilter = (val) => {
    const localStorageDossier = localStorage.getItem("dossier")
      ? JSON.parse(localStorage.getItem("dossier"))
      : [];
    if (localStorageDossier) {
      setFilterList(localStorageDossier);
      setActiveTab(val);
    }
    if (val === 0) {
      setFilterList(data?.dossier);
      setActiveTab(0);
    }
  };

  // if(location?.state){
  //   setFilterList(localStorageDossier);
  //   setActiveTab(location?.state);
  // }else{
  //   setActiveTab(0);
  // }

  useEffect(() => {
    setFilterList(data?.dossier);
  }, [data]);

  const handleDeleteDraft = (ele) => {
    let existingDraftData = localStorage.getItem("dossier")
      ? JSON.parse(localStorage.getItem("dossier"))
      : localStorage.getItem("dossier");
    const filterData = existingDraftData.filter((itm, index) => index !== ele);
    localStorage.setItem("dossier", JSON.stringify(filterData)) &&
      setFilterList(filterData);
    setPopupMsg({ show: false });
    showNotification("success", "Draft Removed");
  };

  const handleEditDetails = (item, index) => {
    if (item?.label === 1) {
      navigate("/officer/physco-dossier-tat-create", {
        state: { item, index },
      });
    }
    if (item?.label === 2) {
      navigate("/officer/physco-dossier-wat-create", {
        state: { item, index },
      });
    }
    if (item?.label === 3) {
      navigate("/officer/physco-dossier-srt-create", {
        state: { item, index },
      });
    }
    if (item?.label === 4) {
      navigate("/officer/physco-dossier-sdt-create", {
        state: { item, index },
      });
    }
  };

  // DELETE DOSSIER STATE FROM DOSSIER DELETE REDUCER
  const {
    loading: dossierDeleteLoading,
    dossier,
    error: dossierDeleteError,
  } = useSelector((state) => state.deleteDossier);

  // HANDLE DELETE FUNCTION START
  const handleDelete = (id) => {
    dispatch(deleteDossier(id));
  };

  // /CLEAR ERROR AND STATE AFTER DELETE
  useEffect(() => {
    if (dossierDeleteError) {
      showNotification("error", dossierDeleteError);
      dispatch(dossierClearErrors());
    }
    if (dossier?.success) {
      showNotification("success", dossier?.message);
      // setTimeout(() => {
      dispatch(getDossierAction());
      setPopupMsg(false);
      dispatch(dossierClearState());
      // }, 3000);
    }
  }, [
    dossierDeleteError,
    dispatch,
    dossier?.success,
    dossier?.message,
    showNotification,
  ]);

  // VIEW COURSE DETAILS PAGE
  const handleViewDetails = (id) => {
    navigate(`/officer/view-dossier/${id}`);
  };
  // EDIT DOOSIER PAGE NAVIGATE
  const handleEditDossier = (id) => {
    navigate(`/officer/edit-dossier/${id}`);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <section className="officer_uploadDossier_section txt_color">
              <div className="title">
                <h2>Dossiers</h2>
                <button
                  title="Add New Course"
                  onClick={() => navigate("/officer/create-dossier")}
                >
                  <FaPlus />
                </button>
              </div>

              <div className="batch_timing_main officer_dossier_content txt_color">
                <div className="filter_tabs">
                  <button
                    className={`btn ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => handleListFilter(0)}
                  >
                    All
                  </button>
                  <button
                    className={`btn ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => handleListFilter(1)}
                  >
                    Draft
                  </button>
                  {/* <button
                    className={`btn ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => handleListFilter(2)}
                  >
                    WAT
                  </button>
                  <button
                    className={`btn ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => handleListFilter(3)}
                  >
                    SRT
                  </button>
                  <button
                    className={`btn ${activeTab === 4 ? "active" : ""}`}
                    onClick={() => handleListFilter(4)}
                  >
                    SDT
                  </button> */}
                </div>
                {filterList?.length > 0 ? (
                  <>
                    <div className="timing_calendar">
                      <div className="timing_table custonScroll">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Category</th>
                              <th>Created Date & Time</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterList?.map((ele, index) => (
                              <tr key={index}>
                                <td>
                                  <p>{ele?.title}</p>
                                </td>
                                <td className="txt_center">
                                  <p>
                                    {ele?.label === 1
                                      ? "TAT"
                                      : ele?.label === 2
                                      ? "WAT"
                                      : ele?.label === 3
                                      ? "SRT"
                                      : ele?.label === 4
                                      ? "SDT"
                                      : null}
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {moment(ele?.createdAt).format(
                                      "MMMM-DD-YYYY [at] h:mm A"
                                    )}
                                  </p>
                                </td>

                                <td>
                                  <p>
                                    {activeTab === 0 && (
                                      <button
                                        title="View Dossier"
                                        onClick={() =>
                                          handleViewDetails(ele?._id)
                                        }
                                      >
                                        <AiFillEye />
                                      </button>
                                    )}

                                    {activeTab === 0 && (
                                      <button
                                        title="Edit Dossier"
                                        onClick={() =>
                                          handleEditDossier(ele?._id)
                                        }
                                      >
                                        <FaEdit />
                                      </button>
                                    )}

                                    {activeTab === 0 ? (
                                      <button
                                        title="Delete Dossier"
                                        onClick={() =>
                                          setPopupMsg({
                                            show: true,
                                            id: ele._id,
                                          })
                                        }
                                      >
                                        <MdDelete />
                                      </button>
                                    ) : (
                                      <button
                                        title="Delete Draft"
                                        onClick={() =>
                                          setPopupMsg({ show: true, id: index })
                                        }
                                      >
                                        <MdDelete />
                                      </button>
                                    )}

                                    {activeTab === 1 && (
                                      <button
                                        title="Edit Draft"
                                        onClick={() =>
                                          handleEditDetails(ele, index)
                                        }
                                      >
                                        <CiEdit />
                                      </button>
                                    )}
                                  </p>

                                  {popupMsg.show && (
                                    <PopupPrompt
                                      confirmBtnTxt="Yes"
                                      cancelBtnTxt="No"
                                      title="Are you sure! You want to delete this dossier test."
                                      confirmOnclick={
                                        activeTab === 0
                                          ? () => handleDelete(popupMsg?.id)
                                          : () =>
                                              handleDeleteDraft(popupMsg?.id)
                                      }
                                      cancelOnclick={() => setPopupMsg(false)}
                                      loading={dossierDeleteLoading}
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <OfficerNoDataCard
                    txt={
                      activeTab === 1 && !filterList?.length
                        ? "Attention, officer! The draft list for courses awaits your command. It's time to rally your knowledge and draft your first course into the battlefield of education!"
                        : "Attention, officer! Our course barracks await your command. Time to muster your knowledge and launch your first course into the battlefield of education!"
                    }
                    // icon={<FaRegSadTear />}
                    image={emptyCourse}
                  />
                )}
              </div>
            </section>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default UploadDossier;
