import { useEffect } from "react";
// import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/viewDossier.scss";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import Loader from "../../components/reusable/Loader";
import {
  dossierClearErrors,
  getDossierByIdAction,
} from "../../redux/actions/dossierAction";
import moment from "moment";
const ViewDossier = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // GET DOSSIER STATE FROM STORE
  const { error, loading, dossierData } = useSelector(
    (state) => state.getDossierById
  );

  const dossierDetails = dossierData?.dossier;

  useEffect(() => {
    if (id) {
      dispatch(getDossierByIdAction(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(dossierClearErrors());
      navigate("/officer/upload-dossier");
    }
  }, [error, dispatch,navigate, showNotification]);

  return loading ? (
    <Loader />
  ) : (
    <div className="view_dossier">
      <div className="header">
        <div className="title">
          {dossierDetails?.title}
          <button
            title="Go Back"
            onClick={() => navigate("/officer/upload-dossier")}
          >
            <RxCross2 />
          </button>
        </div>
      </div>
      <div className="container">
        <div>
          <div className="create_dossier_content">
            <div className="dossier_description">
              <p>
                <span>Title :</span>
                <span>{dossierDetails?.title}</span>
              </p>
              <p>Instruction :</p>
              <div className="instruction_list">
                {dossierDetails?.instructionList?.map((ele, idx) => (
                  <span key={idx}>{ele?.instruction}</span>
                ))}
              </div>
            </div>
            {dossierDetails?.allImages.length > 0 && (
              <div className="course_banner">
                <p>Images :</p>
                <div>
                  {dossierDetails?.allImages?.map((image, index) => (
                    <div key={index}>
                      <img key={index} src={image?.url} alt={`preview-${index}`} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {dossierDetails?.allWords.length > 0 && (
              <div className="course_banner">
                <p>Word List</p>
                <div>
                  {dossierDetails?.allWords?.map((ele, index) => (
                    <p key={index}>{ele.word}</p>
                  ))}
                </div>
              </div>
            )}
            {dossierDetails?.allSdQuestions.length > 0 && (
              <div className="allSdQuestions">
                <p>Quetions List :</p>
                <div>
                  {dossierDetails?.allSdQuestions?.map((ele, index) => (
                    <p key={index}>{ele?.question}</p>
                  ))}
                </div>
              </div>
            )}
            {dossierDetails?.allSituations.length > 0 && (
              <div className="allSdQuestions">
                <p>Situations List :</p>
                <div>
                  {dossierDetails?.allSituations?.map((ele, index) => (
                    <p key={index}>{ele?.situations}</p>
                  ))}
                </div>
              </div>
            )}
            <div className="created_by">
              <p>
                <span>Created At :</span>
                <span>
                  {moment(dossierDetails?.createdAt).format("DD-MM-YYYY")}
                </span>
              </p>
              <p>
                <span>Update At :</span>
                <span>
                  {moment(dossierDetails?.updatedAt).format("DD-MM-YYYY")}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDossier;
