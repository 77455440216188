export const arTeacherSidebarLinks = [
    {
      id: 1,
      title: "Profile",
      link: "/ar-teacher/profile",
    },
    {
      id: 2,
      title: "Timetable",
      link: "/ar-teacher/timetable",
    },
    {
      id: 3,
      title: "Social Media",
      link: "/ar-teacher/social-media",
    }
  ];
  