import React from "react";
import "./whyAttend.scss";
import { FiUsers } from "react-icons/fi";
import { GrWorkshop } from "react-icons/gr";
import { FaHouseUser } from "react-icons/fa";

const WhyAttend = () => {
  return (
    <div className="why_you_attend">
      <div className="container">
        <div className="title">
          <h2>Why Should You Attend?</h2>
          <p>
            Eventer 2022 gathered the brightest minds in the tech world. Learn
            new skills and get insights from experts and practitioners from all
            around!
          </p>
        </div>

        <div className="why_attend_content">
          <div className="card_single">
            <div className="icon">
              <FiUsers />
            </div>
            <h2 className="card_title">Advanced Speakers</h2>
            <p className="card_desc">
              Porta semper lacus cursus, feugiat primis ultrice in ligula risus
              auctor tempus undo feugiat dolor lacinia ibero tempus, blandit
            </p>
          </div>
          <div className="card_single">
            <div className="icon">
              <GrWorkshop />
            </div>
            <h2 className="card_title">Specialized Workshops</h2>
            <p className="card_desc">
              Porta semper lacus cursus, feugiat primis ultrice in ligula risus
              auctor tempus undo feugiat dolor lacinia ibero tempus, blandit
            </p>
          </div>
          <div className="card_single">
            <div className="icon">
              <FaHouseUser />
            </div>
            <h2 className="card_title">782 Available Seats</h2>
            <p className="card_desc">
              Porta semper lacus cursus, feugiat primis ultrice in ligula risus
              auctor tempus undo feugiat dolor lacinia ibero tempus, blandit
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyAttend;
