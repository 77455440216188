import React, { Fragment, useEffect, useState } from "react";
import "../styles/workshop99.scss";
import Layout from "../components/reusable/Layout";
import WorkshopAbout from "./workshop99/WorkshopAbout";
import ConferenceSpeaker from "./workshop99/ConferenceSpeaker";
import WhatHappen from "./workshop99/WhatHappen";
import WhyAttend from "./workshop99/WhyAttend";
import TicketSection from "./workshop99/TicketSection";
import RegisterSection from "./workshop99/RegisterSection";
import FaqSection from "./workshop99/FaqSection";
import WorkshopHero from "./workshop99/WorkshopHero";
import Loader from "../components/reusable/Loader";

const Workshop99 = () => {
  const [countDown, setCountDown] = useState(4);

  useEffect(() => {
    if (countDown >= 0) {
      setTimeout(() => {
        setCountDown((prevState) => --prevState);
      }, 1000);
    }
  }, [countDown]);

  return (
    <Fragment>
      {countDown >= 0 ? (
        <Loader />
      ) : (
        <Layout>
          <div className="workshop_99_main txt_color">
            {/* HERO SECTION START */}
            <WorkshopHero />
            {/* HERO SECTION ENDS */}

            {/* ABOUT SECTION START */}
            <WorkshopAbout />
            {/* ABOUT SECTION ENDS */}

            {/* Conference Keynote Speakers Start */}
            <ConferenceSpeaker />
            {/* Conference Keynote Speakers Ends */}

            {/* What Is Going To Happen? START */}
            <WhatHappen />
            {/* What Is Going To Happen? ENDS */}

            {/* Why Should You Attend? START */}
            <WhyAttend />
            {/* Why Should You Attend? ENDS */}

            {/* Get Ticket Now SECTION START */}
            <TicketSection />
            {/* Get Ticket Now SECTION ENDS */}

            {/* REGISTER FOR ATTEND START */}
            <RegisterSection />
            {/* REGISTER FOR ATTEND ENDS */}

            {/* Have Questions? Look Here. START */}
            <FaqSection />
            {/* Have Questions? Look Here. ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default Workshop99;
