import React from "react";
import "../styles/pageNotFound.scss";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

const PageNotFound = () => {
  return (
    <section className="page_not_found">
      <div className="conatiner">
        <div className="not_found_content">
          <title>
            <h1>404</h1>
            <p className="subheading">Page not found</p>
          </title>
          <p>
            {/* The page you are looking for might have been removed had its name
            changed or is temporarily unavailable. */}
            Webpage not found! Our apologies for the inconvenience
          </p>
          <Link to="/">
            go to homepage <BsChevronRight />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
