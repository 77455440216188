import React, { useState } from "react";
import "../styles/landingPage.scss";
import Layout from "../components/reusable/Layout";
import { useLocale } from "../context/locale";
import {
  GDCourse,
  Tarun,
  abhit,
  arunsingh,
  atul,
  chirag,
  clerkCourse,
  davesh,
  deepak,
  dheeraj,
  monty,
  rahul,
  rohit,
  sachin,
  sandesh,
  support,
  surajyadav,
  tarunclerk,
  techCourse,
  tushar,
  yogender,
} from "../assets/images";
import SingleCourseCard from "../components/course/SingleCourseCard";
import {
  AiOutlineClose,
  // AiOutlineDoubleRight
} from "react-icons/ai";
import jobSupport from "../images/jobSupport.svg";
import latestStudy from "../images/latestStudy.svg";
import practical from "../images/practical.svg";
import quiz from "../images/quiz.svg";
import CustomCard from "../components/reusable/CustomCard";
import LearnBestCard from "../components/course/LearnBestCard";
import Carousel from "react-elastic-carousel";
import { learnFromBest } from "../dummyContent";
import { MdCall } from "react-icons/md";
import CustomButton from "../components/reusable/CustomButton";
import { Link } from "react-router-dom";
import currHighlight1 from "../images/currHighlight1.png";
import currHighlight3 from "../images/currHighlight3.png";
import landing_page_thumb from "../images/landing_page_thumb.png";
import videoIcon from "../images/video_icon.png";
// SLIDER
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import SoldierHeart from "../components/home/SoldierHeart";
import { toast } from "react-toastify";

// Popup for Know More Video Of Batch
const MoreDetailBatchVideo = ({ showDetailVideo, setshowDetailVideo }) => {
  return (
    <section
      className={`more_batch_details_modal ${
        showDetailVideo ? "show_video_Mocal" : ""
      } bg_color txt_color`}
    >
      <div className="container">
        {showDetailVideo && (
          <div className="video_content">
            <button
              className="close_modal"
              onClick={() => setshowDetailVideo(false)}
            >
              <AiOutlineClose />
            </button>
            <div>
              <div
                style={{
                  left: 0,
                  width: "100%",
                  height: 0,
                  position: "relative",
                  paddingBottom: "56.25%",
                }}
              >
                <figure
                  style={{
                    left: 0,
                    width: "100%",
                    height: 0,
                    position: "relative",
                    paddingBottom: "56.25%",
                    marginBlockEnd: 0,
                    marginBlockStart: 0,
                    marginInlineStart: 0,
                    marginInlineEnd: 0,
                  }}
                >
                  <iframe
                    title="Shaurya GD, Shaurya Clerk, Shaurya Tech Batches of Alpha Regiment."
                    src="https://www.youtube.com/embed/CDDbeA6JFXE"
                    scrolling="no"
                    style={{
                      border: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      overflow: "hidden",
                      borderRadius: 5,
                    }}
                    allowFullScreen={1}
                    allow="autoplay"
                  />
                </figure>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const LandingPage = () => {
  const [t] = useLocale();
  const [showDetailVideo, setshowDetailVideo] = useState(false);

  const [mobileNumber, setMobileNumber] = useState("");

  // SUBMIT FRANCHISE FORM DATA ON MAIL
  const handleSubmit = () => {
    const config = {
      SecureToken: process.env.REACT_APP_SMTPSECRET,
      To: process.env.REACT_APP_SMTPUSERNAME,
      From: process.env.REACT_APP_SMTPUSERNAME,
      Subject: "Booking Of Free Counselling Session with Experts",
      Body:
        "<h1>Booking Of Free Counselling Session with Experts</h1> <br/> <p><b>Mobile Number:</b> " +
        mobileNumber +
        "</p>",
    };

    if (mobileNumber) {
      if (window.Email) {
        window.Email.send(config).then(() => {
          toast.success("Request Sent Successfully.");
          setMobileNumber("");
        });
      }
    } else {
      toast.error("Please fill your query.");
    }
  };

  // SLIDER BREAK_POINTS
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
  ];

  // COURSE DUMMY ARRAY
  const courseContent = [
    {
      id: "1",
      title: t("shauryaGdPageTitle"),
      icon: GDCourse,
      description: t("shauryaGdPageShortDescription"),
      largeDescription: t("shauryaGdPageLargeDescription"),
      actualPrice: "20499",
      discountedPrice: "18499",
      goalType: "Armed Forces | Para Military",
      studyMode: "Online",
      category: "Air Force | Navy | Army | CAPF",
      BatchDetail_1: t("shauryaGDBatchDetail_1"),
      BatchDetail_2: t("shauryaGDBatchDetail_2"),
      BatchDetail_3: t("shauryaGDBatchDetail_3"),
      BatchDetail_4: t("shauryaGDBatchDetail_4"),
      paymentLink: "https://imjo.in/fw7TYZ",
    },
    {
      id: "2",
      title: t("shauryaTechPageTitle"),
      icon: techCourse,
      description: t("shauryaTechPageShortDescription"),
      largeDescription: t("shauryaTechPageLargeDescription"),
      actualPrice: "24999",
      discountedPrice: "20499",
      goalType: "Armed Forces | Para Military",
      studyMode: "Online",
      category: "Air Force | Navy | Army | CAPF",
      BatchDetail_1: t("shauryaTechBatchDetail_1"),
      BatchDetail_2: t("shauryaTechBatchDetail_2"),
      BatchDetail_3: t("shauryaTechBatchDetail_3"),
      BatchDetail_4: t("shauryaTechBatchDetail_4"),
      paymentLink: "https://imjo.in/hmwZAD",
    },
    {
      id: "3",
      title: t("shauryaClerkPageTitle"),
      icon: clerkCourse,
      description: t("shauryaClerkPageShortDescription"),
      largeDescription: t("shauryaClerkPageLargeDescription"),
      actualPrice: "24999",
      discountedPrice: "20499",
      goalType: "Armed Forces | Para Military",
      studyMode: "Online",
      category: "Air Force | Navy | Army | CAPF",
      BatchDetail_1: t("shauryaClerkBatchDetail_1"),
      BatchDetail_2: t("shauryaClerkBatchDetail_2"),
      BatchDetail_3: t("shauryaClerkBatchDetail_3"),
      BatchDetail_4: t("shauryaClerkBatchDetail_4"),
      paymentLink: "https://imjo.in/p8mPsn",
    },
  ];

  // WHY COURSES LOCAL CONTENT
  const whyShauryaCourses = [
    {
      id: 1,
      text: t("landing_page_why_shaurya1"),
    },
    {
      id: 2,
      text: t("landing_page_why_shaurya2"),
    },
    {
      id: 3,
      text: t("landing_page_why_shaurya3"),
    },
    {
      id: 4,
      text: t("landing_page_why_shaurya4"),
    },
    {
      id: 5,
      text: t("landing_page_why_shaurya5"),
    },
    {
      id: 6,
      text: t("landing_page_why_shaurya6"),
    },
  ];

  // REVO.. LEARNING ARRAY
  const revoLearning = [
    {
      id: "1",
      title: t("jobGuaranteeHeading"),
      img: jobSupport,
      description: t("jobGuaranteeCardDesc"),
    },
    {
      id: "2",
      title: t("latestStudyMaterialHeading"),
      img: latestStudy,
      description: t("latestStudyMaterialDesc"),
    },
    {
      id: "3",
      title: t("oneOnOneGuidanceHeading"),
      img: practical,
      description: t("oneOnOneGuidanceDesc"),
    },
    {
      id: "4",
      title: t("regularQuizzesHeading"),
      img: quiz,
      description: t("regularQuizzesDesc"),
    },
  ];

  // COURSE CURRICULUM HIGHLIGHTS
  const currHighlights = [
    {
      id: 1,
      title: t("heighlights_prevPaper_heading"),
      img: currHighlight1,
      description: t("heighlights_prevPaper_desc"),
    },
    {
      id: 2,
      title: t("heighlights_mockPaper_heading"),
      img: currHighlight1,
      description: t("heighlights_mockPaper_desc"),
    },
    {
      id: 3,
      title: t("heighlights_doubtSession_heading"),
      img: currHighlight3,
      description: t("heighlights_doubtSession_desc"),
    },
  ];

  //
  //
  // OUR SOLDIER HEART CONSTENT
  const soldierHeart = [
    {
      id: 1,
      img: abhit,
      name: "Abhit",
      subHeading: "Selected in Army GD",
      text: t("landing_page_abhit"),
    },
    {
      id: 2,
      img: arunsingh,
      name: "Arun Singh",
      subHeading: "",
      text: t("landing_page_arunsingh"),
    },
    {
      id: 3,
      img: atul,
      name: "Atul",
      subHeading: "",
      text: t("landing_page_atul"),
    },
    {
      id: 4,
      img: chirag,
      name: "Chirag",
      subHeading: "Selected in Army Clerk",
      text: t("landing_page_chirag"),
    },
    {
      id: 5,
      img: davesh,
      name: "Davesh",
      subHeading: "",
      text: t("landing_page_davesh"),
    },
    {
      id: 6,
      img: deepak,
      name: "Deepak",
      subHeading: "",
      text: t("landing_page_deepak"),
    },
    {
      id: 7,
      img: dheeraj,
      name: "Dheeraj",
      subHeading: "",
      text: t("landing_page_dheeraj"),
    },
    {
      id: 9,
      img: monty,
      name: "Monty",
      subHeading: "Selected in Army GD",
      text: t("landing_page_monty"),
    },
    {
      id: 10,
      img: rahul,
      name: "Rahul",
      subHeading: "",
      text: t("landing_page_rahul"),
    },
    {
      id: 11,
      img: rohit,
      name: "Rohit",
      subHeading: "",
      text: t("landing_page_rohit"),
    },
    {
      id: 12,
      img: sachin,
      name: "Sachin",
      subHeading: "",
      text: t("landing_page_sachin"),
    },
    {
      id: 13,
      img: sandesh,
      name: "Sandesh",
      subHeading: "",
      text: t("landing_page_sandesh"),
    },
    {
      id: 14,
      img: surajyadav,
      name: "Suraj Yadav",
      subHeading: "",
      text: t("landing_page_surajyadav"),
    },
    {
      id: 15,
      img: tarunclerk,
      name: "Tarun",
      subHeading: "Selected in Army Clerk",
      text: t("landing_page_tarunclerk"),
    },
    {
      id: 16,
      img: Tarun,
      name: "Tarun",
      subHeading: "Selected in Army GD",
      text: t("landing_page_Tarun"),
    },
    {
      id: 17,
      img: tushar,
      name: "Tushar",
      subHeading: "",
      text: t("landing_page_tushar"),
    },
    {
      id: 18,
      img: yogender,
      name: "Yogender",
      subHeading: "",
      text: t("landing_page_yogender"),
    },
  ];

  return (
    <Layout>
      <section className="landing_page_main txt_color">
        {/* ABOUT COURSES SECTION START */}
        <div className="container">
          <div className="course_about">
            <div
              className="video_icon"
              onClick={() => setshowDetailVideo(true)}
            >
              <img
                className="thumbnail"
                src={landing_page_thumb}
                alt="landing_page_thumb"
              />
              <img className="absolute_btn" src={videoIcon} alt="videoIcon" />
            </div>
            <div className="text_section">
              <h1>{t("landing_page_hero_main")}</h1>
              <p>{t("landing_page_hero_heading")}</p>
            </div>
          </div>
        </div>
        {/* ABOUT COURSES SECTION ENDS */}

        {/* ALL COURSES CARDS START */}
        <div className="container">
          <div className="all_courses_sec">
            {courseContent.length > 0 &&
              courseContent.map((item, idx) => (
                <SingleCourseCard
                  key={idx}
                  title={item.title}
                  description={item.description}
                  icon={item.icon}
                  discountedPrice={item.discountedPrice}
                  actualPrice={item.actualPrice}
                  link={item.paymentLink}
                />
              ))}
          </div>
        </div>
        {/* ALL COURSES CARDS ENDS */}

        {/* Why Shaurya Courses Start */}
        <div className="container">
          <div className="why_shaurya_courses">
            <div className="title">
              <h2>{t("landing_page_why_shaurya_Heading")}</h2>
            </div>
            <div className="course_content">
              {whyShauryaCourses.map((item) => (
                <div className="why_course_card" key={item.id}>
                  <p>
                    <span>{item.text}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Why Shaurya Courses Ends */}

        {/* WHAT MAKES OUR COURSE UNIQUE START */}
        <div className="revo_learning">
          <div className="container">
            <div className="title">
              <h2>{t("unique_course_heading")}</h2>
            </div>
            <div className="revo_learning_cards">
              {revoLearning?.map((item) => (
                <CustomCard
                  key={item.id}
                  img={item.img}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>
        </div>
        {/* WHAT MAKES OUR COURSE UNIQUE ENDS */}

        {/* Learn from the best start */}
        <section className="learn_from_best">
          <div className="container">
            <div className="title">
              <h2>Learn from the best</h2>
            </div>
            <div className="learn_from_best_content">
              <Carousel
                breakPoints={breakPoints}
                // enableAutoPlay
                // autoPlaySpeed={2500}
              >
                {learnFromBest?.map((item, idx) => {
                  return (
                    <LearnBestCard
                      key={idx}
                      img={item.img}
                      title={item.title}
                      description={item.teachFor}
                      language={item.language}
                    />
                  );
                })}
              </Carousel>
            </div>
          </div>
        </section>
        {/* Learn from the best ends */}

        {/* curriculum highlights start */}
        <section className="curriculum_highlights">
          <div className="container">
            <div className="title">
              <h2>{t("course_curr_heighlights")}</h2>
            </div>
            <div className="curriculum_highlights_content">
              {currHighlights?.map((item) => {
                return (
                  <CustomCard
                    key={item.id}
                    img={item.img}
                    title={item.title}
                    description={item.description}
                  />
                );
              })}
            </div>
          </div>
        </section>
        {/* curriculum highlights ends */}

        {/* BATCH DETAILS SECTION START */}
        <section className="batch_details_main">
          <div className="container">
            <div className="title">
              <h2>{t("batchDetailsHeading")}</h2>
            </div>
            <div className="batch_details_content">
              <ul>
                <li>
                  Since this course also includes medical Guidance, before your
                  medical examination, an appointment will be scheduled with the
                  hospital nearest to your location for a complete checkup. Your
                  reports will be evaluated by retired military doctors and
                  senior doctors will provide feedback to ensure a smooth
                  medical examination process.
                </li>
                <li>
                  Since this course also includes medical Guidance, before your
                  medical examination, an appointment will be scheduled with the
                  hospital nearest to your location for a complete checkup. Your
                  reports will be evaluated by retired military doctors and
                  senior doctors will provide feedback to ensure a smooth
                  medical examination process.
                </li>
                <li>
                  Since this course also includes medical Guidance, before your
                  medical examination, an appointment will be scheduled with the
                  hospital nearest to your location for a complete checkup. Your
                  reports will be evaluated by retired military doctors and
                  senior doctors will provide feedback to ensure a smooth
                  medical examination process.
                </li>
                <li>
                  Since this course also includes medical Guidance, before your
                  medical examination, an appointment will be scheduled with the
                  hospital nearest to your location for a complete checkup. Your
                  reports will be evaluated by retired military doctors and
                  senior doctors will provide feedback to ensure a smooth
                  medical examination process.
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* BATCH DETAILS SECTION ENDS */}

        {/* BOOK FREE COUNSELLING SESSION START */}
        <section className="couselling_session_main">
          <div className="container">
            <div className="session_card">
              <div className="form_sec">
                <h2>Book Free Counselling Session with Experts</h2>
                <p>
                  Enter your mobile number and recieve a call from our expert
                </p>
                <div className="form_sub_sec">
                  <div className="input_sec">
                    <span>+91</span>
                    <input
                      type="text"
                      onChange={(e) => setMobileNumber(e.target.value)}
                      placeholder="Mobile No."
                    />
                  </div>
                  <div className="free_callBtn">
                    <CustomButton
                      title="Book A Free Call"
                      onClick={handleSubmit}
                    />
                  </div>
                  <div className="contact_dtls">
                    <p>Or Contact</p>
                    <p>
                      <span>
                        <MdCall />
                      </span>
                      <span>
                        <Link to="tel:+918480005537">8480005537</Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="img_sec">
                <img src={support} alt="support" />
              </div>
            </div>
          </div>
        </section>
        {/* BOOK FREE COUNSELLING SESSION ENDS */}

        {/* Our soldiers heart content start */}
        <section className="soldiers_heart_content">
          <div className="container">
            <div className="content_background_color">
              <p className="title">Our soldiers heart content</p>
              <Swiper
                grabCursor={true}
                // autoplay={{
                //   delay: 4000,
                //   disableOnInteraction: false,
                // }}
                navigation={true}
                loop={true}
                loopPreventsSliding={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                {soldierHeart?.map((item) => (
                  <SwiperSlide key={item.id}>
                    <SoldierHeart
                      img={item.img}
                      sodierName={item.name}
                      subHeading={item.subHeading}
                      text={item.text}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
        {/* Our soldiers heart content Ends */}

        {/* JOIN US COURSES CARDS START */}
        <div className="container join_us_main">
          <div className="title">
            <h2>Join Us</h2>
          </div>
          <div className="all_courses_sec">
            {courseContent.length > 0 &&
              courseContent.map((item, idx) => (
                <SingleCourseCard
                  key={idx}
                  title={item.title}
                  description={item.description}
                  icon={item.icon}
                  discountedPrice={item.discountedPrice}
                  actualPrice={item.actualPrice}
                  link={item.paymentLink}
                />
              ))}
          </div>
        </div>
        {/* JOIN US COURSES CARDS ENDS */}
      </section>

      {/* POPUP FOR DETAIL VIDEO OF BATCH START */}
      <MoreDetailBatchVideo
        showDetailVideo={showDetailVideo}
        setshowDetailVideo={setshowDetailVideo}
      />
      {/* POPUP FOR DETAIL VIDEO OF BATCH ENDS */}
    </Layout>
  );
};

export default LandingPage;
