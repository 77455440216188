import React, { Fragment } from "react";
import "../styles/register.scss";
import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import { Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa";
import { GiPoliceOfficerHead, GiTeacher } from "react-icons/gi";

const Register = () => {
  return (
    <Fragment>
      <CustomHelmet
        keywords="Sign up Alpha Regiment "
        description="Join the India's biggest community of veterans and defense aspirants."
        author="Team Alpha Regiment"
        title="Alpha Regiment: Register"
        canonical={window?.location?.href}
      />

      <section className="signup_sec_main">
        <div className="container">
          <Layout>
            <div className="signUpWrapper">
              <div className="signUp_content">
                <div className="title">
                  <h1>REGISTER</h1>
                </div>
                <div className="registerChangerSec">
                  <div className="btn_grp_Sec">
                    <Link
                      title="Click here to register as STUDENT"
                      to="student"
                    >
                      <div className="btnBox">
                        <FaUserGraduate />
                        <p>As Student</p>
                      </div>
                    </Link>
                    <Link
                      title="Click here to register as OFFICER"
                      to="officer"
                    >
                      <div className="btnBox">
                        <GiPoliceOfficerHead />
                        <p>As SSB Instructor</p>
                      </div>
                    </Link>
                    <Link
                      title="Click here to register as TEACHER"
                      to="teacher"
                    >
                      <div className="btnBox">
                        <GiTeacher />
                        <p>As Exam Teacher</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        </div>
      </section>
    </Fragment>
  );
};

export default Register;
