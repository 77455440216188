import React, { useEffect, useState } from "react";
import "../../styles/chat/chatInterface.scss";
import ChatSidebar from "./ChatSidebar";
import { logo_mobile } from "../../assets/images";
import UserChat from "./UserChat";
import { useChatAuth } from "../../context/chatContext";
import { useAuth } from "../../context/userContext";
import {
  clearChatError,
  getMyConversation,
} from "../../redux/actions/chatAction";
import { useDispatch, useSelector } from "react-redux";

const ChatInterface = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const dispatch = useDispatch();
  const { person } = useChatAuth();
  const [auth] = useAuth();
  const user = auth?.user;

  // GET MY CONVERSATIONS START
  const {
    conversation,
    error,
    loading: conversationLoading,
  } = useSelector((state) => state.myConversations);

  useEffect(() => {
    if (user?._id) {
      dispatch(getMyConversation());
    }
  }, [user?._id, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearChatError());
    }
  }, [error, dispatch]);
  // GET MY CONVERSATIONS ENDS

  return (
    <div className="chat_interface_main">
      <div className="chatSidebar">
        <ChatSidebar
          conversation={conversation}
          conversationLoading={conversationLoading}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
      </div>
      <div className="chatSection">
        {Object?.keys(person)?.length ? (
          <div className="chatContainer">
            <UserChat setShowSidebar={setShowSidebar} />
          </div>
        ) : (
          <div className="noChatCard">
            <img src={logo_mobile} alt="logo_mobile" />
            <p>
              Connect, collaborate, and converse seamlessly with colleagues and
              peers across the network, fostering productivity and innovation at
              every turn.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatInterface;
