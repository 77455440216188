import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllSsbCourses,
  ssbClearErrors,
} from "../../redux/actions/ssbCourseAction";
import ImgLazyLoad from "../../components/reusable/ImgLazyLoad";
import { courseNotFound } from "../../assets/images";
import { useLocale } from "../../context/locale";
import { Link, useNavigate } from "react-router-dom";
import {
  addWishListAction,
  deleteWishlistItem,
  getAllWishListAction,
  wishListClearDeleteState,
  wishListClearErrors,
  wishListClearState,
} from "../../redux/actions/wishListAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import CourseCard from "../../components/course/CourseCard";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import "../../styles/ssbCoaching.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
import { IoMdArrowRoundBack } from "react-icons/io";
const MockInterviewCourse = () => {
  const currentPageUrl = window?.location?.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const [t] = useLocale();
  const { loading, courses, error } = useSelector(
    (state) => state.getAllSsbCourses
  );

  let allSsbApiCourses = courses?.courses?.filter(
    (itm) =>
      itm?.category === "Mock Interview" &&
      ((itm?.isActive && !itm?.isPause) ||
        (itm?.isActive && itm?.isPause && itm?.pauseForDate !== null))
  );
  const [courseMode, setCourseMode] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const handleSearchChange = (e) => {
    const filter = e.target.value;
    setSearchCategory(filter);
    const filterdata = allSsbApiCourses?.filter((i) => {
      return (
        i.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) &&
        i.courseMode
          .toLocaleLowerCase()
          .includes(courseMode.toLocaleLowerCase())
      );
    });
    allSsbApiCourses = filterdata;
  };
  const handleFilterMode = (query) => {
    setCourseMode(query);
    const filterData = allSsbApiCourses?.filter((course) => {
      return course?.courseMode === query;
    });
    allSsbApiCourses = filterData;
  };

  // console.log(allSsbApiCourses);

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(ssbClearErrors());
    }
  }, [error, dispatch]);

  const handleSsbDetailsPage = (data) => {
    // if (categoryTitle === "Spoken English") {
    //   const { title, study_mode, courseCategory, _id } = data;
    //   const formattedTitle = title.toLowerCase().replace(/\s+/g, "-");
    //   const formattedMode = study_mode.toLowerCase().replace(/\s+/g, "-");
    //   const formattedCategory = courseCategory
    //     .toLowerCase()
    //     .replace(/\s+/g, "-");
    //   const seoFriendlyUrl = `/course-detail/${formattedTitle}-${formattedMode}-${formattedCategory}/${_id}`;
    //   navigate(seoFriendlyUrl);
    // } else {
    const { slug } = data;
    const seoFriendlyUrl = `/ssb-course-details/${slug}`;
    navigate(seoFriendlyUrl);
    // }
  };

  // CHECK WISHLIST EXIST OR NOT
  const [wishListId, setWishListId] = useState("");

  const {
    // eslint-disable-next-line
    loading: getWishListLoading,
    error: getWishListError,
    allWishList,
  } = useSelector((state) => state.getWishList);

  useEffect(() => {
    setWishListId("");
    if (auth?.user?._id) {
      dispatch(getAllWishListAction());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (getWishListError) {
      dispatch(wishListClearErrors());
    }
  }, [dispatch, getWishListError]);

  const handleAddtoWishlist = (wishlistData) => {
    const courseId = wishlistData?.itemId;
    const isSsb = wishlistData?.isSsb;
    const existInWishList = allWishList?.wishlist?.ssbCourse?.some(
      (obj) => obj._id === courseId
    );
    if (auth?.user?._id && !existInWishList) {
      setWishListId(courseId);
      dispatch(addWishListAction(courseId, isSsb));
    }
    if (auth?.user?._id && existInWishList) {
      setWishListId(courseId);
      dispatch(deleteWishlistItem(courseId, isSsb));
    }
    if (!auth?.user?._id) {
      showNotification("error", "Please login before adding to wishlist");
      navigate("/login");
    }
  };

  // HANDLE REMOVE WISHLIST STATES
  const {
    loading: deleteLoading,
    error: deleteError,
    allWishListData,
  } = useSelector((state) => state.deleteWishList);

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(wishListClearErrors());
    }
    if (allWishListData?.success) {
      showNotification("success", allWishListData?.message);
      dispatch(wishListClearDeleteState());
      dispatch(getAllWishListAction());
    }
  }, [
    dispatch,
    deleteError,
    allWishListData?.success,
    allWishListData?.message,
    showNotification,
  ]);

  // HANDLE ADD WISHLIST STATES
  const {
    loading: addWishListLoading,
    error: addWishListError,
    wishListData,
  } = useSelector((state) => state.addWishlist);

  useEffect(() => {
    if (addWishListError) {
      showNotification("error", addWishListError);
    }
    if (wishListData?.success) {
      showNotification("success", wishListData?.message);
      navigate("/student/wishlist");
      dispatch(wishListClearState());
    }
  }, [
    dispatch,
    addWishListError,
    navigate,
    showNotification,
    wishListData?.message,
    wishListData?.success,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            keywords="Alpha Regiment Courses, Best Defence Courses"
            description="A personal interview plays a crucial role in the recommendation during an SSB interview. For effective preparation, consider opting for a mock interview. Prepare comprehensively with Alpha Regiment."
            author="Team Alpha Regiment"
            title="Schedule Your SSB Mock Personal Interview Online/Offline at Your Preferred Time"
            canonical={currentPageUrl}
          />
          <section className="ssb_coaching_courses_main">
            <div className="container">
              <div className="search_course">
                <CustomInput
                  type="search"
                  onChange={handleSearchChange}
                  placeholder="Search"
                  name="search"
                  value={searchCategory}
                  icon={<GoSearch />}
                />
                <button
                  className={courseMode === "Online" ? "active" : ""}
                  onClick={() => handleFilterMode("Online")}
                >
                  online
                </button>
                <button
                  className={courseMode === "offline" ? "active" : ""}
                  onClick={() => handleFilterMode("offline")}
                >
                  offline
                </button>
              </div>

              <div className="all_course_content">
                <div className="all_course_cards">
                  <h1 className="main_title"><span onClick={()=> navigate(-1)}><IoMdArrowRoundBack /></span> Mock Interview</h1>
                  <p className="description">
                    As an SSB interview aspirant, you are aware that SSB
                    interviews utilize three different techniques to evaluate
                    your <b>Officer Like Qualities (OLQs)</b>. One of these key
                    techniques is the personal interview. Once you feel
                    confident in your preparation for the personal interview,
                    you can enroll in a mock personal interview. This can be
                    done both online and offline.
                  </p>
                  {allSsbApiCourses?.length > 0 && (
                    <div className="slider_container">
                      {allSsbApiCourses?.map((item, idx) => {
                        const roundedPrice = Math.ceil(item?.originalPrice);
                        const roundedDiscountedPrice = Math.ceil(
                          item?.discountedPrice
                        );
                        return (
                          <CourseCard
                            key={idx}
                            isActiveClass={true}
                            className="course_card"
                            title={item?.title}
                            description={`${item?.description?.substr(
                              0,
                              40
                            )}...`}
                            icon={item?.thumbnail?.url}
                            startingFrom={item?.starting_date}
                            price={roundedPrice}
                            discountedPrice={roundedDiscountedPrice}
                            numOfReviews={item?.numOfReviews}
                            rating={item?.rating}
                            addWishList={() =>
                              handleAddtoWishlist({
                                itemId: item?._id,
                                isSsb: true,
                              })
                            }
                            deleteWishListLoading={
                              wishListId === item?._id &&
                              deleteLoading && <ButtonLoader />
                            }
                            existInWishList={allWishList?.wishlist?.ssbCourse?.some(
                              (obj) => obj._id === item._id
                            )}
                            addWishListLoading={
                              wishListId === item?._id &&
                              addWishListLoading && <ButtonLoader />
                            }
                            onHomePageClick={() => handleSsbDetailsPage(item)}
                          />
                        );
                      })}
                    </div>
                  )}

                  {allSsbApiCourses?.length < 1 && (
                    <div className="txt_color ssb_not_found courseNotFound_main">
                      <h2 className="txt_center">{t("courseNotFoundTitle")}</h2>
                      <ImgLazyLoad
                        className={"img_load"}
                        src={courseNotFound}
                        alt="courseNotFound"
                      />
                      <p>{t("courseNotFoundDescription")}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>What is a Mock SSB Personal Interview?</h2>
                </div>
                <div className="detail">
                  <p>
                    The Mock SSB personal interview replicates the Personal
                    Interview conducted in the SSB/AFSB/NSB. The only difference
                    is that at the end of the interview, the Interviewing
                    Officer (IO) will share his observations about your
                    performance, identify your shortcomings, and provide
                    guidance on how to improve them.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Can I schedule my Mock Interview at my convenience?</h2>
                </div>
                <div className="detail">
                  <p>
                    Yes, you will be able to schedule your interview even before
                    enrolling.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    What is the duration of the Mock SSB Personal Interview?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    Similar to the real board, a personal interview typically
                    lasts an average of 1 hour, and this is the same for any
                    Mock SSB Mock Interview. However, before ending the session,
                    the interviewing officer will answer all your questions
                    related to the personal interview. Therefore, if you have
                    more questions, the duration of the interview may extend
                    beyond an hour.
                  </p>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>Mock SSB Personal Interview is best for whom?</h2>
                </div>
                <div className="detail">
                  <ol>
                    <li>
                      <b>Repeaters:</b> Any aspirant who was conferenced out in
                      a previous attempt and, upon analysis, realized that the
                      interview was the key factor in not getting recommended
                      can focus on personal interview preparation separately.
                      Once they have completed their preparation, they can
                      choose to take online or offline mock personal interviews,
                      depending on their preference.
                    </li>
                    <li>
                      <b>Preparation in Parts and Pieces:</b> Aspirants who have
                      divided their SSB preparation into three parts—IO, Psych,
                      and GTO. Once they have completed their personal interview
                      preparation and want to assess their readiness, a mock
                      personal interview can be very helpful.
                    </li>
                  </ol>
                </div>
              </div>

              <div className="detail_summary">
                <div className="title">
                  <h2>
                    Can one also enroll separately in Mock Psychology and Mock
                    GTO?
                  </h2>
                </div>
                <div className="detail">
                  <p>
                    Yes, that is also possible. Please follow these links to
                    access the{" "}
                    <Link to="/mock-psychology">Mock Psychology</Link> and{" "}
                    <Link to="/mock-gto">Mock GTO</Link> pages.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default MockInterviewCourse;
