import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_LIVE_CLASS_ERROR,
  CLEAR_LIVE_CLASS_STATE,
  CLEAR_START_CLASS_ERROR,
  CLEAR_START_CLASS_STATE,
  CREATE_LIVE_CLASS_FAIL,
  CREATE_LIVE_CLASS_REQUEST,
  CREATE_LIVE_CLASS_SUCCESS,
  GET_ALL_LIVE_LIST_FAIL,
  GET_ALL_LIVE_LIST_REQUEST,
  GET_ALL_LIVE_LIST_SUCCESS,
  START_LIVE_CLASS_FAIL,
  START_LIVE_CLASS_REQUEST,
  START_LIVE_CLASS_SUCCESS,
} from "../constants/liveClassConstant";

// GET ALL LIVE CLASSES
export const getAllLiveClasses = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_LIVE_LIST_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/get-classes", config);

    dispatch({
      type: GET_ALL_LIVE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_LIVE_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CREATE LIVE CLASS ACTION
export const createLiveClass =
  (title, subject, startTimeString, description, duration, course) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_LIVE_CLASS_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/create-liveClass",
        { title, subject, startTimeString, description, duration, course },
        config
      );

      dispatch({
        type: CREATE_LIVE_CLASS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_LIVE_CLASS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// START LIVE CLASS ACTION
export const startLiveClass = (id) => async (dispatch) => {
  try {
    dispatch({
      type: START_LIVE_CLASS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/start-liveClass/${id}`,
      {},
      config
    );

    dispatch({
      type: START_LIVE_CLASS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: START_LIVE_CLASS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR FOR CLASS ACTION FUNCTION
export const clearLiveClassErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_LIVE_CLASS_ERROR });
};

// CLEAR STATE FOR CLASS ACTION FUNCTION
export const clearLiveClassState = () => async (dispatch) => {
  dispatch({ type: CLEAR_LIVE_CLASS_STATE });
};

// CLEAR STATE FOR LIVE CLASS START
export const clearStartLiveState = () => async (dispatch) => {
  dispatch({ type: CLEAR_START_CLASS_STATE });
};

// CLEAR ERROR FOR LIVE CLASS START
export const clearStartLiveError = () => async (dispatch) => {
  dispatch({ type: CLEAR_START_CLASS_ERROR });
};
