export const DOSSIER_CREATE_REQUEST = "DOSSIER_CREATE_REQUEST";
export const DOSSIER_CREATE_SUCCESS = "DOSSIER_CREATE_SUCCESS";
export const DOSSIER_CREATE_FAIL = "DOSSIER_CREATE_FAIL";


export const DOSSIER_GET_REQUEST = "DOSSIER_GET_REQUEST";
export const DOSSIER_GET_SUCCESS = "DOSSIER_GET_SUCCESS";
export const DOSSIER_GET_FAIL = "DOSSIER_GET_FAIL";

export const DOSSIER_GET_BY_ID_REQUEST = "DOSSIER_GET_BY_ID_REQUEST";
export const DOSSIER_GET_BY_ID_SUCCESS = "DOSSIER_GET_BY_ID_SUCCESS";
export const DOSSIER_GET_BY_ID_FAIL = "DOSSIER_GET_BY_ID_FAIL";

export const DOSSIER_DELETE_REQUEST = "DOSSIER_DELETE_REQUEST";
export const DOSSIER_DELETE_SUCCESS = "DOSSIER_DELETE_SUCCESS";
export const DOSSIER_DELETE_FAIL = "DOSSIER_DELETE_FAIL";


export const CLEAR_DOSSIER_ERRORS = "CLEAR_DOSSIER_ERRORS";
export const CLEAR_DOSSIER_STATE = "CLEAR_DOSSIER_STATE";