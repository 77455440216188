import React, { Fragment, useEffect } from "react";
import "../styles/verification.scss";
import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import logo from "../images/logo.png";
import CustomStepper from "../components/reusable/CustomStepper";
import {
  clearPaymentErrors,
  clearPaymentState,
} from "../redux/actions/instaMojoAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/reusable/Loader";
import { toast } from "react-toastify";
import { Link,  useLocation } from "react-router-dom";
import { removeItemsFromCart } from "../redux/actions/cartAction";
// import { useLocale } from "../context/locale";
import { useNotification } from "../context/notificationContext";
import {
  getSsbCourseDetails,
  ssbClearErrors,
} from "../redux/actions/ssbCourseAction";
// import CustomButton from "../components/reusable/CustomButton";

const VerificationPage = () => {
  // const [t] = useLocale();
  const location = useLocation();
  const courseData = location.state;
  const for_course = courseData?._id;
  // const courseCategory = courseData?.type;
  const courseId = courseData?._id;
  const ssbCourse = courseData?.ssbCourse;
  // const naviagte = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const { loading, paymentData, error } = useSelector(
    (state) => state.userPaymentData
  );

  // useEffect(() => {
  //   if (payment_id && payment_request_id && payment_status && for_course) {
  //     dispatch(
  //       userPaymentData(
  //         payment_id,
  //         payment_request_id,
  //         payment_status,
  //         for_course,
  //         couponCode,
  //         emiId,
  //         seatBooking,
  //         ssbCourse
  //       )
  //     );
  //   }
  // }, [
  //   dispatch,
  //   payment_id,
  //   payment_request_id,
  //   payment_status,
  //   for_course,
  //   couponCode,
  //   emiId,
  //   seatBooking,
  //   ssbCourse,
  // ]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearPaymentErrors);
    }

    if (paymentData?.success) {
      toast.success(paymentData?.message);
      dispatch(clearPaymentState());
    }
  }, [error, dispatch, paymentData?.message, paymentData?.success]);

  // IF PAYMENT DONE REMOVE PAID ITEM FROM CART
  if (for_course) {
    dispatch(removeItemsFromCart(for_course));
  }

  const officerSsbCourseDetails = useSelector(
    (state) => state.getSsbCourseDetails
  );

  const {
    loading: courseLoading,
    error: courseError,
    courseDetails: data,
  } = officerSsbCourseDetails;

  const getCourseById = data?.course;

  useEffect(() => {
    if (ssbCourse) {
      dispatch(getSsbCourseDetails(courseId));
    }
  }, [ssbCourse, courseId, dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(ssbClearErrors());
    }
  }, [courseError, dispatch, showNotification]);

  // NAVIGATE  TO PIQ WITH STATE
  // const handleNavigate = () => {
  //   naviagte("/student/piq", { state: getCourseById });
  // };

  return (
    <Fragment>
      {loading || courseLoading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet />
          <section className="verification_sec">
            <div className="container">
              <div className="verification_content">
                <div className="title">
                  <h1>Verification</h1>
                </div>
                <section className="stepper_style">
                  <CustomStepper activeStep={2} />
                </section>
                <div className="logo_text">
                  <img src={logo} alt="logo" />
                  {getCourseById?.category === "SSB Coaching" &&
                  getCourseById?.courseMode === "Offline" ? (
                    <>
                      <p>
                        The remaining fees, including hostel charges of Rs. 400
                        per day (which also covers meals), can be settled
                        directly at the counter upon your arrival/reporting.
                      </p>
                      {/* <p>{t("verification_page_txt1")}</p>

                      <p> {t("verification_page_txt2")}</p> */}
                    </>
                  ) : (
                    <>
                      <p>
                        Welcome, Alpha Warrior. Making your dream of donning the
                        uniform our mission.
                      </p>
                    </>
                  )}

                  {/* {courseCategory === "Psychology" ||
                  courseCategory === "Interview" ? (
                    <CustomButton
                      title="Schedule Interview"
                      className="schedule_btn"
                      onClick={handleNavigate}
                    />
                  ) : ( */}
                    <Link
                      to="/student/enrolled-courses"
                      className="homepage_btn"
                    >
                      Enrolled Courses
                    </Link>
                  {/* )} */}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default VerificationPage;
