import React, { Fragment, useEffect, useState } from "react";
import "../styles/register.scss";
// import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import CustomInput from "../components/reusable/CustomInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearErrors,
  clearState,
  userRegisterAction,
} from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "../components/reusable/Loader";
import { useAuth } from "../context/userContext";
// import CustomSelect from "../components/reusable/CustomSelect";
import Loader from "../components/reusable/Loader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import { axiosInstance } from "../utils/axiosConfig";
// import { FcGoogle } from "react-icons/fc";
import { FaUpload } from "react-icons/fa";
import { BiLeftArrowAlt } from "react-icons/bi";
// import CustomButton from "../components/reusable/CustomButton";
// import CustomSelect from "../components/reusable/CustomSelect";
import CustomTextarea from "../components/reusable/CustomTextarea";
import { defaultUser } from "../assets/images";
import PopupPrompt from "../components/reusable/PopupPrompt";
import Layout from "../components/reusable/Layout";
import { useNotification } from "../context/notificationContext";
import CustomSelect from "../components/reusable/CustomSelect";

// OFFICER REGISTRATION FORM
const OfficerRegister = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [popupMsg, setPopupMsg] = useState(false);
  const [, , showNotification] = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profile, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(defaultUser);

  const handleAvatarChange = (e) => {
    if (e.target.name === "profile") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // const profileUploadSubmit = (e) => {
  //   e.preventDefault();
  //   if (profile !== "") {
  //     alert("done profile pic");
  //   } else {
  //     toast.error("Please Select a profile picture...");
  //   }
  // };

  const location = useLocation();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const [roleValue, setRoleValue] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [registerData, setRegisterData] = useState({
    first_name: "",
    email: "",
    phone: "",
    bio: "",
    password: "",
    confirmPassword: "",
  });
  const handleFileInputChange = (e) => {
    if (e.target.name === "documentFile") {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setDocumentFile(reader.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

 
  const handleCheckboxChange = (event) => {
    setAcceptTerms(event.target.checked);
  };

  // USER REGISTRATION DATA GET BY API
  const {
    userInfo,
    error: registerError,
    loading: registerLoading,
  } = useSelector((state) => state.register);
  const [auth] = useAuth(); // GET USER FROM USER CONTEXT

  // deStructure The Data
  const { first_name, email, phone, bio, password, confirmPassword } =
    registerData;

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setRegisterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "password") {
      const message =
        value.length < 8 ? "Password must be at least 8 characters" : "";
      setPasswordMessage(message);
    }
  };
  // PASSWORD VALIDATION CHECKED
  const isSubmitDisabled =
    registerData.password.length < 8 ||
    registerData.password !== registerData.confirmPassword;

  const handleRegister = () => {
    if (
      !first_name ||
      !email ||
      !phone ||
      !password ||
      !confirmPassword ||
      !acceptTerms ||
      !documentFile
    ) {
      toast.error("Please fill in all required fields and accept the terms.");
    } else if (password !== confirmPassword) {
      toast.error("Password and confirm password must be the same!");
    } else {
      const teacherBio = registerData.bio;
      const role = roleValue;
      dispatch(
        userRegisterAction(
          first_name,
          "",
          email,
          phone,
          password,
          profile,
          role,
          teacherBio,
          "",
          {},
          {},
          documentFile
        )
      );
    }
  };

  useEffect(() => {
    if (registerError) {
      showNotification("error", registerError);
      dispatch(clearErrors());
    }
    if (userInfo?.success) {
      setPopupMsg(true);
      dispatch(clearState());
    }
    if (auth?.user?._id) {
      navigate(redirect);
    }
  }, [
    registerError,
    userInfo?.success,
    userInfo?.message,
    showNotification,
    navigate,
    dispatch,
    auth?.user?._id,
    redirect,
  ]);

  const registerHandler = () => {
    navigate("/register");
  };
  const handleRedirect = () => {
    setPopupMsg(false);
    navigate("/login");
  };

  return (
    <Fragment>
      {registerLoading ? (
        <Loader />
      ) : (
        <>
          <CustomHelmet
            keywords="Sign up Alpha Regiment "
            description="Use your battlefield experience in the classroom with Alpha Regiment to train future warriors. Empowering retired officers, inspiring the next generation of leaders."
            author="Team Alpha Regiment"
            title="Alpha Regiment : Officers' Registration"
            canonical={window?.location?.href}
          />

          <section className="signup_sec_main">
            <Layout>
              <div className="container">
                <div className="signUpWrapper">
                  <div className="goback">
                    <span
                      onClick={() => registerHandler("")}
                      title="Click here to go back"
                    >
                      <BiLeftArrowAlt />
                    </span>
                    <span>Back</span>
                  </div>
                  <div className="signUp_content">
                    <div className="title">
                      <h1>REGISTER AS OFFICER</h1>
                    </div>

                    <div
                      className="signUp_form"
                      id="officerForm"
                      // ref={officerFormRef}
                    >
                      <h2 className="subheading">Officer Details</h2>
                      <div className="student_form">
                        <div className="halfForm">
                          <div>
                            <CustomInput
                              label="Full Name"
                              impStar="*"
                              type="text"
                              placeholder="Ex: Colonel Vikram"
                              value={first_name}
                              name="first_name"
                              onChange={handleInputChange}
                            />
                            <CustomInput
                              label="Email (Username)"
                              type="email"
                              impStar="*"
                              placeholder="Ex: vikram123@gmail.com"
                              value={email}
                              name="email"
                              onChange={handleInputChange}
                            />
                            <CustomInput
                              label="Whatsapp number"
                              type="number"
                              impStar="*"
                              placeholder="Ex: 9876555432"
                              value={phone}
                              name="phone"
                              onChange={handleInputChange}
                            />
                            <CustomInput
                              label="Upload Document"
                              type="file"
                              impStar="*"
                              name="documentFile"
                              accept=".pdf"
                              tooltip="Please upload a document supporting your claim of being on the selection board or being a recommended candidate."
                              onChange={handleFileInputChange}
                            />
                            <CustomSelect
                              label="You are a"
                              impStar="*"
                              name="roleValue"
                              value={roleValue}
                              onChange={(e) => setRoleValue(e.target.value)}
                              optionArray={[
                                {
                                  value: 16,
                                  option: "GTO",
                                },
                                {
                                  value: 17,
                                  option: "IO",
                                },
                                {
                                  value: 18,
                                  option: "Psychologist",
                                },
                                {
                                  value: 4,
                                  // option: "Were not a member of any selection board",
                                  option: "You are an armed forces officer",
                                },
                                {
                                  value: 5,
                                  label: "Recommended Candidate",
                                },
                              ]}
                            />
                          </div>
                          <div className="uploadPicSec">
                            <p>
                              Enhance your profile by uploading a picture,
                              preferably in uniform or displaying a medical
                              chest number for added effectiveness.
                              <span>*</span>
                            </p>
                            <div className="profile_pic">
                              <img
                                src={
                                  avatarPreview ? avatarPreview : defaultUser
                                }
                                alt="alpha user profile pic"
                              />
                              <label htmlFor="uploadProfilePic">
                                <FaUpload /> Select File
                              </label>
                              <CustomInput
                                id="uploadProfilePic"
                                label="Upload Profile Picture"
                                type="file"
                                impStar="*"
                                name="profile"
                                accept="image/*"
                                onChange={handleAvatarChange}
                              />
                            </div>
                          </div>
                        </div>
                        <CustomTextarea
                          label="Bio (Please write about your journey in armed forces)"
                          impStar="*"
                          type="text"
                          onChange={handleInputChange}
                          placeholder="Word limit 300 words..."
                          name="bio"
                          value={bio}
                          fullWidth
                        />
                        <CustomInput
                          label="Create Password"
                          type={showPassword ? "text" : "password"}
                          impStar="*"
                          placeholder="Choose strong password"
                          eyeIcon={
                            showPassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )
                          }
                          value={password}
                          name="password"
                          passwordMessage={passwordMessage}
                          onChange={handleInputChange}
                          setshowPassword={setshowPassword}
                        />

                        <CustomInput
                          label="Confirm Password"
                          type={showPassword ? "text" : "password"}
                          impStar="*"
                          placeholder="Re-enter password"
                          eyeIcon={
                            showPassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )
                          }
                          value={confirmPassword}
                          name="confirmPassword"
                          onChange={handleInputChange}
                          setshowPassword={setshowPassword}
                        />
                      </div>
                      <label className="pricacyAgreeNote" htmlFor="acceptTerms">
                        <input
                          type="checkbox"
                          name="acceptTerms"
                          id="acceptTerms"
                          checked={acceptTerms}
                          onChange={handleCheckboxChange}
                        />
                        By submitting this form you agree to the{" "}
                        <Link to="/privacy-policies">privacy policy</Link> &{" "}
                        <Link to="/officer-term-conditions">
                          terms and condition
                        </Link>
                        .
                      </label>

                      <button
                        title="Alpha Officer Registration Button"
                        onClick={() => handleRegister()}
                        className={`register_submit_btn ${
                          !acceptTerms || isSubmitDisabled ? "disabled" : ""
                        }`}
                      >
                        Submit
                      </button>
                      <p className="have_account">
                        Already have an account?{" "}
                        <Link to="/login">Login Now!</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>

            {popupMsg && (
              <PopupPrompt
                title={
                  userInfo?.newUser?.role === "13"
                    ? "Welcome to Alpha Regiment! Your profile is currently active with limited functionality. After we verify the details you've provided and confirm that you can contribute high-quality content, we will grant full access to your account. This process may take 3 to 4 working days. Jai Hind!"
                    : "Welcome to the Alpha Regiment! Your account has been successfully created;however, the ability to create any course will be granted once we review your document and confirm your service at SSB/AFSB/NSB, ensuring satisfaction with your military background."
                }
                // lightTitle={true}
                cancelBtnTxt="Okay"
                cancelOnclick={() => handleRedirect()}
              />
            )}
          </section>
        </>
      )}
    </Fragment>
  );
};

export default OfficerRegister;
