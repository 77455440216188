export const MockInterview = [ 
    {
        id:1,
        title:"Rapport building",
        subTitle:"5 min"
    },
    {
        id:2,
        title:"Your education-related questions.",
        subTitle:"10-15 min"
    },
    {
        id:3,
        title:"Your family-related questions.",
        subTitle:"10-15 min"
    },
    {
        id:4,
        title:"Your hobby and Day-routine related questions.",
        subTitle:"10-15 min"
    },
    {
        id:5,
        title:"Current Affairs, Armed Forces, and miscellaneous questions.",
        subTitle:"10-15 min"
    },
    {
        id:6,
        title:"Feedback",
        subTitle:"Till the student is satisfied."
    }
]