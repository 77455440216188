import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { useLocale } from "../../context/locale";
import styled from "styled-components";
import CustomButton from "../reusable/CustomButton";
import { Link } from "react-router-dom";
import startDateBg from "../../images/startingdatebg.png";

const MyCard = styled.div`
  
  ${"" /*width: 28%; */}
  background-color: #121d40;
  border-radius: 16px;
  padding: 40px 20px;
  box-sizing: border-box;
  ${"" /* margin-bottom: 20px; */}
  position: relative;

  @media screen and (max-width: 575px) {
    width: 47%;
    padding: 25px 15px;
  }
  @media screen and (max-width: 470px) {
    width: 100%;
  }

  > .abolute_starting_date {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 10px;
    left: 10px;

    > p {
      position: absolute;
      ${"" /* top: 17px; */}
      ${"" /* left: 5px; */}
      width: 100%;
      height: 100%;
      transform: rotate(-45deg);
      font-size: 12px;
      text-align: center;
      color: #081336;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      box-sizing: border-box;
      > span {
        font-weight: 600;
      }
    }
  }

  > .card_content {
    color: #f2f2f2;

    > h2 {
      font-family: "casanova", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      text-align: right;
      letter-spacing: 0.1em;
      width: 150px;
      margin: 0 0 0 auto;
      @media screen and (max-width: 991px) {
        font-size: 18px;
        width: 120px;
      }
      @media screen and (max-width: 850px) {
        font-size: 16px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
        letter-spacing: 0.05em;
      }
    }
    > .icon {
      > img {
        width: 100%;
        ${"" /* height: 135px; */}
        object-fit: contain;
        ${"" /* border-radius: 8px; */}
        display: block;
        margin: 30px auto;
        @media screen and (max-width: 575px) {
          margin: 25px auto;
        }
      }
    }
    > p.price {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.06rem;
      > span {
        &.discountedPrice {
          color: #f2f2f2;
          margin-right: 15px;
        }
        &.actualPrice {
          text-decoration: line-through;
          font-weight: 400;
          color: #a9b5bb;
        }
      }
    }
    > p.desc {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.06rem;
      ${
        "" /* @media screen and (max-width: 767px) {
         display: none;
       } */
      }
    }
    > button.explore_btn {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      height: 40px;
      border-radius: 8px;
      width: 100%;
      margin-top: 25px;
      cursor: pointer;
      > .icon {
        margin-left: 5px;
      }
    }

    > .enroll_btn {
      margin-top: 20px;
      color: #f2f2f2;
      background-color: transparent;
      padding: 10px;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      display: block;
      text-align: center;
      transition: 0.4s;

      &:hover {
        background-color: #f2f2f2;
        color: rgb(18, 29, 64);
      }
    }
  }
`;

const SingleCourseCard = ({
  title,
  description,
  icon,
  onClick,
  discountedPrice,
  actualPrice,
  link,
  startingFrom = "05 July 2023",
}) => {
  const [t] = useLocale();

  return (
    <MyCard className="course_card">
      <div className="abolute_starting_date">
        <p>
          <span>{startingFrom}</span>
        </p>
        <img src={startDateBg} alt="" width="100%" height="100%" />
      </div>
      <div className="card_content">
        <h2>{title}</h2>
        <div className="icon">
          <img src={icon} alt={title} />
        </div>
        {discountedPrice && actualPrice && (
          <p className="price">
            <span className="discountedPrice">₹{discountedPrice}</span>
            <span className="actualPrice">₹{actualPrice}</span>
          </p>
        )}
        <p className="desc">{description}</p>

        {onClick && (
          <CustomButton
            className="explore_btn"
            title={t("exploreBtn")}
            onClick={onClick}
            icon={<BsChevronRight className="icon" />}
            width="100%"
          />
        )}
        {link && (
          <Link to={link} className="enroll_btn">
            Enroll Now
          </Link>
        )}
      </div>
    </MyCard>
  );
};

export default SingleCourseCard;
