import React, { Fragment, useEffect, useState } from "react";
import "../styles/register.scss";
import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import CustomInput from "../components/reusable/CustomInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearErrors,
  clearState,
  userRegisterAction,
} from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "../components/reusable/Loader";
import { useAuth } from "../context/userContext";
// import CustomSelect from "../components/reusable/CustomSelect";
import Loader from "../components/reusable/Loader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiLeftArrowAlt } from "react-icons/bi";

const StudentRegister = () => {
  const [showPassword, setshowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/student/profile";

  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    bio: "",
    password: "",
    confirmPassword: "",
  });

  // USER REGISTRATION DATA GET BY API
  const {
    userInfo,
    error: registerError,
    loading: registerLoading,
  } = useSelector((state) => state.register);
  const [auth] = useAuth(); // GET USER FROM USER CONTEXT

  // deStructure The Data
  const {
    first_name,
    last_name,
    email,
    phone,
    password,
    confirmPassword,
  } = registerData;

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setRegisterData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegister = () => {
    if (password !== confirmPassword) {
      toast.error("Password and confirm password must be same!");
    } else {
      dispatch(
        userRegisterAction(first_name,last_name,email,phone,password)
      );
    }
  };

  useEffect(() => {
    if (registerError) {
      toast.error(registerError);
      dispatch(clearErrors());
    }
    if (userInfo?.success) {
      toast.success(userInfo?.message);
      navigate("/login");
      dispatch(clearState());
    }
    if (auth?.user?._id) {
      navigate(redirect);
    }
  }, [
    registerError,
    userInfo?.success,
    userInfo?.message,
    navigate,
    dispatch,
    auth?.user?._id,
    redirect,
  ]);

  // REGISTER URL REQUEST WITH GOOGLE
  // const googleLoginHadler = () => {
  //   window.open(`${process.env.REACT_APP_API}/googlelogin`, "_self");
  // };

  // const regColorBtn = useRef();
  // const studentFormRef = useRef();
  // const officerFormRef = useRef();
  // const studentFormBtn = useRef();
  // const officerFormBtn = useRef();

  // useEffect(() => {
  //   officerFormRef.current.style.display = "none";
  //   studentFormBtn.current.style.color = "#081336";
  // }, []);

  // const studentForm = () => {
  //   regColorBtn.current.style.left = "0";
  //   studentFormRef.current.style.display = "block";
  //   officerFormRef.current.style.display = "none";
  //   studentFormBtn.current.style.color = "#081336";
  //   officerFormBtn.current.style.color = "#f2f2f2";
  // };

  // const officerForm = () => {
  //   regColorBtn.current.style.left = "110px";
  //   studentFormRef.current.style.display = "none";
  //   officerFormRef.current.style.display = "block";
  //   studentFormBtn.current.style.color = "#f2f2f2";
  //   officerFormBtn.current.style.color = "#081336";
  // };

  const registerHandler = () => {
    navigate("/register")
  }
  return (
    <Fragment>
      {registerLoading ? (
        <Loader />
      ) : (
        <>
          <CustomHelmet
            keywords="Sign up Alpha Regiment "
            description="Take the first step toward your goal of serving the motherland in uniform. Alpha Regiment, Empowering teachers, inspiring students, and democratizing education for all."
            author="Team Alpha Regiment"
            title="Alpha Regiment : Student Registration"
            canonical={window?.location?.href}
          />

          <section className="signup_sec_main">
            <div className="container">
            <Layout>
              <div className="signUpWrapper">
                <div className="goback">
                  <span
                    onClick={() => registerHandler("")}
                    title="Click here to go back"
                  >
                    <BiLeftArrowAlt />
                  </span>
                  <span>Back</span>
                </div>
                <div className="signUp_content">
                  <div className="title">
                    <h1>REGISTER AS STUDENT</h1>
                  </div>

                  <section className="stepper_style">
                    {/* <CustomStepper activeStep={0} /> */}
                  </section>

                  <div
                    className="signUp_form"
                    id="studentForm"
                  // ref={studentFormRef}
                  >
                    <h2 className="subheading">Student Details</h2>
                    <div className="student_form">
                      <CustomInput
                        label="First Name"
                        impStar="*"
                        type="text"
                        placeholder="Ex: Ashish"
                        value={first_name}
                        name="first_name"
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Last Name"
                        type="text"
                        placeholder="Ex: Singh"
                        value={last_name}
                        name="last_name"
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Email"
                        type="email"
                        impStar="*"
                        placeholder="Ex: ashishverma123@gmail.com"
                        value={email}
                        name="email"
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Whatsapp number"
                        type="number"
                        impStar="*"
                        placeholder="Ex: 9876555432"
                        value={phone}
                        name="phone"
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Create Password"
                        type={showPassword ? "text" : "password"}
                        impStar="*"
                        placeholder="Choose strong password"
                        eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                        value={password}
                        name="password"
                        onChange={handleInputChange}
                        setshowPassword={setshowPassword}
                      />
                      <CustomInput
                        label="Confirm Password"
                        type={showPassword ? "text" : "password"}
                        impStar="*"
                        placeholder="Re-enter password"
                        eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                        value={confirmPassword}
                        name="confirmPassword"
                        onChange={handleInputChange}
                        setshowPassword={setshowPassword}
                      />
                    </div>
                    <button onClick={handleRegister} className="register_submit_btn">
                      Submit
                    </button>
                    <p className="have_account">
                      Already have an account? <Link to="/login">Login Now!</Link>
                    </p>
                  </div>
                </div>
              </div>
              </Layout>
            </div>


          </section>
        </>
      )}
    </Fragment>
  );
};

export default StudentRegister;
