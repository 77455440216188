import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import ButtonLoader from "./ButtonLoader";
import { IoClose } from "react-icons/io5";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import CustomButton from "./CustomButton";

const PromptSection = styled.section`
  height: 100vh;
  width: 100%;
  background-color: rgba(8, 19, 54, 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;
  overflow-y: scroll;

  > .closeBtn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    transition: 0.4s;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #081336;
    z-index: 2;
    border: 1px solid #f2f2f2;

    &:hover {
      background-color: #081336;
      color: #f2f2f2;
    }
  }

  > .promt_mess_sec {
    width: 700px;
    background: #081336;
    padding: 30px 15px 15px 15px;
    box-sizing: border-box;
    border-radius: 15px;
    position: absolute;
    top: 0;
    // left: 50%;
    // transform: translate(-50%, -50%);

    @media screen and (max-width: 767px) {
      width: 540px;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding-top: 60px;
    }

    .batch_timing_main {
      padding-top: 10px;
    }
    .timing_calendar {
      margin-top: 0px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 40px;

      > h2 {
        font-family: $monserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 120%;
        color: #fff;
        letter-spacing: 0.1em;

        @media screen and (max-width: 991px) {
          // margin-left: 40px;
          font-size: 25px;
        }

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      > button {
        margin-right: 15px;
        margin-left: auto;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: none;
        outline: none;
        cursor: pointer;
        color: rgb(18, 29, 64);
        background-color: #fff;
        transition: 0.4s;

        > svg {
          width: 30px;
          height: 30px;
          @media (max-width: 567px) {
            width: 18px;
            height: 18px;
          }
        }

        &:hover {
          color: #fff;
          background-color: rgb(18, 29, 64);
        }
      }
    }

    > .prompt-title {
      text-align: center;
      color: #fff;
      font-size: 20px;
      margin-bottom: 30px;
    }

    > .btn_grps {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 30px;

      > button {
        width: 100px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #081336;
        cursor: pointer;
        font-size: 18px;

        &:first-child {
          color: #f2f2f2;
          background-color: red;
        }
        &:last-child {
          color: #f2f2f2;
          background-color: #081336;
        }

        &:hover {
          background-color: transparent;
          color: #081336;
        }
      }
    }
  }
`;

const CustomModal = ({
  confirmBtnTxt,
  className,
  confirmOnclick,
  cancelOnclick,
  title,
  lightTitle,
  loading,
  tableData,
  handleTeacherEvent,
}) => {
  return (
    <Fragment>
      <PromptSection>
        <button
          title="Close Popup"
          className="closeBtn"
          onClick={cancelOnclick}
        >
          <IoClose />
        </button>
        <div className={`promt_mess_sec ${className ? className : ""}`}>
          {tableData?.length > 0 &&
            (!lightTitle ? (
              <h2 className="prompt-title">{title}</h2>
            ) : (
              <p className="prompt-title">{title}</p>
            ))}
          {!tableData?.length && (
            <div className="title">
              <h2>Schedule Event</h2>
              <button title="Add Working hrs" onClick={handleTeacherEvent}>
                <FaPlus />
              </button>
            </div>
          )}
          {tableData?.length > 0 && (
            <div className="batch_timing_main officer_studentList_main txt_color">
              <div className="timing_calendar">
                <div className="timing_table custonScroll">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S NO.</th>
                        <th>Title</th>
                        <th>Student Name</th>
                        <th>Start Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            <p>{idx + 1}</p>
                          </td>
                          <td>
                            <p>{item?.title}</p>
                          </td>
                          <td>
                            <p>{item?.studentData?.first_name}</p>
                          </td>
                          <td>
                            <p>{moment(item?.start).format("hh:mm:ss A")}</p>
                          </td>
                          <td>
                            <p>
                              <CustomButton title="Join" width="70px"/>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="btn_grps">
            {confirmOnclick && confirmBtnTxt !== "" && (
              <button onClick={confirmOnclick}>
                {loading ? <ButtonLoader /> : confirmBtnTxt}
              </button>
            )}
          </div>
        </div>
      </PromptSection>
    </Fragment>
  );
};

export default CustomModal;
