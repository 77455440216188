import { ACCEPT_CONNECTION_FAIL, ACCEPT_CONNECTION_REQUEST, ACCEPT_CONNECTION_SUCCESS, CLEAR_CONNECTION_ERROR, CLEAR_CONNECTION_STATE, CLEAR_REMOVE_CONNECTION_STATE, CONNECTION_REQUEST_FAIL, CONNECTION_REQUEST_SEND, CONNECTION_REQUEST_SUCCESS, REJECT_CONNECTION_FAIL, REJECT_CONNECTION_REQUEST, REJECT_CONNECTION_SUCCESS, REMOVE_CONNECTION_FAIL, REMOVE_CONNECTION_REQUEST, REMOVE_CONNECTION_SUCCESS } from "../constants/connectConstant";

//SEND CONNECTION REQUEST ======
export const sendConnectReducer = (state = {}, action) => {
    switch (action.type) {
      case CONNECTION_REQUEST_SEND:
        return {
          ...state,
          loading: true,
        };
      case CONNECTION_REQUEST_SUCCESS:
        return {
          loading: false,
          connectionInfo: action.payload,
        };
      case CONNECTION_REQUEST_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CONNECTION_ERROR:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      case CLEAR_CONNECTION_STATE:
        return {
          loading: false,
          connectionInfo: null,
        };
  
      default:
        return state;
    }
  };
  
  // ACCEPT FOLLOW REQUEST
  export const acceptConnectionReducer = (state = {}, action) => {
    switch (action.type) {
      case ACCEPT_CONNECTION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ACCEPT_CONNECTION_SUCCESS:
        return {
          loading: false,
          connectionInfo: action.payload,
        };
      case ACCEPT_CONNECTION_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CONNECTION_ERROR:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      case CLEAR_REMOVE_CONNECTION_STATE:
        return {
          loading: false,
          connectionInfo: null,
        };
  
      default:
        return state;
    }
  };

   // ACCEPT FOLLOW REQUEST
   export const rejectConnectionReducer = (state = {}, action) => {
    switch (action.type) {
      case REJECT_CONNECTION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case REJECT_CONNECTION_SUCCESS:
        return {
          loading: false,
          connectionInfo: action.payload,
        };
      case REJECT_CONNECTION_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CONNECTION_ERROR:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      case CLEAR_REMOVE_CONNECTION_STATE:
        return {
          loading: false,
          connectionInfo: null,
        };
  
      default:
        return state;
    }
  };
  
  
  // REMOVE CONNECTION =====>
  export const removeConnectionReducer = (state = {}, action) => {
    switch (action.type) {
      case REMOVE_CONNECTION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case REMOVE_CONNECTION_SUCCESS:
        return {
          loading: false,
          connectionInfo: action.payload,
        };
      case REMOVE_CONNECTION_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CONNECTION_ERROR:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      case CLEAR_REMOVE_CONNECTION_STATE:
        return {
          loading: false,
          connectionInfo: null,
        };
  
      default:
        return state;
    }
  };