import React, { useEffect, useState } from "react";
import "../../styles/chat/chatSidebar.scss";
import { defaultUser } from "../../assets/images";
import { MdOutlineMessage, MdOutlineSearch } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useChatAuth } from "../../context/chatContext";
import { useAuth } from "../../context/userContext";
import { HiOutlineStatusOnline } from "react-icons/hi";
import moment from "moment";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { BiLoader } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { proSocketListeners } from "../webRTC/utils/proSocketListeners";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ChatSidebar = ({
  conversation,
  conversationLoading,
  showSidebar,
  setShowSidebar,
}) => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const query = useQuery();
  const chatId = query.get("chatId");
  console.log(chatId);
  const user = auth?.user;
  const [conversations, setConversations] = useState([]);
  const [searchString, setSearchString] = useState("");
  const { person, setPerson, conversationId, setConversationId, activeUsers } =
    useChatAuth();

  const myConversation = conversation?.myConversation;

  const handleAddToChat = (item) => {
    // console.log(item);
    setConversationId(item?._id);
    setPerson(item?.members[0]);
    if (item?._id) {
      setShowSidebar(false);
    }
  };

  useEffect(() => {
    if (chatId) {
      setConversationId(chatId);
    }
  }, [chatId, setConversationId]);

  useEffect(() => {
    if (searchString) {
      const filteredData = myConversation.filter((val) =>
        val?.members[0]?.first_name
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
      setConversations(filteredData);
    } else {
      setConversations(myConversation);
    }
  }, [searchString, myConversation]);

  const handleCloseSidebar = () => {
    if (person?._id) {
      setShowSidebar(false);
    }
  };

  // VIDEO CALL TEMP SYSTEM START
  const { socket } = useChatAuth();
  const dispatch = useDispatch();
  const [apptInfo, setApptInfo] = useState([]);
  useEffect(() => {
    if (socket) {
      proSocketListeners(socket, setApptInfo, dispatch);
    }
  }, [socket, dispatch]);
  // console.log("apptInfo", apptInfo);

  const joinCall = (appt) => {
    // console.log("appt", appt);
    navigate(
      `/join-video-pro?uuid=${appt.uuid}&client=${appt.client.name}`
    );
  };

  // VIDEO CALL TEMP SYSTEM ENDS

  return (
    <div
      className={`chatSidebar_main ${showSidebar ? "visibleSidebar" : ""}`}
      style={{ boxShadow: !showSidebar && "none" }}
    >
      <div className="topBar">
        <div
          className="backBtn"
          title="Close Chats"
          onClick={handleCloseSidebar}
        >
          <MdKeyboardDoubleArrowLeft />
        </div>
        <div className="myDp">
          <img
            src={user?.profile ? user?.profile?.url : defaultUser}
            alt={user?.first_name}
          />
        </div>
        <div className="toggleBar">
          <span>
            <MdOutlineMessage />
          </span>
          <span>
            <BsThreeDotsVertical />
          </span>
        </div>
      </div>
      <div className="searchBar">
        <MdOutlineSearch />
        <input
          type="search"
          placeholder="search or start new chat"
          name="searchString"
          id="searchString"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </div>
      <div className="userList custonScroll">
        {conversationLoading ? (
          <div className="noItemFound">
            <BiLoader />
          </div>
        ) : (
          conversations?.map((itm, idx) => {
            return (
              <div
                className={`user ${
                  itm?._id === conversationId ? "active" : ""
                }`}
                key={idx}
                onClick={() => handleAddToChat(itm)}
              >
                <div className="details">
                  <img
                    src={
                      itm?.members[0]?.profile
                        ? itm?.members[0]?.profile?.url
                        : defaultUser
                    }
                    alt={itm?.members[0]?.first_name}
                  />
                  <p>
                    <span className="userName">
                      {itm?.members[0]?.first_name.length > 12
                        ? itm?.members[0]?.first_name.substring(0, 12)
                        : itm?.members[0]?.first_name}
                      {activeUsers.find(
                        (user) => user?.userData?._id === itm?.members[0]?._id
                      ) && <HiOutlineStatusOnline title="active" />}
                    </span>
                    <span className="sentMsg">
                      {itm?.message && itm?.message?.length > 17
                        ? itm?.message.substring(0, 17) + "..."
                        : itm?.message}
                    </span>
                  </p>
                </div>
                <div className="msgSentTime">
                  {moment(itm?.updatedAt).format("hh:mm A")}
                </div>
              </div>
            );
          })
        )}
        {apptInfo?.map((a) => (
          <div key={a.uuid} className="txt_color">
            <p>{a.client.name}</p>
            <p>{moment(a.apptDate).calendar()}</p>
            {a.waiting ? (
              <>
                <div className="waiting-text">Waiting</div>
                <button className="join-button" onClick={() => joinCall(a)}>
                  Join
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatSidebar;
