import React, { Fragment, useEffect } from "react";
import "../../styles/studentCMS.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { GoSearch } from "react-icons/go";
// import { currentHappeningArray, hotHeadingArray } from "../../dummyContent";
import CustomButton from "../../components/reusable/CustomButton";
import { BsChevronRight } from "react-icons/bs";
import { blog1, staticImg } from "../../assets/images";
import {
  clearOfficerErrors,
  getAllTimelinePostAction,
} from "../../redux/actions/officerAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "../../components/reusable/Loader";
import moment from "moment";
import { clearBlogErrors, getAllBlogs } from "../../redux/actions/blogAction";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/reusable/Layout";
import CustomHelmet from "../../components/reusable/Helmet";
// import LatestNews from "../../components/home/LatestNews";
// import latest_news_light from "../../images/latest_news_light.png";
// import {
//   getAllRssBlogs,
//   getAllRssBlogsHindi,
// } from "../../redux/actions/rssBlogAction";
// import { useLocale } from "../../context/locale";

const StudentCMS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [t] = useLocale();
  const {
    error: getPostListError,
    loading: getPostListLoading,
    postData: postList,
  } = useSelector((state) => state.getAllPostTimeline);
  useEffect(() => {
    dispatch(getAllTimelinePostAction());
  }, [dispatch]);

  useEffect(() => {
    if (getPostListError) {
      dispatch(clearOfficerErrors());
    }
  }, [getPostListError, dispatch]);

  // GET BLOG LIST
  const {
    error: blogListError,
    loading: blogListLoading,
    blogs,
  } = useSelector((state) => state.getBlogsList);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  useEffect(() => {
    if (blogListError) {
      dispatch(clearBlogErrors());
    }
  }, [blogListError, dispatch]);

  // console.log(blogs?.blogs);

  const handleBlogDetails = (id) => {
    navigate(`/blog/${id}`);
  };

  // const { blogs: blogData, loading } = useSelector((state) => state.getBlogs);

  // const allBlogPosts = blogData?.items; // store APIs blog post in (allBlogPosts)

  // GET LANGUAGE FROM LOCALSTORAGE --- which stored in localstorage by localeContext....
  // const getLocalStorageLang = localStorage.getItem("lang")
  //   ? localStorage.getItem("lang")
  //   : "en";

  // useEffect(() => {
  //   if (getLocalStorageLang === "hi") {
  //     dispatch(getAllRssBlogsHindi()); // function for getting RSS Blog Posts in HINDI
  //   } else {
  //     dispatch(getAllRssBlogs()); // function for getting RSS Blog Posts in ENGLISH
  //   }
  // }, [getLocalStorageLang, dispatch]);


  return (
    <Fragment>
      {getPostListLoading || blogListLoading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
          description="Start reading about vacancies, preparation strategies, high quality content, see your result, read and share the success stories all at one place that is Alpha Regiment."
          author="Team Alpha Regiment"
          title="Know your defence forces with Alpha Regiment where only expert writes."
          canonical={window?.location?.href}
           />
          <section className="student_cms_main txt_color">
            <div className="saerch_bar">
              <div className="container">
                <CustomInput
                  icon={<GoSearch />}
                  type="search"
                  placeholder="Search"
                  name="seachString"
                  impStar="*"
                />
              </div>
            </div>

            {/* CURRENT HAPPENING SECTION START */}
            {postList?.allPosts?.length > 0 && (
              <div className="current_happenings">
                <div className="container">
                  <div className="title">
                    <h2>Current happenings</h2>
                  </div>

                  <div className="current_happenings_content custonScroll">
                    {postList?.allPosts
                      ?.filter((itm) => itm?.thumbnail?.url === "")
                      ?.slice(0, 4)
                      ?.map((item, idx) => (
                        <div
                          className="current_happenings_card"
                          key={item?._id}
                        >
                          <div className="current_happenings_card_content">
                            <img
                              src={item?.createdBy?.profile?.url}
                              alt={item?.createdBy?.first_name}
                            />
                            <div className="card_content">
                              <div className="content">
                                {item.label.slice(0, 100)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {/* CURRENT HAPPENING SECTION ENDS */}

            {/* Latest News Start */}
            {/* <div className="latest_news_main">
              <div className="container">
                <div className="title">
                  <h2>{t("latestNewsHeading")}</h2>
                </div>
              </div>
              <div className="latest_news_card">
                {loading ? (
                  <h3>Loading...</h3>
                ) : (
                  <LatestNews allBlogPosts={allBlogPosts} />
                )}
              </div>
              <img
                src={latest_news_light}
                alt="Alpha Regiment latest_news_light"
                className="heighlight_img"
              />
            </div> */}
            {/* Latest News Ends */}

            {/* HOT HEADLINE SECTION START */}
            {postList?.allPosts?.length > 0 && (
              <div className="hot_headline_main">
                <div className="container">
                  <div className="title">
                    <h2>Hot Headline</h2>
                  </div>

                  <div className="hot_headline_content">
                    {postList?.allPosts
                      ?.filter((itm) => itm?.thumbnail?.url !== "")
                      ?.slice(0, 4)
                      ?.map((item) => (
                        <div
                          className="hotHeadlineCard"
                          // onClick={() => handleBlogDetails(item?._id)}
                          key={item?._id}
                        >
                          <img
                            src={item.thumbnail?.url}
                            alt={item.createdBy?.first_name}
                          />
                          <div className="hotHeadlineCardContent">
                            {/* <div className="title">{item.title}</div> */}
                            <div className="content">
                              {item.label?.slice(0, 120)}
                            </div>
                            <CustomButton
                              title="View"
                              // onClick={() => handleBlogDetails(item?._id)}
                              icon={<BsChevronRight />}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}

            {/* HOT HEADLINE SECTION ENDS */}

            {/* OUR BLOGS SECTION START */}
            <div className="our_blog_main">
              <div className="container">
                <div className="title">
                  <h2>Blogs</h2>
                </div>

                {blogs?.blogs?.length > 0 ? (
                  <div className="our_blog_content">
                    {blogs?.blogs?.reverse()?.map((item) => (
                      <div
                        className="our_blog_card"
                        onClick={() => handleBlogDetails(item?._id)}
                        key={item?._id}
                      >
                        <img
                          src={
                            item?.image ? item?.image?.url : staticImg
                          }
                          alt={item?.createdBy?.first_name}
                        />
                        <div className="ourBlogCard_content">
                          <div className="time">
                            {moment(item?.updatedAt).format("MMMM DD[,] YYYY")}
                          </div>
                          <div className="content">
                            {item?.title.slice(0, 100)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <OfficerNoDataCard txt="No blog post yet" />
                )}
              </div>
            </div>
            {/* OUR BLOGS SECTION ENDS */}
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default StudentCMS;
