// this function job is to update all peerConnections (addTracks) and update redux callStatus

import updateCallStatus from "../../../../redux/actions/updateCallStatus";

const startLocalVideoStream = (streams, dispatch) => {
  const localStream = streams.localStream;
  console.log("localStream", localStream);
  for (const s in streams) {
    // s is the key
    if (s !== "localStream") {
      // We dont add tracks in localStream
      const curStream = streams[s];
      console.log("curStream", curStream);
      // addTracks to all  peerConnections
      localStream.stream.getVideoTracks().forEach((t) => {
        curStream.peerConnection.addTrack(t, localStream.stream);
      });
      // update redux callStatus
      dispatch(updateCallStatus("video", "enabled"));
    }
  }
};

export default startLocalVideoStream;
