import React from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { arCoin } from "../../assets/images";

const MyCard = styled.div`
  padding: 15px;
  border-radius: 16px;
  background: linear-gradient(
    116.1deg,
    rgba(255, 255, 255, 0.4) -106.73%,
    rgba(255, 255, 255, 0) 108.62%
  );

  h2 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #f2f2f2;
    margin-bottom: 15px;
  }

  li {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
    color: #f2f2f2;
    margin-left: 15px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .chart {
    > .chart_line {
      height: 20px;
      border: 1px solid #f2f2f2;
      border-radius: 50px;
      position: relative;
      margin: 30px 0;

      &:after {
        background-color: ${(props) => props.color};
        content: "";
        display: block;
        height: 20px;
        width: ${(props) => props.range};
        max-width: 100%;
        border-radius: 50px;
      }
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 11px;
        text-shadow: 0px 0px 2px #000;
      }
    }

    > .circle_chart_main {
      display: flex;
      align-items: center;

      > .circle_chart {
        width: 64px;
        height: 64px;
        ${"" /* background-color: red; */}
        border-radius: 100%;
        position: relative;
        margin: 20px 0 0;

        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 11px;
          text-shadow: 0px 0px 2px #000;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;

          background: linear-gradient(
            116.1deg,
            rgba(255, 255, 255, 0.4) -106.73%,
            rgba(255, 255, 255, 0) 108.62%
          );
          box-shadow: inset 0.888889px 0.888889px 1.77778px
              rgba(210, 215, 230, 0.3),
            inset -0.888889px -0.888889px 1.77778px rgba(5, 12, 34, 0.5);
          filter: drop-shadow(
              -1.77778px 1.77778px 3.55556px rgba(5, 12, 34, 0.2)
            )
            drop-shadow(1.77778px -1.77778px 3.55556px rgba(5, 12, 34, 0.2))
            drop-shadow(-1.77778px -1.77778px 3.55556px rgba(13, 30, 84, 0.9))
            drop-shadow(1.77778px 1.77778px 4.44444px rgba(5, 12, 34, 0.9));
        }
      }

      > .chart_details {
        margin-left: 15px;

        > p {
          display: flex;
          align-items: center;
          font-size: 11px;
          flex-wrap: wrap;

          &:first-child {
            margin-bottom: 10px;
          }

          > span {
            &:not(:last-child) {
              margin-right: 5px;
            }

            &:first-child {
              width: 10px;
              height: 10px;
              border-radius: 100%;

              &.attended {
                background-color: #44da5c;
              }
              &.notAttended {
                background-color: #f2f2f2;
              }
            }
          }
        }
      }
    }

    > .ar_coins {
      display: flex;
      align-items: center;

      > img {
        margin-right: 50px;
        width: 64px;
        height: 64px;
        animation: rotateLoader infinite 15s linear;

        @media screen and (max-width: 423px) {
          margin-right: 20px;
        }
      }
      > p {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 120%;
        color: #f2f2f2;
      }
    }
  }
`;

const PerformanceCard = ({
  children,
  title,
  range = "0%",
  chartLine,
  circleChart,
  attended = "125",
  totalClasses = "135",
  arCoinsNumber,
}) => {
  ChartJS.register(ArcElement);
  const doughnutState = {
    labels: ["Attended", "Not Attended"],
    datasets: [
      {
        backgroundColor: ["#44DA5C", "#f2f2f2"],
        hoverBackgroundColor: ["#329E43", "#C7C7C7"],
        data: [attended, totalClasses - attended],
      },
    ],
  };

  return (
    <MyCard
      className="performance_card"
      range={range}
      color={
        range > "60%"
          ? "#47EC61"
          : range > "30%"
          ? "#0049C6"
          : range >= "0%"
          ? "#D0333B"
          : ""
      }
    >
      <h2 className="card_heading">{title}</h2>
      <div className="chart">
        {chartLine && (
          <div className="chart_line">
            <p>{range}</p>
          </div>
        )}

        {circleChart && (
          <div className="circle_chart_main">
            <div className="circle_chart">
              <Doughnut data={doughnutState} />
              <p>{attended}</p>
            </div>
            <div className="chart_details">
              <p>
                <span className="attended"></span>
                <span>Attended:</span> <span>{attended}</span>
              </p>
              <p>
                <span className="notAttended"></span>
                <span>Not Attended:</span>
                <span>{totalClasses - attended}</span>
              </p>
            </div>
          </div>
        )}

        {arCoinsNumber && (
          <div className="ar_coins">
            <img src={arCoin} alt="Alpha Regiment Coin" />
            <p>{arCoinsNumber}</p>
          </div>
        )}
      </div>
      <div className="card_details">{children}</div>
    </MyCard>
  );
};

export default PerformanceCard;
