export const CREATE_CONVERSATION_REQUEST = "CREATE_CONVERSATION_REQUEST";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_FAIL = "CREATE_CONVERSATION_FAIL";

export const GET_MY_CONVERSATION_REQUEST = "GET_MY_CONVERSATION_REQUEST";
export const GET_MY_CONVERSATION_SUCCESS = "GET_MY_CONVERSATION_SUCCESS";
export const GET_MY_CONVERSATION_FAIL = "GET_MY_CONVERSATION_FAIL";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";

export const GET_MESSAGE_REQUEST = "GET_MESSAGE_REQUEST";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAIL = "GET_MESSAGE_FAIL";

export const UPDATE_MESSAGE_REQUEST = "UPDATE_MESSAGE_REQUEST";
export const UPDATE_MESSAGE_SUCCESS = "UPDATE_MESSAGE_SUCCESS";
export const UPDATE_MESSAGE_FAIL = "UPDATE_MESSAGE_FAIL";

export const CLEAR_CHAT_ERROR = "CLEAR_CHAT_ERROR";
export const CLEAR_CHAT_STATE = "CLEAR_CHAT_STATE";
