import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  CLEAR_PROFILE_ERRORS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  CLEAR_USER_LOADING,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  CLEAR_UPDATE_STATE,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  REMOVE_AVATAR_REQUEST,
  REMOVE_AVATAR_SUCCESS,
  REMOVE_AVATAR_FAIL,
  AFFILIATE_DASHBOARD_REQUEST,
  AFFILIATE_DASHBOARD_SUCCESS,
  AFFILIATE_DASHBOARD_FAIL,
  GET_AFFILIATE_DASHBOARD_REQUEST,
  GET_AFFILIATE_DASHBOARD_SUCCESS,
  GET_AFFILIATE_DASHBOARD_FAIL,
  COUPON_CODE_REQUEST,
  COUPON_CODE_SUCCESS,
  COUPON_CODE_FAIL,
  GET_ENROLLED_USERS_REQUEST,
  GET_ENROLLED_USERS_SUCCESS,
  GET_ENROLLED_USERS_FAIL,
  GET_AFF_NOTIFICATION_REQUEST,
  GET_AFF_NOTIFICATION_SUCCESS,
  GET_AFF_NOTIFICATION_FAIL,
  GET_DOWNLOAD_NOTIFICATION_REQUEST,
  GET_DOWNLOAD_NOTIFICATION_SUCCESS,
  GET_DOWNLOAD_NOTIFICATION_FAIL,
  CLEAR_COUPON_CODE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  ENROLL_STUDENT_LIST_REQUEST,
  ENROLL_STUDENT_LIST_SUCCESS,
  ENROLL_STUDENT_LIST_FAIL,
  COURSE_COUPON_CREATE_REQUEST,
  COURSE_COUPON_CREATE_SUCCESS,
  COURSE_COUPON_CREATE_FAIL,
  CLEAR_CREATE_COUPON_STATE,
  USER_DETAILS_BY_ID_REQUEST,
  USER_DETAILS_BY_ID_SUCCESS,
  USER_DETAILS_BY_ID_FAIL,
  USER_LIST_BY_ROLE_REQUEST,
  USER_LIST_BY_ROLE_SUCCESS,
  USER_LIST_BY_ROLE_FAIL,
} from "../constants/userConstant";

// FOR USER REGISTRATION
export const userRegister = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case REGISTER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// USER LOGIN REDUCER
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        loading: false,
        isAuthenticated: false,
        userInfo: null,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        isAuthenticated: false,
        error: null,
        userInfo: {},
      };
    case CLEAR_USER_LOADING:
      return {
        ...state,
        loading: false,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };
    case USER_PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };
    case USER_PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: null,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// GET USER DETAILS BY ID REDUCER
export const userDetailsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_BY_ID_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case USER_DETAILS_BY_ID_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_DETAILS_BY_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };



    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const officerListReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true,
        userList: {},
      };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        userList: action.payload,
      };
    case USER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET USER LIST BY ROLE 

export const userListByRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_BY_ROLE_REQUEST:
      return {
        loading: true,
        userList: {},
      };
    case USER_LIST_BY_ROLE_SUCCESS:
      return {
        loading: false,
        userList: action.payload,
      };
    case USER_LIST_BY_ROLE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// CREATE COUPON REDUCER
export const createCourseCouponReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_COUPON_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COURSE_COUPON_CREATE_SUCCESS:
      return {
        loading: false,
        couponData: action.payload,
      };
    case COURSE_COUPON_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CREATE_COUPON_STATE:
      return {
        loading: false,
        couponData: null,
      };

    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const enrollStudentListReducer = (state = {}, action) => {
  switch (action.type) {
    case ENROLL_STUDENT_LIST_REQUEST:
      return {
        loading: true,
        userList: {},
      };
    case ENROLL_STUDENT_LIST_SUCCESS:
      return {
        loading: false,
        userList: action.payload,
      };
    case ENROLL_STUDENT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE USER PROFILE ======
export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case PROFILE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        loading: false,
        userInfo: null,
      };

    default:
      return state;
  }
};

// UPDATE USER PROFILE ======
export const updateProfilePicReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };
    case UPDATE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };
    case UPDATE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// REMOVE PROFILE PICTURE
export const removeProfilePic = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };

    case REMOVE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };

    case REMOVE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// FORGOT PASSWORD REQUEST REDUCER
export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        loading: true,
        data: {},
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// RESET PASSWORD REDUCER
export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        loading: true,
        data: {},
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };

    case RESET_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        data: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// SETUP AFFILIATE DASHBOARD
export const setUpAffiliateDashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case AFFILIATE_DASHBOARD_REQUEST:
      return {
        loading: true,
        affiliateDashboard: {},
      };

    case AFFILIATE_DASHBOARD_SUCCESS:
      return {
        loading: false,
        affiliateDashboard: action.payload,
      };

    case AFFILIATE_DASHBOARD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET AFFILIATE DASHBOARD DATA
export const affiliateDashboardDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AFFILIATE_DASHBOARD_REQUEST:
      return {
        loading: true,
        affiliateDashboard: {},
      };

    case GET_AFFILIATE_DASHBOARD_SUCCESS:
      return {
        loading: false,
        affiliateDashboard: action.payload,
      };

    case GET_AFFILIATE_DASHBOARD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET AFFILIATE DASHBOARD DATA
export const checkCouponCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_CODE_REQUEST:
      return {
        loading: true,
        isValidCoupon: {},
      };

    case COUPON_CODE_SUCCESS:
      return {
        loading: false,
        isValidCoupon: action.payload,
      };

    case COUPON_CODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        isValidCoupon: {},
        loading: false,
        error: null,
      };

    case CLEAR_COUPON_CODE:
      return {
        isValidCoupon: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET ENROLLED USERS BY AFFILIATE
export const getEnrolledUsersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ENROLLED_USERS_REQUEST:
      return {
        loading: true,
        enrolledUsers: [],
      };

    case GET_ENROLLED_USERS_SUCCESS:
      return {
        loading: false,
        enrolledUsers: action.payload,
      };

    case GET_ENROLLED_USERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        enrolledUsers: [],
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET ENROLLED USERS BY AFFILIATE
export const getNotificationsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_AFF_NOTIFICATION_REQUEST:
      return {
        loading: true,
        allNotifications: [],
      };

    case GET_AFF_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        allNotifications: action.payload,
      };

    case GET_AFF_NOTIFICATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        allNotifications: [],
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL NOTIFICATION FOR AFFILIATES
export const getDownloadNotificationsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DOWNLOAD_NOTIFICATION_REQUEST:
      return {
        loading: true,
        downloadNotifications: [],
      };

    case GET_DOWNLOAD_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        downloadNotifications: action.payload,
      };

    case GET_DOWNLOAD_NOTIFICATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        downloadNotifications: [],
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
