import {
  CLEAR_DOSSIER_ERRORS,
  CLEAR_DOSSIER_STATE,
  DOSSIER_CREATE_FAIL,
  DOSSIER_CREATE_REQUEST,
  DOSSIER_CREATE_SUCCESS,
  DOSSIER_DELETE_FAIL,
  DOSSIER_DELETE_REQUEST,
  DOSSIER_DELETE_SUCCESS,
  DOSSIER_GET_BY_ID_FAIL,
  DOSSIER_GET_BY_ID_REQUEST,
  DOSSIER_GET_BY_ID_SUCCESS,
  DOSSIER_GET_FAIL,
  DOSSIER_GET_REQUEST,
  DOSSIER_GET_SUCCESS,
} from "../constants/dossierConstant";

export const addDossierReducer = (state = {}, action) => {
  switch (action.type) {
    case DOSSIER_CREATE_REQUEST:
      return {
        loading: true,
        dossierData: {},
      };
    case DOSSIER_CREATE_SUCCESS:
      return {
        loading: false,
        dossierData: action.payload,
      };
    case DOSSIER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DOSSIER_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_DOSSIER_STATE:
      return {
        loading: false,
        dossierData: {},
      };

    default:
      return state;
  }
};

// GET ALL DOSSIER REDUCER
export const getDossierReducer = (state = {}, action) => {
    switch (action.type) {
        case DOSSIER_GET_REQUEST:
            return {
                loading: true,
                dossierData: {},
            };
        case DOSSIER_GET_SUCCESS:
            return {
                loading: false,
                dossierData: action.payload,
            };
        case DOSSIER_GET_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_DOSSIER_ERRORS:
            return {
                dossierData: {},
                loading: false,
                error: null,
            };

    default:
      return state;
  }
};


// GET DOSSIER BY ID REDUCER
export const getDossierByIdReducer = (state = {}, action) => {
  switch (action.type) {
      case DOSSIER_GET_BY_ID_REQUEST:
          return {
              loading: true,
              dossierData: {},
          };
      case DOSSIER_GET_BY_ID_SUCCESS:
          return {
              loading: false,
              dossierData: action.payload,
          };
      case DOSSIER_GET_BY_ID_FAIL:
          return {
              loading: false,
              error: action.payload,
          };
      case CLEAR_DOSSIER_ERRORS:
          return {
              dossierData: {},
              loading: false,
              error: null,
          };

  default:
    return state;
}
};

// DELETE DOSSIER REDUCER
export const deleteDossierReducer = (state = {}, action) => {
    switch (action.type) {
        case DOSSIER_DELETE_REQUEST:
            return {
                loading: true,
                dossier: {},
            };
        case DOSSIER_DELETE_SUCCESS:
            return {
                loading: false,
                dossier: action.payload,
            };
        case DOSSIER_DELETE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_DOSSIER_ERRORS:
            return {
                dossier: {},
                loading: false,
                error: null,
            };
        case CLEAR_DOSSIER_STATE:
            return {
                dossier: {},
                loading: false,
                error: null,
            };

    default:
      return state;
  }
};
