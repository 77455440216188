import React, { Fragment, useEffect, useState } from "react";
import "../styles/courseDetail.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../components/reusable/Layout";
import CustomHelmet from "../components/reusable/Helmet";
import { useLocale } from "../context/locale";
import styled from "styled-components";
import Loader from "../components/reusable/Loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearErrors,
  getCourseDetails,
  getCourseLongDetails,
  getCourseMetaDetails,
} from "../redux/actions/courseAction";
// import cyberPartnerHero from "../images/cyberPartnerBg.png";
// import moment from "moment";
import { useAuth } from "../context/userContext";
import {
  BsAlarm,
  BsCheckLg,
  BsChevronDown,
  BsChevronUp,
  BsExclamationCircle,
  BsFillAwardFill,
  BsFillPlayCircleFill,
  BsGlobe,
  BsPeopleFill,
  BsStarFill,
  BsSuitHeart,
} from "react-icons/bs";
import { hybrid_classes, purchase1, ravinderYadavHome } from "../assets/images";
import video_icon from "../images/video_icon.png";
import CustomButton from "../components/reusable/CustomButton";
import { AiOutlineClose } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { addItemsToCart } from "../redux/actions/cartAction";
import {
  clearPaymentErrors,
  getAllPayments,
} from "../redux/actions/instaMojoAction";
import { RiVidiconLine } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import CustomRating from "../components/reusable/CustomRating";
import { useNotification } from "../context/notificationContext";
import ButtonLoader from "../components/reusable/ButtonLoader";
import CustomInput from "../components/reusable/CustomInput";
import { useCookies } from "react-cookie";
import {
  checkCouponCodeValidation,
  clearCouponCode,
} from "../redux/actions/userAction";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import {
  clearRazorErrors,
  clearRazorState,
  createRazorPayment,
  getRazorKey,
  razorPaymentverification,
} from "../redux/actions/razorpayAction";
import moment from "moment";
import PromotionPopup from "../components/reusable/PromotionPopup";
import { notification } from "../array/notificationList";

// STYLED CSS FOR ENROLL NOW POPUP ========= FOR THIS PAGE SHOW ON SCROLL
const EnrollNowPopUp = styled.div`
  position: fixed;
  bottom: -50%;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 460px;
  background-color: #121d40;
  border: 1px solid #f2f2f2;
  padding: 10px 15px;
  border-radius: 0.4rem;
  z-index: 999;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f2f2f2;
  box-sizing: border-box;

  /* for small device */
  @media screen and (max-width: 500px) {
    width: 100%;
    background-color: #081336;
    border: none;
    border-top: 1px solid #f2f2f2;
  }

  /* Show Scroll */
  &.show-scroll {
    bottom: 0;

    @media screen and (max-width: 500px) {
      bottom: -30px;
      border-radius: 25px 25px 0 0;
    }
  }

  > .create_payment_btn {
    /* padding: 7px 15px; */
    border-radius: 8px;
    outline: none;
    ${"" /* color: #121d40; */}
    color: red;
    background-color: #f2f2f2;
    border: 1px solid red;
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 103px;
    height: 30px;
    cursor: pointer;

    &:hover {
      font-weight: 700;
      border: 1px solid red;
    }
  }
`;

const CouponPaymentPopup = ({
  showCouponPopup,
  setShowCouponPopup,
  courseDetails,
}) => {
  const [t] = useLocale();
  const [auth] = useAuth();
  const [couponCode, setCouponCode] = useState("");
  const [showEmiTable, setShowEmiTable] = useState(false);
  const [, , showNotification] = useNotification();
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [initialData, setInitialData] = useState({});
  const dispatch = useDispatch();

  const payment = courseDetails?.discounted_price
    ? courseDetails?.discounted_price
    : courseDetails?.original_price;

  const courseId = courseDetails?._id;
  const discountePercentage = courseDetails?.discount;

  const closeModal = () => {
    // window.location.reload();
    dispatch(clearCouponCode());
    setShowCouponPopup(false);
    setShowEmiTable(false);
    setCouponCode("");
  };

  // CHECK COUPON CODE IS VALID OR NOT START
  // CHECK COUPON CODE IS VALID OR NOT START
  const handleCodeCheck = () => {
    if (couponCode) {
      dispatch(checkCouponCodeValidation(couponCode));
    } else {
      showNotification("warning", "Please fill Coupon Code!");
    }
  };

  const { loading, isValidCoupon, error } = useSelector(
    (state) => state.checkCouponCode
  );

  let discountedAmount = payment;
  if (isValidCoupon?.success) {
    const discounteAmount = (payment * discountePercentage) / 100;
    discountedAmount = payment - discounteAmount;
  } else {
    discountedAmount = payment;
  }

  useEffect(() => {
    if (error) {
      setCouponCode("");
    }
  }, [error]);
  // CHECK COUPON CODE IS VALID OR NOT ENDS
  // CHECK COUPON CODE IS VALID OR NOT ENDS

  // GET RAZOR PAY KEY START
  const {
    key: razorKey,
    error: razorKeyError,
    // eslint-disable-next-line
    loading: razorKeyLoading,
  } = useSelector((state) => state.razorKey);

  const razorPaykey = razorKey?.key;

  useEffect(() => {
    dispatch(getRazorKey());
  }, [dispatch]);

  useEffect(() => {
    if (razorKeyError) {
      dispatch(clearRazorErrors());
    }
  }, [dispatch, razorKeyError]);
  // GET RAZOR PAY KEY ENDS

  // CREATE PAYEMENT INSTANCE START
  // CREATE PAYEMENT INSTANCE START
  const {
    payment: razorPayment,
    error: razorError,
    // eslint-disable-next-line
    loading: razorLoading,
  } = useSelector((state) => state.razorPayment);

  const paymentDone = (amount, for_course, couponCode, emiId, seatBooking) => {
    const ssbCourse = courseDetails?.ssbCourse
      ? courseDetails?.ssbCourse
      : false;
    setInitialData({
      for_course,
      ssbCourse,
      amount,
      couponCode,
      emiId,
      seatBooking,
    });
    dispatch(
      createRazorPayment(
        for_course,
        ssbCourse,
        amount,
        couponCode,
        emiId,
        seatBooking
      )
    );
  };

  useEffect(() => {
    if (razorPayment?.success) {
      // INTILSE RAZOR PAY FOR PAYENT POPUP START
      const intialiseRazorPay = (order) => {
        var options = {
          key: razorPaykey,
          amount: order.amount,
          currency: "INR",
          order_id: order.id,
          handler: function (response) {
            setPaymentResponse(response);
          },
          prefill: {
            name: auth?.user?.first_name,
            email: auth?.user?.email,
            contact: auth?.user?.phone,
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      };
      intialiseRazorPay(razorPayment?.order);
      // INTILSE RAZOR PAY FOR PAYENT POPUP ENDS
      dispatch(clearRazorState());
    }
    if (razorError) {
      showNotification("error", razorError);
      dispatch(clearRazorErrors());
    }
  }, [
    dispatch,
    razorPaykey,
    razorError,
    razorPayment?.success,
    razorPayment?.order,
    showNotification,
    auth,
  ]);
  // CREATE PAYEMENT INSTANCE ENDS
  // CREATE PAYEMENT INSTANCE ENDS

  // VERIFY PAYMENT START
  // VERIFY PAYMENT START
  const {
    payment: verifyPayment,
    error: verifyPaymentError,
    // eslint-disable-next-line
    loading: verifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  useEffect(() => {
    if (paymentResponse && initialData) {
      dispatch(
        razorPaymentverification(
          paymentResponse.razorpay_payment_id,
          paymentResponse.razorpay_order_id,
          paymentResponse.razorpay_signature,
          initialData?.for_course,
          initialData?.ssbCourse,
          initialData?.couponCode,
          initialData?.emiId,
          initialData?.seatBooking
        )
      );
    }
  }, [paymentResponse, initialData, dispatch]);

  useEffect(() => {
    if (verifyPayment?.success) {
      showNotification("success", verifyPayment?.message);
      setShowCouponPopup(false);
      dispatch(clearRazorState());
    }
    if (verifyPaymentError) {
      showNotification("error", verifyPaymentError);
      dispatch(clearRazorErrors());
    }
  }, [verifyPayment, showNotification, dispatch, verifyPaymentError]);
  // VERIFY PAYMENT ENDS
  // VERIFY PAYMENT ENDS

  // GET COUPON CODE FROM COOKIES START
  // GET COUPON CODE FROM COOKIES START
  const [cookies] = useCookies([]);

  useEffect(() => {
    const myCookieValue = cookies.alphaCouponCodeCookie;
    setCouponCode(myCookieValue);
  }, [cookies]);
  // GET COUPON CODE FROM COOKIES ENDS
  // GET COUPON CODE FROM COOKIES ENDS

  // GET ALL PAYMENTS DONE BY SELF USER START
  // GET ALL PAYMENTS DONE BY SELF USER START
  const {
    // eslint-disable-next-line
    loading: allPaymentsLoading,
    allPayments,
    error: allPaymentsError,
  } = useSelector((state) => state.allPaymentsDoneByUser);

  const allPaymentsParam = allPayments?.paymentDetails;

  // CHECK FOR SEAT BOOKING
  const seatBookingFees = 500;
  let isSeatBookingDone = [];
  allPaymentsParam?.forEach((payment) => {
    isSeatBookingDone.push(payment?.seatBooking);
  });

  useEffect(() => {
    dispatch(getAllPayments());
  }, [dispatch]);

  useEffect(() => {
    if (allPaymentsError) {
      // toast.error(allPaymentsError);
      dispatch(clearPaymentErrors());
    }
  }, [allPaymentsError, dispatch]);
  // GET ALL PAYMENTS DONE BY SELF USER ENDS
  // GET ALL PAYMENTS DONE BY SELF USER ENDS

  return (
    <section
      className={`coupon_payment_popup_main bg_color txt_color ${
        showCouponPopup ? "showPaymentModal" : ""
      }`}
    >
      {showCouponPopup && (
        <>
          <button className="close_modal" onClick={() => closeModal()}>
            <AiOutlineClose />
          </button>
          <div>
            <p className="coupon_code_txt">
              If you have coupon for discount fill here!
            </p>
            {error && <p className="error_line">{error}</p>}
            {isValidCoupon?.success && (
              <p className="succes_line">{isValidCoupon?.message}</p>
            )}
            <div className="couponCode_sec">
              <CustomInput
                type="text"
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Coupon Code"
                name="couponCode"
                value={couponCode}
              />
              <button
                className={`check_couponCode_btn ${
                  couponCode?.length <= 0 ? "disabled" : ""
                }`}
                onClick={() => handleCodeCheck()}
              >
                {loading ? <ButtonLoader /> : "Apply Coupon"}
              </button>
            </div>
            <div className="pay_withoutCoupon">
              {isValidCoupon?.success && (
                <p style={{ color: "green", fontWeight: "bold" }}>
                  This is {isValidCoupon?.isValidCoupon?.name}'s Coupon Code.
                  you get {discountePercentage}% discount for this Course.
                </p>
              )}
              <div className="all_payment_btns">
                <CustomButton
                  className=""
                  title={`Pay ₹${discountedAmount}`}
                  onClick={() =>
                    paymentDone(
                      discountedAmount,
                      courseId,
                      couponCode,
                      null,
                      false
                    )
                  }
                />
                {courseDetails?.isEmiAvail && (
                  <>
                    <CustomButton
                      className="emi_pay_btn"
                      title={t("pay_in_emi")}
                      onClick={() => setShowEmiTable(!showEmiTable)}
                    />
                    {showEmiTable && (
                      <div className="emi_table_wrapper">
                        <div className="emi_table custonScroll">
                          <div className="title">
                            EMI Installments for {courseDetails?.title}.
                            <AiOutlineClose
                              onClick={() => setShowEmiTable(false)}
                            />
                          </div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>EMI Title</th>
                                <th>EMI Amount</th>
                                <th>EMI Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {courseDetails?.allEmis?.map((item, idx) => {
                                let paymentEmiIdData = [];
                                allPaymentsParam?.forEach((payment) => {
                                  paymentEmiIdData.push(payment?.emiId);
                                });

                                return (
                                  <tr key={item._id}>
                                    <td>
                                      <p>{idx + 1}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiName}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiAmount}</p>
                                    </td>
                                    <td>
                                      <p>{item?.emiDuration}days</p>
                                    </td>
                                    <td>
                                      {paymentEmiIdData.includes(item._id) ? (
                                        <p
                                          className="txt_center"
                                          style={{ color: "yellow" }}
                                        >
                                          Wait For Verification
                                        </p>
                                      ) : (
                                        <button
                                          className="pay__emi_btn"
                                          onClick={() =>
                                            paymentDone(
                                              item?.emiAmount,
                                              courseId,
                                              couponCode,
                                              item._id,
                                              false
                                            )
                                          }
                                        >
                                          Pay
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {isSeatBookingDone.includes(true) ? (
                  <p style={{ marginTop: "20px", color: "yellow" }}>
                    Seat Booked! Wait for verification.
                  </p>
                ) : (
                  <CustomButton
                    className="seat_book_btn"
                    title={`Book Your Seat`}
                    onClick={() =>
                      paymentDone(
                        seatBookingFees,
                        courseId,
                        couponCode,
                        null,
                        true
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const MoreDetailBatchVideo = ({ showDetailVideo, setshowDetailVideo }) => {
  return (
    <section
      className={`more_batch_details_modal ${
        showDetailVideo ? "show_video_Mocal" : ""
      } bg_color txt_color`}
    >
      <div className="container">
        {showDetailVideo && (
          <div className="video_content">
            <button
              className="close_modal"
              onClick={() => setshowDetailVideo(false)}
            >
              <AiOutlineClose />
            </button>
            <div>
              <div
                style={{
                  left: 0,
                  width: "100%",
                  height: 0,
                  position: "relative",
                  paddingBottom: "56.25%",
                }}
              >
                <figure
                  style={{
                    left: 0,
                    width: "100%",
                    height: 0,
                    position: "relative",
                    paddingBottom: "56.25%",
                    marginBlockEnd: 0,
                    marginBlockStart: 0,
                    marginInlineStart: 0,
                    marginInlineEnd: 0,
                  }}
                >
                  <iframe
                    title="How can Cyber Cafe owners become our partners? Alpha Regiment"
                    src="https://www.youtube.com/embed/_FpYBy6PvkY"
                    scrolling="no"
                    style={{
                      border: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      overflow: "hidden",
                      borderRadius: 5,
                    }}
                    allowFullScreen={1}
                    allow="autoplay"
                  />
                </figure>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const CourseDetail = () => {
  const [t] = useLocale();
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const navigate = useNavigate();

  // GET COURSE DETAILS BY ID
  const { id: courseId } = useParams();
  // const location = useLocation();
  // const courseId = location?.state;
  const [, , showNotification] = useNotification();
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [courseData, setcourseData] = useState({});
  const { loading, courseDetails, error } = useSelector(
    (state) => state.courseDetails
  );

  const getCourseById = courseDetails?.course;
  useEffect(() => {
    dispatch(getCourseDetails(courseId));
  }, [courseId, dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  // SHOW BANNER VIDEO FUNCTION

  const handleVideoClick = () => {
    if (getCourseById?.bannerVideoLink) {
      setshowDetailVideo(true);
    }
  };

  // COURSE PAYMENT HANDLERS AND PAYMENT LOADERS
  const {
    // eslint-disable-next-line
    payment: verifyPayment,
    // eslint-disable-next-line
    error: verifyPaymentError,
    loading: verifyPaymentLoading,
  } = useSelector((state) => state.verifyRazorPayment);

  // ADD TO CART
  const handleAddToCart = (courseDetails) => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/course-detail/${courseId}` });
      showNotification("warning", "Please login before adding to cart!");
    } else {
      dispatch(addItemsToCart(courseDetails));
      showNotification("success", "Item Added To Cart");
      navigate("/student/cart");
    }
  };

  const paymentHandler = (data) => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/course-detail/${courseId}` });
      showNotification("warning", "Please login before proceed to buy");
    } else {
      setShowCouponPopup(true);
      setcourseData(data);
    }
  };

  // get language param from localstorage
  const langParam = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";

  // GET COURSE LONG DETAILS START
  const {
    // eslint-disable-next-line
    loading: courseLongDetailsLoading,
    courseLongDetails,
    error: courseLongDetailsError,
  } = useSelector((state) => state.getCourseLongDetailsReducer);

  // console.log(courseLongDetails?.courseDetail);
  // const courseLongDetailsParam = courseLongDetails?.courseDetail;

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseLongDetails(courseId));
    }
  }, [courseId, dispatch]);

  useEffect(() => {
    if (courseLongDetailsError) {
      toast.error(courseLongDetailsError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    courseLongDetailsError,
    courseLongDetails?.success,
    courseLongDetails?.message,
  ]);
  // GET COURSE LONG DETAILS ENDS
  // GET COURSE LONG DETAILS ENDS

  // GET COURSE META DETAILS START
  // GET COURSE META DETAILS START
  const {
    courseMeta,
    // loading: courseMetaLoading,
    error: courseMetaError,
  } = useSelector((state) => state.courseMetaDetailsReducer);

  const courseMetaParam = courseMeta?.courseMetaDetail;
  // console.log(courseMetaParam);

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseMetaDetails(courseId));
    }
  }, [courseId, dispatch]);

  useEffect(() => {
    if (courseMetaError) {
      toast.error(courseMetaError);
      dispatch(clearErrors());
    }
  }, [courseMetaError, dispatch]);

  // accordion  function start
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedPurchase, setIsExpandedPurchase] = useState(false);
  const [showDetailVideo, setshowDetailVideo] = useState(false);
  const [isExpandTeacher, setIsExpandTeacher] = useState(false);
  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleContentPurchase = () => {
    setIsExpandedPurchase(!isExpandedPurchase);
  };
  const toggleTeacherExpand = () => {
    setIsExpandTeacher(!isExpandTeacher);
  };
  //   accordion  function ends

  
  // RATING ROUND OFF
  const ratingRoundOff = (value) => {
    var multiplier = Math.pow(10, 1);
    return Math.round(value * multiplier) / multiplier;
  };

  const pauseForDate = moment(getCourseById?.pauseForDate);
  const currentDate = moment();
  const differenceInDays = pauseForDate.diff(currentDate, "days");
  const currentPageUrl = window?.location?.href;

  // DUMMY USER POPUP
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [lastNotificationIndex, setLastNotificationIndex] = useState(-1);

  const getRandomIndex = (max) => {
    let randomIndex = currentNotificationIndex;
    while (randomIndex === currentNotificationIndex) {
      randomIndex = Math.floor(Math.random() * max);
    }
    return randomIndex;
  };

  const showNextRandomNotification = () => {
    if (notification?.length > 0) {
      let randomIndex = getRandomIndex(notification?.length);

      setCurrentNotificationIndex(randomIndex);
      setLastNotificationIndex(randomIndex);
      setIsNotificationVisible(true);

      setTimeout(() => {
        setIsNotificationVisible(false);
      }, 10000);
    }
  };

  useEffect(() => {
    const notificationInterval = setInterval(showNextRandomNotification, 30000);
    return () => {
      clearInterval(notificationInterval);
    };
    //eslint-disable-next-line
  }, [notification, currentNotificationIndex, lastNotificationIndex]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <CustomHelmet
            title={
              langParam === "hi"
                ? courseMetaParam?.metaTitleHindi
                : courseMetaParam?.metaTitle
            }
            keywords={
              langParam === "hi"
                ? courseMetaParam?.metaKeywordsHindi
                : courseMetaParam?.metaKeywords
            }
            description={
              langParam === "hi"
                ? courseMetaParam?.metaDescriptionHindi
                : courseMetaParam?.metaDescription
            }
            author={
              langParam === "hi"
                ? courseMetaParam?.metaAuthorHindi
                : courseMetaParam?.metaAuthor
            }
            canonical={currentPageUrl}
          />
          <section className="course_wrapper">
            <div className="course_bg">
              <div className="container">
                <nav className="bredcrumb_grid">
                  <ol>
                    <li>
                      <Link to="/">Home</Link>/
                    </li>
                    <li className="active">Course Detail</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="all_details">
              <div className="container">
                <nav className="bredcrumb_grid">
                  <ol>
                    <li>
                      <Link to="/">Home</Link>/
                    </li>
                    <li className="active">Course Detail</li>
                  </ol>
                </nav>
                <div className="course_details">
                  <div className="right_section">
                    <div className="headig_txt">
                      <div
                        className="course_detail_img"
                        onClick={() => handleVideoClick()}
                      >
                        <img
                          className="main_thumbnail"
                          src={getCourseById?.thumbnail?.url}
                          alt={
                            langParam === "en"
                              ? getCourseById?.title
                              : getCourseById?.titleHindi
                          }
                        />
                        {getCourseById?.bannerVideoLink && (
                          <img
                            className="abs_vdo_icon"
                            src={video_icon}
                            alt="video_icon"
                          />
                        )}
                      </div>
                      <h1>
                        {langParam === "en"
                          ? getCourseById?.title
                          : getCourseById?.titleHindi}
                      </h1>
                      <p>
                        {langParam === "en"
                          ? getCourseById?.description
                          : getCourseById?.descriptionHindi}
                      </p>
                      <div className="ratings">
                        {getCourseById?.numOfReviews > 0 ? (
                          <>
                            <div className="stars">
                              <p>{ratingRoundOff(getCourseById?.ratings)}</p>
                              <CustomRating
                                val={ratingRoundOff(getCourseById?.ratings)}
                                readOnly={true}
                              />
                              <Link to={`/course/rating/${getCourseById?._id}`}>
                                ( {getCourseById?.numOfReviews} )
                              </Link>
                            </div>
                            <div className="count">
                              <Link to={`/course/rating/${getCourseById?._id}`}>
                                See all reviews
                              </Link>
                            </div>
                          </>
                        ) : (
                          <Link
                            className="no_rated"
                            to={`/course/rating/${getCourseById?._id}`}
                          >
                            No rated yet
                          </Link>
                        )}
                      </div>
                      <div className="updates">
                        <ul>
                          <li>
                            <BsExclamationCircle />
                            <p>Last Updated 08/12/2022</p>
                          </li>
                          <li>
                            <BsGlobe />
                            <p>English</p>
                          </li>
                          <li>
                            {(getCourseById?.study_mode === "Online" && (
                              <FaChalkboardTeacher />
                            )) ||
                              (getCourseById?.study_mode === "Offline" && (
                                <GiTeacher />
                              )) ||
                              (getCourseById?.study_mode === "Hybrid" && (
                                <img src={hybrid_classes} alt="" />
                              ))}
                            <p>{getCourseById?.study_mode}</p>
                          </li>
                          <li>
                            <RiVidiconLine />
                            <p>Recorded</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="left_section_mob">
                      <div className="details">
                        <div className="price_text">
                          <ul className="prize">
                            {getCourseById?.discounted_price !== 0 && (
                              <li>
                                <h4>Rs {getCourseById?.discounted_price}</h4>
                              </li>
                            )}
                            {getCourseById?.discounted_price !== 0 ? (
                              <>
                                <li>
                                  <strike>
                                    Rs {getCourseById?.original_price}
                                  </strike>
                                </li>
                                <li>{/* <p>70% off</p> */}</li>
                              </>
                            ) : (
                              <li>
                                <h4>{getCourseById?.original_price}</h4>
                              </li>
                            )}
                          </ul>
                          <span>
                            <BsAlarm />
                            <p>
                              <strong>2 days</strong> left at this price
                            </p>
                          </span>

                          {differenceInDays > 0 && (
                            <span>
                              <p>
                                Course will continue after {differenceInDays}{" "}
                                days.
                              </p>
                            </span>
                          )}

                          {auth?.user?.courses?.some(
                            (itm) => itm?.course?._id === getCourseById?._id
                          ) ? (
                            <button className="add_cart" disabled>
                              Already Purchased
                            </button>
                          ) : (
                            <>
                              <button
                                className="add_cart"
                                disabled={verifyPaymentLoading ? true : false}
                                onClick={() => handleAddToCart(getCourseById)}
                              >
                                {verifyPaymentLoading
                                  ? "Loading..."
                                  : "Add To Cart"}
                              </button>
                              <button
                                className="buy_now"
                                disabled={verifyPaymentLoading ? true : false}
                                onClick={() => paymentHandler(getCourseById)}
                              >
                                {verifyPaymentLoading
                                  ? "Loading..."
                                  : "Buy Now"}
                              </button>
                            </>
                          )}
                          <p className="money_back">
                            30-Day Money-Back Guarantee
                          </p>
                          <div className="location_map">
                            <span>
                              <CiLocationOn />
                              <p>
                                Enable your location to calculate the precious
                                distance
                              </p>
                            </span>

                            <div className="progress_sec">
                              <CustomButton
                                className="location_allow"
                                title={t("Allow Location")}
                                // onClick={onClick}
                                icon={""}
                              />
                              <div className="progress-bar">
                                <div
                                  className="progress"
                                  style={{ width: "40%" }}
                                ></div>
                              </div>

                              <div className="distance_calculator">
                                <p style={{ width: "68%" }}>
                                  12km km distance from your location
                                </p>
                              </div>
                            </div>
                          </div>
                          <ul className="feature">
                            <h4>This course includes:</h4>
                            {getCourseById?.courseInclude?.map((ele, id) => (
                              <li key={id}>
                                <IoCheckmarkDoneSharp />
                                <p>{ele}</p>
                              </li>
                            ))}
                          </ul>
                          <ul className="share_things">
                            <li>
                              <Link to="/">Share</Link>
                            </li>
                            <li>
                              <Link to="/">Gift this course</Link>
                            </li>
                            <li>
                              <Link to="/">Apply Coupen</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="business">
                          <h4>Training 5 or more student?</h4>
                          <p>
                            Get your team access to 24,000+ top Udemy courses
                            anytime, anywhere.
                          </p>
                          <CustomButton
                            className="buy_now"
                            title={t("Try Alpha School Now?")}
                            // onClick={onClick}
                            icon={""}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="what_to_learn">
                      <h4>Course Feature</h4>
                      <div className="points">
                        <ul>
                          {getCourseById?.featureList.map((ele) => (
                            <li key={ele?._id}>
                              <BsCheckLg />
                              <p>{ele?.feature}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {getCourseById?.category === "Mock" && (
                      <div className="interview_content">
                        <div className="title">
                          <h4>Steps</h4>
                        </div>
                        <div className="rightSide">
                          <div className="steps">
                            <div className="step">
                              <div>
                                <span>1</span>
                                <span>Enroll</span>
                              </div>
                              <p>
                                Enrolling in Mock Test is easy with any payment
                                method. If you encounter any issues, we're here
                                to help.
                              </p>
                            </div>
                            <div className="step">
                              <div>
                                <span>2</span>
                                <span>Attempt Test</span>
                              </div>

                              <p>Take the test with the appropriate timer.</p>
                            </div>
                            <div className="step">
                              <div>
                                <span>3</span>
                                <span>Answer Keys</span>
                              </div>
                              <p>Answer Keys with explanation</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={`course_description ${
                        isExpanded ? "expanded" : ""
                      }`}
                    >
                      <h4>{t("batchDetailsHeading")}</h4>
                      <div
                        className={`content ${isExpanded ? "expanded" : ""}`}
                      >
                        <p>
                          <strong>
                            **UPDATED FOR 2023 – ALL NEW CONTENT, EXAMPLES AND
                            EXERCISES**
                          </strong>
                        </p>
                        <p>
                          <strong>
                            “I purchased this course way back in 2012 when I was
                            just beginning to take writing seriously… I have
                            looked and re-looked at this course over time and
                            have still found it to be highly relevant and
                            helpful.”
                          </strong>
                          – Caleb K, Udemy student
                        </p>
                        <p>
                          <strong>LESS IS MORE</strong>
                        </p>
                        <p>
                          <strong>
                            Often the fewer words you write, the more impact you
                            make.
                          </strong>
                        </p>
                        <p>
                          Your writing is <strong>punchy</strong>,{" "}
                          <strong>to the point</strong> and{" "}
                          <strong>enticing</strong> for your readers.
                        </p>
                        <ul>
                          <li>
                            <p>
                              Do you write for business, university or a blog?
                            </p>
                          </li>
                          <li>
                            <p>
                              Do you <strong>write too many words</strong>? Wish
                              you could be more <strong>succinct</strong>?
                            </p>
                          </li>
                          <li>
                            <p>
                              Do you need to write to a{" "}
                              <strong>word count</strong>?
                            </p>
                          </li>
                          <li>
                            <p>
                              Perhaps you just need to produce{" "}
                              <strong>your best written communication</strong>.
                            </p>
                          </li>
                        </ul>
                        <p>
                          Whether you write client emails, essays, marketing
                          material or technical reports, being concise will help
                          you get a{" "}
                          <strong>more powerful message across – faster</strong>
                          . This course will equip you with the{" "}
                          <strong>writing and editing skills</strong> to produce{" "}
                          <strong>concise, effective</strong> written
                          communication with confidence.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <strong>
                            “Comprehensive guide on keeping it simple. Fewer
                            words for a more compelling story!”
                          </strong>{" "}
                          –Jack O, Udemy student
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          I’ve worked as a writer and editor for more than 25
                          years – as a journalist for high-profile international
                          publications, and as a copywriter for countless
                          businesses, creative agencies, government entities and
                          not-for-profit organizations. Being succinct is one of
                          the most common issues I’m asked to address.
                        </p>
                        <p>
                          In this <strong>fast-paced</strong>{" "}
                          <strong>business writing course</strong>, I share my
                          secrets to writing concise content and powerful
                          messages. I reveal <strong>simple techniques</strong>{" "}
                          to keep your words down and your message{" "}
                          <strong>compelling</strong> – giving you the{" "}
                          <strong>confidence</strong> to write concisely, with
                          ease.
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <strong>
                            “This course is well-planned and well-presented. The
                            instructor teaches from a foundation of experience
                            in writing business messages. This makes for a
                            decidedly "business-friendly" class rather than one
                            packed with complex rules about grammar and
                            structure.”
                          </strong>
                          <em> </em>–<em> </em>Bruce F, Udemy student
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          I’ve created <strong>cheat sheets</strong> you can
                          print and keep next to your computer for easy
                          reference, and exercises that will hone your business
                          writing skills.
                        </p>
                        <p>
                          <strong>Forget grammar theory</strong>; think
                          easy-to-follow examples and business-oriented content.
                          Like my writing, my explanations are concise and clear
                          – you won’t find yourself sitting through long-winded
                          or technical explanations.
                        </p>
                        <p>
                          At the end of this course, your business writing will:
                        </p>
                        <ul>
                          <li>
                            <p>
                              Make more <strong>impact</strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Be more <strong>succinct</strong> and{" "}
                              <strong>powerful</strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Be <strong>punchy</strong>, <strong>clear</strong>{" "}
                              and <strong>impactful</strong>
                            </p>
                          </li>
                          <li>
                            <p>Grab and hold your readers’ attention</p>
                          </li>
                        </ul>
                        <p>
                          <br />
                        </p>
                        <p>
                          <strong>
                            “Interesting course and easy to follow. I'm already
                            cutting down on wordiness and achieving better
                            results. Good examples and cheat sheets.”
                          </strong>
                          <em> </em>– Ann P, Udemy student
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          Complete this business writing course in{" "}
                          <strong>just 1 hour</strong>, and continue to use the
                          material as a reference.
                        </p>
                        <p>
                          <strong>
                            Enroll now to reduce wordiness and produce more
                            professional writing.
                          </strong>
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <strong>STUDENT COMMENTS</strong>
                        </p>
                        <p>
                          "This course made me aware how many words I frequently
                          use and how many I could lose to make the sentence
                          more crisp. Very helpful!" Franziska B
                        </p>
                        <p>
                          "This was a great course with challenging exercises to
                          refresh or increase your writing skills." Stephen
                        </p>
                        <p>
                          "It was a good match! Is coaching available? Thanks
                          for a helpful and interesting course." Gwendolyn W
                        </p>
                        <p>
                          "Very useful tips. I love the cheat sheets! The
                          instructor's pace was very good." Theresa B. K
                        </p>
                        <p>"Useful tips to write with less words." Octavio M</p>
                        <p>
                          "I undertook this writing course to help my writing
                          become more concise. It achieved that and more – and
                          in very little time. I was surprised at how quickly my
                          writing improved. The examples and exercises are
                          relevant and easy to follow. I will continue to refer
                          to the material in my current role." Jayne K
                        </p>
                        <p>
                          "* Material/delivery clear and concise. * Enjoyed the
                          exercises. would like to see more. * Instructor very
                          knowledgeable." Jerome C
                        </p>
                        <p>
                          "I used the various tools and means to check my
                          writing and improve the way I communicate. Especially
                          when writing emails, I use what I learned to check and
                          make sure it is following the best practice taught."
                          Dawn C
                        </p>
                        <p>
                          "Glad I took the course. Knowledgeable instructor who
                          communicates clearly and efficiently. Useful tips for
                          concise writing." Lincoln J
                        </p>
                        <p>
                          "Course has been useful for me to understand how to
                          write more precisely going forward." Preethi J
                        </p>
                        <p>
                          "I enjoyed the course. It was simple and easy to
                          follow! Keep up the good work, Caroline!" Vishruta B
                        </p>
                        <p>
                          "Good course to develop short and powerful posts,
                          document and presentations!" Romans L
                        </p>
                        <p>"Excellent course. Highly recommend." Adam L</p>
                        <p>
                          "The course gives great practical examples." Michael D
                        </p>
                        <p>
                          "No jargon and simple examples made it easy to grasp."
                          Jennifer J
                        </p>
                        <p>
                          "This is the best course I have taken so far." Kim K
                        </p>
                        <p>"Perfect!" Emma M</p>
                      </div>

                      <p className="toggle-button" onClick={toggleContent}>
                        {isExpanded ? (
                          <>
                            Show Less <BsChevronUp />
                          </>
                        ) : (
                          <>
                            Show More <BsChevronDown />
                          </>
                        )}
                      </p>
                    </div>
                    <div className="student_purchase">
                      <h4>Students also bought</h4>

                      <div
                        className={`content ${
                          isExpandedPurchase ? "expanded" : ""
                        }`}
                      >
                        <div className="pruchase_course">
                          <div className="course_title">
                            <img
                              src={purchase1}
                              alt=""
                              className="course_ban"
                            />
                            <div className="course_det">
                              <h5>Advanced Business writing skills</h5>
                              <span>
                                <strong>20 hours</strong>
                                <p>Updated 10/12/2021</p>
                              </span>
                              <div className="over_review mob_view">
                                <ul>
                                  <li>
                                    <span>
                                      <p>4.6</p>
                                      <BsStarFill />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <BsPeopleFill />
                                      <p>667</p>
                                    </span>
                                  </li>
                                  <li>
                                    <div>
                                      <span>
                                        {getCourseById?.discounted_price !==
                                          0 && (
                                          <strong>
                                            Rs {getCourseById?.discounted_price}
                                          </strong>
                                        )}
                                        {getCourseById?.discounted_price !==
                                        0 ? (
                                          <strike>
                                            Rs {getCourseById?.original_price}
                                          </strike>
                                        ) : (
                                          <strong>
                                            Rs {getCourseById?.original_price}
                                          </strong>
                                        )}
                                      </span>
                                      <span className="icon">
                                        <BsSuitHeart />
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="over_review desk_view">
                            <ul>
                              <li>
                                <span>
                                  <p>4.6</p>
                                  <BsStarFill />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <BsPeopleFill />
                                  <p>667</p>
                                </span>
                              </li>
                              <li>
                                <div>
                                  <span>
                                    {getCourseById?.discounted_price !== 0 && (
                                      <strong>
                                        Rs {getCourseById?.discounted_price}
                                      </strong>
                                    )}
                                    {getCourseById?.discounted_price !== 0 ? (
                                      <strike>
                                        Rs {getCourseById?.original_price}
                                      </strike>
                                    ) : (
                                      <strong>
                                        Rs {getCourseById?.original_price}
                                      </strong>
                                    )}
                                  </span>
                                  <span className="icon">
                                    <BsSuitHeart />
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="pruchase_course">
                          <div className="course_title">
                            <img
                              src={purchase1}
                              alt=""
                              className="course_ban"
                            />
                            <div className="course_det">
                              <h5>Advanced Business writing skills</h5>
                              <span>
                                <strong>20 hours</strong>
                                <p>Updated 10/12/2021</p>
                              </span>
                              <div className="over_review mob_view">
                                <ul>
                                  <li>
                                    <span>
                                      <p>4.6</p>
                                      <BsStarFill />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <BsPeopleFill />
                                      <p>667</p>
                                    </span>
                                  </li>
                                  <li>
                                    <div>
                                      <span>
                                        {getCourseById?.discounted_price !==
                                          0 && (
                                          <strong>
                                            Rs {getCourseById?.discounted_price}
                                          </strong>
                                        )}
                                        {getCourseById?.discounted_price !==
                                        0 ? (
                                          <strike>
                                            Rs {getCourseById?.original_price}
                                          </strike>
                                        ) : (
                                          <strong>
                                            Rs {getCourseById?.original_price}
                                          </strong>
                                        )}
                                      </span>
                                      <span className="icon">
                                        <BsSuitHeart />
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="over_review desk_view">
                            <ul>
                              <li>
                                <span>
                                  <p>4.6</p>
                                  <BsStarFill />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <BsPeopleFill />
                                  <p>667</p>
                                </span>
                              </li>
                              <li>
                                <div>
                                  <span>
                                    {getCourseById?.discounted_price && (
                                      <strong>
                                        Rs {getCourseById?.discounted_price}
                                      </strong>
                                    )}
                                    {getCourseById?.discounted_price ? (
                                      <strike>
                                        Rs {getCourseById?.original_price}
                                      </strike>
                                    ) : (
                                      <strong>
                                        Rs {getCourseById?.original_price}
                                      </strong>
                                    )}
                                  </span>
                                  <span className="icon">
                                    <BsSuitHeart />
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="pruchase_course">
                          <div className="course_title">
                            <img
                              src={purchase1}
                              alt=""
                              className="course_ban"
                            />
                            <div className="course_det">
                              <h5>Advanced Business writing skills</h5>
                              <span>
                                <strong>20 hours</strong>
                                <p>Updated 10/12/2021</p>
                              </span>
                              <div className="over_review mob_view">
                                <ul>
                                  <li>
                                    <span>
                                      <p>4.6</p>
                                      <BsStarFill />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <BsPeopleFill />
                                      <p>667</p>
                                    </span>
                                  </li>
                                  <li>
                                    <div>
                                      <span>
                                        {getCourseById?.discounted_price !==
                                          0 && (
                                          <strong>
                                            Rs {getCourseById?.discounted_price}
                                          </strong>
                                        )}
                                        {getCourseById?.discounted_price !==
                                        0 ? (
                                          <strike>
                                            Rs {getCourseById?.original_price}
                                          </strike>
                                        ) : (
                                          <strong>
                                            Rs {getCourseById?.original_price}
                                          </strong>
                                        )}
                                      </span>
                                      <span className="icon">
                                        <BsSuitHeart />
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="over_review desk_view">
                            <ul>
                              <li>
                                <span>
                                  <p>4.6</p>
                                  <BsStarFill />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <BsPeopleFill />
                                  <p>667</p>
                                </span>
                              </li>
                              <li>
                                <div>
                                  <span>
                                    {getCourseById?.discounted_price !== 0 && (
                                      <strong>
                                        Rs {getCourseById?.discounted_price}
                                      </strong>
                                    )}
                                    {getCourseById?.discounted_price !== 0 ? (
                                      <strike>
                                        Rs {getCourseById?.original_price}
                                      </strike>
                                    ) : (
                                      <strong>
                                        Rs {getCourseById?.original_price}
                                      </strong>
                                    )}
                                  </span>
                                  <span className="icon">
                                    <BsSuitHeart />
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="pruchase_course">
                          <div className="course_title">
                            <img
                              src={purchase1}
                              alt=""
                              className="course_ban"
                            />
                            <div className="course_det">
                              <h5>Advanced Business writing skills</h5>
                              <span>
                                <strong>20 hours</strong>
                                <p>Updated 10/12/2021</p>
                              </span>
                              <div className="over_review mob_view">
                                <ul>
                                  <li>
                                    <span>
                                      <p>4.6</p>
                                      <BsStarFill />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <BsPeopleFill />
                                      <p>667</p>
                                    </span>
                                  </li>
                                  <li>
                                    <div>
                                      <span>
                                        {getCourseById?.discounted_price !==
                                          0 && (
                                          <strong>
                                            Rs {getCourseById?.discounted_price}
                                          </strong>
                                        )}
                                        {getCourseById?.discounted_price !==
                                        0 ? (
                                          <strike>
                                            Rs {getCourseById?.original_price}
                                          </strike>
                                        ) : (
                                          <strong>
                                            Rs {getCourseById?.original_price}
                                          </strong>
                                        )}
                                      </span>
                                      <span className="icon">
                                        <BsSuitHeart />
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="over_review desk_view">
                            <ul>
                              <li>
                                <span>
                                  <p>4.6</p>
                                  <BsStarFill />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <BsPeopleFill />
                                  <p>667</p>
                                </span>
                              </li>
                              <li>
                                <div>
                                  <span>
                                    {getCourseById?.discounted_price !== 0 && (
                                      <strong>
                                        Rs {getCourseById?.discounted_price}
                                      </strong>
                                    )}
                                    {getCourseById?.discounted_price !== 0 ? (
                                      <strike>
                                        Rs {getCourseById?.original_price}
                                      </strike>
                                    ) : (
                                      <strong>
                                        Rs {getCourseById?.original_price}
                                      </strong>
                                    )}
                                  </span>
                                  <span className="icon">
                                    <BsSuitHeart />
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <button
                        className="toggle-button"
                        onClick={toggleContentPurchase}
                      >
                        {isExpandedPurchase ? (
                          <>
                            Show Less <BsChevronUp />
                          </>
                        ) : (
                          <>
                            Show More <BsChevronDown />
                          </>
                        )}
                      </button>
                    </div>
                    <div className="teacher_details">
                      <h4>Teacher</h4>
                      <h5 className="name">
                        <Link to="#!">Sandeep Sharma</Link>
                      </h5>
                      <p>Msc Physics</p>
                      <div className="profile_card">
                        <img src={ravinderYadavHome} alt="" />
                        <ul>
                          <li>
                            <BsStarFill />
                            <p>4.6 teacher rating</p>
                          </li>
                          <li>
                            <BsFillAwardFill />
                            <p>200 reviews</p>
                          </li>
                          <li>
                            <BsPeopleFill />
                            <p>1,112 students</p>
                          </li>
                          <li>
                            <BsFillPlayCircleFill />
                            <p>1 course</p>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`content ${
                          isExpandTeacher ? "expanded" : ""
                        }`}
                      >
                        <p>
                          Writewords Write<em>well</em> founder Caroline
                          McDevitt has worked in media and
                          marketing-communications for more than 20 years.
                        </p>
                        <p>
                          As a journalist, Caroline has written for, and edited,
                          high-profile, international publications including{" "}
                          <em>The Times</em>,{" "}
                          <em>Qantas: The Australian Way</em> and <em>Money</em>
                          magazine, covering travel, sport, finance and
                          international affairs.
                        </p>
                        <p>
                          As a business writing&nbsp;specialist, she has worked
                          with countless businesses, creative agencies,
                          government entities and not-for-profit organisations.
                        </p>
                        <p>
                          Caroline's Writewords Write<em>well</em>
                          courses will give you the skills to write with
                          confidence – in business, at work, in life.
                        </p>
                        <p>
                          <em>
                            “Caroline has the rare ability to be creative while
                            never losing an eye for detail. I have hired her to
                            write publications, edit magazines, proofread and
                            project manage – and I will continue to use her in
                            the future. She is a valuable freelance resource to
                            any busy communications team." - Chrissa Favaloro,
                            Australian Catholic University
                          </em>
                        </p>
                        <p>
                          More Write<em>well</em>&nbsp;business
                          writing&nbsp;courses launching soon!
                        </p>
                      </div>
                      <p
                        className="toggle-button"
                        onClick={toggleTeacherExpand}
                      >
                        {isExpandTeacher ? (
                          <>
                            Show Less <BsChevronUp />
                          </>
                        ) : (
                          <>
                            Show More <BsChevronDown />
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    className="left_section"
                    //  ref={leftSectionRef}
                  >
                    <div className="top_img" onClick={() => handleVideoClick()}>
                      <img
                        className="main_thumbnail"
                        src={getCourseById?.thumbnail?.url}
                        alt={
                          langParam === "en"
                            ? getCourseById?.title
                            : getCourseById?.titleHindi
                        }
                      />
                      {getCourseById?.bannerVideoLink && (
                        <img
                          className="abs_vdo_icon"
                          src={video_icon}
                          alt="video_icon"
                        />
                      )}
                    </div>
                    <div className="details">
                      <div className="price_text">
                        <ul className="prize">
                          {getCourseById?.discounted_price !== 0 && (
                            <li>
                              <h4>Rs {getCourseById?.discounted_price}</h4>
                            </li>
                          )}

                          {getCourseById?.discounted_price !== 0 ? (
                            <>
                              <li>
                                <strike>
                                  Rs {getCourseById?.original_price}
                                </strike>
                              </li>
                              <li>{/* <p>70% off</p> */}</li>
                            </>
                          ) : (
                            <li>
                              <h4>{getCourseById?.original_price}</h4>
                            </li>
                          )}
                        </ul>
                        <span>
                          <BsAlarm />
                          <p>
                            <strong>2 days</strong> left at this price
                          </p>
                        </span>
                        {differenceInDays > 0 && (
                          <span>
                            <p>
                              Course will continue after {differenceInDays}{" "}
                              days.
                            </p>
                          </span>
                        )}

                        {auth?.user?.courses?.some(
                          (itm) => itm?.course?._id === getCourseById?._id
                        ) ? (
                          <button className="add_cart" disabled>
                            Already Purchased
                          </button>
                        ) : (
                          <>
                            <button
                              className="add_cart"
                              disabled={verifyPaymentLoading ? true : false}
                              onClick={() => handleAddToCart(getCourseById)}
                            >
                              {verifyPaymentLoading
                                ? "Loading..."
                                : "Add To Cart"}
                            </button>
                            <button
                              className="buy_now"
                              disabled={verifyPaymentLoading ? true : false}
                              onClick={() => paymentHandler(getCourseById)}
                            >
                              {verifyPaymentLoading ? "Loading..." : "Buy Now"}
                            </button>
                          </>
                        )}

                        <p className="money_back">
                          30-Day Money-Back Guarantee
                        </p>
                        <div className="location_map">
                          <span>
                            <CiLocationOn />
                            <p>
                              Enable your location to calculate the precious
                              distance
                            </p>
                          </span>

                          <div className="progress_sec">
                            <CustomButton
                              className="location_allow"
                              title={t("Allow Location")}
                              // onClick={onClick}
                              icon={""}
                            />
                            <div className="progress-bar">
                              <div
                                className="progress"
                                style={{ width: "40%" }}
                              ></div>
                            </div>

                            <div className="distance_calculator">
                              <p style={{ width: "68%" }}>
                                12km km distance from your location
                              </p>
                            </div>
                          </div>
                        </div>
                        <ul className="feature">
                          <h4>This course includes:</h4>
                          {getCourseById?.courseInclude?.map((ele, id) => (
                            <li key={id}>
                              <IoCheckmarkDoneSharp />
                              <p>{ele}</p>
                            </li>
                          ))}
                        </ul>
                        <ul className="share_things">
                          <li>
                            <Link to="/">Share</Link>
                          </li>
                          <li>
                            <Link to="/">Gift this course</Link>
                          </li>
                          <li>
                            <Link to="/">Apply Coupen</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="business">
                        <h4>Training 5 or more student?</h4>
                        <p>
                          Get your team access to 24,000+ top Udemy courses
                          anytime, anywhere.
                        </p>
                        <CustomButton
                          className="buy_now"
                          title={t("Try AR Now ?")}
                          // onClick={onClick}
                          icon={""}
                          width="100%"
                        />
                      </div>
                    </div>

                    <MoreDetailBatchVideo
                      showDetailVideo={showDetailVideo}
                      setshowDetailVideo={setshowDetailVideo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <EnrollNowPopUp />
          {/* COUPON CODE POPUP START */}

          <CouponPaymentPopup
            showCouponPopup={showCouponPopup}
            setShowCouponPopup={setShowCouponPopup}
            courseDetails={courseData}
          />
          {/* COUPON CODE POPUP ENDS */}

          {/* PROMOTION POPUP STARTS */}
          {notification?.length > 0 &&
            currentNotificationIndex < notification?.length - 1 && (
              <PromotionPopup
                isNotificationVisible={isNotificationVisible}
                currentNotificationIndex={currentNotificationIndex}
                data={notification}
              />
            )}
        </Layout>
      )}
    </Fragment>
  );
};

export default CourseDetail;
