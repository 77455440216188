import React, { useEffect, useRef, useState } from "react";
import "../../../styles/videoCallComponent.scss";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addStream from "../../../redux/actions/addStream";
import updateCallStatus from "../../../redux/actions/updateCallStatus";
import CallInfo from "./CallInfo";
import ChatWindow from "./ChatWindow";
import ActionButtons from "./ActionButtons";
import createPeerConnection from "../utils/createPeerConnection";
import { useChatAuth } from "../../../context/chatContext";
import { clearVideoCallErrors, validateVideoCallLink } from "../../../redux/actions/videoCallAction";
import { useNotification } from "../../../context/notificationContext";
import clientSocketListeners from "../utils/clientSocketListeners";

const CallerVideoPage = () => {
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  console.log("streams", streams);
  // get query string finder hook
  const [searchParams, setSearchParams] = useSearchParams();
  const [apptInfo, setApptInfo] = useState({});
  const smallFeedEl = useRef(null);
  const largeFeedEl = useRef(null);
  const uuidRef = useRef(null);
  const streamRef = useRef(null);
  const [showCallInfo, setShowCallInfo] = useState(true);
  const [, , showNotfication] = useNotification();
  const { socket } = useChatAuth()

  useEffect(() => {
    // fetch the user media
    const fetchMedia = async () => {
      const constraints = {
        video: true,
        audio: true,
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        dispatch(updateCallStatus("haveMedia", true));
        // dispatch will send this function to the redux dispatcher so all reducers
        // we will send 2 args, the who, and the stream
        dispatch(addStream("localStream", stream));
        const { peerConnection, remoteStream } = await createPeerConnection(addIce);
        // we dont know "WHO" we are talking to... yet.
        dispatch(addStream("remote1", remoteStream, peerConnection));
        // We have a peerConnection... let's make an offer!
        // EXCEPT, it's not time yet.
        // socket.emit...
        largeFeedEl.current.srcObject = remoteStream; // We have the remoteStream from the peerConnection. Set the video feed to be the remoteStream just created
      } catch (error) {
        console.log("Err in fetching user media", error);
      }
    };
    fetchMedia();
  }, [dispatch]);
  

  useEffect(() => {
    // We can not update streamRef until we know redux is finished
    if (streams.remote1) {
      streamRef.current = streams;
    }
  }, [streams]);

  useEffect(() => {
    // we have audio and video and we need an offer. Let's make it
    const createOfferAsync = async () => {
      for (const s in streams) {
        if (s !== "localStream") {
          try {
            const pc = streams[s].peerConnection;
            const offer = await pc.createOffer();
            await pc.setLocalDescription(offer);
            // get the token from the url for the socket connection
            const token = searchParams.get("token");
            // const socket = socketConnection(token);
            socket.emit("newOffer", { offer, apptInfo });
            // // add our event listener
            // clientSocketListeners(socket, dispatch)
          } catch (error) {
            console.log("Err in createOffer", error);
          }
        }
      }
      dispatch(updateCallStatus("haveCreatedOffer", true));
    };
    if (callStatus.audio === "enabled" && callStatus.video === "enabled" && !callStatus.haveCreatedOffer) {
      createOfferAsync();
    }
  }, [ callStatus.audio, callStatus.video, callStatus.haveCreatedOffer, apptInfo, streams, dispatch, searchParams, socket]);


  useEffect(() => {
    const asyncAddAnswer = async () => {
      // listen for changes to callStatus.answer
      // if it exist we have an answer
      for (const s in streams) {
        if (s !== "localStream") {
          const pc = streams[s].peerConnection;
          await pc.setRemoteDescription(callStatus.answer);
          console.log(pc.signalingState);
          console.log("======Answer Added========");
        }
      }
    };
    if (callStatus.answer) {
      asyncAddAnswer();
    }
  }, [callStatus.answer, streams]);


  useEffect(() => {
    if (socket) {
      clientSocketListeners(socket, dispatch, addIceCandidateToPc);
    }
  }, [socket, dispatch]);

  const addIceCandidateToPc = async(iceC) => {
    console.log("iceToClient in callerVideoPage", iceC);
    // add an ice candidate from the remote, to the pc
    for(const s in streamRef.current){
        if(s !== 'localStream'){
            const pc = streamRef.current[s].peerConnection;
            await pc.addIceCandidate(iceC)
            console.log('Added an iceCandidate to existing page presence');
            setShowCallInfo(false)
            console.log("showCallInfo", showCallInfo)
        }
    }
  }

  const addIce = (iceC) => {
    console.log("CallerVideoPage iceC", iceC)
    // Emit a new ice candidate to signaling server
    // const socket = socketConnection(searchParams.get('token'))
    socket.emit('iceToServer', {
      iceC,
      who: "client",
      uuid: uuidRef.current // we used useRef,to keep the value fresh
    })
  };

  
  // DECODED DATA OF VIDEO CALL
  const {data: VideoCallData, error: validateVideoCallErr, loading: validateVideoCallLoading} = useSelector(state=>state.validateVideoCall)

  useEffect(() => {
    const token = searchParams.get("token");
    if(token){
     dispatch(validateVideoCallLink(token)) 
    }
  }, [ dispatch, searchParams])

  useEffect(() => {
    if(VideoCallData?.success){
      setApptInfo(VideoCallData?.decodedData)
      uuidRef.current = VideoCallData?.decodedData?.uuid
    }
     if(validateVideoCallErr){
      showNotfication("error", validateVideoCallErr)
      dispatch(clearVideoCallErrors())
    }
  }, [VideoCallData?.decodedData, showNotfication, VideoCallData?.success, dispatch, validateVideoCallErr])
  
  useEffect(() => {
    if(socket && apptInfo){
      socket.emit('userAddForCall', apptInfo)
      console.log("apptInfo", apptInfo);
    }
  }, [socket, apptInfo])

  return (
    <div className="main-video-page">
      <div className="video-chat-wrapper">
        <video id="large-feed" ref={largeFeedEl} autoPlay controls playsInline ></video>
        <video id="own-feed" ref={smallFeedEl} autoPlay controls playsInline ></video>
        {showCallInfo ? <CallInfo apptInfo={apptInfo} /> : <></>}
        <ChatWindow />
      </div>
      <ActionButtons smallFeedEl={smallFeedEl} largeFeedEl={largeFeedEl} />
    </div>
  );
};

export default CallerVideoPage;
