import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/srtDossierCreate.scss";
import CustomButton from "../../components/reusable/CustomButton";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import CustomInput from "../../components/reusable/CustomInput";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import {
  dossierClearErrors,
  dossierClearState,
  dossierCreateAction,
} from "../../redux/actions/dossierAction";
import { useEffect } from "react";
const PhycoDossierSrtCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const draftData = location?.state?.item;
  const [, , showNotification] = useNotification();
  const [currentStep, setCurrentStep] = useState(1);
  const [instruction, setInstruction] = useState("");
  const [instructionList, setInstructionList] = useState([]);

  // GET DATA FROM REDUCER STATE
  const { dossierData, error, loading } = useSelector(
    (state) => state.addDossier
  );

  // DOSSIER TEST IMAGE
  const [situations, setSituations] = useState("");
  const [situtationList, setSitutationList] = useState(
    draftData?.situtationList ? draftData?.situtationList : []
  );

  const [formData, setFormData] = useState({
    title: draftData?.title ? draftData.title : "",
    description: draftData?.description ? draftData.description : "",
  });
  // INPUT ON CHANGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddsituations = () => {
    if (!situations) {
      showNotification("error", "Please add a situations");
    } else if (situtationList.length >= 60) {
      showNotification("error", "You can add only 60 situations");
    } else {
      setSitutationList([...situtationList, { situations: situations }]);
      setSituations("");
    }
  };
  // DRAG AND DROP FOR WORDS
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDrop = (e, currentIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedWords = [...situtationList];
    const [draggedWord] = updatedWords.splice(draggedIndex, 1);
    updatedWords.splice(currentIndex, 0, draggedWord);
    setSitutationList(updatedWords);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // HANDLE REMOVE LIST
  const handleRemovesituations = (index) => {
    const updatedWordList = situtationList.filter((_, i) => i !== index);
    // Update the sWATe with the new array
    setSitutationList(updatedWordList);
  };

  const handleAddInstruction = () => {
    if (!instruction) {
      showNotification("error", "Please add a instruction");
    } else {
      setInstructionList([...instructionList, { instruction: instruction }]);
      setInstruction("");
    }
  };

  // HANDLE REMOVE instruction
  const handleRemoveInstruction = (index) => {
    const updatedinstructionList = instructionList.filter(
      (_, i) => i !== index
    );
    // Update the sWATe with the new array
    setInstructionList(updatedinstructionList);
  };

  // ADD COURSE STEPS
  const handleNext = () => {
    if (currentStep < 4) {
      if (
        currentStep === 1 &&
        (formData.title === "" || formData.description === "")
      ) {
        showNotification("error", "Please fill all fields");
      } else if (currentStep === 2 && instructionList.length < 1) {
        showNotification("error", "Please add instruction");
      } else if (currentStep === 3 && situtationList.length !== 60)
        showNotification("error", "Please add 60 situations");
      else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleFirstStep = () => {
    setCurrentStep(1);
  };

  // progressbar width of stepper form

  let totalSteps = 4;
  const calculateProgress = () => {
    return (currentStep / totalSteps) * 100;
  };

  // FORM SUBMIT WAT DOSSIER CREATION
  const handleSubmit = () => {
    const label = 3;
    dispatch(
      dossierCreateAction(
        label,
        formData?.title,
        formData?.description,
        instructionList,
        [],
        situtationList,
        []
      )
    );
  };

  // SAVED DATA TO DRAFT WITH LOCAL STORAGE

  const handleSaveToDraft = () => {
    let draftData = {
      label: 3,
      title: formData?.title,
      description: formData?.description,
      instructionList: instructionList,
      situtationList: situtationList,
    };
    let existingDraftData = localStorage.getItem("dossier")
      ? JSON.parse(localStorage.getItem("dossier"))
      : [];
    const draftId = location?.state?.index;
    let filterDraft = existingDraftData.filter((ele, idx) => idx !== draftId);
    filterDraft.push(draftData);
    localStorage.setItem("dossier", JSON.stringify(filterDraft));
    showNotification("success", "Draft Saved");
    navigate("/officer/upload-dossier", { state: 1 });
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(dossierClearErrors());
    }
    if (dossierData?.success) {
      showNotification("success", dossierData?.message);
      navigate("/officer/upload-dossier");
      dispatch(dossierClearState());
    }
  }, [
    error,
    dossierData?.success,
    showNotification,
    dossierData?.message,
    navigate,
    dispatch,
  ]);

  return (
    <div className="wat_dossier_create">
      <div className="header">
        <div className="title">
          Create SRT (Situation Reaction Test)
          <button
            title="Go Back"
            onClick={() => navigate("/officer/upload-dossier")}
          >
            <RxCross2 />
          </button>
        </div>
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${calculateProgress()}%` }}
          ></div>
        </div>
      </div>
      <div className="container">
        <div>
          <div className="create_dossier_content">
            {/* <div className='title'>
            Create Instruction for test
          </div> */}

            <div className="dossierForm">
              {currentStep === 1 && (
                <>
                  <CustomInput
                    label="Title"
                    type="text"
                    placeholder="SSB interview SRT"
                    name="title"
                    impStar="*"
                    value={formData.title}
                    onChange={handleInputChange}
                  />

                  <CustomTextarea
                    label="Description"
                    type="text"
                    impStar="*"
                    placeholder="Upto 500 words..."
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </>
              )}
              {currentStep === 2 && (
                <div className="sc-irTswW izZXSN myInput">
                  <label>
                    Instruction <abbr className="impStar">*</abbr>
                  </label>
                  <div className="input_icon courseinstruction">
                    <input
                      className="input_text"
                      type="text"
                      placeholder="SRT instruction..."
                      name="feature"
                      value={instruction}
                      onChange={(e) => setInstruction(e.target.value)}
                    />
                    <button
                      title="Add More Instruction"
                      onClick={handleAddInstruction}
                    >
                      <FaPlus />
                    </button>
                  </div>
                  {instructionList && instructionList.length > 0 && (
                    <div className="feature_list">
                      <ul>
                        {instructionList.map((ele, i) => (
                          <li key={i}>
                            <div>
                              <p>{ele.instruction}</p>
                              <span onClick={() => handleRemoveInstruction(i)}>
                                <RxCross2 />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {currentStep === 3 && (
                <div className="test_images">
                  <label>
                    Upload SRT <p>(Add 60 situations)</p>
                  </label>
                  <div className="input_icon courseFeatures">
                    <input
                      className="input_text"
                      type="text"
                      placeholder="Add situations ..."
                      name="situations"
                      value={situations}
                      onChange={(e) => setSituations(e.target.value)}
                    />
                    <button
                      title="Add More Situations"
                      onClick={handleAddsituations}
                    >
                      <FaPlus />
                    </button>
                  </div>
                  {situtationList && situtationList.length > 0 && (
                    <div className="question_list">
                      <ul>
                        {situtationList.map((ele, i) => (
                          <li
                            key={i}
                            draggable
                            onDragStart={(e) => handleDragStart(e, i)}
                            onDrop={(e) => handleDrop(e, i)}
                            onDragOver={handleDragOver}
                          >
                            <div>
                              <p>{ele?.situations}</p>
                              <span onClick={() => handleRemovesituations(i)}>
                                <RxCross2 />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {currentStep === 4 && (
                <>
                  <div className="review_details">
                    <h2 className="txt-center">Review your SRT details</h2>
                    <div className="review_wrap">
                      <ol>
                        <li>
                          <div>
                            <p>Title :</p>
                            <p>{formData?.title}</p>
                          </div>
                        </li>
                      </ol>
                      <ol>
                        <li>
                          <div className="instruction">
                            <span>Instruction :</span>
                            <div className="instruction_list">
                              {instructionList?.map((ele, idx) => (
                                <span key={idx}>{ele?.instruction}</span>
                              ))}
                            </div>
                          </div>
                        </li>
                      </ol>
                      <ol>
                        <li>
                          <div className="description">
                            <p>Description : </p>
                            <p>{formData?.description}</p>
                          </div>
                        </li>
                      </ol>
                      <ol>
                        <li>
                          <div className="situtationList">
                            <p>Situation List :</p>
                            <div>
                              {situtationList?.map((ele, index) => (
                                <p key={index}>{ele?.situations}</p>
                              ))}
                            </div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="createBtn">
                    <CustomButton
                      title="Edit Test"
                      onClick={handleFirstStep}
                      // disabled={true}
                    />
                    <CustomButton
                      title="Save SRT"
                      // title={ssbCreateLoading ? <ButtonLoader /> : `Create Course`}
                      onClick={handleSubmit}
                      // disabled={true}
                    />
                  </div>
                </>
              )}
              {currentStep < 4 && (
                <div className="draft_btn">
                  <CustomButton
                    title="Save As Draft"
                    className="btn"
                    onClick={() => handleSaveToDraft()}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="navigation_buttons">
          {currentStep > 1 && (
            <button onClick={handlePrev} className="prev">
              Previous
            </button>
          )}
          {currentStep < 4 && (
            <button onClick={handleNext} className="next">
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhycoDossierSrtCreate;
