import React, { Fragment } from "react";
import "../../styles/customStepper.scss";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
// import stepperLogo from "../../images/stepperLogo.png";

const CustomStepper = ({ activeStep }) => {
  const steps = [
    {
      label: <Typography>Register</Typography>,
      icon: 1,
    },
    {
      label: <Typography>Payment</Typography>,
      icon: 2,
    },
    {
      label: <Typography>Start Learning</Typography>,
      icon: 3,
    },
  ];

  const stepStyles = {
    boxSizing: "border-box",
  };
  return (
    <Fragment>
      <Stepper alternativeLabel activeStep={activeStep} style={stepStyles}>
        {steps.map((item, index) => {
          return (
            <Step
              className="redred"
              key={index}
              active={activeStep === index ? true : false}
              completed={activeStep >= index ? true : false}
            >
              <StepLabel
                style={{
                  color: activeStep >= index ? "red" : "#fff",
                }}
                icon={item.icon}
              >
                {item.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Fragment>
  );
};

export default CustomStepper;
