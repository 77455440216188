export const MockPsy = [
  {
    id: 1,
    title: "TAT (Thematic Apperception Test)",
    subTitle: "12 Images, 54 min",
  },
  {
    id: 2,
    title: "WAT (Word Association Test)",
    subTitle: "60 Words, 15 min",
  },
  {
    id: 3,
    title: "SRT (Situation Reaction Test)",
    subTitle: "60 Situations, 30 min",
  },
  {
    id: 4,
    title: "SD (Self Description) ",
    subTitle: "5 Questions, 15 min",
  },

];
