import updateCallStatus from "../../../redux/actions/updateCallStatus";

const proSocketListeners = (socket, setApptInfo, dispatch) => {
  socket.on("apptData", (apptData) => {
    console.log(apptData);
    setApptInfo(apptData);
  });

  socket.on("newOfferWaiting", (offerData) => {
    console.log("newOfferWaiting", offerData);
    dispatch(updateCallStatus('offer', offerData.offer))
    dispatch(updateCallStatus('myRole', 'answerer'))
  });
};

const proVideoSocketListeners = (socket, addIceCandidateToPc) => {
  socket.on('iceToClient', (iceC)=> {
    console.log("iceC", iceC)
    addIceCandidateToPc(iceC)
  })
}

export {proSocketListeners, proVideoSocketListeners};
