import React, { Fragment } from "react";
import "../../styles/officerTimetable.scss";
import Loader from "../../components/reusable/Loader";
import OfficerLayout from "./OfficerLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { MdFilterList } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { useAuth } from "../../context/userContext";
import OfficerNoDataCard from "./OfficerNoDataCard";

const OfficerTimeTable = () => {
  const loading = false;
  const navigate = useNavigate();
  const [auth] = useAuth();

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <div className="txt_color officer_timeTable_list">
              <div className="title">
                <h2>Time Table</h2>

                {auth?.user?.isVerified && (
                  <div className="action_btns">
                    <button title="Filter Students">
                      <MdFilterList />
                    </button>
                    <button
                      title="Add New Time Table"
                      onClick={() => navigate("/officer/create-timetable")}
                    >
                      <FaPlus />
                    </button>
                  </div>
                )}
              </div>
              {auth?.user?.isVerified ? (
                <div className="batch_timing_main officer_course_content txt_color">
                  <h3>scheduled interview</h3>
                  <div className="timing_calendar">
                    <div className="timing_table custonScroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Student Name</th>
                            <th>Enroll Course</th>
                            <th>Schedule Date</th>
                            <th>Schedule Time</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[1, 2, 3, 4, 5].map((index) => (
                            <tr key={index}>
                              <td>
                                <p>Rahul</p>
                              </td>
                              <td>
                                <p>SSB GD COURSE</p>
                              </td>
                              <td>
                                <p>04-12-2023</p>
                              </td>
                              <td>
                                <p>06:15 PM</p>
                              </td>
                              <td>
                                <button title="View/Edit Timetable">
                                  <AiFillEye />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <OfficerNoDataCard
                  txt="The authorization to create time table will be provided after we assess your
                         document and verify your service at SSB/AFSB/NSB. 
                         This process may take up to three days from the date of account creation."
                  // icon={<FaRegSadTear />}
                />
              )}

              {/* <div className="batch_timing_main officer_course_content txt_color">
                <h3>availaible time table</h3>
                {
                  auth?.user?.timeTable.length > 0 ?
                    <div className="timing_calendar">
                      <div className="timing_table custonScroll">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Time Table</th>
                              <th>Week Day</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {auth?.user?.timeTable?.map((item, idx) => (
                              <tr key={idx}>
                                <td>
                                  {
                                    item?.classTime?.map((ele) => (
                                      <p key={ele._id}>{moment(ele?.time, "HH:mm:ss").format("hh:mm A")}</p>
                                    ))
                                  }

                                </td>
                                <td>
                                  <p>{item?.weekDay}</p>
                                </td>
                                <td>
                                  <button title="View/Edit Timetable" onClick={() => showModal(auth?.user?.timeTable[idx])}>
                                    <AiFillEye />
                                  </button>
                                  <button title="Delete Timetable">
                                    <MdDelete />
                                  </button>
                                  <CustomModal
                                    closeModal={handleCancel}
                                    visible={isModalVisible}
                                    title={"Update Time Table"}
                                    timeArray={timeArray}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    :
                    <OfficerNoDataCard
                      txt={"There is no time table added yet"}
                      icon={<FaRegSadTear />}
                    />
                }

              </div> */}
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OfficerTimeTable;
