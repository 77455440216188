import React, { Fragment } from "react";
import "../../styles/studentPhysical.scss";
import StudentLayout from "../../components/student/StudentLayout";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import physicalchartcenter from "../../images/physicalchartcenter.png";
import styled from "styled-components";
import mentorImg from "../../images/mentorImg.png";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaEnvelope } from "react-icons/fa";
import { courseSidebarLinks } from "./courseSidebarLinks";
import StudentFooter from "../../components/student/StudentFooter";

const LineChart = styled.div`
  height: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 50px;
  position: relative;

  &:after {
    background-color: ${(props) => props.color};
    content: "";
    display: block;
    height: 20px;
    width: ${(props) => props.range};
    max-width: 100%;
    border-radius: 50px;
  }
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11px;
    text-shadow: 0px 0px 2px #000;
  }
`;

const StudentPhysical = ({
  physicalEarnedNumber = "70",
  totalPhysicalNumber = "100",
}) => {
  ChartJS.register(ArcElement, Tooltip);
  const doughnutState = {
    labels: ["Physical Points", "Not Gained Pointes"],
    datasets: [
      {
        backgroundColor: ["#44DA5C", "#f2f2f2"],
        hoverBackgroundColor: ["#329E43", "#C7C7C7"],
        data: [
          physicalEarnedNumber,
          totalPhysicalNumber - physicalEarnedNumber,
        ],
      },
    ],
  };

  //   LINE CHART RANGE
  const range = "70%";

  return (
    <Fragment>
      <StudentLayout sidebarLinks={courseSidebarLinks}>
        <div className="phsical_performance_main txt_color">
          <div className="performance_sec">
            <div className="title">
              <h2>Physical Performance</h2>
            </div>
            <div className="stats_card">
              <div className="card_title">Overall Stats</div>

              <div className="stats_chart">
                <div className="circle_chart">
                  <Doughnut data={doughnutState} />
                  <div className="icon_phyical">
                    <img src={physicalchartcenter} alt="physicalchartcenter" />
                  </div>
                </div>
                <div className="circle_chart_number">
                  <p>+ {physicalEarnedNumber} %</p>
                </div>
              </div>

              <div className="card_title" style={{ marginTop: "25px" }}>
                Current Stats
              </div>

              <div className="stats_line_card">
                <div className="line_stats">
                  <LineChart
                    range={range}
                    color={
                      range > "60%"
                        ? "#47EC61"
                        : range > "30%"
                        ? "#0049C6"
                        : range >= "0%"
                        ? "#D0333B"
                        : ""
                    }
                    className="chart_line"
                  >
                    <p className="txt_color">{range}</p>
                  </LineChart>
                </div>
                <div className="content_stats">
                  <ul>
                    <li>Running</li>
                    <li>Medical</li>
                    <li>Weekly tasks</li>
                  </ul>
                </div>
              </div>

              <div className="color_inst">
                <p>
                  <span className="low"></span>
                  <span>Low</span>
                </p>
                <p>
                  <span className="medium"></span>
                  <span>Moderate</span>
                </p>
                <p>
                  <span className="excellent"></span>
                  <span>Excellent</span>
                </p>
              </div>
            </div>
          </div>
          <div className="mentor_sec txt_color">
            <div className="title">
              <h2>Mentor details</h2>
            </div>

            <div className="mentorDetail_content">
              <div className="mentorDetail_card">
                <div className="img_sec">
                  <img src={mentorImg} alt={mentorImg} />
                </div>
                <div className="txt_sec">
                  <h2>Mr. Shashank Gupta</h2>
                  <p className="sub_title">
                    Physical and resistance training specialist
                  </p>
                  <p className="content">
                    Health + fitness is about more than just exercise. We make
                    fitness fun, challenging and engaging—so you can enjoy the
                    process and results together.I want to make sure you are
                    doing everything you can to stay healthy, fit and strong!
                  </p>
                </div>
              </div>

              <div className="social_icon_links">
                <Link to="/" title="Facebook">
                  <FaFacebookF />
                </Link>
                <Link to="/" title="Twitter">
                  <FaTwitter />
                </Link>
                <Link to="/" title="E-Mail">
                  <FaEnvelope />
                </Link>
              </div>

              <div className="live_chat_section">
                <h2>For live chat and mentor guidance</h2>
                <p>
                  Click on button below to connect with your mentor on whatsapp
                </p>
                <Link to="/" title="Whatsapp">
                  Click here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </StudentLayout>
      {/* FOOTER START */}
      <StudentFooter />
      {/* FOOTER ENDS */}
    </Fragment>
  );
};

export default StudentPhysical;
