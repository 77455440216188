import React, { Fragment, useEffect, useState } from "react";
import "../../styles/oCourseCreate.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import CustomInput from "../../components/reusable/CustomInput";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import CustomSelect from "../../components/reusable/CustomSelect";
import { BsCurrencyRupee } from "react-icons/bs";
import {
  FaInfoCircle,
  FaPercent,
  FaPlus,
  FaRupeeSign,
  FaUpload,
} from "react-icons/fa";
import { thumbnail } from "../../assets/images";
import CustomButton from "../../components/reusable/CustomButton";
import CustomDateInput from "../../components/reusable/CustomDateInput";
import { MdDateRange, MdOutlinePercent } from "react-icons/md";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { useSelector } from "react-redux";
import {
  teacherClearErrors,
  teacherClearState,
  teacherCourseCreateAction,
} from "../../redux/actions/teacherAction";
import { useNotification } from "../../context/notificationContext";
import CustomCheckbox from "../../components/reusable/CustomCheckbox";
import { RiDeleteBin5Fill } from "react-icons/ri";
import CustomMultiSelect from "../../components/reusable/CustomMultiSelect";
import { clearErrors, officerListAction } from "../../redux/actions/userAction";
import { useAuth } from "../../context/userContext";
import { GrClose } from "react-icons/gr";
const OCourseCreate = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [, , showNotification] = useNotification();
  const [currentStep, setCurrentStep] = useState(1);
  const [feature, setFeature] = useState("");
  const [featureList, setFeatureList] = useState([]);
  const [collaborator, setCollaborator] = useState([]);
  const [content, setContent] = useState("");
  const [contentList, setContentList] = useState([]);
  // IMAGE HANDLER
  const [image, setImage] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(thumbnail);

  const [collaboratorName, setCollaboratorName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [collabError, setCollabError] = useState("");
  const [weekDay, setWeekDay] = useState("");
  const [time, setTime] = useState("");
  const [endTime, setEndTime] = useState("");

  // COLLABORATOR OPTION VALUE STATE
  const [collaboratorOption, setCollaboratorOption] = useState("no");
  const [formData, setFormData] = useState({
    title: "",
    titleHindi: "",
    shortDescription: "",
    description: "",
    shortDescriptionHindi: "",
    descriptionHindi: "",
    original_price: "",
    discountedPrice: "",
    discount: "",
    discountValidity: "",
    courseDuration: "",
    study_mode: location?.state?.existData?.category,
    affiliate_percentage: "",
    starting_date: "",
    googleLocation: "",
    classType: "",
    courseCategory: location?.state?.existData?.courseCategory,
    bannervideoLink: "",
    monthlyClassDate: "",
    weeklyClassDay: "",
  });

  const {
    title,
    titleHindi,
    shortDescription,
    description,
    shortDescriptionHindi,
    descriptionHindi,
    original_price,
    discountedPrice,
    discount,
    discountValidity,
    courseDuration,
    study_mode,
    affiliate_percentage,
    starting_date,
    courseCategory,
    bannervideoLink,
    googleLocation,
    classType,
    weeklyClassDay,
    monthlyClassDate,
  } = formData;

  // const discountedPriceValue = Math.floor(
  //   original_price - (original_price * discount) / 100
  // );

  // useEffect(() => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     discountedPrice: discount ? discountedPriceValue : "",
  //   }));
  // }, [discount, discountedPriceValue]);

  // DISCOUNT VALIDATION
  useEffect(() => {
    if (discount) {
      const intValue = parseInt(discount, 10);
      if (isNaN(intValue) || intValue < 0 || intValue > 100) {
        showNotification("error", "Please enter a valid discount");
      }
    }
  }, [discount, showNotification]);

  // Price VALIDATION
  useEffect(() => {
    if (original_price !== undefined && original_price !== null) {
      const isValidInput = /^-?\d*$/.test(original_price);
      if (!isValidInput || Number(original_price) < 0) {
        showNotification("error", "Please enter a valid price value");
      }
    }
  }, [original_price, showNotification]);

  // MONTH LIST
  const monthArray = Array.from({ length: 30 }, (_, idx) => ({
    option: `${idx + 1}`,
  }));

  monthArray.push({ option: `Last Day of Month` });

  const [goalType, setGoalType] = useState();

  // INPUT ON CHANGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;

    if (name === "discount") {
      const discountValue = parseInt(value, 10);
      const discountedPriceValue = Math.floor(
        original_price - (original_price * discountValue) / 100
      );

      setFormData((prevData) => ({
        ...prevData,
        discount: value,
        discountedPrice: isNaN(discountedPriceValue)
          ? ""
          : discountedPriceValue,
      }));
    } else if (name === "discountedPrice") {
      const discountedPriceValue = parseInt(value, 10);
      let discountValue;
      if (original_price === discountedPriceValue) {
        discountValue = 100;
      } else {
        const discountVal = Math.floor(
          ((original_price - discountedPriceValue) / original_price) * 100
        );
        discountValue = discountVal;
      }

      setFormData((prevData) => ({
        ...prevData,
        discount: isNaN(discountValue) ? "" : discountValue,
        discountedPrice: value,
      }));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // IMAGE HANDLER
  const handleAvatarChange = (e) => {
    if (e.target.name === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader?.readyState === 2) {
          setAvatarPreview(reader?.result);
          setImage(reader?.result);
        }
      };
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  // HANDLE FEATURE ADDS START
  const handleAddFeature = () => {
    if (!feature) {
      showNotification("error", "Please add a learning content for aspirant");
    } else if (featureList.length >= 6) {
      showNotification("error", "You can add maximum 6");
    } else {
      setFeatureList([...featureList, { feature: feature }]);
      setFeature("");
    }
  };

  // HANDLE REMOVE FEATURE
  const handleRemoveFeature = (index) => {
    const updatedFeatureList = featureList.filter((_, i) => i !== index);
    // Update the state with the new array
    setFeatureList(updatedFeatureList);
  };

  // HANDLE CONTENT ADDS START
  const handleAddContent = () => {
    if (!content) {
      showNotification("error", "Please add a learning content");
    } else if (contentList.length >= 10) {
      showNotification("error", "You can add maximum 10");
    } else {
      setContentList([...contentList, { content: content }]);
      setContent("");
    }
  };

  // HANDLE REMOVE CONTENT
  const handleRemoveContent = (index) => {
    const updatedFeatureList = contentList.filter((_, i) => i !== index);
    // Update the state with the new array
    setContentList(updatedFeatureList);
  };

  // HANDLE collaborator add in list

  const handleRadioChange = (e) => {
    setCollaboratorOption(e.target.value);
  };

  const handleAddCollaborator = () => {
    if (!collaboratorName || !percentage) {
      showNotification("error", "Please Add name and collaborator percent.");
    } else if (
      collaborator.some((itm) => itm.value === collaboratorName?.value)
    ) {
      setCollabError("You cannot add same collaborator multiple time");
      setTimeout(() => {
        setCollabError("");
      }, 4000);
    } else {
      const totalPercentage = collaborator.reduce(
        (sum, collaborator) => sum + parseFloat(collaborator.percentage || 0),
        0
      );
      if (totalPercentage + parseFloat(percentage) >= 100) {
        showNotification(
          "error",
          "Total percentage cannot be greater than 100%."
        );
      } else {
        setCollaborator([
          ...collaborator,
          {
            label: collaboratorName?.label,
            user: collaboratorName?.value,
            percentage: parseFloat(percentage),
          },
        ]);
        setPercentage("");
        setCollaboratorName("");
      }
    }
  };
  // HANDLE collaborator add in list

  // DELETe COLLABORATOR
  const handleDeleteCollab = (item) => {
    var newData = collaborator;
    newData.splice(item, 1);
    setCollaborator([...newData]);
    showNotification("success", `Index number ${item + 1} deleted`);
  };
  // DELETe COLLABORATOR ENDS

  // ADD TIME TABLE IN ARRAY
  const [timeError, setTimeError] = useState("");
  const [timeList, setTimeList] = useState([]);

  const handleAddTimeTable = () => {
    if (!weekDay || !time) {
      showNotification(
        "error",
        "Please Select Time and WeekDay for add in list"
      );
    } else {
      const existingTimeSlot = timeList.find(
        (slot) =>
          slot?.weekDay === weekDay &&
          slot.classTime.some(
            (existingSlot) =>
              existingSlot.time === time ||
              existingSlot.endTime === endTime ||
              (new Date(`2000-01-01 ${time}`) >=
                new Date(`2000-01-01 ${existingSlot.time}`) &&
                new Date(`2000-01-01 ${time}`) <=
                  new Date(`2000-01-01 ${existingSlot.endTime}`)) ||
              (new Date(`2000-01-01 ${endTime}`) >=
                new Date(`2000-01-01 ${existingSlot.time}`) &&
                new Date(`2000-01-01 ${endTime}`) <=
                  new Date(`2000-01-01 ${existingSlot.endTime}`))
          )
      );
      if (existingTimeSlot) {
        setTimeError(
          "A class already exists time slot. Please choose a time after this slot time."
        );
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else if (
        new Date(`2000-01-01 ${time}`) > new Date(`2000-01-01 ${endTime}`)
      ) {
        setTimeError("Start time cannot be greater than end time.");
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else if (time === endTime) {
        setTimeError("Start time and end time cannot be the same.");
        setTimeout(() => {
          setTimeError("");
        }, 3000);
      } else {
        const existingDayIndex = timeList?.findIndex(
          (item) => item.weekDay === weekDay
        );

        if (existingDayIndex !== -1) {
          const updatedTimeList = [...timeList];
          updatedTimeList[existingDayIndex].classTime.push({ time, endTime });

          setTimeList(updatedTimeList);
        } else {
          setTimeList([
            ...timeList,
            {
              weekDay: weekDay,
              classTime: [{ time: time, endTime: endTime }],
            },
          ]);
        }
        // setSelectedTime({
        //   weekDay: weekDay,
        //   classTime: [
        //     ...classTime,
        //     { time: time, endTime: endTime, weekDay: weekDay },
        //   ],
        // });
        setTime("");
        setEndTime("");
      }
    }
  };

  // DELETE SINGLE TIME TABLE ROW FROM ARRAY
  const deleteTimeRow = (id) => {
    var newData = timeList;
    // var classData = classTime;
    // classData.splice(id,1)
    newData.splice(id, 1);
    setTimeList([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };

  // RECURRING CLASSES RADIO OPTION
  const [classesOption, setClassesOption] = useState("yes");
  const [recurringClass, setRecurringClass] = useState({});
  const handleClassOption = (e) => {
    setClassesOption(e.target.value);
    setRecurringClass({});
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: "",
    }));
  };

  // COURSE INCLUDES ON CHNAGE
  const [courseIncludes, setCourseIncludes] = useState([]);
  const [includesInput, setIncludesInput] = useState(false);
  const [customInclude, setCustomInclude] = useState("");
  const [customIncludeArray, setCustomIncludeArray] = useState("");
  const [checkboxStates, setCheckboxStates] = useState({
    phn_call: false,
    video_call: false,
    dossier: false,
    question: false,
    stdy_material: false,
    mock_interview: false,
    live_gd: false,
    mock_psycho_test: false,
    gto_simulator: false,
    full_length_tat: false,
    full_length_wat: false,
    full_length_srt: false,
    full_length_sd: false,
    checked_dossier: false,
    phn_call_physclogist: false,
    video_call_physclogist: false,
  });

  // ADD COURSE INCLUDE TO STATE COURSEINCLUDES
  const handleCourseIncludes = (e) => {
    const { id, value } = e.target;
    const valueExists = courseIncludes.includes(value);
    if (valueExists) {
      const newCourseIncludes = courseIncludes.filter((item) => item !== value);
      setCourseIncludes(newCourseIncludes);
    } else {
      setCourseIncludes([...courseIncludes, value]);
    }
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [id]: !valueExists,
    }));
  };

  // ADD INCLUDE STRING FROM CUSTOM ARRAY
  const handleAddIncludes = () => {
    const valueExists = courseIncludes.includes(customInclude);
    if (valueExists) {
      showNotification("error", "Already added");
    } else {
      setCourseIncludes([...courseIncludes, customInclude]);
      setCustomIncludeArray([...customIncludeArray, customInclude]);
      setCustomInclude("");
    }
  };

  // REMOVE INCLUDE STRING FROM CUSTOM ARRAY
  const handleRemoveInclude = (idx) => {
    const updateIncludeList = courseIncludes.filter((_, i) => i !== idx);
    const customInclUpdateList = customIncludeArray.filter((_, i) => i !== idx);
    setCustomIncludeArray(customInclUpdateList);
    setCourseIncludes(updateIncludeList.concat(customInclUpdateList));
  };

  //   HANDLE PREV STEP FUNCTION
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  //   HANDLE NEXT STEP FUNCTION
  const handleNext = () => {
    if (currentStep < 7) {
      if (currentStep === 1) {
        if (title === "") {
          showNotification("error", "Please fill required fields");
        } else if (
          courseCategory !== "Mock" &&
          study_mode === "Online" &&
          classType === ""
        ) {
          showNotification("error", "Please fill all fields");
        } else if (
          courseCategory !== "Mock" &&
          classType === "Live" &&
          timeList?.length < 1
        ) {
          showNotification("error", "Please add class time");
        } else if (
          courseCategory &&
          study_mode === "Offline" &&
          googleLocation === ""
        )
          showNotification("error", "Please add google location");
        else {
          setCurrentStep(currentStep + 1);
        }
      } else if (currentStep === 2) {
        const isValidoriginal_price = /^\d+$/.test(original_price);
        const intValue = parseInt(discount, 10);
        const isValidDiscount =
          isNaN(intValue) || intValue < 0 || intValue > 100;
        if (formData.original_price === "" || !isValidoriginal_price) {
          showNotification("error", "Please enter a valid price value");
        } else if (discount !== "" && isValidDiscount) {
          showNotification("error", "Please enter valid discount!");
        } else {
          setCurrentStep(currentStep + 1);
        }
      } else if (currentStep === 3) {
        if (
          courseCategory === "Written" &&
          featureList?.length < 4 &&
          contentList?.length < 4
        ) {
          showNotification("error", "Please add minimum 4");
        } else if (courseCategory !== "Written" && featureList?.length < 4) {
          showNotification("error", "Please add minimum 4");
        } else {
          setCurrentStep(currentStep + 1);
        }
      } else if (currentStep === 4) {
        if (formData.shortDescription === "") {
          showNotification("error", "Please enter short description");
        } else if (formData?.shortDescription?.length > 300) {
          showNotification(
            "error",
            "Please enter short description with 300 character"
          );
        } else if (formData?.description === "") {
          showNotification("error", "Please enter description");
        } else if (formData?.description?.length > 1000) {
          showNotification(
            "error",
            "Please enter description with 1000 character"
          );
        } else {
          setCurrentStep(currentStep + 1);
        }
      } else if (currentStep === 5) {
        if (courseCategory !== "Mock" && courseDuration === "") {
          showNotification("error", "Please enter a course duration");
        } else if (goalType === "") {
          showNotification("error", "Please select a goal type");
        } else if (
          courseCategory !== "Mock" &&
          (study_mode === "Offline" || classType === "Recorded") &&
          formData.starting_date === ""
        ) {
          showNotification("error", "Please fill startDate");
        } else if (
          courseCategory !== "Mock" &&
          study_mode === "Online" &&
          classType !== "Recorded" &&
          classesOption === "yes" &&
          formData.starting_date === ""
        ) {
          showNotification("error", "Please fill startDate");
        } else if (
          courseCategory !== "Mock" &&
          classType !== "Recorded" &&
          study_mode === "Online" &&
          classesOption === "recurring" &&
          Object.keys(recurringClass).length === 0
        ) {
          showNotification("error", "Please fill recurringClass");
        } else if (
          courseCategory !== "Mock" &&
          study_mode === "Online" &&
          classType !== "Recorded" &&
          classesOption === "recurring" &&
          Object.keys(recurringClass).length !== 0 &&
          recurringClass?.label === "Monthly" &&
          formData.monthlyClassDate === ""
        ) {
          showNotification("error", "Please fill monthly Class Day");
        } else if (
          courseCategory !== "Mock" &&
          study_mode === "Online" &&
          classType !== "Recorded" &&
          classesOption === "recurring" &&
          Object.keys(recurringClass).length !== 0 &&
          recurringClass?.label === "Weekly" &&
          formData.weeklyClassDay === ""
        ) {
          showNotification("error", "Please fill weekly Class Day");
        } else {
          setCurrentStep(currentStep + 1);
        }
      } else if (currentStep === 6) {
        if (image === "") {
          showNotification("error", "Please upload course thumnail");
        } else {
          setCurrentStep(currentStep + 1);
        }
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleFirstStep = () => {
    setCurrentStep(1);
  };

  let totalSteps = 7;
  // progressbar width

  const calculateProgress = () => {
    return (currentStep / totalSteps) * 100;
  };

  // VALIDATE THE DATE INPUT
  const getCurrentDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const { error, loading, courseData } = useSelector(
    (state) => state.teacherCourseCreate
  );
  const [emiList] = useState([]);
  const handleSubmit = () => {
    const updatedPrice = parseInt(original_price);
    const updatesDiscount = parseInt(discount);
    const isEmi = false;
    dispatch(
      teacherCourseCreateAction(
        title,
        titleHindi,
        shortDescription,
        description,
        shortDescriptionHindi,
        descriptionHindi,
        updatedPrice,
        discountedPrice,
        updatesDiscount,
        discountValidity,
        courseDuration,
        study_mode,
        affiliate_percentage,
        starting_date,
        goalType,
        courseCategory,
        bannervideoLink,
        image,
        featureList,
        timeList,
        collaborator,
        googleLocation,
        courseIncludes,
        weeklyClassDay,
        monthlyClassDate,
        classType,
        isEmi,
        emiList
      )
    );
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(teacherClearErrors());
    }
    if (courseData?.success) {
      showNotification("success", courseData?.message);
      dispatch(teacherClearState());
      navigate("/teacher/courses");
    }
  }, [
    dispatch,
    navigate,
    error,
    showNotification,
    courseData?.success,
    courseData?.message,
  ]);

  // GET ALL OFFICER LIST
  const {
    error: officerListError,
    loading: officerListLoading,
    userList,
  } = useSelector((state) => state.officerList);

  useEffect(() => {
    dispatch(officerListAction());
  }, [dispatch]);

  const officerList = userList?.officers?.filter(
    (itm) =>
      itm?._id !== auth?.user?._id && (itm?.role === "12" || itm?.role === "13")
  );

  // EXTRACT OFFICERS ID FROM OFFICER LIST ARRAY
  let officerArray = [];

  officerList
    ?.filter((itm) => itm?._id !== auth?.user?._id)
    ?.forEach((ele) => {
      officerArray.push({
        label: ele?.first_name,
        value: ele?._id,
      });
    });

  useEffect(() => {
    if (officerListError) {
      dispatch(clearErrors());
    }
  }, [dispatch, officerListError]);

  return (
    <Fragment>
      <div className="officer_createCourse_main">
        <div className="header">
          <div className="title">
            Create Course - Step {currentStep}
            <button
              title="Go Back"
              onClick={() => navigate("/teacher/courses")}
            >
              <RxCross2 />
            </button>
          </div>

          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${calculateProgress()}%` }}
            ></div>
          </div>
        </div>
        <div className="container">
          <div>
            <div className="create_course_content">
              <div className="courseForm">
                {currentStep === 1 && (
                  <>
                    <CustomInput
                      label="Course Title"
                      type="text"
                      placeholder="Ex:- Mock Interview By Colonel Sanjay"
                      name="title"
                      impStar="*"
                      tooltip="Ensure that the course name is self-explanatory, indicating the specific target entry exams (such as AFCAT, CDS, NDA)"
                      value={formData.title}
                      onChange={handleInputChange}
                    />
                    {study_mode === "Online" && courseCategory !== "Mock" && (
                      <CustomSelect
                        label="Select Class Type"
                        impStar="*"
                        name="classType"
                        onChange={handleInputChange}
                        value={formData.classType}
                        optionArray={[
                          {
                            option: "Live",
                          },
                          {
                            option: "Recorded",
                          },
                        ]}
                      />
                    )}

                    {classType === "Live" && (
                      <div className="batch_timeTable">
                        <div className="title_label">Create Class Time</div>
                        <div className="add_timeTable">
                          <div>
                            <div className="timetable_tile">
                              <span>
                                <label>Select Weekday :</label>
                                <select
                                  name="weekDay"
                                  value={weekDay}
                                  onChange={(e) => setWeekDay(e.target.value)}
                                >
                                  <option value="">Select Week Day</option>
                                  <option value="Monday">Monday</option>
                                  <option value="Tuesday">Tuesday</option>
                                  <option value="Wednesday">Wednesday</option>
                                  <option value="Thrusday">Thrusday</option>
                                  <option value="Friday">Friday</option>
                                  <option value="Saturday">Saturday</option>
                                  <option value="Sunday">Sunday</option>
                                </select>
                              </span>
                              <div className="time_select">
                                <span>
                                  <label>Start Time :</label>
                                  <input
                                    type="time"
                                    name="time"
                                    value={time}
                                    onChange={(e) => setTime(e.target.value)}
                                  />
                                </span>
                                <span>
                                  <label>to</label>
                                </span>
                                <span>
                                  <label>End Time :</label>
                                  <input
                                    type="time"
                                    name="endTime"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                  />
                                </span>
                              </div>

                              <button
                                // title="Add New Time Table"
                                className="add_time"
                                onClick={() => handleAddTimeTable()}
                              >
                                <FaPlus />
                              </button>
                            </div>
                            {timeError && (
                              <p className="error_msg">{timeError}</p>
                            )}
                            {timeList?.length > 0 && (
                              <div className="time_list">
                                <h5>Time List:</h5>
                                {timeList?.map((item, idx) => (
                                  <p key={idx}>
                                    <div>
                                      <span>{item?.weekDay}</span>
                                      {item?.classTime?.map((ele, id) => (
                                        <span key={id}>
                                          {ele.time} - {ele.endTime}
                                        </span>
                                      ))}
                                    </div>

                                    <span onClick={() => deleteTimeRow(idx)}>
                                      <GrClose />
                                    </span>
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {study_mode === "Offline" && (
                      <CustomInput
                        label="Your location"
                        type="text"
                        placeholder="Ex:- Sector 45, Gurgaon,123034"
                        name="googleLocation"
                        impStar="*"
                        onChange={handleInputChange}
                        value={formData.googleLocation}
                      />
                    )}
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <CustomInput
                      icon={<BsCurrencyRupee />}
                      label="Price"
                      type="number"
                      placeholder="19000"
                      name="original_price"
                      impStar="*"
                      onChange={handleInputChange}
                      value={formData.original_price}
                    />
                    <div className="discount_tab">
                      <CustomInput
                        icon={<FaPercent />}
                        label="Discount Percentage"
                        type="number"
                        placeholder=""
                        name="discount"
                        // impStar="*"
                        onChange={handleInputChange}
                        value={formData.discount}
                      />
                      <CustomInput
                        icon={<FaRupeeSign />}
                        label="Discount Price"
                        type="number"
                        placeholder=""
                        name="discountedPrice"
                        // impStar="*"
                        onChange={handleInputChange}
                        value={formData.discountedPrice}
                      />
                    </div>
                    {formData.discount !== "" && (
                      <CustomDateInput
                        icon={<MdDateRange />}
                        label="Discount valid till"
                        type="date"
                        placeholder="Forever"
                        name="discountValidity"
                        impStar=""
                        tooltip="In case forever discount ,leave blank this input"
                        onChange={handleInputChange}
                        value={formData.discountValidity}
                        disabledDate={getCurrentDate}
                      />
                    )}
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <div className="sc-irTswW izZXSN myInput teacher_feature">
                      <label>
                        What Aspirant will learn (minimum 4)
                        <abbr className="impStar">*</abbr>
                      </label>
                      <div className="input_icon courseFeatures">
                        <input
                          className="input_text"
                          type="text"
                          placeholder="Add minimum 4"
                          name="feature"
                          value={feature}
                          onChange={(e) => setFeature(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleAddFeature();
                            }
                          }}
                        />
                        <button
                          title="Add More Feature"
                          onClick={handleAddFeature}
                        >
                          <FaPlus />
                        </button>
                      </div>
                      {featureList && featureList.length > 0 && (
                        <div className="feature_list">
                          <ul>
                            {featureList.map((ele, i) => (
                              <li key={i}>
                                <div>
                                  <p>{ele.feature}</p>
                                  <span onClick={() => handleRemoveFeature(i)}>
                                    <RxCross2 />
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    {courseCategory === "Written" && (
                      <div
                        className="sc-irTswW izZXSN myInput"
                        style={{ marginBottom: "20px" }}
                      >
                        <label>
                          Course Content (minimum 4)
                          <abbr className="impStar">*</abbr>
                        </label>
                        <div className="input_icon courseFeatures">
                          <input
                            className="input_text"
                            type="text"
                            placeholder="Add minimum 4"
                            name="content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleAddContent();
                              }
                            }}
                          />
                          <button
                            title="Add More Content"
                            onClick={handleAddContent}
                          >
                            <FaPlus />
                          </button>
                        </div>
                        {contentList && contentList.length > 0 && (
                          <div className="feature_list">
                            <ul>
                              {contentList.map((ele, i) => (
                                <li key={i}>
                                  <div>
                                    <p>{ele.content}</p>
                                    <span
                                      onClick={() => handleRemoveContent(i)}
                                    >
                                      <RxCross2 />
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}

                {currentStep === 4 && (
                  <>
                    <CustomTextarea
                      label="Course Short Description"
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Upto 300 words and add minimum 125 words"
                      name="shortDescription"
                      impStar="*"
                      value={formData.shortDescription}
                      fullWidth
                      textLength={300}
                    />

                    <CustomTextarea
                      label="Course Description"
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Upto 1000 words and add minimum 125 words"
                      name="description"
                      impStar="*"
                      value={formData.description}
                      fullWidth
                      textLength={1000}
                    />

                    <div className="include_content">
                      <p>This includes</p>

                      {(courseCategory === "Written" ||
                        courseCategory === "Spoken English") && (
                        <>
                          <CustomCheckbox
                            label="Live GD"
                            id="live_gd"
                            checked={checkboxStates.live_gd}
                            onChange={(e) => handleCourseIncludes(e)}
                          />

                          <CustomCheckbox
                            label="Mock Interview"
                            id="mock_interview"
                            checked={checkboxStates.mock_interview}
                            onChange={(e) => handleCourseIncludes(e)}
                          />
                          <CustomCheckbox
                            label="3D GTO simulator"
                            id="gto_simulator"
                            checked={checkboxStates.gto_simulator}
                            onChange={(e) => handleCourseIncludes(e)}
                          />
                        </>
                      )}

                      {courseCategory === "Mock" && (
                        <>
                          <CustomCheckbox
                            label="Mock Test"
                            id="mock_psycho_test"
                            checked={checkboxStates.mock_psycho_test}
                            onChange={(e) => handleCourseIncludes(e)}
                          />
                          <CustomCheckbox
                            label="Study Material"
                            id="stdy_material"
                            checked={checkboxStates.stdy_material}
                            onChange={(e) => handleCourseIncludes(e)}
                          />
                        </>
                      )}

                      {customIncludeArray?.length > 0 && (
                        <div className="include_list">
                          {customIncludeArray?.map((ele, idx) => (
                            <div>
                              <button onClick={() => handleRemoveInclude(idx)}>
                                <RxCross2 />
                              </button>
                              <p key={idx}>{ele}</p>
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="add_more_includes">
                        {includesInput && (
                          <>
                            <div className="include_input">
                              <CustomInput
                                icon=""
                                label="Add custom"
                                type="text"
                                onChange={(e) =>
                                  setCustomInclude(e.target.value)
                                }
                                placeholder=""
                                passwordMessage=""
                                name=""
                                value={customInclude}
                                impStar=""
                              />
                              <CustomButton
                                title={<FaPlus />}
                                width="50px"
                                onClick={() => handleAddIncludes()}
                              />
                            </div>
                          </>
                        )}
                        <CustomButton
                          title={includesInput ? "Cancel" : "Add More"}
                          icon={!includesInput && <FaPlus />}
                          width="130px"
                          className="cancel_btn"
                          onClick={() => setIncludesInput(!includesInput)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 5 && (
                  <>
                    {courseCategory !== "Mock" && (
                      <CustomInput
                        label="Course Duration"
                        type="number"
                        placeholder="Ex: 7 months course"
                        name="courseDuration"
                        impStar="*"
                        tooltip="Course Duration is in months"
                        value={formData.courseDuration}
                        onChange={handleInputChange}
                      />
                    )}
                    <CustomMultiSelect
                      label="Choose Goal Type"
                      impStar="*"
                      name="goalType"
                      isMulti={true}
                      onChange={(val) => setGoalType(val)}
                      value={goalType}
                      optionArray={[
                        {
                          value: 1,
                          label: "Army",
                        },
                        {
                          value: 2,
                          label: "Navy",
                        },
                        {
                          value: 3,
                          label: "Air Force",
                        },
                        {
                          value: 4,
                          label: "CAPF",
                        },
                        {
                          value: 5,
                          label: "Military Police",
                        },
                        {
                          value: 6,
                          label: "Police",
                        },
                      ]}
                    />
                    {courseCategory !== "Mock" && classType !== "Recorded" && (
                      <div className="start_date">
                        <div className="btn_group">
                          <div>
                            <label
                              className={
                                classesOption === "yes" ? "checked" : ""
                              }
                            >
                              <input
                                type="radio"
                                value="yes"
                                checked={classesOption === "yes"}
                                onChange={(e) => handleClassOption(e)}
                              />
                              One Time Course
                            </label>
                            <p>OR</p>
                            <label
                              className={
                                classesOption === "recurring" ? "checked" : ""
                              }
                            >
                              <input
                                type="radio"
                                value="recurring"
                                checked={classesOption === "recurring"}
                                onChange={(e) => handleClassOption(e)}
                              />
                              Recurring Course
                            </label>
                          </div>
                        </div>
                        {classesOption === "yes" ? (
                          <CustomDateInput
                            icon=""
                            label="Course Start Date"
                            type="date"
                            placeholder=""
                            name="starting_date"
                            impStar="*"
                            onChange={handleInputChange}
                            value={formData.starting_date}
                            disabledDate={getCurrentDate}
                          />
                        ) : (
                          <CustomMultiSelect
                            label="Course Start Every"
                            value={recurringClass}
                            defaultValue={recurringClass}
                            onChange={(val) => setRecurringClass(val)}
                            isMulti={false}
                            impStar="*"
                            optionArray={[
                              {
                                value: 1,
                                label: "Monthly",
                              },
                              {
                                value: 2,
                                label: "Weekly",
                              },
                            ]}
                          />
                        )}
                        {recurringClass?.label === "Monthly" && (
                          <CustomSelect
                            label="Starts on"
                            impStar="*"
                            name="monthlyClassDate"
                            onChange={handleInputChange}
                            value={formData.monthlyClassDate}
                            optionArray={monthArray}
                          />
                        )}
                        {recurringClass?.label === "Weekly" && (
                          <CustomSelect
                            label="Starts on"
                            name="weeklyClassDay"
                            onChange={handleInputChange}
                            optionArray={[
                              {
                                id: 1,
                                option: "Monday",
                              },
                              {
                                id: 2,
                                option: "Tuesday",
                              },
                              {
                                id: 3,
                                option: "Wednesday",
                              },
                              {
                                id: 4,
                                option: "Thrusday",
                              },
                              {
                                id: 5,
                                option: "Friday",
                              },
                              {
                                id: 6,
                                option: "Saturday",
                              },
                              {
                                id: 7,
                                option: "Sunday",
                              },
                            ]}
                            impStar=""
                            value={formData?.weeklyClassDay}
                            readOnly=""
                            placeholder=""
                            classStyle=""
                            borderColor=""
                          />
                        )}
                      </div>
                    )}

                    {courseCategory !== "Mock" &&
                      (study_mode === "Offline" || classType !== "Live") && (
                        <CustomDateInput
                          icon=""
                          label="Course Start Date"
                          type="date"
                          placeholder=""
                          name="starting_date"
                          impStar="*"
                          onChange={handleInputChange}
                          value={formData.starting_date}
                          disabledDate={getCurrentDate}
                        />
                      )}
                  </>
                )}

                {currentStep === 6 && (
                  <>
                    <div className="profile_pic">
                      <img
                        src={avatarPreview ? avatarPreview : thumbnail}
                        alt="alpha user profile pic"
                      />
                      <label htmlFor="uploadProfilePic">
                        <FaUpload /> Upload Thumbnail (aspect ratio 16:9)
                      </label>
                      <CustomInput
                        id="uploadProfilePic"
                        label="Upload Course Image"
                        type="file"
                        impStar="*"
                        name="image"
                        accept="image/*"
                        onChange={handleAvatarChange}
                      />
                    </div>
                    {courseCategory !== "Mock" && (
                      <CustomInput
                        label="Demo Video"
                        type="text"
                        placeholder="Youtube embed video link"
                        name="bannervideoLink"
                        tooltip="Ensure the video meets the following specifications: HD resolution, a preferred duration of 4 to 5 minutes, maintain an aspect ratio of 1:9, and limit the file size to a maximum of 100MB."
                        value={formData?.bannervideoLink?.name}
                        onChange={handleInputChange}
                      />
                    )}

                    {courseCategory === "Written" && (
                      <div className="collaborator_names">
                        <div className="btn_group">
                          <p>
                            Invite in this course
                            <div className="tooltip">
                              <FaInfoCircle />
                              <span className="tooltiptext">
                                If adding an officer improves course quality,
                                invite them, but they must be part of your Alpha
                                Regiment Connection. add in the i button of
                                invitation.
                              </span>
                            </div>
                          </p>
                          <div>
                            <label
                              className={
                                collaboratorOption === "yes" ? "checked" : ""
                              }
                            >
                              <input
                                type="radio"
                                value="yes"
                                checked={collaboratorOption === "yes"}
                                onChange={(e) => handleRadioChange(e)}
                              />
                              Yes
                            </label>
                            <label
                              className={
                                collaboratorOption === "no" ? "checked" : ""
                              }
                            >
                              <input
                                type="radio"
                                value="no"
                                checked={collaboratorOption === "no"}
                                onChange={(e) => handleRadioChange(e)}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {collaboratorOption === "yes" && (
                          <>
                            <div className="collab_input">
                              <CustomMultiSelect
                                label="You can invites only your contacts"
                                // impStar="*"
                                value={collaboratorName}
                                onChange={(val) => setCollaboratorName(val)}
                                isMulti={false}
                                optionArray={officerArray}
                                // tooltip=""
                                loading={officerListLoading}
                              />
                              <CustomInput
                                label="Revenue in share"
                                type="text"
                                placeholder="Ex:5%"
                                name="percentage"
                                // impStar="*"
                                icon={<MdOutlinePercent />}
                                value={percentage}
                                onChange={(e) => setPercentage(e.target.value)}
                              />
                            </div>
                            <CustomButton
                              onClick={() => handleAddCollaborator()}
                              title="Add"
                              className="add_btn"
                            />
                            {collabError !== "" && (
                              <p className="error_msg" style={{ color: "red" }}>
                                {collabError}
                              </p>
                            )}
                            <div className="total_collabs">
                              <span>
                                <p>Original Price: Rs {original_price}</p>
                                {formData?.discount !== "" && (
                                  <p>Discount Per: {formData?.discount} %</p>
                                )}
                                {formData?.discountedPrice !== "" && (
                                  <p>
                                    Dis.Price: Rs {formData?.discountedPrice}{" "}
                                  </p>
                                )}
                                {/* {
                                <p>
                                  {((formData?.discountedPrice
                                      ? formData?.discountedPrice
                                      : original_price) *
                                      percentage) /
                                    100}
                                </p>
                              } */}
                              </span>
                            </div>
                          </>
                        )}

                        {collaborator.length > 0 && (
                          <>
                            <div className="timing_table">
                              <p>Collaborator List</p>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>%</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {collaborator?.map((ele, index) => {
                                    const discountedPercent =
                                      ((formData?.discountedPrice
                                        ? formData?.discountedPrice
                                        : original_price) *
                                        ele?.percentage) /
                                      100;
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <p>{ele?.label}</p>
                                        </td>
                                        <td>
                                          <p>{ele?.percentage}</p>
                                        </td>
                                        <td>
                                          <p>
                                            <BsCurrencyRupee />{" "}
                                            {discountedPercent}
                                          </p>
                                        </td>
                                        <td>
                                          <button
                                            title="Delete Collab"
                                            onClick={() =>
                                              handleDeleteCollab(index)
                                            }
                                          >
                                            <RiDeleteBin5Fill />
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {currentStep === 7 && (
                  <>
                    <div className="review_details">
                      <h2 className="txt-center">Review your course details</h2>
                      <div className="review_wrap">
                        <ol>
                          <li>
                            <div>
                              <p>Course Title :</p>
                              <p>{formData?.title}</p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Course Category :</p>
                              <p>{formData?.courseCategory}</p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Course Study mode :</p>
                              <p>{formData.study_mode}</p>
                            </div>
                            {courseCategory === "Written" && (
                              <li>
                                <div>
                                  <p>Course Duration :</p>
                                  <p>{formData?.courseDuration} months</p>
                                </div>
                              </li>
                            )}
                          </li>
                        </ol>
                        <ol>
                          <li>
                            <div>
                              <p>Course Price :</p>
                              <p>₹ {formData?.original_price}</p>
                            </div>
                          </li>
                          {formData.discount !== "" && (
                            <>
                              <li>
                                <div>
                                  <p>Discount :</p>
                                  <p>₹ {formData?.discount}/-</p>
                                </div>
                              </li>
                            </>
                          )}
                        </ol>
                        <ol>
                          <li>
                            <div>
                              <p>Goal Type :</p>
                              <p>{goalType?.map((ele) => ele?.label + ",")}</p>
                            </div>
                          </li>
                        </ol>
                        {courseCategory === "Written" && (
                          <ol>
                            <li>
                              <div>
                                <p>Class Type :</p>
                                <p>{classType}</p>
                              </div>
                            </li>
                            {classesOption === "yes" && (
                              <li>
                                <div>
                                  <p>Course Startdate :</p>
                                  <p>{formData?.starting_date}</p>
                                </div>
                              </li>
                            )}
                            {classesOption !== "yes" &&
                              recurringClass?.label === "Monthly" && (
                                <li>
                                  <div>
                                    <p>Monthly Class Date :</p>
                                    <p>{formData?.monthlyClassDate}</p>
                                  </div>
                                </li>
                              )}
                            {classesOption !== "yes" &&
                              recurringClass?.label === "Weekly" && (
                                <li>
                                  <div>
                                    <p>Weekly Class Day :</p>
                                    <p>{formData?.weeklyClassDay}</p>
                                  </div>
                                </li>
                              )}
                          </ol>
                        )}

                        <ol>
                          {courseIncludes.length > 0 && (
                            <li>
                              <div className="includes">
                                <p>This Includes :</p>
                                <div>
                                  {courseIncludes?.map((ele, idx) => (
                                    <span key={idx}>
                                      {ele}
                                      {idx < courseIncludes.length - 1 && ", "}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </li>
                          )}
                          {courseCategory === "Written" &&
                            collaborator.length > 0 && (
                              <li>
                                <div className="time_table">
                                  <p>Collaborator List :</p>
                                  <div className="batch_timeTable_data">
                                    {collaborator?.map((item, idx) => {
                                      return (
                                        <div
                                          className="batch_timeCard"
                                          key={idx}
                                        >
                                          <div className="weekDay">
                                            <span>{item?.label}</span>
                                          </div>
                                          <div className="batch_Time">
                                            <p>Percentage</p>
                                            <p>{item?.percentage} %</p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </li>
                            )}
                        </ol>
                        <ol>
                          <li>
                            <div className="description">
                              <p>Short description : </p>
                              <p>{formData?.shortDescription}</p>
                            </div>
                          </li>

                          <li>
                            <div className="description">
                              <p>Description : </p>
                              <p>{formData?.description}</p>
                            </div>
                          </li>
                        </ol>
                        <ol>
                          <li>
                            <div className="feature">
                              <span>What aspirant learn :</span>
                              <div className="feature_list">
                                {featureList?.map((ele, idx) => (
                                  <span key={idx}>{ele?.feature}</span>
                                ))}
                              </div>
                            </div>
                          </li>
                        </ol>
                        {courseCategory === "Written" && (
                          <ol>
                            <li>
                              <div className="feature">
                                <span>Course Content :</span>
                                <div className="feature_list">
                                  {contentList?.map((ele, idx) => (
                                    <span key={idx}>{ele?.content}</span>
                                  ))}
                                </div>
                              </div>
                            </li>
                          </ol>
                        )}
                        <ol>
                          <li>
                            <div className="course_banner">
                              <p>Course Thumbnail</p>
                              <img src={avatarPreview} alt="course_image" />
                            </div>
                          </li>
                          {formData?.bannervideoLink !== "" && (
                            <li>
                              <div className="video_url">
                                <p>Video URL</p>
                                <a
                                  href={formData?.bannervideoLink}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {formData?.bannervideoLink}
                                </a>
                              </div>
                            </li>
                          )}
                        </ol>
                      </div>
                    </div>
                    <div className="createBtn">
                      <CustomButton
                        title="Edit Course"
                        onClick={handleFirstStep}
                        // disabled={true}
                      />
                      <CustomButton
                        title={loading ? <ButtonLoader /> : `Create Course`}
                        onClick={handleSubmit}
                        // disabled={true}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="navigation_buttons">
            {currentStep > 1 && (
              <button onClick={handlePrev} className="prev">
                Previous
              </button>
            )}
            {currentStep < 7 && (
              <button onClick={handleNext} className="next">
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OCourseCreate;
