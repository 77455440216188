import { axiosInstance } from "../../utils/axiosConfig";
import {
  COURSE_PAUSE_FAIL,
  COURSE_PAUSE_REQUEST,
  COURSE_PAUSE_SUCCESS,
} from "../constants/courseConstant";
import {
  DELETE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  GET_MY_CREATAED_COURSE_FAIL,
  GET_MY_CREATAED_COURSE_REQUEST,
  GET_MY_CREATAED_COURSE_SUCCESS,
  TEACHER_CLEAR_DELETE_STATE,
  TEACHER_CLEAR_ERRORS,
  TEACHER_CLEAR_STATE,
  TEACHER_COURSE_CREATE_FAIL,
  TEACHER_COURSE_CREATE_REQUEST,
  TEACHER_COURSE_CREATE_SUCCESS,
} from "../constants/teacherConstant";

export const teacherCourseCreateAction =
  (
    title,
    titleHindi,
    shortDescription,
    description,
    shortDescriptionHindi,
    descriptionHindi,
    original_price,
    discountedPrice,
    discount,
    discountValidity,
    courseDuration,
    study_mode,
    affiliate_percentage,
    starting_date,
    goalType,
    courseCategory,
    bannervideoLink,
    thumbnail,
    featureList,
    timeTable,
    collaborator,
    googleLocation,
    courseInclude,
    weeklyClassDay,
    monthlyClassDate,
    classType,
    isEmi,
    allEmis
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TEACHER_COURSE_CREATE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const goalData = goalType?.map((item) => item.label);
      const { data } = await axiosInstance.post(
        "/create-course",
        {
          title,
          titleHindi,
          shortDescription,
          description,
          shortDescriptionHindi,
          descriptionHindi,
          original_price,
          discountedPrice,
          discount,
          discountValidity,
          courseDuration,
          study_mode,
          affiliate_percentage,
          starting_date,
          goalData,
          courseCategory,
          bannervideoLink,
          thumbnail,
          featureList,
          timeTable,
          collaborator,
          googleLocation,
          courseInclude,
          weeklyClassDay,
          monthlyClassDate,
          classType,
          isEmi,
          allEmis,
        },
        config
      );
      dispatch({
        type: TEACHER_COURSE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TEACHER_COURSE_CREATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const getMyCreatedCourseAction = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MY_CREATAED_COURSE_REQUEST,
    });
    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-course/${userId}`, config);
    dispatch({
      type: GET_MY_CREATAED_COURSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_MY_CREATAED_COURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE COURSE BY ID
export const deleteCourse = (courseId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COURSE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/course/${courseId}`, config);

    dispatch({
      type: DELETE_COURSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// PAUSE COURSE
export const pauseCourse = (id, pauseForDays, isPause) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_PAUSE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/pause-course/${id}`,
      {
        pauseForDays,
        isPause,
      },
      config
    );

    dispatch({
      type: COURSE_PAUSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_PAUSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const teacherClearErrors = () => async (dispatch) => {
  dispatch({ type: TEACHER_CLEAR_ERRORS });
};

// CLEAR DELETE STATE ACTION FUNCTION
export const teacherClearDeleteState = () => async (dispatch) => {
  dispatch({ type: TEACHER_CLEAR_DELETE_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const teacherClearState = () => async (dispatch) => {
  dispatch({ type: TEACHER_CLEAR_STATE });
};
