import {
  CLEAR_VIDEOCALL_ERROR,
  CLEAR_VIDEOCALL_STATE,
  PRO_VIDEOCALL_LINK__FAIL,
  PRO_VIDEOCALL_LINK__REQUEST,
  PRO_VIDEOCALL_LINK__SUCCESS,
  VIDEOCALL_LINK_FAIL,
  VIDEOCALL_LINK_REQUEST,
  VIDEOCALL_LINK_SUCCESS,
  VIDEOCALL_LINK_VALIDATE_FAIL,
  VIDEOCALL_LINK_VALIDATE_REQUEST,
  VIDEOCALL_LINK_VALIDATE_SUCCESS,
} from "../constants/videoCallConstant";

export const createVideoCallLink = (state = {}, action) => {
  switch (action.type) {
    case VIDEOCALL_LINK_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case VIDEOCALL_LINK_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case VIDEOCALL_LINK_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_VIDEOCALL_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_VIDEOCALL_STATE:
      return {
        loading: false,
        data: {},
      };

    default:
      return state;
  }
};

export const validateVideoCallLink = (state = {}, action) => {
  switch (action.type) {
    case VIDEOCALL_LINK_VALIDATE_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case VIDEOCALL_LINK_VALIDATE_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case VIDEOCALL_LINK_VALIDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_VIDEOCALL_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


export const proVideoCallLink = (state = {}, action) => {
  switch (action.type) {
    case PRO_VIDEOCALL_LINK__REQUEST:
      return {
        loading: true,
        data: {},
      };
    case PRO_VIDEOCALL_LINK__SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case PRO_VIDEOCALL_LINK__FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_VIDEOCALL_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_VIDEOCALL_STATE:
      return {
        loading: false,
        data: {},
      };

    default:
      return state;
  }
};