import React, { Fragment } from "react";
import "../../styles/affiliateCourseLinks.scss";
import Loader from "../../components/reusable/Loader";
import AffiliateLayout from "./AffiliateLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { affiliateSidebarLinks } from "./affiliateSidebarLinks";
import { VscCopy } from "react-icons/vsc";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clearErrors, getAllCourses } from "../../redux/actions/courseAction";

const AffiliateCourseLinks = () => {
  const dispatch = useDispatch();

  const { loading, courses, error } = useSelector(
    (state) => state.getAllCourses
  );

  const allApiCourses = courses?.courses;

  // CALL API WHEN PAGE LOAD
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  // LINK COPY TO CLIPBOARD
  const handleLinkCopy = (link) => {
    const copiedLink = navigator.clipboard.writeText(link);
    if (copiedLink) {
      toast.success("Course Link Copied!");
    } else {
      toast.error("Something Went Wrong!");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AffiliateLayout>
          <ProfileLayout sidebarLinks={affiliateSidebarLinks}>
            <section className="course_links_main txt_color">
              <div className="title">
                <h2>Course Links</h2>
              </div>
              <div className="course_links_card">
                {allApiCourses?.map((item, id) => (
                  <div className="link_card_content" key={id}>
                    <p>{item?.title}</p>
                    <button
                      value={item?._id}
                      onClick={() =>
                        handleLinkCopy(
                          `${process.env.REACT_APP_URL}/course-details/${item?._id}`
                        )
                      }
                    >
                      Copy Link <VscCopy />
                    </button>
                  </div>
                ))}
              </div>
            </section>
          </ProfileLayout>
        </AffiliateLayout>
      )}
    </Fragment>
  );
};

export default AffiliateCourseLinks;
