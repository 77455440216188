import React, { Fragment, useEffect } from "react";
import "../../styles/affiliateDashboard.scss";
import AffiliateLayout from "./AffiliateLayout";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { affiliateSidebarLinks } from "./affiliateSidebarLinks";
import Loader from "../../components/reusable/Loader";
import { useAuth } from "../../context/userContext";
import { ImStatsBars } from "react-icons/im";
import { FiUserCheck } from "react-icons/fi";
import { AiOutlineFolderOpen, AiOutlineMail } from "react-icons/ai";
import { BsBoxArrowInUpLeft, BsDownload } from "react-icons/bs";
import { defaultUser } from "../../assets/images";
import { VscCopy } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearErrors,
  getAffiliateDashboard,
  getAllAffiliateNotifications,
  getAllDownloadNotifications,
  getEnrolledUsersByAffiliate,
  setupAffiliateDashboard,
} from "../../redux/actions/userAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import CustomButton from "../../components/reusable/CustomButton";

const AffiliateDashboard = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();

  // SETUP AFFILIATE DASHBOARD START
  // SETUP AFFILIATE DASHBOARD START
  const {
    loading: affiliateDashboardLoading,
    affiliateDashboard: setupAffiliateDashboardData,
    error: affiliateDashboardError,
  } = useSelector((state) => state.affiliateDashboard);

  const dashboardDataSetupHandler = () => {
    dispatch(setupAffiliateDashboard());
  };

  useEffect(() => {
    if (affiliateDashboardError) {
      toast.error(affiliateDashboardError);
      dispatch(clearErrors());
    }
    if (setupAffiliateDashboardData?.success) {
      dispatch(getAffiliateDashboard());
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    affiliateDashboardError,
    setupAffiliateDashboardData?.success,
    setupAffiliateDashboardData?.message,
  ]);
  // SETUP AFFILIATE DASHBOARD ENDS
  // SETUP AFFILIATE DASHBOARD ENDS

  // GET AFFILIATE DASHBOARD DATA START
  // GET AFFILIATE DASHBOARD DATA START
  const { loading, affiliateDashboard, error } = useSelector(
    (state) => state.affiliateDashboardData
  );

  const affiliateDashboardParam = affiliateDashboard?.dashboardData;

  useEffect(() => {
    dispatch(getAffiliateDashboard());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [
    error,
    dispatch,
    affiliateDashboard?.success,
    affiliateDashboard?.message,
  ]);
  // GET AFFILIATE DASHBOARD DATA ENDS
  // GET AFFILIATE DASHBOARD DATA ENDS

  // GET ALL NOTIFICATIONS START
  // GET ALL NOTIFICATIONS START
  const {
    loading: allNotificationsLoading,
    allNotifications,
    error: allNotificationsError,
  } = useSelector((state) => state.getNotifications);

  const allNotificationsParam = allNotifications?.notifications;

  const latestFiveNotifications = allNotificationsParam?.slice(-5)?.reverse();

  useEffect(() => {
    dispatch(getAllAffiliateNotifications());
  }, [dispatch]);

  useEffect(() => {
    if (allNotificationsError) {
      toast.error(allNotificationsError);
      dispatch(clearErrors());
    }
  }, [dispatch, allNotificationsError]);

  // GET ALL NOTIFICATIONS ENDS
  // GET ALL NOTIFICATIONS ENDS

  // GET ALL DOWNLOAD NOTIFICATIONS START
  // GET ALL DOWNLOAD NOTIFICATIONS START
  const {
    loading: downloadNotificationsLoading,
    downloadNotifications,
    error: downloadNotificationsError,
  } = useSelector((state) => state.getDownloadNotifications);

  const downloadNotificationsParam =
    downloadNotifications?.downloadNotifications;

  const latestFiveDownloadNotifications = downloadNotificationsParam
    ?.slice(-5)
    ?.reverse();

  // console.log(latestFiveDownloadNotifications);

  useEffect(() => {
    dispatch(getAllDownloadNotifications());
  }, [dispatch]);

  useEffect(() => {
    if (downloadNotificationsError) {
      toast.error(downloadNotificationsError);
      dispatch(clearErrors());
    }
  }, [dispatch, downloadNotificationsError]);
  // GET ALL DOWNLOAD NOTIFICATIONS ENDS
  // GET ALL DOWNLOAD NOTIFICATIONS ENDS

  // LINK COPY TO CLIPBOARD
  const handleLinkCopy = (link) => {
    const copiedLink = navigator.clipboard.writeText(link);
    if (copiedLink) {
      toast.success("Copied!");
    } else {
      toast.error("Something Went Wrong!");
    }
  };

  // GET ALL ENROLLED USERS BY AFFILIATE START
  // GET ALL ENROLLED USERS BY AFFILIATE START
  const {
    loading: enrolledUsersLoading,
    enrolledUsers,
    error: enrolledUsersError,
  } = useSelector((state) => state.getEnrolledUsers);

  const enrolledUsersParam = enrolledUsers?.enrolledUsers;

  useEffect(() => {
    dispatch(getEnrolledUsersByAffiliate());
  }, [dispatch]);

  useEffect(() => {
    if (enrolledUsersError) {
      toast.error(enrolledUsersError);
      dispatch(clearErrors());
    }
  }, [dispatch, enrolledUsersError]);
  // GET ALL ENROLLED USERS BY AFFILIATE ENDS
  // GET ALL ENROLLED USERS BY AFFILIATE ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AffiliateLayout>
          <ProfileLayout sidebarLinks={affiliateSidebarLinks}>
            {affiliateDashboardParam?.couponCode ? (
              <section className="affiliate_dahboard_main txt_color">
                <div className="title">
                  <h2>Hey {auth?.user?.first_name}!</h2>
                </div>
                <div className="dashboard_revenue_sec">
                  <div className="rev_card_content">
                    <p className="cardHeading">Total Revenue</p>
                    <h2 className="total_revenue">
                      {affiliateDashboardParam?.totalRevenue} INR
                    </h2>
                    <div className="stats_icons_link">
                      Check Stats <ImStatsBars />
                    </div>
                  </div>
                  <div className="rev_card_content">
                    <p className="cardHeading">Balance</p>
                    <h2 className="total_revenue">
                      {affiliateDashboardParam?.totalRevenue -
                        affiliateDashboardParam?.deductedRevenue}{" "}
                      INR
                    </h2>
                    <div className="stats_icons_link">
                      Withdraw <AiOutlineFolderOpen />
                    </div>
                  </div>
                  <div className="rev_card_content">
                    <p className="cardHeading">Total Clicks</p>
                    <h2 className="total_revenue">
                      {affiliateDashboardParam?.totalClicks}{" "}
                      <span>Last Month</span>
                    </h2>
                    <div className="stats_icons_link">
                      Check analytics <BsBoxArrowInUpLeft />
                    </div>
                  </div>
                  <div className="rev_card_content">
                    <p className="cardHeading">Enrollments</p>
                    <h2 className="total_revenue">
                      {enrolledUsersParam?.length}{" "}
                      <span>Last Month</span>
                    </h2>
                    <div className="stats_icons_link">
                      Check enrollments <FiUserCheck />
                    </div>
                  </div>
                </div>

                <div className="dashboard_second_sec">
                  <div className="universal_link_card">
                    <h2>Universal Link</h2>
                    <p className="link">
                      {affiliateDashboardParam?.univesalLink}{" "}
                      <VscCopy
                        onClick={() =>
                          handleLinkCopy(affiliateDashboardParam?.univesalLink)
                        }
                      />
                    </p>
                    <p className="txt">
                      Universal link navigates user to the alpha regiment
                      website and the candidate enrollment after that leads to
                      the profit{" "}
                    </p>
                  </div>
                  <div className="coupon_code_card">
                    <h2>Coupon Code</h2>
                    <p className="coupon_code">
                      {affiliateDashboardParam?.couponCode}
                      <VscCopy
                        onClick={() =>
                          handleLinkCopy(affiliateDashboardParam?.couponCode)
                        }
                      />
                    </p>
                    <p className="txt">
                      This coupon code give direct discount to the candidate
                    </p>
                  </div>
                  {/* <div className="aff_under_you">
                    <h2>Affiliates Under you</h2>
                    <div className="affiliate_lists">
                      {[1, 1].map((item, idx) => (
                        <div className="single_affiliate" key={idx}>
                          <div>
                            <img src={defaultUser} alt="defaultUser" />
                            <p className="aff_name">Pankaj Yadav</p>
                          </div>
                          <span className="main_icon">
                            <AiOutlineMail />
                          </span>
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>

                <div className="notification_download_sec">
                  <div className="notification_sec">
                    <h2>Notifications</h2>
                    {allNotificationsLoading ? (
                      "Loading"
                    ) : (
                      <ul>
                        {latestFiveNotifications?.map((item) => (
                          <li key={item?._id}>{item?.notificationText}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="download_sec">
                    <h2>Download</h2>
                    <ul className="download_list">
                      {downloadNotificationsLoading ? (
                        <p className="txt_center">Loading...</p>
                      ) : latestFiveDownloadNotifications?.length <= 0 ? (
                        <p className="txt_center">
                          No Downloadable links found
                        </p>
                      ) : (
                        latestFiveDownloadNotifications?.map((item) => (
                          <li key={item?._id}>
                            <p>
                              <span>{item?.downloadText}</span>
                              <Link to={item?.downloadLink}>
                                <BsDownload />
                              </Link>
                            </p>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </div>
              </section>
            ) : (
              <div className="txt_color bg_color setup_dashboard_section">
                <h2>Your dashboard is not ready!</h2>
                <CustomButton
                  onClick={dashboardDataSetupHandler}
                  className=""
                  title={
                    affiliateDashboardLoading ? (
                      <ButtonLoader />
                    ) : (
                      "setup your dashboard"
                    )
                  }
                  disabled={affiliateDashboardLoading ? true : false}
                />
              </div>
            )}
          </ProfileLayout>
        </AffiliateLayout>
      )}
    </Fragment>
  );
};

export default AffiliateDashboard;
