import React, { Fragment, useEffect, useState } from "react";
import "../../styles/OfficerStudentList.scss";
import OfficerLayout from "./OfficerLayout";
import Loader from "../../components/reusable/Loader";
import ProfileLayout from "../../components/reusable/ProfileLayout";
import { officerSidebarLink } from "./officerSidebarLink";
// import { MdFilterList } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import EnrolledStudentDetailsPopup from "./EnrolledStudentDetailsPopup";
import CustomInput from "../../components/reusable/CustomInput";
import CustomButton from "../../components/reusable/CustomButton";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { useNotification } from "../../context/notificationContext";
import {
  clearErrors,
  enrollStudentListAction,
} from "../../redux/actions/userAction";
import { useAuth } from "../../context/userContext";
import OfficerNoDataCard from "./OfficerNoDataCard";
import { noCourse } from "../../assets/images";

const OfficerStudentList = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();
  // const [, , showNotification] = useNotification();
  const [showPopup, setShowPopup] = useState(false);
  const [activeTab, setActiveTab] = useState("Online");
  const [schedulePopUp, setSchedulePopUp] = useState(false);
  const [studentDetail, setStudentDetail] = useState("");
  const [filterData, setFilterData] = useState(null);
  const [data, setData] = useState({
    title: "",
    date: "",
    startTime: "",
    endTime: "",
    student: "",
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleStudentPopUp = (itm) => {
    setShowPopup(true);
    setStudentDetail(itm);
  };
  const { error, loading, userList } = useSelector(
    (state) => state.enrollStudentList
  );
  const userData = userList?.enrolledUser;

  useEffect(() => {
    if (userData) {
      const filteredData = userData?.filter((user) =>
        user.courses.some((course) => course.courseMode === "Online")
      );
      setFilterData(filteredData);
    }
  }, [userData]);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(enrollStudentListAction(auth?.user?._id));
    }
  }, [auth?.user?._id, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  // HANDLE FILTER USER TABS
  const handleSearch = (query) => {
    if (query !== "Mock Interview") {
      const filteredData = userData.filter((user) =>
        user.courses.some((course) => course.courseMode === query)
      );
      setActiveTab(query);
      setFilterData(filteredData);
    } else {
      const filteredData = userData.filter((user) =>
        user.courses.some((course) => course.category === query)
      );
      setActiveTab(query);
      setFilterData(filteredData);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <OfficerLayout>
          <ProfileLayout sidebarLinks={officerSidebarLink}>
            <div className="txt_color officer_student_list">
              <div className="title">
                <h2>Reviews Students</h2>
              </div>

              <div className="filter_tabs">
                <ul>
                  <li onClick={() => handleSearch("Online")}>
                    <p className={activeTab === "Online" ? "active" : ""}>
                      Online
                    </p>
                  </li>
                  <li onClick={() => handleSearch("Offline")}>
                    <p className={activeTab === "Offline" ? "active" : ""}>
                      Offline
                    </p>
                  </li>
                  <li onClick={() => handleSearch("Mock Interview")}>
                    <p
                      className={activeTab === "Mock Interview" ? "active" : ""}
                    >
                      Mock
                    </p>
                  </li>
                </ul>
              </div>

              {filterData?.length > 0 ? (
                <div className="batch_timing_main officer_studentList_main txt_color">
                  <div className="timing_calendar">
                    <div className="timing_table custonScroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>S NO.</th>
                            <th>Student Name</th>

                            {activeTab === "Mock Interview" ? (
                              <th>Category</th>
                            ) : (
                              <th>Course Name</th>
                            )}
                            {activeTab === "Mock Interview" ? (
                              <th>Date Scheduled</th>
                            ) : (
                              <th>Date Enrolled</th>
                            )}

                            {activeTab === "Mock Interview" && <th>Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {filterData?.map((item, idx) => (
                            <tr key={idx}>
                              <td>
                                <p>{idx + 1}</p>
                              </td>
                              <td>
                                <p>{item?.first_name}</p>
                              </td>
                              {activeTab === "Mock Interview" ? (
                                <td>
                                  {item?.courses?.map((ele) => (
                                    <p>{ele?.category}</p>
                                  ))}
                                </td>
                              ) : (
                                <td>
                                  {item?.courses?.map((ele) => (
                                    <p>{ele?.title}</p>
                                  ))}
                                </td>
                              )}
                              <td>
                                <p>20-09-2023</p>
                              </td>
                              {activeTab === "Mock Interview" && (
                                <td>
                                  <button
                                    onClick={() => handleStudentPopUp(item)}
                                  >
                                    <AiFillEye />
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <OfficerNoDataCard
                  txt="No any student subscribed yet"
                  image={noCourse}
                />
              )}

              {/* VIEW MODAL START */}
              <EnrolledStudentDetailsPopup
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                setSchedulePopUp={() => setSchedulePopUp(true)}
              />
              {/* VIEW MODAL ENDS */}

              {/* HANDLE SCHEDULE MODAL STARTS */}
              {schedulePopUp && (
                <div className="officer_schedule_modal">
                  <button
                    title="Close Popup"
                    className="closeBtn"
                    onClick={() => setSchedulePopUp(false)}
                  >
                    <IoClose />
                  </button>
                  <div className="calender_modal_mes">
                    <p className="title">
                      Schedule interview for {studentDetail?.first_name}
                    </p>

                    <CustomInput
                      label="Date"
                      placeholder="Date"
                      value={data?.date}
                      onChange={handleInputChange}
                      type="date"
                    />
                    <CustomInput
                      label="Start Time"
                      placeholder="startTime"
                      value={data?.startTime}
                      onChange={handleInputChange}
                      type="time"
                    />

                    <div className="create_btn">
                      <CustomButton
                        title="Re-Schedule"
                        width="140px"
                        onClick={() => setSchedulePopUp(false)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ProfileLayout>
        </OfficerLayout>
      )}
    </Fragment>
  );
};

export default OfficerStudentList;
