import React from "react";
import styled from "styled-components";

const TestimonialCard = styled.div`
  padding: 20px;

  @media screen and (max-width: 460px) {
    padding: 15px;
  }

  > h2.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #f2f2f2;
    margin-bottom: 7px;
  }

  > p.business {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #a9b5bb;
  }

  > img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: block;
    margin: 10px auto;
  }

  p.description {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    text-align: center;
  }
`;

const TestimonialMobile = ({ title, business, img, description }) => {
  return (
    <TestimonialCard className="testimonial_Card_mobile">
      <h2 className="title">{title}</h2>
      <p className="business">{business}</p>
      <img src={img} alt="owner" />
      <p className="description">{description} </p>
    </TestimonialCard>
  );
};

export default TestimonialMobile;
