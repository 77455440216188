import { axiosInstance } from "../../utils/axiosConfig";
import {
  AUTHOR_REPLY_COMMENT_FAIL,
  AUTHOR_REPLY_COMMENT_REQUEST,
  AUTHOR_REPLY_COMMENT_SUCCESS,
  AUTHOR_REPLY_DELETE_FAIL,
  AUTHOR_REPLY_DELETE_REQUEST,
  AUTHOR_REPLY_DELETE_STATE,
  AUTHOR_REPLY_DELETE_SUCCESS,
  CLEAR_BLOG_ERROR,
  CLEAR_BLOG_STATE,
  CLEAR_COMMENT_ERROR,
  CLEAR_COMMENT_STATE,
  CLEAR_DELETE_BLOG_STATE,
  CREATE_BLOG_FAIL,
  CREATE_BLOG_REQUEST,
  CREATE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  DELETE_COMMENT_FAIL,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  GET_BLOGS_FAIL,
  GET_BLOGS_REQUEST,
  GET_BLOGS_SUCCESS,
  GET_BLOG_BY_ID_FAIL,
  GET_BLOG_BY_ID_REQUEST,
  GET_BLOG_BY_ID_SUCCESS,
  POST_COMMENT_FAIL,
  POST_COMMENT_REQUEST,
  POST_COMMENT_SUCCESS,
} from "../constants/blogConstant";

// CREATE NEW BATCH
export const createNewBlog =
  (title, shortDescription, metaDescription, metaTitle, image, blogContent) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_BLOG_REQUEST,
      });

      const localStorageToken = localStorage.getItem("alphaToken")
        ? JSON.parse(localStorage.getItem("alphaToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-blog`,
        {
          title,
          shortDescription,
          metaDescription,
          metaTitle,
          image,
          blogContent,
        },
        config
      );

      dispatch({
        type: CREATE_BLOG_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_BLOG_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL BATCHES
export const getAllBlogs = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BLOGS_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/blogs`, config);

    dispatch({
      type: GET_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BLOGS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET BLOG BY ID
export const getBlogDetailsById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BLOG_BY_ID_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/blog/${id}`, config);

    dispatch({
      type: GET_BLOG_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BLOG_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE BLOG
export const deleteBlogByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_BLOG_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/blog/${id}`, config);

    dispatch({
      type: DELETE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BLOG_FAIL,
      payload: error.response.data.message,
    });
  }
};

// POST COMMENT RESUQEST
export const postBlogComment = (id, comment) => async (dispatch) => {
  try {
    dispatch({
      type: POST_COMMENT_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/blog-comment/${id}`,
      {
        comment,
      },
      config
    );

    dispatch({
      type: POST_COMMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_COMMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// REPLY COMMENT
export const replyBlogComment = (commentId, comment) => async (dispatch) => {
  try {
    dispatch({
      type: AUTHOR_REPLY_COMMENT_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/reply-blog-comment/${commentId}`,
      {
        comment,
      },
      config
    );

    dispatch({
      type: AUTHOR_REPLY_COMMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AUTHOR_REPLY_COMMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE BLOG COMMENT
export const deleteBlogComment = (commentId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMMENT_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/delete-blog-comment/${commentId}`,
      config
    );

    dispatch({
      type: DELETE_COMMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COMMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE REPLY COMMENT
export const deleteReplyComment = (commentId, replyId) => async (dispatch) => {
  try {
    dispatch({
      type: AUTHOR_REPLY_DELETE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("alphaToken")
      ? JSON.parse(localStorage.getItem("alphaToken"))
      : "";
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/delete-blog-reply/comment/${commentId}/replies/${replyId}`,
      config
    );

    dispatch({
      type: AUTHOR_REPLY_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AUTHOR_REPLY_DELETE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// clear comment state
export const clearCommentState = () => async (dispatch) => {
  dispatch({ type: CLEAR_COMMENT_STATE });
};
export const clearCommenteError = () => async (dispatch) => {
  dispatch({ type: CLEAR_COMMENT_ERROR });
};

// CLEAR DELETE COMMENT AND REPLY STATE

export const clearDeleteCommentState = () => async (dispatch) => {
  dispatch({ type: AUTHOR_REPLY_DELETE_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearBlogErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_BLOG_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearBlogState = () => async (dispatch) => {
  dispatch({ type: CLEAR_BLOG_STATE });
};

export const clearDeleteBlogState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_BLOG_STATE });
};
