import React from "react";
import styled from "styled-components";
import { useAuth } from "../../context/userContext";
import moment from "moment";
import { IoCheckmarkDone, IoCheckmark } from "react-icons/io5";

const OwnMessage = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 5px;
  background-color: #5b637d;
  color: #fff;
  max-width: 350px;
  width: fit-content;
  margin-bottom: 10px;
  margin-left: auto;
  box-shadow: rgb(0, 0, 0) -2px 2px 5px 0px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;

  > .messageTxt {
    font-size: 15px;
    margin-right: 10px;
  }

  > .messageTime {
    font-size: 11px;
    margin-top: auto;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    column-gap: 3px;

    > svg {
      font-size: 14px;

      &.read {
        color: #53bdeb;
      }
    }
  }
`;

const PersonMessage = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #081336;
  max-width: 350px;
  width: fit-content;
  margin-bottom: 10px;
  margin-right: auto;
  box-shadow: rgb(0, 0, 0) 2px 2px 5px -2px;
  border-right: 1px solid rgb(8, 19, 54);
  border-bottom: 1px solid rgb(8, 19, 54);

  > .messageTxt {
    font-size: 15px;
    margin-right: 10px;
  }

  > .messageTime {
    font-size: 11px;
    margin-top: auto;
    white-space: nowrap;
  }
`;

const MessageCard = (message) => {
  const [auth] = useAuth();
  const user = auth?.user;
  return (
    <div className="msg_card">
      {user?._id === message?.message?.senderId ? (
        <OwnMessage>
          <p className="messageTxt">{message?.message?.text}</p>
          <p className="messageTime">
            {moment(message?.message?.createdAt).format("hh:mm a")}{" "}
            {message?.message?.status === "sent" ? (
              <IoCheckmark className="sent" />
            ) : message?.message?.status === "delivered" ? (
              <IoCheckmarkDone className="delivered" />
            ) : (
              <IoCheckmarkDone className="read" />
            )}
            {/* <IoCheckmarkDone className="read" /> */}
          </p>
        </OwnMessage>
      ) : (
        <PersonMessage>
          <p className="messageTxt">{message?.message?.text}</p>
          <p className="messageTime">
            {moment(message?.message?.createdAt).format("hh:mm a")}
          </p>
        </PersonMessage>
      )}
    </div>
  );
};

export default MessageCard;
