import React, { Fragment, useEffect, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsPersonFillAdd, BsSearch } from "react-icons/bs";
import { useNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/userContext";
import { clearErrors, officerListAction } from "../../redux/actions/userAction";
import {
  clearFollowErrors,
  getFollowerList,
} from "../../redux/actions/followAction";
import {
  acceptConnectionRequest,
  clearConnectionErrors,
  clearConnectionState,
  clearRemoveConnectionState,
  rejectConnectionRequest,
  removeConnectionRequest,
  sendConnectRequest,
} from "../../redux/actions/connectAction";
import Loader from "../../components/reusable/Loader";
import CustomButton from "../../components/reusable/CustomButton";
import CustomInput from "../../components/reusable/CustomInput";
import { defaultUser, followerImg } from "../../assets/images";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";

const OConnectionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const [activeTab, setActiveTab] = useState("search");
  const [searchOfficer, setSearchOfficer] = useState("");
  const [filterOfficer, setFilterOfficer] = useState();

  const [auth] = useAuth();
  const user = auth?.user;

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchOfficer(e.target.value);
    if (searchTerm.toLowerCase() === "teacher") {
      const filterOfficer = officerList.filter((item) => item.role === "13");
      setFilterOfficer(filterOfficer);
    } else if (searchTerm && officerList) {
      const filterOfficer = officerList.filter(
        (item) =>
          (item.first_name &&
            item.first_name
              .toLowerCase()
              .includes(searchOfficer.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(searchOfficer.toLowerCase())) ||
          (item.last_name &&
            item.last_name.toLowerCase().includes(searchOfficer.toLowerCase()))
      );
      setFilterOfficer(filterOfficer);
    }
  };

  // GET ALL OFFICER LIST
  const {
    error: officerListError,
    loading: officerListLoading,
    userList,
  } = useSelector((state) => state.officerList);

  useEffect(() => {
    dispatch(officerListAction());
  }, [dispatch]);

  const officerList = userList?.officers?.filter(
    (itm) => itm?._id !== user?._id && (itm.role === "12" || itm.role === "13")
  );

  useEffect(() => {
    setFilterOfficer(officerList);
  }, [officerList]);

  useEffect(() => {
    if (officerListError) {
      dispatch(clearErrors());
    }
  }, [dispatch, officerListError]);

  const handleTabChange = (query) => {
    setActiveTab(query);
  };

  // GET ALL FOLLOWERS
  const {
    loading: followerLoading,
    error: followerError,
    followList,
  } = useSelector((state) => state.followerList);

  const connectionList = followList?.follows?.connections;

  const requestList = followList?.follows?.connections?.filter(
    (itm) => itm.status === "Pending"
  );

  const checkSentRequest = (id) => {
    return followList?.follows?.sentConnectRequest?.includes(id);
  };

  const checkFriend = (id) => {
    return followList?.follows?.connections.some(
      (itm) => itm?.connect?._id === id
    );
  };

  useEffect(() => {
    dispatch(getFollowerList());
  }, [dispatch]);

  useEffect(() => {
    if (followerError) {
      dispatch(clearFollowErrors());
    }
  }, [followerError, showNotification, dispatch]);

  const handleViewProfile = (data) => {
    navigate(`/friend-profile/${data?._id}`);
  };

  const [selectedId, setSelectedId] = useState();

  //   HANDLE REMOVE CONNECTION
  const handleRemoveConnection = (id) => {
    setSelectedId(id);
    dispatch(removeConnectionRequest(id));
  };

  const {
    error: removeError,
    loading: removeLoading,
    connectionInfo,
  } = useSelector((state) => state.removeConnection);

  useEffect(() => {
    if (removeError) {
      showNotification("error", removeError);
      dispatch(clearConnectionErrors());
    }
    if (connectionInfo?.success) {
      showNotification("success", connectionInfo?.message);
      dispatch(clearRemoveConnectionState());
      dispatch(getFollowerList());
    }
  });

  // SEND CONNECTION REQUEST
  const handleRequestSend = (id) => {
    setSelectedId(id);
    dispatch(sendConnectRequest(id));
  };

  const {
    error: sendError,
    loading: sendLoading,
    connectionInfo: sendInfo,
  } = useSelector((state) => state.sendConnect);

  useEffect(() => {
    if (sendError) {
      showNotification("error", sendError);
      dispatch(clearConnectionErrors());
    }
    if (sendInfo?.success) {
      showNotification("success", sendInfo?.message);
      dispatch(clearConnectionState());
      dispatch(getFollowerList());
    }
  }, [
    sendInfo?.message,
    sendInfo?.success,
    sendError,
    dispatch,
    showNotification,
  ]);

  //   HANDLE ACCEPT REQUEST AND REJECT
  const handleAcceptConnection = (id) => {
    setSelectedId(id);
    dispatch(acceptConnectionRequest(id));
  };
  const {
    error: acceptError,
    loading: acceptLoading,
    connectionInfo: acceptInfo,
  } = useSelector((state) => state.acceptConnection);

  useEffect(() => {
    if (acceptError) {
      showNotification("error", acceptError);
      dispatch(clearConnectionErrors());
    }
    if (acceptInfo?.success) {
      showNotification("success", acceptInfo?.message);
      dispatch(clearRemoveConnectionState());
      dispatch(getFollowerList());
    }
  }, [
    acceptError,
    dispatch,
    acceptInfo?.message,
    acceptInfo?.success,
    showNotification,
  ]);

  const handleRejectConnection = (id) => {
    dispatch(rejectConnectionRequest(id));
  };
  const {
    error: rejectError,
    loading: rejectLoading,
    connectionInfo: rejectInfo,
  } = useSelector((state) => state.rejectConnection);

  useEffect(() => {
    if (rejectError) {
      showNotification("error", rejectError);
      dispatch(clearConnectionErrors());
    }
    if (rejectInfo?.success) {
      showNotification("success", rejectInfo?.message);
      dispatch(clearRemoveConnectionState());
      dispatch(getFollowerList());
    }
  }, [
    rejectError,
    dispatch,
    rejectInfo?.message,
    rejectInfo?.success,
    showNotification,
  ]);

  return (
    <Fragment>
      {followerLoading || officerListLoading ? (
        <Loader />
      ) : (
        <div className="officer_connection_main txt_color">
          <div className="title">
            <button title="Go Back" onClick={() => navigate(-1)}>
              <BiLeftArrowAlt />
            </button>
            Connection
          </div>
          <div className="container">
            <div className="userDetails">
              <div className="buttonGrp">
                <CustomButton
                  className={`tab ${activeTab === "search" ? "active" : ""}`}
                  title="Find New Connection"
                  onClick={() => handleTabChange("search")}
                />
                <CustomButton
                  className={`tab ${activeTab === "list" ? "active" : ""}`}
                  title={`Connection (${
                    connectionList
                      ? connectionList?.filter(
                          (itm) => itm?.status === "Accepted"
                        )?.length
                      : 0
                  })`}
                  onClick={() => handleTabChange("list")}
                />
                <CustomButton
                  className={`tab ${activeTab === "request" ? "active" : ""}`}
                  title={`Connection Request (${requestList?.length})`}
                  onClick={() => handleTabChange("request")}
                />
              </div>
            </div>
            {activeTab === "search" && (
              <>
                <div className="cnctWth">
                  <div className="cnctWth_content">
                    <p>Connect With:</p>
                    <div>
                      <CustomInput
                        icon={<BsSearch />}
                        type="text"
                        onChange={(e) => handleSearch(e)}
                        placeholder="Search"
                        name="searchString"
                        value={searchOfficer}
                      />
                    </div>
                  </div>
                </div>
                {/* LIST OF SEARCH OFFICER RESULT START */}
                {filterOfficer?.length > 0 && (
                  <div className="officer_timeline_content">
                    <div className="officersList">
                      {filterOfficer.map((item, idx) => (
                        <div className="officerRow" key={idx}>
                          <div className="profileName">
                            <span>
                              <img
                                src={
                                  item?.profile?.url
                                    ? item?.profile?.url
                                    : defaultUser
                                }
                                alt="alpha officer"
                              />
                            </span>
                            <span>{item?.first_name}</span>
                          </div>
                          <div className="officerTag">
                            {item?.role === "18" && <p>Psychologist</p>}
                            {item?.role === "17" && <p>I/O</p>}
                            {item?.role === "16" && <p>GTO</p>}
                          </div>

                          <div className="connectBtn">
                            {checkSentRequest(item?._id) && <p>Request Sent</p>}
                            {checkFriend(item?._id) && (
                              <CustomButton
                                className=""
                                title={
                                  selectedId === item?._id && removeLoading ? (
                                    <ButtonLoader />
                                  ) : (
                                    "Remove"
                                  )
                                }
                                onClick={() =>
                                  handleRemoveConnection(item?._id)
                                }
                                // preIcon={<BsPersonFillAdd />}
                              />
                            )}
                            {!checkSentRequest(item?._id) &&
                              !checkFriend(item?._id) && (
                                <CustomButton
                                  className=""
                                  title={
                                    selectedId === item?._id && sendLoading ? (
                                      <ButtonLoader />
                                    ) : (
                                      "Connect"
                                    )
                                  }
                                  onClick={() => handleRequestSend(item?._id)}
                                  disabled=""
                                  preIcon={
                                    sendLoading ? "" : <BsPersonFillAdd />
                                  }
                                />
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {/* LIST OF SEARCH OFFICER RESULT END */}
              </>
            )}
            {activeTab === "list" && (
              <>
                <div className="officer_timeline_content">
                  {connectionList?.length > 0 ? (
                    <div className="officersList">
                      {connectionList?.map((item, idx) => (
                        <div className="officerRow" key={idx}>
                          <div className="profileName">
                            <span>
                              <img
                                src={
                                  item?.connect?.profile?.url
                                    ? item?.connect?.profile?.url
                                    : defaultUser
                                }
                                alt="alpha officer"
                              />
                            </span>
                            <span>{item?.connect?.first_name}</span>
                          </div>
                          <div className="officerTag">
                            {item?.connect?.role === "18" && (
                              <p>Psychologist</p>
                            )}
                            {item?.connect?.role === "17" && <p>I/O</p>}
                            {item?.connect?.role === "16" && <p>GTO</p>}
                          </div>
                          <div className="connectBtn">
                            <CustomButton
                              className=""
                              title="View Profile"
                              onClick={() => handleViewProfile(item?.connect)}
                              preIcon={<BsPersonFillAdd />}
                            />
                            <CustomButton
                              className=""
                              title={
                                selectedId === item?._id && removeLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  "Remove"
                                )
                              }
                              onClick={() =>
                                handleRemoveConnection(item?.connect?._id)
                              }
                              preIcon={<BsPersonFillAdd />}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <OfficerNoDataCard
                      txt="Alpha teacher, you have no connection at present. Share compelling content to attract aspiring connection and grow your audience."
                      image={followerImg}
                    />
                  )}
                </div>
              </>
            )}
            {activeTab === "request" && (
              <>
                <div className="officer_timeline_content">
                  {requestList?.length > 0 ? (
                    <div className="officersList">
                      {requestList?.map((item) => (
                        <div className="officerRow">
                          <div className="profileName">
                            <span>
                              <img
                                src={
                                  item?.connect?.profile?.url
                                    ? item?.connect?.profile?.url
                                    : defaultUser
                                }
                                alt="alpha officer"
                              />
                            </span>
                            <span>{item?.connect?.first_name}</span>
                          </div>
                          <div className="officerTag">
                            {item?.connect?.role === "18" && (
                              <p>Psychologist</p>
                            )}
                            {item?.connect?.role === "17" && <p>I/O</p>}
                            {item?.connect?.role === "16" && <p>GTO</p>}
                          </div>
                          <div className="connectBtn">
                            <CustomButton
                              className=""
                              title={
                                selectedId === item?._id && rejectLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  "Reject"
                                )
                              }
                              onClick={() =>
                                handleRejectConnection(item?.connect?._id)
                              }
                              preIcon={<BsPersonFillAdd />}
                            />
                            <CustomButton
                              className=""
                              title={
                                selectedId === item?._id && acceptLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  "Accept"
                                )
                              }
                              onClick={() =>
                                handleAcceptConnection(item?.connect?._id)
                              }
                              preIcon={<BsPersonFillAdd />}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <OfficerNoDataCard
                      txt="Alpha officers, you have no connection request at present. Share compelling content to attract aspiring connection and grow your audience."
                      image={followerImg}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OConnectionList;
