import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import styled from "styled-components";
import { FaEye } from "react-icons/fa";
const CalndarWrapper = styled.div`
  .fc {
    color: #fff;
  }
  .fc-header-toolbar {
    background-color: #081336;
    color: white;
  }
  .fc-event-main-frame {
    color: #fff;
    background-color: green;
    padding: 10px 4px;
    cursor: pointer;
  }

  .fc-view-harness-active {
    height: 440px;
  }

  .fc-daygrid-day-number {
    padding: 0px;
    position: unset;
  }
  .fc-daygrid-day-top {
    height: 0px;
  }
  @media (max-width: 991px) {
    .fc-daygrid-day-top {
      height: auto;
    }
  }
  .fc-daygrid-day-events {
    min-height: 0;
  }
  .fc-button-primary {
    background: #fff;
    color: #081336;
    font-size: 14px;
    text-transform: uppercase;
    &.disabled {
      background: #081336;
      color: #fff;
    }
  }
`;
const CustomCalendar = ({ events, handleDateClick }) => {
  const headerOptions = {
    left: "prev,next today",
    center: "title",
    right: "",
  };

  const handleClickWithProps = (info) => {
    handleDateClick(info);
  };

  const calendarOptions = {
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "",
    },
    eventBackgroundColor: "blue",
    eventColor: "white",
    eventBorderColor: "transparent",
    eventTextColor: "black",
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      meridiem: false,
    },
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: "dayGridMonth",
    dayCellContent: function (arg) {
      const eventsForDate = events?.filter(
        (event) =>
          event.date &&
          new Date(event.date).toDateString() === arg.date.toDateString()
      );
      if (eventsForDate?.length > 0) {
        return (
          <div className="element_date">
            <p>{arg.dayNumberText}</p>
            <div className="desk">
              <span
                style={{
                  backgroundColor: "#121D40",
                  color: "#fff",
                  display: "flex",
                  fontSize: "12px",
                  padding: "4px",
                }}
              >
                No. of event {eventsForDate?.length}
              </span>
              <button
                className="event_view_btn"
                title="View All Schedule events"
                onClick={() => handleClickWithProps(eventsForDate)}
              >
                See events
              </button>
            </div>
            <div className="mob">
              <button
                className="event_view_btn"
                title="View All Schedule events"
                onClick={() => handleClickWithProps(eventsForDate)}
              >
                ({eventsForDate?.length}) <FaEye />
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="element_date"
            onClick={() => handleClickWithProps(arg.date)}
          >
            {arg.dayNumberText}
          </div>
        );
      }
    },
  };
  return (
    <CalndarWrapper>
      <FullCalendar headerToolbar={headerOptions} {...calendarOptions} />
    </CalndarWrapper>
  );
};

export default CustomCalendar;
