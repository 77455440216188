import React from "react";
import soldier_header from "../../images/soldier_header.png";
import { BsStarFill } from "react-icons/bs";
import ImgLazyLoad from "../reusable/ImgLazyLoad";
import CustomRating from "../reusable/CustomRating";

const SoldierHeart = ({ img, sodierName, text, subHeading, star }) => {
  return (
    <div className="soldier_heart_main">
      <div className="pro_pic">
        {/* <img src={img ? img : soldier_header} alt="soldier_header" /> */}
        <ImgLazyLoad
          src={img ? img : soldier_header}
          alt="soldier_header"
          custom_class=""
        />
        <p>{sodierName}</p>
        <p className="soldier_heart_subHeading">{subHeading}</p>
        <div className="stars">
          <CustomRating val={star} readOnly={true} />
        </div>
        <div className="border_styles"></div>
      </div>
      <div className="soldier_heart_txt">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default SoldierHeart;
