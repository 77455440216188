import React from "react";
import OfficerNav from "./OfficerNav";
import OfficerFooter from "../../components/reusable/OfficerFooter";

const OfficerLayout = ({ children }) => {
  return (
    <div>
      <OfficerNav />
      <main style={{ minHeight: "70vh" }}>{children}</main>
      {/* <Footer /> */}
      <OfficerFooter />
    </div>
  );
};

export default OfficerLayout;
