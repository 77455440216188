import React, { Fragment, useEffect, useState } from "react";
import { BsPersonFillAdd } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import { clearFollowErrors, clearRemoveFollowState, getFollowerList, unfollowSomeone } from "../../redux/actions/followAction";
import Loader from "../../components/reusable/Loader";
import { defaultUser, followerImg } from "../../assets/images";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import OfficerNoDataCard from "../officer/OfficerNoDataCard";
const OFollowing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // GET ALL FOLLOWERS
  const {
    loading: followerLoading,
    error: followerError,
    followList,
  } = useSelector((state) => state.followerList);

  const followers = followList?.follows?.followings;
  useEffect(() => {
    dispatch(getFollowerList());
  }, [dispatch]);

  useEffect(() => {
    if (followerError) {
      showNotification("error", followerError);
      dispatch(clearFollowErrors());
    }
  }, [followerError, showNotification, dispatch]);

  const handleViewProfile = (data) => {
    navigate(`/friend-profile/${data?._id}`);
  };

  const [unFollowId, setUnFollowId] = useState();
  const handleUnFollow = (id) => {
    setUnFollowId(id);
    dispatch(unfollowSomeone(id));
  };

  const {
    error: unFollowError,
    loading: unFollowLoading,
    followInfo: unFollowInfo,
  } = useSelector((state) => state.unFollowRequest);

  useEffect(() => {
    if (unFollowError) {
      showNotification("error", unFollowError);
      dispatch(clearFollowErrors());
    }
    if (unFollowInfo?.success) {
      showNotification("success", unFollowInfo?.message);
      dispatch(clearRemoveFollowState());
      dispatch(getFollowerList());
    }
  }, [
    unFollowError,
    showNotification,
    dispatch,
    unFollowInfo?.message,
    unFollowInfo?.success,
  ]);

  return (
    <Fragment>
      {followerLoading ? (
        <Loader />
      ) : (
        <div className="officer_follower_main txt_color">
          <div className="title">
            <button title="Go Back" onClick={() => navigate(-1)}>
              <BiLeftArrowAlt />
            </button>
            Followings
          </div>
          <div className="container">
            <div className="officer_timeline_content">
              {followers?.length > 0 ? (
                <div className="officersList">
                  {followers?.map((item, id) => (
                    <div className="officerRow" key={id}>
                      <div className="profileName">
                        <span>
                          <img
                            src={
                              item?.profile?.url
                                ? item?.profile?.url
                                : defaultUser
                            }
                            alt="alpha officer"
                          />
                        </span>
                        <span>{item?.first_name}</span>
                      </div>
                      <div className="officerTag">
                        {item?.role === "18" && <p>Psychologist</p>}
                        {item?.role === "17" && <p>I/O</p>}
                        {item?.role === "16" && <p>GTO</p>}
                      </div>
                      <div className="connectBtn">
                        <CustomButton
                          className=""
                          title="View Profile"
                          onClick={() => handleViewProfile(item)}
                          preIcon={<BsPersonFillAdd />}
                        />
                        <CustomButton
                          className=""
                          title={
                            unFollowId === item?._id && unFollowLoading ? (
                              <ButtonLoader />
                            ) : (
                              "Unfollow"
                            )
                          }
                          onClick={() => handleUnFollow(item?._id)}
                          preIcon={<BsPersonFillAdd />}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <OfficerNoDataCard
                txt="Alpha officers, you have no following request at present. Share compelling content to attract follower and grow your audience."
                image={followerImg}
              />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OFollowing;
