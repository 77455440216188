import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TeacherLayout from "../../components/arTeacher/TeacherLayout";
import TeacherFooter from "../../components/arTeacher/TeacherFooter";
import Loader from "../../components/reusable/Loader";
import { otherTeacherLinks } from "./otherTeacherLinks";
import "../../styles/otherTeacherEaring.scss";
import { FaRupeeSign } from "react-icons/fa";
import CustomInput from "../../components/reusable/CustomInput";
import CustomButton from "../../components/reusable/CustomButton";
import CustomSelect from "../../components/reusable/CustomSelect";
import { BsFillFunnelFill, BsFillStickiesFill } from "react-icons/bs";
import {
  clearOfficerErrors,
  getOfficerRevenueAction,
} from "../../redux/actions/officerAction";
import { useDispatch } from "react-redux";
import {
  getMyCreatedCourseAction,
  teacherClearErrors,
} from "../../redux/actions/teacherAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";

const OtherTeacherEarning = () => {
  //   const { loading } = useSelector((state) => state.profile);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const dispatch = useDispatch();
  // const [courseFilter, setCourseFilter] = useState(false);
  const [auth] = useAuth();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  // const [filterValue, setFilterValue] = useState(false);
  const [, , showNotification] = useNotification();
  const filterDropdown = () => {
    setIsOpenFilter(!isOpenFilter);
  };
  // GET OFFICER REVENUE STARTS
  const {
    loading: revenueLoading,
    error: revenueError,
    revenueData,
  } = useSelector((state) => state.getOfficerRevenue);

  const totalEarning = revenueData?.revenue;

  useEffect(() => {
    dispatch(getOfficerRevenueAction());
  }, [dispatch]);

  useEffect(() => {
    if (revenueError) {
      dispatch(clearOfficerErrors());
    }
  }, [dispatch, revenueError]);

  // GET MY CREATED COURSE START
  const {
    loading: teacherCourseLoading,
    courseData: teacherCourses,
    error: teacherCourseErr,
  } = useSelector((state) => state.teacherCreatedCourse);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getMyCreatedCourseAction(auth?.user?._id));
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (teacherCourseErr) {
      dispatch(teacherClearErrors());
      showNotification("error", teacherCourseErr);
    }
  }, [dispatch, showNotification, teacherCourseErr]);

  // MAKE AN ARRAY FOR COURSE SLECT INPUT
  let optionArray = [];

  for (let i = 0; i < teacherCourses?.courses?.length; i++) {
    let optionData = {
      value: teacherCourses?.courses[i]?._id,
      option: teacherCourses?.courses[i]?.title,
    };
    optionArray.push(optionData);
  }
  // GET MY CREATED COURSE ENDS

  const [totalAmount, setTotalAmount] = useState(0);
  const [courseName, setCourseName] = useState("");
  const [courseFilter, setCourseFilter] = useState(false);
  // console.log(totalEarning);
  const handleFilter = (e) => {
    setCourseName(e);
    
    const filterRevenue = totalEarning?.totalRevenue?.txn?.filter(
      (itm) => itm?._id === e
    );
    setTotalAmount(filterRevenue?.reduce((acc, obj) => acc + obj.amount, 0));
    setCourseFilter(true);
  };

  return (
    <Fragment>
      {revenueLoading || teacherCourseLoading ? (
        <Loader />
      ) : (
        <TeacherLayout sidebarLinks={otherTeacherLinks}>
          <div className="otherTeacherMain txt_color">
            <div className="performance_panel">
              <div className="title">
                <h2>Earnings</h2>
              </div>
              <div className="performance_cards_data">
                <div className="earning_card">
                  <div className="total_earn">
                    <h2>Total Earning</h2>
                    <span>
                      <FaRupeeSign />
                    </span>
                  </div>
                  <p>{totalEarning?.totalRevenue} /-</p>
                  {/* <div className='progress-bar'>
                                        <div className='progress' style={{ width:"70%" }}></div>
                                    </div> */}
                </div>
                <div className="earning_card">
                  <div className="total_earn">
                    <h2>By Courses</h2>
                    <span>
                      <BsFillStickiesFill />
                    </span>
                  </div>
                  <CustomSelect
                    classStyle={"select_course"}
                    type="text"
                    fullWidth={"100%"}
                    placeholder={"Select course"}
                    name="last_name"
                    value={courseName}
                    loading={teacherCourseLoading}
                    readOnly={false}
                    onChange={(e) => handleFilter(e.target.value)}
                    optionArray={optionArray}
                    borderColor={"#f2f2f2"}
                  />
                  <p>
                    {courseFilter ? totalAmount : totalEarning?.totalRevenue}
                    /-
                  </p>
                </div>
                <div className="earning_card">
                  <div className="total_earn">
                    <h2>Filter Earnings</h2>
                    <span>
                      <BsFillFunnelFill />
                    </span>
                  </div>
                  <p>{totalEarning?.totalRevenue}/-</p>
                </div>
                <div className="earning_card">
                  <div className="total_earn">
                    <h2>Referral Revenue</h2>
                    <div className="filter">
                      <span onClick={filterDropdown}>
                        <BsFillFunnelFill />
                      </span>
                    </div>
                  </div>
                  <p>{totalEarning?.refferalRevenue} /-</p>
                </div>
              </div>
            </div>
          </div>

          <div className="earning_checkout">
            <div className="balance_withdraw">
              <div className="balance_title">
                <h2>Balance</h2>
                <p>{totalEarning?.totalRevenue} INR</p>
              </div>
              <div className="withdraw">
                <h2>Withdraw</h2>
                <CustomInput
                  icon={<FaRupeeSign />}
                  type="number"
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                  name="withdrawAmount"
                  value={withdrawAmount}
                  placeholder="Enter amount"
                />
                <CustomButton
                  className="payment_btn"
                  title="Withdraw Request"
                  //   onClick={handleWithdrawalSubmit}
                  //   disabled={false}
                />
              </div>
            </div>
          </div>

          <TeacherFooter />
        </TeacherLayout>
      )}
    </Fragment>
  );
};
export default OtherTeacherEarning;
