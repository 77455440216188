import {
  UNFOLLOW_FAIL,
  UNFOLLOW_REQUEST,
  UNFOLLOW_SUCCESS,
  CLEAR_DELETE_FRIEND_STATE,
  CLEAR_FOLLOW_ERROR,
  CLEAR_FOLLOW_STATE,
  FOLLOW_REQUEST_FAIL,
  FOLLOW_REQUEST_SEND,
  FOLLOW_REQUEST_SUCCESS,
  GET_FOLLOWER_FAIL,
  GET_FOLLOWER_REQUEST,
  GET_FOLLOWER_SUCCESS,
  REMOVE_FOLLOWER_FAIL,
  REMOVE_FOLLOWER_REQUEST,
  REMOVE_FOLLOWER_SUCCESS,
  VIEW_FRIEND_PROFILE_REQUEST,
  VIEW_FRIEND_PROFILE_SUCCESS,
  VIEW_FRIEND_PROFILE_FAIL,
  CLEAR_FIREND_PROFILE_ERROR,
} from "../constants/followRequest";

//SEND FOLLOW REQUEST ======
export const sendRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };
    case FOLLOW_REQUEST_SUCCESS:
      return {
        loading: false,
        followInfo: action.payload,
      };
    case FOLLOW_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_FOLLOW_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_FOLLOW_STATE:
      return {
        loading: false,
        followInfo: null,
      };

    default:
      return state;
  }
};

// ACCEPT FOLLOW REQUEST
export const unfollowRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case UNFOLLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UNFOLLOW_SUCCESS:
      return {
        loading: false,
        followInfo: action.payload,
      };
    case UNFOLLOW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_FOLLOW_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_DELETE_FRIEND_STATE:
      return {
        loading: false,
        followInfo: null,
      };

    default:
      return state;
  }
};

// REMOVE FOLLOWer =====>
export const removeRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_FOLLOWER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_FOLLOWER_SUCCESS:
      return {
        loading: false,
        followInfo: action.payload,
      };
    case REMOVE_FOLLOWER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_FOLLOW_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_DELETE_FRIEND_STATE:
      return {
        loading: false,
        followInfo: null,
      };

    default:
      return state;
  }
};

//   GET ALL FOLLOWERS
export const followerListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FOLLOWER_REQUEST:
      return {
        loading: true,
        followList: {},
      };
    case GET_FOLLOWER_SUCCESS:
      return {
        loading: false,
        followList: action.payload,
      };
    case GET_FOLLOWER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_FOLLOW_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET FRIEND PROFILE DETAILS
export const getFriendProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case VIEW_FRIEND_PROFILE_REQUEST:
      return {
        loading: true,
        friendDetails: {},
      };
    case VIEW_FRIEND_PROFILE_SUCCESS:
      return {
        loading: false,
        friendDetails: action.payload,
      };
    case VIEW_FRIEND_PROFILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_FIREND_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
