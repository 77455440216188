import {
  OMockInter,
  OPhsy,
  OcompleteMock,
  OcompleteSbb,
  OmockGto,
} from "../../assets/images";

export const OfflineCourseCategoryList = [
  {
    value: 3,
    label: "Mock Psychology",
    image: OPhsy,
    category: "Offline",
    courseCategory: "Psychology",
    tooltip:"Create offline mock Psychology test."
  },

  {
    value: 4,
    label: "Mock Interview",
    image: OMockInter,
    category: "Offline",
    courseCategory: "Interview",
    tooltip:"Create offline mock personal Interview.",
  },
  {
    value: 1,
    label: "SSB Coaching",
    image: OcompleteSbb,
    category: "Offline",
    courseCategory: "Complete SSB",
    tooltip:"Create Offline Courses, that cover a wide range of subjects, including SSB interview techniques, SSB psychology, and GTO strategies."
  },
  {
    value: 2,
    label: "Mock SSB Interview",
    image: OcompleteMock,
    category: "Offline",
    courseCategory: "Complete SSB",
    tooltip:"Conduct mock SSB using our infrastructure."
  },

  {
    value: 5,
    label: "Mock GTO",
    image: OmockGto,
    category: "Offline",
    courseCategory: "GTO",
    tooltip:"Conduct mock GTO using our GTO Ground."
  },
];
