import { Rating } from "@mui/material";
import React from "react";
// import { FaRegStar, FaStar } from "react-icons/fa";

const CustomRating = ({ val, readOnly, handleChange, precision = 0.1 }) => {
  const options = {
    size: "medium",
    value: val,
    readOnly: readOnly,
    precision:precision,
  };

  return (
    <Rating
      {...options}
      onChange={handleChange}
      sx={{
        '& .MuiRating-iconEmpty svg': {
          color: '#fff',
        },
      }}
     
    />
  );
};

export default CustomRating;
