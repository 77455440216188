import React from "react";
import { Link } from "react-router-dom";
import "../styles/sitemap.scss";
import {
    FaEnvelope,
    FaFacebookF,
    FaInstagram,
    FaLinkedin,
    FaPhoneAlt,
    FaTwitter,
    FaYoutube
} from "react-icons/fa";
import { BsAndroid2, BsApple } from "react-icons/bs";
import Header from "../components/reusable/Header";
const Sitemap = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="sitemap_links">
                    <div>
                        <div>
                            <h2>Courses</h2>
                            <ul>

                                <li>
                                    <Link to="/courses">All Courses</Link>
                                </li>
                                <li>
                                    <Link to="/ssb-courses">SSB Courses</Link>
                                </li>
                                <li>
                                    <Link to="/mock-ssb-interview">Mock SSB Interview</Link>
                                </li>
                                <li>
                                    <Link to="/mock-interview">Mock Interview Course</Link>
                                </li>
                                <li>
                                    <Link to="/mock-psychology">Mock Psychology</Link>
                                </li>
                                <li>
                                    <Link to="/mock-gto">Mock GTO</Link>
                                </li>
                                <li>
                                    <Link to="/spoken-english">Spoken English</Link>
                                </li>
                                <li>
                                    <Link to="/medical-checkups">Medical Checkups</Link>
                                </li>
                                <li>
                                    <Link to="/fitness-coach">Fitness Coach</Link>
                                </li>
                                <li>
                                    <Link to="/check-eligible-course">Check Eligible Course</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Policies</h2>
                            <ul>
                                <li>
                                    <Link to="/property-policies">Property Policies</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policies">Privacy Policies</Link>
                                </li>
                                <li>
                                    <Link to="/refund-policies">Refund Policies</Link>
                                </li>
                                <li>
                                    <Link to="/term-conditions">Term Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/ar-school-term-conditions">AR School Term Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/teacher-term-conditions">Teacher Terms</Link>
                                </li>
                                <li>
                                    <Link to="/officer-term-conditions">Officer Terms</Link>
                                </li>
                                <li>
                                    <Link to="/medical-partner-term-conditions">Medical Partner Term</Link>
                                </li>
                                <li>
                                    <Link to="/cyber-partner-term-conditions">Cyber Partner Term</Link>
                                </li>
                                <li>
                                    <Link to="/physical-coach-term-conditions">Physical Coach Term</Link>
                                </li>
                                <li>
                                    <Link to="/franchise-partner-term-conditions">Franchise Partner Term</Link>
                                </li>
                                <li>
                                    <Link to="/accommodation-partners">Accommodation Partners</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Alpha Partner</h2>
                            <ul>
                                <li>
                                    <Link to="/alpha-school">Alpha School</Link>
                                </li>
                                <li>
                                    <Link to="/franchise/home">Franchise Partner</Link>
                                </li>
                                <li>
                                    <Link to="/accommodation-partners">Accommodation Partners</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Company</h2>
                            <ul>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Useful Links</h2>
                            <ul>

                                <li>
                                    <Link to="/welcome">Welcome</Link>
                                </li>
                                <li>
                                    <Link to="/online-workshop">Online Workshop</Link>
                                </li>
                                <li>
                                    <Link to="/forgot-password">Forgot Password</Link>
                                </li>
                                <li>
                                    <Link to="/payment-options">Payment Options</Link>
                                </li>
                                <li>
                                    <Link to="/verification">Verification Page</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>
                                Alpha Regiment
                            </h2>
                            <ul>

                                <li>
                                    <Link to="/login">Login</Link>
                                </li>
                                <li>
                                    <Link to="/register">Register</Link>
                                </li>
                                <li>
                                    <Link to="/register/student">Student Register</Link>
                                </li>
                                <li>
                                    <Link to="/register/officer">Officer Register</Link>
                                </li>
                                <li>
                                    <Link to="/register/teacher">Teacher Register</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_main txt_color">
                <div className="container">

                    <div className="social_links">
                        <div className="app_link">
                            <p>Connect with us</p>
                            <div className="links">
                                <Link to="https://www.instagram.com/alpharegimentofficial/" title="Instagram" rel="nofollow noopener">
                                    <FaInstagram />
                                </Link>
                                <Link to="https://www.youtube.com/@alpharegiment" title="Youtube" rel="nofollow noopener">
                                    <FaYoutube />
                                </Link>
                                <Link to="https://linkedin.com/company/alpha-regiment/" title="Linkedin" rel="nofollow noopener">
                                    <FaLinkedin />
                                </Link>
                                <Link
                                    to="https://facebook.com/AlphaRegimentOfficial"
                                    title="Facebook"
                                    rel="nofollow noopener"
                                >
                                    <FaFacebookF />
                                </Link>
                                <Link
                                    to="https://twitter.com/AlphaRegiment "
                                    title="Twitter"
                                    rel="nofollow noopener"
                                >
                                    <FaTwitter />
                                </Link>
                                <Link
                                    to="mailto:hello@alpharegiment.com"
                                    title="Email"
                                    rel="nofollow noopener"
                                >
                                    <FaEnvelope />
                                </Link>
                                <Link to="tel:+919034864394" title="Call" rel="nofollow noopener">
                                    <FaPhoneAlt />
                                </Link>
                            </div>
                        </div>
                        <div className="app_link">
                            <p>Download our official app</p>
                            <div className="links">
                                <Link
                                    to="#!"
                                    title="Download Android App"
                                    rel="nofollow noopener"
                                >
                                    <BsAndroid2 />
                                </Link>
                                <Link to="#!" title="Download IOS App" rel="nofollow noopener">
                                    <BsApple />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <p className="copyright_txt">
                        Copyright © ARCAPTOR TECHNOLOGIES PRIVATE LIMITED. All rights reserved
                    </p>
                </div>
            </div>
        </>
    )
}

export default Sitemap