import React, { Fragment, useEffect, useRef, useState } from "react";
import "../../styles/chat/userChat.scss";
import { defaultUser } from "../../assets/images";
import { BsThreeDotsVertical, BsEmojiSmile, BsSendFill } from "react-icons/bs";
import { FaVideo } from "react-icons/fa";
import {
  MdKeyboardDoubleArrowRight,
  MdOutlineAttachFile,
} from "react-icons/md";
import { useAuth } from "../../context/userContext";
import { useChatAuth } from "../../context/chatContext";
import { useDispatch, useSelector } from "react-redux";
import {
  clearChatError,
  getMessageByConversation,
  newMessage,
  updateMessageAction,
} from "../../redux/actions/chatAction";
import MessageCard from "./MessageCard";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/reusable/Loader";
import audioBell from "../../audio/incoming-msg-tone.mp3";
import { useNotification } from "../../context/notificationContext";
import {
  clearVideoCallErrors,
  clearVideoCallState,
  createVideoCallLink,
} from "../../redux/actions/videoCallAction";

const UserChat = ({ setShowSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const user = auth?.user;
  const [msgValue, setMsgValue] = useState("");
  const [messages, setMessages] = useState([]);
  const { person, conversationId, activeUsers, socket } = useChatAuth();
  const [newMessageFlag, setNewMessageFlag] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [incomingMessage, setIncomingMessage] = useState(null);
  const [, , showNotfication] = useNotification();
  const {
    data: videoCallData,
    error: videoCallDataError,
    loading: videoCallDataLoading,
  } = useSelector((state) => state.videoCallLink);

  const messagesEndRef = useRef(null);

  // HANDLE VIDEO CALL LINK CREATION SYSTEM
  useEffect(() => {
    if (videoCallData?.success) {
      navigate(videoCallData?.link); // redirect to the new created link
      // const newWindow = window.open(videoCallData?.link, '_blank', 'noopener,noreferrer')
      // if (newWindow) newWindow.opener = null
      dispatch(clearVideoCallState());
    }
    if (videoCallDataError) {
      showNotfication("error", videoCallDataError);
      dispatch(clearVideoCallErrors());
    }
  }, [videoCallData, videoCallDataError, navigate, showNotfication, dispatch]);

  // GET REALTIME MESSAGE START
  useEffect(() => {
    const notification = new Audio(audioBell);
    if (socket) {
      socket.on("getMessage", (data) => {
        setIncomingMessage({
          ...data,
          createdAt: Date.now(),
        });
        notification.play();

        // Acknowledge receipt of the message
        socket.emit("acknowledgeReceipt", data);

        // Simulate viewing the message after a delay (e.g., user opens the message)
        setTimeout(() => {
          socket.emit("messageSeen", data);
        }, 2000); // 2 seconds delay for simulation
      });
    }
  }, [socket, dispatch]);
  // GET REALTIME MESSAGE ENDS

  useEffect(() => {
    if (!socket) return;

    const handleMessageReceived = (data) => {
      setMessages((prev) =>
        prev.map((msg) =>
          msg.messageId === data.messageId
            ? { ...msg, status: "delivered" }
            : msg
        )
      );
      dispatch(updateMessageAction(data.messageId, "delivered"));
    };

    const handleMessageSeen = (data) => {
      setMessages((prev) =>
        prev.map((msg) => {
          // console.log("msg", msg);
          return msg.messageId === data.messageId
            ? { ...msg, status: "read" }
            : msg;
        })
      );
      dispatch(updateMessageAction(data.messageId, "read"));
    };

    socket.on("messageReceived", handleMessageReceived);
    socket.on("messageSeen", handleMessageSeen);

    return () => {
      socket.off("messageReceived", handleMessageReceived);
      socket.off("messageSeen", handleMessageSeen);
    };
  }, [socket, dispatch, person?._id]);

  // SET INCOMING MESSAGE IN MESSAGES ARRAY START
  useEffect(() => {
    incomingMessage &&
      person?._id === incomingMessage?.senderId &&
      setMessages((prev) => [...prev, incomingMessage]);
  }, [incomingMessage, person?._id]);

  // SET INCOMING MESSAGE IN MESSAGES ARRAY ENDS

  // generate unique id
  function generateUniqueId() {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base-36 string
    const randomString = Math.random().toString(36).substring(2, 15); // Generate a random string
    return `${timestamp}-${randomString}`;
  }

  const handleSendMessage = (e) => {
    e.preventDefault();
    let message = {
      senderId: user?._id,
      receiverId: person?._id,
      conversationId: conversationId,
      type: "text",
      text: msgValue,
      messageId: generateUniqueId(),
      status: "sent",
    };
    const includeData = "1234567890onetwothreefourfivesixseveneightnineten";
    if (message.text && !includeData.includes(msgValue)) {
      setMessages((prev) => [...prev, { ...message, createdAt: Date.now() }]);
      socket.emit("sendMessage", message);
      dispatch(newMessage(message));
    } else {
      showNotfication("warning", "You can't Numbers");
    }
    setMsgValue("");
    setNewMessageFlag((prev) => !prev);
  };

  // GET MESSAGES START
  const { message, loading, error } = useSelector((state) => state.getMessages);

  useEffect(() => {
    dispatch(getMessageByConversation(conversationId));
  }, [dispatch, conversationId]);
  // newMessageFlag

  useEffect(() => {
    if (message?.success) {
      setMessages(message?.messages);
    }
    if (error) {
      dispatch(clearChatError());
    }
  }, [dispatch, error, message]);
  // GET MESSAGES ENDS

  // GO TO BOTTOM AUTOMATICALLY START
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  // GO TO BOTTOM AUTOMATICALLY ENDS

  // FILE CHANGE INPUT START
  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setMsgValue(e.target.files[0].name);
  };
  // FILE CHANGE INPUT ENDS

  // HANDLE VIDEO CALL START
  const handleVideoCall = async () => {
    const professionalsInfo = activeUsers.find(
      (user) => user?.userData?._id === person?._id
    );

    // console.log("professionalsInfo", professionalsInfo);
    
    if (professionalsInfo) {
      dispatch(
        createVideoCallLink(
          professionalsInfo?.userData?.first_name,
          professionalsInfo.socketId,
          professionalsInfo?.userData?._id
        )
      );
    } else {
      alert(`Call not initiated, ${person?.first_name} is not online.`);
    }
  };
  // HANDLE VIDEO CALL ENDS

  const emojis = ["😊", "🎉", "❤️"];

  return (
    <div className="user_chat_main">
      <div className="chat_topBar">
        <div className="user">
          <div
            className="forwardBtn"
            onClick={() => setShowSidebar(true)}
            title="See All Chats"
          >
            <MdKeyboardDoubleArrowRight />
          </div>
          <div className="details">
            <img
              src={person?.profile ? person?.profile?.url : defaultUser}
              alt="defaultUser"
            />
            <p>
              <span className="username">
                {person?.first_name ? person?.first_name : "User"}
              </span>
              <span className="status">
                {activeUsers.find((user) => user?.userData?._id === person._id)
                  ? "Online"
                  : "Offline"}
              </span>
            </p>
          </div>
          <div className="actionBar">
            <span title="Video Call" onClick={() => handleVideoCall()}>
              <FaVideo />
            </span>
            <span title="Action">
              <BsThreeDotsVertical />
            </span>
          </div>
        </div>
      </div>
      <div className="chat__messages">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="bgSection"></div>
            <div className="all_messages custonScroll">
              {messages?.map((msg, idx) => {
                return <MessageCard key={idx} message={msg} />;
              })}
              <div ref={messagesEndRef} />
            </div>
          </>
        )}

        <div className="msg_type_actionBox">
          <div className="emogiPicker">
            <BsEmojiSmile />
          </div>
          <div className="imagePicker">
            <label htmlFor="fileInput">
              <MdOutlineAttachFile />
            </label>
            <input
              type="file"
              name="fileInput"
              id="fileInput"
              style={{ display: "none" }}
              onChange={(e) => onFileChange(e)}
            />
          </div>
          <form className="msg_form" onSubmit={handleSendMessage}>
            <input
              type="text"
              name="message"
              placeholder="Type a message"
              value={msgValue}
              onChange={(e) => setMsgValue(e.target.value)}
              autoComplete="off"
            />
            {msgValue && (
              <button type="submit" className="msgSendBtn" title="Send Message">
                <BsSendFill />
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserChat;
